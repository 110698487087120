import * as XLSX from 'xlsx';

interface ColumnInfo<T> {
    [header: string]: {
        width: number,
        propertyFn: (row: T) => unknown;
    };
}

interface ExcelSheet<T> {
    data: T[];
    columnInfo: ColumnInfo<T>;
    filename: string;
}

const generateRow = <T>(row: T, columnInfo: ColumnInfo<T>): Record<keyof T, unknown> => Object.keys(columnInfo).reduce((newRow, key) => {
        newRow[key] = columnInfo[key].propertyFn(row);
        return newRow;
    }, {} as Record<keyof T, unknown>);

const downloadExcel = <T>({ data, columnInfo: columnInfo, filename }: ExcelSheet<T>) => {
    if (!data || !columnInfo || !filename) {
        throw new Error('Missing necessary parameters');
    }

    const rows = data.map(row => generateRow(row, columnInfo));
    const header = Object.keys(columnInfo);
    const cols = Object.values(columnInfo).map(value => ({ wch: value.width }));

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(rows, { header });
    worksheet['!cols'] = cols;
    
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, filename);
};

export { ExcelSheet, ColumnInfo, downloadExcel };
