<template>
  <div class="p-float-label">
    <Dropdown id="relativeDateRangeOptions" v-model="selectedOption" :options="options" option-label="name" option-value="id"
      class="p-border-round-left" />
    <label for="relativeDateRangeOptions">Relative Date Range</label>
  </div>
</template>

<script lang="ts">

import {RelativeDateRange, RelativeDateRangeFilter} from '@/models/filters';
import {PropType, ref, watch} from 'vue';
import {Lookup} from '@/models/lookup';

export default {
  name: 'CpDefaultDateRangeSearchField',
  inheritAttrs: true,
  props: {
    modelValue: { type: Object as PropType<RelativeDateRangeFilter>, default: new RelativeDateRangeFilter() },
    label: { type: String, default: '' },
    submitted: Boolean
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const selectedOption = ref<RelativeDateRange>(props.modelValue.value);
    
    const onOptionChange = () => {
      const newVal = new RelativeDateRangeFilter({
        ...props.modelValue,
        value: selectedOption.value
      });
      emit('update:modelValue', newVal);
    };
    
    watch(selectedOption, () => onOptionChange(), { deep: true, immediate: true});

    const options: Lookup<string>[] = [
      {id: RelativeDateRange.Custom, name: 'Custom'},
      {id: RelativeDateRange.Last30Days, name: 'Last 30 Days'},
      {id: RelativeDateRange.Last60Days, name: 'Last 60 Days'},
      {id: RelativeDateRange.Last90Days, name: 'Last 90 Days'}
    ];
    
    return { selectedOption, options };
  }
};

</script>

