import axios from 'axios';
import { ReaderInfo } from '@/models/user-service/readerInfo';
import { ReaderRecentEngagement } from '@/models/user-service/readerRecentEngagement';
import dayjs from 'dayjs';
import { IntervalBucket } from '@/models/intervalBucket';
import { NamedSeries } from '@/models/namedSeries';
import { Quality } from '@/models/activity-dissemination';

export interface ActivitiesByMonthRequest {
  idOrEmail: string;
  companyIds: number[];
  from: Date;
  to: Date;
  qualities: Quality[];
}

export interface EngagementsByCategoryRequest extends ActivitiesByMonthRequest {
  category: string;
}

export interface RecentEngagementsRequest {
  idOrEmail: string;
  companyIds: number[];
  size: number;
  qualities: Quality[];
}

export interface ActivitiesBaseRequest {
  companyIds: number[];
  from: Date;
  to: Date;
}

function toDateRangeStr(from?: Date, to?: Date): string {
  const fromStr = from
    ? dayjs(from).format('YYYY-MM-DD')
    : dayjs().subtract(1, 'year').format('YYYY-MM-DD');
  const toStr = dayjs(to || new Date()).format('YYYY-MM-DD');

  return `${fromStr}/${toStr}`;
}

export default {

  async getReaderInfo(idOrEmail: string): Promise<ReaderInfo> {
    const opts = { params: { path: `user/${idOrEmail}?level=Cerebro` } };
    const response = await axios.get('/api/user-proxy', opts);
    return new ReaderInfo({ ...response.data });
  },

  async getRecentGatedContent(req: RecentEngagementsRequest): Promise<ReaderRecentEngagement[]> {
    const cIds = req.companyIds.join(',');
    const qualities = req.qualities.join(',');
    req.size = req.size || 5;
    const url = `/api/reader-dashboard/${cIds}/recent-gated/${req.idOrEmail}/${req.size}`;
    const params = { qualities };
    return (await axios.get(url, { params })).data;
  },

  async getRecentActiveContent(req: RecentEngagementsRequest): Promise<ReaderRecentEngagement[]> {
    const cIds = req.companyIds.join(',');
    const qualities = req.qualities.join(',');
    req.size = req.size || 5;
    const url = `/api/reader-dashboard/${cIds}/recent-active/${req.idOrEmail}/${req.size}`;
    const params = { qualities };
    return (await axios.get(url, { params })).data;
  },

  async getActivitiesByMonth(req: ActivitiesByMonthRequest): Promise<Array<IntervalBucket<Record<string, number>>>> {
    const cIds = req.companyIds.join(',');
    const qualities = req.qualities.join(',');
    let url = `/api/reader-dashboard/${cIds}/share-by-month/${req.idOrEmail}`;
    url = `${url}/${toDateRangeStr(req.from, req.to)}`;
    const params = { qualities };
    return (await axios.get(url, { params })).data;
  },

  async getEngagementsByCategory(req: EngagementsByCategoryRequest): Promise<NamedSeries[]> {
    const cIds = req.companyIds.join(',');
    const qualities = req.qualities.join(',');
    let url = `/api/reader-dashboard/${cIds}/by-category/${req.idOrEmail}/${req.category}`;
    url = `${url}/${toDateRangeStr(req.from, req.to)}`;
    const params = { qualities };
    return (await axios.get(url, { params })).data;
  },
};
