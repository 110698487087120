import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex col-12 justify-content-between" }
const _hoisted_2 = { id: "engagements-over-time" }
const _hoisted_3 = { class: "pt-2" }
const _hoisted_4 = {
  ref: "chart",
  "cp-color-picker": "",
  class: "flex align-items-center pt-3",
  style: {"min-height":"300px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_cp_export_chart_options = _resolveComponent("cp-export-chart-options")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, [
        _createVNode(_component_Dropdown, {
          modelValue: _ctx.category,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.category) = $event)),
          options: _ctx.categories,
          "option-label": "name",
          class: "p-dropdown-noborder",
          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeCategory()))
        }, {
          value: _withCtx((slotProps) => [
            _createElementVNode("h2", null, _toDisplayString(slotProps.value.name), 1)
          ]),
          _: 1
        }, 8, ["modelValue", "options"]),
        _createTextVNode(" " + _toDisplayString(_ctx.showTitle), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_cp_export_chart_options, {
          chart: _ctx.chartExport,
          onChartUpdated: _ctx.chartUpdated
        }, null, 8, ["chart", "onChartUpdated"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_ProgressSpinner)
    ], 512)
  ], 64))
}