import { EngagementSearchDetails } from '@/models/search/engagementSearchDetails';

export class IndividualActivitySearchDetails extends EngagementSearchDetails {
  otherActivitiesForReaderCount: number;
  otherReadersCount: number;

  constructor(data: Partial<IndividualActivitySearchDetails> | null = null) {
    super(data);
   
    if (data) {
      Object.assign(this, data);
    }
  }
}
