import { DATETIME_WIDTH, DATE_WIDTH, ICON_WIDTH } from '@/models/styles';

export const DASHBOARD_HEADERS = [
  { text: 'Notification Sent On', value: 'runOn', width: DATETIME_WIDTH },
  { text: 'Alert Name', value: 'notificationName' },
  { text: 'Alert Description', value: 'description', sortable: false },
  { text: 'Report Link', align: 'center', value: 'sasUrl', width: DATE_WIDTH },
  { text: 'Count', value: 'totalMatched', align: 'end' },
  { text: 'Created By', value: 'notificationCreatedBy' },
  { text: 'Read / Unread', align: 'center', value: 'isRead', width: ICON_WIDTH }
];
export const AUDIT_DETAILS_HEADERS = [
  { text: 'Event Date', align: 'start', value: 'date', width: DATETIME_WIDTH },
  { text: 'Reader Org', value: 'orgName' },
  { text: 'Reader', value: 'fullName' },
  { text: 'Job Title', value: 'jobTitle' },
  { text: 'Content Consumed', value: 'documentTitle', sortable: false }
];
export const AUDIT_SUMMARY_HEADERS = [
  { text: 'Reader Org', value: 'orgName' },
  { text: '% Change', value: 'percentChange' },
  { text: '# Unique Readers', value: 'baselineUniqueUserCount'},
  { text: 'Current Period', value: 'currentUniqueUserCount'},
  { text: '# Engagements', value: 'currentAverageActivities' },
  { text: 'Change in past 1 month as compared to the prior 3 months', value: ''}
];

export const FRIENDLY_FILTER_HEADERS = [
  {text: 'Filter', value: 'filterName'},
  {text: 'Operation', value: 'operation'},
  {text: 'Value Specified', value: 'value'}
];
