<template>
  <div v-if="modelValue" class="grid pb-0 my-2">
    <div class="col-12 lg:col-2 p-secondary-color mt-3 flex align-items-center">
      <label for="enabled">Enabled</label>
      <Checkbox id="enabled" v-model="modelValue.enableApiAccess" :binary="true" class="ml-2" />
    </div>
    <div class="col-12 lg:col-4 p-secondary-color">
      <span class="sub-heading">Templeton Activities API Url</span><br>
      <span class="font-weight-bold text-break">{{ templetonActivitiesApiUrl }}</span>
    </div>
    <div class="col-12 lg:col-3 p-secondary-color">
      <span class="sub-heading">API Client Id</span><br>
      <span class="font-weight-bold text-break">{{ modelValue.apiClientId }}</span>
    </div>
    <div class="col-12 lg:col-3 mt-2 flex lg:flex-row-reverse">
      <Button type="button" class="p-button-outlined p-button-rounded ml-3" @click="generateSecret">
        <span className="p-button-label p-c">Generate</span>
        <i class="material-icons ml-3 p-secondary-color">vpn_key</i>
      </Button>
    </div>
  </div>
  <div v-if="apiClientSecret" class="grid">
    <div class="col-12 lg:col-6">
      <Message :closable="false" severity="warn">
        Please record and protect your API Client Secret. You won't be
        able to view it after you leave this page. It is required for your API Requests.
      </Message>
    </div>
    <div class="col-12 lg:col-6">
      <span class="sub-heading p-secondary-color">API Client Secret</span><a @click="copySecret"><span class="material-icons-outlined pl-1">copy</span></a><br>
      <span class="font-weight-bold text-break">{{ apiClientSecret }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { AppUser } from '@/models/appUser';
import { JwtTokenUser } from '@/store/models';
import axios from 'axios';
import { Prop } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { Vue, Options } from 'vue-class-component';

@Options({
  computed: {
    ...mapGetters('auth', ['userRole', 'userId'])
  }
})
export default class ApiSettings extends Vue {
  @Prop() modelValue: AppUser;
  loggedInUser: JwtTokenUser;
  templetonActivitiesApiUrl = process.env.VUE_APP_TEMPLETON_PUBLIC_API_URL;

  apiClientSecret: string = '';
  apiClientSecretMessage: string = `Please record and protect your <b>API Client Secret</b> as you will no longer be able to view it after you leave 
    this page and it is required for your API requests.<br/> If you forget your <b>API Client Secret</b>
    you can generate a new one by clicking the <b>Generate Secret</b> button which will invalid the previous <b>API Client Secret</b>.`;

  async created() {
    this.loggedInUser = (<JwtTokenUser>this.$store.state.auth.user);
  }

  async generateSecret() {
    const res = await axios.get(`/api/secret/${this.modelValue.apiClientId}`);
    this.apiClientSecret = res.data.secret;
  }

  async copySecret() {
    navigator.clipboard
      .writeText(this.apiClientSecret)
      .then(() => {
        console.log(`"${this.apiClientSecret}" was copied to clipboard.`);
      })
      .catch((err) => {
        console.error(`Error copying text to clipboard: ${err}`);
      });
  }

  get canViewApiCredentials() {
    if (this.modelValue) {
      if (!this.modelValue.enableApiAccess) {
        this.apiClientSecret = '';
      }
      return this.modelValue.enableApiAccess && this.modelValue.apiClientId;
    }

    return false;
  }
}
</script>
<style lang="scss">

</style>
