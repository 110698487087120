import axios from 'axios';

export interface ErrorRequestData {
  eventInfo: string;
  stackTrace?: string;
  path: string;
  userAgent: string;
}

export default {
  async sendErrorToApi(req: ErrorRequestData): Promise<any> {
    const { eventInfo, stackTrace, path, userAgent } = req;
    const data = { eventInfo, stackTrace, path, userAgent };
    return await axios.post('/api/log/error', data);
  }
};
