const emailProviders = `<a href="https://support.microsoft.com/en-us/office/add-recipients-of-my-email-messages-to-the-safe-senders-list-be1baea0-beab-4a30-b968-9004332336ce" 
target="_blank" rel="noopener noreferrer">Outlook</a>, 
<a href="https://support.google.com/mail/answer/6579?hl=en#zippy=%2Ccreate-a-filter" target="_blank" rel="noopener noreferrer">Gmail</a>, 
<a href="https://www.xfinity.com/support/articles/xfinity-connect-safe-list" target="_blank" rel="noopener noreferrer">Xfinity</a>, 
<a href="https://forums.verizon.com/t5/verizon-net-email/verizon-fios-e-mail-spam-filter-how-to-edit-whitelist/td-p/733587#:~:
text=The%20SpamDetector's%20%22whitelist%22%20is%20called,Senders%20section%20at%20the%20bottom" target="_blank" rel="noopener noreferrer">Verizon</a>, 
or <a href="https://help.yahoo.com/kb/add-edit-delete-contacts-yahoo-mail-sln28059.html" target="_blank" rel="noopener noreferrer">Yahoo</a>.`;

export const safeLinkInstruction = `Ensure notification emails go straight to your inbox by adding us to your safe sender list. 
For instructions on how to do this, please refer to the guidelines from your email service provider: ${emailProviders}`;

export const whitelistInstruction = `Please review whitelisting guidelines from your email service provider: ${emailProviders}`;