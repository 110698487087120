<template>
  <ColorPicker :value="local" @update:model-value="c => $emit('input', c)" />
</template>

<script lang="ts">
import { Prop } from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component';

@Options({
})
export default class ColorPickerComponent extends Vue {

  @Prop({ default: '#1976D2FF' }) value: string;
  menu: boolean = false;

  get swatchStyle() {
    return {
      backgroundColor: this.local,
      cursor: 'pointer',
      height: '30px',
      width: '30px',
      borderRadius: '4px',
      transition: 'border-radius 200ms ease-in-out'
    };
  }

  get local() {
    return this.value || '#111111';
  }
}
</script>

<style scoped>

</style>
