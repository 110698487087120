import {getActivityFieldNode} from '@/components/admin/activityFieldAdminUtils';
import {ActivityFieldCategoryEnum, categoryLabel, categorySortOrder} from '@/components/searchFields/metadata';
import {ActivityFieldAccessLevelEnum} from '@/models/common/activityFieldAccessLevel';
import {FieldCode} from '@/models/filters';
import {ActivityField, ActivityFieldNode, TreeNode} from '@/models/search';
import {UserRole} from '@/models/userRole';
import {groupBy, uniq} from 'lodash';
import {Ref, ref} from 'vue';
import useActivityFields from '@/use/activityFields';

export default function useFilterOptionSecurity(treeSelections: Ref<FieldCode[]>) {
  const dataInitialized = ref<boolean>(false);
  const fields = ref<ActivityField[]>([]);
  const items = ref<TreeNode[] | null>(null);
  const { allActivityFields } = useActivityFields();

  const getData = async (userRole: UserRole) => {
    const maxAccessLevel: ActivityFieldAccessLevelEnum = userRole == 'Client' ? ActivityFieldAccessLevelEnum.Premium : ActivityFieldAccessLevelEnum.Internal;
    fields.value = allActivityFields.value.filter(x => ActivityFieldAccessLevelEnum[x.accessLevel] <= maxAccessLevel
      && ActivityFieldAccessLevelEnum[x.accessLevel] > ActivityFieldAccessLevelEnum.Basic);
    items.value = _getTreeNodes();
    dataInitialized.value = true;
  };

  const refreshItems = async (userRole: UserRole) => {
    await getData(userRole);
  };

  const toggleOption = async (state: boolean, key: FieldCode, group: TreeNode) => {
    if (state) {
      treeSelections.value = uniq(treeSelections.value.concat(key));
      if (group.children.every(x => x.isActive === true)) {
        group.isAllActive = state;
      }
    } else {
      treeSelections.value = treeSelections.value.filter(x => x !== key);
      if (group.isAllActive) {
        group.isAllActive = state;
      }
    }
  };

  const toggleOptions = async (state: boolean, key: string) => {
    const category = items.value?.find((x) => x.key == key);
    if (category) {
      const newFields: FieldCode[] = category.children.map(x => x.key);
      category.children.forEach(x => {
        x.isActive = state;
      });
      if (state) {
        treeSelections.value = uniq(treeSelections.value.concat(newFields));
      } else {
        treeSelections.value = treeSelections.value.filter(x => !newFields.includes(x));
      }

      // console.debug(`FilterOptions.activateAll()`, newFields, treeSelections);
    }
  };

  const toggleGroup = async (state, group: TreeNode) => {
    // console.debug(`FilterOptions.toggleGroup()`, state, group);
    group.isCollapsed = state.value;
  };

  const _getTreeNodes = () => {
    const groups = groupBy(fields.value, x => x.category);
    return Object.keys(groups)
        .map<TreeNode>(cat => _getCategoryNode(ActivityFieldCategoryEnum[cat], groups[cat]))
        .sort((a, b) => (a.sortOrder || 99) > (b.sortOrder || 99) ? 1 : -1);
  };

  const _getCategoryNode = (cat: ActivityFieldCategoryEnum, group: ActivityField[]) => {
    const children = group
      .map<ActivityFieldNode>(c => {
        c.isActive = treeSelections.value.includes(c.field);
        return getActivityFieldNode(c, cat);
      })
      .sort((a, b) => a.label > b.label ? 1 : -1);
    return {
      key: ActivityFieldCategoryEnum[cat],
      label: categoryLabel[cat] || ActivityFieldCategoryEnum[cat],
      children: children,
      sortOrder: categorySortOrder[cat] ?? 99,
      isAllActive: children.every(x => x.isActive === true),
      isCollapsed: true
    };
  };

  return { dataInitialized, items, toggleOptions, toggleOption, toggleGroup, getData, refreshItems, fields };
}
