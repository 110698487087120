import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex p-fluid" }
const _hoisted_2 = { for: "options" }
const _hoisted_3 = { class: "w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputNumber = _resolveComponent("InputNumber")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: "p-float-label",
      style: _normalizeStyle($setup.defaultLabel.length < 21 ? 'min-width: 150px' : 'min-width: 230px')
    }, [
      _createVNode(_component_Dropdown, {
        modelValue: $setup.local.op,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.local.op) = $event)),
        options: $setup.operationsOpts,
        "option-label": "name",
        "option-value": "id",
        class: "p-border-round-left",
        onChange: _cache[1] || (_cache[1] = v => $setup.updateField('op', v))
      }, null, 8, ["modelValue", "options"]),
      _createElementVNode("label", _hoisted_2, _toDisplayString($setup.defaultLabel), 1)
    ], 4),
    _createElementVNode("div", _hoisted_3, [
      ($setup.displayAsCurrency)
        ? (_openBlock(), _createBlock(_component_InputNumber, {
            key: 0,
            modelValue: $setup.local.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.local.value) = $event)),
            class: "w-full p-border-round-right inputnumber-standard-text",
            mode: "currency",
            currency: "USD",
            onInput: _cache[3] || (_cache[3] = ($event: any) => ($setup.updateField('value', $event)))
          }, null, 8, ["modelValue"]))
        : _createCommentVNode("", true),
      (!$setup.displayAsCurrency)
        ? (_openBlock(), _createBlock(_component_InputNumber, {
            key: 1,
            modelValue: $setup.local.value,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.local.value) = $event)),
            class: "w-full p-border-round-right inputnumber-standard-text",
            onInput: _cache[5] || (_cache[5] = ($event: any) => ($setup.updateField('value', $event)))
          }, null, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ])
  ]))
}