import { INDEX_LOOKUPS } from '@/models/activity-dissemination';
import { DEFAULT_DATE_RANGE_FILTER, FieldCode } from '@/models/filters';
import { ActivityField, FilterModel } from '@/models/search';
import { SearchDefaults } from '@/store/models/currentSearchState';
import { SEARCH_MODULE } from '@/store/modules/search.module';
import { Vue, Options } from 'vue-class-component';
import { mapGetters } from 'vuex';
import { Watch } from 'vue-property-decorator';

@Options({
  computed: {
    ...mapGetters('auth', ['userActivitySearchFields', 'userId']),
    ...mapGetters('activityFields', ['fields']),
  }
})
export class DefaultSearchMixin extends Vue {
  userId!: string;
  userActivitySearchFields!: FieldCode[];  // mapped from vuex
  fields: ActivityField[]; // mapped from vuex
  searchDefaults: SearchDefaults;
  initialState: FilterModel;
  currentState: FilterModel;
  defaultsLoaded: boolean = false;

  @Watch('fields', { immediate: true })
  async fieldsChanged(val: ActivityField[]) {
    if (val && val.length > 0) {
      // eslint-disable-next-line no-async-promise-executor
      if (!this.$store.state.search.defaults) {
        await this.$store.dispatch(`search/${SEARCH_MODULE.GET_SEARCH_DEFAULTS}`, { userId: this.userId });
      }
      this.searchDefaults = this.$store.state.search.defaults;
      this.currentState = this.$store.state.search.criteria?.filterModel ?? this.$store.state.search.defaults;

      const indices = this.currentState.indices?.length
        ? this.currentState.indices
        : this.searchDefaults.indices?.length
          ? this.searchDefaults.indices
          : INDEX_LOOKUPS;

      const filters = this.currentState.filters?.length
        ? this.currentState.filters
        : this.searchDefaults.filters;

      const dateFilterExists = this.currentState.filters.some(filter => filter.field === 'DateRange');
      const filteredFilters = filters.filter(f => [...this.userActivitySearchFields, ...this.fields.filter(x => x.accessLevel == 'Basic' && x.isSearchable).map(x => x.field)].includes(f.field));
      const updatedFilters = dateFilterExists ? filteredFilters : [...filteredFilters, DEFAULT_DATE_RANGE_FILTER];
      this.initialState = {
        savedSearchId: this.currentState?.savedSearchId,
        filters: updatedFilters,
        indices
      };
      this.defaultsLoaded = true;
    }
  }
}
