import { IntLookup } from '@/models/lookup';

export class NotificationDashboardDetails {
  id: string;
  notificationId: string;
  sasUrl?: string;
  reportFileName?: string;
  notificationName: string;
  companies: IntLookup[];
  runOn: Date;
  isRead: boolean;
  notificationCreatedBy: string;
  notificationCreatedDate: Date;
  totalMatched: number;
  exceedsThreshold: boolean;

  constructor(props: Partial<NotificationDashboardDetails>) {
    Object.assign(this, props);
    this.companies = this.companies || [];
    if (typeof props.runOn === 'string') {
      const a = /\/Date\((\d*)\)\//.exec(props.runOn);
      if (a) {
        this.runOn = new Date(+a[1]);
      }
    }
  }
}
