import { Router } from 'vue-router';
import { Store } from 'vuex';
import { isPublicPage } from '../utils/publicRoutes';
import { JwtTokenUser } from '../store/models';
import { AUTH_MUTATIONS } from '../store/modules/auth.module';
import { Claim } from '../models/security/claim';

let initialized = false;
export function useRouteGuard(router: Router, store: Store<any>) {

  if (initialized) { return; }
  initialized = true;

  router.beforeEach((to, from, next) => {
    if (isPublicPage(to.path)) {
      return next();
    }
  
    const tokenUser = <JwtTokenUser>store.state.auth.user;

    if (!tokenUser || tokenUser.isExpired) {
      console.warn('[RouteGuard] Session Expired');
      const msg = document.referrer ? 'Your session expired' : null;
      // set route so that we can redirect to it when user logs in
      if (to && to.path !== '/' && to.path !== '/login') {
        store.commit(`auth/${AUTH_MUTATIONS.SET_ROUTE}`, to);
      }
      store.commit(`auth/${AUTH_MUTATIONS.CLEAR_STATE}`, msg);
      return next('/login');
    }

    const claim: Claim = to.meta.auth as Claim;
    if (!claim) { return next(); }

    if (!tokenUser.satisfiesClaim(claim)) {
      console.warn('[RouteGuard] insufficient privileges');
      store.commit(`auth/${AUTH_MUTATIONS.CLEAR_STATE}`, 'Insufficient privileges');
      return next('/login');
    }
    return next();
  });
}
