<template>
  <div class="flex justify-content-between">
    <h2 class="line-height-1">
      {{ title }}
      <!-- <span class="help-icon ml-2">?</span> -->
    </h2>
  </div>
  <div class="flex px-0 pt-6">
    <DataView :value="data1" class="w-full">
      <template #list="slotProps">
        <div class="col-12 px-0">
          <div class="flex col-12 px-0 pb-0">
            <div class="col-8 px-0">
              <span class="text-base font-bold capitalize">
                <router-link :to="readerURL(slotProps.data)">
                  {{ slotProps.data.Name }}
                </router-link>
              </span>
              <div class="text-sm capitalize">
                {{ slotProps.data.Title }}
              </div>
            </div>
            <div class="col-4">
              <span class="text-sm font-semibold">Engagements: {{ slotProps.data.Engagements }}</span>
              <br>
              <span class="text-sm font-semibold">Documents: {{ slotProps.data.Documents }}</span>
            </div>
          </div>
          <div class="flex col-12 px-0 pt-0">
            <span class="font-semibold pr-1 text-sm">Most Recent: </span>
            <span class="truncate-text-readerco text-sm">
              <a target="_blank" :title="slotProps.data.MostRecent" :href="slotProps.data.DocumentUrl" class="text-sm">{{ slotProps.data.MostRecent
              }}</a></span>
          </div>
        </div>
      </template>
      <template #empty>
        <div>
          <cp-no-datatable-records />
        </div>
      </template>
    </DataView>
  </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import ReaderCoService from '@/services/readerCoService';
import DataMapper, { FieldMapping } from '@/models/dataMapper';
import { LinkFieldData } from '@/models/analytics/linkFieldData';
import { FilterModel } from '@/models/search';
import { AggregationType } from '@/models/analytics/aggregationType';
import { cloneDeep, isEqual } from 'lodash';
import { filterValueExists } from '@/utils/filters';
import { Filter } from '@/models/filters';
import { Vue, Options } from 'vue-class-component';
import NoDataTableRecordsComponent from '@/components/common/NoDataTableRecordsComponent.vue';
import { sanitizeUrlParam } from '@/utils/urlUtils';

@Options({
  components: {
    'cp-no-datatable-records': NoDataTableRecordsComponent,
  },
})
export default class DataView extends Vue {
  @Prop({ default: () => <FilterModel>{ filters: [], indices: [] } })
  public filterModel: FilterModel;
  @Prop({ default: () => <Filter>{ field: 'ReaderOrgs' } }) public orgFilter: Filter;
  @Prop() aggType!: AggregationType;
  data1: Array<Record<string, any>> = [];
  linksCache: Record<string, string[]> = {};
  fieldMappings: FieldMapping[] = [];
  title: string = '';
  linkFields: LinkFieldData[] = [];
  rebuildLinksCache = false;

  @Watch('filterModelWithOrg', { immediate: true })
  async filtersChanged(newVal: FilterModel, oldVal: FilterModel) {
    const noChanges = isEqual(newVal, oldVal);
    if (!newVal || noChanges || !filterValueExists(newVal.filters, 'Clients')) {
      return;
    }

    this.rebuildLinksCache = true;
    await this._executeApiRequest(newVal);
  }

  get filterModelWithOrg() {
    const filterModel = cloneDeep(this.filterModel);
    filterModel.filters.push(this.orgFilter);
    return filterModel;
  }

  get headers() {
    return this.fieldMappings.map((x: FieldMapping) => ({
      text: x.targetField,
      value: x.targetField,
      sortable: false,
    }));
  }

  get links() {
    if (Object.keys(this.linksCache).length === 0 || this.rebuildLinksCache) {
      const linkFields: LinkFieldData[] = this.linkFields;

      if (linkFields) {
        linkFields.forEach((x) => {
          this.linksCache[x.textField] = this.data1.map((y) => `${x.url}/${encodeURIComponent(y[x.urlParamField])}`);
        });
      }
    }

    return this.linksCache;
  }

  readerURL(item: Record<string, any>) {
    return '/analytics/reader-dashboard/' + sanitizeUrlParam(item.userIdOrEmail);
  }

  private async _executeApiRequest(filterModel: FilterModel) {
    let responseData: Array<Record<string, any>> = [];
    switch (this.aggType) {
      case 'mostActiveReaders':
        this.fieldMappings = [
          { targetField: 'Name', sourceField: 'name' },
          { targetField: 'Title', sourceField: 'jobTitle' },
          { targetField: 'ReaderOrg', sourceField: 'company' },
          { targetField: 'Documents', sourceField: 'totalDocuments' },
          { targetField: 'Engagements', sourceField: 'totalEngagements' },
          { targetField: 'MostRecent', sourceField: 'documentTitle' },
          { targetField: 'DocumentUrl', sourceField: 'documentUrl' },
        ];
        // this.linkFields = [
        //   {
        //     textField: "Name",
        //     url: "/analytics/reader-dashboard",
        //     urlParamField: "userIdOrEmail",
        //   },
        // ];
        this.title = 'Most Active Readers';
        responseData = await ReaderCoService.getMostActiveReaders(filterModel);
        break;

      default:
        break;
    }

    if (responseData?.length > 0) {
      // Create data object with fields that should appear in the header while preserving other fields
      this.data1 = responseData.map((x: Record<string, any>) => {
        const mapper = new DataMapper(x, this.fieldMappings, true);
        return { key: responseData.indexOf(x), ...mapper.mappedData };
      });
    } else {
      this.data1 = [];
    }
  }
}
</script>

<style lang="scss">
.truncate-text-readerco {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  max-width: 250px;
}
</style>
