<template>
  <div class="col-12 h-6rem">
    <div class="col-12 mt-2">
      <Card id="summary" class="p-alerts-user-card p-secondary-color pl-3">
        <template #content>
          <div class="flex align-items-center justify-content-between -mt-2 py-0">
            <div class="p-primary-color text-xs font-bold">
              RESULTS SUMMARY
            </div>
            <div class="flex p-primary-color text-sm font-bold pr-2">
              <Divider layout="vertical" class="py-0 my-0 px-1" />
              <div class="text-xl">
                {{ stats?.orgsCount }}
                <span class="text-sm">Unique Reader Orgs</span>
              </div>
              <Divider layout="vertical" class="py-0 my-0 px-1" />
              <div class="text-xl">
                {{ stats?.usersCount }}
                <span class="text-sm">Unique Readers</span>
              </div>
              <Divider layout="vertical" class="py-0 my-0 px-1" />
              <div class="text-xl">
                {{ stats?.activitiesCount }}
                <span class="text-sm">Engagements</span>
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>
    <div class="flex mt-6">
      <div class="col-5">
        <cp-most-active-cos-by-type-chart :data="chartData" />
      </div>
      <div class="col-7">
        <div class="flex justify-content-between">
          <h2>Top Engaging Reader Orgs</h2>
          <Button type="button" label="Toggle" class="p-button-rounded py-2 px-3" :disabled="readerOrgs.length === 0"
            @click="downloadReport">
            <img alt="logo" :src="require('../../assets/icons/microsoft-excel.svg')" style="width: 1.2rem"
              class="text-xl">
            <span class="mx-2 text-white">Download</span>
          </Button>
        </div>
        <DataTable :value="readerOrgs" class="w-full mt-5" :paginator="false" :rows-per-page-options="[10, 20, 50]"
          :rows="10" :total-records="readerOrgs.length"
          paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
          current-page-report-template="Showing {first} to {last} of {totalRecords}">
          <Column field="orgName" header="Reader Org" style="width: 32%" sortable>
            <template #body="slotProps">
              <router-link :to="slotProps.data.getOrgProfileUrl" class="p-button-link opacity-100 capitalize">
                {{ slotProps.data.orgName }}
              </router-link>
            </template>
          </Column>
          <Column field="usersCount" class="text-center" style="width: 23%" sortable>
            <template #header>
              <div class="flex-1">
                <span class="">Unique Readers</span>
              </div>
            </template>
            <template #body="slotProps">
              {{ slotProps.data.usersCount }}
            </template>
          </Column>
          <Column field="activitiesCount" class="text-center" style="width: 20%" sortable>
            <template #header>
              <div class="flex-1">
                <span class="">Engagements</span>
              </div>
            </template>
            <template #body="slotProps">
              {{ slotProps.data.activitiesCount }}
            </template>
          </Column>
          <Column field="formatCompanyTypes" header="Org Type" style="width: 25%" sortable>
            <template #body="slotProps">
              {{ slotProps.data.formatCompanyTypes }}
            </template>
          </Column>
          <template #empty>
            <div class="flex align-items-center justify-content-center py-5">
              <ProgressSpinner v-if="inProgress" />
              <cp-no-datatable-records v-if="!inProgress" />
            </div>
          </template>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import { PropType, ref, watch } from 'vue';
import { FilterModel } from '@/models/search';
import mostActiveCosService from '@/services/mostActiveCosService';
import { MostActiveCosEngagements } from '@/models/analytics/mostActiveCosEngagements';
import { MostActiveCosStats } from '@/models/analytics/mostActiveCosStats';
import { mostActiveCosByTypeResult } from '@/models/analytics/mostActiveCosByCompanyTypeResult';
import MostActiveCosByCompanyType from './MostActiveCosByCompanyType.vue';
import NoDataTableRecordsComponent from '../common/NoDataTableRecordsComponent.vue';
import { ColumnInfo, ExcelSheet, downloadExcel } from '@/utils/excelUtils';

export default {

  components: {
    'cp-most-active-cos-by-type-chart': MostActiveCosByCompanyType,
    'cp-no-datatable-records': NoDataTableRecordsComponent
  },

  props: {
    filterModel: { type: Object as PropType<FilterModel> }
  },

  setup(props) {

    let readerOrgs = ref<MostActiveCosEngagements[]>([]);
    let stats = ref<MostActiveCosStats>();
    let chartData = ref<mostActiveCosByTypeResult>();
    const inProgress = ref(false);

    const fetchData = async (filterModel) => {
      inProgress.value = true;
      readerOrgs.value = await mostActiveCosService.getReaderOrgEngagements(filterModel);
      stats.value = await mostActiveCosService.getStats(filterModel);
      chartData.value = await mostActiveCosService.getMostActiveCosByOrgType(filterModel);
      inProgress.value = false;
    };

    watch(props, async () => {
      await fetchData(props.filterModel);
    });

    const downloadReport = () => {
      const columnInfo: ColumnInfo<MostActiveCosEngagements> = {
        'Reader Org': { width: 50, propertyFn: (row) => row.orgName },
        'Unique Readers': { width: 16, propertyFn: (row) => row.usersCount },
        'Engagements': { width: 16, propertyFn: (row) => row.activitiesCount },
        'Org Type': { width: 50, propertyFn: (row) => row.formatCompanyTypes },
      };

      const sheet: ExcelSheet<MostActiveCosEngagements> = {
        filename: 'Most Active Companies Report.xlsx',
        data: readerOrgs.value,
        columnInfo
      };
      
      downloadExcel(sheet);
    };

    return {
      readerOrgs, stats, downloadReport, chartData, inProgress
    };
  }

};
</script>

<style lang="scss" scoped>
#summary {
  cursor: text;

  &.p-card.p-alerts-user-card {
    background-color: #F0F7FF;
  }
}
</style>
