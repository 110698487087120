import {IntLookup, Lookup} from '@/models/lookup';
import {FilterModel} from '@/models/search';
import {getIcon, getIconTooltip} from '../activityReportConfig';
import {ActivityReportType, ActivityReportTypeString} from '../activityReportType';
import {SEND_DAYS} from '../sendDays';

export class ActivityReportDashboardRow {
  id: string;
  userId: string;
  reportType: ActivityReportTypeString;
  aggregateSubsequent: boolean;
  sasUrl: string;
  sendDays: string[];
  runOn: Date | string;
  filterModel: FilterModel;
  activityTypes: string[];
  activityReportConfigId: string;
  startDate?: Date;
  endDate?: Date;
  recordsCount: number;
  companies: IntLookup[];
  onlines: Array<Lookup<string>>;

  constructor(props: Partial<ActivityReportDashboardRow>) {
    Object.assign(this, props);
    if (typeof props.runOn === 'string') {
      const a = /\/Date\((\d*)\)\//.exec(props.runOn);
      if (a) {
        this.runOn = new Date(+a[1]);
      }
    }
    
    this.companies = this.companies || [];
    this.onlines = this.onlines || [];
    this.activityTypes = this.activityTypes || this.reportTypeEnum === ActivityReportType.Individual
      ? ['Customer Requests'] 
      : this.filterModel.indices.map(x => x.name);
  }
  
  get reportTypeEnum(): ActivityReportType {
    return ActivityReportType[this.reportType];  
  }

  get editUrl(): string {
    return `/dissemination-contact/${this.activityReportConfigId}/1`;
  }

  get detailsUrl(): string {
    return `/dissemination-contact/dashboard/${this.activityReportConfigId}/${this.id}`;
  }

  get icon(): string {
    return getIcon(this.reportTypeEnum, this.aggregateSubsequent);
  }

  get iconTooltip(): string {
    return getIconTooltip(this.reportTypeEnum, this.aggregateSubsequent);
  }
}

