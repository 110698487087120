import { NumericDisplayType, NumericFilter, TermsControlType, TermsFilter, TextFilter } from '@/models/filters';
import FilterLookupsSvc from '@/services/filterLookupsService';
import { SearchFieldMetaData, SearchFieldProp } from '.';

const DEFAULT_ORG_SEARCHFIELD_PROPS: SearchFieldProp = {
  loadOptionsOnce: true,
  showOps: true,
  multiValue: true,
  controlType: TermsControlType.Chips
};

export const FIRMOGRAPHIC_FIELD_METADATA: Array<SearchFieldMetaData | SearchFieldMetaData[]> = [
  {
    model: new TermsFilter({ field: 'ReaderOrgs' }),
    props: { showOps: true, loadOptionsOnce: false, optionsGetter: FilterLookupsSvc.suggestOrgs }
  },
  {
    model: new TextFilter({ field: 'ReaderCompany' }),
    props: { multiValue: true }
  },

  {
    model: new TermsFilter({ field: 'Revenue' }),
    props: { questionId: 183, ...DEFAULT_ORG_SEARCHFIELD_PROPS }
  },
  {
    model: new TermsFilter({ field: 'ProductCategory' }),
    props: { questionId: 84, ...DEFAULT_ORG_SEARCHFIELD_PROPS }
  },
  {
    model: [new TermsFilter({ field: 'OrgType' })],
    props: { questionId: 86, ...DEFAULT_ORG_SEARCHFIELD_PROPS, isCompound: true }
  }
];

export const FIRMOGRAPHIC_MED_DEVICES_FIELD_METADATA: Array<SearchFieldMetaData | SearchFieldMetaData[]> = [
  {
    model: new TermsFilter({ field: 'DeviceTherapeuticArea', value: [] }),
    props: { questionId: 217, ...DEFAULT_ORG_SEARCHFIELD_PROPS }
  },
  {
    model: new TextFilter({ field: 'DeviceName' }),
    props: { questionId: 214, ...DEFAULT_ORG_SEARCHFIELD_PROPS }
  },
  {
    model: new TextFilter({ field: 'DeviceApplications' }),
    props: { questionId: 220, ...DEFAULT_ORG_SEARCHFIELD_PROPS }
  },
  {
    model: new TextFilter({ field: 'DeviceIndications' }),
    props: { questionId: 219, ...DEFAULT_ORG_SEARCHFIELD_PROPS }
  },
  {
    model: new TermsFilter({ field: 'DeviceClass', value: [] }),
    props: { questionId: 222, ...DEFAULT_ORG_SEARCHFIELD_PROPS }

  },
  {
    model: new TextFilter({ field: 'DeviceRegulatoryPath' }),
    props: { questionId: 214, ...DEFAULT_ORG_SEARCHFIELD_PROPS }
  },
  {
    model: new TermsFilter({ field: 'DeviceStageOfDevelopment', value: [] }),
    props: { questionId: 215, ...DEFAULT_ORG_SEARCHFIELD_PROPS }
  },
  {

    model: new TextFilter({ field: 'DeviceEquipmentType' }),
    props: { questionId: 216, ...DEFAULT_ORG_SEARCHFIELD_PROPS }
  },
  {

    model: new TextFilter({ field: 'DeviceApprovedTerritories' }),
    props: { questionId: 218, ...DEFAULT_ORG_SEARCHFIELD_PROPS }
  },
  {

    model: new TextFilter({ field: 'CompanyDivisionName' }),
    props: { questionId: 213, ...DEFAULT_ORG_SEARCHFIELD_PROPS }
  }
];

export const FIRMOGRAPHIC_LIFE_SCIENCES_FIELD_METADATA: Array<SearchFieldMetaData | SearchFieldMetaData[]> = [
  {
    model: new TermsFilter({ field: 'DevelopmentStages', value: [] }),
    props: { questionId: 81, ...DEFAULT_ORG_SEARCHFIELD_PROPS }
  },
  {
    model: new TermsFilter({ field: 'TherapeuticArea', value: [] }),
    props: { questionId: 82, ...DEFAULT_ORG_SEARCHFIELD_PROPS }
  },
  {
    model: new TermsFilter({ field: 'EntityType', value: [] }),
    props: { questionId: 203, ...DEFAULT_ORG_SEARCHFIELD_PROPS }
  },
  {
    model: new TermsFilter({ field: 'DrugType', value: [] }),
    props: { questionId: 204, ...DEFAULT_ORG_SEARCHFIELD_PROPS }
  },
  {
    model: new TermsFilter({ field: 'DrugPipeline', value: [] }),
    props: { questionId: 88, ...DEFAULT_ORG_SEARCHFIELD_PROPS }
  },
  {
    model: new TermsFilter({ field: 'ApplicationType', value: [] }),
    props: { questionId: 205, ...DEFAULT_ORG_SEARCHFIELD_PROPS }
  },
  {
    model: new TermsFilter({ field: 'DesignationType', value: [] }),
    props: { questionId: 206, ...DEFAULT_ORG_SEARCHFIELD_PROPS }
  },
  {
    model: new TermsFilter({ field: 'DesignationStatus', value: [] }),
    props: { questionId: 207, ...DEFAULT_ORG_SEARCHFIELD_PROPS }
  },
  {
    model: new TermsFilter({ field: 'ReviewGeography', value: [] }),
    props: { questionId: 208, ...DEFAULT_ORG_SEARCHFIELD_PROPS }
  },
  {
    model: new TermsFilter({ field: 'DrugDescriptor', value: [] }),
    props: { questionId: 209, ...DEFAULT_ORG_SEARCHFIELD_PROPS }
  },
  {
    model: new TermsFilter({ field: 'RouteOfAdministration', value: [] }),
    props: { questionId: 210, ...DEFAULT_ORG_SEARCHFIELD_PROPS }
  },
  {
    model: new TermsFilter({ field: 'MoleculeType', value: [] }),
    props: { questionId: 211, ...DEFAULT_ORG_SEARCHFIELD_PROPS }
  },
  {
    model: new TermsFilter({ field: 'HighestDevelopmentStage', value: [] }),
    props: { questionId: 212, ...DEFAULT_ORG_SEARCHFIELD_PROPS }
  },
  {
    model: new NumericFilter({ field: 'CapitalRaisedPastThreeYears', displayType: NumericDisplayType.Currency }),
    props: { questionId: 223 }
  },
  {
    model: new TermsFilter({ field: 'GeneTherapyVector', value: [] }),
    props: { questionId: 224, ...DEFAULT_ORG_SEARCHFIELD_PROPS }
  },
  {
    model: new NumericFilter({ field: 'CompanyDrugCount' }),
    props: { questionId: 225 }
  },
  {
    model: new TermsFilter({ field: 'ExpressionSystems', value: [] }),
    props: { questionId: 226, ...DEFAULT_ORG_SEARCHFIELD_PROPS }
  },
  {
    model: new TermsFilter({ field: 'Containment', value: [] }),
    props: { questionId: 227, ...DEFAULT_ORG_SEARCHFIELD_PROPS }
  },
  {
    model: new TermsFilter({ field: 'DrugRelease', value: [] }),
    props: { questionId: 228, ...DEFAULT_ORG_SEARCHFIELD_PROPS }
  },
  {
    model: new TermsFilter({ field: 'ControlledSubstance', value: [] }),
    props: { questionId: 229, ...DEFAULT_ORG_SEARCHFIELD_PROPS }
  },
  {
    model: new TermsFilter({ field: 'DrugFormulation', value: [] }),
    props: { questionId: 230, ...DEFAULT_ORG_SEARCHFIELD_PROPS }
  },
  {
    model: new TermsFilter({ field: 'Packaging', value: [] }),
    props: { questionId: 231, ...DEFAULT_ORG_SEARCHFIELD_PROPS }
  },
  {
    model: new TermsFilter({ field: 'Solubility', value: [] }),
    props: { questionId: 232, ...DEFAULT_ORG_SEARCHFIELD_PROPS }
  },
  {
    model: new TermsFilter({ field: 'ApiSource', value: [] }),
    props: { questionId: 233, ...DEFAULT_ORG_SEARCHFIELD_PROPS }
  },
  {
    model: new TermsFilter({ field: 'DosageSource', value: [] }),
    props: { questionId: 234, ...DEFAULT_ORG_SEARCHFIELD_PROPS }
  },
  {
    model: new TermsFilter({ field: 'PackagingSource', value: [] }),
    props: { questionId: 235, ...DEFAULT_ORG_SEARCHFIELD_PROPS }
  }
];
