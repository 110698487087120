import axios from 'axios';
import { BasicOnboardingFormData } from '@/models/onboarding/basicOnboardingFormData';
import { OnBoardingSearchRequest } from '@/models/onboarding/onBoardingSearchRequest';
import { OnBoardingSearchResults } from '@/models/onboarding/onBoardingSearchResults';
import { PagedList } from '@/models/pagedList';
import { onError, onSuccess} from '@/utils/toast/toastUtils';
import {OnBoardingFormApiResponse} from '@/models/onboarding/onboardingFormApiResponse';
import {ToastServiceMethods} from 'primevue/toastservice';

 class OnBoardingFormService {
  generateOnboardingForm(data: BasicOnboardingFormData) {
    return axios.post('/api/onboarding', data);
  }

   async search(request: OnBoardingSearchRequest) : Promise<PagedList<OnBoardingSearchResults>> {
    //const req = <any>cloneDeep(request);
    const response = await axios.post(`/api/manage-onboarding/list`, request);
    return response.data;
  }

     async getOnboardingForm(id: string): Promise<OnBoardingFormApiResponse> {
         const r = await axios.get(`/api/manage-onboarding/`+ id);
         return r.data;
     }

     async getOnboardingFormStatus(id: string): Promise<any> {
         const r = await axios.get(`/api/onboarding/`+ id);
         return r.data;
     }

     async deleteContent(formId, contentId) {
      await axios.delete(`/api/onboarding/${formId}/content/${contentId}`);
     }
     async resendOnBoardingForm(id: string, toast:ToastServiceMethods): Promise<any> {
         try {
             await axios.get(`/api/manage-onboarding/${id}/resend-onboarding-form`);
             onSuccess(toast,'Form resent successfully');
         }
         catch (err) {
             onError( toast, 'An error was encountered when submitting the form');
             throw (err);
         }
     }
}

export default new OnBoardingFormService();
