<template>
  <div class="grid">
    <div class="col-12 pb-0">
      <div class="flex">
        <h1 class="line-height-1">
          Content Overview
        </h1>
      </div>
      <span class="p-secondary-color text-xs">{{ dateRange }}</span>
    </div>
    <div class="col-12 lg:col-4 lg:pr-4">
      <cp-doc-count-card :filters="filtersWithoutDate" text="Unique documents posted in our network"
        @update-doc-count="updateTotalDocCount" />
    </div>
    <div class="col-12 lg:col-4 lg:pr-4">
      <cp-docs-with-engagements :doc-count="totalDocCount" :filter-model="filterModel"
        text="Active documents with engagements" />
    </div>
    <div class="col-12 lg:col-4 lg:pr-4">
      <cp-doc-count-card :filters="filterModel.filters" icon="add_comment" text="New documents added" />
    </div>
    <div class="col-12 lg:col-6">
      <cp-doc-count-percent-chart segment="type" label="Content By Document Type" :filters="filterModel.filters" />
    </div>
    <div class="col-12 lg:col-6">
      <cp-doc-count-percent-chart segment="buyers-journey" label="Content By Buyer's Journey"
        :filters="filterModel.filters" />
    </div>

    <div class="col-12 lg:col-6">
      <cp-buyers-journey-by-document-age segment="buyers journey" :filter-model="filterModel"
        label="Buyer's Journey of Content By Document Age" />
    </div>
    <div class="col-12 lg:col-6">
      <cp-top-performing-count-by-journey :filter-model="filterModel" stage="Late" />
    </div>
    <div class="col-12 lg:pl-0">
      <cp-most-popular-docs :filter-model="filterModel" series-field="contentType"
        title="Top Performing Content By Reader Engagement" />
    </div>
    <Suspense>
      <cp-filter v-model="filterModel" :show="showFilter" :include-indices="true" @filter-submitted="filterSubmitted"
        @close-panel="closePanel" />
    </Suspense>
    <div class="filter-menu-wrapper">
      <a class="filter-menu" @click.stop="showFilter = !showFilter">
        <img :src="require('../../assets/icons/dashboard-filters.svg')" alt="Templeton Logo" width="52">
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import DocCountCard from '@/components/content-dashboard/DocCountCard.vue';
import { mapGetters } from 'vuex';
import DocsWithEngagements from '@/components/content-dashboard/DocsWithEngagements.vue';
import DocCountPercentChart from '@/components/content-dashboard/DocCountPercentChart.vue';
import TopPerformingByBuyersJourney from '@/components/content-dashboard/TopPerformingByBuyersJourney.vue';
import BuyersJourneyByDocumentAge from '@/components/content-dashboard/BuyersJourneyByDocumentAge.vue';
import MostPopularDocs from '@/components/client-dashboard/MostPopularDocs.vue';
import { FieldCode } from '@/models/filters';
import { filterValueExists, FilterProp, dateRangeText } from '@/utils/filters';
import { FilterableDashboard } from '@/models/analytics/filterableDashboard.interface';
import { FilterModel } from '@/models/search';
import { cloneDeep } from 'lodash';
import { required } from '@/utils/validators/validators';
import { DefaultSearchMixin } from '@/mixins/defaultSearch.mixin';
import { mixins, Options } from 'vue-class-component';
import emitter from '@/services/emitter';
import NoChartDataComponent from '@/components/common/NoChartDataComponent.vue';
import { defineAsyncComponent } from 'vue';
import { Watch } from 'vue-property-decorator';

const DashboardFilter = defineAsyncComponent(() => import('@/components/client-dashboard/DashboardFilter.vue'));

@Options({
  computed: {
    ...mapGetters('auth', ['userCompanies']),
    ...mapGetters('search', ['defaultFilters', 'defaultIndices']),
  },
  components: {
    'cp-filter': DashboardFilter,
    'cp-doc-count-card': DocCountCard,
    'cp-docs-with-engagements': DocsWithEngagements,
    'cp-doc-count-percent-chart': DocCountPercentChart,
    'cp-no-data': NoChartDataComponent,
    'cp-most-popular-docs': MostPopularDocs,
    'cp-top-performing-count-by-journey': TopPerformingByBuyersJourney,
    'cp-buyers-journey-by-document-age': BuyersJourneyByDocumentAge,
  },
})
export default class ContentOverviewDashboard
  extends mixins(DefaultSearchMixin)
  implements FilterableDashboard {
  props?: FilterProp[] | undefined;
  hasNoData: boolean = false;
  filterModel: FilterModel = { filters: [], indices: [] };
  declare initialState: FilterModel;
  rules: Partial<Record<FieldCode, Array<(val: string) => any>>> = {
    Clients: [required('Companies')],
    DateRange: [required('From Date')],
  };
  totalDocCount = 0;
  showFilter = false;

  async mounted() {
    emitter.on('nodata', ($event) => {
      this.hasNoData = $event as boolean;
    });
  }

  @Watch('defaultsLoaded', { immediate: true })
  async defaultsLoadedDone(val: boolean) {
    if (val) {
      this.filterModel = cloneDeep(this.initialState);
      if (!filterValueExists(this.filterModel.filters, 'Clients')) {
        this.showFilter = true;
      }
    }
  }

  filterSubmitted(filterModel: FilterModel) {
    this.filterModel = cloneDeep(filterModel);
  }

  get filtersWithoutDate() {
    return this.filterModel.filters.filter((x) => x.field !== 'DateRange');
  }

  updateTotalDocCount(count: number) {
    this.totalDocCount = count;
  }

  get dateRange() {
    return dateRangeText(this.filterModel.filters);
  }

  closePanel() {
    this.showFilter = false;
  }
}
</script>

<style lang="scss">
.p-card.no-results-card {
  color: #c1c8d1;
  background: white !important;
  box-shadow: 0px 4px #cfdaea;
  max-width: 400px;
  border-width: thin;
  border-style: solid;
  height: 140px;
}
</style>
