<template>
  <Sidebar :visible="show" position="full" :show-close-icon="false">
    <ScrollPanel id="customScroll" style="width: 100%; height: 100%">
      <form @submit.prevent="emitFilterChange">
        <div class="flex px-0 mb-3 py-3 sticky-header">
          <div class="col-6">
            <h2 class="text-2xl text-white pl-5">
              Dashboard Filters
            </h2>
          </div>
          <div class="col-6 flex flex-row-reverse align-items-center">
            <span id="closeButton" class="ml-6 cursor-pointer" @click="closePanel">
              <span class="material-icons text-white text-4xl mr-4 "> close </span>
            </span>
            <Button type="submit" class="p-button-success p-button-rounded p-button-raised ml-5 px-4"
              label="See results" />
            <Button type="button" class="p-button-outlined p-button-rounded bg-white" label="Reset" @click="reset" />
          </div>
        </div>
        <div class="grid pb-4">
          <div class="col-10 col-offset-1">
            <Card class="card-active p-3 pb-0">
              <template #content>
                <div class="grid px-2 pb-2">
                  <div class="col-6 p-fluid pb-3">
                    <cp-date-input v-model="dateRange.from" label="From Date" :submitted="submitted"
                      :v="v$.dateRange.from" />
                  </div>
                  <div class="col-6 p-fluid pb-3">
                    <cp-date-input v-model="dateRange.to" label="To Date" :submitted="submitted" :v="v$.dateRange.to" />
                  </div>
                  <small
                    v-if="((v$?.dateRange.from?.required != null) && v$.dateRange.from.required.$invalid && submitted) || v$.$pending"
                    class="col-12 p-error">
                    {{ v$.dateRange.from.required.$message.replace("Value", "From Date") }}
                  </small>
                  <div class="col-12 p-fluid pb-3">
                    <span class="p-float-label">
                      <AutoComplete v-model="selectedIndices" :multiple="true" :suggestions="filteredIndices"
                        :dropdown="true" :complete-on-focus="true" field="name" @complete="searchActivityTypes($event)"
                        @focus="searchActivityTypes($event)" />
                      <label for="activityTypes">Activity Types</label>
                    </span>
                  </div>
                  <div class="col-6 pb-3">
                    <span class="p-float-label p-fluid">
                      <Dropdown v-model="status" :options="reviewStatusesBySequenceSelection" option-label="name"
                        option-value="id" placeholder="Select Filter by Status" />
                      <label for="filterByStatus">Filter by Status</label>
                    </span>
                  </div>
                  <div class="col-6 grid pt-3 pb-0 vertical-align-middle">
                    <div class="ml-2 mr-5">
                      <RadioButton v-model="secondaryOnly" name="Initial" :value="false" />
                      <label for="Initial" class="text-sm font-bold mx-2">Initial</label>
                    </div>
                    <div class="mr-5">
                      <RadioButton v-model="secondaryOnly" name="Subsequent" :value="true" />
                      <label for="Subsequent" class="text-sm font-bold mx-2">Subsequent</label>
                    </div>
                  </div>
                  <div class="col-12 p-fluid pb-3">
                    <span class="p-float-label">
                      <AutoComplete v-model="selectedOnlines" :multiple="true" :suggestions="filteredOnlines"
                        field="name" :dropdown="true" :complete-on-focus="true"
                        @complete="searchOnlines($event.query)" />
                      <label for="Communities">Communities</label>
                    </span>
                  </div>
                  <div class="col-12 p-fluid pb-3">
                    <span class="p-float-label">
                      <AutoComplete v-model="selectedCompanies" :multiple="true" :suggestions="filteredCompanies"
                        field="name" @complete="searchCompanies($event.query)" />
                      <label for="Clients">Clients</label>
                    </span>
                  </div>
                  <div class="col-6 p-fluid pb-3">
                    <span class="p-float-label">
                      <Dropdown v-model="selectedActivitySource" :options="activitySources"
                        placeholder="Select activity source" />
                      <label for="activitySource">Activity Source</label>
                    </span>
                  </div>
                  <div class="col-6 p-fluid pb-3">
                    <span class="p-float-label">
                      <AutoComplete id="quality" v-model="selectedQualities" :multiple="true"
                        :suggestions="filteredQualities" field="name" :dropdown="true" :complete-on-focus="true"
                        @complete="searchQualities($event)" @focus="searchQualities($event)"
                        @item-select="triggerQualityField('quality')" />

                      <label for="quality">Quality</label>
                    </span>
                  </div>
                  <div class="col-2 p-fluid pb-3">
                    <span class="p-float-label">
                      <Dropdown v-model="comparedFields.email.op" :options="operations" option-label="name"
                        option-value="id" />
                      <label for="activitySource">Operation</label>
                    </span>
                  </div>
                  <div class="col-10 p-fluid pb-3">
                    <span class="p-float-label">
                      <InputText id="email" v-model="comparedFields.email.value" type="text" />
                      <label for="email">Email</label>
                    </span>
                  </div>
                </div>
              </template>
            </Card>
          </div>
        </div>
      </form>
    </ScrollPanel>
  </Sidebar>
</template>

<script lang="ts">
import { Lookup, Online } from '@/models/lookup';
import dayjs from 'dayjs';
import { required } from '@/utils/validators/validators';
import DateInputVue from '@/components/common/DateInput.vue';

import { ActivitySource, ACTIVITY_TYPE_LOOKUPS, QUALITY_LOOKUPS } from '@/models/activity-dissemination';

import {
  FieldComparison,
  InclusionCondition,
} from '@/models/search';
import { ActivityReviewStatus, ACTIVITY_REVIEW_STATUS_LOOKUPS } from '@/models/activity-review';
import { FIELD_OP_KEYWORD_LIST_ITEMS } from '@/models/search/fieldOperation';
import { DateRangeFilterValue } from '@/models/filters';
import { Claim, CompanyClaim } from '@/models/security';
import { useStore } from 'vuex';
import { computed, onMounted, ref } from 'vue';
import useCompanySecurity from '@/use/companySecurity';
import useOnlineSecurity from '@/use/onlineSecurity';
import useVuelidate from '@vuelidate/core';
import useActivityTypeSecurity from '@/use/activityTypeSecurity';
import { DEFAULT_TIMEOUT_TIME } from '@/models/common/constants';

export default {
  name: 'CpReviewActivityFilter',
  components: {
    'cp-date-input': DateInputVue
  },
  props: {
    show: { type: Boolean },
    showActivityFieldsFilter: { type: Boolean },
    submitted: Boolean,
  },
  emits: ['filter-changed', 'close-panel', 'input'],
  setup(props, { emit }) {
    const operations = FIELD_OP_KEYWORD_LIST_ITEMS.filter(x => ['Equals', 'Contains'].includes(x.name));
    const comparedFields = ref({
      email: new FieldComparison('Email', 'Equals')
    });
    const inclusionCondition = InclusionCondition.All;
    const selectedIndices = ref<Array<Lookup<string>>>([]);
    const filteredIndices = ref<Array<Lookup<string>>>([]);
    const selectedCompanies = ref<CompanyClaim[]>([]);
    const selectedOnlines = ref<Online[]>([]);

    const activitySources = [ActivitySource.NL, ActivitySource.Web];
    const qualities = QUALITY_LOOKUPS;

    const selectedActivitySource = ref<any>(null);
    const selectedQualities = ref<Array<Lookup<any>>>([]);
    const filteredQualities = ref<Array<Lookup<any>>>([]);
    const defaultFromDate = dayjs().subtract(7, 'day').toDate();
    const dateRange = ref<DateRangeFilterValue>({ from: defaultFromDate, to: new Date() });
    const status = ref<ActivityReviewStatus>('Pending');
    const secondaryOnly = ref(false);
    const reviewStatuses = ACTIVITY_REVIEW_STATUS_LOOKUPS;
    const activityTypesOptions = ACTIVITY_TYPE_LOOKUPS;
    const filteredCompanies = ref<CompanyClaim[]>([]);

    const atLeastInternal: Claim = { name: 'role', value: 'Internal' };

    // Vuex Store
    const store = useStore();
    const satisfiesClaim = store.getters['auth/satisfiesClaim'];
    const userCompanies = store.state.auth.user.companies;
    const basicActivityTypesOnly = ref(true);

    const { data: companyData, getCompanyData } = useCompanySecurity(satisfiesClaim(atLeastInternal), userCompanies, selectedCompanies);
    const { data: filteredOnlines, getData: getOnlineData, getOptions: getOnlineOptions } = useOnlineSecurity(selectedOnlines);
    const { searchActivityTypes } = useActivityTypeSecurity(selectedIndices, filteredIndices, basicActivityTypesOnly);

    const rules = computed(() => {
      const validationModel = {
        dateRange: {
          from: {
            required,
            minValue: val => {
              const result = new Date(val) <= new Date();
              // console.debug(`DateRangeSearchField.rules.dateRange.from.minValue() - { val, result }`, val, result);
              return result;
            },
            maxValue: (val, { to }) => {
              if (!to) return true;
              const result = new Date(to) >= new Date(val);
              // console.debug(`DateRangeSearchField.rules.dateRange.from.maxValue() - { val, to, result }`, val, to, result);
              return result;
            }
          },
          to: {
            minValue: (val, { from }) => {
              if (!val) return true;
              const result = new Date(val) >= new Date(from);
              // console.debug(`DateRangeSearchField.rules.dateRange.to.minValue() - { val, from, result }`, val, from, result);
              return result;
            },
          }
        }
      };

      return validationModel;
    });
    const v$ = useVuelidate(rules, { dateRange });

    const reviewStatusesBySequenceSelection = computed(() =>
      secondaryOnly.value === true ?
        reviewStatuses :
        reviewStatuses.filter(x => x.id !== 'RejectedWithPrimary')
    );

    const emitFilterChange = async () => {
      const isValid = (await v$?.value.$validate()) ?? true;
      // console.debug(`ActivityFieldsFilter.emitFilterChange() - { isValid, v$ }`, isValid, v$.value);
      if (!isValid) {
        return;
      }
      emit('filter-changed', {
        comparedFields: comparedFields.value,
        fromDate: dateRange.value.from,
        toDate: dateRange.value.to,
        onlines: selectedOnlines.value.map(m => m.id),
        companyIds: selectedCompanies.value.map(m => m.id),
        activitySource: selectedActivitySource.value,
        inclusionCondition: inclusionCondition,
        status: status.value,
        secondaryOnly: secondaryOnly.value === true,
        activityTypes: selectedIndices.value.map(m => m.id),
        qualities: selectedQualities.value.map(m => m.id)
      });
      emit('close-panel', false);
      emit('input', false);
    };

    const reset = () => {
      selectedOnlines.value = [];
      selectedCompanies.value = [];
      selectedActivitySource.value = null;
      dateRange.value.from = defaultFromDate;
      dateRange.value.to = new Date();
      comparedFields.value = {
        email: new FieldComparison('Email', 'Equals')
      };
      status.value = 'Pending';
      secondaryOnly.value = false;
      emitFilterChange();
    };

    const searchCompanies = async (q: string) => {
      await getCompanyData(q);
      filteredCompanies.value = [...companyData.value];
      // console.debug(`ActivityFieldsFilter.searchCompanies() - { filterCompanies, selectedCompanies }`, q, filteredCompanies.value, selectedCompanies.value);
      // console.debug(`ActivityFieldsFilter.searchCompanies() - useVuelidate() - companies`, props.v);
    };

    const searchOnlines = async (q: string) => {
      await getOnlineData(q);
    };

    onMounted(async () => {
      getOnlineOptions();
    });

    const closePanel = () => {
      emit('close-panel', false);
    };

    const searchQualities = (event) => {
      setTimeout(() => {
        if (!event.query?.trim().length) {
          filteredQualities.value = qualities.filter((e1) => selectedQualities.value.findIndex((e2) => e2.name == e1.name) === -1);
        } else {
          const notSelected = qualities.filter((e1) => selectedQualities.value.findIndex((e2) => e2.name == e1.name) === -1);

          filteredQualities.value = notSelected.filter((type) => type.name.toLowerCase().includes(event.query.toLowerCase()));
        }
      }, DEFAULT_TIMEOUT_TIME);
    };

    const triggerQualityField = (id) => {
      document.getElementById(id)?.getElementsByTagName('input')[0].focus();
    };

    return {
      operations
      , comparedFields
      , activitySources
      , selectedCompanies
      , selectedActivitySource
      , selectedOnlines
      , dateRange
      , status
      , secondaryOnly
      , reviewStatusesBySequenceSelection
      , reviewStatuses
      , activityTypesOptions
      , selectedIndices
      , filteredOnlines
      , filteredCompanies
      , filteredIndices
      , closePanel
      , emitFilterChange
      , reset
      , searchCompanies
      , searchOnlines
      , searchActivityTypes
      , selectedQualities
      , filteredQualities
      , searchQualities
      , triggerQualityField
      , v$
    };
  }
};
</script>

<style scoped lang="scss">
.range-seperator {
  font-size: 25px;
  line-height: 70px;
}

.op-select {
  max-width: 100px;
}

.scrollable {
  height: calc(100vh - 130px);
  overflow-y: scroll;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #1b3a63;
}
</style>
