import { DisabledReason } from './../models/notification/disabledReasons';
import { NotificationFrequencyRequest } from './../models/notification/frequency/notificationFrequencyRequest';
import { NotificationBehaviorChangeRequest } from '@/models/notification/behavior-change';
import { SetNameRequest } from '@/models/common/setNameRequest';
import { NotificationSearchRequest, NotificationSearchRow, NotificationCreateRequest, Notification } from '@/models/notification';
import { NotificationSetFiltersRequest } from '@/models/notification/notificationSetFiltersRequest';
import { PagedList } from '@/models/pagedList';
import axios from 'axios';
import { SetTargetRequest } from '@/models/notification/alert-method/setTargetRequest';
import { NotificationResponse } from '@/models/notification/notificationResponse';

class NotificationService {
  private baseUrl: string = '/api/notification';

  async get(id: string): Promise<Notification> {
    const r = await axios.get<NotificationResponse>(`${this.baseUrl}/${id}`);
    return new Notification(r.data.notification);
  }

  async getDesc(id: string): Promise<string> {
    const r = await axios.get<string>(`${this.baseUrl}/${id}/description`, { responseType: 'text' as 'json' });
    return r.data;
  }

  async search(request: NotificationSearchRequest): Promise<PagedList<NotificationSearchRow>> {
    const r = await axios.post<PagedList<NotificationSearchRow>>(`${this.baseUrl}s/search`, request);
    r.data.items = r.data.items.map(x => new NotificationSearchRow(x));
    return r.data;
  }

  async create(data: NotificationCreateRequest): Promise<Notification> {
    const r = await axios.post<NotificationResponse>(this.baseUrl, data);
    return new Notification(r.data.notification);
  }

  async setName(id: string, data: SetNameRequest): Promise<Notification> {
    const r = await axios.put<NotificationResponse>(`${this.baseUrl}/${id}/name`, data);
    return new Notification(r.data.notification);
  }

  async setFilters(id: string, req: NotificationSetFiltersRequest): Promise<Notification> {
    const r = await axios.put<NotificationResponse>(`${this.baseUrl}/${id}/filters`, req);
    return new Notification(r.data.notification);
  }

  async setBehaviorChange(id: string, req: NotificationBehaviorChangeRequest): Promise<Notification> {
    const r = await axios.put<NotificationResponse>(`${this.baseUrl}/${id}/behavior-change`, { request: req });
    return new Notification(r.data.notification);
  }

  async setTargets(id: string, req: SetTargetRequest): Promise<Notification> {
    const r = await axios.put<NotificationResponse>(`${this.baseUrl}/${id}/targets`, req);
    return new Notification(r.data.notification);
  }

  async markAsCompleted(id: string): Promise<Notification> {
    const r = await axios.get<NotificationResponse>(`${this.baseUrl}/${id}/mark-as-completed`);
    return new Notification(r.data.notification);
  }

  async setFrequency(id: string, req: NotificationFrequencyRequest): Promise<Notification> {
    const r = await axios.put<NotificationResponse>(`${this.baseUrl}/${id}/frequency`, req);
    return new Notification(r.data.notification);
  }

  async remove(id: string) {
    await axios.delete(`${this.baseUrl}/${id}`);
  }

  async enable(id: string): Promise<DisabledReason> {
    const r = await axios.put<DisabledReason>(`${this.baseUrl}-enable/${id}`);
    return r.data;
  }
}

export default new NotificationService();


