import { IntLookup } from '../lookup';

export enum FieldOperation {
  Equals,
  Contains,
  Like,
  NotEquals,
  NotContains,
  NotLike
}

export const FIELD_OP_LIST_ITEMS: IntLookup[] = [
  { id: 0, name: 'Equals' },
  { id: 1, name: 'Contains' },
  { id: 2, name: 'Like' },
  { id: 3, name: 'Not Equals' },
  { id: 4, name: 'Not Contains' },
  { id: 5, name: 'Not Like' },
];

export const FIELD_OP_KEYWORD_LIST_ITEMS: IntLookup[] = [
  { id: 0, name: 'Equals' },
  { id: 1, name: 'Contains' },
  { id: 3, name: 'Not Equals' },
  { id: 4, name: 'Not Contains' },
];

export const FIELD_OP_CHIP_LIST_ITEMS: IntLookup[] = [
  { id: 0, name: 'Contains' },
  { id: 3, name: 'Not Contains' },
];
