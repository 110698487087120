import { FriendlyFilterRow } from './filterFactory';
import { Lookup } from '@/models/lookup';
import { Filter } from './filter';
import { FieldCode } from './fieldCode';
import { FilterType } from './filterType';
import { Operation, WithOperation, operationLabel } from './operation';
import { startCase } from 'lodash';
import { INDEX_LOOKUPS, SEQUENCE_LOOKUPS } from '@/models/activity-dissemination';

export class TermsFilter implements Filter, WithOperation {
  field: FieldCode;
  op: Operation;
  value: Array<Lookup<any>>;
  allowNA: boolean;
  readonly $type: FilterType = 'TermsFilter';

  constructor(opts: Partial<TermsFilter> = {}) {
    Object.assign(this, opts);
    this.value = this.value || [];
    this.$type = opts.$type || 'TermsFilter';
    this.op = opts.op || 'Contains';
  }

  isEmpty() {
    return !this.value?.length;
  }

  getFriendlyText(labelOverride: string): FriendlyFilterRow {
    const label = `${labelOverride || startCase(this.field)}`;
    const opStr = operationLabel[this.op];
    const value = this.value.length === 1
      ? `${this.value[0].name}`
      : `[${this.value.map(x => `'${x.name}'`).join(', ')}]`;

    // console.log(`TermsFilter - ${this.name} - Friendly Text: `, label, opStr, value);

    // return `${label} ${opStr} ${value}`;
    return <FriendlyFilterRow>{ filterName: label, operation: opStr, value };
  }

  clearValue() {
    this.value = [];
  }
}

export const DEFAULT_TERMS_FILTER: TermsFilter = new TermsFilter({ value: [], op: 'Contains' });
export const DEFAULT_ACTIVITY_TYPE_FILTER: TermsFilter = new TermsFilter({ field: 'ActivityTypes', value: INDEX_LOOKUPS });
export const DEFAULT_SEQUENCE_FILTER: TermsFilter = new TermsFilter({ field: 'Sequence', allowNA: true, value: SEQUENCE_LOOKUPS.filter(x => x.name === 'Initial') });
