import { CurrentUserState } from '@/store/models/currentUserState';
import { USER_MODULE } from '@/store/modules/user.module';
import { ref } from 'vue';
import { useStore } from 'vuex';

export default function useCurrentUser() {
  const store = useStore();
  const currentUser = ref<CurrentUserState>({ userId: '', email: '', firstName: '', lastName: '' });

  const getData = () => {
    if (!store.state.user) {
      store.dispatch(`user/${USER_MODULE.GET_CURRENT_USER}`);
    }

    currentUser.value = store.state.user;
  };

  getData();

  return { currentUser, getData };
}