import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_cp_animated_integer = _resolveComponent("cp-animated-integer")!
  const _component_cp_no_data_component = _resolveComponent("cp-no-data-component")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, {
    class: _normalizeClass(_ctx.value == 0 ? ['p-card no-results-card'] : 'p-count-card')
  }, {
    content: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_ProgressSpinner, {
            key: 0,
            class: "flex align-items-center justify-content-center"
          }))
        : _createCommentVNode("", true),
      _withDirectives(_createVNode(_component_cp_animated_integer, {
        value: _ctx.value,
        class: "company-count pt-4"
      }, null, 8, ["value"]), [
        [_vShow, _ctx.value !== 0 && _ctx.loading == false]
      ]),
      (_ctx.value === 0 && _ctx.loading == false)
        ? (_openBlock(), _createBlock(_component_cp_no_data_component, {
            key: 1,
            "chart-type": 'widget'
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.value !== 0 ? 'text-center pt-0' : 'sub-no-data')
      }, _toDisplayString(_ctx.subheading), 3)
    ]),
    _: 1
  }, 8, ["class"]))
}