import { Module } from 'vuex';
import { AppMessage } from '../models/AppMessage';

const DEFAULT_MSG: AppMessage = {
    text: undefined,
    timeout: 5000,
    type: 'success',
    visible: false
};

export const MSG_MUTATIONS = Object.freeze({
    MSG: 'setMessage',
    PROGRESS: 'progress'
});

export interface MessageModule {
    message: AppMessage;
    inProgressCount: number;
}

export const msgModule: Module<MessageModule, any> = {
    namespaced: true,
    state: {
        message: DEFAULT_MSG,
        inProgressCount: 0
    },
    mutations: {
        [MSG_MUTATIONS.MSG](state, msg: Partial<AppMessage>) {
            state.message = Object.assign({}, DEFAULT_MSG, msg);
            state.message.visible = true;
        },
        [MSG_MUTATIONS.PROGRESS](state, dir: 'up' | 'down') {
            if (dir === 'up') {
                state.inProgressCount++;
            } else {
                state.inProgressCount--;
            }
        }
    }
};
