import { ActivityType } from '../activity-review/activityType';
import { ActivityTypeBadge } from '../analytics/activityTypeBadge';
import { Badge } from '../analytics/badge';

export class ReaderRecentEngagement {
  date: Date;
  type: string;
  buyersJourney: string;
  title: string;
  online: string;
  docUrl: string;
  siteUrl: string;
  specifics?: string;
  activityType: ActivityType;
  readonly activityTypeBadge?: ActivityTypeBadge;
  readonly buyersJourneyBadge?: Badge;

  constructor(data: Partial<ReaderRecentEngagement> | null = null) {
    if (data) {
      Object.assign(this, data);
    }

    switch (this.activityType) {
      case 'Engagement':
        this.activityTypeBadge = { icon: require('../../assets/icons/engagement.png'), title: 'Engagement' };
        break;
      case 'CustomerRequest':
        this.activityTypeBadge = { icon: require('../../assets/icons/customer-request.png'), title: 'Customer Request' };
        break;
      case 'Webinar':
        this.activityTypeBadge = { icon: require('../../assets/icons/webinar.png'), title: 'Webinar' };
        break;
      case 'Event':
        this.activityTypeBadge = { icon: require('../../assets/icons/events.png'), title: 'Event' };
        break;
    }

    switch (this.buyersJourney) {
      case '951fe812-3bb2-45e1-86a4-bcce143115ff':
        this.buyersJourneyBadge = { icon: require('../../assets/icons/early.png'), title: 'Early' };
        break;
      case '2378a4d7-345c-4a78-8ca7-68d8999b18eb':
        this.buyersJourneyBadge = { icon: require('../../assets/icons/middle.png'), title: 'Middle' };
        break;
      case 'b935dd05-e194-497f-b9aa-20a54e7730d1':
        this.buyersJourneyBadge = { icon: require('../../assets/icons/late.png'), title: 'Late' };
        break;
    }
  }
}
