import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex p-fluid" }
const _hoisted_2 = { class: "col-12 p-0" }
const _hoisted_3 = { class: "p-float-label" }
const _hoisted_4 = { for: "terms-field" }
const _hoisted_5 = {
  key: 0,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _createVNode(_component_AutoComplete, {
          id: "terms-field",
          modelValue: $setup.selectedTermValues,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.selectedTermValues) = $event)),
          suggestions: $setup.filteredOptions,
          field: "name",
          multiple: true,
          class: _normalizeClass({ 'p-invalid': $setup.isAnalystTagInvalid }),
          onComplete: _cache[1] || (_cache[1] = ($event: any) => ($setup.onSearch($event.query))),
          onItemSelect: _cache[2] || (_cache[2] = ($event: any) => ($setup.updateField($event.value))),
          onItemUnselect: _cache[3] || (_cache[3] = ($event: any) => ($setup.deleteField($event.value)))
        }, null, 8, ["modelValue", "suggestions", "class"]),
        _createElementVNode("label", _hoisted_4, _toDisplayString($setup.label), 1)
      ]),
      ($setup.isAnalystTagInvalid || ($setup.v$ && $setup.v$.$pending.$response))
        ? (_openBlock(), _createElementBlock("small", _hoisted_5, _toDisplayString($setup.v$.required.$message.replace('Value', 'Analyst Tag')), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}