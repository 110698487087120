<template>
  <div class="grid">
    <div class="col-12 pb-0">
      <div class="flex">
        <h1 class="line-height-1">
          Engagements Search
        </h1>
      </div>
      <span class="p-secondary-color text-xs">{{ dateRange }}</span>
    </div>

    <div class="col-12 pt-0">
      <DataTable v-model:selection="selectedItems" v-model:filters="filters1" v-model:expandedRows="expandedRows"
        :value="data1.items" data-key="id" :paginator="true" :rows-per-page-options="[50, 100]" :rows="50" :lazy="true"
        :total-records="data1.total"
        paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
        current-page-report-template="Showing {first} to {last} of {totalRecords}" :auto-layout="true"
        @page="onPage($event)" @sort="onSort($event)">
        <template #header>
          <div class="flex">
            <div class="col-12 p-0 flex flex-row-reverse">
              <Button label="Print" :class="selectedItems.length > 0 ? 'p-button-link' : 'p-button-link p-disabled'"
                @click="printView()">
                <span class="material-icons text-xl"> print </span>
                <span class="ml-1 text-sm font-bold underline">Print</span>
              </Button>
              <Button type="button" label="Toggle" aria-haspopup="true" aria-controls="overlay_menu"
                class="p-button-rounded" :disabled="isNoData" @click="toggle">
                <span class="material-icons-outlined">
                  file_download
                </span>
                <span class="mx-2 text-white">Download</span>
                <span class="material-icons text-sm text-white">
                  arrow_drop_down
                </span>
              </Button>
              <Menu id="overlay_menu" ref="menu" style="width: 250px; z-index: 1102;" :model="downloadItems"
                :popup="true">
                <template #item="slotProps">
                  <div class="p-menuitem-link py-2 flex align-items-center"
                    :class="{ 'p-disabled': slotProps.item.disabled }">
                    <span class="flex mr-2" v-html="slotProps.item.icon" />
                    <span class="text-sm">{{ slotProps.item.label }}</span>
                  </div>
                </template>
              </Menu>
            </div>
          </div>
        </template>

        <Column selection-mode="multiple" style="width: 3%" />
        <Column field="date" header="Date" sortable style="width: 8%">
          <template #body="slotProps">
            {{ formatDate(slotProps.data.date) }}
          </template>
        </Column>
        <Column field="activityType" style="width: 5%" class="text-center vertical-align-middle">
          <template #header>
            <div class="flex-1">
              <span class="">Activity</span>
            </div>
          </template>
          <template #body="slotProps">
            <img v-tooltip="slotProps.data.activityTypeBadge.title" :src="slotProps.data.activityTypeBadge.icon"
              width="20" class="vertical-align-middle">
          </template>
        </Column>
        <Column field="quality" header="Quality" sortable style="width: 5%">
          <template #body="slotProps">
            <img :src="slotProps.data.qualityBadge?.icon" width="30" class="ml-2">
          </template>
        </Column>
        <Column field="firstName:lastName:email" header="Reader Name" sortable class="truncate-text" style="width: 12%">
          <template #body="slotProps">
            <router-link :title="slotProps.data.fullName" :to="`/analytics/reader-dashboard/${slotProps.data.emailUrl}`"
              class="p-button-link opacity-100 capitalize">
              {{ slotProps.data.fullName }}
            </router-link><br>
            <span class="text-xs capitalize" :title="slotProps.data.jobTitle">{{ slotProps.data.jobTitle }}</span>
          </template>
        </Column>
        <Column field="company.keyword" header="Reader Company" sortable class="truncate-text" style="width: 16%">
          <template #body="slotProps">
            <router-link :title="slotProps.data.company" :to="slotProps.data.readerCompanyUrl"
              class="p-button-link opacity-100 capitalize">
              {{
                slotProps.data.company
              }}
            </router-link>
          </template>
        </Column>
        <Column field="onlines" header="Community" sortable class="truncate-text" style="width: 15%">
          <template #body="slotProps">
            <span :title="slotProps.data.portal">{{ slotProps.data.portal }}</span>
          </template>
        </Column>
        <Column field="docTitle.keyword" header="Document" sortable class="truncate-text" style="width: 18%">
          <template #body="slotProps">
            <a :href="slotProps.data.portalUrl" :title="slotProps.data.docTitle" target="_blank"
              class="p-button-link opacity-100">{{ slotProps.data.docTitle }}</a>
          </template>
        </Column>
        <Column field="shares" class="text-center" style="width: 10%">
          <template #header>
            <div class="flex-1">
              <span class="">Sequence</span>
            </div>
          </template>
          <template #body="slotProps">
            {{ slotProps.data.sequenceName }}
          </template>
        </Column>
        <Column field="specificsIncluded" class="text-center" style="width: 5%">
          <template #header>
            <div class="flex-1">
              <span class="">Specifics</span>
            </div>
          </template>
          <template #body="slotProps">
            <div v-tooltip.top="'Click to view specifics'" class="cursor-pointer">
              <span v-if="slotProps.data.specificsModel || slotProps.data.specifics" class="material-icons-outlined"
                style="font-size: 22px;"
                @click="specificsClick(slotProps.data.specificsModel, slotProps.data.specifics)">
                description
              </span>
            </div>
          </template>
        </Column>
        <Column header="Details" :expander="true" style="width: 5%" />

        <template #expansion="slotProps">
          <cp-activity-list-item :activity-type="slotProps.data.activityType" :activity-id="slotProps.data.id"
            :quality="slotProps.data.qualityNum" />
        </template>

        <template #empty>
          <div class="flex align-items-center justify-content-center py-5">
            <ProgressSpinner v-if="inProgress" />
            <cp-no-datatable-records v-if="!inProgress" />
          </div>
        </template>
      </DataTable>
    </div>
    <Dialog id="dialogSpecificsDetails" v-model:visible="displayDialogSpecifics"
      :breakpoints="{ '960px': '100vw', '640px': '100vw' }" :style="{ width: '50vw' }" :modal="true" header="Specifics"
      :closable="false">
      <template #header>
        <div class="flex col-12 pb-0">
          <div class="col-11 pb-0">
            <h3 class="p-secondary-color cursor-pointer">
              Specifics
            </h3>
          </div>
          <div class="col-1 absolute right-0 pb-0 p-secondary-color">
            <i class="pi pi-times reverse p-secondary-color" aria-haspopup="true" aria-controls="overlay_menu"
              @click="dialogClose" />
          </div>
        </div>
      </template>
      <div class="col-12">
        <specifics-questions v-model="dSpecifics" :show-title="false" :specifics-freetext="dSpecificsFreetext" />
      </div>
    </Dialog>
    <cp-export-column-config v-model:visible="showExportColumns" :data1="userExportColumns"
      @columns-submitted="columnSubmitted" @close-sidebar="closeSidebar" />
    <Suspense>
      <cp-dashboard-filter v-model="criteria.filterModel" :show="showFilter" :include-indices="true"
        @filter-submitted="filterSubmitted" @close-panel="closePanel" />
    </Suspense>
    <div class="filter-menu-wrapper">
      <a class="filter-menu" @click.stop="showFilter = !showFilter">
        <img :src="require('../../assets/icons/dashboard-filters.svg')" alt="Filter Framework" width="52">
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { cloneDeep, isEqual } from 'lodash';
import dayjs from 'dayjs';
import { Watch } from 'vue-property-decorator';
import { mixins, Options } from 'vue-class-component';
import { FilterMatchMode } from 'primevue/api';

import { PageableFilterCriteria } from '@/models/search/pageableFilterCriteria';
import { PagedList } from '@/models/pagedList';
import { DEFAULT_PAGINATION } from '@/models/search/pagination';
import { EngagementSearchResultItem } from '@/models/search/engagementSearchResultItem';
import { SortOrder } from '@/models/sortOrder';
import { SEARCH_MODULE } from '@/store/modules/search.module';
import { CurrentSearchState } from '@/store/models/currentSearchState';
import SearchService from '@/services/searchService';
import ExportService from '@/services/exportService';
import { dateRangeText } from '@/utils/filters';
import { FieldCode } from '@/models/filters';
import { required } from '@/utils/validators/validators';
import { FilterModel } from '@/models/search/filterModel.interface';
import { AppUser } from '@/models/appUser';

import { DefaultSearchMixin } from '@/mixins/defaultSearch.mixin';
import ActivityListItem from '@/components/analytics/ActivityListItem.vue';
// import DashboardFilter from "@/components/client-dashboard/DashboardFilter.vue";
import ExportColumnConfig from '@/components/common/ExportColumnConfig.vue';
import DefaultColumnsService from '@/services/defaultColumnService';
import NoDataTableRecordsComponent from '@/components/common/NoDataTableRecordsComponent.vue';
import { event } from 'vue-gtag';
import { createApp, defineAsyncComponent } from 'vue';
import SpecificsQuestions from '@/components/analytics/SpecificsQuestions.vue';
import { Specifics } from '@/models/analytics/Specifics';
import { createJsPdf } from '@/utils/jsPdfUtils';
import jsPDF from 'jspdf';
import IndividualActivityReport from '@/templates/IndividualActivityReport.vue';

const DashboardFilter = defineAsyncComponent(() => import('@/components/client-dashboard/DashboardFilter.vue'));

@Options({
  components: {
    SpecificsQuestions,
    'cp-activity-list-item': ActivityListItem,
    'cp-dashboard-filter': DashboardFilter,
    'cp-export-column-config': ExportColumnConfig,
    'cp-no-datatable-records': NoDataTableRecordsComponent,
  },
})
export default class Search extends mixins(DefaultSearchMixin) {
  criteria = new PageableFilterCriteria();
  user: AppUser;
  rules: Partial<Record<FieldCode, Array<(val: string) => any>>> = {
    DateRange: [required('From Date')],
    Clients: [required('Companies')],
  };
  data1: PagedList<EngagementSearchResultItem> = {
    items: [],
    total: 0,
    totalPages: 0,
  };
  showFilter = false;
  showExportColumns = false;
  pagination = Object.assign({}, DEFAULT_PAGINATION);
  selected = <EngagementSearchResultItem[]>[];
  dataFooterOptions = { itemsPerPageOptions: [50, 100] };
  inProgress = false;
  declare userActivitySearchFields: FieldCode[];
  userExportColumns: FieldCode[] = [];
  selectedItems: any = [];
  dSpecifics: Specifics;
  dSpecificsFreetext: string;
  displayDialogSpecifics = false;

  data() {
    return {
      expandedRows: [],
    };
  }

  get downloadItems() {
    return [
      {
        label: 'Individual Activities (xlsx)',
        icon: `<img alt="logo" src="${require('../../assets/icons/microsoft-excelgray.svg')}" style="width: 1.5rem">`,
        command: () => {
          this.exportData(false);
        },
        disabled: false
      },
      {
        label: 'Aggregated Subsequent Rows (xlsx)',
        icon: `<img alt="logo" src="${require('../../assets/icons/microsoft-excelgray.svg')}" style="width: 1.5rem">`,
        command: () => {
          this.exportData(true);
        },
        disabled: false
      },
      {
        label: 'Individual Activities (pdf)',
        icon: '<span class="material-icons-outlined text-2xl p-secondary-color">picture_as_pdf</span>',
        command: () => {
          this.exportPDF();
        },
        disabled: this.selectedItems.length == 0
      },
      {
        label: 'Setup Export Columns',
        icon: '<span class="material-icons text-2xl p-secondary-color">settings</span>',
        command: () => {
          this.showExportColumns = true;
        },
      },
    ];
  }

  filters1: any = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  };
  specificsClick(specifics, specificsFreetext) {
    this.dSpecifics = specifics;
    this.dSpecificsFreetext = specificsFreetext;
    this.displayDialogSpecifics = true;
  }
  dialogClose() {
    this.displayDialogSpecifics = false;
  }

  @Watch('defaultsLoaded', { immediate: true })
  async defaultsLoadedDone(val: boolean) {
    if (val) {
      this.user = this.$store.state.auth.user;

      this.criteria = new PageableFilterCriteria({
        filterModel: cloneDeep(this.initialState),
      });

      const pageInfo = this.$store.getters[`search/${SEARCH_MODULE.GET_CURRENT_PAGEINFO}`];
      if (pageInfo) {
        this.pagination = pageInfo;
      }

      await this.search();

      this.userExportColumns = cloneDeep(
        await DefaultColumnsService.get((this.user as any).userId as string)
      );
    }
  }

  async filterSubmitted(filterModel: FilterModel) {
    this.criteria.filterModel = cloneDeep(filterModel);
    this.pagination.page = 1;
    await this.search();
  }

  async columnSubmitted(exportColumns: FieldCode[]) {
    this.userExportColumns = cloneDeep(exportColumns);
    // console.log("final", this.userExportColumns);
  }

  async exportData(aggregate: boolean) {
    if (this.isExportDisabled) {
      return; // If search does not contain any results don't do anything
    }
    event(`export:${aggregate ? 'aggregate' : 'individual'}`, {
      event_label: this.user.email,
      event_category: 'search-engagement',
    });

    let blob: Blob | null;
    if (!(this.selectedItems || []).length) {
      blob = await ExportService.exportFromSearch(
        this.criteria,
        this.userExportColumns,
        aggregate
      );
    } else {
      const ids = this.selectedItems.map((s) => s.qualifiedId);
      blob = await ExportService.exportSelected(
        ids,
        this.userExportColumns,
        aggregate
      );
    }
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'Engagements Export.xlsx';
    // NOTE: document.body.appendChild and the code in setTimeout are needed for the file download option to appear in Firefox
    // https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    }, 100);
  }
  printView() {
    const ids = this.selectedItems.map((x) => x.qualifiedId).join(',');

    this.$router.push(`/analytics/print?ids=${ids}`);
  }

  async search() {
    this.inProgress = true;

    const pageInfo = this.pagination;
    this.criteria.page = pageInfo.page;
    this.criteria.pageSize = pageInfo.itemsPerPage;

    const oldSort = `${this.criteria.sortInfo.field}:${this.criteria.sortInfo.order}`;
    const newSort = `${pageInfo.sortBy[0]}:${pageInfo.sortDesc[0] ? SortOrder.Descending : SortOrder.Ascending
      }`;

    if (oldSort !== newSort) {
      const name = `sort:${newSort.replace('.keyword', '')}`
        .replace('0', 'asc')
        .replace('1', 'desc');
      event(name, {
        event_label: this.user.email,
        event_category: 'search-engagement',
      });
    }

    this.criteria.sortInfo = {
      field: pageInfo.sortBy[0],
      order: pageInfo.sortDesc[0] ? SortOrder.Descending : SortOrder.Ascending,
    };

    const searchState: CurrentSearchState = {
      criteria: this.criteria,
      pagination: pageInfo,
    };
    this.$store.commit(`search/${SEARCH_MODULE.SET_CURRENT_SEARCH}`, searchState);

    this.data1 = await SearchService.search(this.criteria);
    this.inProgress = false;
  }

  pdfIndex: number = 0;

  async exportPDF() {
    const doc = createJsPdf();

    this.pdfIndex = 0;
    this.htmlToPdf(doc, this.pdfIndex);
  }

  htmlToPdf(doc: jsPDF, index: number) {
    const activities = this.selectedItems.map((x) => x);

    const props = { activityType: activities[index].activityType, id: activities[index].id };

    const app = createApp(IndividualActivityReport, props);
    var el: HTMLDivElement = document.createElement('div');
    app.mount(el).$nextTick();

    setTimeout(() => {
      doc.html(el, {
        callback: function (doc) {
          if (index == activities.length - 1)
            doc.save(`Activity Report${activities.length == 1 ? ' - ' + activities[0].fullName : ''}.pdf`);
          else
            doc.addPage();
        },
        y: 802 * (doc.getNumberOfPages() - 1),
        html2canvas: { scale: 1 },
        autoPaging: 'text',
        margin: [20, 8, 20, 20],
      }).then(() => {
        if (index != activities.length - 1) {
          this.htmlToPdf(doc, ++this.pdfIndex);
        }
      });
    }, 1000);
  }

  @Watch('pagination')
  async gridOptsChanged(newOpts: any, oldOpts: any) {
    if (isEqual(newOpts, oldOpts)) {
      return;
    }
    await this.search();
  }

  get dateRange() {
    return dateRangeText(this.criteria.filterModel.filters);
  }

  getArrowIcon(isExpanded: boolean) {
    return isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
  }

  get isExportDisabled() {
    return this.data1.total === 0;
  }

  formatDate(value) {
    return dayjs(String(value)).format('MM/DD/YYYY');
  }

  onPage(event) {
    this.pagination.page = event.page + 1;
    this.pagination.itemsPerPage = event.rows;
    this.search();
  }

  onSort(event) {
    this.pagination.itemsPerPage = event.rows;
    this.pagination.sortBy[0] = event.sortField;
    this.pagination.sortDesc[0] = event.sortOrder == 1;
    this.search();
  }

  closeSidebar(e) {
    this.showExportColumns = e;
  }

  toggle(event) {
    (this.$refs.menu as any).toggle(event);
  }

  closePanel() {
    this.showFilter = false;
  }

  get isNoData() {
    return this.data1.items.length == 0;
  }
}
</script>

<style lang="scss" scoped></style>
