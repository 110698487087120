
export default function useViewportScroller() {
  const scrollListener = (element: any) => {
    if (element) {
      window.addEventListener('scroll', () => {
        if (isInViewport(element)) {
          element.getElementsByClassName('p-card')[0].classList.remove('card-inactive');
          element.getElementsByClassName('p-card')[0].classList.add('card-active');
        } else {
          element.getElementsByClassName('p-card')[0].classList.add('card-inactive');
          element.getElementsByClassName('p-card')[0].classList.remove('card-active');
        }
      });

      element.addEventListener('click', () => {
        //element.scrollIntoView(true);
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      });
    }
  };
  const isInViewport = (el) => {
    if (el) {
      const rect = el.getBoundingClientRect();
      return rect.top <= 380 && rect.height + rect.top > 380;
    }
  };

  return { scrollListener };
}