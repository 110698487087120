<template>
  <div class="col-12 h-6rem">
    <DataTable :value="activeReadersEng" class="w-full mt-5" :paginator="true" :rows-per-page-options="[10, 20, 50]"
      :rows="10" :total-records="activeReadersEng.length"
      paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
      current-page-report-template="Showing {first} to {last} of {totalRecords}">
      <template #header>
        <div class="flex">
          <div class="col-12 p-0 flex flex-row-reverse">
            <Button type="button" label="Toggle" class="p-button-rounded py-2 px-3 mr-6"
              :disabled="activeReadersEng.length === 0" @click="downloadReport">
              <img alt="logo" :src="require('../../assets/icons/microsoft-excel.svg')" style="width: 1.2rem"
                class="text-xl">
              <span class="mx-2 text-white">Download</span>
            </Button>
          </div>
        </div>
      </template>
      <Column field="fullName" header="Reader Name" style="width: 15%" sortable>
        <template #body="slotProps">
          <router-link :to="slotProps.data.getReaderURL" class="p-button-link opacity-100 capitalize">
            {{ slotProps.data.fullName }}
          </router-link><br>
          <span class="text-xs capitalize">{{ slotProps.data.jobTitle }}</span>
        </template>
      </Column>

      <Column field="company" header="Reader Org" style="width: 15%" sortable>
        <template #body="slotProps">
          {{ slotProps.data.company }}
        </template>
      </Column>

      <Column field="totalEngagements" header="Engagements" class="text-center" style="width: 10%" sortable>
        <template #body="slotProps">
          {{ slotProps.data.totalEngagements }}
        </template>
      </Column>

      <Column field="totalDocuments" header="Documents" class="text-center" style="width: 10%" sortable>
        <template #body="slotProps">
          {{ slotProps.data.totalDocuments }}
        </template>
      </Column>

      <Column field="documentTitle" header="Most Recent" style="width: 50%" sortable class="truncate-text">
        <template #body="slotProps">
          <span class="truncate-text-readerco text-sm">
            <a target="_blank" :title="slotProps.data.documentTitle" :href="slotProps.data.documentUrl" class="text-sm">
              {{ slotProps.data.documentTitle }}</a></span>
        </template>
      </Column>

      <template #empty>
        <div class="flex align-items-center justify-content-center py-5">
          <ProgressSpinner v-if="inProgress" />
          <cp-no-datatable-records v-if="!inProgress" />
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script lang="ts">

import { MostActiveReaderEngagements } from '@/models/analytics/mostActiveReaderEngagements';
import { FilterModel } from '@/models/search';
import mostActiveReaderService from '@/services/mostActiveReadersService';
import { PropType, ref, watch } from 'vue';
import NoDataTableRecordsComponent from '../common/NoDataTableRecordsComponent.vue';
import { ColumnInfo, ExcelSheet, downloadExcel } from '@/utils/excelUtils';

export default ({
  components: {
    'cp-no-datatable-records': NoDataTableRecordsComponent
  },
  props: {
    filterModel: { type: Object as PropType<FilterModel> }
  },
  setup(props) {
    let activeReadersEng = ref<MostActiveReaderEngagements[]>([]);
    const inProgress = ref(false);

    const fetchData = async (filterModel) => {
      inProgress.value = true;
      activeReadersEng.value = await mostActiveReaderService.getMostActiveReaderEngagements(filterModel);
      inProgress.value = false;
    };

    watch(props, async () => {
      await fetchData(props.filterModel);
    });

    const downloadReport = () => {
      const columnInfo: ColumnInfo<MostActiveReaderEngagements> = {
        'Reader Name': { width: 20, propertyFn: (row) => row.fullName },
        'Reader Job Title': { width: 20, propertyFn: (row) => row.jobTitle },
        'Reader Org': { width: 50, propertyFn: (row) => row.company },
        'Engagements': { width: 12, propertyFn: (row) => row.totalEngagements },
        'Documents': { width: 10, propertyFn: (row) => row.totalDocuments },
        'Most Recent': { width: 50, propertyFn: (row) => ({ t: 's', v: row.documentTitle, l: { Target: row.documentUrl } }) }
      };

      const sheet: ExcelSheet<MostActiveReaderEngagements> = {
        filename: 'Most Active Readers Report.xlsx',
        data: activeReadersEng.value,
        columnInfo
      };
      
      downloadExcel(sheet);
    };

    return {
      activeReadersEng, inProgress, downloadReport
    };
  },
});
</script>
