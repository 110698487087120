<template>
  <div v-if="!lookUpSubmitted" class="col-6 flex flex-row pb-5" style="margin-left:-2px">
    <Button type="button" icon-pos="left" class="lookUpButton" @click="lookUpReaderOrgs">
      <div>
        <img alt="orgSearch" style="width:20px;height:20px"
          :src="require('../../assets/icons/format-list-group-plus.svg')">
      </div>
      <div style="width:70%">
        Reader Org Lookup
      </div>
    </Button>
  </div>
  <div v-if="lookUpSubmitted" class="col-12 p-0 flex flex-row pb-4">
    <div class="col-5" style="margin-left:-8px">
      <div :class="{ valid: !v$2.$error && v$2.$dirty, error: v$2.$error }">
        <span class="p-float-label">
          <Textarea id="orgs" v-model="v$2.orgs.$model" rows="10" style="width:100%" @keyup="orgChanged($event)" />
          <label for="orgs">{{ title }} </label>
        </span>
      </div>


      <div class="flex">
        <div class="flex" style="width:80%;font-size:12px">
          Type or paste reader companies on separate lines
        </div>
        <div class="flex" style="width:20%">
          <Button type="button" label="Search" icon="pi pi-search" icon-pos="right" style="background-color:#1B3A63"
            :disabled="v$2.orgs.$model === ''" @click="searchReaderOrgs" />
        </div>
      </div>
    </div>

    <div v-if="v$2.orgs.$errors.length > 0" class="col-7 pt-0">
      <Message icon="pi pi-info-circle" :closable="false">
        <div v-for="(error, index) in v$2.$errors" :key="index" style="color:#ff0000">
          {{ error.$message }}
        </div>
      </Message>
    </div>

    <div v-if="submitted && !v$2.$error" class="col-7 pt-3">
      <div class="grid mb-3">
        <div class="card w-full">
          <div style="padding-bottom:10px">
            <span class="p-message-icon pi pi-info-circle" style="font-size:1.4rem">
              <span class="message-found pl-2">Results</span></span>
          </div>
          <div v-if="readerOrgsNotFound?.length">
            <div>
              <h5 class="unmatched">
                {{ readerOrgsNotFound?.length }} {{ readerOrgsNotFound?.length == 1 ? 'name' : 'names' }} did not match any reader orgs:
              </h5>
            </div>
            <DataView :value="readerOrgsNotFound" :layout="layout" :paginator="false" :rows="9" data-key="">
              <template #header>
                <div class="grid grid-nogutter flex-row-reverse" style="margin-bottom:18px" />
              </template>

              <template #grid="slotProps">
                <div class="col-12 md:col-4 mt-9">
                  <div class="org-grid-item">
                    <div class="org-grid-item-content">
                      <div class="org-name">
                        {{ slotProps.data }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template #empty>
                No records found.
              </template>
            </DataView>
          </div>
          <div v-if="readerOrgsFound?.length">
            <div style="margin-top:10px">
              <h5 class="matched">
                {{ readerOrgsFound?.length }} Reader {{ readerOrgsFound?.length == 1 ? 'org' : 'orgs' }} found (below).
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, PropType, reactive, ref, onUnmounted } from 'vue';
import { TermsFilter } from '@/models/filters';
import readerOrgMatchService from '@/services/readerOrgMatchService';
import useVuelidate from '@vuelidate/core';
import { helpers, requiredUnless } from '@vuelidate/validators';
import _ from 'lodash';

export default {

  name: 'CpReaderOrgSearchField',
  inheritAttrs: false,
  props: {
    modelValue: { type: Object as PropType<TermsFilter>, default: new TermsFilter() },

  },
  emits: ['filter-changed', 'reset-filter'],
  setup(props, { emit }) {
    const layout = ref('grid');
    const limit = ref(2000);
    const lineCount = ref(0);
    const title = ref('Find Related Reader Orgs');
    const readerOrgsFound = ref();
    let selectedOrgs = reactive({ orgs: '' });
    const readerOrgsNotFound = ref();
    const submitted = ref(false);
    const submitCount = ref(0);
    const lookUpSubmitted = ref(false);



    const hasNotExceededLimit = (): boolean => {
      lineCount.value = selectedOrgs.orgs.split(/\r\n|\r|\n/).length;
      return lineCount.value <= limit.value;

    };


    const rules = computed(() => ({
      orgs: {
        required: requiredUnless(() => !submitted.value),
        hasExceededLimit: helpers.
          withMessage(({ $model }) => `Error. Maximum of ${limit.value} Reader Orgs is exceeded. Please remove ${$model.split(/\r\n|\r|\n/).length - limit.value} reader orgs to continue`,
            hasNotExceededLimit),
      }

    }));

    const v$2 = useVuelidate(rules, selectedOrgs, { $stopPropagation: true });
    const orgChanged = async (e) => {
      submitted.value = false;
      if (e.target.value === '') {
        v$2.value.orgs.$model = '';
        await v$2.value.$validate();
        emit('reset-filter');
      }
    };

    const clearOrgs = async () => {
      v$2.value.orgs.$model = '';
      submitted.value = false;
    };

    onUnmounted(() => {
      submitted.value = false;
    });
    const lookUpReaderOrgs = () => {
      lookUpSubmitted.value = true;
    };

    const searchReaderOrgs = async () => {
      submitted.value = true;
      await v$2.value.$validate();
      if (v$2.value['$errors'].length > 0) return;
      readerOrgsFound.value = [];
      readerOrgsNotFound.value = [];
      let selectedOrgArray = selectedOrgs.orgs.split('\n').filter(e => String(e).trim());
      let orgs = await readerOrgMatchService.getReaderOrgByNames(_.uniq(selectedOrgArray));

      if (orgs.lookups.length > 0) {
        orgs.lookups.forEach((x) => {
          readerOrgsFound.value.push(x.name);
        });
      }

      if (orgs.unmatchedNames.length > 0) {
        orgs.unmatchedNames.forEach((item) => {
          readerOrgsNotFound.value.push(item);
        });
      }

      emit('filter-changed', orgs.lookups);
    };

    return {
      title, layout, rules, lineCount, limit, hasNotExceededLimit, readerOrgsFound, readerOrgsNotFound, searchReaderOrgs,
      submitted, lookUpSubmitted, selectedOrgs, lookUpReaderOrgs, orgChanged, v$2, clearOrgs, submitCount
    };
  }

};
</script>
<style lang="scss" scoped>
.valid input {
  border: 1px solid green;
}

.p-message.p-message-info .p-message-icon {
  color: red;
}

.error input {
  border: 1px solid red;
}

.message-found {
  text-align: left;
  font: normal normal normal 16px/20px Overpass !important;
  letter-spacing: 0px;
  color: #76869A;
  opacity: 1;
  margin-top: 3px;
  padding-bottom: 10px;
  position: absolute;
}

.lookUpButton {

  width: 40%;
  height: 10%;
  background-color: #1B3A63
}

.org-name {
  font: normal normal normal 16px/20px Overpass;

}

.card {
  padding: 2rem;
  border-radius: 4px;
  margin-bottom: 2rem;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 3px #00000014;
  border: 1px solid #D3D8E6;
  opacity: 1;
  width: 59%
}

.matched,
.unmatched {
  text-align: left;
  font: normal normal 900 16px/20px Overpass;
  letter-spacing: 0px;
  color: #1b3557;
  opacity: 1
}

.pi {
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #76869A;
  display: inline-block;

}

::v-deep(.product-grid-item) {
  margin: 0.5rem;

  .org-grid-item-top,
  .org-grid-item-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .org-grid-item-content {
    text-align: center;
  }
}
</style>
