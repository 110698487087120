<template>
  <div id="scroll" class="sidebar" :class="isOpened ? 'open' : ''">
    <span class="material-icons text-2xl pt-1 pl-2 mb-0 color-gray cursor-pointer" @click="isOpened = !isOpened">
      menu
    </span>
    <div class="logo-details mt-0 pb-2">
      <router-link to="/" class="flex align-items-center justify-content-center">
        <img v-if="isOpened" :src="require('../../assets/Templeton_logo.svg')" style="width:67%" alt="Templeton Logo">
        <img v-else :src="require('../../assets/Logo-Minimized.svg')" alt="Templeton Logo" class="minimized-logo ml-2">
      </router-link>
    </div>
    <Divider type="solid" class="my-3" />
    <div class="name-profile text-center my-0">
      <div :class="isOpened ? '' : 'flex pl-3'" class="align-items-center justify-content-center">
        <Avatar class="va-avatar-initials font-medium" :class="getAvatarColor" shape="circle">
          <span class="pt-1" :class="{
            'text-white': loggedInUserIsClient,
            'p-avatar-text': !loggedInUserIsClient,
          }">{{ loggedInUserInitials }}</span>
        </Avatar>
        <div class="flex align-items-center justify-content-center pt-2">
          <h3 v-if="isOpened">
            {{ loggedInUserName }}
          </h3>
          <router-link :to="'/admin/user/' + userId">
            <span class="p-secondary material-icons text-xs pl-1">
              edit
            </span>
          </router-link>
        </div>
      </div>
    </div>
    <Divider class="my-3" />
    <PanelMenu :model="secureItems" class="mt-2" :expanded-keys="expandKeys" @panel-open="expandMenu"
      @panel-close="collapseMenu">
      <template #item="{ item }">
        <div v-if="!item.link" class="flex align-items-center" :class="{ 'justify-content-between': isOpened }">
          <div class="flex align-items-center">
            <Avatar v-if="item.key !== 'tactic' && item.icon" shape="circle" class="menu-circle ml-3">
              <i :class="[
                { 'opened text-xl': isOpened, 'text-lg': !isOpened },
                item.iconClass ? item.iconClass : 'material-icons',
              ]">{{ item.icon }}</i>
            </Avatar>
            <Avatar v-if="item.key === 'tactic' && !isOpened" v-tooltip="item.name" shape="circle" class="menu-circle" v-html="item.icon" />
            <span v-if="isOpened" class="links_name pl-2 font-bold p-primary-color cursor-pointer text-sm">
              {{ item.name }}
            </span>
            <router-link v-if="item.key == 'notifications' && isOpened" to="/notifications/dashboard"
              class="opacity-100">
              <Badge v-if="dashboardUnreadCount > 0" class="badge-count flex align-items-center justify-content-center"
                :value="dashboardUnreadCount" />
            </router-link>
          </div>
          <div class="flex align-items-center" v-if="item.key !== 'tactic'">
            <span class="material-icons pr-3 text-2xl cursor-pointer">
              {{ checkExpandStatus(item.key!) ?'expand_less': 'expand_more' }}
            </span>
          </div>
        </div>
        <a v-if="item.link && item.disabled">
          <Avatar v-if="!isOpened" v-tooltip="item.name" shape="circle" class="menu-circle" v-html="item.icon" />
          <span class="links_name font-bold text-sm pl-1">{{ item.name }}</span>
        </a>
        <router-link v-else-if="item.link" :to="item.link" :class="activeClass(item.link)">
          <Avatar v-if="!isOpened" v-tooltip="item.name" shape="circle" class="menu-circle" v-html="item.icon" />
          <span class="links_name font-bold text-sm pl-1">{{ item.name }}</span>
        </router-link>
      </template>
    </PanelMenu>
    <div class="profile">
      <Divider v-if="!isOpened" type="solid" />
      <div v-if="!isOpened" class="profile-details mt-5">
        <span v-tooltip="'Logout'" class="material-icons font-bold cursor-pointer text-2xl" @click="logout">
          logout
        </span>
      </div>
      <div v-if="isOpened" class="profile-details mt-3">
        <Button label="Log out" class="p-button-outlined p-button-rounded px-4 font-normal log-out" @click="logout" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import {AUTH_MUTATIONS, initIntercom} from '@/store/modules/auth.module';
import { mapGetters } from 'vuex';
import { UserRole } from '@/models/userRole';
import { Claim } from '@/models/security';
import { getAvatarColorClass, getInitials } from '@/utils/userUtils';
import { Vue, Options } from 'vue-class-component';
import { ProfileMenu } from '@/models/ProfileMenu';
import { SEARCH_MODULE } from '@/store/modules/search.module';
import { NavItem } from '@/models/common/navItem';
import { NAV_ITEMS } from '@/models/common/navMenuItems';
import { PanelMenuExpandedKeys, PanelMenuPanelCloseEvent, PanelMenuPanelOpenEvent } from 'primevue/panelmenu';
import { USER_MODULE } from '@/store/modules/user.module';
declare var window:any;

@Options({
  name: 'cp-nav',
  computed: {
    ...mapGetters('auth', ['satisfiesClaim', 'userId', 'userName', 'userRole', 'isMPSFeatureEnabled']),
    ...mapGetters('notification', ['dashboardUnreadCount']),
  },
})
export default class SideBar extends Vue {
  @Prop({ default: true }) isMenuOpen: boolean;
  @Prop({ default: '17.857rem' }) menuOpenedPaddingLeftBody: string;
  @Prop({ default: '6.429rem' }) menuClosedPaddingLeftBody: string;

  navItems = NAV_ITEMS;
  satisfiesClaim: (claim: Claim) => boolean; // from vuex
  userId!: string;
  userName!: string;
  userRole!: UserRole;
  dashboardUnreadCount!: number;
  isMPSFeatureEnabled!: boolean;
  expandKeys: PanelMenuExpandedKeys = {'tactic': true};

  isOpened = false;
  activeTab: ProfileMenu = ProfileMenu.Filters;

  async mounted() {
    this.winWidth();    
  }
 
  @Watch('isOpened', { immediate: true })
  setPadding() {
    window.document.body.style.paddingLeft = this.isOpened
      ? this.menuOpenedPaddingLeftBody
      : this.menuClosedPaddingLeftBody;
  }

  get isAtLeastAdmin() {
    return this.satisfiesClaim({ name: 'role', value: 'Admin' });
  }

  get secureItems(): NavItem[] {
    const filteredItems = NAV_ITEMS
      .filter(item => {
        const hasAccess = !item.claim || this.satisfiesClaim(item.claim);
        const isPerformanceItem = item.key === 'performance' && !this.isMPSFeatureEnabled;
        const hasAccessibleSubItems = (item.items || []).some(subItem => !subItem.claim || this.satisfiesClaim(subItem.claim));

        return hasAccess || isPerformanceItem || hasAccessibleSubItems;
      })
      .map(item => {
        if (item.key === 'performance') {
          item.items = item.items?.map(subItem => ({
            ...subItem,
            disabled: item.claim && !this.isMPSFeatureEnabled
          }));
        }

        return {
          ...item,
          items: item.items?.filter(subItem => !subItem.claim || this.satisfiesClaim(subItem.claim))
        };
      });
    return filteredItems;
  }

  logout() {
    this.$store.dispatch(`search/${SEARCH_MODULE.CLEAR_CURRENT_SEARCH}`);
    this.$store.commit(`auth/${AUTH_MUTATIONS.CLEAR_STATE}`);
    this.$store.commit(`currentUser/${USER_MODULE.CLEAR_CURRENT_USER}`);
    this.$router.push('/login');
    window.Intercom('shutdown');
    initIntercom(null);
  }

  navigate(route: string) {
    this.$router.push(route);
    this.$emit('refreshRoute');
  }

  activeClass(route: string) {
    const overviewRoute = route === '/';

    if (
      (overviewRoute && this.$route.path === route) ||
      (!overviewRoute && this.$route.path.match(new RegExp(route)))
    ) {
      const key = overviewRoute ? 'analytics' : route.split('/')[1];

      this.expandKeys[key] = true;

      return 'active';
    }
  }

  checkExpandStatus(keyName: string) {
    return Object.entries(this.expandKeys).filter(([key, value]) => key == keyName && value == true).length > 0;
  }

  get loggedInUserInitials(): string {
    if (this.userName) {
      const nameParts = this.userName.split(' ');
      return getInitials(nameParts);
    } else return '';
  }

  get loggedInUserName(): string {
    return this.userName ?? '';
  }

  get getAvatarColor(): string {
    return this.userName ? getAvatarColorClass(this.userRole) : 'color-yellow';
  }

  get loggedInUserIsClient(): boolean {
    return this.userRole === 'Client';
  }

  winWidth() {
    var w = window.innerWidth;

    if (w < 768) {
      this.isOpened = false;
    } else {
      this.isOpened = this.isMenuOpen;
    }
  }

  expandMenu(item: PanelMenuPanelOpenEvent) {
    this.expandKeys[item.item.key] = false;
  }

  collapseMenu(item: PanelMenuPanelCloseEvent) {
    for (let node of this.secureItems) {
      if (node.key) {
        this.expandKeys[node.key] = node.key === item.item.key;
      }
      if (node.items) {
        for (let subNode of node.items) {
          if (subNode.key) {
            this.expandKeys[subNode.key] = subNode.key === item.item.key;
          }
        }
      }
    }
  }
}
</script>

<style>
.sidebar {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  height: calc(100% - 10px);
  min-height: min-content;
  width: 7.429rem;
  background: transparent linear-gradient(182deg, #e5f9fa6b 0%, #d0e7ff54 100%) 0% 0% no-repeat;
  /* padding: 6px 14px 0 14px; */
  z-index: 99;
  transition: all 0.7s ease;
}

.sidebar.open {
  width: 17.857rem;
  height: 100vh;
}

.sidebar .logo-details {
  display: flex;
  justify-content: center;
  position: relative;
}

.sidebar .logo-details .img {
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar .name-profile {
  transition: all 0.5s ease;
}

.sidebar i {
  color: #1d3557;
  height: 4.286rem;
  min-width: 3.571rem;
  text-align: center;
  line-height: 4.286rem;
}

.sidebar li {
  position: relative;
  margin: 0.429rem 0;
  list-style: none;
  transition: all 0.1s ease;
}

.sidebar li a {
  display: flex;
  height: 100%;
  width: 90%;
  border-radius: 4px;
  align-items: center;
  text-decoration: none;
  transition: all 0.5s ease;
  padding: 0.5rem 0.5rem;
  opacity: 1;
}

.sidebar li a:hover {
  background: #cdd6e6;
}

.sidebar li a.active {
  background: #304899;
}

.sidebar li a .links_name {
  color: #1d3557;
  font-weight: bold;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}

.sidebar li a.active .links_name {
  color: #ffff;
}

.sidebar li a.active i {
  color: #ffff;
}

.sidebar li a.active .menu-circle {
  background-color: #7484bb;
}

.sidebar.open li a .links_name {
  opacity: 1;
  pointer-events: auto;
}

.default-filter {
  color: #4259a8;
}

.default-filter:hover {
  text-decoration: none;
}

.sidebar li i {
  height: 2.857rem;
  line-height: 2.857rem;
  font-size: 1rem;
  border-radius: 1.071rem;
}

.minimized-logo {
  height: 3.571rem !important;
  border-radius: 0px !important;
}

.scroll-active {
  overflow-y: auto;
}

#scroll {
  overflow-y: auto;
}

#scroll::-webkit-scrollbar {
  display: none;
}

.menu-circle {
  width: 1.7rem !important;
  height: 1.7rem !important;
  margin-left: 0.1rem;
}

.sidebar div.profile {
  position: relative;
  height: 2.857rem;
  width: 100%;
  padding-bottom: 7.857rem;
  padding-top: 0px;
  transition: all 0.5s ease;
  overflow: hidden;
}

.sidebar.open div.profile {
  padding-bottom: 4.286rem;
}

.sidebar div .profile-details {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: 500;
  color: #1d3557;
}

@media (max-width: 420px) {
  .sidebar li .tooltip {
    display: none;
  }
}

.log-out {
  color: #1f3274 !important;
}

.log-out:hover {
  box-shadow: 0 0 2px 1px;
}

.p-panelmenu .p-panelmenu-panel {
  box-shadow: none;
  margin-bottom: 1rem;
}

.p-panelmenu-content {
  background: transparent !important;
  margin-left: 1.8rem;
}

.p-panelmenu-header,
.p-submenu-list {
  outline: none;
}

.p-submenu-list {
  border-left: 2px solid rgba(0, 0, 0, 0.12);
}

.badge-count {
  position: absolute;
  background-color: #E63946;
  box-shadow: 0 3px 6px #00000045;
  width: 1.5rem;
  height: 1.5rem;
  font-size: .65rem;
  line-height: 1.5rem;
  z-index: 11;
  top: -0.1rem;
  margin-left: 0.5rem;
  border-radius: 1.071rem !important;
}
</style>
