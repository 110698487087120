import { Notification, NotificationResponse } from '@/models/notification';
import axios from 'axios';

class NotificationScheduleService {
  private baseUrl: string = '/api/notification-schedule';

  async activate(id: string): Promise<Notification> {
    const r = await axios.get<NotificationResponse>(`${this.baseUrl}/${id}/activate`);
    return new Notification(r.data.notification);
  }

  async deactivate(id: string): Promise<Notification> {
    const r = await axios.get<NotificationResponse>(`${this.baseUrl}/${id}/deactivate`);
    return new Notification(r.data.notification);
  }
}

export default new NotificationScheduleService();
