import { SortOrder } from '../sortOrder';
import { INDEX_LOOKUPS } from '@/models/activity-dissemination';
import { FilterModel } from './filterModel.interface';


export class PageableFilterCriteria {

  filterModel: FilterModel = { filters: [], indices: [] };

  public sortInfo: { field: string; order: SortOrder; };
  public page: number = 1;
  public pageSize: number = 50;

  constructor(data: Partial<PageableFilterCriteria> = {}) {
    Object.assign(this, data);
    this.sortInfo = { field: 'date', order: SortOrder.Descending };
    this.filterModel.indices = this.filterModel.indices.length ? this.filterModel.indices : INDEX_LOOKUPS;
  }
}
