<template>
  <div class="flex justify-content-between">
    <h2 :id="field">
      {{ titleTrim() }}
    </h2>
    <div class="pt-2">
      <cp-export-chart-options :chart="chartExport" @chart-updated="chartUpdated" />
    </div>
  </div>

  <div ref="chart" cp-color-picker class="flex align-items-center pt-3" style="min-height: 300px;">
    <ProgressSpinner />
  </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import { Chart } from 'highcharts';
import ClientDashboardService from '@/services/clientDashboardService';
import {
  setDefaults,
  getChartColor,
  getPieChartConfig,
  PieChartOptions,
  getPieChartNoDataOptions,
} from '@/utils/highchartUtils';
import { NamedSeries } from '@/models/namedSeries';
import ExportChartOptionsComponent from '../common/ExportChartOptions.vue';
import ChangeChartColorComponent from '../common/ChangeChartColor.vue';
import { filterValueExists } from '@/utils/filters';
import { FilterModel } from '@/models/search';
import { isEqual } from 'lodash';
import { Vue, Options } from 'vue-class-component';
import Highcharts from 'highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { renderPieChart } from '@/plugins/highchart-extend';
import { FieldCode } from '@/models/filters';
NoDataToDisplay(Highcharts);

@Options({
  components: {
    'cp-chart-color': ChangeChartColorComponent,
    'cp-export-chart-options': ExportChartOptionsComponent,
  }
})
export default class EngagmentsByField extends Vue {
  @Prop({ default: () => <FilterModel>{ filters: [], indices: [] } })
  public filterModel: FilterModel;
  @Prop() public field!: FieldCode;
  @Prop() public title!: string;
  @Prop({ default: 8 }) public size: number;

  declare $refs: { chart: HTMLDivElement };
  chartExport: Chart | null = null;
  metrics: NamedSeries[] = [];
  loading = true;
  invalidNamesArray: string[] = ['Unclassified', '-1'];
  showTitle: string;

  created() {
    this.showTitle = this.title;
  }
  @Watch('filterModel', { immediate: true })
  async onFilterChange(filterModel: FilterModel, oldVal: FilterModel) {
    const noChanges = isEqual(filterModel, oldVal);
    const hasClientValue = filterValueExists(filterModel?.filters, 'Clients');
    const hasFilters = filterModel.filters?.length > 0;
    if (!hasFilters || !hasClientValue || noChanges) {
      return;
    }

    this.loading = true;
    this.metrics = await ClientDashboardService.getActivitiesByField(
      this.filterModel,
      this.field,
      this.size
    );
    this.loading = false;
    this._renderChart();
  }

  private _renderChart() {
    setDefaults();
    const series = this._getSeriesData();
    const options: PieChartOptions = <PieChartOptions>{
      field: this.field,
      exportTitle: this.title,
      series,
    };
    this.chartExport = (Highcharts as any).chart(
      this.$refs.chart,
      this.metrics.length > 0 ? getPieChartConfig(options) : getPieChartNoDataOptions(options),
      (chartInstance) => {
        // on complete
        if (this.metrics.length < 1) {
          renderPieChart(chartInstance, getPieChartNoDataOptions(options).lang.noDataImage);
        }
      }
    );
  }

  private _getSeriesData() {
    return [
      {
        type: 'pie',
        name: 'Engagements',
        data: this.metrics.map((x, index) => ({
          name: x.name,
          y: x.value,
          color: getChartColor(this.field, index, x.name),
        })),
        innerSize: '50%',
      },
    ];
  }

  chartUpdated(value: Chart) {
    this.chartExport = value;
    this.showTitle = (this.chartExport as any).options.exporting.chartOptions.title.text;
    this.$forceUpdate();
  }

  titleTrim() {
    return this.showTitle.length > 35
      ? this.showTitle.substring(0, 35) + '...  '
      : this.showTitle;
  }
}
</script>

<style scoped lang="scss">

</style>
