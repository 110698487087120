import {INDEX_LOOKUPS} from '@/models/analytics/activityTypes';
import {ActivityField, FilterModel} from '@/models/search';
import {JwtTokenUser} from '@/store/models';
import {SEARCH_MODULE} from '@/store/modules/search.module';
import {ref, Ref} from 'vue';
import {useStore} from 'vuex';
import {DEFAULT_DATE_RANGE_FILTER} from '@/models/filters';

export default function useCurrentFilters(authenticatedUser: Ref<JwtTokenUser>) {
  const store = useStore();
  const currentState = ref<FilterModel>({ filters: [], indices: [] });

  if (!store.state.search.defaults) {
    store.dispatch(`search/${SEARCH_MODULE.GET_SEARCH_DEFAULTS}`, { userId: authenticatedUser.value.userId });
  }

  if (!store.state.search.criteria) {
    store.dispatch(`search/${SEARCH_MODULE.GET_CURRENT_SEARCH}`, { userId: authenticatedUser.value.userId });
  }

  currentState.value = store.state.search.criteria?.filterModel ?? store.state.search.defaults;
  if (!currentState.value.indices?.length) {
    currentState.value.indices = INDEX_LOOKUPS;
  }

  const dateFilterExists = currentState.value.filters.some(filter => filter.field === 'DateRange');
  const basicSearchableFields = (store.state.activityFields.fields as ActivityField[]).filter((x: ActivityField) => x.accessLevel == 'Basic' && x.isSearchable);
  // NOTE: Before the Postgres migration, all fields were stored in the UserClaims table, but currently, only non-basic fields are stored
  // Therefore, basic fields are now expected to be included by default
  const filteredFilters = currentState.value.filters.filter(f => basicSearchableFields.some(x => x.field === f.field)
      ||  currentState.value.filters.filter(f => authenticatedUser.value.activityFields.includes(f.field)));
  currentState.value.filters = dateFilterExists ? filteredFilters : [...filteredFilters, DEFAULT_DATE_RANGE_FILTER];
  currentState.value.savedSearchId = currentState.value.savedSearchId;

  return { currentState };
}
