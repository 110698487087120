<template>
  <div class="grid">
    <div class="col-12 pb-0">
      <div class="flex">
        <h1 class="line-height-1">
          {{ titleText }}
        </h1>
      </div>
      <span class="p-secondary-color text-xs">{{ dateRange }}</span>
    </div>
    <!-- <div class="col-12 pt-4 px-0">
      <div class="flex"> -->
    <div class="col-12 lg:col-5 pt-4">
      <div class="justify-content-between">
        <h2 class="line-height-1 capitalize">
          {{ companyDisplayName }}
          <!-- <span class="help-icon ml-2" v-if="companyDisplayName">?</span> -->
        </h2>
      </div>
      <div class="pt-5">
        <cp-engagements-by-month :filter-model="filterModelWithoutOrgOrCompany" :org-filter="orgOrCompanyFilter" />
      </div>
      <div class="pt-5">
        <cp-data-view :filter-model="filterModelWithoutOrgOrCompany" :org-filter="orgOrCompanyFilter"
          agg-type="mostActiveReaders" />
      </div>
    </div>
    <div class="col-12 lg:col-7 py-0 pt-4 my-0 pl-3">
      <div class="flex">
        <div class="col-6 pr-2">
          <cp-engagements-stat-card :filter-model="filterModelWithoutOrgOrCompany" :org-filter="orgOrCompanyFilter"
            stat="engagements" />
        </div>
        <div class="col-6 pl-2">
          <cp-engagements-stat-card :filter-model="filterModelWithoutOrgOrCompany" :org-filter="orgOrCompanyFilter"
            stat="users" />
        </div>
      </div>
      <div class="pt-5">
        <cp-data-table :filter-model="filterModelWithoutOrgOrCompany" :org-filter="orgOrCompanyFilter"
          agg-type="mostReadContent" />
      </div>
      <div class="pt-5">
        <cp-engagements-by-category :filter-model="filterModelWithoutOrgOrCompany" :org-filter="orgOrCompanyFilter" />
      </div>
    </div>
    <!-- </div>
    </div> -->
    <!-- <Suspense>
      <cp-dashboard-filter v-model="filterModel" :show="showFilter" :include-indices="includeIndices"
        @filter-submitted="filterSubmitted" @close-panel="closePanel" />
    </Suspense> -->
    <!-- <div class="filter-menu-wrapper">
      <a class="filter-menu" @click.stop="showFilter = !showFilter">
        <img :src="require('../../assets/icons/dashboard-filters.svg')" alt="Filter Framework" width="52">
      </a>
    </div> -->
  </div>
</template>

<script lang="ts">
// import DashboardFilter from "@/components/client-dashboard/DashboardFilter.vue";
import DataTable from '@/components/reader-co-dashboard/DataTable.vue';
import DataView from '@/components/reader-co-dashboard/DataView.vue';
import EngagementsByCategory from '@/components/reader-co-dashboard/EngagementsByCategory.vue';
import EngagementsByMonth from '@/components/reader-co-dashboard/EngagementsByMonth.vue';
import EngagementsStatCard from '@/components/reader-co-dashboard/EngagementsStatCard.vue';
import {
  Filter,
  TermsFilter,
  TextFilter,
  FieldCode,
  DEFAULT_SEQUENCE_FILTER,
  FIRMOGRAPHIC_FIELDS,
} from '@/models/filters';
import { FilterableDashboard } from '@/models/analytics/filterableDashboard.interface';
import { Lookup } from '@/models/lookup';
import { FilterModel } from '@/models/search/filterModel.interface';
import ReaderCoService from '@/services/readerCoService';
import { findFilter, filterValueExists, dateRangeText } from '@/utils/filters';
import { required } from '@/utils/validators/validators';
import { cloneDeep } from 'lodash';
import { mixins, Options } from 'vue-class-component';
import { DefaultSearchMixin } from '@/mixins/defaultSearch.mixin';
import { FilterConfigModel } from '@/models/search/filterConfig';
import { Watch } from 'vue-property-decorator';
import { revertSanitizedUrlParam } from '@/utils/urlUtils';

interface ReaderCoRouteData {
  id?: string;
  orgName?: string;
  companyName?: string;
}
@Options({
  components: {
    'cp-engagements-stat-card': EngagementsStatCard,
    'cp-engagements-by-category': EngagementsByCategory,
    'cp-engagements-by-month': EngagementsByMonth,
    'cp-data-table': DataTable,
    'cp-data-view': DataView
  },
})
export default class ReaderCoDashboard
  extends mixins(DefaultSearchMixin)
  implements FilterableDashboard {
  filterModel: FilterModel = { filters: [], indices: [] };
  filterModelWithoutOrgOrCompany: FilterModel = { filters: [], indices: [] };
  showFilter = false;
  declare userId: string;
  declare userActivitySearchFields: FieldCode[];
  rules: Partial<Record<FieldCode, Array<(val: string) => any>>> = {
    DateRange: [required('From Date')],
    Clients: [required('Companies')],
  };
  orgOrCompanyFilter: Filter = new TermsFilter({ field: 'ReaderOrgs', value: [] });
  activeStates = { row1: false, row2: false, row3: false };
  companyDisplayName = '';
  routeData: ReaderCoRouteData = {};
  isRouteDataEmpty: boolean = true;
  filterConfig = new FilterConfigModel();


  async mounted() {
    // await this.defaultsLoaded;
    // this.includeIndices = this.userActivitySearchFields.includes('ActivityTypes');
    // const filterModel = this._filterModelWithSequence(this.initialState);
    // await this.filterSubmitted(filterModel);
    // if (!filterValueExists(this.filterModel.filters, 'Clients')) {
    //   this.showFilter = true;
    // }
    // this.filterConfig.excludeFields = FIRMOGRAPHIC_FIELDS;
  }

  @Watch('defaultsLoaded', { immediate: true })
  async defaultsLoadedDone(val: boolean) {
    if (val) {
      const filterModel = this._filterModelWithSequence(this.initialState);
      await this.filterSubmitted(filterModel);
      if (!filterValueExists(this.filterModel.filters, 'Clients')) {
        this.showFilter = true;
      }
      this.filterConfig.excludeFields = FIRMOGRAPHIC_FIELDS;
    }
  }

  async filterSubmitted(filterModel: FilterModel) {
    await this._setOrgOrCompanyFilter(filterModel);
    this.filterModel = cloneDeep(filterModel);
    this.filterModelWithoutOrgOrCompany = this._filterModelWithoutOrgOrCompany(
      filterModel
    );
    this._updateCompanyDisplayName();
  }
  get dateRange() {
    return dateRangeText(this.filterModel.filters);
  }
  get titleText() {
    return this.isRouteDataEmpty ? 'Most Active Company' : 'Company Dashboard';
  }
  private _getOrgOrCompanyFilter(orgData: Lookup<string>): Filter {
    return orgData.id
      ? new TermsFilter({ field: 'ReaderOrgs', value: [orgData] })
      : new TextFilter({ field: 'ReaderCompany', value: orgData.name });
  }
  private _updateCompanyDisplayName() {
    const isReaderOrg = this.orgOrCompanyFilter.field === 'ReaderOrgs';
    const filter: any = isReaderOrg
      ? <TermsFilter>this.orgOrCompanyFilter
      : <TextFilter>this.orgOrCompanyFilter;
    if (isReaderOrg) {
      this.companyDisplayName =
        (<TermsFilter>filter)?.value?.map((v) => v.name).join(' | ') || '';
    } else {
      this.companyDisplayName = (<TextFilter>filter)?.value || '';
    }
    const filterOpPrefix =
      filter &&
        ['NotContains', 'NotEqual', 'NotLike'].includes(filter.op)
        ? 'Companies Other Than'
        : '';
    if (filterOpPrefix) {
      this.companyDisplayName = `${filterOpPrefix} ${this.companyDisplayName}`;
    }
  }
  private _filterModelWithSequence(filterModel: FilterModel): FilterModel {
    const newFilterModel = cloneDeep(filterModel);
    const sequenceFilter: TermsFilter = findFilter<TermsFilter>(
      newFilterModel.filters,
      'Sequence'
    );
    if (!sequenceFilter && this.checkRouteDataExists()) {
      newFilterModel.filters.push(DEFAULT_SEQUENCE_FILTER);
    }
    return newFilterModel;
  }
  private _filterModelWithoutOrgOrCompany(filterModel: FilterModel): FilterModel {
    const newFilterModel = cloneDeep(filterModel);
    newFilterModel.config = this.filterConfig;
    newFilterModel.filters = newFilterModel.filters.filter(f => !(this.filterConfig.excludeFields?.concat(['ReaderOrgs', 'ReaderCompany']) || []).includes(f.field));
    return newFilterModel;
  }
  private checkRouteDataExists(): boolean {
    if (this.$route) {
      this.routeData = <ReaderCoRouteData>{
        id: this.$route.params['id'].toString(),
        orgName: revertSanitizedUrlParam(this.$route.params['orgName'].toString()),
        companyName: revertSanitizedUrlParam(this.$route.params['company'].toString()),
      };
    }
    return !this.routeData.companyName && !this.routeData.id;
  }
  private async _setOrgOrCompanyFilter(filterModel: FilterModel) {
    if (this.$route) {
      this.routeData = <ReaderCoRouteData>{
        id: this.$route.params['id'].toString(),
        orgName: this.$route.params['orgName'].toString(),
        companyName: this.$route.params['company'].toString(),
      };
    }
    this.isRouteDataEmpty = this.checkRouteDataExists();
    const newFilterModel = cloneDeep(filterModel);
    let orgOrCompanyFilter =
      findFilter<Filter>(newFilterModel.filters, 'ReaderOrgs') ||
      findFilter<Filter>(newFilterModel.filters, 'ReaderCompany');
    if (orgOrCompanyFilter && this.isRouteDataEmpty) {
      this.orgOrCompanyFilter = orgOrCompanyFilter;
      return; // Use Default Org Filter
    }
    if (this.isRouteDataEmpty) {
      // get most active, update filters, perform search
      const mostActiveOrg = await ReaderCoService.getMostActiveOrg(filterModel);
      orgOrCompanyFilter = this._getOrgOrCompanyFilter(mostActiveOrg);
    } else {
      // fill out filters from route data
      const name = this.routeData.orgName || this.routeData.companyName || '';
      const orgLookup = { id: this.routeData.id || '', name: decodeURI(name) };
      orgOrCompanyFilter = this._getOrgOrCompanyFilter(orgLookup);
    }
    this.orgOrCompanyFilter = orgOrCompanyFilter;
  }
  closePanel() {
    this.showFilter = false;
  }
}
</script>

<style lang="scss"></style>
