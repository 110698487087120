﻿import * as Highcharts from 'highcharts';

export default function setHighChartPlotSize() {
    (function (H) {
        H.wrap(H.Chart.prototype, 'setChartSize', function (proceed, skipAxes) {
            // eslint-disable-next-line  @typescript-eslint/no-this-alias
            var chart = this,
                inverted = chart.inverted,
                renderer = chart.renderer,
                chartWidth = chart.chartWidth,
                chartHeight = chart.chartHeight,
                optionsChart = chart.options.chart,
                spacing = chart.spacing,
                clipOffset = chart.clipOffset,
                clipX,
                clipY,
                plotLeft,
                plotTop,
                plotWidth,
                plotHeight,
                plotBorderWidth,
                plotAreaWidth = chart.options.chart.plotAreaWidth,
                plotAreaHeight = chart.options.chart.plotAreaHeight;


            if (plotAreaWidth) {
                chart.plotWidth = plotWidth = plotAreaWidth;
                chart.plotLeft = plotLeft = Math.round((chartWidth - plotAreaWidth) / 2);
            } else {
                chart.plotLeft = plotLeft = Math.round(chart.plotLeft);
                chart.plotWidth = plotWidth = Math.max(0, Math.round(chartWidth - plotLeft - chart.marginRight));
            }
            if (plotAreaHeight) {
                chart.plotTop = plotTop = Math.round((chartHeight - plotAreaHeight) / 2);
                chart.plotHeight = plotHeight = plotAreaHeight;
            } else {
                chart.plotTop = plotTop = Math.round(chart.plotTop);
                chart.plotHeight = plotHeight = Math.max(0, Math.round(chartHeight - plotTop - chart.marginBottom));
            }

            chart.plotSizeX = inverted ? plotHeight : plotWidth;
            chart.plotSizeY = inverted ? plotWidth : plotHeight;

            chart.plotBorderWidth = optionsChart.plotBorderWidth || 0;

            // Set boxes used for alignment
            chart.spacingBox = renderer.spacingBox = {
                x: spacing[3],
                y: spacing[0],
                width: chartWidth - spacing[3] - spacing[1],
                height: chartHeight - spacing[0] - spacing[2]
            };
            chart.plotBox = renderer.plotBox = {
                x: plotLeft,
                y: plotTop,
                width: plotWidth,
                height: plotHeight
            };

            plotBorderWidth = 2 * Math.floor(chart.plotBorderWidth / 2);
            clipX = Math.ceil(Math.max(plotBorderWidth, clipOffset[3]) / 2);
            clipY = Math.ceil(Math.max(plotBorderWidth, clipOffset[0]) / 2);
            chart.clipBox = {
                x: clipX,
                y: clipY,
                width: Math.floor(chart.plotSizeX - Math.max(plotBorderWidth, clipOffset[1]) / 2 - clipX),
                height: Math.max(0, Math.floor(chart.plotSizeY - Math.max(plotBorderWidth, clipOffset[2]) / 2 - clipY))
            };

            if (!skipAxes) {
                Highcharts.each(chart.axes, function (axis) {
                    axis.setAxisSize();
                    axis.setAxisTranslation();
                });
            }
        });
    }(Highcharts));
}

export function setRoundChartCornersStacked() {
    (function (H) {
        H.wrap(H.seriesTypes.column.prototype, 'drawPoints', function (proceed) {
            let seriesIndex = this.index,
                firstIndex = this.chart.series[0].index,
                lastIndex = this.chart.series[this.chart.series.length - 1].index,
                borderRadius = this.options.borderRadius;
            this.options.borderRadius = 0;
            this.points.forEach(function (point) {
                if (seriesIndex !== firstIndex && seriesIndex !== lastIndex || seriesIndex !== firstIndex + 1) {
                    if (point.y > 0) {
                        point.shapeArgs.y -= borderRadius;
                        point.shapeArgs.height += borderRadius * 2;
                    }
                }
            });
            proceed.apply(this, Array.prototype.slice.call(arguments, 1));
            this.points.forEach(function (point) {
                if (seriesIndex === firstIndex || seriesIndex !== firstIndex + 1) {
                    point.graphic?.attr({
                        r: borderRadius
                    });
                }
            });
        });
    }(Highcharts));

}
export function setRoundChartCornersBarChart() {
    (function (H) {
        var rel = H.relativeLength;

        H.wrap(H.seriesTypes.column.prototype, 'translate', function (proceed) {
            var options = this.options,
                topMargin = options.topMargin || 0,
                bottomMargin = options.bottomMargin || 0;

            proceed.call(this);

            this.points.forEach(function (point) {
                var shapeArgs = point.shapeArgs,
                    w = shapeArgs.width,
                    h = shapeArgs.height,
                    x = shapeArgs.x,
                    y = shapeArgs.y;

                // Get the radius
                var rTopLeft = rel(options.borderRadiusTopLeft || 0, w),
                    rTopRight = rel(options.borderRadiusTopRight || 0, w),
                    rBottomRight = rel(options.borderRadiusBottomRight || 0, w),
                    rBottomLeft = rel(options.borderRadiusBottomLeft || 0, w);

                if (rTopLeft || rTopRight || rBottomRight || rBottomLeft) {
                    var maxR = Math.min(w, h) / 2;

                    if (rTopLeft > maxR) {
                        rTopLeft = maxR;
                    }

                    if (rTopRight > maxR) {
                        rTopRight = maxR;
                    }

                    if (rBottomRight > maxR) {
                        rBottomRight = maxR;
                    }

                    if (rBottomLeft > maxR) {
                        rBottomLeft = maxR;
                    }

                    // Preserve the box for data labels
                    point.dlBox = point.shapeArgs;

                    point.shapeType = 'path';
                    point.shapeArgs = {
                        d: [
                            ['M', x + rTopLeft, y + topMargin],
                            // top side
                            ['L', x + w - rTopRight, y + topMargin],
                            // top right corner
                            ['C', x + w - rTopRight / 2, y, x + w, y + rTopRight / 2, x + w, y + rTopRight],
                            // right side
                            ['L', x + w, y + h - rBottomRight],
                            // bottom right corner
                            ['C', x + w, y + h - rBottomRight / 2, x + w - rBottomRight / 2, y + h, x + w - rBottomRight, y + h + bottomMargin],
                            // bottom side
                            ['L', x + rBottomLeft, y + h + bottomMargin],
                            // bottom left corner
                            ['C', x + rBottomLeft / 2, y + h, x, y + h - rBottomLeft / 2, x, y + h - rBottomLeft],
                            // left side
                            ['L', x, y + rTopLeft],
                            // top left corner
                            ['C', x, y + rTopLeft / 2, x + rTopLeft / 2, y, x + rTopLeft, y],
                            ['Z']
                        ]
                    };
                }

            });
        });
    }(Highcharts));

}


export function renderSplineChart(chartInstance, imageUrl) {

    chartInstance.renderer.text('No results found', chartInstance.plotSizeX / 2 - 20, 165).css(getTextHeaderCss())
        .attr({ zIndex: 99999 })
        .add();
    chartInstance.renderer.text('<br/>Try adjusting your filter to find what ', chartInstance.plotSizeX / 2 - 70, 200)
        .css(getTextBodyCss())
        .add();
    chartInstance.renderer.text('you are looking for.', chartInstance.plotSizeX / 2 - 20, 220)
        .css(getTextBodyCss())
        .add();
    chartInstance.renderer.image(imageUrl, chartInstance.plotSizeX / 2, 70, 70)
        .attr({ zIndex: 99999 })
        .add();

}

export function renderBarChart(chartInstance, imageUrl) {

    chartInstance.noData = chartInstance.renderer.
        image(imageUrl, chartInstance.plotLeft + chartInstance.plotWidth / 2 - 110, chartInstance.plotTop + chartInstance.plotHeight / 2 - 80).
        attr({ zIndex: 99999 }).
        add();
    chartInstance.engagementLabel = chartInstance.renderer.text('Engagements', chartInstance.plotLeft + chartInstance.plotWidth / 2 - 35, chartInstance.plotTop + chartInstance.plotHeight / 2 + 195)
        .css(getFooterTextCss())
        .attr({ zIndex: 99999 })
        .add();
}



export function renderColumnChart(chartInstance, imageUrl) {
    chartInstance.noData = chartInstance.renderer.image(imageUrl, chartInstance.plotLeft + chartInstance.plotWidth / 2 - 100, chartInstance.plotTop + chartInstance.plotHeight / 2 - 80)
        .attr({ zIndex: 99999 })
        .add();

}

export function renderPieChart(chartInstance, imageUrl) {

    chartInstance.noData = chartInstance.renderer.image(imageUrl, chartInstance.plotLeft + chartInstance.plotWidth / 2 - 108, chartInstance.plotTop + chartInstance.plotHeight / 2 - 30)
        .attr({ zIndex: 99999 })
        .add();

}

export function getTextHeaderCss() {
    return {
        color: '#76869A',
        fontSize: '8px',
        'text-align': 'center',
        font: 'normal normal normal 16px/18px Overpass',
        'letter-spacing': '0px',
        opacity: 0.8
    };

}
export function getFooterTextCss() {
    return {
        color: '#76869A',
        'text-align': 'middle',
        font: 'normal normal normal 11px/13px Overpass',
        'letter-spacing': '0px',
        opacity: 0.8
    };
}

export function getTextBodyCss() {
    return {
        color: '#76869A',
        fontSize: '8px',
        'text-align': 'middle',
        font: 'normal normal normal 14px/17px Overpass',
        'letter-spacing': '0px',
        opacity: 0.8
    };
}
