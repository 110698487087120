<template>
  <form @submit.prevent="addContact(!v$.$invalid)">
    <div class="grid">
      <div class="col-12 pb-3">
        <Message :closable="false">
          Who in your company should receive access to Templeton? Please enter the contact details of each person below.
        </Message>
      </div>
      <div class="flex col-12">
        <div class="col-3">
          <span class="p-float-label p-fluid">
            <InputText id="firstName" v-model="v$.newContact.firstName.$model" type="text"
              :class="{ 'p-invalid': v$.newContact.firstName.$invalid && submitted }" />
            <label for=" firstName" :class="{ 'p-error': v$.newContact.firstName.$invalid && submitted }">First
              Name</label>
          </span>
          <small v-if="(v$.newContact.firstName.$invalid && submitted) || v$.newContact.firstName.$pending.$response"
            class="p-error">
            {{ v$.newContact.firstName.required.$message.replace('Value', 'First Name') }}
          </small>
        </div>
        <div class="col-3 field">
          <span class="p-float-label p-fluid">
            <InputText id="lastName" v-model="v$.newContact.lastName.$model" type="text"
              :class="{ 'p-invalid': v$.newContact.lastName.$invalid && submitted }" />
            <label for=" lastName" :class="{ 'p-error': v$.newContact.lastName.$invalid && submitted }">Last
              Name</label>
          </span>
          <small v-if="(v$.newContact.lastName.$invalid && submitted) || v$.newContact.lastName.$pending.$response"
            class="p-error">
            {{ v$.newContact.lastName.required.$message.replace('Value', 'Last Name') }}</small>
        </div>
        <div class="col-3 field">
          <span class="p-float-label p-fluid">
            <InputText id="email" v-model="v$.newContact.email.$model" type="text"
              :class="{ 'p-invalid': v$.newContact.email.$invalid && submitted }" />
            <label for=" email" :class="{ 'p-error': v$.newContact.email.$invalid && submitted }">Email</label>
          </span>
          <small v-if="(v$.newContact.email.required.$invalid && submitted) || v$.newContact.email.$pending.$response"
            class="p-error">
            {{ v$.newContact.email.required.$message.replace('Value', 'Email') }}</small>
          <small v-if="(submitted && v$.newContact.email.email.$invalid)" class="p-error">
            {{ v$.newContact.email.email.$message.replace('Value', 'Email') }}
          </small>
          <small v-if="(submitted && v$.newContact.email.emailMustBeUnique.$invalid)" class="p-error">
            {{ v$.newContact.email.emailMustBeUnique.$message }}
          </small>
        </div>
        <div class="col-2">
          <Button type="submit" label="Add Contact" class="p-button-success p-button-raised">
            <span class="material-icons pr-2"> add </span>
            Add Contact
          </Button>
        </div>
      </div>
      <div v-if="data1.length > 0" class="col-12 pb-10">
        <div>
          <h3>Manage Recipients</h3>
        </div>
        <DataTable v-model:selection="selectedItems" :value="data1" data-key="id" :lazy="true">
          <column>
            <template #body="slotProps">
              <avatar class="two-char-avatar" shape="circle" style="color:#76869A">
                <span style="font-weight: bold">
                  {{ getInitials(slotProps) }} </span>
              </avatar>
            </template>
          </column>
          <column>
            <template #body="slotProps">
              {{ slotProps.data.firstName + ' ' + slotProps.data.lastName }}
            </template>
          </column>
          <column header="Email" field="email" />
          <Column>
            <template #body="slotProps">
              <i class="pi pi-trash" aria-haspopup="true" aria-controls="overlay_menu"
                @click="showRemoveConfirmation($event, slotProps.data)" />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </form>
  <div v-if="data1.length > 0" class="mb-2 flex flex-row-reverse">
    <Button ref="goForward" label="Save and Continue" type="button" class="px-4 p-button-success p-button-raised"
      @click="nextStep" />
  </div>
</template>
<script lang="ts">
import axios from 'axios';
import { ref, toRefs, onMounted, reactive } from 'vue';
import { email, required, helpers } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { onSuccess } from '@/utils/toast/toastUtils';

interface Contact { id: number; firstName: string; lastName: string; email: string; }
export default {
  props: {
    formId: {
      type: String,
    },
  },
  emits: ['step-completed', 'next-page'],
  setup(props, { emit }) {
    onMounted(() => {
      formIdChanged();
    });

    const state = reactive({
      newContact: {
        firstName: '',
        lastName: '',
        email: ''
      }
    });

    const emailMustBeUnique = (email: string) => !data1.value.some(x => x.email === email);

    const rules = {
      newContact: {
        firstName: { required },
        lastName: { required },
        email: { required, email, emailMustBeUnique: helpers.withMessage('Email is already added, must be unique.', emailMustBeUnique) }
      }
    };

    let selectedItems = ref([]) as any;
    let data1 = ref<Contact[]>([]);
    let newContact = ref({});
    let { formId } = toRefs(props);
    const submitted = ref(false);
    const toast = useToast();
    const confirm = useConfirm();

    const v$ = useVuelidate(rules, state);

    const formIdChanged = async () => {
      try {
        const url = `/api/onboarding/${formId.value}/company-contacts`;
        data1.value = (await axios.get<any[]>(url)).data as any;
      } catch (err) {
        console.log(err);
        throw (err);
      }
    };

    const showRemoveConfirmation = async (event, item: any) => {
      confirm.close();
      confirm.require({
        target: event.currentTarget,
        message: 'Are you sure you want to delete this contact?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Continue',
        rejectLabel: 'Cancel',
        accept: () => {
          remove(item);
        },
        reject: () => {
          //callback to execute when user rejects the action
        }
      });
    };
    const remove = async (item: any) => {
      const index = data1.value.findIndex(x => x === item);
      data1.value.splice(index, 1);
      onSuccess(toast, `Deleted contact '${item.email}'`);
    };

    const addContact = (isFormValid) => {
      submitted.value = true;
      if (!isFormValid) {
        return;
      }
      const contact = <Contact>Object.assign({}, v$.value.newContact.$model);
      data1.value.push(contact);
      state.newContact = { firstName: '', lastName: '', email: '' };
      submitted.value = false;
      newContact.value = {};
    };

    const save = async () => {
      try {
        const url = `/api/onboarding/${formId.value}/company-contacts`;
        const response = await axios.post(url, data1.value);
        emit('step-completed', response.data);
      } catch (error) {
        console.log(error);
        throw (error);
      }
    };

    const nextStep = () => {
      save();
      emit('next-page', { pageIndex: 3 });
      emit('step-completed', 3);
    };

    const getInitials = (slotProps) => Array.from(slotProps.data.firstName.toUpperCase())[0] + '' + Array.from(slotProps.data.lastName.toUpperCase())[0];
    return { state, v$, submitted, selectedItems, data1, newContact, addContact, showRemoveConfirmation, save, nextStep, getInitials };
  }
};
</script>
<style lang="scss" scoped>

</style>
