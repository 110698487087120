import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "flex col-12 justify-content-between" }
const _hoisted_3 = { class: "line-height-2" }
const _hoisted_4 = { class: "col-12 pt-2" }
const _hoisted_5 = ["title", "href"]
const _hoisted_6 = ["src"]
const _hoisted_7 = { style: {"height":"220px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_cp_no_datatable_records = _resolveComponent("cp-no-datatable-records")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.title), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_DataTable, { value: _ctx.topMetrics }, {
        empty: _withCtx(() => [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_cp_no_datatable_records)
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            field: "title",
            header: "Content Title",
            class: "truncate-text",
            style: { width: '36%' }
          }, {
            body: _withCtx((slotProps) => [
              _createElementVNode("a", {
                target: "_blank",
                title: slotProps.data.name,
                href: slotProps.data.customFields.documentUrl
              }, _toDisplayString(slotProps.data.name), 9, _hoisted_5)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            field: "type",
            header: "Type",
            style: {"width":"17%"}
          }, {
            body: _withCtx((slotProps) => [
              _createTextVNode(_toDisplayString(slotProps.data["customFields"]["docTypeName"]), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            field: "stage",
            header: "Stage",
            style: {"width":"5%"}
          }, {
            body: _withCtx((slotProps) => [
              _createElementVNode("img", {
                src: _ctx.getBuyersJourneyBadge(slotProps.data['customFields']['buyersJourney'])?.icon,
                width: "55"
              }, null, 8, _hoisted_6)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            field: "readers",
            header: "Unique Readers",
            style: {"width":"10%"},
            class: "text-center"
          }, {
            body: _withCtx((slotProps) => [
              _createTextVNode(_toDisplayString(slotProps.data["customFields"]["uniqueReaders"]), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value"])
    ])
  ]))
}