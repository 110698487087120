import { IntLookup } from '@/models/lookup';
import { NotificationType } from '../notificationType';

export class NotificationDashboardRow {
  id: string;
  notificationId: string;
  sasUrl: string;
  reportFileName: string;
  notificationName: string;
  description: string;
  companies: IntLookup[];
  runOn: Date;
  isRead: boolean;
  disabled: boolean;
  disabledReason: string;
  editable: boolean;
  notificationCreatedBy: string;
  totalMatched: number;
  type: NotificationType;

  constructor(props: Partial<NotificationDashboardRow>) {
    Object.assign(this, props);
    this.companies = this.companies || [];
    if (typeof props.runOn === 'string') {
      const a = /\/Date\((\d*)\)\//.exec(props.runOn);
      if (a) {
        this.runOn = new Date(+a[1]);
      }
    }
  }

  get editTooltip(): string {
    return this.disabled ? `Cannot Edit due to ${this.disabledReason}` : `Configure`;
  }

  get editUrl(): string {
    return `/notification/${this.notificationId}/1`;
  }

  get detailsUrl(): string {
    return `/notifications/dashboard/${this.notificationId}/${this.id}`;
  }
}
