<template>
  <span class="p-float-label">
    <Calendar id="date" v-model="local" autocomplete="off" :show-button-bar="true" date-format="mm/dd/yy" :number-of-months="numberOfMonths"
      :class="{ 'p-invalid': v$.$invalid && submitted }"
      append-to="self" @date-select="updateDate" @clear-click="clearDate" @blur="handleManualDateChange" />
    <label for="date">{{ label }}</label>
  </span>
</template>

<script lang="ts">
import dayjs from 'dayjs';
import { clone } from 'lodash';
import { ref } from 'vue';
import { CalendarBlurEvent } from 'primevue/calendar';

export default {
  name: 'CpDateInput',
  props: {
    label: { type: String, default: null },
    modelValue: { type: Date, default: new Date() },
    submitted: Boolean,
    numberOfMonths: { type: Number, default: 1 },
    v: { type: Object, required: true },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const data = ref<Date | null>(props.modelValue ? clone(dayjs(props.modelValue)?.toDate()) : null);

    const updateDate = (d: Date) => {
        emit('update:modelValue', d);
    };
    
    const clearDate = (e: Event) => {
      console.debug(`DateInput.clearDate()- { e }`, e);
      emit('update:modelValue', null);
    };
    
    const handleManualDateChange = (e: CalendarBlurEvent) => {
        if (e.value !== dayjs(props.modelValue).format('MM/DD/YYYY'))
        {
            emit('update:modelValue', new Date(e.value));
        }
    };

    return { local: data, updateDate, clearDate, handleManualDateChange, v$: props.v };
  }
};
</script>

<style lang="scss" scoped>

</style>
