import { createStore } from 'vuex';
import { authModule } from './modules/auth.module';
import { userModule } from './modules/user.module';
import { msgModule } from './modules/msg.module';
import { searchModule } from './modules/search.module';
import { notificationModule } from './modules/notification.module';
import { userTokenModule } from './modules/userToken.module';
import { activityFieldsModule } from './modules/activityFields.module';
export default createStore<any>({
  // state: {},
  // getters: {},
  // mutations: {},
  // actions: {},
  modules: {
    msg: msgModule,
    auth: authModule,
    search: searchModule,
    notification: notificationModule,
    user: userModule,
    userStore: userTokenModule,
    activityFields: activityFieldsModule
  },
});
