import { Quality, SEQUENCE_LOOKUPS, Sequence } from '../activity-dissemination';
import { ActivityType } from '@/models/activity-review/activityType';
import { AttendeeType } from '@/models/activity-review/attendeeType';
import { sanitizeUrlParam } from '@/utils/urlUtils';
import { Badge } from '../analytics/badge';
import { Specifics } from '@/models/analytics/Specifics';
import { BUYERS_JOURNEY_MAP } from '../buyersJourney';
import { CONTENT_TYPE_MAP } from '../contentType';
import { activityCodeByType } from '@/models/activity-review/activityCodeByType';


export class EngagementSearchResultItem {
  id: string;
  date: Date;
  formattedDate: string;
  userId?: string;
  email: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  company: string;
  orgId: string;
  orgName: string;
  docTitle: string;
  online: string;
  portal: string;
  shareCount: string;
  friendlyId: string;
  quality: string;
  sequence: string;
  readonly sequenceName: string;
  activityType: ActivityType;
  readonly fullName: string;
  readonly activityTypeBadge?: Badge;
  specifics: string;
  specificsModel: Specifics;
  readonly qualityNum?: Quality;
  readonly qualityBadge?: Badge;
  docType: string;
  buyersJourney?: string;
  readonly buyersJourneyBadge?: Badge;
  contentType?: string[];
  companyTypes: string[];
  attendeeType: AttendeeType;
  attendeeTypeName: string;

  constructor(data: Partial<EngagementSearchResultItem> | null = null) {
    if (data) {
      Object.assign(this, data);
    }

    switch (this.activityType) {
      case 'Engagement':
        this.activityTypeBadge = { icon: require('../../assets/icons/engagement.png'), title: 'Engagement' };
        break;
      case 'CustomerRequest':
        this.activityTypeBadge = { icon: require('../../assets/icons/customer-request.png'), title: 'Customer Request' };
        break;
      case 'Webinar':
        this.activityTypeBadge = { icon: require('../../assets/icons/webinar.png'), title: 'Webinar' };
        break;
      case 'Event':
        this.activityTypeBadge = { icon: require('../../assets/icons/events.png'), title: 'Event' };
        break;
    }

    switch (this.buyersJourney) {
      case '951fe812-3bb2-45e1-86a4-bcce143115ff':
        this.buyersJourneyBadge = { icon: require('../../assets/icons/early.png'), title: 'Early' };
        break;
      case '2378a4d7-345c-4a78-8ca7-68d8999b18eb':
        this.buyersJourneyBadge = { icon: require('../../assets/icons/middle.png'), title: 'Middle' };
        break;
      case 'b935dd05-e194-497f-b9aa-20a54e7730d1':
        this.buyersJourneyBadge = { icon: require('../../assets/icons/late.png'), title: 'Late' };
        break;
    }


    this.qualityNum = Quality[this.quality];

    switch (this.qualityNum) {
      case Quality.Level1:
        this.qualityBadge = { icon: require('../../assets/icons/quality1.png'), title: 'Quality 1' };
        break;
      case Quality.Level2:
        this.qualityBadge = { icon: require('../../assets/icons/quality2.png'), title: 'Quality 2' };
        break;
      case Quality.Level3:
        this.qualityBadge = { icon: require('../../assets/icons/quality3.png'), title: 'Quality 3' };
        break;
    }

    this.fullName = `${this.firstName || ''} ${this.lastName || ''}`.trim() || this.email;
    this.sequenceName = SEQUENCE_LOOKUPS.find((x) => x.id == Sequence[this.sequence])?.name ?? '';
  }

  get buyersJourneyName(): string {
    if (!this.buyersJourney) {
      return '';
    }
    return BUYERS_JOURNEY_MAP[this.buyersJourney];
  }
  get specificsIncluded(): boolean {
    return !!this.specifics;
  }

  get contentTypeName() {
    return this.contentType ? CONTENT_TYPE_MAP[this.contentType[0]] : '';
  }

  public get qualifiedId() {
    const activityCode = activityCodeByType(this.activityType);
    return `${activityCode}${this.id}`;
  }

  get readerCompanyUrl() {
    const encodedCompanyName = sanitizeUrlParam(this.company);
    const encodedOrgName = sanitizeUrlParam(this.orgName);
    const route = `/analytics/reader-co-dashboard/${encodedCompanyName}`;
    return this.orgId ? `${route}/${this.orgId}/${encodedOrgName}` : route;
  }

  get emailUrl() {
    return sanitizeUrlParam(this.email);
  }

  get formatCompanyTypes() {
    return this.companyTypes?.join(', ');
  }
}