import axios from 'axios';
import { IntervalBucket } from '@/models/intervalBucket';
import { NamedSeries } from '@/models/namedSeries';
import { Cacheable } from '@/utils/decorators/cacheable.decorator';
import { FilterModel } from '@/models/search';
import { FieldCode } from '@/models/filters';

export interface ActivityCountValue { secondary: number; primary: number; }

export interface LeadSourceValue {
  organic: number;
  editorial: number;
  customNewsletter: number;
  leadNurtureCampaign: number;
  webinar: number;
  event: number;
}

export interface ClientStats { totalLeads: number; uniquePeople: number; uniqueDocs: number; }

class ClientDashboardService {

  getByMonth(filterModel: FilterModel): Promise<Array<IntervalBucket<ActivityCountValue>>> {
    return axios
      .post(`/api/client-dashboard/by-month`, { filters: filterModel.filters, indices: filterModel.indices.map(x => x.id) })
      .then(r => r.data);
  }

  getUniqueByMonth(filterModel: FilterModel): Promise<Array<IntervalBucket<ActivityCountValue>>> {
    return axios
      .post(`/api/client-dashboard/unique-by-month`, { filters: filterModel.filters, indices: filterModel.indices.map(x => x.id) })
      .then(r => r.data);
  }

  async getActivitiesBySource(filterModel: FilterModel, unique: boolean = false): Promise<Array<IntervalBucket<LeadSourceValue>>> {
    const url = unique ? '/api/client-dashboard/unique-by-source' : '/api/client-dashboard/by-source';
    return (await axios.post(url, { filters: filterModel.filters, indices: filterModel.indices.map(x => x.id) })).data;
  }

  async getActivitiesByField(filterModel: FilterModel, field: FieldCode, size: number): Promise<NamedSeries[]> {
    const url = `/api/client-dashboard/by-field/${field}`;
    return (await axios.post(url, { filters: filterModel.filters, indices: filterModel.indices.map(x => x.id), size })).data;
  }

  @Cacheable({ keyProvider: (filterModel: FilterModel) => JSON.stringify(filterModel) })
  async getMostPopularDocs(filterModel: FilterModel, size: number): Promise<NamedSeries[]> {
    const url = `/api/client-dashboard/top-documents`;
    const metrics = (await axios.post<NamedSeries[]>(url, { filters: filterModel.filters, indices: filterModel.indices.map(x => x.id), size })).data;
    return metrics.filter(m => m.customFields).map(m => {
      m.customFields = m.customFields || {};
      m.customFields['buyersJourney'] = m.customFields['buyersJourney'] || 'Unclassified';
      m.customFields['contentType'] = m.customFields['contentType'] || 'Unclassified';
      m.customFields['documentUrl'] = m.customFields['documentUrl'];  //?
      //'<a href='+ m.customFields['documentUrl'] +' '+' target="_blank">' + m.name +'</a>': m.name;
      return m;
    });
  }

  @Cacheable({ keyProvider: (filterModel: FilterModel) => JSON.stringify(filterModel) })
  async getStats(filterModel: FilterModel): Promise<ClientStats> {
    return (await axios.post(`/api/client-dashboard/stats`, { filters: filterModel.filters, indices: filterModel.indices.map(x => x.id) })).data;
  }
}

export default new ClientDashboardService();
