import { FriendlyFilterRow } from './filterFactory';
import { Filter } from './filter';
import { FieldCode } from './fieldCode';
import { Operation, WithOperation, operationLabel } from './operation';
import { startCase } from 'lodash';

export class TextFilter implements Filter, WithOperation {
  field: FieldCode;
  op: Operation;
  restrictedToOps: Operation[] = [];
  value: string | undefined;

  readonly $type = 'TextFilter';

  constructor(opts: Partial<TextFilter> = {}) {
    Object.assign(this, opts);
    this.value = this.value || undefined;
    this.op = opts.op || 'Equals';
  }

  /**
   * Displays the filter in a user-friendly text format
   * @param labelOverride Explicitly label value
   * @returns
   */
  getFriendlyText(labelOverride: string): FriendlyFilterRow {
    const label = `${labelOverride || startCase(this.field)}`;
    const opStr = operationLabel[this.op];
    const values = <string[]>this.value?.split('\n').filter(x => x);
    const valueStr = values.length === 1 ? `${values[0]}` : `one of (${values.join(',')})`;
    // return `${label} ${opStr} '${valueStr}'`;
    return <FriendlyFilterRow>{ filterName: label, operation: opStr, value: valueStr };
  }

  /**
   * Checks if the text value is empty or is only whitespace
   * @returns boolean
   */
  isEmpty() {
    return !this.value || /^\s+$/.test(unescape(this.value));
  }

  clearValue() {
    this.value = undefined;
  }
}
