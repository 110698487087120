<template>
  <div ref="general" class="grid pb-4">
    <div class="col-12">
      <Card class="p-card-default-filters">
        <template #title>
          General
        </template>
        <template #content>
          <div class="grid px-4 pb-0">
            <div class="col-12 p-fluid pb-3">
              <span class="p-float-label">
                <AutoComplete id="activityType" v-model="modelValue.indices" :multiple="true"
                  :suggestions="filteredIndices" field="name" :dropdown="true" :complete-on-focus="true"
                  @complete="searchActivityType($event)" @focus="searchActivityType($event)" />
                <label for="activityType">Activity Type</label>
              </span>
            </div>
            <div v-for="row in generalFields" :key="row.items[0].props?.label" class="col-12 p-fluid pb-3">
              <div v-for="f in row.items" :key="f.props?.label">
                <cp-compound-search-field-elements v-if="f.props?.isCompound" :search-field="f" />
                <component :is="f.type" v-if="!f.props?.isCompound" v-bind="f.props" v-model="f.model" />
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>

  <div v-if="demographicFields != null" ref="demographic" class="grid pb-4">
    <div class="col-12">
      <Card class="p-card-default-filters card-active">
        <template #title>
          Demographic
        </template>
        <template #content>
          <div class="grid px-4 pb-0">
            <div v-for="(row, i) in demographicFields" :key="row.items[0].props?.label"
              :class="getClass('main', row.items.length, i, row.items.some(x => x.props?.isCompound))">
              <div v-for="(f, j) in row.items" :key="f.props?.label" :class="getClass('child', row.items.length, j)">
                <cp-compound-search-field-elements v-if="f.props?.isCompound" :search-field="f" />
                <component :is="f.type" v-if="!f.props?.isCompound" v-bind="f.props" v-model="f.model" />
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>

  <div v-if="allFirmographicFields != null" ref="firmographic" class="grid pb-4 card-active">
    <div class="col-12">
      <Card class="p-card-default-filters">
        <template #title>
          Firmographic
        </template>
        <template #content>
          <cp-reader-org-search-field v-if="isReaderLookUpExists" ref="childRef" @filter-changed="orgFilterChanged" />
          <div class="grid px-4 pb-0">
            <div v-for="(row, i) in allFirmographicFields" :key="row.items[0].props?.label"
              :class="getClass('main', row.items.length, i, row.items.some(x => x.props?.isCompound))">
              <div v-for="(f, j) in row.items" :key="f.props?.label" :class="getClass('child', row.items.length, j)">
                <cp-compound-search-field-elements :is="f.type" v-if="f.props?.isCompound" v-bind="f.props"
                  :search-field="f" />
                <component :is="f.type" v-if="!f.props?.isCompound" v-bind="f.props" v-model="f.model" />
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>

  <div v-if="contextualFields != null" ref="contextual" class="grid pb-4">
    <div class="col-12">
      <Card class="p-card-default-filters">
        <template #title>
          Contextual
        </template>
        <template #content>
          <div class="grid px-4 pb-0">
            <div v-for="(row, i) in contextualFields" :key="row.items[0].props?.label" class=""
              :class="getClass('main', row.items.length, i, row.items.some(x => x.props?.isCompound))">
              <div v-for="(f, j) in row.items" :key="f.props?.label" :class="getClass('child', row.items.length, j)">
                <cp-compound-search-field-elements v-if="f.props?.isCompound" :search-field="f" />
                <component :is="f.type" v-if="!f.props?.isCompound" v-bind="f.props" v-model="f.model" />
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script lang="ts">
import { FILTER_COMPONENTS } from '@/components/searchFields';
import { initSearchFilter } from '@/components/searchFields/metadata';
import { NotificationWorkflowMixin } from '@/mixins/notificationWorkflow.mixin';
import { FiltersMixin } from '@/mixins/filters.mixin';
import { NotificationSetFiltersRequest, WorkflowStepEnum } from '@/models/notification';
import notificationService from '@/services/notificationService';
import { Mixins } from 'vue-property-decorator';
import { INDEX_LOOKUPS } from '@/models/analytics/activityTypes';
import { FieldCode, Filter, TermsFilter } from '@/models/filters';
import NotificationStepperTitleBar from './common/NotificationStepTitleBar.vue';
import { DefaultSearchMixin } from '@/mixins/defaultSearch.mixin';
import { Lookup } from '@/models/lookup';
import { Options } from 'vue-class-component';
import { DEFAULT_TIMEOUT_TIME } from '@/models/common/constants';
import ReaderOrgSearchField from '../searchFields/readerOrgSearchField.vue';
import { findFilter } from '@/utils/filters';
import { Watch } from 'vue-property-decorator';

const MINIMUM_REQUIRED_FILTERS_MESSAGE: string =
  'At least two filters are required to create a notification. Please add an additional filter to the notification (that is not activity type) to continue';
@Options({
  name: 'cp-notification-define-filters-step',
  components: {
    ...FILTER_COMPONENTS,
    'cp-stepper-title-bar': NotificationStepperTitleBar,
    'cp-reader-org-search-field': ReaderOrgSearchField
  },
})
export default class NotificationDefineFiltersStep extends Mixins(
  NotificationWorkflowMixin,
  FiltersMixin,
  DefaultSearchMixin
) {
  activityTypes: Array<Lookup<string>> = INDEX_LOOKUPS;
  activityTypeQuery = '';
  filteredIndices: Array<Lookup<string>> = [];
  isReaderLookUpExists: boolean;

  async mounted() {
    this.isReaderLookUpExists = this.userActivitySearchFields.includes('ReaderOrgsLookup');
    this.$emit('is-loaded', false);
    await this.dataReady;
    this.$emit('is-loaded', true);
    this._loadFilters();
  }

  /**
   * Calls the Liberty Bell API and saves the filters to the loaded Notification
   *
   * @returns Notification Id
   */
  async save(): Promise<string> {
    try {
      const filters = this.getSearchFieldFilters();
      if (filters.length === 0) {
        this.$toast.add({
          severity: 'error',
          summary: '',
          detail: MINIMUM_REQUIRED_FILTERS_MESSAGE,
          life: 5000,
          closable: true,
        });
        return Promise.reject();
      }

      if (this.modelValue.indices.length === 0) {
        this.$toast.add({
          severity: 'error',
          summary: '',
          detail: 'Activity type is required',
          life: 5000,
          closable: true,
        });
        return Promise.reject();
      }

      const req: NotificationSetFiltersRequest = {
        filters,
        indices: this.modelValue.indices,
        lastStepCompleted: WorkflowStepEnum.DefineFilters
      };
      if (!this.modelValue.isReadOnly) {
        await notificationService.setFilters(this.modelValue.id, req);
      }
      this.$emit('update:modelValue', this.modelValue);
      return Promise.resolve(this.modelValue.id);
    } catch (err) {
      // Send error message back to parent
      this.showError([this.ERROR_MESSAGES.save, this.ERROR_MESSAGES.sysAdmin]);
      return Promise.reject(err);
    }
  }

  async orgFilterChanged(model: Array<Lookup<any>>) {
    let readerOrgFilter: TermsFilter = findFilter<TermsFilter>(this.modelValue.filters, 'ReaderOrgs');
    if (!readerOrgFilter) {
      readerOrgFilter = new TermsFilter({ field: 'ReaderOrgs', value: model });
    }
    const notification = this.getSearchFieldFilters() as Filter[];
    notification.push(readerOrgFilter);
    this.searchFields = initSearchFilter(this._notificationFields, notification);
  }

  @Watch('defaultsLoaded', { immediate: true })
  defaultsLoadedDone(val: boolean) {
    if (val) {
      this._loadFilters();
    }
  }

  getClass(scope: string, length: number, index: number, isCompound: boolean = false): string {
    if (scope == 'main') {
      return isCompound ? 'col-12 p-fluid ml-0' : (length > 1 ? 'grid' : '') + ' col-12 p-fluid ml-0 pb-3';
    }
    if (scope == 'child') {
      return length > 1
        ? 'col-' + 12 / length + (index % 2 == 0 ? ' pl-0 pr-3 pb-0' : ' pl-3 pr-0 pb-0')
        : '';
    }
    return '';
  }

  searchActivityType(event) {
    setTimeout(() => {
      if (!event.query.trim().length) {
        this.filteredIndices = this.activityTypes.filter((e1) => this.modelValue.indices.findIndex((e2) => e2.name == e1.name) === -1);
      } else {
        const notSelected = this.activityTypes.filter((e1) => this.modelValue.indices.findIndex((e2) => e2.name == e1.name) === -1);

        this.filteredIndices = notSelected.filter((type) => type.name.toLowerCase().startsWith(event.query.toLowerCase()));
      }
    }, DEFAULT_TIMEOUT_TIME);
  }

  private _loadFilters() {
    const notification = this.modelValue;
    //For newly created notification we need to use default filters instead of notification filters (because they are not set),
    //however there is a technical smell on how to determine whether the notification is newly create, thus checking for existence of 'Clients' filter
    const isNew = !findFilter<TermsFilter>(notification.filters, 'Clients');
    if (isNew) {
      this.searchFields = initSearchFilter(this._notificationFields, this.initialState.filters);
      this.modelValue.indices = this.initialState.indices;
    }
    else {
      this.searchFields = initSearchFilter(this._notificationFields, notification.filters);
    }
  }

  private get _notificationFields(): FieldCode[] {
    return [...this.userActivitySearchFields, ...this.fields.filter(x => x.accessLevel == 'Basic' && x.isSearchable).map(x => x.field)].filter(
      (x) => x !== 'DateRange' && x !== 'RelativeDateRange' && x !== 'ActivityTypes'
    );
  }
}
</script>

<style lang="scss"></style>
