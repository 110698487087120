export class SingleAnswerQuestion implements AnsweredQuestion {
  question: string;
  answer: string;
  get renderedAnswer(): string {
    return this?.answer ?? '';
  }

  constructor(data: Partial<SingleAnswerQuestion> | null = null) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

export class SingleAnswerQuestionWithAuthor extends SingleAnswerQuestion {
  answeredBy: string;
}

export class MultiAnswerQuestion implements AnsweredQuestion {
  get renderedAnswer(): string {
    return this?.answers?.join(', ') ?? '';
  }
  question: string;
  answers: string[];

  constructor(data: Partial<MultiAnswerQuestion> | null = null) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

export abstract class AnsweredQuestion {
 question: string;
 abstract get renderedAnswer(): string;
}
