import { getBarGradientPerColumn, getColumnGradientMostPopularByOrigin } from '@/utils/highchartUtils';
import { FieldCode } from '@/models/filters/fieldCode';

export const SERIES_LABELS: Record<string, any> = {
    buyersJourney: [
        { name: 'Early', color: getBarGradientPerColumn()[0], tag: 'Early' },
        { name: 'Middle', color: getBarGradientPerColumn()[1], tag: 'Middle' },
        { name: 'Late', color: getBarGradientPerColumn()[2], tag: 'Late' },
        { name: 'Unclassified', color: getBarGradientPerColumn()[3], tag: 'Unclassified' },
    ],
    contentType: [
        { name: 'Unclassified', color: getColumnGradientMostPopularByOrigin('unclassified'), tag: 'Unclassified' },
        { name: 'Your Content', color: getColumnGradientMostPopularByOrigin('your content'), tag: 'Your Content' },
        { name: 'Custom Content', color: getColumnGradientMostPopularByOrigin('custom content'), tag: 'Custom Content' },
        { name: 'Editorial', color: getColumnGradientMostPopularByOrigin('editorial'), tag: 'Editorial' },
    ],
};
export const SERIES_TO_FIELD: Record<string, FieldCode> = {
    buyersJouyney: 'BuyersJourneyStage',
    contentType: 'ContentType',
};
