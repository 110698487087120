export type ClaimAccessLevel = 'None' | 'Basic' | 'Advanced' | 'Admin';

export enum ClaimAccessLevelEnum {
    None = 1,
    Basic,
    Advanced,
    Admin
}

export const NOTIFICATION_CLAIM_LEVELS: ClaimAccessLevel[] = ['None', 'Basic', 'Advanced', 'Admin'];

export const EMPLOYEE_CLAIM_LEVELS: ClaimAccessLevel[] = NOTIFICATION_CLAIM_LEVELS.filter(x => x !== 'Basic');

export const BASIC_NOTIFICATION_CLAIM_LEVELS: ClaimAccessLevel[] = ['None', 'Basic'];

