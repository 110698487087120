<template>
  <div class="grid">
    <div class="flex col-12 justify-content-between">
      <h2 class="line-height-2">
        {{ title }}
      </h2>
    </div>
    <div class="col-12 pt-2">
      <DataTable :value="topMetrics">
        <Column field="title" header="Content Title" class="truncate-text" :style="{ width: '36%' }">
          <template #body="slotProps">
            <a target="_blank" :title="slotProps.data.name" :href="slotProps.data.customFields.documentUrl">{{
              slotProps.data.name
            }}</a>
          </template>
        </Column>
        <Column field="type" header="Type" style="width: 17%">
          <template #body="slotProps">
            {{ slotProps.data["customFields"]["docTypeName"] }}
          </template>
        </Column>
        <Column field="stage" header="Stage" style="width: 5%">
          <template #body="slotProps">
            <img :src="getBuyersJourneyBadge(slotProps.data['customFields']['buyersJourney'])?.icon" width="55">
          </template>
        </Column>
        <Column field="readers" header="Unique Readers" style="width: 10%" class="text-center">
          <template #body="slotProps">
            {{ slotProps.data["customFields"]["uniqueReaders"] }}
          </template>
        </Column>
        <template #empty>
          <div style="height: 220px">
            <cp-no-datatable-records />
          </div>
        </template>
      </DataTable>
    </div>
  </div>
</template>
<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { FilterModel } from '@/models/search';
import { NamedSeries } from '@/models/namedSeries';
import { isEqual } from 'lodash';
import { filterValueExists } from '@/utils/filters';
import contentOverviewDashboardService from '@/services/contentOverviewDashboardService';
import NoDataTableRecordsComponent from '@/components/common/NoDataTableRecordsComponent.vue';

@Options({
  components: {
    'cp-no-datatable-records': NoDataTableRecordsComponent,
  },
})
export default class TopPerformingByBuyersJourney extends Vue {
  @Prop({ default: () => <FilterModel>{ filters: [], indices: [] } })
  public filterModel!: FilterModel;
  @Prop({ default: 5 }) public size!: number;
  topMetrics: NamedSeries[] = [];
  loading = true;

  @Watch('filterModel', { immediate: true })
  async onFilterChange(newVal: FilterModel, oldVal: FilterModel) {
    const noChanges = isEqual(newVal, oldVal);
    if (
      !newVal.filters?.length ||
      !filterValueExists(newVal.filters, 'Clients') ||
      noChanges
    ) {
      return;
    }
    this.loading = true;

    this.topMetrics = await contentOverviewDashboardService.getMostPopularDocsWithUniqueReaders(
      newVal,
      this.size
    );
    this.loading = false;
  }

  getBuyersJourneyBadge(value: string) {
    switch (value.toLowerCase()) {
      case 'early':
        return { icon: require('../../assets/icons/early.png'), title: 'Early' };
      case 'middle':
        return { icon: require('../../assets/icons/middle.png'), title: 'Middle' };
      case 'late':
        return { icon: require('../../assets/icons/late.png'), title: 'Late' };
    }
  }
  get title() {
    return `Top performing Document By Buyer's Journey `;
  }
}
</script>
<style scoped lang="scss"></style>
