import { GuidLookup } from '@/models/lookup';
import { ClaimAccessLevel } from './security/claimLevel';
import { UserRole, UserRoleEnum } from './userRole';
import { CompanyClaim } from './security';
import { FieldCode } from './filters';
import { getInitials, getRoleColor, getRoleTextColor } from '@/utils/userUtils';
import { Online } from './activity-dissemination';

export class AppUser {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  role: UserRole;
  companies: CompanyClaim[];
  communities: Online[];
  isRegistered: boolean;
  reviewActivities: boolean;
  notificationClaimLevel: ClaimAccessLevel;
  onboardingFormId?: string;
  enableApiAccess?: boolean;
  apiClientId?: string;
  activityFields?: FieldCode[];
  analystTags?: GuidLookup[];
  viewNotifications?: boolean;
  accessMarketingPerformance: boolean;

  constructor(fields: Partial<AppUser> = {}) {
    if (fields) {
      Object.assign(this, fields);
    }

    this.companies = this.companies || [];
    this.role = this.role || 'Client';
    this.reviewActivities = this.reviewActivities || false;
    this.notificationClaimLevel = this.notificationClaimLevel || 'None';
    this.viewNotifications = this.viewNotifications || false;
    this.accessMarketingPerformance = this.accessMarketingPerformance || false;
  }

  public get roleColor(): string {
    return getRoleColor(this.role, this.isRegistered);
  }

  public get roleTextColor(): string {
    return getRoleTextColor(this.role, this.isRegistered);
  }

  public get initials(): string {
    return getInitials([this.firstName, this.lastName]);
  }

  public get isRegisteredClient(): boolean {
    return this.isRegistered && this.role === 'Client';
  }

  public get roleEnum(): UserRoleEnum {
    return UserRoleEnum[this.role];
  }
}
