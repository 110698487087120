import { computed } from 'vue';
import { useStore } from 'vuex';
import { FieldCode } from '@/models/filters';
import { JwtTokenUser } from '@/store/models';
import { ClaimAccessLevel } from '@/models/security';
import { UserRoleEnum } from '@/models/userRole';

export default function useAuthenticatedUser() {
  const store = useStore();
  const authenticatedUser = computed(() => <JwtTokenUser>store.state.auth.user);
  const isAuthenticatedAdmin = computed(() => authenticatedUser.value?.roleEnum >= UserRoleEnum.Admin);
  const isAuthenticatedAtLeastInternal = computed(() => authenticatedUser.value?.roleEnum >= UserRoleEnum.Internal);
  const userActivitySearchFields = computed(() => <FieldCode[]>authenticatedUser.value?.activityFields ?? []);
  const userOnlines = computed(() => authenticatedUser.value ? authenticatedUser.value?.communities : null);
  const userCompanies = computed(() => authenticatedUser.value ? authenticatedUser.value?.companies : null);
  const notificationClaimLevel = computed(() => <ClaimAccessLevel | unknown>(+authenticatedUser.value?.notificationClaimLevel));
  const userId = computed(() => authenticatedUser.value.userId);

  return { authenticatedUser, isAuthenticatedAdmin, isAuthenticatedAtLeastInternal, userActivitySearchFields, userId, userOnlines, userCompanies, notificationClaimLevel };
}