import {IntLookup} from '@/models/lookup';

export enum Gated {
  Gated = 1,
  Ungated = 2
}

export const GATED_LOOKUPS: IntLookup[] = [
  {id: 1, name: 'Gated'},
  {id: 2, name: 'Ungated'}
];
