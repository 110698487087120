import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid pt-3 pb-0 vertical-align-middle" }
const _hoisted_2 = {
  for: "bothButton",
  class: "text-sm font-bold mx-2"
}
const _hoisted_3 = { class: "mr-5" }
const _hoisted_4 = {
  for: "both",
  class: "text-sm font-bold"
}
const _hoisted_5 = { class: "mr-5" }
const _hoisted_6 = {
  for: "exists",
  class: "text-sm font-bold"
}
const _hoisted_7 = {
  for: "notExists",
  class: "text-sm font-bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioButton = _resolveComponent("RadioButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString($setup.defaultLabel), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_RadioButton, {
        id: "bothButton",
        modelValue: $setup.bothSelection,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.bothSelection) = $event)),
        name: "both",
        value: $setup.BOTH,
        class: "mr-2",
        onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.updateValue($setup.BOTH)))
      }, null, 8, ["modelValue", "value"]),
      _createElementVNode("label", _hoisted_4, _toDisplayString($props.noSelectionLabel), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_RadioButton, {
        id: "existsButton",
        modelValue: $setup.localSelection,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.localSelection) = $event)),
        name: "exists",
        value: "true",
        class: "mr-2",
        onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.updateValue('true')))
      }, null, 8, ["modelValue"]),
      _createElementVNode("label", _hoisted_6, _toDisplayString($props.existsLabel), 1)
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_RadioButton, {
        id: "notExistsButton",
        modelValue: $setup.localSelection,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.localSelection) = $event)),
        name: "notExists",
        value: "false",
        class: "mr-2",
        onClick: _cache[5] || (_cache[5] = ($event: any) => ($setup.updateValue('false')))
      }, null, 8, ["modelValue"]),
      _createElementVNode("label", _hoisted_7, _toDisplayString($props.notExistsLabel), 1)
    ])
  ]))
}