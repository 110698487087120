import { WorkflowStepEnum } from './../workflowStep';
import { NotificationBehaviorChangeRequest } from './notificationBehaviorChangeRequest';
import { ThresholdDirection } from '.';
import { Notification } from '@/models/notification';
import { InsightType } from './insightType';

export class ChangeInActivitiesNotificationBehaviorChangeRequest implements NotificationBehaviorChangeRequest {
  static fromNotification(n: Partial<Notification>): ChangeInActivitiesNotificationBehaviorChangeRequest {
    const lastStepEnum =   n.lastStepCompleted && WorkflowStepEnum[n.lastStepCompleted] || WorkflowStepEnum.DefineFilters;  
    return new ChangeInActivitiesNotificationBehaviorChangeRequest(
      {
        aggField: n.aggField,
        activityMonths: n.activityMonths,
        uniqueUserCount: n.aggField === InsightType.ReaderOrg ? n.uniqueUserCount : undefined,
        baselineActivityMonths: n.baselineActivityMonths,
        minimumThresholdPercent: n.minimumThresholdPercent,
        minimumBaselineActivities: n.minimumBaselineActivities,
        thresholdDirection: n.thresholdDirection,
        lastStepCompleted: lastStepEnum < WorkflowStepEnum.BehaviorChange ? WorkflowStepEnum.BehaviorChange : lastStepEnum
      });
  }

  aggField: InsightType;
  activityMonths: number;
  uniqueUserCount?: number | undefined;
  baselineActivityMonths?: number;
  minimumThresholdPercent?: number;
  minimumBaselineActivities?: number;
  thresholdDirection: ThresholdDirection;
  lastStepCompleted?: WorkflowStepEnum;
  readonly $type: string = 'ChangeInActivitiesNotificationBehaviorChangeRequest';

  constructor(opts: Partial<ChangeInActivitiesNotificationBehaviorChangeRequest> = {}) {
    Object.assign(this, opts);
    this.$type = 'ChangeInActivitiesNotificationBehaviorChangeRequest';
    this.uniqueUserCount = opts.aggField === InsightType.ReaderOrg ? opts.uniqueUserCount : undefined;
  }
}
