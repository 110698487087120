export type ActivityFieldAccessLevel = 'Basic' | 'Premium' | 'Internal';

export enum ActivityFieldAccessLevelEnum {
  Basic = 1,
  Premium,
  Internal
}

export type Color = 'primary' | 'secondary' | 'grey';

export function getColor(accessLevel: ActivityFieldAccessLevel): Color {
  switch (accessLevel) {
    case 'Internal': return 'primary';
    case 'Premium': return 'secondary';
    default: return 'grey';
  }
}
