<template>
  <div class="flex flex-row-reverse flex-wrap card-container yellow-container mt-3 mr-5 h-2rem mb-5 lg:mb-0">
    <div class="flex align-items-center fixed-header">
      <div v-if="adminAccess" style="margin-right: 0.1rem">
        <Button type="button" label="Toggle" class="p-button-secondary px-1 button-radius-left admin-button"
          @click="toggleAdmin">
          <span class="material-icons text-2xl pl-2"> build </span>
          <span class="material-icons text-2xl p-secondary-color">
            arrow_drop_down
          </span>
        </Button>
        <Menu id="overlay_menu" ref="menuAdmin" :model="secureItemsAdmin" :popup="true">
          <template #item="{ item }">
            <router-link v-if="satisfiesClaim(item.claim)" class="p-menuitem-link py-2" :to="item.link"
              @click="$refs.menuAdmin.toggle(false)">
              {{ item.name }}
            </router-link>
          </template>
        </Menu>
      </div>
      <div>
        <a href="https://knowledgebase.templetoninsights.com" target="_blank" class="no-underline opacity-100">
          <Button type="button" class="button-radius-right text-white faq-button pl-3">
            <span class="material-icons text-2xl"> quiz </span>
          </Button>
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import { Claim } from '@/models/security';
import { JwtTokenUser } from '@/store/models';
import { NOTIFICATION_MODULE } from '@/store/modules/notification.module';
import { Vue, Options } from 'vue-class-component';
import Menu from 'primevue/menu';
import { NavItem } from '@/models/common/navItem';

const INTERNAL_USER = new Claim('role', 'Internal');
const ACTIVITY_REVIEWER = new Claim('reviewActivities');
const ONBOARDING_FORM_MANAGER = new Claim('manageOnboardingForms');
const ADMIN_USER = new Claim('role', 'Admin');

const NAV_ITEMS_ADMIN: NavItem[] = [
  { name: 'Manage Users', link: '/admin/users', claim: INTERNAL_USER },
  {
    name: 'Onboarding Dashboard',
    link: '/admin/onboarding-list',
    claim: ONBOARDING_FORM_MANAGER,
  },
  { name: 'Review Activities', link: '/review-activities', claim: ACTIVITY_REVIEWER }
];

@Options({
  name: 'cp-nav',
  computed: {
    ...mapGetters('auth', [
      'satisfiesClaim',
      'isDisseminationDashboardFeatureEnabled',
    ]),
    ...mapGetters('notification', ['dashboardUnreadCount']),
  },
})
export default class TopBar extends Vue {
  navItemsAdmin = NAV_ITEMS_ADMIN;
  loggedInUser: JwtTokenUser;
  // from vuex
  satisfiesClaim: (claim: Claim) => boolean;
  isDisseminationDashboardFeatureEnabled!: boolean
  dashboardUnreadCount!: number;
  INTERNAL_USER = INTERNAL_USER;

  async created() {
    this.loggedInUser = <JwtTokenUser>this.$store.state.auth.user;
  }

  async mounted() {
    if (this.satisfiesClaim(new Claim('viewNotifications'))) {
      await this.$store.dispatch(
        `notification/${NOTIFICATION_MODULE.GET_DASHBOARD_UNREAD_COUNT}`
      );
    }
  }

  get isAtLeastAdmin() {
    return this.satisfiesClaim({ name: 'role', value: 'Admin' });
  }

  get secureItemsAdmin(): NavItem[] {
    return NAV_ITEMS_ADMIN.filter((i) => !i.claim || this.satisfiesClaim(i.claim));
  }

  get adminAccess() {
    // console.log("admin", NAV_ITEMS_ADMIN.some((x) => !x.claim || this.satisfiesClaim(x.claim)));
    return NAV_ITEMS_ADMIN.some((x) => !x.claim || this.satisfiesClaim(x.claim));
  }

  activeClass(route: string) {
    const overviewRoute = route === '/';

    if (
      (overviewRoute && this.$route.path === route) ||
      (!overviewRoute && this.$route.path.match(new RegExp(route)))
    ) {
      return 'active-indicator v-list__tile--active';
    }
  }

  toggleAdmin(event) {
    (this.$refs.menuAdmin as Menu).toggle(event);
  }
}
</script>

<style lang="scss">
.admin-button {
  height: 2.643rem !important;
  width: 4.214rem !important;
}

.faq-button {
  height: 2.643rem !important;
  width: 4rem !important;
}

.button-radius-right {
  border-top-right-radius: 0.786rem !important;
  border-bottom-right-radius: 0.786rem !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.button-radius-left {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: 0.786rem !important;
  border-bottom-left-radius: 0.786rem !important;
}
</style>