import {useStore} from 'vuex';
import {ActivityFieldsState} from '@/store/models/activityFieldsState';
import {ACTIVITY_FIELDS_MODULE} from '@/store/modules/activityFields.module';
import {ref, computed} from 'vue';
import {ActivityField} from '@/models/search';

export default function useActivityFields() {
    const store = useStore();
    const allActivityFields = ref<ActivityField[]>((<ActivityFieldsState>store.state.activityFields).fields);
    const basicSearchableActivityFields = ref<ActivityField[]>(allActivityFields.value.filter(x => x.accessLevel == 'Basic' && x.isSearchable));
    const fieldsAddedToStore = ref(false);
    const getData = async () => {
        // eslint-disable-next-line no-async-promise-executor
        new Promise(async resolve => {
            if (!store.state.activityFields.fields.length) {
                await store.dispatch(`activityFields/${ACTIVITY_FIELDS_MODULE.GET_FIELDS}`, null, { root: true });
                fieldsAddedToStore.value = true;
                allActivityFields.value = (<ActivityFieldsState>store.state.activityFields).fields;
                basicSearchableActivityFields.value = allActivityFields.value.filter(x => x.accessLevel == 'Basic' && x.isSearchable);
            }
            
            resolve(true);
        });
    };
    getData();
    return { allActivityFields, basicSearchableActivityFields, fieldsAddedToStore };
}
