<template>
  <div class="flex col-12 justify-content-between">
    <h2>
      {{ titleTrim() }}
    </h2>
    <div class="pt-2">
      <cp-export-chart-options :chart="chartExport" @chart-updated="chartUpdated" />
    </div>
  </div>
  <div ref="chart" cp-color-picker class="flex align-items-center pt-3 doc-percent" style="min-height: 300px">
    <ProgressSpinner />
  </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import ContentOverviewDashboardService, {
  ContentDataRequest,
} from '@/services/contentOverviewDashboardService';
import {
  setDefaults,
  getPieChartConfig,
  PieChartOptions,
  getChartColor, getPieChartNoDataOptions,
} from '@/utils/highchartUtils';
import ExportChartOptionsComponent from '../common/ExportChartOptions.vue';
import { orderBy, sum, isEqual } from 'lodash';
import { Filter, DateRangeFilter, TermsFilter, FieldCode } from '@/models/filters';
import { filterValueExists, findFilter } from '@/utils/filters';
import dayjs from 'dayjs';
import { Vue, Options } from 'vue-class-component';
import setHighChartPlotSize, { renderPieChart } from '@/plugins/highchart-extend';
import * as Highcharts from 'highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { Chart } from 'highcharts';
//import {setNoDataImage} from "@/plugins/highchart-extend";
NoDataToDisplay(Highcharts);

@Options({
  name: 'cp-doc-count-percent-chart',
  components: {
    'cp-export-chart-options': ExportChartOptionsComponent
  },
})
export default class DocCountPercentChart extends Vue {
  @Prop({ default: () => [] }) filters: Filter[];
  @Prop({ default: 'Untitled' }) label: string;
  @Prop({ required: true }) segment: 'ContentType' | 'BuyersJourneyStage';
  declare $refs: { chart: HTMLDivElement };
  chartExport: any | null = null;
  data1: Record<string, number> = {};
  loading = true;
  series: any;
  showTitle: string;

  created() {
    this.showTitle = this.label;
  }
  @Watch('filters', { immediate: true })
  async filtersChanged(newVal: Filter[], oldVal: Filter[]) {
    if (
      !newVal?.length ||
      isEqual(oldVal, newVal) ||
      !filterValueExists(newVal, 'Clients')
    ) {
      return;
    }

    const dateVal = findFilter<DateRangeFilter>(newVal, 'DateRange')?.value;

    const req: ContentDataRequest = {
      companyIds: findFilter<TermsFilter>(newVal, 'Clients')?.value.map((x) => x.id),
      from: dateVal && dayjs(dateVal.from).format('YYYY-MM-DD'),
      to: dateVal && dayjs(dateVal.to).format('YYYY-MM-DD'),
    };
    this.loading = true;
    this.data1 = await ContentOverviewDashboardService.getDocCountBySegment(
      req,
      <FieldCode>this.segment
    );

    if (this.segment == 'BuyersJourneyStage' && Object.values(this.data1).reduce((a, b) => a + b) == 0) {
      this.data1 = {};
    }

    this.loading = false;
    this.renderChart();
  }

  // NOTE: For charts whose data comes from ElasticSearch, this is handled on the server-side
  // However, since this data comes from CMS, we chose to implement the code below rather than modifying CMS
  private chartData() {
    const total = sum(Object.values(this.data1));
    let data = Object.keys(this.data1).map((key) => ({
      name: key,
      value: this.data1[key],
    }));
    // This will push 'other' to the bottom of the array
    data = orderBy(data, (x) => (x.name.toLowerCase() === 'other' ? 0 : x.value), 'desc');
    const topData = data.splice(0, 8);
    const otherCount = sum(data.map((x) => x.value));
    const allData = [...topData];
    if (otherCount > 0) {
      allData.push({ name: 'Other', value: otherCount });
    }

    allData.forEach((x) => {
      const percent = (x.value / total) * 100;
      x.value = Number.parseFloat(percent.toPrecision(2));
    });

    return allData.map((x, index) => ({
      name: x.name,
      y: x.value,
      color: getChartColor(<FieldCode>this.segment, index, x.name)
    }));
  }

  private renderChart() {
    setDefaults();
    setHighChartPlotSize();
    this.series = this._getSeriesData();
    const options: PieChartOptions = <PieChartOptions>{ field: <FieldCode>this.segment, exportTitle: this.label, series: this.series };
    this.chartExport = (Highcharts as any).chart(this.$refs.chart, this.chartData().length > 0 ? 
      getPieChartConfig(options) : getPieChartNoDataOptions(options),
      (chartInstance) => { // on complete
        if (this.chartData().length < 1) {
          renderPieChart(chartInstance, getPieChartNoDataOptions(options).lang.noDataImage);
        }
      }
    );
  }


  private _getSeriesData() {
    return [
      {
        type: 'pie',
        name: '% of documents',
        data: this.chartData(),
        innerSize: '50%',
      },
    ];
  }

  setLegend() {
    return this.segment == 'BuyersJourneyStage'
      ? {
        y: -95,
      }
      : {
        y: -40,
      };
  }

  chartUpdated(value: Chart) {
    this.chartExport = value;
    this.showTitle = (this.chartExport as any).options.exporting.chartOptions.title.text;
    this.$forceUpdate();
  }

  titleTrim() {
    return this.showTitle.length > 35
      ? this.showTitle.substring(0, 35) + '...  '
      : this.showTitle;
  }
}
</script>
<style scoped lang="scss">
.chart-label {
  text-align: left;
  font: normal normal bold 24px/30px Overpass;
  letter-spacing: 0px;
  color: #1b3557;
  opacity: 1;
}
</style>
