import { Lookup } from '../lookup';
import { ActivityType } from '@/models/activity-review/activityType';

export enum ActivitySource {
  NL = 'Newsletter',
  Web = 'Web'
}

export const ACTIVITY_SOURCE_LOOKUPS: Array<Lookup<string>> = [
  { id: ActivitySource.NL, name: ActivitySource.NL },
  { id: ActivitySource.Web, name: ActivitySource.Web }
];

export const ACTIVITY_TYPE_LOOKUPS: Array<Lookup<ActivityType>> = [
  { id: 'Engagement', name: 'Engagement' },
  { id: 'CustomerRequest', name: 'Customer Request' }
];

export const ACTIVITY_TYPE_IMAGE_PATH_LOOKUPS: Array<Lookup<ActivityType>> = [
  { id: 'CustomerRequest', name: 'https://leadtrail.blob.core.windows.net/images/customer-request.png' }
];

export const INDEX_LOOKUPS: Array<Lookup<string>> = [
  { id: 'cr-engagement-read', name: 'Engagements' },
  { id: 'cr-customerrequest-read', name: 'Customer Requests' },
  { id: 'cr-webinar-read', name: 'Webinars' },
  { id: 'cr-event-read', name: 'Events' },
];

export const INDEX_MAP: Record<string, string> = {
  'cr-engagement-read': 'engagement',
  'cr-customerrequest-read': 'customerrequest',
  'cr-webinar-read': 'webinar',
  'cr-event-read': 'event'
};
