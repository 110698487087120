import { CompanyClaim } from '@/models/security';

import CmsCompanyService from '@/services/cmsCompanyService';
import { Ref, ref } from 'vue';

export default function useCompanySecurity(satisfiesClaim: boolean, userCompanies: CompanyClaim[], selections: Ref<CompanyClaim[]>) {
  const data = ref<CompanyClaim[]>([]);
  const loading = ref(false);
  const error = ref<any>(null);

  const getData = async (q: string) => {
    loading.value = true;
    data.value = [];
    error.value = null;
    if (!q?.length) {
      loading.value = false;
      return;
    }

    try{
    const options = satisfiesClaim
      ? (await CmsCompanyService.find(q)) || []
      : userCompanies.filter((x) => x.name.indexOf(q) < 0);

    // console.debug(`CompanySearch.getData()`, options);

    const selectedCompanies = selections?.value?.map((c: CompanyClaim) => c.name) ?? [];
     data.value = options.filter((e1) => !selectedCompanies.includes(e1.name));
    } catch(err){
      error.value = err;
    }

    loading.value = false;
  };

  const updateData = async (value: CompanyClaim) => {
    loading.value = true;
    // data.value = [];
    error.value = null;

    try{
      const termValue: Array<CompanyClaim> = [
        ...selections.value,
        value
      ];

    selections.value = termValue;
    } catch(err){
      error.value = err;
    }

    loading.value = false;
  };

  const removeData = async (value: CompanyClaim) => {
    loading.value = true;
    error.value = null;

    try{
      const findIndex = selections.value.findIndex((a) => a.id === value.id);
      selections.value.splice(findIndex, 1);
    } catch(err){
      error.value = err;
    }

    loading.value = false;
  };

  return { data, loading, error, getCompanyData: getData, updateCompanyData: updateData, removeCompanyData: removeData};
}