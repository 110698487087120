import { GenericActionItem } from '@/models/common/actionItem';
import { InsightType } from './insightType';

export type InsightTypeActionItem = GenericActionItem<InsightType>

export const DEFAULT_INSIGHT_TYPE_ACTION: InsightTypeActionItem = {
  type: InsightType.ReaderOrg,
  text: 'Reader Organization',
  description: 'A late stage engagement from a target reader at a target company',
  icon: 'business',
  active: true
};

export const INSIGHT_TYPE_ACTIONS: InsightTypeActionItem[] = [
  {
    type: InsightType.Reader,
    text: 'Reader',
    description: 'A target reader at a target company',
    icon: 'face',
    active: true
  },
  {
    type: InsightType.ReaderOrg,
    text: 'Reader Organization',
    description: 'A target company',
    icon: 'business'
  }
];
