
export enum WorkflowStepEnum {
  Begin,
  DefineFilters,
  BehaviorChange,
  SetAlert,
  Frequency,
  Activate
}

export type WorkflowStep = 'Begin' | 'DefineFilters' | 'BehaviorChange' | 'SetAlert' | 'Frequency' | 'Activate'
