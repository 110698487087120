import { FriendlyFilterRow } from './filterFactory';
import { Lookup } from '@/models/lookup';
import { Filter } from './filter';
import { FieldCode } from './fieldCode';
import { startCase } from 'lodash';

export class MediaFilter implements Filter {
  field: FieldCode;
  value: Array<Lookup<any>>;
  readonly $type = 'MediaFilter';

  constructor(opts: Partial<MediaFilter> = {}) {
    Object.assign(this, opts);
    this.value = this.value || [];
  }

  isEmpty() {
    return !this.value?.length;
  }

  getFriendlyText(labelOverride: string): FriendlyFilterRow {
    const label = `${labelOverride || startCase(this.field)}`;
    const isNotArray = this.value.length === 1;
    const operation = isNotArray ? `is` : `is on of`;
    const value = this.value.length === 1
      ? `${this.value[0].id}`
      : `(${this.value.map(x => x.name).join(',')})`;

    return <FriendlyFilterRow>{ filterName: label, operation, value };
  }

  clearValue() {
    this.value = [];
  }
}
