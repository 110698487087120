<template>
  <Panel class="w-full h-full">
    <template #header>
      <h4 class="my-2 p-panel-title">
        Activity Report Settings
      </h4>
    </template>
    <div class="grid mt-2 p-3">
      <div class="col-12 lg:col-6">
        <span class="p-float-label p-fluid">
          <Dropdown id="method" v-model="data.reportType" :options="reportTypes"
            placeholder="Select a Report Type"
            :class="{ 'p-invalid': v$.reportType.$invalid && submitted }"
            @update:model-value="reportTypeChanged" />
          <label for="method">Report Type</label>
        </span>
        <small
          v-if="(v$.reportType.required.$invalid && submitted) || v$.reportType.$pending.$response"
          class="p-error">
          {{ v$.reportType.required.$message.replace('Value', 'Report Type') }}</small>
      </div>
      <div class="col-12 lg:col-6 flex justify-content-between mt-2">
        <div v-for="(sendDay, index) in sendDayData" :key="index"
          class="flex p-0 text-sm align-items-center field-checkbox">
          <Checkbox :id="'day_of_week_' + index" v-model="sendDay.selected" :binary="true"
            :class="{ 'p-invalid': v$.sendDay.$invalid && submitted }" />
          <label :for="'day_of_week_' + index" class="ml-2" :class="{ 'p-error': v$.sendDay.$invalid && submitted }">
            {{ sendDay.name }}</label>
        </div>
      </div>

      <div v-if="(v$.sendDay.minValue.$invalid && submitted) || v$.sendDay.$pending.$response"
        class="col-12 mt-n4 mb-n3">
        <small v-if="(v$.sendDay.minValue.$invalid && submitted) || v$.sendDay.$pending.$response"
          class="p-error">Please
          schedule at least one day</small>
      </div>

      <div class="col-12 lg:col-4 flex align-items-center pb-0 mt-2">
        <span>Disable Schedule</span>
        <InputSwitch v-model="data.disabled" v-tooltip="'When this is enabled, this contact will not be receiving any engagements / customer requests'
        " class="ml-3" />
      </div>
      <div class="col-12 lg:col-4 flex align-items-center pb-0 mt-2">
        <span>Include Quality Column</span>
        <InputSwitch v-model="data.includeActivityQualityColumn" v-tooltip="`When this is enabled, this user's report will include quality column`
        " class="ml-3" />
      </div>
      <div v-if="isConsolidated" class="col-12 lg:col-4 flex align-items-center mt-1 pb-0">
        <span>Aggregate Subsequent Engagements</span>
        <InputSwitch v-model="data.aggregateSubsequent" class="ml-3" />
      </div>
    </div>
  </Panel>
</template>
<script lang="ts">
import {
  ActivityReportConfig,
  ActivityReportType,
  ACTIVITY_REPORT_TYPE_LOOKUPS,
  SendDayData,
  SEND_DAYS_LIST,
} from '@/models/activity-dissemination';
import Tooltip from 'primevue/tooltip';
import { computed, PropType, ref, toRef } from 'vue';

export default {
  name: 'CpActivityReportConfigSettings',
  directives: { tooltip: Tooltip },
  props: {
    modelValue: { type: Object as PropType<ActivityReportConfig>, required: true },
    sendDayData: { type: Array as PropType<SendDayData[]>, default: () => SEND_DAYS_LIST },
    submitted: Boolean,
    v: { type: Object, required: true }
  },
  emits: ['is-individual'],
  setup(props, { emit }) {
    const reportTypes = ref(ACTIVITY_REPORT_TYPE_LOOKUPS);
    const data = toRef(props, 'modelValue');

    const reportTypeChanged = (reportType: ActivityReportType) => {
      emit('is-individual', isIndividual.value);
    };

    const isWebhook = computed(() => data.value?.reportTypeEnum === ActivityReportType.Webhook);
    const isConsolidated = computed(() => data.value?.reportTypeEnum === ActivityReportType.Consolidated);
    const isIndividual = computed(() => data.value?.reportTypeEnum === ActivityReportType.Individual);

    return { reportTypes, data, isWebhook, isConsolidated, reportTypeChanged, v$: props.v };
  }
};
</script>
<style lang="scss">
.day-checkbox.v-input--checkbox {
  padding-left: 10px;
  margin-top: 0;
}

.day-checkbox.v-input--checkbox:first {
  padding-left: 0;
}
</style>
