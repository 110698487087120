<template>
  <form @submit.prevent="reset(!v$.$invalid)">
    <background-logo />
    <div class="flex flex-column align-items-center justify-content-center h-screen">
      <Card v-show="!isSuccess" class="col-12 p-login-card">
        <template #title>
          <div class="flex flex-row justify-content-center mt-8 p-login-card-title">
            <h1>Complete Registration</h1>
          </div>
        </template>
        <template #content>
          <Message v-if="message.length > 0" severity="info" :closable="false">
            {{ message }}
          </Message>
          <div class="flex flex-column">
            <div class="flex flex-row align-items-center justify-content-center">
              <div class="flex px-8">
                <p v-show="!info.hasPassword" class="text-left">
                  For security purposes, you must complete account
                  registration
                  before you can access Templeton. Please set your password in order to continue.
                </p>
                <p v-show="info.hasPassword" class="text-left">
                  Registration is already completed.
                </p>
              </div>
            </div>
          </div>
          <div class="flex px-8 mt-7">
            <span class="p-float-label p-fluid w-full">
              <Password v-model="v$.password.original.$model" toggle-mask
                :class="{ 'p-invalid': v$.password.original.$invalid && submitted }">
                <template #footer>
                  <Divider />
                  <p class="mt-2">Suggestions</p>
                  <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                    <li>At least one lowercase</li>
                    <li>At least one uppercase</li>
                    <li>At least one numeric</li>
                    <li>Minimum 8 characters</li>
                  </ul>
                </template>
              </Password>
              <label for="email">Password</label>
            </span>
          </div>
          <div class="flex px-8 mt-6">
            <span class="p-float-label p-fluid w-full">
              <Password v-model="v$.password.confirm.$model" toggle-mask
                :class="{ 'p-invalid': v$.password.confirm.$invalid && submitted }" />
              <label>Confirm Password</label>
              <small v-if="v$.password.confirm.$error" class="p-invalid">Password not matching</small>
            </span>
          </div>
          <div class="flex align-items-center justify-content-center py-8">
            <Button type="submit" label="Submit" class="p-button-raised p-button-rounded text-white px-5 py-2">
              <ProgressSpinner v-if="loading" style="width: 20px; height: 20px" stroke-width="8" />
            </Button>
          </div>
        </template>
      </Card>
      <Card v-show="isSuccess" class="col-12 p-login-card">
        <template #content>
          <div class="flex flex-row align-items-center justify-content-center mt-8 cursor-pointer">
            <img alt="No data" :src="require('@/assets/icons/success_icon.svg')">
          </div>
          <div class="flex align-items-center justify-content-center mt-2">
            <h1>Success!</h1>
          </div>
          <div class="flex align-items-center justify-content-center mt-2 mb-6">
            <p>Registration is complete.</p>
          </div>
          <div class="flex align-items-center justify-content-center mt-6 mx-6 px-6 text-center">
            <p>
              You will be redirected to log in to Templeton momentarily.
              Please log in to view your engagement activities.
            </p>
          </div>
          <div class="flex align-items-center justify-content-center mt-4 text-center">
            <router-link to="/login" class="p-button p-component p-button-link pl-0">
              Click here if this page does not redirect.
            </router-link>
          </div>
        </template>
      </Card>
      <mail-to />
    </div>
  </form>
</template>


<script lang="ts">
import { defineComponent } from 'vue';
import axios from 'axios';
import { ApiResult } from '@/models/apiResult';
import { AUTH_ACTIONS, AUTH_MUTATIONS } from '@/store/modules/auth.module';

import { required, sameAs } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

import BackgroundLogo from '@/components/login/backgroundLogo.vue';
import MailTo from '@/components/login/mailTo.vue';

export default defineComponent({
  components: { MailTo, BackgroundLogo },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      token: this.$route.params['token'].toString(),
      redirectUrl: this.$route.query['redirect']?.toString(),
      info: {} as any,
      message: '',
      loading: false,
      password: { original: '', confirm: '' },
      submitted: false,
      isSuccess: false,
    };
  },
  validations() {
    return {
      password: {
        original: { required },
        confirm: { required, sameAsPassword: sameAs(this.password.original) },
      },
    };
  },
  async mounted() {
    const apiResponse = (
      await axios.get<ApiResult<any>>(`/api/verify-registration-token/${this.token}`)
    ).data;

    if (!apiResponse.success) {
      this.$store.commit(`auth/${AUTH_MUTATIONS.CLEAR_STATE}`, apiResponse.message);
      this.$router.push('/login');
      return;
    }
    //check if user already has setup password and redirect url
    this.info = apiResponse.data;
    if (this.info.hasPassword && this.redirectUrl)
      this.$router.push(this.redirectUrl);
    else if (this.info.hasPassword)
      this.$router.push('/login');
  },
  methods: {
    async reset(isFormValid: boolean) {
      this.submitted = true;

      if (!isFormValid) {
        return;
      }

      this.loading = true;

      const payload = { token: this.token, password: this.password.original, isRegistration: true };

      try {
        const result = await axios.post('/api/set-password/from-token', payload);

        this.$store.commit(`auth/${AUTH_MUTATIONS.INIT_FROM_TOKEN}`, result.data.token);
        this.$store.dispatch(`auth/${AUTH_ACTIONS.MONITOR_TOKEN}`);

        this.$toast.add({
          severity: 'success',
          summary: '',
          detail: `Password set successfully.`,
          life: 3000,
          closable: false,
        });

        this.isSuccess = true;
        //delay redirect
        setTimeout(() => {
          //to handle redirect from server, we check for stored path
          if (this.redirectUrl) {
            this.$router.push(this.redirectUrl);
          } else
            this.$router.push('/');
        }, 6000);

      } catch (error: any) {
        this.$store.commit(`auth/${AUTH_MUTATIONS.CLEAR_STATE}`, error.message);
        this.$router.push('/login');
        // NOTE: Error thrown to be detected by Vue.config.errorHandler
        this.$toast.add({
          severity: 'error',
          summary: '',
          detail: error.message,
          life: 3000,
          closable: false,
        });
        throw error;
      }
    },
  },
});
</script>

<style lang="scss" scoped>

</style>
