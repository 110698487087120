import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-09e86f90"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-content-between" }
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "pt-2" }
const _hoisted_4 = {
  ref: "chart",
  class: "flex justify-items-center align-items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cp_export_chart_options = _resolveComponent("cp-export-chart-options")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _directive_cp_color_picker = _resolveDirective("cp-color-picker")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", {
        id: _ctx.seriesField + '-content'
      }, _toDisplayString(_ctx.showTitle), 9, _hoisted_2),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_cp_export_chart_options, {
          chart: _ctx.chartExport,
          onChartUpdated: _ctx.chartUpdated
        }, null, 8, ["chart", "onChartUpdated"])
      ])
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
      _createVNode(_component_ProgressSpinner)
    ])), [
      [_directive_cp_color_picker]
    ])
  ], 64))
}