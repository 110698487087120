<template>
  <div class="flex p-fluid">
    <span class="p-float-label" :style="defaultLabel.length < 21 ? 'min-width: 150px' : 'min-width: 230px'">
      <Dropdown v-model="local.op" :options="operationsOpts" option-label="name" option-value="id"
        class="p-border-round-left" @change="v => updateField('op', v)" />
      <label for="options">{{ defaultLabel }}</label>
    </span>
    <div class="w-full">
      <InputNumber v-if="displayAsCurrency" v-model="local.value" class="w-full p-border-round-right inputnumber-standard-text" 
        mode="currency" currency="USD" @input="updateField('value', $event)" />
      <InputNumber v-if="!displayAsCurrency" v-model="local.value" class="w-full p-border-round-right inputnumber-standard-text" 
        @input="updateField('value', $event)" />  
    </div>    
  </div>
</template>

<script lang="ts">
import {PropType, ref} from 'vue';
import { NUMERIC_OPERATIONS, NumericDisplayType, NumericFilter, Operation, operationLabel, toLabel} from '@/models/filters';
import { Lookup } from '@/models/lookup';

export default {
  name: 'CpNumericSearchField',
  props: {
    modelValue: {type: Object as PropType<NumericFilter>, default: new NumericFilter()},
    label: {type: String, default: ''}
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const operationsOpts = NUMERIC_OPERATIONS.map(x=><Lookup<Operation>>{id: x, name: operationLabel[x]});
    const defaultLabel = props.label || toLabel(props.modelValue.field);
    const local = ref<NumericFilter>(props.modelValue);
    const displayAsCurrency = props.modelValue.displayType === NumericDisplayType.Currency;

    const updateField = (name: keyof NumericFilter, e: any) => {
      const newVal = new NumericFilter({...local.value, [name]: e.value});
      emit('update:modelValue', newVal);
    };

    return {operationsOpts, local, defaultLabel, updateField, displayAsCurrency};
  }
};
</script>
