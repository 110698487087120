import { Claim } from '../security';
import { NAV_ITEMS_ALERT } from './alertsMenuItems';
import { NavItem } from './navItem';

const ACCESS_MARKETING_PERFORMANCE = new Claim('accessMarketingPerformance');

export const NAV_ITEMS: NavItem[] = [{
    key: 'analytics',
    name: 'Analytics',
    icon: 'stacked_bar_chart',
    items: [
        {
            link: '/',
            name: 'Performance Overview',
            icon: '<i class="material-icons">trending_up</i>'
        },
        {
            link: '/analytics/content-overview-dashboard',
            name: 'Content Overview',
            icon: '<i class="material-icons">description</i>'
        },
        {
            link: '/analytics/search',
            name: 'Engagements Search',
            icon: '<i class="material-icons">manage_search</i>'
        },
        {
            link: '/analytics/most-active-companies-dashboard',
            name: 'Most Active Companies',
            icon: '<i class="material-icons">business</i>'
        },
        {
            link: '/analytics/most-active-readers-dashboard',
            name: 'Most Active Readers',
            icon: '<i class="material-icons-outlined">person</i>',
        },
        ...(process.env.VUE_APP_ENABLE_DISSEM_DASH === 'true' ? [{
            name: 'Activity Reports',
            link: '/dissemination-contact/dashboard',
            icon: '<i class="material-icons">list_alt</i>'
        }] : [])
    ]
},
{
    key: 'performance',
    name: 'Marketing Performance',
    icon: 'campaign',
    claim: ACCESS_MARKETING_PERFORMANCE,
    items: [
        {
            link: '/performance/content-insights',
            name: 'Content Insights',
            icon: '<i class="ci ci-audience-content"></i>'
        },
        {
            link: '/performance/net-new-readers-orgs',
            name: 'Net New Readers & Orgs',
            icon: '<i class="ci ci-net-new"></i>'
        },
        {
            link: '/performance/pipeline-impact',
            name: 'Pipeline Impact',
            icon: '<i class="ci ci-pipeline"></i>'
        },
        {
            link: '/performance/tactic-activity/custom-newsletters',
            name: 'Tactic Activity',
            icon: '<i class="ci ci-tactic"></i>'
        },
        // {
        //     key: 'tactic',
        //     name: 'Tactic Activity',
        //     icon: '<i class="ci ci-tactic"></i>',
        //     items: [
        //         {
        //             link: '/performance/tactic-activity/webinar-events',
        //             name: 'Webinars',
        //             icon: '<i class="ci ci-webinars"></i>'
        //         },
        //         {
        //             link: '/performance/tactic-activity/custom-newsletters',
        //             name: 'Custom Newsletters',
        //             icon: '<i class="ci ci-newsletters"></i>'
        //         },
        //     ]
        // },
        {
            link: '/performance/comparative-content-tactics',
            name: 'Comparative Content Tactics',
            icon: '<i class="ci ci-comparative-content"></i>'
        }

    ]
},
{
    key: 'notifications',
    name: 'Alerts',
    icon: 'notifications',
    items: NAV_ITEMS_ALERT
}
];
