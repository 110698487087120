import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Message = _resolveComponent("Message")!

  return (_ctx.statusCode)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Message, {
          closable: false,
          severity: "info"
        }, {
          default: _withCtx(() => [
            (_ctx.statusCode === '404')
              ? (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString(_ctx.pageNotFound), 1))
              : (_ctx.statusCode === '403')
                ? (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString(_ctx.restrictedAccess), 1))
                : (_openBlock(), _createElementBlock("h2", _hoisted_4, _toDisplayString(_ctx.otherError), 1)),
            _createTextVNode(" " + _toDisplayString(_ctx.message), 1)
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}