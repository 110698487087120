import { WorkflowType } from './workflowtype';

export type NotificationType = 'SpecificActivityNotification' | 'SeriesOfActivitiesNotification' | 'ChangeInActivitiesNotification';

export function typeToName(type: NotificationType) {
  switch (type) {
    case 'ChangeInActivitiesNotification': return 'Changes In Activities';
    case 'SeriesOfActivitiesNotification': return 'Multiple Activities';
    case 'SpecificActivityNotification': return 'Individual Activity';
  }
}

export type NotificationTypeDiscriminatorName = 'ChangeInActivitiesNotification' | 'SeriesOfActivitiesNotification' | 'SpecificActivityNotification';

export function GetWorkflowType(isCompleted: boolean): WorkflowType {
  return isCompleted ? WorkflowType.Edit : WorkflowType.Create;
}

export function GetTypeIcon(type: NotificationType): string {
  switch (type) {
    case 'ChangeInActivitiesNotification': return NOTIFICATION_TYPE_ICON_MANIFEST.ChangeInActivitiesNotification;
    case 'SeriesOfActivitiesNotification': return NOTIFICATION_TYPE_ICON_MANIFEST.SeriesOfActivitiesNotification;
    case 'SpecificActivityNotification': return NOTIFICATION_TYPE_ICON_MANIFEST.SpecificActivityNotification;
    default: return '';
  }
}

export const NOTIFICATION_TYPE_ICON_MANIFEST: Record<NotificationTypeDiscriminatorName, string> = {
  ChangeInActivitiesNotification: 'mdi-chart-gantt',
  SeriesOfActivitiesNotification: 'mdi-chart-bar',
  SpecificActivityNotification: 'mdi-chart-scatter-plot'
};
