import {
    FieldCode,
    Filter,
    FilterType
} from '@/models/filters';
import {
    GENERAL_FIELD_METADATA,
    CONTEXTUAL_FIELD_METADATA,
    DEMOGRAPHIC_FIELD_METADATA,
    FIRMOGRAPHIC_FIELD_METADATA,
    ActivityFieldCategory,
    SearchFieldMetaData, searchFieldModelValue,
    SearchFieldRow,
    FIRMOGRAPHIC_LIFE_SCIENCES_FIELD_METADATA,
    FIRMOGRAPHIC_MED_DEVICES_FIELD_METADATA
} from '.';
import DateRangeSearchField from '@/components/searchFields/dateRangeSearchField.vue';
import TextSearchField from '@/components/searchFields/textSearchField.vue';
import TermsSearchField from '@/components/searchFields/termsSearchField.vue';
import BoolSearchField from '@/components/searchFields/boolSearchField.vue';
import NumericSearchField from '@/components/searchFields/numericSearchField.vue';
import ExistsSearchField from '@/components/searchFields/existsSearchField.vue';
import RelativeDateRangeSearchField from '@/components/searchFields/relativeDateRangeSearchField.vue';

import { clone, cloneDeep } from 'lodash';
import Vue from 'vue';
export function getSearchFieldMetadata(): SearchFieldRow[] {
    return [
        ...toSearchFieldRows(GENERAL_FIELD_METADATA, 'General'),
        ...toSearchFieldRows(CONTEXTUAL_FIELD_METADATA, 'Contextual'),
        ...toSearchFieldRows(DEMOGRAPHIC_FIELD_METADATA, 'Demographics'),
        ...toSearchFieldRows(FIRMOGRAPHIC_FIELD_METADATA, 'FirmographicGeneral'),
        ...toSearchFieldRows(FIRMOGRAPHIC_LIFE_SCIENCES_FIELD_METADATA, 'FirmographicLifeSciences'),
        ...toSearchFieldRows(FIRMOGRAPHIC_MED_DEVICES_FIELD_METADATA, 'FirmographicMedicalDevices'),
    ];
}

export function initSearchFilter(filterNames: FieldCode[], data: Filter[] = []): SearchFieldRow[] {
    return getSearchFieldMetadata()
        .filter(x => x.items.some(m => filterNames.includes(searchFieldModelValue(m, 'field'))))
        .map(row => {
            row.items
                .filter(m => filterNames.includes(searchFieldModelValue(m, 'field')))
                .forEach(m => {
                    let dbModels: Filter[] | any[] = data.filter(x => x.field === searchFieldModelValue(m, 'field'));
                    dbModels = dbModels.length ? dbModels : [{}];
                    dbModels.forEach((x, i) => {
                        if (m.props?.isCompound) {
                            const newModel = clone(m.model[0]);
                            m.model[i] = Object.assign(newModel, x);
                        } else {
                            m.model = Object.assign(m.model, x);
                        }
                    });
                });
            return row;
        });
}

export function toSearchFieldRows(data: Array<SearchFieldMetaData | SearchFieldMetaData[]>, category: ActivityFieldCategory) {
    const rows = data.map(f => {
        const items = (Array.isArray(f) ? f : [f]).map(i => {
            i.type = i.type || getFilterComponent(searchFieldModelValue(i, '$type'));
            return i;
        });
        // console.debug(`searchFieldManifest.toSearchFieldRows() - { items, category }`, items, category);
        return { items, category };
    });
    return cloneDeep(rows);
}

function getFilterComponent(ft: FilterType): Vue.Component | undefined {
    switch (ft) {
        case 'TermsFilter':
        case 'MediaFilter':
            return TermsSearchField;
        case 'TextFilter':
            return TextSearchField;
        case 'BoolFilter':
            return BoolSearchField;
        case 'DateRangeFilter':
            return DateRangeSearchField;
        case 'NumericFilter':
            return NumericSearchField;
        case 'ExistsFilter':
            return ExistsSearchField;
        case 'RelativeDateRangeFilter':
            return RelativeDateRangeSearchField;
        default:
            return undefined;
    }
}
