<template>
  <div class="grid">
    <Message :closable="false" v-if="currentUser.email != authenticatedUser.email" style="background-color: #E5F9FA6B;">
      NOTE: You are currently in <b>{{ usersName }}</b> Account. Be mindful when making any changes, especially when
      deleting filters, to avoid unintended impacts.
    </Message>
    <div class="col-12 flex flex-row-reverse align-items-center">
      <span class="p-secondary material-icons text-xs pl-1">
        <Button label="New User" class="p-button-success p-button-raised" @click="goToUserFilters('')">
          New Search
        </Button>
      </span>
    </div>
  </div>
  <div id="saved-queries-table" class="col-14 pt-4">
    <DataTable :value="pagedResults.items" data-key="id" :paginator="true" :rows="searchCriteria.pageSize"
      :rows-per-page-options="pageSizeOptions" :lazy="true" :total-records="pagedResults.total"
      paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
      current-page-report-template="Showing {first} to {last} of {totalRecords}" paginator-position="both"
      @page="onPage($event)" @sort="onSort($event)">
      <Column field="name" header="Search Name" sortable class="w-6rem">
        <template #body="slotProps">
          <a class="cursor-pointer" :title="slotProps.data.name" @click="goToUserFilters(slotProps.data.id)">
            {{ slotProps.data.name }}
          </a>
        </template>
      </Column>
      <Column field="updatedOn" header="Date Modified" sortable class="w-5rem">
        <template #body="slotProps">
          {{ format_date(slotProps.data.updatedOn) }}
        </template>
      </Column>
      <Column field="updatedBy" header="Modified By" sortable class="w-5rem">
        <template #body="slotProps">
          {{ slotProps.data.updatedBy }}
        </template>
      </Column>
      <Column field="createdOn" header="Date Created" sortable class="w-5rem">
        <template #body="slotProps">
          {{ format_date(slotProps.data.createdOn) }}
        </template>
      </Column>
      <Column field="createdBy" header="Created By" sortable class="w-5rem">
        <template #body="slotProps">
          {{ slotProps.data.createdBy }}
        </template>
      </Column>
      <Column header="Make Default" class="w-5rem">
        <template #body="slotProps">
          <div style="display: flex; justify-content: center;">
            <RadioButton :id="slotProps.data.id" v-model="selectedQuery" :value="slotProps.data.id"
              class="p-button-rounded p-button-text"
              @click="queryPrompt($event, slotProps.data, slotProps.data.isDefault)" />
          </div>
        </template>
      </Column>
      <Column header="Delete" class="w-2rem">
        <template #body="slotProps">
          <Button icon="pi pi-trash" v-if="!slotProps.data.isDefault" class="p-button-rounded p-button-text"
            @click="deletePrompt($event, slotProps.data, slotProps.data.isDefault)" />
        </template>
      </Column>
      <template #empty>
        <div v-if="!loading" class="flex align-items-center justify-content-center py-5">
          No saved search data exists.
        </div>
      </template>
    </DataTable>
  </div>
</template>
<script lang="ts">
import { savedSearchRow } from '@/models/saved-search/savedSearchModel';
import { DEFAULTS, SearchCriteria } from '@/services/searchCriteria';
import Tooltip from 'primevue/tooltip';
import { computed, onMounted, PropType, ref } from 'vue';
import { AppUser } from '@/models/appUser';
import { useConfirm } from 'primevue/useconfirm';
import dayjs from 'dayjs';
import { SortOrder } from '@/models/sortOrder';
import savedSearchesService from '@/services/savedSearchesService';
import { PagedList } from '@/models/pagedList';
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import { onError, onSuccess } from '@/utils/toast/toastUtils';
import useAuthenticatedUser from '@/use/authenticatedUser';
import useCurrentUser from '@/use/currentUser';

export default {
  name: 'CpManageSavedSearches',
  components: {
  },

  directives: {
    tooltip: Tooltip
  },
  props: {
    modelValue: { type: Object as PropType<AppUser>, required: true }
  },

  setup(props) {
    const router = useRouter();
    const toast = useToast();
    const goToUserFilters = (id: string) => {
      if (id == '')
        router.push(`/admin/savedsearch/${props.modelValue.id}`);
      else
        router.push(`/admin/savedsearch/${props.modelValue.id}/${id}`);
    };
    const selectedQuery = ref<string | null>(null);
    const previousSelection = ref<string | null>(null);
    previousSelection.value = selectedQuery.value;
    const confirm = useConfirm();
    const pageSize = ref(1);
    const first = ref(0);
    const editedRow = ref<savedSearchRow | null>(null);
    const pagedResults = ref<PagedList<savedSearchRow>>({ items: [], total: 0, totalPages: 0 });
    const hasDefault = ref<boolean | null>(null);
    const totalRecords = ref<number>(0);
    const { authenticatedUser } = useAuthenticatedUser();
    const { currentUser } = useCurrentUser();
    const usersName = computed(() => `${currentUser.value.firstName ?? ''} ${currentUser.value.lastName ?? ''}`);

    const onPage = (event) => {
      searchCriteria.value.page = event.page + 1;
      searchCriteria.value.pageSize = event.rows;
      search();
    };

    const searchCriteria = ref<SearchCriteria>({ page: 1, pageSize: DEFAULTS.PAGE_SIZE });
    const loading = ref(false);

    const isValid = ref(false);

    const queryPrompt = (event: any, query: savedSearchRow, isDefault: boolean | null) => {
      let confirmMessage: any;

      if (isDefault)
        confirmMessage = 'By deselecting this option, you will remove this search as your default. Your results will revert to the base filters. Are you sure you want to proceed?';
      else
        confirmMessage = 'By selecting this option you are setting the filters defined in this search as your Default Filters.';

      confirm.close();
      confirm.require({
        target: event.currentTarget,
        message: confirmMessage,
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Continue',
        rejectLabel: 'Cancel',
        accept: () => {
          makeDefault(query, isDefault);
          previousSelection.value = selectedQuery.value;
        },
        reject: () => {
          if (hasDefault.value == null)
            selectedQuery.value = null;
          else
            selectedQuery.value = previousSelection.value;
        }
      });
    };

    const deletePrompt = (event: any, query: savedSearchRow, isDefault: boolean | null) => {
      if (isDefault) {
        onError(toast, 'The default saved search cannot be deleted.');
        return;
      }

      let confirmMessage: any;
      if (currentUser.value.email == authenticatedUser.value.email)
        confirmMessage = 'Are you sure you want to permanently delete the saved search?';
      else
        confirmMessage = `Are you sure you want to permanently delete the saved search for ${usersName.value}?`;

      confirm.close();
      confirm.require({
        target: event.currentTarget,
        message: confirmMessage,
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Continue',
        rejectLabel: 'Cancel',
        accept: () => {
          deleteQuery(query);
        },
        reject: () => {
          selectedQuery.value = previousSelection.value;
        }
      });
    };

    const search = async () => {
      loading.value = true;
      try {
        const result = await savedSearchesService.getAllForUser(props.modelValue.id as string, searchCriteria.value);
        pagedResults.value = result;
        totalRecords.value = result.total;

        const defaultQuery = result.items.find(item => item.isDefault);

        if (defaultQuery) {
          hasDefault.value = true;
          selectedQuery.value = defaultQuery.id;
          previousSelection.value = selectedQuery.value;
        } else {
          hasDefault.value = false;
          selectedQuery.value = null;
        }

      } catch (error) {
        handleError(error, 'There was an error fetching saved searches.');
      } finally {
        loading.value = false;
      }
    };

    const onSort = (event) => {
      if (event.sortField) {
        searchCriteria.value.sortBy = event.sortField;
      }
      if (event.sortField) {
        searchCriteria.value.sortDir =
          event.sortOrder == 1 ? SortOrder.Descending : SortOrder.Ascending;
      }
      search();
    };

    const format_date = (value: string) => {
      if (value) {
        return dayjs(String(value)).format('MM/DD/YYYY');
      }
    };

    onMounted(async () => {
      search();
    });

    const makeDefault = async (query: savedSearchRow, isDefault: boolean | null) => {
      loading.value = true;
      try {
        if (isDefault) {
          let isDefaultRemoved = await savedSearchesService.removeDefaultSavedSearch(props.modelValue.id as string, query.id);
          if (isDefaultRemoved) clearDefault();
          onSuccess(toast, 'The default search has been removed.');
        } else {
          setDefault(query);
          await savedSearchesService.setDefaultSavedSearch(props.modelValue.id as string, query.id);
          onSuccess(toast, 'This search has been set as the default search.');
        }
      } catch (error) {
        handleError(error, 'There was an error setting the default search.');
      } finally {
        loading.value = false;
      }
    };

    const clearDefault = () => {
      pagedResults.value.items.forEach(item => item.isDefault = false);
      hasDefault.value = null;
      selectedQuery.value = null;
    };

    const setDefault = (query: savedSearchRow) => {
      clearDefault();
      const targetItem = pagedResults.value.items.find(item => item.id === query.id);
      if (targetItem) targetItem.isDefault = true;
      selectedQuery.value = query.id;
      hasDefault.value = true;
    };

    const deleteQuery = async (query: savedSearchRow) => {
      loading.value = true;
      try {
        await savedSearchesService.deleteSavedSearch(props.modelValue.id as string, query.id);
        onSuccess(toast, 'The search has been deleted.');
        search();
      } catch (error) {
        handleError(error, 'There was an error deleting the search.');
      } finally {
        loading.value = false;
      }
    };

    const handleError = (error: any, message: string) => {
      onError(toast, message);
      console.error(message, error);
    };

    return {
      loading
      , first
      , pageSize
      , onPage
      , format_date
      , onSort
      , queryPrompt
      , deletePrompt
      , deleteQuery
      , makeDefault
      , isValid
      , selectedQuery
      , editedRow
      , pagedResults
      , pageSizeOptions: DEFAULTS.PAGE_SIZE_OPTIONS
      , searchCriteria
      , goToUserFilters
      , totalRecords
      , authenticatedUser
      , currentUser
      , usersName
    };
  }
};
</script>@/services/savedSearchesService@/models/saved-search/savedSearchesModel@/models/saved-search/savedSearchModel