<template>
  <div class="flex p-fluid">
    <div class="col-12 p-0">
      <span class="p-float-label">
        <AutoComplete id="terms-field" v-model="selectedTermValues" :suggestions="filteredOptions" field="name"
          :multiple="true" :class="{ 'p-invalid': isAnalystTagInvalid }" @complete="onSearch($event.query)"
          @item-select="updateField($event.value)" @item-unselect="deleteField($event.value)" />
        <label for="terms-field">{{ label }}</label>
      </span>
      <small v-if="isAnalystTagInvalid || (v$ && v$.$pending.$response)" class="p-error">
        {{ v$.required.$message.replace('Value', 'Analyst Tag') }}</small>
    </div>
  </div>
</template>
<script lang="ts">
import { GuidLookup } from '@/models/lookup';
import { FieldCode, toLabel } from '@/models/filters';
import { computed, PropType, ref, toRef, watch } from 'vue';
import useCmsSecurity from '@/use/termsFieldSecurity';

export default {
  name: 'CpTermsFieldManager',
  props: {
    modelValue: { type: Array as PropType<Array<GuidLookup>>, default: () => [] },
    filterName: { type: String as PropType<FieldCode>, required: true },
    loadOptionsOnce: { type: Boolean, default: () => true },
    submitted: { type: Boolean, default: () => false },
    v: { type: Object }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const label = ref(toLabel(props.filterName));

    const optionsLoaded = ref(false);
    const filteredOptions = ref<GuidLookup[]>([]);

    const selectedTermValues = ref<GuidLookup[]>([...props.modelValue]);
    const v$ = ref(props.v);

    // console.debug(`TermsFieldManager.setup()`, props);

    function notifyParent(e: any) {
      // console.debug(`TermsFieldManager.notifyParent()`, e);
      emit('update:modelValue', e);
    }

    const { data, getData } = useCmsSecurity(toRef(props, 'filterName'), toRef(props, 'loadOptionsOnce'), optionsLoaded, selectedTermValues);
    async function onSearch(q: string) {
      await getData(q);
      filteredOptions.value = [...data.value];
    }

    function updateField() {
      // const { id, name } = value;
      // const termValue: Array<GuidLookup> = [
      //   ...selectedTermValues.value,
      //   { id, name }
      // ];
      // console.debug(`TermsFieldManager.updateField()`, termValue);
      notifyParent(selectedTermValues.value);
    }

    function deleteField(value: GuidLookup) {
      const findIndex = selectedTermValues.value.findIndex((a) => a.id === value.id);
      selectedTermValues.value.splice(findIndex, 1);

      // console.debug(`TermsFieldManager.deleteField()`, selectedTermValues, value);
      notifyParent(selectedTermValues.value);
    }

    watch(props, () => {
      v$.value = props.v;

      console.debug(`TermsFieldManager.watch(props)`, v$);
    });


    const isAnalystTagInvalid = computed(() => {
      const isAnalystTagSelected = props.filterName === 'AnalystTags';
      const isAnalystRequired = (v$.value?.required.$invalid ?? false) && props.submitted;

      return isAnalystTagSelected && isAnalystRequired;
    });

    return {
      label,
      selectedTermValues,
      filteredOptions,
      isAnalystTagInvalid,
      onSearch,
      updateField,
      deleteField,
      v$
    };
  }
};
</script>
