<template>
  <div v-if="fields" class="grid my-2">
    <div class="col-12 lg:col-2 p-secondary-color">
      Internal and <br>Premium Filters
    </div>
    <div class="col-12 lg:col-10 py-0">
      <div class="flex col-12 py-0">
        <Message :closable="false" severity="info">
          Expand the containers to control filter activation below. Please note, Fields
          marked with a * are only available to internal users. All other fields are
          premium fields. This means that an additional charge is required.
        </Message>
      </div>
      <div v-for="(group, index) in items" :key="index" class="flex col-12">
        <Panel :header="group.label" :toggleable="true" :collapsed="true"
          :class="{ collapsed: group.isCollapsed, 'p-invalid': analystTagsInvalid(group) }" class="w-full"
          @toggle="toggleGroup($event, group)">
          <div class="grid mt-2 pb-3 pl-3">
            <div v-for="(field, index1) in group.children" :key="index1"
              class="col-12 md:col-6 lg:col-4 flex mt-2 flex align-items-center">
              <InputSwitch v-model="field.isActive" class="mr-1" :disabled="field.isReadOnly"
                @input="changeState($event, field.key, group)" />
              <span :class="{ 'active-label': field.isActive, 'inactive-label': !field.isActive }">{{
                field.label
              }}</span>
              <span v-if="field.accessLevel === internalAccessLevel">*</span>
            </div>
          </div>
          <div class="col-12">
            <cp-terms-field-manager
              v-if="showAnalystTagOptions && group.children.flatMap((x) => x.key).includes('AnalystTags')"
              v-model="analystTags" filter-name="AnalystTags" :submitted="submitted" :load-options-once="false"
              :v="v$.analystTags" />
          </div>
          <div class="flex flex-row-reverse align-items-center">
            <InputSwitch v-model="group.isAllActive" class="mr-2 ml-2" @input="activateAll($event, group.key)" />
            <span :class="{
              'active-label': group.isAllActive,
              'inactive-label': !group.isAllActive,
            }">Activate All</span>
          </div>
        </Panel>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { FieldCode } from '@/models/filters';
import { TreeNode } from '@/models/search';
import { UserRole } from '@/models/userRole';
import { cloneDeep, isEqual, uniq } from 'lodash';
import { GuidLookup } from '@/models/lookup';

import TermsFieldManager from '@/components/admin/TermsFieldManager.vue';
import { computed, PropType, ref, watch } from 'vue';
import useFilterOptionSecurity from '@/use/filterOptionSecurity';
import { ActivityFieldAccessLevel } from '@/models/common/activityFieldAccessLevel';

export default {
  name: 'CpFilterOptions',
  components: {
    'cp-terms-field-manager': TermsFieldManager,
  },
  props: {
    userRole: { type: String as PropType<UserRole>, required: true },
    modelValue: { type: Array as PropType<FieldCode[]>, default: () => [] },
    restrictedAnalystTagValues: {
      type: Array as PropType<GuidLookup[]>,
      default: () => [],
    },
    submitted: Boolean,
    v: { type: Object, required: true },
  },
  emits: ['update:modelValue', 'update:restrictedAnalystTagValues', 'update:restrictedAnalystTags'],
  setup(props, { emit }) {
    const treeSelections = ref<FieldCode[]>([...props.modelValue]);
    const internalAccessLevel = ref<ActivityFieldAccessLevel>('Internal');
    const analystTags = ref<GuidLookup[]>([...props.restrictedAnalystTagValues]);

    const userRole = ref<UserRole>(props.userRole);
    const v$ = ref(props.v);
    const showAnalystTagOptions = ref(false);

    const {
      items,
      fields,
      getData,
      refreshItems,
      toggleOption,
      toggleOptions,
      toggleGroup,
    } = useFilterOptionSecurity(treeSelections);

    async function changeState(state: boolean, key: FieldCode, group: TreeNode) {
      await toggleOption(state, key, group);
      // console.debug(`FilterOptions.changeState()`, key, group, treeSelections);
      emit('update:modelValue', treeSelections.value);
    }

    async function activateAll(state: boolean, key: string) {
      await toggleOptions(state, key);
      emit('update:modelValue', treeSelections.value);
    }

    async function userRoleChanged(newRole: UserRole, oldRole: UserRole) {
      if (newRole === oldRole) {
        return;
      }
      refreshItems(newRole);
    }

    async function onUserSearchFieldChanged(userFields: FieldCode[]) {
      // debugger;
      if (isEqual(userFields, treeSelections.value)) {
        return;
      }
      // console.debug('FilterOptions.onUserSearchFieldChanged() - selections', userFields, treeSelections);
      const callRefresh = !userFields?.length && treeSelections.value?.length;
      treeSelections.value = cloneDeep(userFields);

      if (!userFields.includes('AnalystTags') && analystTags.value?.length) {
        analystTagsChanged([], analystTags.value); // Clear analyst tags if analyst tag is not active
      }

      if (callRefresh) {
        refreshItems(userRole.value);
      }
    }

    async function analystTagsChanged(tags: GuidLookup[], oldVal: GuidLookup[]) {
      // console.debug(`FilterOptions.analystTagsChanged() - before emit`, tags, oldVal);
      // await dataInitialized;
      if (isEqual(tags, oldVal)) {
        return;
      }

      emit(
        'update:restrictedAnalystTagValues',
        uniq(tags.map((t) => <GuidLookup>{ id: t.id, name: t.name }))
      );
      // console.debug(`FilterOptions.analystTagsChanged() - after emit`, tags, oldVal);
    }

    watch(() => props.userRole, (newRole: UserRole, oldRole: UserRole) => userRoleChanged(newRole, oldRole));
    // watch(modelValue, (userFields: FieldCode[]) => onUserSearchFieldChanged(userFields));
    watch(analystTags, (tags: GuidLookup[], oldVal: GuidLookup[]) => analystTagsChanged(tags, oldVal));

    watch(props, (newProps, oldProps) => {
      onUserSearchFieldChanged(newProps.modelValue);
      analystTagsChanged(newProps.restrictedAnalystTagValues, oldProps.restrictedAnalystTagValues);
      v$.value = props.v;

      // console.debug(`FilterOptions.watch(props)`, userRole, modelValue, v$);
    });

    getData(userRole.value);

    const isAnalystTagActive = computed(() => {
      const isActive = items.value
        ?.flatMap((g) => g.children)
        ?.find((c) => c.key === 'AnalystTags')?.isActive;

      if (!isActive) {
        emit('update:restrictedAnalystTags', []); // Make sure that Defaults respect Client Security
      }

      return isActive;
    });

    const calculateShowAnalystTagOptions = () => isAnalystTagActive.value && props.userRole == 'Client';

    watch([() => props.userRole, isAnalystTagActive], () => {
      showAnalystTagOptions.value = calculateShowAnalystTagOptions();
    });

    function analystTagsInvalid(group: TreeNode) {
      return showAnalystTagOptions.value && props.submitted && group.children.flatMap((x) => x.key).includes('AnalystTags')
        && (v$.value?.$invalid ?? false);
    }

    return {
      items,
      fields,
      internalAccessLevel,
      showAnalystTagOptions,
      analystTags,
      changeState,
      activateAll,
      toggleGroup,
      analystTagsInvalid,
      v$,
    };
  },
};
</script>
<style lang="scss">
.filter-names-tree {
  .v-treeview-node__prepend {
    min-width: 0 !important;
  }
}
</style>
