import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex p-fluid" }
const _hoisted_2 = { for: "options" }
const _hoisted_3 = { class: "w-full" }
const _hoisted_4 = {
  key: 0,
  class: "p-float-label"
}
const _hoisted_5 = {
  key: 1,
  class: "p-float-label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Textarea = _resolveComponent("Textarea")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: "p-float-label",
      style: _normalizeStyle(_ctx.defaultLabel.length < 21 ? 'min-width: 150px' : 'min-width: 230px')
    }, [
      _createVNode(_component_Dropdown, {
        "model-value": _ctx.local.op,
        options: _ctx.textOpts,
        "option-label": "name",
        "option-value": "id",
        class: "p-border-round-left",
        onChange: _cache[0] || (_cache[0] = v => _ctx.updateField('op', v))
      }, null, 8, ["model-value", "options"]),
      _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.defaultLabel), 1)
    ], 4),
    _createElementVNode("div", _hoisted_3, [
      (!_ctx.multiValue)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
            _createVNode(_component_InputText, {
              id: "textfield",
              class: "p-inputtext w-full p-border-round-right",
              "model-value": _ctx.local.value,
              onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateField('value', $event.target)))
            }, null, 8, ["model-value"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.multiValue)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
            _createVNode(_component_Textarea, {
              id: "textarea",
              rows: "1",
              class: "p-inputtext w-full p-border-round-right",
              "model-value": _ctx.local.value,
              onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateField('value', $event.target)))
            }, null, 8, ["model-value"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}