<template>
  <Card :class="count == 0 ? ['p-card no-results-card'] : 'p-count-card flex align-items-center justify-content-center'">
    <template #content>
      <ProgressSpinner v-if="loading" class="flex align-items-center justify-content-center" />
      <cp-animated-integer v-if="count !== 0 && loading == false" :value="count" class="company-count pt-4" />
      <cp-no-data-component v-if="count === 0 && loading == false" :chart-type="'widget'" class="company-count" />
      <div v-if="!loading" :class="count !== 0 ? 'text-center pt-0' : 'sub-no-data'">
        {{ text }}
      </div>
    </template>
  </Card>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import AnimatedIntegerComponent from '../common/animatedInteger.component';
import ContentOverviewDashboardService, {
  ContentDataRequest,
} from '@/services/contentOverviewDashboardService';
import { Filter, TermsFilter, DateRangeFilter } from '@/models/filters';
import { filterValueExists, findFilter } from '@/utils/filters';
import { isEqual } from 'lodash';
import dayjs from 'dayjs';
import { Vue, Options } from 'vue-class-component';
import NoChartDataComponent from '@/components/common/NoChartDataComponent.vue';

@Options({
  components: {
    'cp-animated-integer': AnimatedIntegerComponent,
    'cp-no-data-component': NoChartDataComponent,
  },
})
export default class DocCountCard extends Vue {
  @Prop({ required: true, default: () => [] }) filters: Filter[];
  /* @Prop({ required: false }) iconBackground: string;
  @Prop({ required: false }) icon: string;*/
  @Prop({ required: true }) text: string;
  count: number = 0;

  loading = true;

  @Watch('filters', { immediate: true })
  async filtersChanged(newVal: Filter[], oldVal: Filter[]) {
    if (
      !newVal?.length ||
      isEqual(oldVal, newVal) ||
      !filterValueExists(newVal, 'Clients')
    ) {
      return;
    }

    this.loading = true;

    const dateVal = findFilter<DateRangeFilter>(newVal, 'DateRange')?.value;

    const req: ContentDataRequest = {
      companyIds: findFilter<TermsFilter>(newVal, 'Clients')?.value.map((x) => x.id),
      from: dateVal && dayjs(dateVal.from).format('YYYY-MM-DD'),
      to: dateVal && dayjs(dateVal.to).format('YYYY-MM-DD'),
    };

    this.count = await ContentOverviewDashboardService.getDocCount(req);
    // console.log("empty", this.count);
    this.loading = false;
    this.$emit('updateDocCount', this.count);
  }
}
</script>

<style scoped lang="scss">
.sub-no-data {
  opacity: 1;
  font-family: Overpass, sans-serif;
  font-size: 0.8rem;
  font-weight: normal;
  text-align: center;
}

.card-icon {
  position: absolute;
  top: 10px;
}

.subheading-container {
  height: 20px;
  text-align: center;
  margin-top: 40px;
  margin-left: 0px;
  font: normal normal normal 16px/30px Overpass;
  letter-spacing: 0px;
  color: #54606e;
  opacity: 1;
}

.count {
  text-align: center;
  font-size: 95px;
  font-weight: bold;
}

.sub-no-data {
  opacity: 1;
  font-family: Overpass, sans-serif;
  font-size: 0.8rem;
  font-weight: normal;
  text-align: center;
}
</style>
