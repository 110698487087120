import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12 pb-0" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "line-height-1" }
const _hoisted_5 = { class: "p-secondary-color text-xs" }
const _hoisted_6 = { class: "col-12 lg:col-5 pt-4" }
const _hoisted_7 = { class: "justify-content-between" }
const _hoisted_8 = { class: "line-height-1 capitalize" }
const _hoisted_9 = { class: "pt-5" }
const _hoisted_10 = { class: "pt-5" }
const _hoisted_11 = { class: "col-12 lg:col-7 py-0 pt-4 my-0 pl-3" }
const _hoisted_12 = { class: "flex" }
const _hoisted_13 = { class: "col-6 pr-2" }
const _hoisted_14 = { class: "col-6 pl-2" }
const _hoisted_15 = { class: "pt-5" }
const _hoisted_16 = { class: "pt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cp_engagements_by_month = _resolveComponent("cp-engagements-by-month")!
  const _component_cp_data_view = _resolveComponent("cp-data-view")!
  const _component_cp_engagements_stat_card = _resolveComponent("cp-engagements-stat-card")!
  const _component_cp_data_table = _resolveComponent("cp-data-table")!
  const _component_cp_engagements_by_category = _resolveComponent("cp-engagements-by-category")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.titleText), 1)
      ]),
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.dateRange), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("h2", _hoisted_8, _toDisplayString(_ctx.companyDisplayName) + " ", 1)
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_cp_engagements_by_month, {
          "filter-model": _ctx.filterModelWithoutOrgOrCompany,
          "org-filter": _ctx.orgOrCompanyFilter
        }, null, 8, ["filter-model", "org-filter"])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_cp_data_view, {
          "filter-model": _ctx.filterModelWithoutOrgOrCompany,
          "org-filter": _ctx.orgOrCompanyFilter,
          "agg-type": "mostActiveReaders"
        }, null, 8, ["filter-model", "org-filter"])
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_cp_engagements_stat_card, {
            "filter-model": _ctx.filterModelWithoutOrgOrCompany,
            "org-filter": _ctx.orgOrCompanyFilter,
            stat: "engagements"
          }, null, 8, ["filter-model", "org-filter"])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createVNode(_component_cp_engagements_stat_card, {
            "filter-model": _ctx.filterModelWithoutOrgOrCompany,
            "org-filter": _ctx.orgOrCompanyFilter,
            stat: "users"
          }, null, 8, ["filter-model", "org-filter"])
        ])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createVNode(_component_cp_data_table, {
          "filter-model": _ctx.filterModelWithoutOrgOrCompany,
          "org-filter": _ctx.orgOrCompanyFilter,
          "agg-type": "mostReadContent"
        }, null, 8, ["filter-model", "org-filter"])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createVNode(_component_cp_engagements_by_category, {
          "filter-model": _ctx.filterModelWithoutOrgOrCompany,
          "org-filter": _ctx.orgOrCompanyFilter
        }, null, 8, ["filter-model", "org-filter"])
      ])
    ])
  ]))
}