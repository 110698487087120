<template>
  <Card v-if="loading" class="p-reader-card pl-1 pr-1 mb-6"
    style="--bg-color: transparent linear-gradient(237deg, #ffffff 0%, #f0f3f5 100%)">
    <template #content>
      <div class="no-reader">
        <div class="flex" style="color: #1b3557">
          <div class="col-6">
            READER PROFILE
          </div>
        </div>
        <cp-no-datatable-records />
      </div>
    </template>
  </Card>
  <Card v-if="readerInfo && readerInfo.email" class="p-reader-card pl-1 pr-1"
    style="--bg-color: transparent linear-gradient(237deg, #ffffff 0%, #f0f3f5 100%)">
    <template #subtitle>
      <div class="flex" style="color: #1b3557">
        <div class="col-6">
          READER PROFILE
        </div>
        <div class="flex flex-row-reverse col-6">
          PRIVACY CONSENT: {{ clientConsentDate }}
        </div>
      </div>
    </template>
    <template #content>
      <div class="flex pb-0 pt-0">
        <div class="flex align-items-center">
          <span class="material-icons text-7xl"> account_circle </span>
        </div>
        <div class="pl-3">
          <div class="p-card-title capitalize font-bold">
            {{ (readerInfo.firstName || "") + " " + (readerInfo.lastName || "") }}
          </div>
          <div class="capitalize">
            {{ readerInfo.jobTitle + ", " }}
            <router-link :to="readerInfo.readerCompanyUrl" class="opacity-100 p-primary-color">
              {{ readerInfo.company }}
            </router-link>
          </div>
        </div>
        <div class="col flex flex-row-reverse">
          <a v-if="fullLinkedInUrl" :href="fullLinkedInUrl" target="_blank"
            class="p-primary-color opacity-100 font-medium">
            <i class="pi pi-linkedin" />
          </a>
        </div>
      </div>
      <div class="flex mt-3">
        <div>
          <div class="underline">
            <a :href="'mailto:' + readerInfo.email" class="p-primary-color opacity-100 font-medium">{{ readerInfo.email
            }}</a>
          </div>
        </div>
        <div class="col flex flex-row-reverse">
          <span class="material-icons" style="font-size: 1rem"> email </span>
        </div>
      </div>
      <div class="flex m-0 pt-2">
        <div class="capitalize">
          {{ readerInfo.formattedAddr }}
        </div>
        <div class="col flex flex-row-reverse">
          <span class="material-icons" style="font-size: 1rem"> place </span>
        </div>
      </div>
      <div class="flex m-0 pt-2">
        <div>
          <div>{{ readerInfo?.address?.phone }}</div>
        </div>
        <div class="col flex flex-row-reverse">
          <span class="material-icons" style="font-size: 1rem"> phone </span>
        </div>
      </div>
    </template>
  </Card>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import readerService from '@/services/readerService';
import * as UserServiceModels from '@/models/user-service/readerInfo';
import dayjs from 'dayjs';
import { Options, Vue } from 'vue-class-component';
import NoDataTableRecordsComponent from '@/components/common/NoDataTableRecordsComponent.vue';
import commonLookupService from '@/services/commonLookupService';

@Options({
  components: {
    'cp-no-datatable-records': NoDataTableRecordsComponent,
  },
})
export default class ReaderInfo extends Vue {
  @Prop() email: string;
  readerInfo: UserServiceModels.ReaderInfo | null = null;

  loading = true;

  @Watch('email')
  async emailChanged(newVal: string, oldVal: string) {
    // console.debug(`ReaderInfo.emailChanged() - { newEmail, oldEmail }`, newVal, oldVal);
    if (!newVal || newVal === oldVal) {
      return;
    }

    await this._getReaderInfo(newVal);
  }

  get clientConsentDate(): string {
    if (this.readerInfo?.clientConsent && this.readerInfo?.clientConsentUpdatedDate) {
      return dayjs(this.readerInfo.clientConsentUpdatedDate).format('YYYY-MM-DD');
    }
    return 'NO CONSENT';
  }

  get fullLinkedInUrl(): string {
    if (!this.readerInfo || !this.readerInfo.linkedIn) {
      return '';
    }

    const { linkedIn } = this.readerInfo;
    const httpRegex = new RegExp('http');
    return httpRegex.test(linkedIn) ? linkedIn : `https://${linkedIn}`;
  }

  private async _getReaderInfo(email: string) {
    this.readerInfo = await readerService.getReaderInfo(email);

    var countryMap = await commonLookupService.getCountries();
    var stateMap = await commonLookupService.getStates();

    if (this.readerInfo.address?.country) {
      var country = countryMap.find(x => x.id === this.readerInfo?.address.country.toUpperCase())?.name;

      if (country)
        this.readerInfo.address.country = country;
    }

    if (this.readerInfo.address?.state) {
      var state = stateMap.find(x => x.id === this.readerInfo?.address.state.toUpperCase())?.name;

      if (state)
        this.readerInfo.address.state = state;
    }

    // console.debug(`ReaderInfo._getReaderInfo() - { readerInfo, email }`, this.readerInfo, email);
    this.loading = false;
  }

  get getReaderCompanyUrl() {
    return '/analytics/reader-co-dashboard/' + this.readerInfo?.company;
  }
}
</script>

<style lang="scss" scoped>
.p-reader-card {
  color: #1b3557 !important;
  font-weight: 500;
  box-shadow: 0 4px 1px -1px #bac2cc, 1px 1px 1px 1px rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%) !important;
}

.no-reader {
  height: 220px;
}
</style>
