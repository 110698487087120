import notificationDashboardService from '@/services/notificationDashboardService';
import { GetterTree, MutationTree, Module } from 'vuex';
import { CurrentNotificationState } from '../models/currentNotificationState';

export const NOTIFICATION_MODULE = Object.freeze({
  SET_DASHBOARD_UNREAD_COUNT: 'setUnreadCount',
  GET_DASHBOARD_UNREAD_COUNT: 'getUnreadCount'
});

const GETTERS: GetterTree<CurrentNotificationState, any> = {
  dashboardUnreadCount: state => state.dashboardUnreadCount || 0
};

const MUTATIONS: MutationTree<CurrentNotificationState> = {
  [NOTIFICATION_MODULE.SET_DASHBOARD_UNREAD_COUNT](state, count: number) {
    state.dashboardUnreadCount = count;
  }
};

export const notificationModule: Module<CurrentNotificationState, any> = {
  namespaced: true,
  state: {
    dashboardUnreadCount: undefined
  },
  getters: GETTERS,
  mutations: MUTATIONS,
  actions: {
    [NOTIFICATION_MODULE.GET_DASHBOARD_UNREAD_COUNT]({ commit }) {
      notificationDashboardService.getUnreadCount().then(count => {
        commit(NOTIFICATION_MODULE.SET_DASHBOARD_UNREAD_COUNT, count);
    });
    }
  }
};
