import { ActionItem } from '@/models/common/actionItem';
import { ThresholdDirection } from '.';

export interface ThresholdDirectionActionItem extends ActionItem {
  type: ThresholdDirection;
}

export const DEFAULT_THRESHOLD_DIRECTION_ACTION: ThresholdDirectionActionItem = {
  type: ThresholdDirection.Both,
  text: 'more or less',
  description: 'Select past vs. current period change direction is both Positive and Negative',
  active: true
};

export const THRESHOLD_DIRECTION_ACTIONS: ThresholdDirectionActionItem[] = [
  {
    type: ThresholdDirection.Positive,
    text: 'more',
    description: 'Select past vs. current period change direction is Positive'
  },
  {
    type: ThresholdDirection.Negative,
    text: 'less',
    description: 'Select past vs. current period change direction is Negative'
  },
  {
    type: ThresholdDirection.Both,
    text: 'more or less',
    description: 'Select past vs. current period change direction is both Positive and Negative',
    active: true
  }
];
