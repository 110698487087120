<template>
  <div class="grid pt-3 pb-0 vertical-align-middle">
    <label for="bothButton" class="text-sm font-bold mx-2">{{ defaultLabel }}</label>
    <div class="mr-5">
      <RadioButton id="bothButton" v-model="bothSelection" name="both" :value="BOTH" class="mr-2"
        @click="updateValue(BOTH)" />
      <label for="both" class="text-sm font-bold">{{ noSelectionLabel }}</label>
    </div>
    <div class="mr-5">
      <RadioButton id="existsButton" v-model="localSelection" name="exists" value="true" class="mr-2"
        @click="updateValue('true')" />
      <label for="exists" class="text-sm font-bold">{{ existsLabel }}</label>
    </div>
    <div>
      <RadioButton id="notExistsButton" v-model="localSelection" name="notExists" value="false" class="mr-2"
        @click="updateValue('false')" />
      <label for="notExists" class="text-sm font-bold">{{ notExistsLabel }}</label>
    </div>
  </div>
</template>

<script lang="ts">

import { PropType, ref } from 'vue';
import { ExistsFilter, toLabel } from '@/models/filters';

export default {
  name: 'CpExistsSearchField',
  props: {
    modelValue: { type: Object as PropType<ExistsFilter>, default: new ExistsFilter() },
    label: { type: String, default: '' },
    noSelectionLabel: { type: String, default: 'Both' },
    existsLabel: { type: String, default: 'Exists' },
    notExistsLabel: { type: String, default: `Doesn't Exist` }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const defaultLabel = props.label || toLabel(props.modelValue.field);
    const BOTH = 'both';
    const localSelection = ref<string | null>(props.modelValue.value === null || props.modelValue.value === undefined ? null : props.modelValue.value.toString());
    const bothSelection = ref<string | undefined>(props.modelValue.value === null ? BOTH : undefined);
    const updateValue = (val: any) => {
      if (val === BOTH) {
        bothSelection.value = BOTH;
        localSelection.value = null;
      } else {
        bothSelection.value = undefined;
      }

      const newVal = new ExistsFilter({
        ...props.modelValue,
        value: val === BOTH ? null : JSON.parse(val)
      });
      emit('update:modelValue', newVal);
    };

    return { defaultLabel, updateValue: updateValue, BOTH, bothSelection, localSelection };
  }
};
</script>
