
import axios from 'axios';
import { CompanyClaim } from '@/models/security';

export default {

  async find(q: string, includeCidInName = false): Promise<CompanyClaim[]> {
    const response = await axios.get<CompanyClaim[]>('/api/cms/companies', { params: { q } });
    return includeCidInName
      ? (response.data || []).map(x => ({ id: x.id, name: `${x.name} - ${x.id}`, onlines: x.onlines}))
      : response.data;
  },
  
  async findByNameOrIdStartingWith(q: string, includeCidInName = false): Promise<CompanyClaim[]> {
      const response = await axios.get<CompanyClaim[]>('/api/cms/companies/by-name-or-id-starting-with', { params: { q } });
      return includeCidInName
          ? (response.data || []).map(x => ({ id: x.id, name: `${x.name} - ${x.id}`, onlines: x.onlines}))
          : response.data;
  }
};
