<template>
  <div v-if="anyQuestions" class="mt-2">
    <div class="p-column-value">
      {{ title }}
    </div>
    <div v-for="(item, index) in modelValue" :key="index" class="pt-2">
      <div v-if="!!item.question" class="flex p-column-value text-left mt-2">
        <div class="flex-none pr-1">
          <b>[Q]</b>
        </div>
        <div class="flex-grow-1">
          {{ item.question }}
        </div>
      </div>
      <div v-if="!!item.renderedAnswer" class="p-column-value">
        <b>[A]</b> {{ item.renderedAnswer }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { AnsweredQuestion } from '@/models/analytics';
import { computed, PropType } from 'vue';

export default {
  name: 'ListQuestions',
  props: {
    modelValue: { type: Array as PropType<AnsweredQuestion[]>, required: true, default: () => [] },
    title: { type: String, required: true },
  },
  setup(props) {
    // computed : {
    //   anyQuestions() {
    //     return !!this.questions?.length;
    //   }
    // }

    const anyQuestions = computed(() => !!props.modelValue?.length);

    return { anyQuestions };
  }
};
</script>

<style scoped></style>