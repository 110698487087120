import { ActivityField } from '@/models/search';
import axios from 'axios';

const baseUrl: string = `/api/activity-fields`;
class ActivityFieldsService {

  async get(): Promise<ActivityField[]> {
    const fields = (await axios.get<ActivityField[]>(`${baseUrl}`)).data;
    return fields;
  }

  async getByUserId(id: string): Promise<ActivityField[]> {
    const fields = (await axios.get<ActivityField[]>(`${baseUrl}/${id}`)).data;
    return fields;
  }
}

export default new ActivityFieldsService();
