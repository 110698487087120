import { ActivityReportDashboardDetails } from '@/models/activity-dissemination/dashboard';
import axios from 'axios';

class ActivityReportDetailsService {
  private baseUrl: string = '/api/activity-report';

  async get(id: string, runId: string): Promise<ActivityReportDashboardDetails> {
    const r = await axios.get<ActivityReportDashboardDetails>(`${this.baseUrl}/${id}/${runId}`);

    return new ActivityReportDashboardDetails(r.data);
  }

  async downloadFile(filePath: string): Promise<Blob> {
    const file = await axios.get<Blob>(filePath, {responseType: 'blob'});

    return file.data;
  }
}

export default new ActivityReportDetailsService();
