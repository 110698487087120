<template>
  <Dialog id="shareReportDialog" v-model:visible="showDialog" :modal="true" :closable="false" :style="{ width: '75vw' }"
    class="share-report">
    <template #header>
      <div class="flex col-12">
        <div class="flex col-11">
          <h4 style="color: white;">
            Share With Colleagues
          </h4>
        </div>
        <div class="flex col-1 absolute right-0 align-items-center justify-content-center" style="padding-right:0rem;">
          <i class="pi pi-times reverse" aria-haspopup="true" aria-controls="overlay_menu" style="color: white;"
            @click="closeDialog" />
        </div>
      </div>
    </template>
    <div class="flex flex-column">
      <div class="flex align-items-center mb-4">
        <Message :closable="false">
          Enter valid e-mail addresses to share the details of this report.
          To ensure this message is received, ask recipients to add activities@templetoninsights.com to their safe
          sender
          list.
          <span v-html-safe="whitelistInstruction" />
        </Message>
      </div>
      <div v-for="(address, index) in emailAddresses" :key="index">
        <div v-if="emailTypes.some(emailType => emailType.id === address.type)" class="flex p-fluid">
          <div class="col-2">
            <span v-if="index === 0" class="p-float-label p-fluid p-2">To</span>
            <span v-else class="p-float-label p-fluid">
              <Dropdown v-model="address.type" :options="emailTypes" option-label="name" option-value="id" />
            </span>
          </div>
          <div class="col-4">
            <span class="p-float-label p-fluid">
              <InputText id="name" v-model="address.name" class="col-12" placeholder="Enter name" />
              <label for="name">Name</label>
            </span>
            <small class="p-error"> {{ v$.emailAddresses.$each.$response.$errors[index].name.map(e =>
              e.$message.replace('Value', 'Name')).join(', ') }}
            </small>
          </div>
          <div class="col-5">
            <span class="p-float-label p-fluid">
              <InputText id="email" v-model="address.email" type="email" class="col-12"
                placeholder="Enter email address" />
              <label for="email">Email</label>
            </span>
            <small class="p-error"> {{ v$.emailAddresses.$each.$response.$errors[index].email.map(e =>
              e.$message.replace('Value', 'Email')).join(', ') }}
            </small>
          </div>
          <div class="col-1">
            <Button v-if="index === 0" class="p-button-success p-button-raised p-button-icon-only"
              @click="addEmailEntry">
              <span class="material-icons-outlined">add</span>
            </Button>
            <Button v-if="index > 0" class="p-button-success p-button-raised p-button-icon-only mr-1"
              @click="removeEmailEntry(index)">
              <span class="material-icons-outlined">remove</span>
            </Button>
          </div>
        </div>
      </div>
      <div class="flex align-item-center mt-3">
        <div class="col-12">
          <span class="p-float-label p-fluid1">
            <InputText id="subject" v-model="emailMessage.subject" class="col-12" placeholder="Enter subject" />
            <label for="subject">Subject</label>
          </span>
        </div>
      </div>
      <div class="flex align-item-center mt-2">
        <div class="col-12">
          <span class="font-semibold mr-2">Attach Report</span>
          <Checkbox v-model="emailMessage.attachReportExcel" input-id="attachReportExcel" :binary="true"
            class="p-checkbox p-checkbox-rounded" />
          <label for="attachReportExcel" class="pl-1 pr-2">Excel</label>
          <Checkbox v-model="emailMessage.attachReportPdf" input-id="attachReportPdf" :binary="true"
            class="p-checkbox p-checkbox-rounded" />
          <label for="attachReportPdf" class="pl-1">PDF</label>
        </div>
      </div>
      <div class="flex align-items-center mt-3">
        <div class="col-12">
          <h4 class="mb-3">
            Edit the Message Below
          </h4>
          <p class="mb-3">
            Use the HTML editor below to edit the contents of the message.
            You can edit text, remove, or reorder items within the message.
            Scroll within the container to see more of the message.
            When you are satisfied, click the "Send" below, to share the report with your contacts.
          </p>
          <ckeditor v-model="emailMessage.body" :editor="editor" :config="editorConfig" />
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex justify-content-end">
        <Button type="button" label="Cancel" class="p-button-outlined p-button-rounded mr-3" @click="closeDialog" />
        <Button type="button" label="Send" class="p-button-success p-button-raised p-button-rounded"
          :loading="sendingEmail" :disabled="v$.$invalid" @click="sendEmail" />
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import { computed, reactive, ref } from 'vue';
import { whitelistInstruction } from '@/models/common/alertMessage';
import { IndividualActivitySearchDetails } from '@/models/search/individualActivitySearchDetails';
import { Lookup } from '@/models/lookup';
import { ACTIVITY_TYPE_LOOKUPS } from '@/models/analytics/activityTypes';
import dayjs from 'dayjs';
import Editor from '@vert/ckeditor5-custom';
import CKEditor from '@ckeditor/ckeditor5-vue';
import type { EditorConfig } from '@ckeditor/ckeditor5-core';
import useAuthenticatedUser from '@/use/authenticatedUser';
import { email as emailValidator, helpers, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { EmailAddress, EmailMessage, EmailType } from '@/models/emailMessage';
import individualActivityService from '@/services/individualActivityService';
import { useRoute } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import { onError, onSuccess } from '@/utils/toast/toastUtils';

export default {
  name: 'IndividualActivityShare',
  components: { ckeditor: CKEditor.component },
  props: {
    show: { type: Boolean, required: true },
    activity: { type: IndividualActivitySearchDetails, required: true },
    activityPdf: { type: String, required: true }
  },
  emits: ['closed'],
  setup(props, { emit }) {
    const toast = useToast();
    const route = useRoute();
    const activityReportId = route.params['activityReportId'] as string;
    const activity = props.activity as IndividualActivitySearchDetails;
    const showDialog = computed(() => props.show);
    const closeDialog = () => { emit('closed'); };
    const { authenticatedUser } = useAuthenticatedUser();
    const fullName = authenticatedUser.value.name;
    const emailTypes = ref<Array<Lookup<EmailType>>>([
      { id: EmailType.To, name: 'To' },
      { id: EmailType.Cc, name: 'Cc' },
      { id: EmailType.Bcc, name: 'Bcc' }
    ]);
    const newEmailAddress = (): EmailAddress => ({ type: EmailType.To, name: '', email: '' });
    const emailAddresses = reactive<EmailAddress[]>([newEmailAddress()]);
    const maxEmailAddresses: number = 20;
    const activityTypeName = ACTIVITY_TYPE_LOOKUPS.find(x => x.id === activity.activityType)?.name;
    const subject = `${activity.portal} by Life Science Connect ${activityTypeName} from ${activity.fullName}, ${activity.jobTitle} @ ${activity.company}`;
    const portalLogo = `https://leadtrail.blob.core.windows.net/images/onlines/${activity.portal.replace(/\s/g, '_').replace('&', 'and').toLowerCase()}.png`;
    const otherActivitiesForReaderCount = activity.otherActivitiesForReaderCount ?
      `<li style="padding-top: .5rem !important;" class="hl-black">
        <a href="${window.location.origin}/analytics/reader-dashboard/${activity.emailUrl}">${activity.firstName}</a> 
        has ${activity.otherActivitiesForReaderCount} other activities recorded with your content in the last 6 months.
      </li>` : '';
    const otherReadersCount = activity.otherReadersCount ?
      `<li style="padding-top: .5rem !important;" class="hl-black">
        ${activity.otherReadersCount} other reader(s) from <a href="${window.location.origin + activity.readerCompanyUrl}">${activity.company}</a> 
        also consumed your content in the past 6 months.
      </li>` : '';
    const relatedInsights = activity.otherActivitiesForReaderCount || activity.otherReadersCount ?
      `<table cellpadding="20" cellspacing="0" width="100%" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-spacing:0px;width:100%;border:1px solid #9babdd;border-radius:20px;">
        <tr>
          <td width="100%">
            <img src="${window.location.origin}/themes/vert-analytics/vert-analytics-light/icons/account-star.png" alt="">&nbsp;
            <span style="font-size: 18px; font-weight: 700;">Related insights</span>
            <br />
            <div style="font-weight: 700; padding-left: 1rem;">
              <ul>
                ${otherActivitiesForReaderCount}
                ${otherReadersCount}
              </ul>
            </div>
          </td>
        </tr>
      </table>` : '';
    const body = `<center><img src="https://leadtrail.blob.core.windows.net/images/templeton_email_header.png" alt="Templeton"></center>
      <p>I wanted to bring this activity to your attention, received from our partner - Life Science Connect.
      <strong>${activity.fullName}</strong> with <strong>${activity.company}</strong> has the following request as relates to our content - 
      <strong>${activity.docTitle}</strong> promoted on <strong>${activity.portal}</strong> portal.</p>
      <table border="0" cellpadding="0" cellspacing="0" width="100%" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-spacing:0px;width:100%">
        <tr>
          <td colspan="2">
            <table border="0" cellpadding="0" cellspacing="0" width="100%" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-spacing:0px;width:100%">
              <tr style="border:0;background-color:#27538e;color:#FFFFFF;">
                <td width="160" style="border:0;background-color:#27538e;color:#FFFFFF;padding:10px;margin:0;padding-left:20px;">
                  <span style="color:#FFFFFF;"><strong>${activityTypeName} Notes</strong></span>
                </td>
                <td style="border:0;background-color:#27538e;color:#FFFFFF;text-align:right;padding:10px;margin:0;padding-right:20px;">
                  <img src="${portalLogo}" alt="${activity.portal} logo" style="float:right">
                </td>
              </tr>
              <tr style="border:0;background-color:#27538e;color:#FFFFFF;">
                <td colspan="2" style="border:0;background-color:#27538e;color:#FFFFFF;padding:0px;margin:0;padding-left:20px;padding-right:20px;">
                  <span style="color:#FFFFFF;"><strong>${dayjs(activity.date).format('MM/DD/YYYY')}</strong></span>
                </td>
              </tr>
              <tr style="border:0;background-color:#27538e;color:#FFFFFF;">
                <td colspan="2" style="border:0;background-color:#27538e;color:#FFFFFF;padding:10px 20px 20px 20px;margin:0;">
                  <p>
                    <span class="hl-white" style="color:#FFFFFF;">
                      ${activity?.specifics.replace('\n', '').trim().replace(/(http[s]?:\/\/[^\s]+)/g, '<a href="$1" style="color:#ffffff !important">$1</a>')}
                    </span>
                  </p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr style="border:0;">
          <td colspan="2" style="border:0;padding:10px;margin:0;padding-left:20px;padding-right:20px;"><br>
            <strong>Activity Details:</strong>
          </td>
        </tr>
        <tr style="border:0;background-color:#f8f8f8">
          <td width="25%" style="border:0;background-color:#f8f8f8;padding:10px;margin:0;padding-left:20px;max-width:150px;">
            <strong>Content Title</strong>
          </td>
          <td style="border:0;background-color:#f8f8f8;padding:10px;margin:0;padding-right:20px;" class="hl-black">
            <a href="${activity.portalUrl}" title="${activity?.docTitle}" target="_blank" class="hl-black" style="color:#000000 !important">${activity?.docTitle}</a>
          </td>
        </tr>
        <tr style="border:0;">
          <td width="25%" style="border:0;padding:10px;margin:0;padding-left:20px;max-width:150px;">
            <strong>Name</strong>
          </td>
          <td style="border:0;padding:10px;margin:0;padding-right:20px;">
            ${activity?.fullName}
          </td>
        </tr>
        <tr style="background-color:#f8f8f8">
          <td width="25%" style="border:0;background-color:#f8f8f8;padding:10px;margin:0;padding-left:20px;max-width:150px;">
            <strong>Title</strong>
          </td>
          <td style="border:0;background-color:#f8f8f8;padding:10px;margin:0;padding-right:20px;">
            ${activity?.jobTitle}
          </td>
        </tr>
        <tr>
          <td width="25%" style="border:0;padding:10px;margin:0;padding-left:20px;max-width:150px;">
            <strong>Company Name</strong>
          </td>
          <td style="border:0;padding:10px;margin:0;padding-right:20px;">
            ${activity?.company}
          </td>
        </tr>
        <tr style="background-color:#f8f8f8">
          <td width="25%" style="border:0;background-color:#f8f8f8;padding:10px;margin:0;padding-left:20px;max-width:150px;">
            <strong>Email</strong>
          </td>
          <td style="border:0;background-color:#f8f8f8;padding:10px;margin:0;padding-right:20px;" class="hl-black">
            <a href="mailto:${activity?.email}" title="${activity?.email}" class="hl-black" style="color:#000000 !important">${activity?.email}</a>
          </td>
        </tr>
        <tr>
          <td width="25%" style="border:0;padding:10px;margin:0;padding-left:20px;max-width:150px;">
            <strong>Phone Number</strong>
          </td>
          <td style="border:0;padding:10px;margin:0;padding-right:20px;">
            ${activity?.phone}
          </td>
        </tr>
        <tr style="background-color:#f8f8f8">
          <td width="25%" style="border:0;background-color:#f8f8f8;padding:10px;margin:0;padding-left:20px;max-width:150px;">
            <strong>Address</strong>
          </td>
          <td style="border:0;background-color:#f8f8f8;padding:10px;margin:0;padding-right:20px;">
            ${activity?.formattedAddr}
          </td>
        </tr>
      </table>
      <p>&nbsp;</p>
      ${relatedInsights}
      <p>Thank You,</p>
      <p><strong>${fullName}</strong></p>`;

    const emailMessage = ref<EmailMessage>({
      addresses: emailAddresses,
      subject: subject,
      body: body,
      attachReportExcel: true,
      attachReportPdf: true,
      activityPdf: null
    });

    const editor = Editor;
    const editorConfig: EditorConfig = {
      htmlSupport: {
        allow: [
          {
            name: /.*/,
            attributes: true,
            classes: true,
            styles: true
          }
        ],
        disallow: [
          {
            name: /script/i
          }
        ]
      }
    };

    const v$ = useVuelidate({
      emailAddresses: {
        $each: helpers.forEach({
          email: { required, emailValidator },
          name: { required },
          type: { required }
        })
      }
    }, { emailAddresses });

    const addEmailEntry = () => {
      if (emailAddresses.length < maxEmailAddresses) {
        emailAddresses.push(newEmailAddress());
      }
    };

    const removeEmailEntry = (index: number) => {
      emailAddresses.splice(index, 1);
    };

    const sendingEmail = ref<boolean>(false);

    const sendEmail = async () => {
      sendingEmail.value = true;
      emailMessage.value.addresses.push({ type: EmailType.From, name: fullName, email: authenticatedUser.value.email });
      emailMessage.value.activityPdf = emailMessage.value.attachReportPdf ? await getActivityPdf() : null;

      const formData = new FormData();
      formData.append('activityReportId', activityReportId);
      formData.append('addresses', JSON.stringify(emailMessage.value.addresses));
      formData.append('subject', emailMessage.value.subject);
      formData.append('body', emailMessage.value.body);
      formData.append('attachReportExcel', emailMessage.value.attachReportExcel.toString());
      formData.append('attachReportPdf', emailMessage.value.attachReportPdf.toString());
      formData.append('activityPdf', emailMessage.value.activityPdf as File);

      const res = await individualActivityService.shareReport(formData);
      sendingEmail.value = false;
      if (res.success) {
        onSuccess(toast, res.message);
        closeDialog();
      }
      else {
        onError(toast, res.message);
      }
    };

    const getActivityPdf = async (): Promise<Blob> => {
      const response = await fetch(props.activityPdf);
      const blob = await response.blob();
      return new File([blob], 'ActivityReport.pdf', { type: blob.type });
    };

    return {
      showDialog,
      closeDialog,
      whitelistInstruction,
      emailAddresses,
      emailTypes,
      addEmailEntry,
      removeEmailEntry,
      emailMessage,
      editor,
      editorConfig,
      v$,
      sendingEmail,
      sendEmail,
    };
  }
};
</script>

<style lang="scss">
.p-dialog.share-report {
  border-radius: 23px;
  box-shadow: 2px 2px 15px #00000047;
  border: 0px solid white;
}

.p-dialog.share-report .p-dialog-header {
  padding-left: 0rem;
  background: #1B3557;
  color: #ffffff;
  border-top-right-radius: 23px;
  border-top-left-radius: 23px;
}

.p-dialog.share-report .p-dialog-footer {
  border-bottom-right-radius: 23px;
  border-bottom-left-radius: 23px;
}

.p-button.p-button-icon-only {
  border-radius: 50%;
  min-width: auto;
}
</style>
