import { createFilter, Filter } from '@/models/filters';
import { Lookup } from '@/models/lookup';
import axios from 'axios';
import { FilterModel } from '@/models/search';

class DefaultFiltersService {
  async getDefault(userId: string): Promise<FilterModel> {
    if (!userId) {
      return { filters: [], indices: [] };
    }
    const { savedSearchId, filters, indices } = (await axios.get<FilterModel>(`/api/default-filters/${userId}`)).data;

    const filterInstances = filters.map(f => createFilter(f.$type, f));
    return { savedSearchId, filters: filterInstances, indices };
  }

  async getBySavedSearchId(userId: string, searchId: string): Promise<FilterModel> {
    if (!searchId) {
      return { filters: [], indices: [] };
    }
    const { savedSearchId, filters, indices } = (await axios.get<FilterModel>(`/api/default-filters/${userId}/${searchId}`)).data;

    const filterInstances = filters.map(f => createFilter(f.$type, f));
    return { savedSearchId, filters: filterInstances, indices };
  }

  async save(userId: string, defaultFilters: Filter[], defaultIndices: Array<Lookup<string>>): Promise<FilterModel> {
    const { filters, indices } = (await axios.put<FilterModel>('/api/default-filters', { filters: defaultFilters, userId, indices: defaultIndices })).data;
    const filterInstances = filters.map(f => createFilter(f.$type, f));
    return { filters: filterInstances, indices };
  }
}

export default new DefaultFiltersService();
