export type Operation =
  'Equals' |
  'Contains' |
  'Like' |
  'NotEqual' |
  'NotContains' |
  'NotLike' |
  'LessThan' |
  'LessThanOrEquals' |
  'GreaterThan' |
  'GreaterThanOrEquals'

export const TERM_OPERATIONS: Operation[] = [
  'Contains',
  'NotContains'
];

export const TEXT_OPERATIONS: Operation[] = [
  'Equals',
  'Contains',
  'Like',
  'NotEqual',
  'NotContains',
  'NotLike',
];

export const NUMERIC_OPERATIONS: Operation[] = [
  'LessThan',
  'LessThanOrEquals',
  'Equals',
  'GreaterThan',
  'GreaterThanOrEquals'
];

export interface WithOperation {
  op: Operation;
  restrictedToOps?: Operation[];
}

export const operationLabel: Partial<Record<Operation, string>> = {
  'Equals': 'Equals',
  'Contains': 'Contains',
  'Like': 'Like',
  'NotEqual': 'Not Equals',
  'NotContains': 'Not Contains',
  'NotLike': 'Not Like',
  'LessThan': 'Less Than',
  'LessThanOrEquals': 'Less Than Or Equal To',
  'GreaterThan': 'Greater Than',
  'GreaterThanOrEquals': 'Greater Than Or Equal To'
};
