import { SaveActivityReportConfigRequest } from '@/models/activity-dissemination/saveActivityReportConfigRequest';
import axios, { AxiosPromise } from 'axios';
import { cloneDeep } from 'lodash';
import { ActivityReportConfigRow, ActivityReportConfig } from '@/models/activity-dissemination';
import { ApiResult } from '@/models/apiResult';
import { SearchCriteria } from './searchCriteria';
import { createFilter } from '@/models/filters';
import { PagedList } from '@/models/pagedList';

export default {
    async get(id: any): Promise<ActivityReportConfig | null> {
        if (!id) { return null; }
        const config = (await axios.get<ActivityReportConfig>(`/api/activity-report-config/${id}`)).data;
        if (!config) { return null; }
        config.filterModel.filters = config.filterModel.filters.map(f => createFilter(f.$type, f));
        return new ActivityReportConfig(config);
    },
    
    async getAllForUser(userId: string, opts: SearchCriteria): Promise<PagedList<ActivityReportConfigRow>> {
        const qsArgs = {
            params: {
                page: opts.page,
                pageSize: opts.pageSize, sortBy: opts.sortBy, sortDir: opts.sortDir
            }
        };
        const response = await axios.get(`/api/activity-report-configs/user/${userId}`, qsArgs);
        const pl: PagedList<any> = response.data;
        pl.items = pl.items.map(x => new ActivityReportConfigRow(x));
        return pl;
    },

    save(config: SaveActivityReportConfigRequest): Promise<ActivityReportConfig> {
        const req = <any>cloneDeep(config);
        let promise: AxiosPromise<ApiResult<ActivityReportConfig>>;
        if (config.id) {
            promise = axios.patch(`/api/activity-report-config/${req.id}`, req);
        } else {
            promise = axios.post('/api/activity-report-config', req);
        }

        return apiRequestPromiseHandler(promise).then(c => new ActivityReportConfig(c));
    },

    delete(id: any): Promise<boolean> {
        return axios.delete(`/api/activity-report-config/${id}`).then(() => true);
    }
};

function apiRequestPromiseHandler(requestPromise: Promise<any>): Promise<any> {
    return requestPromise.then(r => {
        const { success, message, data } = r.data;
        if (!success) { return Promise.reject(message || ''); }
        return data;
    }, err => {
        console.log('error', err);
        return Promise.reject(err);
    });
}
