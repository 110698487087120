import axios from 'axios';

const BASE_URL = '/api/analytics/share-individual-activity';

export default {
    async shareReport(formData: FormData): Promise<{ success: boolean, message: string }> {
        try {
            await axios.post(`${BASE_URL}`, formData);
            return {
                success: true,
                message: 'Report shared successfully'
            };
        } catch (error: any) {
            return {
                success: false,
                message: error.message
            };
        }
    }

};