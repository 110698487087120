import { FieldCode } from '@/models/filters';
import { GuidLookup, Lookup } from '@/models/lookup';
import { Option, Question } from '@/models/user-service';
import { Cacheable } from '@/utils/decorators/cacheable.decorator';
import axios from 'axios';
import { orderBy } from 'lodash';

class FilterLookupService {
  async getPersonas(query: string): Promise<Array<Lookup<string>>> {
    if (!query) { return []; }
    return (await axios.get('/api/user-proxy', {
      params: {
        path: `persona/lookups/${query}`
      }
    })).data;
  }

  async suggestOrgs(q: string): Promise<GuidLookup[]> {
    if (!q) { return []; }
    const response = await axios.get<GuidLookup[]>('/api/user-proxy', {
      params: {
        path: `suggest/reader-org/${q}`
      }
    });
    return response.data;
  }

  @Cacheable()
  async getOrgCustomFields(): Promise<Question[]> {
    const response = await axios.get<Question[]>('/api/user-proxy', {
      params: { path: `questions/3` }
    });
    const questions = response.data.map(q => {
      q.options = orderBy<Option>(q.options || [], o => (o.min !== undefined && o.min + 1) || o.text, 'asc');
      return q;
    });
    return questions;
  }

  async getOrgCustomField(idOrTitle: string | number): Promise<Question | null> {
    const questions = await this.getOrgCustomFields() || [];

    if (isNaN(<number>idOrTitle)) {
      const reTitleCheck = new RegExp(`^${idOrTitle}$`, 'i');
      return questions.find(q => reTitleCheck.test(q.title)) || null;
    } else {
      return questions.find(q => q.id === parseInt(<string>idOrTitle)) || null;
    }
  }

  async getOrgQuestionsMap(): Promise<Record<number, Question>> {
    return (await this.getOrgCustomFields()).reduce((map, q) => {
      map[q.id] = q;
      return map;
    }, <any>{});
  }

  async getDocumentTypes(): Promise<Array<Lookup<string>>> {
    const response = await axios.get('/api/cms-proxy', {
      params: { path: `/internal/lookups/document/doc-types` }
    });
    return response.data;
  }

  async getCmsTags(field: FieldCode, q?: string): Promise<Array<Lookup<string>>> {
    const response = await axios.get(`/api/filter-lookups/cms/tags/${field}${q ? '/' + q : ''}`);
    return response.data;
  }

  async getNewsletters(q: string): Promise<Array<Lookup<string>>> {
    if (!q) { return []; }
    const response = await axios.get('/api/filter-lookups/nl-builder/newsletters', {
      params: { query: q }
    });
    return response.data;
  }

  async getMediaSources(): Promise<Array<Lookup<string>>> {
    const items: Array<Lookup<string>> = [
      { id: 'web', name: 'Non-newsletter' },
      { id: 'all', name: 'All newsletters' },
      { id: 'editorial', name: 'Regular Newsletters' },
      { id: 'custom', name: 'Custom Newsletters' },
      { id: 'leadNurture', name: 'Lead Nurture Campaign Newsletters' },
    ];
    const response = await axios.get('/api/filter-lookups/cms/nl-custom-companies');

    items.push(...(response.data || []));
    return items;
  }

  async getServiceOfferings(): Promise<Array<Lookup<string>>> {
    const response = await axios.get<GuidLookup[]>('/api/user-proxy', {
      params: { path: `service-offerings` }
    });
    return response.data;
  }
}


export default new FilterLookupService();
