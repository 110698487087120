<template>
  <div class="flex p-fluid pb-2">
    <span v-if="showOps" class="p-float-label"
      :style="defaultLabel.length < 22 ? 'min-width: 150px' : 'min-width: 230px'">
      <Dropdown id="options" :model-value="local.op" :options="operationItems" option-label="name" option-value="id"
        class="p-border-round-left" @change="(v) => updateOp(v.value)" />
      <label for="options">{{ defaultLabel }}</label>
    </span>
    <div class="w-full">
      <span class="p-float-label w-full">
        <AutoComplete v-if="controlType === TermsControlType.Chips" :id="modelValue.field" ref="autocomplete"
          :multiple="true" :model-value="local.value" :suggestions="filteredOptions" field="name"
          :dropdown="loadOptionsOnce" :class="{
            'p-border-noround': showOps && loadOptionsOnce,
            'p-border-round-right': showOps && !loadOptionsOnce,
          }" :complete-on-focus="true" @complete="onSearch($event.query)"
          @item-select="updateVal($event.value, modelValue.field)" @item-unselect="deleteVal($event.value)" />
        <Dropdown v-if="controlType === TermsControlType.Select" :model-value="local.value[0]" :suggestions="options"
          field="name" :dropdown="true" class="p-border-round-left" @complete="onSearch($event.query)" />
        <AutoComplete v-if="controlType === TermsControlType.Autocomplete" :multiple="true" :model-value="local.value"
          :suggestions="filteredOptions" field="name" :dropdown="loadOptionsOnce" :class="{
            'p-border-noround': showOps && loadOptionsOnce,
            'p-border-round-right': showOps && !loadOptionsOnce,
          }" :complete-on-focus="true" @complete="onSearch($event.query)"
          @item-select="updateVal($event.value, modelValue.field)" @item-unselect="deleteVal($event.value)" />
        <label v-if="!showOps" for="options">{{ defaultLabel }}</label>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import { Lookup } from '@/models/lookup';
import { DEFAULT_TERMS_FILTER, TermsFilter } from '@/models/filters/termsFilter';
import { Debounce } from '@/utils/decorators/debounce.decorator';
import {
  TermsControlType,
  Operation,
  toLabel,
  TERM_OPERATIONS,
  operationLabel,
} from '@/models/filters';
import { cloneDeep } from 'lodash';
import { Vue, Options } from 'vue-class-component';
import { DEFAULT_TIMEOUT_TIME } from '@/models/common/constants';

const noop = () => Promise.resolve([]);

@Options({
  name: 'cp-terms-search-field',
})
export default class TermsSearchField extends Vue {
  options: Array<Lookup<any>> = [];
  optionsQuery: string | null = null;
  operationItems = TERM_OPERATIONS.map(x=><Lookup<Operation>>{id: x, name: operationLabel[x]});
  @Prop() modelValue: TermsFilter;
  @Prop() label: string;
  @Prop({ default: () => noop }) optionsGetter: (
    ...args: any
  ) => Promise<Array<Lookup<any>>>;
  @Prop({ default: true }) loadOptionsOnce: boolean;
  @Prop({ default: false }) orgSelected: boolean;
  @Prop({ default: 0 }) inputLength: boolean;
  @Prop({ default: false }) reset: boolean;
  @Prop({ default: false }) showOps: boolean;
  @Prop({ default: '' }) fieldName: string;
  @Prop({ default: () => TermsControlType.Chips }) controlType: TermsControlType;
  @Prop({ default: true }) dense: boolean;
  @Prop({ default: () => [] }) rules: Array<(val: string) => any>;

  TermsControlType: any = TermsControlType;

  filteredOptions: Array<Lookup<string>> = [];

  padding: string;
  query: string;

  async created() {
    this.$emit('update:modelValue', cloneDeep(this.local));

    // this.padding =
    //   this.defaultLabel === "State" ? "flex p-fluid w-full mr-5" : "flex p-fluid w-full";
  }

  get local(): TermsFilter {
    return new TermsFilter({ ...DEFAULT_TERMS_FILTER, ...this.modelValue });
  }

  get defaultLabel(): string {
    return this.label || toLabel(this.modelValue.field);
  }

  async mounted() {
    if (this.controlType === TermsControlType.Select && this.optionsGetter) {
      this.options = await this.optionsGetter();
    }

    var input = (this.$refs['autocomplete'] as any).$el.firstElementChild;
    if (this.orgSelected && this.modelValue.field == 'ReaderOrgs' && !this.reset) {
      if (input.innerText === '') return;
      input.classList.add('org-selected');
    }
    else {
      input.classList.remove('org-selected');
    }

    if (this.loadOptionsOnce) {
      this.options = await this.optionsGetter();
    }

  }

  @Watch('optionsQuery')
  @Debounce(DEFAULT_TIMEOUT_TIME)
  async onSearch(q: string) {
    if (q.length > 0) {
      this.query = q;
    }

    if (this.query?.length > 0 && this.optionsGetter) {
      this.options = await this.optionsGetter(q);
    }

    if (!this.query?.trim().length) {
      this.filteredOptions = this.options.filter((e1) => this.local.value.findIndex((e2) => e2.name == e1.name) === -1);
    } else {
      const notSelected = this.options.filter((e1) => this.local.value.findIndex((e2) => e2.name == e1.name) === -1);

      this.filteredOptions = notSelected.filter((type) => type.name.toLowerCase().includes(this.query?.toLowerCase()));
    }

    if (this.filteredOptions.length == 0) {
      this.query = '';
    }
  }

  @Watch('optionsGetter')
  async optionsGetterChanged() {
    // NOTE: This method will be called when optionsGetter prop gets changed from the outside.
    const autoComplete = <any>this.$refs.autocomplete;
    if (autoComplete) {
      autoComplete.cachedItems = [];
    }
    this.options = await this.optionsGetter();

  }

  @Watch('value', { deep: true })
  setDefaultOptions(val: TermsFilter) {
    if (!val) {
      return;
    }
    if (this.controlType !== TermsControlType.Select) {
      this.options = cloneDeep(val.value);
    }
  }

  public updateVal(value: any, id: string) {
    if (!value) {
      this.$emit('update:modelValue', new TermsFilter({ ...this.local, value: [] }));
      return;
    }
    // const lookups = (Array.isArray(value) ? value : [value]).map((x) => ({
    //   id: x.id,
    //   name: x.name,
    // }));
    const termValue: Array<Lookup<string>> = [
      ...this.local.value,
      { id: value.id, name: value.name },
    ];

    const newVal = new TermsFilter({ ...this.local, value: termValue });
    this.$emit('update:modelValue', newVal);

    document.getElementById(id)?.getElementsByTagName('input')[0].focus();
  }

  public deleteVal(value: any) {
    const findIndex = this.local.value.findIndex((a) => a.id === value.id);
    this.local.value.splice(findIndex, 1);

    const newVal = new TermsFilter({ ...this.local });
    this.$emit('update:modelValue', newVal);
  }



  updateOp(value: Operation) {
    // console.log(value);
    const newVal = new TermsFilter({ ...this.local, op: value });
    this.$emit('update:modelValue', newVal);
  }
}
</script>

<style lang="scss" scoped>
.op-select {
  max-width: 100px;
}
</style>
