import { Lookup } from '../lookup';

export type ActivityReviewStatus = 'Pending' | 'Approved' | 'RejectedManually' | 'RejectedWithPrimary' | 'RejectedByBadWordsFilter' | 'RejectedByOtherFilters'

export const ACTIVITY_REVIEW_STATUS_LOOKUPS: Array<Lookup<ActivityReviewStatus>> = [
  { id: 'Pending', name: 'Pending' },
  { id: 'Approved', name: 'Approved' },
  { id: 'RejectedManually', name: 'Rejected Manually' },
  { id: 'RejectedWithPrimary', name: 'Rejected with Primary' },
  { id: 'RejectedByBadWordsFilter', name: 'Rejected by Bad Words Filter' },
  { id: 'RejectedByOtherFilters', name: 'Rejected by Other Filters' }
];
