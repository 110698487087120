import { Claim } from '@/models/security';

export const PERFORMANCE_ROUTES = [
  {
    path: '/performance/content-insights',
    name: 'content-insights',
    component: () => import(/* webpackChunkName: "performance" */ '@/views/program-performance/AudienceContentPreference.vue'),
    meta: { auth: new Claim('accessMarketingPerformance') }
  },
  {
    path: '/performance/net-new-readers-orgs',
    name: 'net-new-readers-orgs',
    component: () => import(/* webpackChunkName: "performance" */ '@/views/program-performance/NetNewReport.vue'),
    meta: { auth: new Claim('accessMarketingPerformance') }
  },
  {
    path: '/performance/pipeline-impact',
    name: 'pipeline-impact',
    component: () => import(/* webpackChunkName: "performance" */ '@/views/program-performance/ImpactPipeLineReport.vue'),
    meta: { auth: new Claim('accessMarketingPerformance') }
  },
  {
    path: '/performance/comparative-content-tactics',
    name: 'comparative-content-tactics',
    component: () => import(/* webpackChunkName: "performance" */ '@/views/program-performance/ComparativeContentTactics.vue'),
    meta: { auth: new Claim('accessMarketingPerformance') }
  },
  {
    path: '/performance/tactic-activity/custom-newsletters',
    name: 'tactic-activity-custom-newsletters',
    component: () => import('@/views/program-performance/SpecificContentTacticsReport.vue'),
    meta: { auth: new Claim('accessMarketingPerformance') }
  },
  {
    path: '/performance/tactic-activity/webinar-events',
    name: 'tactic-activity-webinar-events',
    component: () => import('@/views/program-performance/TacticActivityWebinarEvents.vue'),
    meta: { auth: new Claim('accessMarketingPerformance') }
  }
];
