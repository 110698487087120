import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-content-between" }
const _hoisted_2 = { class: "line-height-1" }
const _hoisted_3 = { class: "flex pt-3" }
const _hoisted_4 = { class: "truncate-text-mostread" }
const _hoisted_5 = ["title", "href"]
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_cp_no_datatable_records = _resolveComponent("cp-no-datatable-records")!
  const _component_DataTable = _resolveComponent("DataTable", true)!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.title) + " ", 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_DataTable, {
        value: _ctx.data1,
        class: "w-full"
      }, {
        empty: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_cp_no_datatable_records)
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            field: "ContentTitle",
            header: "Content Title",
            style: { width: '35%' }
          }, {
            body: _withCtx((slotProps) => [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("a", {
                  target: "_blank",
                  title: slotProps.data.ContentTitle,
                  href: slotProps.data.DocumentUrl
                }, _toDisplayString(slotProps.data.ContentTitle), 9, _hoisted_5)
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            field: "Type",
            header: "Type",
            style: { width: '25%' }
          }),
          _createVNode(_component_Column, {
            field: "Stage",
            header: "Stage",
            style: { width: '15%' }
          }, {
            body: _withCtx((slotProps) => [
              _createElementVNode("img", {
                src: slotProps?.data.buyersJourneyBadge?.icon,
                width: "55"
              }, null, 8, _hoisted_6)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            field: "UniqueReaders",
            header: "Unique Readers",
            style: { width: '15%' }
          }),
          _createVNode(_component_Column, {
            field: "Engagements",
            header: "Engagements",
            style: { width: '10%' }
          })
        ]),
        _: 1
      }, 8, ["value"])
    ])
  ], 64))
}