<template>
  <div class="text-center">
    <img alt="No data"
      :src="require('../../assets/no-data-group.svg')">

    <div class="text-center no-results-header">
      No results found
    </div>
    <div v-if="showMessage"
      class="col-12 no-results-body">
      Try adjusting the filter to find what <br>
      you are looking for.
    </div>
  </div>
</template>
<script lang="ts">
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
@Options({})
export default class NoDataTableRecordsComponent extends Vue {
  @Prop({ default: true }) showMessage: boolean;
}
</script>

<style scoped>
.image-no-results {
  display: inline-block;
  vertical-align: top;
  margin-top: 60px;
}

.image-no-results > img {
  width: 50px;
}
.no-results {
  text-align: center;
}
.no-results-header {
  color: #76869a;
  font-size: 8px;
  text-align: center;
  font: normal normal normal 16px/18px Overpass;
  letter-spacing: 0px;
  opacity: 0.8;
}

.no-results-body {
  text-align: center;
  font: normal normal normal 14px/17px Overpass;
  letter-spacing: 0px;
  color: #76869a;
  opacity: 0.8;
}
</style>
