import { reactive } from '@vue/reactivity';
const errors = reactive({});

export default function useFormValidation() {
    const validateNameField = (fieldName, fieldValue, preferredFieldName) => {
        errors[fieldName] = fieldValue == ''  ? 'The ' + preferredFieldName + ' field is required' : '';
    };

    return { errors, validateNameField};
}

