import { FilterModel } from '@/models/search';
import {ActivityReportType, ActivityReportTypeString} from '../activityReportType';

export class ActivityReportDashboardDetails {
  id: string;
  userId: string;
  reportType: ActivityReportTypeString;
  aggregateSubsequent: boolean;
  filePath: string;
  sendDays?: string[];
  runOn: Date | string;
  filterModel: FilterModel;
  activityTypes: string[];
  activityReportConfigId: string;
  startDate?: Date;
  endDate?: Date;
  totalMatched: number;

  constructor(props: Partial<ActivityReportDashboardDetails>) {
    Object.assign(this, props);
    if (typeof props.runOn === 'string') {
      const a = /\/Date\((\d*)\)\//.exec(props.runOn);
      if (a) {
        this.runOn = new Date(+a[1]);
      }
    }
    
    this.activityTypes = this.activityTypes || [];
  }

  get reportTypeEnum(): ActivityReportType {
    return ActivityReportType[this.reportType];  
  }
  
  get reportTypeText(): string {
    return getIconTooltip(this.reportTypeEnum as ActivityReportType, this.aggregateSubsequent);
  }
}

const getIconTooltip = (reportType: ActivityReportType, agg: boolean) => {
  switch (reportType) {
    case ActivityReportType.Consolidated:
      return agg ? 'Aggregate Subsequent' : 'List Subsequent Individually';
    case ActivityReportType.Webhook:
      return 'Webhook';
    case ActivityReportType.Individual:
    default:
      return 'Individual';
  }
};
