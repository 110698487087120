import { startCase } from 'lodash';

export type FieldCode = 'ActivityDate' |
  'ActivityMonth' |
  'ActivityTypes' |
  'Address' |
  'AnalystTags' |
  'ApiSource' |
  'ApplicationType' |
  'AttendeeType' |
  'BuyersJourneyStage' |
  'CapitalRaisedPastThreeYears' |
  'City' |
  'ClientConsentStatus' |
  'Clients' |
  'CompanyDivisionName' |
  'CompanyDrugCount' |
  'CompanyType' |
  'ConsentToPrivacy' |
  'ConsentToPrivacyDate' |
  'Containment' |
  'ContentTopics' |
  'ContentType' |
  'ControlledSubstance' |
  'Country' |
  'DateRange' |
  'DesignationStatus' |
  'DesignationType' |
  'DevelopmentStages' |
  'DeviceApplications' |
  'DeviceApprovedTerritories' |
  'DeviceClass' |
  'DeviceEquipmentType' |
  'DeviceIndications' |
  'DeviceName' |
  'DeviceRegulatoryPath' |
  'DeviceStageOfDevelopment' |
  'DeviceTherapeuticArea' |
  'Documents' |
  'DocumentTitle' |
  'DocumentTypes' |
  'DosageForm' |
  'DosageSource' |
  'DrugDescriptor' |
  'DrugFormulation' |
  'DrugPipeline' |
  'DrugRelease' |
  'DrugType' |
  'Email' |
  'EntityType' |
  'ExpressionSystems' |
  'FirstName' |
  'FullName' |
  'GeneTherapyVector' |
  'HighestDevelopmentStage' |
  'JsProcessed' |
  'LastName' |
  'MediaSource' |
  'MoleculeType' |
  'Newsletter' |
  'NlConsentStatus' |
  'Onlines' |
  'OpenAccess' |
  'OrgBusinessGroups' |
  'OrgEmployees' |
  'OrgType' |
  'Packaging' |
  'PackagingSource' |
  'Personas' |
  'Phone' |
  'ProductCategory' |
  'ProductServiceOfferings' |
  'Quality' |
  'ReaderCompany' |
  'ReaderOrgs' |
  'ReaderOrgsLookup' |
  'Region' |
  'RelativeDateRange' |
  'Revenue' |
  'ReviewGeography' |
  'RouteOfAdministration' |
  'Sequence' |
  'Solubility' |
  'Specifics' |
  'SpecificsText' |
  'State' |
  'TargetAccounts' |
  'TherapeuticArea' |
  'Title' |
  'Zip' |
  'ProfileStatus' |
  'EligibilityDate' |
  'ActivityReviewStatus' |
  'ActivitySource' |
  'ContentTheme' |
  'NewsletterType' |
  'DocumentTypeName' |
  'EventId';

export function toLabel(name: FieldCode): string {
  return startCase(labelOverrides[name] || name);
}

const labelOverrides: Partial<Record<FieldCode, string>> = {
  ActivityTypes: 'Activity Type',
  BuyersJourneyStage: 'Buyer\'s Journey',
  ClientConsentStatus: 'Privacy Policy Consent Status',
  ReaderCompany: 'Reader Companies',
  ContentTopics: 'Content Theme',
  Newsletter: 'Newsletters',
  Onlines: 'Communities',
  ActivityReviewStatus: 'Filter By Status',
  EligibilityDate: 'Date Range'
};

export const RESTRICTED_FIELDS: FieldCode[] = ['AnalystTags'];
export const HIDDEN_FIELDS: FieldCode[] = ['ClientConsentStatus', 'NlConsentStatus'];
export const DEMOGRAPHIC_FIELDS: FieldCode[] = ['FirstName', 'LastName', 'Email', 'Title', 'Personas', 'City', 'State', 'Country', 'Region', 'ClientConsentStatus'];
export const FIRMOGRAPHIC_FIELDS: FieldCode[] = [
  'ReaderOrgs', 'ReaderCompany', 'Revenue', 'ProductCategory', 'OrgType', 'OrgEmployees',
  'DeviceTherapeuticArea', 'DeviceName', 'DeviceApplications', 'DeviceIndications', 'DeviceClass',
  'DeviceRegulatoryPath', 'DeviceStageOfDevelopment', 'DeviceEquipmentType',
  'DeviceApprovedTerritories', 'CompanyDivisionName', 'DevelopmentStages', 'TherapeuticArea', 'EntityType',
  'DrugType', 'DrugPipeline', 'ApplicationType', 'DesignationType', 'DesignationStatus',
  'ReviewGeography', 'DrugDescriptor', 'RouteOfAdministration', 'MoleculeType', 'HighestDevelopmentStage',
  'CapitalRaisedPastThreeYears', 'GeneTherapyVector', 'CompanyDrugCount', 'ExpressionSystems',
  'Containment', 'DrugRelease', 'ControlledSubstance', 'DrugFormulation', 'Packaging', 'Solubility', 'ApiSource',
  'DosageSource', 'PackagingSource', 'ReaderOrgsLookup'];
