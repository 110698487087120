import { TOAST_LIFETIME } from '@/models/common/constants';
import { ToastServiceMethods } from 'primevue/toastservice';

export function onSuccess(toast: ToastServiceMethods, message: string, closable: boolean=false) {
  toast.removeAllGroups();
  toast.add({
    severity: 'success',
    detail: message,
    life: closable ? 0 : TOAST_LIFETIME,
    closable: closable
  });
}

export function onError(toast: ToastServiceMethods, message: string) {
  toast.removeAllGroups();
  toast.add({
    severity: 'error',
    detail: message,
    life: TOAST_LIFETIME,
    closable: false
  });
}

export function onWarning(toast: ToastServiceMethods, message: string, closable: boolean=false) {
  toast.removeAllGroups();
  toast.add({
    severity: 'warn',
    detail: message,
    life: closable ? 0 : TOAST_LIFETIME,
    closable: closable
  });
}