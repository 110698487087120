import axios, { AxiosResponse } from 'axios';
import { savedSearchRow, SavedSearchRequest } from '@/models/saved-search/savedSearchModel';
import { PagedList } from '@/models/pagedList';

export default {
    async getAllForUser(userId: string, opts: any): Promise<PagedList<savedSearchRow>> {
        const qsArgs = {
            params: {
                page: opts.page,
                pageSize: opts.pageSize, sortBy: opts.sortBy, sortDir: opts.sortDir
            }
        };
        const response = await axios.get(`/api/saved-searches/${userId}`, qsArgs);

        const pagedList: PagedList<savedSearchRow> = {
            items: response.data.items,
            total: response.data.total,
            page: response.data.page,
            totalPages: response.data.pageSize,
        };
        return pagedList;
    },

    async getSearches(userId: string): Promise<savedSearchRow[]> {
        const response = await axios.get<savedSearchRow[]>(`/api/saved-searches/${userId}`);
        return response.data;
    },

    async getSavedSearch(userId: string, searchId: string | null): Promise<savedSearchRow> {
        const response = await axios.get<savedSearchRow>(`/api/saved-searches/${userId}/${searchId}`);
        return response.data;
    },

    async deleteSavedSearch(userId: string, searchId: string): Promise<boolean> {
        await axios.delete(`/api/saved-searches/${userId}/${searchId}`);
        return true;
    },

    async save(req: SavedSearchRequest): Promise<savedSearchRow> {
        let response: AxiosResponse<any, any>;
        if (req.id)
            response = await axios.patch(`/api/saved-searches/${req.id}`, req);
        else
            response = await axios.post(`/api/saved-searches`, req);

        return response.data;
    },

    async setDefaultSavedSearch(userId: string, searchId: any): Promise<boolean> {
        await axios.post(`/api/saved-searches/set-default/${userId}/${searchId}`);
        return true;
    },

    async removeDefaultSavedSearch(userId: string, searchId: any): Promise<boolean> {
        await axios.post(`/api/saved-searches/remove-default/${userId}/${searchId}`);
        return true;
    }
};
