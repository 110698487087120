import { BoolFilter, ExistsFilter, TermsFilter, TextFilter } from '@/models/filters';
import { PROFILE_STATUS_LOOKUPS, QUALITY_LOOKUPS, SEQUENCE_LOOKUPS } from '@/models/activity-dissemination';
import FilterLookupsSvc from '@/services/filterLookupsService';
import { SearchFieldMetaData } from '.';
import AnalystTagsSearchField from '@/components/searchFields/analystTagsSearchField.vue';

export const CONTEXTUAL_FIELD_METADATA: Array<SearchFieldMetaData | SearchFieldMetaData[]> = [
  {
    model: new TextFilter({ field: 'DocumentTitle' }),
    props: { multiValue: true }
  },
  {
    model: new TermsFilter({ field: 'DocumentTypes' }),
    props: { optionsGetter: FilterLookupsSvc.getDocumentTypes }
  },
  [
    {
      model: new TermsFilter({ field: 'Quality', allowNA: true }),
      props: { optionsGetter: () => Promise.resolve([...QUALITY_LOOKUPS]) }
    },
    {
      model: new TermsFilter({ field: 'Sequence', allowNA: true }),
      props: { optionsGetter: () => Promise.resolve([...SEQUENCE_LOOKUPS]) }
    }
  ],
  {
    model: new TermsFilter({ field: 'ContentTopics' }),
    props: { label: 'Content Theme', loadOptionsOnce: false, showOps: true, optionsGetter: q => FilterLookupsSvc.getCmsTags('ContentTopics', q) }
  },
  [{
    model: new TermsFilter({ field: 'BuyersJourneyStage' }),
    props: { label: 'Buyer\'s Journey', showOps: true, optionsGetter: () => FilterLookupsSvc.getCmsTags('BuyersJourneyStage') }
  },
  {
    model: new BoolFilter({ field: 'OpenAccess', allowNA: true }),
    props: { noSelectionLabel: 'Either' }
  }],
  [{
    model: new TermsFilter({ field: 'ContentType' }),
    props: { showOps: true, optionsGetter: q => FilterLookupsSvc.getCmsTags('ContentType', q) }
  },
  {
    model: new BoolFilter({ field: 'JsProcessed', allowNA: true })
  }],
  {
    type: AnalystTagsSearchField,
    model: new TermsFilter({ field: 'AnalystTags' }),
    props: { loadOptionsOnce: false, showOps: true, optionsGetter: q => FilterLookupsSvc.getCmsTags('AnalystTags', q) }
  },
  {
    model: new TermsFilter({ field: 'MediaSource', $type: 'MediaFilter' }),
    props: { optionsGetter: FilterLookupsSvc.getMediaSources }
  },
  {
    model: new TermsFilter({ field: 'Newsletter' }),
    props: { label: 'Newsletters', loadOptionsOnce: false, optionsGetter: FilterLookupsSvc.getNewsletters }
  },
  {
    model: new TermsFilter({ field: 'ProductServiceOfferings' }),
    props: { label: 'Product/Service Offerings', loadOptionsOnce: true, optionsGetter: FilterLookupsSvc.getServiceOfferings }
  },
  {
    model: new TermsFilter({ field: 'ProfileStatus', allowNA: true }),
    props: { optionsGetter: () => Promise.resolve([...PROFILE_STATUS_LOOKUPS]) }
  },
  {
    model: new ExistsFilter({ field: 'Specifics', value: null }),
    props: { label: 'Specifics', existsLabel: 'Only with specifics', notExistsLabel: 'Only without specifics' }
  },
  {
    model: new TextFilter({ field: 'SpecificsText', op: 'Like', restrictedToOps: ['Like', 'NotLike'] }),
    props: { label: 'Specifics Text', multiValue: true },
    displayDependency: {
      field: 'Specifics',
      values: [null, true]
    }
  }
];
