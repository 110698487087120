import { WorkflowStepEnum } from './../workflowStep';
import { NotificationBehaviorChangeRequest } from '.';
import { InsightType } from './insightType';
import { Notification } from '@/models/notification';

export class SeriesOfActivitiesNotificationBehaviorChangeRequest implements NotificationBehaviorChangeRequest {
  static fromNotification(n: Partial<Notification>): SeriesOfActivitiesNotificationBehaviorChangeRequest {
    const lastStepEnum =   n.lastStepCompleted && WorkflowStepEnum[n.lastStepCompleted] || WorkflowStepEnum.DefineFilters;
    return new SeriesOfActivitiesNotificationBehaviorChangeRequest(
      {
        aggField: n.aggField,
        activityMonths: n.activityMonths,
        uniqueUserCount: n.aggField === InsightType.ReaderOrg ? n.uniqueUserCount : undefined,
        minimumCount: n.minimumCount,
        lastStepCompleted: lastStepEnum < WorkflowStepEnum.BehaviorChange ? WorkflowStepEnum.BehaviorChange : lastStepEnum
      });
  }

  aggField: InsightType;
  activityMonths: number;
  uniqueUserCount?: number | undefined;
  minimumCount: number;
  lastStepCompleted?: WorkflowStepEnum;
  readonly $type: string = 'SeriesOfActivitiesNotificationBehaviorChangeRequest';

  constructor(opts: Partial<SeriesOfActivitiesNotificationBehaviorChangeRequest> = {}) {
    Object.assign(this, opts);
    this.$type = 'SeriesOfActivitiesNotificationBehaviorChangeRequest';
    this.uniqueUserCount = opts.aggField === InsightType.ReaderOrg ? opts.uniqueUserCount : undefined;
  }


}
