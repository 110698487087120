<template>
  <div class="flex col-12 justify-content-between">
    <h2 id="engagements-by-source">
      <Dropdown v-model="category" :options="categories" option-label="name" class="p-dropdown-noborder"
        @change="changeCategory($event.value)">
        <template #value="slotProps">
          <h2>{{ slotProps.value.name }}</h2>
        </template>
      </Dropdown>
      {{ showTitle }}
    </h2>
    <div class="pt-2">
      <cp-export-chart-options :chart="chartExport" @chart-updated="chartUpdated" />
    </div>
  </div>

  <div ref="chart" cp-color-picker class="flex align-items-center pt-3" style="min-height:300px;">
    <ProgressSpinner />
  </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import { Chart } from 'highcharts';
import { groupBy, flatten, isEqual } from 'lodash';
import {
    getColumnGradientPerBlockCustomNewsletter,
    getColumnGradientPerBlockNewsletter,
    getColumnGradientPerBlockNonNewsletter,
    getStackedBarChartXAxis,
    getOrderedDataByYear,
    StackedBarOptions,
    getStackedColumnChartConfig,
    getStackedColumnChartNoDataOptions,
    seriesHasData,
    getColumnGradientPerBlock,
    getColumnGradientPerBlockWebinar, getColumnGradientPerBlockEvent
} from '@/utils/highchartUtils';
import { IntervalBucket } from '@/models/intervalBucket';
import ClientDashboardService, { LeadSourceValue } from '@/services/clientDashboardService';
import ExportChartOptionsComponent from '../common/ExportChartOptions.vue';
import {dateFromRelativeDateRange, DateRangeFilter, RelativeDateRange, RelativeDateRangeFilter} from '@/models/filters';
import { filterValueExists, findFilter } from '@/utils/filters';
import { FilterModel } from '@/models/search';
import { Vue, Options } from 'vue-class-component';
import { Lookup } from '@/models/lookup';
import { renderColumnChart } from '@/plugins/highchart-extend';
import * as Highcharts from 'highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
NoDataToDisplay(Highcharts);

@Options({
  components: {
    'cp-export-chart-options': ExportChartOptionsComponent
  }
})
export default class EngagmentsBySource extends Vue {
  @Prop({ default: () => <FilterModel>{ filters: [], indices: [] } }) public filterModel: FilterModel;
  declare $refs: { chart: HTMLDivElement };
  chartExport: Chart | null = null;
  metrics: Array<IntervalBucket<LeadSourceValue>> = [];
  loading = true;
  series: any;
  showTitle: string = '';

  categories: Array<Lookup<any>> = [
    { id: 'all', name: 'All' },
    { id: 'unique', name: 'Unique' }
  ];
  category: Lookup<any> = this.categories[0];

  @Watch('filterModel', { immediate: true })
  async onFilterChange(filterModel: FilterModel, oldVal: FilterModel) {
    const noChanges = isEqual(filterModel, oldVal);
    if (!filterModel.filters?.length || !filterValueExists(filterModel.filters, 'Clients') || noChanges) { return; }
    this.loading = true;
    this.metrics = await ClientDashboardService.getActivitiesBySource(this.filterModel, (this.category.id === 'unique'));
    this.loading = false;
    this._renderChart();
  }

  async changeCategory() {
    this.loading = true;
    this.metrics = await ClientDashboardService.getActivitiesBySource(this.filterModel, (this.category.id === 'unique'));
    this.loading = false;
    this._renderChart();
  }

  get title() {
    this.showTitle = 'Engagements By Source';
    return this.showTitle;
  }

  private _renderChart() {
    const byYear = groupBy<IntervalBucket<LeadSourceValue>>(this.metrics, m => m.key.substring(0, 4));
    const dtFilter = findFilter<DateRangeFilter>(this.filterModel.filters, 'DateRange');
    const relativeDateRangeFilter = findFilter<RelativeDateRangeFilter>(this.filterModel.filters, 'RelativeDateRange');

    // Re-arrange the months to match the order of the filter's date range e.g. Jun, Jul, Aug, Sep, Oct, Nov, Dec, Jan, Feb, Mar, Apr, May
    const from = relativeDateRangeFilter?.value && relativeDateRangeFilter.value != RelativeDateRange.Custom
      ? dateFromRelativeDateRange(relativeDateRangeFilter.value)
      : dtFilter.value.from;
    const xAxisLegend: string[] = getStackedBarChartXAxis(from);

    // Re-arrange data to match the order of the filter's date range
    const orderedDataByYear: Record<string, any> = getOrderedDataByYear(byYear, xAxisLegend);
    this.series = this._getSeriesData(orderedDataByYear);

    const options: StackedBarOptions = {
      xAxisLegend,
      yAxisTitle: 'Number of Engagements',
      exportTitle: this.title,
      series: this.series
    };

    let hasData = seriesHasData(this.series);
    this.chartExport = (Highcharts as any).chart(
      this.$refs.chart,
      hasData ?
        getStackedColumnChartConfig(options) : getStackedColumnChartNoDataOptions(options), (chartInstance) => {
          if (!hasData) {
            renderColumnChart(chartInstance, getStackedColumnChartNoDataOptions(options).lang.noDataImage);
          }

        }
    );
  }

  private _getSeriesData(orderedDataByYear: Record<string, any>) {
    const hasWebinars = this.filterModel.indices.some(x => new RegExp('webinar').test(x.id));
    const hasEvents = this.filterModel.indices.some(x => new RegExp('event').test(x.id));
    const data = flatten(Object.keys(orderedDataByYear).map<any>((year, i) => [
      {
        name: `${year} Non-NL`,
        stack: year,
        color: getColumnGradientPerBlockNonNewsletter()[i],
        data: orderedDataByYear[year].map((m: any) => ({
          y: m.value.organic === 0 ? null : m.value.organic,
          name: m.key
        })),
      },
      {
        name: `${year} Custom Newsletter`,
        stack: year,
        color: getColumnGradientPerBlockCustomNewsletter()[i],
        data: orderedDataByYear[year].map((m: any) => ({
          y: m.value.customNewsletter === 0 ? null : m.value.customNewsletter,
          name: m.key
        })),
      },
      {
        name: `${year} Newsletter`,
        color: getColumnGradientPerBlockNewsletter()[i],
        stack: year,
        data: orderedDataByYear[year].map((m: any) => ({
          y: m.value.editorial === 0 ? null : m.value.editorial,
          name: m.key
        })),
      },
      {
        name: `${year} Lead Nurture Campaign Newsletters`,
        color: getColumnGradientPerBlock()[i],
        stack: year,
        data: orderedDataByYear[year].map((m: any) => ({
          y: m.value.leadNurtureCampaign === 0 ? null : m.value.leadNurtureCampaign,
          name: m.key
        })),
      }]
    ));

    if (hasWebinars) {
      Object.keys(orderedDataByYear).map<any>((year, i) => {
        data.push({
          name: `${year} Webinars`,
          stack: year,
          color: getColumnGradientPerBlockWebinar()[i],
          data: orderedDataByYear[year].map((m: any) => ({
            y: m.value.webinar === 0 ? null : m.value.webinar,
            name: m.key
          }))
        });
      });
    }

    if (hasEvents) {
      Object.keys(orderedDataByYear).map<any>((year, i) => {
        data.push({
          name: `${year} Events`,
          stack: year,
          color: getColumnGradientPerBlockEvent()[i],
          data: orderedDataByYear[year].map((m: any) => ({
            y: m.value.event === 0 ? null : m.value.event,
            name: m.key
          }))
        });
      });
    }

    return data;
  }

  chartUpdated(value: Chart) {
    this.chartExport = value;
    this.showTitle = (this.chartExport as any).options.exporting.chartOptions.title.text;
    this.$forceUpdate();
  }
}
</script>

<style scoped lang="scss"></style>
