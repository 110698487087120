import { Module, GetterTree, MutationTree } from 'vuex';
import axios, { AxiosError } from 'axios';
import { AuthState, JwtTokenUser } from '../models';
import { Claim } from '@/models/security/claim';
import { NOTIFICATION_MODULE } from './notification.module';
import { ACTIVITY_FIELDS_MODULE } from './activityFields.module';

declare let pendo: any;
declare let window: any;
declare let intercomAppId: any;
import dayjs from 'dayjs';

const TOKEN_KEY = 'cp-token';
const MONITOR_FREQUENCY = 1000 * 60;
export const AUTH_MUTATIONS = Object.freeze({
  INIT_FROM_TOKEN: 'initFromToken',
  CLEAR_STATE: 'clearState',
  SET_MONITOR_REF: 'setMonitorRef',
  SET_MESSAGE: 'setMessage',
  SET_ROUTE: 'setRoute'
});

export const AUTH_ACTIONS = Object.freeze({
  LOG_IN: 'logIn',
  LOG_OUT: 'logOut',
  MONITOR_TOKEN: 'monitorToken',
  RESET_TOKEN: 'resetToken'
});

function initFromLocalStorage(): JwtTokenUser | undefined {
  const token = localStorage.getItem(TOKEN_KEY);
  const hmac = localStorage.getItem('INTERCOM_HMAC');
  const user = token ? new JwtTokenUser(token, hmac?.toString()) : undefined;
  initPendo(user);
  initIntercom(user);
  return user;

}

export function initIntercom(user) {

  if (process.env.VUE_APP_INTERCOM_ENABLED !== 'true') { return; }

  setTimeout(() => {
    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: intercomAppId,
      name: user?.name,
      user_id: user?.userId,
      email: user?.email,
      client_ids: user?.companies?.map(c => c.id).join(','),
      created_at: dayjs().unix(),
      user_hash: user?.intercomHmac
    });
  }, 2000);
}


function initPendo(user: JwtTokenUser | undefined) {

  if (!user || process.env.VUE_APP_PENDO_ENABLED !== 'true') { return; }

  pendo.initialize({
    visitor: {
      id: user.userId  // Required if user is logged in
      // email:        // Recommended if using Pendo Feedback, or NPS Email
      // full_name:    // Recommended if using Pendo Feedback
      // role:         // Optional

      // You can add any additional visitor level key-values here,
      // as long as it's not one of the above reserved names.
    },

    account: {
      id: 'templeton' // Required if using Pendo Feedback
      // name:         // Optional
      // is_paying:    // Recommended if using Pendo Feedback
      // monthly_value:// Recommended if using Pendo Feedback
      // planLevel:    // Optional
      // planPrice:    // Optional
      // creationDate: // Optional

      // You can add any additional account level key-values here,
      // as long as it's not one of the above reserved names.
    }
  });
  // console.log('init pendo done');

}

const GETTERS: GetterTree<AuthState, any> = {
  satisfiesClaim: state => (claim: Claim) => {
    if (!claim) {
      return true;
    }
    const result = !state.user || state.user.isExpired ? false : state.user.satisfiesClaim(claim);
    // console.debug(`claim check: ${claim.name}:${result}" `);
    return result;
  },
  userRole: state => state.user ? state.user.role : null,
  userId: state => state.user ? state.user.userId : null,
  userEmail: state => state.user?.email,
  userName: state => state.user?.name,
  userCompanies: state => state.user ? state.user.companies : null,
  userActivitySearchFields: state => state.user ? state.user.activityFields : [],
  userAnalystTags: state => state.user ? state.user.analystTags : [],
  userOnlines: state => state.user ? state.user.communities : null,
  hasMinAdvancedNotificationClaim: state => state.user?.hasMinAdvancedNotificationClaim(),
  isMPSFeatureEnabled: state => state.user?.isMPSFeatureEnabled || false
};

const MUTATIONS: MutationTree<AuthState> = {
  [AUTH_MUTATIONS.SET_MESSAGE](state, msg?: string) {
    state.message = msg;
  },
  [AUTH_MUTATIONS.SET_ROUTE](state, route?: any) {
    state.route = route;
  },
  [AUTH_MUTATIONS.INIT_FROM_TOKEN](state, data: { rawToken: string, user: JwtTokenUser }) {
    localStorage.setItem(TOKEN_KEY, data.rawToken);
    state.rawToken = data.rawToken;
    state.user = data.user;
  },
  [AUTH_MUTATIONS.SET_MONITOR_REF](state, intervalRef: number) {
    state.monitorRef = intervalRef;
  },
  [AUTH_MUTATIONS.CLEAR_STATE](state, msg?: string) {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem('INTERCOM_HMAC');
    window.Intercom('shutdown');
    state.user = undefined;
    state.rawToken = undefined;
    initIntercom(state.user);
    state.message = msg;
    if (state.monitorRef) {
      console.debug('clearing monitor ref interval', state.monitorRef);
      clearInterval(state.monitorRef);
      state.monitorRef = undefined;
    }
  }
};


export const authModule: Module<AuthState, any> = {
  namespaced: true,
  state: {
    user: initFromLocalStorage(),
    monitorRef: undefined,
    message: undefined,
    rawToken: localStorage.getItem(TOKEN_KEY) || undefined
  },
  getters: GETTERS,
  mutations: MUTATIONS,
  actions: {
    [AUTH_ACTIONS.LOG_IN]({ commit, dispatch }, cred: { email: string, password: string }) {
      commit(AUTH_MUTATIONS.SET_MESSAGE); // clear the message

      return axios.post('/api/auth', cred).then(r => {
        const rawToken = r.data.token;
        const intercomHmac = r.data.hmac;
        const user = new JwtTokenUser(rawToken, intercomHmac);
        initPendo(user);
        window.Intercom('shutdown');
        initIntercom(user);
        commit(AUTH_MUTATIONS.INIT_FROM_TOKEN, { rawToken, user });
        dispatch(AUTH_ACTIONS.MONITOR_TOKEN);
        return true;
      }).catch((err: AxiosError) => {
        if (err.response && err.response.status === 401) {
          commit(AUTH_MUTATIONS.SET_MESSAGE, err.response.data.message);
        } else {
          commit(AUTH_MUTATIONS.SET_MESSAGE, 'We are currently experiencing problems with your account, please try again later.');
        }
        return Promise.resolve(false);
      });
    },
    [AUTH_ACTIONS.RESET_TOKEN]({ commit, dispatch }) {
      return axios.get('/api/reset-auth-token').then(r => {
        const rawToken = r.data.token;
        const intercomHmac = r.data.hmac;
        const user = new JwtTokenUser(rawToken, intercomHmac);
        commit(AUTH_MUTATIONS.INIT_FROM_TOKEN, { rawToken, user });
        if (user.satisfiesClaim(new Claim('viewNotifications'))) {
          dispatch(`notification/${NOTIFICATION_MODULE.GET_DASHBOARD_UNREAD_COUNT}`);
        }
        return true;
      });
    },
    [AUTH_ACTIONS.MONITOR_TOKEN]({ commit, dispatch, state }) {

      if (!state.user || state.user.isExpired) {
        return; // no need to poll the user if it is already expired.
      }

      dispatch(`activityFields/${ACTIVITY_FIELDS_MODULE.GET_FIELDS}`, null, { root: true });

      const monitorRef = setInterval(() => {
        if (!state.user) {
          return;
        }
        console.debug(`[${monitorRef}] session expires: ${state.user.expirationDate}`);
        if (!state.user.isExpired) {
          return;
        }

        console.warn('[Action: MONITOR_TOKEN] Session Expired ');
        commit(AUTH_MUTATIONS.CLEAR_STATE, 'Your session expired');
        // router.push('/login'); 
      }, MONITOR_FREQUENCY);

      commit(AUTH_MUTATIONS.SET_MONITOR_REF, monitorRef);
    }
  }
};
