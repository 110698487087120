import { Prop, Watch } from 'vue-property-decorator';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const TWEEN = require('@tweenjs/tween.js');
// can't get the tween tsd files working for some reason. So I have to declare it
// like this to compile;
// declare const TWEEN: any;
import { Vue, Options } from 'vue-class-component';
import { h } from 'vue';

@Options({
  name: 'cp-animated-integer',
  render() {
    const displayValue = this.percentage ? `${this.tweeningValue}%` : this.tweeningValue;
    return h('div', displayValue);
  }
})
export default class AnimatedIntegerComponent extends Vue {

  @Prop({ required: true, default: 0 }) value!: number;
  @Prop({ default: 1000 }) duration!: number;
  @Prop({ default: false }) percentage!: boolean;

  tweeningValue = '0';

  @Watch('value')
  valueChanged(newVal: number, oldVal: number) {
    if (newVal === oldVal) { return; }
    this._animate(oldVal, newVal);
  }

  mounted() {
    if (this.value > 0) {
      this._animate(0, this.value);
    }
  }

  private _animate(from: number, to: number) {
    // console.log(`calling animate [${from} - ${to}]`);
    new TWEEN.Tween({ tweeningValue: from })
      .to({ tweeningValue: to }, this.duration)
      .onUpdate((arg: any) => this.tweeningValue = Math.ceil(arg.tweeningValue).toLocaleString('en'))
      .start();

    (function animate() {
      if (TWEEN.update()) {
        requestAnimationFrame(animate);
      }
    })();
  }

}
