<template>
  <div id="printArea">
    <Panel v-for="e in engagements" :key="e.id" class="my-4 mx-5" style="page-break-after: always;break-after:always">
      <template #header>
        <h2>Engagement</h2>
      </template>
      <div class="flex fluid col-12">
        <div class="col-6">
          <div class="">
            Request From
          </div>
          <div class="font-medium">
            {{ e.portal }}
          </div>
        </div>
        <div class="col-6">
          <div class="p-column-title">
            Received Date
          </div>
          <div class="font-medium">
            {{ e.formattedDate }}
          </div>
        </div>
      </div>
      <Divider class="m-0" />
      <div class="flex fluid col-12">
        <div class="col-6">
          <div class="p-column-title">
            Target Company
          </div>
          <div class="font-medium">
            {{ e.advertiser }}
          </div>
        </div>
        <div class="col-6">
          <div class="p-column-title">
            Source
          </div>
          <div class="font-medium">
            {{ e.engagementSource }}
          </div>
        </div>
      </div>
      <Divider class="m-0" />
      <div class="flex fluid col-12">
        <div class="col-6">
          <div class="p-column-title">
            Name
          </div>
          <div class="font-medium">
            {{ (e.firstName || "") + " " + (e.lastName || "") }}
          </div>
        </div>
        <div class="col-6">
          <div class="p-column-title">
            Reader Company
          </div>
          <div class="font-medium">
            {{ e.company }}
          </div>
        </div>
      </div>
      <Divider class="m-0" />
      <div class="flex fluid col-12">
        <div class="col-6">
          <div class="p-column-title">
            Job Title
          </div>
          <div class="font-medium">
            {{ e.jobTitle }}
          </div>
        </div>
        <div class="col-6">
          <div class="p-column-title">
            Email
          </div>
          <div class="font-medium">
            {{ e.email }}
          </div>
        </div>
      </div>
      <Divider class="m-0" />
      <div class="flex fluid col-12">
        <div class="col-6">
          <div class="p-column-title">
            Phone
          </div>
          <div class="font-medium">
            {{ e.phone }}
          </div>
        </div>
        <div class="col-6">
          <div class="p-column-title">
            Address
          </div>
          <div class="font-medium">
            {{ (e.address1 || "") + ", " + (e.address2 || "") }}
          </div>
        </div>
      </div>
      <Divider class="m-0" />
      <div class="flex fluid col-12">
        <div class="col-3">
          <div class="p-column-title">
            City
          </div>
          <div class="font-medium">
            {{ e.city }}
          </div>
        </div>
        <div class="col-3">
          <div class="p-column-title">
            State
          </div>
          <div class="font-medium">
            {{ e.state }}
          </div>
        </div>
        <div class="col-3">
          <div class="p-column-title">
            Country
          </div>
          <div class="font-medium">
            {{ e.country }}
          </div>
        </div>
        <div class="col-3">
          <div class="p-column-title">
            Postal Code
          </div>
          <div class="font-medium">
            {{ e.zip }}
          </div>
        </div>
      </div>
      <Divider class="m-0" />
      <div class="flex fluid col-12">
        <div class="col-12">
          <div class="p-column-title">
            Document
          </div>
          <div class="font-medium">
            <a :href="e.portalUrl" target="_blank">{{ e.docTitle }}</a>
          </div>
        </div>
      </div>
      <Divider class="m-0" />
      <div class="flex fluid col-12">
        <div class="col-6">
          <div class="p-column-title">
            Document Type
          </div>
          <div class="font-medium">
            {{ e.docType }}
          </div>
        </div>
        <div class="col-6">
          <div class="p-column-title">
            Buyer's Journey
          </div>
          <div class="font-medium">
            {{ e.buyersJourneyName }}
          </div>
        </div>
      </div>
      <Divider v-if="e.specifics" class="m-0" />
      <div v-if="e.specifics" class="flex fluid col-12">
        <div class="col-12">
          <div class="p-column-title">
            Specifics
          </div>
          <div class="font-medium">
            <specifics-questions v-model="e.specificsModel" :show-title="false" :specifics-freetext="e.specifics" />
          </div>
        </div>
      </div>
    </Panel>
  </div>
</template>

<script lang="ts">
import { Watch } from 'vue-property-decorator';
import searchService from '../../services/searchService';
import { EngagementSearchDetails } from '@/models/search';
import { Vue, Options } from 'vue-class-component';
import SpecificsQuestions from '@/components/analytics/SpecificsQuestions.vue';

@Options({
  components: { SpecificsQuestions }
})
export default class Print extends Vue {
  public engagements: EngagementSearchDetails[] = [];

  @Watch('$route', { immediate: true })
  async routeChange() {
    if (this.$route.query['ids']) {
      const ids = (<string>this.$route.query['ids']).split(',');

      this.engagements = await searchService.getDetailsByQualifiedIds(ids);
    }
  }

  updated() {
    window.print();
  }

  mounted() {
    window.onbeforeprint = function () {
      document.body.setAttribute('style', 'padding-left:0px');

      var v = document.getElementsByClassName('sidebar')[0];
      v.className += ' hidden';
    };

    window.onafterprint = function () {
      document.body.setAttribute('style', 'padding-left:250px');

      var v = document.getElementsByClassName('sidebar')[0];
      v.className = 'sidebar open';
    };
  }
}
</script>

<style lang="scss">
.v-card.print-card {
  textarea {
    color: black !important;
  }

  .v-input,
  .v-textarea {
    input {
      padding-top: 15px;
      color: black;
    }

    .v-label {
      color: #4558a1;
    }

    .v-text-field__details {
      display: none;
    }
  }

  .card-header {
    .v-label {
      color: white;
    }

    .v-toolbar__content {
      padding-left: 10px;
      padding-top: 10px;
    }

    .v-input input {
      padding-top: 15px;
      color: white;
    }
  }
}

@media print {
  body * {
    visibility: hidden;
  }

  #printArea {
    margin: 10px;
  }

  #printArea * {
    visibility: visible;
    word-break: keep-all;
  }
  
  #printArea div:last-child {
    page-break-after: auto !important;;
    break-after: auto !important;;
}
}
</style>
<style lang="scss" scoped>

</style>
