<template>
  <div class="flex justify-content-between">
    <h2 :id="seriesField">
      {{ showTitle }}
    </h2>
    <div class="pt-2">
      <cp-export-chart-options :chart="chartExport" @chart-updated="chartUpdated" />
    </div>
  </div>

  <div ref="chart" v-cp-color-picker class="flex justify-items-center align-items-center">
    <ProgressSpinner />
  </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import { Chart } from 'highcharts';
import {
  getChartColor,
  getBarChartConfig,
  BarChartOptions,
  getBarChartNoDataOptions,
} from '@/utils/highchartUtils';
import { NamedSeries } from '@/models/namedSeries';
import ClientDashboardService from '@/services/clientDashboardService';
import ExportChartOptionsComponent from '../common/ExportChartOptions.vue';
import { filterValueExists } from '@/utils/filters';
import { isEqual } from 'lodash';
import { FilterModel } from '@/models/search';
import { Vue, Options } from 'vue-class-component';
import { renderBarChart, setRoundChartCornersBarChart } from '@/plugins/highchart-extend';
import * as Highcharts from 'highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { sanitizeHtmlTags } from '@/utils/htmlUtils';
import { SERIES_LABELS, SERIES_TO_FIELD } from './SERIES_LABELS';


NoDataToDisplay(Highcharts);

const CHART_HEIGHT_PX: number = 700;
const EMPTY_CHART_HEIGHT_PX: number = 200;

@Options({
  components: {
    'cp-export-chart-options': ExportChartOptionsComponent,
  }
})
export default class MostPopularDocs extends Vue {
  @Prop({ default: () => <FilterModel>{ filters: [], indices: [] } })
  public filterModel: FilterModel;
  // @Prop({ default: () => [] }) public filters: Filter[];
  @Prop() public title!: string;
  @Prop({
    default: 'buyersJourney',
    validator: (v: string) => !v || ['buyersJourney', 'contentType'].includes(v),
  })
  public seriesField!: string;
  public chartTitleClicked = false;

  @Prop({ default: 25 }) public size: number;

  declare $refs: { chart: HTMLDivElement };
  chartExport: Chart | null = null;
  metrics: NamedSeries[] = [];
  loading = true;
  series: any;
  showTitle: string;

  created() {
    this.showTitle = this.title;
  }
  @Watch('filterModel', { immediate: true })
  async onFilterChange(newVal: FilterModel, oldVal: FilterModel) {
    const noChanges = isEqual(newVal, oldVal);
    const hasClientValue = filterValueExists(newVal?.filters, 'Clients');
    const hasFilters = newVal.filters?.length > 0;
    if (!hasFilters || !hasClientValue || noChanges) {
      return;
    }

    this.loading = true;
    this.metrics = await ClientDashboardService.getMostPopularDocs(
      this.filterModel,
      this.size
    );

    this.loading = false;
    this._renderChart();
  }

  private _renderChart() {
    const labelsWithData = SERIES_LABELS[this.seriesField].filter((l: any) => this.metrics.some((d) => d.customFields[this.seriesField] === l.tag));
    const xAxisLegend: string[] = this.metrics.map(
      (m) =>
        '<a style="color:#1B3659" href=' +
        m.customFields['documentUrl'] +
        ' ' +
        ' target="_blank">' +
        sanitizeHtmlTags(m.name) +
        '</a>'
    );
    const yAxisTitle = 'Engagements';
    const xAxisTitle = 'Documents';

    this.series = this._getSeriesData(labelsWithData);
    // console.debug(`MostPopularDocs._renderChart() - series`, this.series);
    const chartHeight =
      this.series.length === 0 ? EMPTY_CHART_HEIGHT_PX : CHART_HEIGHT_PX;

    const options: BarChartOptions = <BarChartOptions>{
      CHART_HEIGHT_PX: chartHeight,
      xAxisLegend,
      xAxisTitle,
      yAxisTitle,
      exportTitle: this.title,
      series: this.series,
    };
    setRoundChartCornersBarChart();

    this.chartExport = (Highcharts as any).chart(
      this.$refs.chart,
      this.metrics.length > 0 ? getBarChartConfig(options) : getBarChartNoDataOptions(options),
      (chartInstance) => {
        // on complete
        if (this.metrics.length < 1) {
          renderBarChart(chartInstance, getBarChartNoDataOptions(options).lang.noDataImage);
        }
      }
    );
  }

  private _getSeriesData(labelsWithData) {
    return labelsWithData.map((l: any, index: number) => ({
      type: 'bar',
      name: l.name,
      color: getChartColor(SERIES_TO_FIELD[this.seriesField], index, l.name),
      data: this.metrics.map((d) => {
        const val = d.customFields[this.seriesField] === l.tag ? d.value : 0;
        return val;
      }),
    }));
  }

  chartUpdated(value: Chart) {
    this.chartExport = value;
    this.showTitle = (this.chartExport as any).options.exporting.chartOptions.title.text;
    this.$forceUpdate();
  }
}
</script>

<style scoped lang="scss">
.chart-label {
  text-align: left;
  font: normal normal bold 24px/30px Overpass;
  letter-spacing: 0px;
  color: #1b3557;
  opacity: 1;
}
</style>
