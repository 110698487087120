import { Question } from '@/models/user-service';
import commonLookupService from '@/services/commonLookupService';
import filterLookupsService from '@/services/filterLookupsService';
import { ref } from 'vue';
import { Online } from '@/models/lookup';

export default function useSearchLookups(){
  const orgQuestionsMap = ref<Record<number, Question>>({});
  const onlineBgMap = ref<Record<string, string>>({});
  const onlinesMap = ref<Online[]>([]);
  const dataReady = ref(false);

  const init = () => {
    new Promise((resolve) => {
      Promise.all([
        filterLookupsService.getOrgQuestionsMap(),
        commonLookupService.getOnlines(),
      ]).then(([orgMap, onlines]) => {
        orgQuestionsMap.value = orgMap;
        onlinesMap.value = onlines;
        onlineBgMap.value = onlines.reduce((prev, curr) => {
          prev[curr.id] = curr.businessGroup;
          return prev;
        }, <Record<string, string>>{});
        dataReady.value = true;
        resolve(true);
      });
    });
  };

  init();

  return{orgQuestionsMap, onlineBgMap, dataReady, onlinesMap};
}