import { GenericActionItem } from '@/models/common/actionItem';
import { NotificationType, typeToName } from '..';

export type NotificationTypeActionItem = GenericActionItem<NotificationType>

export const DEFAULT_NOTIFICATION_TYPE_ACTION: NotificationTypeActionItem = {
  type: 'SpecificActivityNotification',
  text: typeToName('SpecificActivityNotification'),
  description: `You define your ideal target, using in-depth demographic and firmographic filters. Each time there's an activity from anyone meeting your specified criteria, you'll be notified.`,
  active: true,
  icon: 'mdi-chart-scatter-plot',
  class: 'p-alerts-individual'
};

export const NOTIFICATION_TYPE_ACTIONS: NotificationTypeActionItem[] = [
  DEFAULT_NOTIFICATION_TYPE_ACTION,
  {
    type: 'SeriesOfActivitiesNotification',
    text: typeToName('SeriesOfActivitiesNotification'),
    description: `You're still defining your ideal target based on demographic and firmographic filters. 
    You'll only be notified when that target records a number of activities over a time period determined by you.`,
    icon: 'mdi-chart-bar',
    class: 'p-alerts-multiple'
  },
  {
    type: 'ChangeInActivitiesNotification',
    text: typeToName('ChangeInActivitiesNotification'),
    description: `Compare current activities by your ideal target to previous performance. You'll be notified when activities increase
     or decrease over a comparable time period based on the thresholds you set.`,
    icon: 'mdi-chart-gantt',
    class: 'p-alerts-changein'
  }
];
