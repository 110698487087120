<template>
  <div class="col-5 p-1">
    <Message :closable="false">
      Tell us about your program
    </Message>
  </div>
  <div class="grid w-full mt-2">
    <div class="col-12 lg:col-4 p-3">
      <span class="p-float-label p-fluid">
        <ValidatedInputText :id="'name'" v-model="data1.name" :field-name="'name'" :display-label="'Name'"
          :preferred-field-name="'Name'" />
      </span>
    </div>
    <div class="col-12 lg:col-4 p-3">
      <span class="p-float-label p-fluid">
        <!--         <InputText id="email" type="email" v-model="data1.email"  :class="{'p-invalid': validationErrors['email'] && submitted}" />
        <label for="email">Email address</label>
          <small v-show="validationErrors.email && submitted" class="p-error">Email is required.</small>-->
        <ValidatedInputText :id="'name'" v-model="data1.email" :field-name="'email'" :display-label="'Email address'"
          :preferred-field-name="'Email'" />
      </span>
    </div>
    <div class="col-12 lg:col-4 p-3">
      <span class="p-float-label p-fluid">
        <InputText id="phoneNumber" v-model="data1.phone" type="text" />
        <label for="phoneNumber">Phone Number</label>
      </span>
    </div>

    <div class="col-12 lg:col-6 p-3">
      <span class="p-float-label p-fluid">
        <AutoComplete id="client" v-model="selectedOnlines" :suggestions="filteredOnlines" field="name" :multiple="true"
          @complete="searchOnline($event)" @item-select="updateValue($event.value)"
          @item-unselect="removeValue($event.value)" />
        <label for="client">Community</label>
        <small v-show="selectedOnlines.length === 0 && data1.length > 0" class="p-error">At least one Community is
          required</small>
      </span>
    </div>
    <div class="col-12 lg:col-6 p-3">
      <span class="p-float-label p-fluid">
        <InputText id="accountManager" v-model="data1.accountManager" type="text" />
        <label for="accountManager">Account Manager</label>
      </span>
    </div>
  </div>
  <br><br>
  <div class="flex col-12 description">
    <div class="col-7">
      <label>Are you a new client or is this the renewal of your program with us?</label>
    </div>
    <div v-for="category of categoriesClient" :key="category.key">
      <div :id="category.key" class="p-0">
        <RadioButton :id="category.key" v-model="selectedCategoryClient" name="category" class="mr-2" :value="category"
          :class="{ 'ml-4': category.key === 'renewal' }" />
        <label :for="category.key">{{ category.name }}</label>
      </div>
    </div>
  </div>

  <div class="flex col-12 description">
    <div class="col-7">
      <label>Have you purchased access to multiple audiences (publications) with this
        contract?</label>
    </div>
    <div v-for="category of categoriesOnlines" :key="category.key">
      <div class="mr-5">
        <RadioButton :id="category.key" v-model="selectedCategoryOnlines" name="category" :value="category"
          class="mr-2" />
        <label :for="category.key">{{ category.name }}</label>
      </div>
    </div>
  </div>


  <div class="flex col-12 description">
    <div class="col-7">
      <label>If “yes,” would you like to promote the same content to each audience?</label>
    </div>
    <div v-for="category of categoriesContentPerSite" :key="category.key">
      <div class="mr-5">
        <RadioButton :id="category.key" v-model="selectedCategoryContent" class="mr-2" name="category"
          :value="category" />
        <label :for="category.key">{{ category.name }}</label>
      </div>
    </div>
  </div>
  <div class="mb-2 flex flex-row-reverse">
    <Button v-if="step !== 5" ref="goForward" label="Save and Continue" type="button"
      class="px-4 p-button-success p-button-raised" @click="nextStep" />
  </div>
</template>



<script lang="ts">
import { reactive, ref, onMounted } from 'vue';
import axios from 'axios';
import { Lookup, Online } from '@/models/lookup';
import commonLookupService from '@/services/commonLookupService';
import useFormValidation from '@/utils/validators/useFormValidation';
import useValidationErrors from '@/utils/validators/useValidationErrors';
import ValidatedInputText from '@/components/common/ValidatedInputText.vue';
import { OnboardingWorkflowStep } from '@/models/onboarding';
import { DEFAULT_TIMEOUT_TIME } from '@/models/common/constants';


export interface GeneralInfo {
  id: string;
  companyId: number;
  name: string;
  email: string;
  phone: string;
  accountManager: string;
  onlines: string[];
  isNewClient: boolean;
  purchasedMultipleOnlines: boolean;
  sameContentPerSite: boolean;
  isExpired: boolean;
}

export interface GeneralInfoResponse {
  availableOnlines: Array<Lookup<string>>;
  info: GeneralInfo;
}

export default {
  components: {
    ValidatedInputText
  },
  props: {
    formId: { type: String, default: '' },
  },
  emits: ['update:modelValue', 'step-completed', 'next-page'],
  setup(props, { emit }) {

    let onlines = ref();
    const availableOnlines = ref([]) as any;
    const selectedOnlines = ref([]) as any;
    const filteredOnlines = ref();
    const generalInfo = ref([]);
    const data1 = reactive(generalInfo);
    let validationErrors = ref({});
    let submitted = ref(false);

    const categoriesClient = ref([
      { name: 'New', key: 'new' },
      { name: 'Renewal', key: 'renewal' },

    ]);
    const categoriesOnlines = ref([
      { name: 'Yes', key: 'yesOnline' },
      { name: 'No', key: 'noOnline' },

    ]);

    const categoriesContentPerSite = ref([
      { name: 'Yes', key: 'yesContent' },
      { name: 'No', key: 'noContent' },

    ]);

    let selectedCategoryClient = ref();
    let selectedCategoryOnlines = ref();
    let selectedCategoryContent = ref();



    onMounted(async () => {

      commonLookupService.getOnlines().then((data) => {
        onlines.value = data;
      });

      const url = `/api/onboarding/${props.formId}/general-info`;
      const response = (await axios.get<GeneralInfoResponse>(url)).data;
      data1.value = response.info as any;
      availableOnlines.value = response.info.onlines as any;

      selectedOnlines.value = onlines.value.filter((online: Online) => availableOnlines.value.includes(online.id));


      selectedCategoryClient.value = data1.value['isNewClient'] ? ref(categoriesClient.value[0]).value : ref(categoriesClient.value[1]).value;
      selectedCategoryOnlines.value = data1.value['purchasedMultipleOnlines'] ? ref(categoriesOnlines.value[0]).value : ref(categoriesOnlines.value[1]).value;
      selectedCategoryContent.value = data1.value['sameContentPerSite'] ? ref(categoriesContentPerSite.value[0]).value : ref(categoriesContentPerSite.value[1]).value;

    });

    const hasErrors = () => {
      const { errors } = useFormValidation();
      return useValidationErrors(data1.value, errors);
    };

    const nextStep = () => {
      submitted.value = true;
      if (!hasErrors()) {
        save();
        emit('next-page', { pageIndex: 1 });
        emit('step-completed', OnboardingWorkflowStep.General);
      }

    };

    const searchOnline = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredOnlines.value = [...onlines.value];
        }
        else {
          filteredOnlines.value = onlines.value.filter((online) => online.name.toLowerCase().startsWith(event.query.toLowerCase()));
        }
      }, DEFAULT_TIMEOUT_TIME);
    };

    const updateValue = () => {

      emit('update:modelValue', selectedOnlines);

    };

    const removeValue = () => {
      emit('update:modelValue', selectedOnlines);
    };



    const save = async () => {
      try {
        const url = `/api/onboarding/${props.formId}/general-info`;
        data1.value['onlines'] = [];
        selectedOnlines.value.map((online) => {
          data1.value['onlines'].push(online['id']);
        });
        await axios.patch<GeneralInfo>(url, data1.value);

      } catch (error) {
        //this.$store.commit(`msg/${MSG_MUTATIONS.MSG}`, { text: 'There was an error saving this form', type: 'error' });
        // NOTE: Error thrown to be detected by Vue.config.errorHandler
        console.log(error);
        throw (error);
      }
    };

    return {
      data1, onlines, categoriesClient, categoriesOnlines, selectedOnlines, availableOnlines, filteredOnlines,
      categoriesContentPerSite, selectedCategoryClient, selectedCategoryOnlines, updateValue, removeValue,
      selectedCategoryContent, save, nextStep, submitted, validationErrors, searchOnline, hasErrors
    };
  }

};

/*import { Component, Prop, Watch } from 'vue-property-decorator';
import axios from 'axios';
import { required } from '@/utils/validators/validators';
import { Lookup } from '@/models/lookup';
import { MSG_MUTATIONS } from '@/store/modules/msg.module';
import { Vue, Options } from 'vue-class-component';

interface GeneralInfo {
  id: string; companyId: number;
  name: string; email: string; phone: string; accountManager: string;
  onlines: string[];
  isNewClient: boolean; purchasedMultipleOnlines: boolean; sameContentPerSite: boolean;
  isExpired: boolean;
}

interface GeneralInfoResponse { availableOnlines: Array<Lookup<string>>; info: GeneralInfo; }

@Options({})
export default class GeneralInfoStep extends Vue {
  @Prop() formId!: string;

  data1: GeneralInfo | null = null;
  onlines: Array<Lookup<string>> = [];
  rules = {
    name: [required('Contact Name')],
    email: [required('Email')],
    onlines: [required('Websites')]
  };

  declare $refs: { form: HTMLFormElement };

  formIsValid() {
    return this.$refs.form && this.$refs.form.validate();
  }

  @Watch('formId', { immediate: true })
  async formIdChanged() {
    try {
      const url = `/api/onboarding/${this.formId}/general-info`;
      const response = (await axios.get<GeneralInfoResponse>(url)).data;
      this.data1 = response.info;
      this.onlines = response.availableOnlines;
    } catch (err) {
      this.$store.commit(`msg/${MSG_MUTATIONS.MSG}`, { text: 'There was an error loading this form', type: 'error' });
      // NOTE: Error thrown to be detected by Vue.config.errorHandler
      throw (err);
    }
  }

  async save() {
    if (!this.$refs.form.validate()) { return; }
    try {
      const url = `/api/onboarding/${this.formId}/general-info`;
      const response = await axios.patch<GeneralInfo>(url, this.data1);
      this.$emit('stepCompleted', response.data);
    } catch (error) {
      this.$store.commit(`msg/${MSG_MUTATIONS.MSG}`, { text: 'There was an error saving this form', type: 'error' });
      // NOTE: Error thrown to be detected by Vue.config.errorHandler
      throw (error);
    }
  }
}*/
</script>

<style lang="scss" scoped>

</style>
