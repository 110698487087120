<template>
  <div class="jspdf c-text-base" style="width: 550px;">
    <div class="flex justify-content-between align-items-center">
      <div>
        <div>
          <img :src="require('../../assets/Templeton_logo.png')" alt="Templeton Logo" class="h-3rem">
        </div>
        <div class="p-primary-color c-text-lg font-extrabold">
          {{ activityTypeName }} from {{ activity.portal }}
        </div>
      </div>
    </div>

    <div class="card mt-3">
      <div class="p-panel p-component">
        <div class="p-panel-header">
          <span class="c-text-base font-bold p-primary-color">Reader Profile</span>
        </div>
        <div class="flex c-text-base my-2 px-3 pb-0">
          <div class="right-border col-5 p-0 pl-2 ">
            <div v-if="activity.consentToPrivacyDate" class="flex align-items-center mb-2">
              {{ `Privacy Consent: ${clientConsentDate}` }}
            </div>
            <div class="flex align-items-center c-text-xl font-bold capitalize mb-2">
              <img class="mr-2" src="../../assets/account_circle.png" width="20">
              {{ (activity.firstName || "") + " " + (activity.lastName || "") }}
            </div>
            <div class="flex align-items-center font-normal capitalize font-italic">
              {{ activity.jobTitle }}
            </div>
            <div class="flex align-items-center font-bold capitalize">
              {{ activity.company }}
            </div>
          </div>
          <div class="col-7 pl-5 p-0">
            <div class="flex align-items-center">
              <img class="mr-2" src="../../assets/email.png" width="10">
              <a :href="`mailto:${activity.email}`" class="no-underline relative">
                <span class="c-underline" />
                {{ activity.email }}
              </a>
            </div>
            <div class="flex align-items-center mt-2 capitalize">
              <img class="mr-2" src="../../assets/place.png" width="10">
              {{ activity?.formattedAddr }}
            </div>
            <div class="flex align-items-center mt-2">
              <img class="mr-2" src="../../assets/phone.png" width="10">
              {{ activity?.phone }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="activity?.specificsModel || activity?.specifics" class="card mt-4" style="page-break-after: always;">
      <div class="p-panel p-component">
        <div class="p-panel-header">
          <span class="c-text-base font-bold p-primary-color capitalize">{{ activityTypeName }} Notes</span>
        </div>
        <div class="flex c-text-base px-4 pt-2">
          {{ activityDate }}
        </div>
        <div class="flex c-text-base px-3 pb-2">
          <specifics-questions v-model="activityRef.specificsModel" :specifics-freetext="activity?.specifics"
            :show-title="false" />
        </div>
      </div>
    </div>

    <div v-if="activity.docId" class="card mt-4">
      <div class="p-panel p-component">
        <div class="p-panel-header">
          <span class="c-text-base font-bold p-primary-color">Content Consumed</span>
        </div>
        <!--  NOTE: As a result of some inexplicable limitations in the html2canvas library used with jspdf         
              links only work when the text matches the url, so the doc title cannot serve as the link
        -->
        <div class="flex c-text-base px-3 pb-2 pt-2">
          {{ activity.docTitle }}
        </div>
        <div class="flex c-text-base px-3 pb-2">
          <a :href="activity.portalUrl" :title="activity.docTitle" target="_blank" class="no-underline relative">
            <span class="c-underline" />
            {{ activity.portalUrl }}
          </a>
        </div>
      </div>
    </div>

    <div v-if="activity.otherActivitiesForReaderCount || activity.otherReadersCount" class="card mt-4">
      <div class="p-panel p-component">
        <div class="p-panel-header">
          <span class="c-text-base font-bold p-primary-color">Related insights</span>
        </div>
        <div class="flex c-text-base px-3 pb-2">
          <ul>
            <li v-if="activity.otherActivitiesForReaderCount" class="pt-2">
              {{ activity.firstName }} has {{ activity.otherActivitiesForReaderCount }} other activities recorded with
              your content in the last 6 months.
            </li>
            <li v-if="activity.otherReadersCount" class="pt-2">
              {{ activity.otherReadersCount }} other reader(s) from {{ activity.company }} also consumed your content in
              the past 6 months.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="card mt-4">
      <div class="p-panel p-component">
        <div class="p-panel-header">
          <span class="c-text-base font-bold p-primary-color">General Info</span>
        </div>
        <div class="flex c-text-base px-3">
          <div class="col-6">
            <div class="flex align-items-center">
              Reader Company
            </div>
            <div class="flex align-items-center font-bold">
              {{ activity.company }}
            </div>
          </div>
          <div class="col">
            <div class="flex align-items-center">
              Document Type
            </div>
            <div class="flex align-items-center font-bold">
              {{ activity.docType || "N/A" }}
            </div>
          </div>
        </div>
        <div class="flex c-text-base px-3">
          <div class="col-6">
            <div class="flex align-items-center">
              Activity Type
            </div>
            <div class="flex align-items-center font-bold">
              {{ activityTypeName }}
            </div>
          </div>
          <div class="col">
            <div class="flex align-items-center">
              Client
            </div>
            <div class="flex align-items-center font-bold">
              {{ activity.advertiser }}
            </div>
          </div>
        </div>
        <div class="flex c-text-base px-3">
          <div class="col-6">
            <div class="flex align-items-center">
              Newsletter
            </div>
            <div class="flex align-items-center font-bold">
              {{ activity.newsletterName || "N/A" }}
            </div>
          </div>
          <div class="col">
            <div class="flex align-items-center">
              Quality
            </div>
            <div class="flex align-items-center">
              <img :src="activity.qualityBadge?.icon" :alt="activity.quality" height="15">
            </div>
          </div>
          <div class="col">
            <div class="flex align-items-center">
              Buyer's Journey
            </div>
            <div class="flex align-items-center">
              <img :src="activity.buyersJourneyBadge?.icon" :alt="activity.buyersJourneyName" height="15">
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="mt-4 pl-1">
      <b>Need help?</b> Email <a :href="`mailto:${supportEmail}`" class="font-bold no-underline relative">
        <span class="c-underline" />
        {{ supportEmail }}</a>, visit
      <a :href="kbLink" target="_blank" class="font-bold no-underline relative">
        <span class="c-underline" />
        {{ kbLink }}</a>
      or contact your representative.
    </div>
  </div>
</template>

<script lang="ts">
import { IndividualActivitySearchDetails } from '@/models/search';
import dayjs from 'dayjs';
import SpecificsQuestions from '@/components/analytics/SpecificsQuestions.vue';
import { ref } from 'vue';
import { ACTIVITY_TYPE_LOOKUPS } from '@/models/analytics/activityTypes';

export default {
  components: { SpecificsQuestions },
  props: {
    activity: { type: IndividualActivitySearchDetails, required: true }
  },
  setup(props) {
    const activityRef = ref<IndividualActivitySearchDetails>(props.activity);
    const clientConsentDate = props.activity.consentToPrivacyDate
      ? dayjs(props.activity.consentToPrivacyDate).format('YYYY-MM-DD')
      : 'No Consent';
    const activityTypeName = ACTIVITY_TYPE_LOOKUPS.find(x => x.id === props.activity.activityType)?.name;
    const activityDate = dayjs(props.activity.date).format('MM/DD/YYYY');
    const supportEmail = process.env.VUE_APP_SUPPORT_EMAIL;
    const kbLink = process.env.VUE_APP_KB_URL;

    return {
      activityRef,
      clientConsentDate,
      activityDate,
      activityTypeName,
      supportEmail,
      kbLink
    };
  }
};
</script>

<style lang="scss" scoped>
.jspdf {
  font-family: 'Overpass' !important;
  color: #586A83;
}

.font-extrabold {
  font-weight: 800;
}

.c-text-base {
  font-size: 9px !important;
}

.c-text-sm {
  font-size: 8px !important;
}

.c-text-xs {
  font-size: 7px !important;
}

.c-text-lg {
  font-size: 10px !important;
}

.c-text-xl {
  font-size: 12px !important;
}

.p-panel {
  border-radius: 10px;
  box-shadow: none !important;
  border: 1px dashed #d3d8e6;
}

.p-panel-header {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  padding: 0.5rem 1rem !important;
}

.right-border {
  border-right: 1px solid #d3d8e6;
}

.top-border {
  border-top: 1px solid #d3d8e6;
}

.c-underline {
  position: absolute;
  bottom: -.5px;
  left: 0;
  width: 100%;
  height: .5px;
  background-color: #586A83;
}

.help {
  margin-top: 100px;
}
</style>
