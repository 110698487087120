import { UserRole } from '@/models/userRole';
import { COLORS_BY_NAME } from './colorUtils';

export function getRoleColor(role: UserRole, isRegistered: boolean = true) {
  if (!isRegistered) {
    return `linear-gradient:(180deg, ${COLORS_BY_NAME['Yellow']} 0%, ${COLORS_BY_NAME['Yellow_Gradient']} 100%)`;
  }
  switch (role) {
    case 'Internal':
      return `linear-gradient:(180deg, ${COLORS_BY_NAME['LightBlue']} 0%, ${COLORS_BY_NAME['LightBlue_Gradient']} 100%)`;
    case 'Admin':
      // return 'green';
      return `linear-gradient:(180deg, ${COLORS_BY_NAME['LightGreen']} 0%, ${COLORS_BY_NAME['LightGreen_Gradient']} 100%)`;
    case 'SystemAdmin':
      return `linear-gradient:(180deg, ${COLORS_BY_NAME['Orange']} 0%, ${COLORS_BY_NAME['Orange_Gradient']} 100%)`;
    case 'Client':
    default:
      return `linear-gradient:(180deg,, ${COLORS_BY_NAME['Gray']} 0%, ${COLORS_BY_NAME['Gray_Gradient']} 100%)`;

  }
}

export function getRoleTextColor(role: UserRole, isRegistered: boolean = true) {
  const color = (role === 'Client' && isRegistered) ? '#ffffff' : '#1b3557';
  return color;
}

export function getAvatarColorClass(role: UserRole, isRegistered: boolean = true) {
  const color = (!isRegistered) ? 'color-yellow' :
    (role === 'SystemAdmin') ? 'color-orange' :
      (role === 'Admin') ? 'color-green' :
        (role === 'Internal') ? 'color-blue' :
          'color-gray';
  return color;
}


export function getInitials(nameParts: string[]) {
  // console.log(`userUtils.getInitials() - { nameParts[0], nameParts[1], nameParts}`, nameParts[0], nameParts[1], nameParts);
  const nonEmptyNameParts = nameParts.filter(n => n);
  return `${nonEmptyNameParts[0].trim()[0]}${nonEmptyNameParts[1].trim()[0]}`.toUpperCase();
}
