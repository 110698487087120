<template v-if="chart">
  <i class="pi pi-ellipsis-v pl-2 cursor-pointer"
    aria-haspopup="true"
    aria-controls="overlay_menu"
    @click="toggleMenu" />

  <Menu ref="menu"
    :model="graphOptions"
    :popup="true"
    style="width:15%;" />
  <cp-change-chart-color :dialog-model="showModal"
    :chart="chart"
    :series="colorPickerSeries"
    @modal-closed="modalClosed" />
  <!-- <cp-rename-chart :dialogModel="showChartName" :chart="chart" :series="colorPickerSeries" @modalClosed="modalClosed"
    @chartUpdated="chartUpdated">
  </cp-rename-chart> -->

  <div class="bg-white">
    <Dialog id="chartColorDialog"
      v-model:visible="showChartName"
      :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
      :style="{ width: '40vw' }"
      :closable="false"
      :modal="true">
      <template #header>
        <div class="flex col-12 pb-0">
          <div class="col-11 pb-0">
            <h3 class="p-secondary-color">
              Rename chart
            </h3>
          </div>
          <div class="col-1 absolute right-0 pb-0 p-secondary-color">
            <i class="pi pi-times reverse p-secondary-color"
              aria-haspopup="true"
              aria-controls="overlay_menu"
              @click="dialogClose" />
          </div>
        </div>
      </template>
      <div class="col-11 pb-2 pt-0">
        <Message :closable="false">
          Edit the text below, to rename the chart. Click "Apply" to
          activate your changes
        </Message>
      </div>
      <div class="flex col-12">
        <div class="col-10 field">
          <span class="p-float-label p-fluid">
            <InputText id="chartTitle"
              v-model="chart.options.exporting.chartOptions.title.text"
              type="text"
              maxlength="70" />
            <label for="chartTitle">Chart Title</label>
          </span>
        </div>
      </div>
      <div class="flex col-12 pb-0">
        <div class="col-9 pb-0">
          <Button type="button"
            class="p-button-outlined p-button-rounded"
            label="Cancel"
            style="float:right"
            @click="dialogClose()" />
        </div>
        <div class="col-3 pb-0">
          <Button type="button"
            class="p-button-outlined p-button-rounded"
            label="Apply"
            @click="changeChartName()" />
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { exportChart } from '@/utils/highchartUtils';
import ChangeChartColorComponent from '@/components/common/ChangeChartColor.vue';
import { Chart, Point, Series } from 'highcharts';
import { Prop } from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component';

@Options({
  name: 'cp-export-chart-options',
  components: {
    'cp-change-chart-color': ChangeChartColorComponent
  },
})
export default class ExportChartOptionsComponent extends Vue {
  @Prop() public chart: Chart;
  //colorPicker: ChangeChartColorComponent = new ChangeChartColorComponent();
  showModal: boolean = false;
  showChartName: boolean = false;
  graphOptions: any[] = [];

  created() {
    this.graphOptions = [
      {
        label: 'Download as SVG',
        icon: 'pi pi-download',
        command: () => this.exportChartAsSVG(),
      },
      {
        label: 'Download as PNG',
        icon: 'pi pi-download',
        command: () => this.exportChartAsPNG(),
      },
      {
        label: 'Configure Chart Colors',
        icon: 'pi pi-cog',
        command: () => this.showColor(),
      },
      {
        label: 'Rename Chart',
        icon: 'pi pi-cog',
        command: () => this.renameChart(),
      },
    ];
  }

  dialogClose() {
    this.$emit('modalClosed', false);
    this.showChartName = false;
  }

  changeChartName() {
    this.$emit('chartUpdated', this.chart);
    this.showChartName = false;
  }

  renameChart() {
    this.showChartName = true;
  }

  showColor() {
    let html = document.getElementsByTagName('html')[0];
    html.classList.add('overflow-hidden');
    this.showModal = true;
  }
  exportChartAsSVG() {
    exportChart(this.chart, 'SVG');
  }

  exportChartAsPNG() {
    exportChart(this.chart, 'PNG');
  }

  modalClosed(value: boolean) {
    let html = document.getElementsByTagName('html')[0];
    html.classList.remove('overflow-hidden');
    this.showModal = value;
    this.showChartName = value;
  }

  get colorPickerSeries(): Series[] | Point[] {
    return ((this.chart?.userOptions?.chart?.type !== 'pie') ?
      this.chart?.series : this.chart?.series[0].data) ?? [];
  }

  toggleMenu(event) {
    (this.$refs.menu as any).toggle(event);
  }
}
</script>

<style scoped lang="scss">
.v-btn>.v-btn__content .v-icon:hover {
  color: "#E7D797" !important;
}
</style>
