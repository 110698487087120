<template>
  <div class="flex pb-2 w-full mt-4">
    <h4 class="my-2 p-panel-title col-12">
      Activity Report Filters
    </h4>
  </div>

  <div ref="general" class="grid pb-4 w-full card-active">
    <div class="col-12">
      <Card class="p-card-default-filters">
        <template #title>
          <div class="px-3 pt-1">
            General
          </div>
        </template>
        <template #content>
          <div class="grid px-3 pb-0">
            <div v-if="includeIndices" class="col-12 p-fluid pb-3">
              <span class="p-float-label">
                <AutoComplete id="activityType" v-model="selectedIndices" :multiple="true"
                  :class="{ 'p-invalid': v$.selectedIndices?.$invalid && submitted }" :suggestions="filteredIndices"
                  field="name" :dropdown="true" :complete-on-focus="true" @complete="searchActivityType($event)"
                  @focus="searchActivityType($event)" />
                <label for="activityType">Activity Type</label>
              </span>
              <small v-if="v$.selectedIndices?.$invalid && submitted" class="p-error">
                {{ v$.selectedIndices.required.$message.replace("Value", "Activity Type") }}
              </small>
            </div>
            <div v-for="row in generalFields" :key="row.items[0].props?.label" class="col-12 p-fluid pb-3">
              <div v-for="f in displayableItems(row.items)" :key="f.props?.label">
                <cp-compound-search-field-elements v-if="f.props?.isCompound" :search-field="f" :submitted="submitted"
                  :is-user-profile="isUserProfile" />
                <component :is="f.type" v-if="!f.props?.isCompound" v-bind="f.props" v-model="f.model"
                  :submitted="submitted" :is-user-profile="isUserProfile" />
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>

  <div v-if="demographicFields != null" ref="demographic" class="grid pb-4 w-full">
    <div class="col-12">
      <Card class="p-card-default-filters card-active">
        <template #title>
          <div class="px-3 pt-1">
            Demographic
          </div>
        </template>
        <template #content>
          <div class="grid px-3 pb-0">
            <div v-for="(row, i) in demographicFields" :key="row.items[0].props?.label"
              :class="getClass('main', row.items.length, i, row.items.some(x => x.props?.isCompound))">
              <div v-for="(f, j) in displayableItems(row.items)" :key="f.props?.label"
                :class="getClass('child', row.items.length, j)">
                <cp-compound-search-field-elements v-if="f.props?.isCompound" :search-field="f"
                  :submitted="submitted" />
                <component :is="f.type" v-if="!f.props?.isCompound" v-bind="f.props" v-model="f.model"
                  :submitted="submitted" />
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>

  <div v-if="allFirmographicFields != null" ref="firmographic" class="grid pb-4 w-full">
    <div class="col-12">
      <Card class="p-card-default-filters">
        <template #title>
          <div class="px-3 pt-1">
            Firmographic
          </div>
        </template>
        <template #content>
          <cp-reader-org-search-field v-if="isReaderLookUpExists" ref="childRef" @filter-changed="orgFilterChanged" />
          <div class="grid px-3 pb-0">
            <div v-for="(row, i) in allFirmographicFields" :key="row.items[0].props?.label"
              :class="getClass('main', row.items.length, i, row.items.some(x => x.props?.isCompound))">
              <div v-for="(f, j) in displayableItems(row.items)" :key="f.props?.label"
                :class="getClass('child', row.items.length, j)">
                <cp-compound-search-field-elements :is="f.type" v-if="f.props?.isCompound" v-bind="f.props"
                  :search-field="f" :submitted="submitted" />
                <component :is="f.type" v-if="!f.props?.isCompound" v-bind="f.props" v-model="f.model"
                  :submitted="submitted" />
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>

  <div v-if="contextualFields != null" ref="contextual" class="grid pb-4 w-full">
    <div class="col-12">
      <Card class="p-card-default-filters">
        <template #title>
          <div class="px-3 pt-1">
            Contextual
          </div>
        </template>
        <template #content>
          <div class="grid px-3 pb-0">
            <div v-for="(row, i) in contextualFields" :key="row.items[0].props?.label" class=""
              :class="getClass('main', row.items.length, i, row.items.some(x => x.props?.isCompound))">
              <div v-for="(f, j) in displayableItems(row.items)" :key="f.props?.label"
                :class="getClass('child', row.items.length, j)">
                <cp-compound-search-field-elements v-if="f.props?.isCompound" :search-field="f"
                  :submitted="submitted" />
                <component :is="f.type" v-if="!f.props?.isCompound" v-bind="f.props" v-model="f.model"
                  :submitted="submitted" />
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script lang='ts'>
import { ref, toRef, onMounted, PropType, computed, watch } from 'vue';
import { FILTER_COMPONENTS } from '@/components/searchFields';
import { FieldCode, TermsFilter } from '@/models/filters';
import { FilterModel } from '@/models/search';
import { FilterProp, findFilter } from '@/utils/filters';
import { Lookup } from '@/models/lookup';
import { INDEX_LOOKUPS } from '@/models/activity-dissemination';
import useViewportScroller from '@/use/viewportScroller';
import useSearchFilterSecurity from '@/use/searchFilterSecurity';
import useSearchLookups from '@/use/searchLookups';
import useCurrentUser from '@/use/currentUser';
import useAuthenticatedUser from '@/use/authenticatedUser';
import useDefaultFilterSecurity from '@/use/defaultFilterSecurity';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { DEFAULT_TIMEOUT_TIME } from '@/models/common/constants';
import useActivityFields from '@/use/activityFields';

export default {
  name: 'CpActivityReportConfigFilters',
  components: {
    ...FILTER_COMPONENTS
  },
  props: {
    modelValue: { type: Object as PropType<FilterModel>, default: () => <FilterModel>{ filters: [], indices: [] } },
    includeIndices: { type: Boolean, default: false },
    props: { type: Array as PropType<FilterProp[]>, default: () => [] },
    submitted: Boolean,
    isNew: Boolean
  },
  setup(props) {
    const selectedIndices = ref<Array<Lookup<string>>>([]);
    const filteredIndices = ref<Array<Lookup<string>>>([]);

    const { userActivitySearchFields, userId } = useAuthenticatedUser();
    const { currentUser } = useCurrentUser();
    const currentUserId = computed(() => currentUser.value.userId ?? '');
    const isUserProfile = computed(() => userId.value !== currentUserId.value);
    const { basicSearchableActivityFields } = useActivityFields();
    const activityReportFields: FieldCode[] = ['ProfileStatus', 'OpenAccess'];
    const excludedFields = ['ActivityTypes', 'DateRange', 'RelativeDateRange'];
    const fields = computed(() => [
      ...userActivitySearchFields.value, 
      ...basicSearchableActivityFields.value.map(x => x.field), 
      ...activityReportFields
    ].filter(x => !excludedFields.includes(x)));
    const { data: defaultFilterModel, getData: getDefaultFilters } = useDefaultFilterSecurity(currentUserId);
    const { onlineBgMap, orgQuestionsMap } = useSearchLookups();

    const {
      generalFields,
      demographicFields,
      contextualFields,
      allFirmographicFields,
      getSearchFieldRowClass,
      updateData,
      filterModel,
      displayableItems
    } = useSearchFilterSecurity(selectedIndices, toRef(props, 'props'), fields, orgQuestionsMap, onlineBgMap);

    const { scrollListener } = useViewportScroller();

    const general = ref<any>(null);
    const demographic = ref<any>(null);
    const firmographic = ref<any>(null);
    const contextual = ref<any>(null);
    const isReaderLookUpExists = ref(false);
    const childRef = ref();

    const searchActivityType = (event) => {
      setTimeout(() => {
        if (!event.query?.trim().length) {
          filteredIndices.value = INDEX_LOOKUPS.filter((e1) => selectedIndices.value.findIndex((e2) => e2.name == e1.name) === -1);
        } else {
          const notSelected = INDEX_LOOKUPS.filter((e1) => selectedIndices.value.findIndex((e2) => e2.name == e1.name) === -1);
          filteredIndices.value = notSelected.filter((type) => type.name.toLowerCase().startsWith(event.query.toLowerCase()));
        }
      }, DEFAULT_TIMEOUT_TIME);
    };

    const onFilterModelChanged = (filters: FilterModel) => {
      updateData(filters);
    };

    const validateActivityTypes = computed(() => (
      props.includeIndices
        ? { selectedIndices: { required } }
        : {}
    )
    );

    const v$ = useVuelidate(validateActivityTypes, { selectedIndices });
    onMounted(() => {
      isReaderLookUpExists.value = userActivitySearchFields.value.includes('ReaderOrgsLookup');
      scrollListener(general.value);
      scrollListener(demographic.value);
      scrollListener(firmographic.value);
      scrollListener(contextual.value);
      if (props.isNew) {
        getDefaultFilters().then(() => {
          // set clients to user's company clalims
          if (isUserProfile.value) {
            defaultFilterModel.value.filters.map(item => {
              if (item.field === 'Clients') {
                const clientsFilter = item as TermsFilter;
                clientsFilter.value = clientsFilter.value.filter(c => currentUser.value.clients?.some(claim => claim.id === c.id));
                item = clientsFilter;
              }
              return item;
            });
          }

          updateData(defaultFilterModel.value);

          // set all 4 activity types
          selectedIndices.value = INDEX_LOOKUPS;
        });
      }
    });

    const orgFilterChanged = (model: Array<Lookup<any>>) => {
      let readerOrgFilter: TermsFilter = findFilter<TermsFilter>(filterModel.value.filters, 'ReaderOrgs');
      if (!readerOrgFilter) {
        readerOrgFilter = new TermsFilter({ field: 'ReaderOrgs', value: model });
        filterModel.value.filters.push(readerOrgFilter);
      }
      readerOrgFilter.value = model;
      onFilterModelChanged(filterModel.value);
      onFilterModelChanged(filterModel.value);
    };

    watch(props, (newProps) => {
      onFilterModelChanged(newProps?.modelValue);
    }, { deep: true, immediate: true });

    return {
      generalFields, demographicFields, contextualFields, allFirmographicFields, filterModel, getClass: getSearchFieldRowClass,
      searchActivityType, selectedIndices, filteredIndices, v$, isUserProfile, displayableItems, childRef, isReaderLookUpExists, orgFilterChanged
    };
  }
};
</script>
