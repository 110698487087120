<template>
  <form class="p-fluid">
    <div class="bg-white">
      <Dialog id="onboardingheader" v-model:visible="isShowing" :closable="false" style="width: 58%;" :modal="true">
        <template #header>
          <div class="flex col-12">
            <div class="flex col-11">
              <h4 style="color:#ffffff">
                Create a new onboarding form
              </h4>
            </div>
            <div class="flex col-1 absolute right-0">
              <i style="color:white" class="pi pi-times reverse" aria-haspopup="true" aria-controls="overlay_menu"
                @click="handleClose" />
            </div>
          </div>
        </template>
        <div v-if="apiStatus" class="col-12 md:col-12 lg:col-8 success-message flex p-0 m-0 pr-2 flex">
          <label>{{ message }}</label>
          <span class="material-icons-outlined pl-1"> check_circle_outline </span>
        </div>

        <div class="col-12 pb-5" />
        <div class="flex col-12">
          <div class="col-5">
            <span class="p-float-label p-fluid">
              <InputText id="Name" v-model="v$.onboardingFormData.name.$model" type="text" />
              <label for="name" :class="{ 'p-error': v$.onboardingFormData.name.$invalid && submitted }">Name</label>
            </span>
            <small
              v-if="(v$.onboardingFormData.name.$invalid && submitted) || v$.onboardingFormData.name.$pending.$response"
              class="p-error">
              {{ v$.onboardingFormData.name.required.$message.replace('Value', 'Name') }}</small>
          </div>
          <div class="col-7">
            <span class="p-float-label p-fluid">
              <InputText id="emailAddress" v-model="v$.onboardingFormData.email.$model" type="text" />
              <label for="emailAddress" :class="{ 'p-error': v$.onboardingFormData.email.$invalid && submitted }">Email
                Address</label>
            </span>
            <small
              v-if="(v$.onboardingFormData.email.$invalid && submitted) || v$.onboardingFormData.email.$pending.$response"
              class="p-error">
              {{ v$.onboardingFormData.email.required.$message.replace('Value', 'Email') }}</small>
          </div>
        </div>
        <div class="flex col-12">
          <div class="col-6 p-fluid pb-3">
            <span class="p-float-label">
              <AutoComplete v-model="v$.selectedCompanies.$model" :multiple="false" :suggestions="filteredCompanies"
                field="name" force-selection :class="{ 'p-invalid': v$.selectedCompanies.$invalid && submitted }"
                @complete="searchCompanies($event)" />
              <label for="Clients" :class="{ 'p-error': v$.selectedCompanies.$invalid && submitted }">Company</label>
            </span>
            <small v-if="(v$.selectedCompanies.$invalid && submitted) || v$.selectedCompanies.$pending.$response"
              class="p-error">
              {{ v$.selectedCompanies.required.$message.replace('Value', 'Company') }}</small>
          </div>
          <div class="col-6">
            <span class="p-float-label p-fluid">
              <Dropdown id="fromName" v-model="v$.onboardingFormData.sentFromName.$model" :options="sentFromNameOptions"
                :class="{ 'p-invalid': v$.onboardingFormData.sentFromName.$invalid && submitted }" />
              <label for="fromName"
                :class="{ 'p-error': v$.onboardingFormData.sentFromName.$invalid && submitted }">From
                Name</label>
            </span>
            <small
              v-if="(v$.onboardingFormData.sentFromName.$invalid && submitted) || v$.onboardingFormData.sentFromName.$pending.$response"
              class="p-error">
              {{ v$.onboardingFormData.sentFromName.required.$message.replace('Value', 'From Name') }}</small>
          </div>
        </div>
        <div class="flex col-12">
          <div class="col-12">
            <span class="p-float-label p-fluid">
              <InputText id="fromAddress" v-model="v$.onboardingFormData.senderEmail.$model" type="text" />
              <label for="fromAddress"
                :class="{ 'p-error': v$.onboardingFormData.senderEmail.$invalid && submitted }">From
                Address</label>
            </span>
            <small
              v-if="(v$.onboardingFormData.senderEmail.$invalid && submitted) || v$.onboardingFormData.senderEmail.$pending.$response"
              class="p-error">
              {{ v$.onboardingFormData.senderEmail.required.$message.replace('Value', 'From Address') }}</small>
          </div>
        </div>
        <div class="col-12 flex flex-row-reverse">
          <div class="col-2 flex flex-row-reverse">
            <Button type="submit" label="New OnBoarding Form" class="p-button-success p-button-raised"
              @click="generateOnboardingForm(!v$.$invalid)">
              Send
              <span class="material-icons pl-2"> mail </span>
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  </form>
</template>

<script lang="ts">
import { BasicOnboardingFormData } from '@/models/onboarding/basicOnboardingFormData';
import { useToast } from 'primevue/usetoast';
import OnboardingFormService from '@/services/onboardingFormService';
import { ref, toRefs, watch, reactive, computed } from 'vue';
import { email, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useStore } from 'vuex';
import CmsCompanyService from '@/services/cmsCompanyService';

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    user: {
      type: String,
      default: '',
    }
  },
  setup(props) {
    const onboardingFormData = ref({});
    // let selectedCompanies = ref(new TermsFilter({ name: 'Clients', value: [] }));
    let selectedCompanies = ref([]) as any;
    let filteredCompanies = ref([]) as any;
    let options = ref([]) as any;
    let message: string = ref('') as any;
    let apiStatus = ref(false) as any;
    const toast = useToast();
    let { show } = toRefs(props);
    const submitted = ref(false);
    let isShowing = ref(false) as any;
    const store = useStore();
    const userId = computed(() =>
      store.getters['auth/userId']
    );

    const sentFromNameOptions = ref([
      'Field Technologies Online',
      'Life Science Connect',
      'MSP Insights',
      'Photonics Online',
      'RF GlobalNet',
      'VAR Insights',
      'Water Online'
    ]);

    const state = reactive({
      onboardingFormData: {
        name: '',
        email: '',
        sentFromName: '',
        senderEmail: ''
      },
      selectedCompanies: ''
    });

    const rules = {
      onboardingFormData: {
        name: { required },
        email: { required, email },
        sentFromName: { required },
        senderEmail: { required },
      },
      selectedCompanies: { required }
    };

    const v$ = useVuelidate(rules, state);

    watch(show, () => {
      isShowing.value = false;
      setTimeout(() => {
        isShowing.value = true;
      }, 0);
    });

    const handleClose = () => {
      isShowing.value = false;
      let html = document.getElementsByTagName('html')[0];

      html.classList.remove('overflow-hidden');
    };

    const searchCompanies = async (event) => {
      options = (await CmsCompanyService.find(event.query));
      options = options.map((x) => ({ id: x.id, name: x.name }));
      filteredCompanies.value = options;
    };

    const generateOnboardingForm = async (isFormValid) => {
      try {
        submitted.value = true;
        if (!isFormValid) {
          return;
        }
        const onboardingFormData = <BasicOnboardingFormData>Object.assign({}, v$.value.onboardingFormData.$model);
        onboardingFormData.companyId = (v$.value.selectedCompanies.$model as any).id;
        onboardingFormData.userId = userId.value;
        const onboardingFormId = (await OnboardingFormService.generateOnboardingForm(onboardingFormData) as any).data;
        toast.add({
          severity: 'success',
          detail: 'The Onboarding form has been generated and an email has been sent to ' + onboardingFormData.email,
          life: 3000
        });
        setTimeout(() => {
          window.location.href = '/onboarding/' + onboardingFormId + '/0';
        }, 3000);
      } catch (error) {
        toast.add({
          severity: 'error',
          detail: 'There was an error generating the Onboarding form',
          life: 3000
        });
        throw (error);
      }
    };
    return {
      state, v$, submitted, userId, filteredCompanies, searchCompanies, options, generateOnboardingForm, onboardingFormData,
      message, apiStatus, sentFromNameOptions, selectedCompanies, isShowing, handleClose
    };
  }
};
</script>
