import {IntLookup} from '@/models/lookup';

export enum Sequence {
  Initial = 1,
  Subsequent = 2
}

export const SEQUENCE_LOOKUPS: IntLookup[] = [
  {id: 1, name: 'Initial'},
  {id: 2, name: 'Subsequent'}
];
