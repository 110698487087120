<template>
  <div class="col-12 lg:col-2 p-secondary-color">
    Communities
  </div>
  <div class="col-12 lg:col-10 flex justify-content-between px-0 mt-3">
    <DataView :value="communities" layout="list" class="w-full">
      <template #list="slotProps">
        <div :class="getItemClass(slotProps.index)" class="flex align-items-center">
          <span class="font-weight-bold col-9">{{ slotProps.data.name }}</span>
          <Badge v-if="showId" class="p-badge-pointer col-3" :style="badgeStyle">
            {{ slotProps.data.id }}
          </Badge>
        </div>
      </template>
    </DataView>
  </div>
</template>

<script lang="ts">
import { Online } from '@/models/activity-dissemination';
import { PropType } from 'vue';

export default {
  name: 'CpCommunities',
  props: {
    communities: { type: Array as PropType<Online[]>, required: true },
    showId: { type: Boolean }
  },
  setup() {
    const badgeStyle = '--bg-color:#76869A';

    const getItemClass = (index: number) => index % 2 === 0 ? 'col-4 my-1 mr-6 flex' : 'col-4 my-1 flex';

    return { getItemClass, badgeStyle };
  }
};
</script>