import { UserRole } from '../userRole';
import { ClaimAccessLevel } from './claimLevel';


export type ClaimName = 'role' | 'company' | 'reviewActivities' | 'manageOnboardingForms' | 'manageNotifications' | 'viewNotifications'
  | 'accessMarketingPerformance';

export class Claim {
  constructor(public name: ClaimName, public value: UserRole | ClaimAccessLevel | boolean = true) { }
}
