<template>
  <div class="pb-3">
    <span class="p-float-label p-fluid">
      <InputText id="fileUrl" :model-value="fileUrl1" type="text" @input="updateFileURL" />
      <label for="fileUrl">File URL</label>
      <small class="hint">{{ hint }}</small>
    </span>
  </div>
  <div>  
    <FileUpload
      ref="fileUpload"
      mode="advanced"
      multiple
      :max-file-size="maxFileSize"
      :accept="accept"
      @select="onSelect">
      <template #header="{ chooseCallback }">
        <div class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
          <div class="flex gap-2">
            <Button label="Choose Files" icon="pi pi-upload" @click="chooseCallback()" />
          </div>
        </div>
      </template>
      <template #content="{ messages, removeFileCallback }">
        <Message v-for="msg of messages" :key="msg" severity="error" icon="pi pi-exclamation-triangle" :closable="true">
          {{ msg }}
        </Message>
        <div v-if="selectedFiles.length > 0" class="flex flex-column">
          <div v-for="(file, index) in selectedFiles" :key="index" class="flex align-items-center justify-content-center selected-file">
            <div class="col-2">
              <img v-if="file.isImage" :src="file.preview" role="presentation" height="50" style="width:100%;object-fit: contain;" class="shadow-2">
            </div>
            <div class="col-9">
              <span class="font-semibold">{{ file.name }}</span>
              <div>{{ formatSize(file.size) }}</div>
            </div>
            <div class="col-1 flex align-items-center justify-content-center">
              <Button icon="pi pi-times" severity="danger" text rounded aria-label="Remove" @click="removeFile(removeFileCallback, index)" />
            </div>
          </div>
        </div>
      </template>
      <template #empty>
        <div class="p-d-flex p-flex-column p-ai-center">
          <span class="p-mb-4">Drag and Drop files here to upload</span>
        </div>
      </template>
    </FileUpload>
    <div v-if="errorMessage" class="p-mt-2">
      <small class="p-error">{{ errorMessage }}</small>
    </div>
  </div>
</template>

<script lang="ts">
import { FileUploadSelectEvent } from 'primevue/fileupload';
import { ref, watch } from 'vue';

interface FileWithPreview extends File {
  preview: string;
  isImage: boolean;
}

export default {
  name: 'MultipleFileUpload',
  props: {
    accept: String,
    fileUrl: String,
    hint: String,
  },
  emits: ['files-selected', 'update:file-url'],
  setup(props, { emit }) {
    const fileUrl1 = ref(props.fileUrl);
    const selectedFiles = ref<FileWithPreview[]>([]);
    const errorMessage = ref('');
    const maxFileSize = 1024**2 * 500;

    const onSelect = (e: FileUploadSelectEvent) => {
      const newFiles = Array.from(e.files) as FileWithPreview[];
      newFiles.forEach(file => {
        const isImage = file.type.startsWith('image/');
        file.preview = URL.createObjectURL(file);
        file.isImage = isImage;

        if(selectedFiles.value.includes(file)) return;

        if (file.size > maxFileSize) {
          e.files = e.files.filter((f: FileWithPreview) => f !== file);
        }
        else {
          selectedFiles.value.push(file);
        }
      });
      emit('files-selected', selectedFiles.value);
    };

    const onUpload = () => {
      selectedFiles.value = [];
    };

    const removeFile = async (removeFileCallback: (arg0: any) => void, index: number) => {
      removeFileCallback(index);
      selectedFiles.value.splice(index, 1);
    };

    const updateFileURL = (e: any) => {
      emit('update:file-url', e.target.value);
    };

    watch(() => props.fileUrl, (newfileUrl) => {
      fileUrl1.value = newfileUrl;
      }
    );
    
    const formatSize = (bytes: number) => {
      if (bytes === 0) return '0 B';
      const k = 1024;
      const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };

    return { fileUrl1, selectedFiles, errorMessage, maxFileSize, onSelect, onUpload, removeFile, updateFileURL, formatSize };
  }
};
</script>
<style scoped>
.selected-file:hover {
  background: rgb(55 55 55 / 4%);
  border-radius: 5px;
}
</style>
