<template>
  <form ref="form">
    <div class="grid fluid">
      <div class="col-12 pb-2">
        <div class="flex justify-content-between">
          <h1 class="flex align-items-center line-height-1">
            {{ titleText }}
          </h1>
        </div>
        <span class="p-secondary-color text-xs">{{ dateRangeTxt }}</span>
      </div>
      <div class="col-12 lg:col-5">
        <cp-reader-info :email="emailFilterForComponents.value" />
      </div>
      <div class="col-12 lg:col-7">
        <cp-recent-engagements v-if="paramsForComponentsReady" :filter-model="filterModelWithWorkflowEmail" size="5" />
      </div>
      <div class="col-12 lg:col-5">
        <cp-engagements-by-month v-if="paramsForComponentsReady" :filter-model="filterModelWithWorkflowEmail" />
      </div>
      <div class="col-12 lg:col-7 pt-1">
        <cp-engagements-by-category v-if="paramsForComponentsReady" :filter-model="filterModelWithWorkflowEmail" />
      </div>
    </div>
  </form>
</template>

<script lang="ts">
import ReaderInfo from '@/components/reader-dashboard/ReaderInfo.vue';
import RecentEngagements from '@/components/reader-dashboard/RecentEngagements.vue';
import EngagementsByMonthShare from '@/components/reader-dashboard/EngagementsByMonth.vue';
import EngagementsByCategory from '@/components/reader-dashboard/EngagementsByCategory.vue';
import { FilterConfigModel, FilterModel, ReaderFilterModel } from '@/models/search';
import { dateRangeText, findFilter } from '@/utils/filters';
import { cloneDeep } from 'lodash';
import ReaderDashboardService from '@/services/readerDashboardService';
import {
  DEMOGRAPHIC_FIELDS,
  FIRMOGRAPHIC_FIELDS,
  TextFilter,
} from '@/models/filters';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import useAuthenticatedUser from '@/use/authenticatedUser';
import { Lookup } from '@/models/lookup';
import useDefaultSearchFilters from '@/use/defaultSearchFilters';
import useSearchFilterSecurity from '@/use/searchFilterSecurity';
import useSearchLookups from '@/use/searchLookups';
import useActivityFields from '@/use/activityFields';
import { usePageReload }  from '@/use/pageReload';

interface ReaderRouteData {
  email?: string;
}
export default {
  name: 'CpReaderDashboard',
  components: {
    'cp-reader-info': ReaderInfo,
    'cp-recent-engagements': RecentEngagements,
    'cp-engagements-by-month': EngagementsByMonthShare,
    'cp-engagements-by-category': EngagementsByCategory
  },
  setup() {
    const showFilter = ref(false);
    const emailFilterForComponents = ref(new TextFilter({ field: 'Email' }));
    const paramsForComponentsReady = ref(false);
    const selectedIndices = ref<Array<Lookup<string>>>([]);
    const route = useRoute();
    const { userActivitySearchFields, userId } = useAuthenticatedUser();
    const { onlineBgMap, orgQuestionsMap } = useSearchLookups();
    const { basicSearchableActivityFields, fieldsAddedToStore } = useActivityFields();
    const { hasReloaded } = usePageReload();
    const fields = computed(() => [
      ...userActivitySearchFields.value,
      ...basicSearchableActivityFields.value.map(x => x.field)
    ]);
    const {
      searchFields,
      filterModel,
      updateData
    } = useSearchFilterSecurity(selectedIndices, ref([]), fields, orgQuestionsMap, onlineBgMap);
    const { setDefaultValues, initialState, updateInitialState } = useDefaultSearchFilters(userId, searchFields, filterModel, selectedIndices);
    const routeData = computed(() => <ReaderRouteData>{ email: route.params['email']?.toString()?.replace(/___/gi, '.') });
    const isRouteDataEmpty = computed(() => !routeData.value.email);
    let filterConfig = new FilterConfigModel();
    filterConfig.excludeFields = [...DEMOGRAPHIC_FIELDS, ...FIRMOGRAPHIC_FIELDS];

    const filterModelWithWorkflowEmail = computed(() => {
      const model = <ReaderFilterModel>cloneDeep(filterModel.value);
      model.config = filterConfig;
      model.filters = model.filters.filter(f => !(filterConfig.excludeFields || []).includes(f.field));
      model.filters = model.filters.filter((x) => x.field !== 'Email');
      model.filters.push(emailFilterForComponents.value);
      model.noReaderFound = emailFilterForComponents.value.isEmpty();
      // NOTE: Due to the re-setting of the 'filters' property, filterModel must be a new object
      // for the watcher to detect changes between the old and new values; otherwise, it just gets the new value
      return model;
    });
    const titleText = computed(() => isRouteDataEmpty.value ? 'Most Active Reader' : 'Reader Dashboard');
    const _setEmailFilterForComponentsText = async (filterModel: FilterModel) => {
      const emailFilter: TextFilter = findFilter<TextFilter>(filterModel.filters, 'Email');
      if (!isRouteDataEmpty.value) {
        emailFilterForComponents.value.value = routeData.value.email;
      } else {
        emailFilterForComponents.value.value = emailFilter?.value && emailFilter?.op === 'Equals'
          ? emailFilter.value
          : await ReaderDashboardService.getMostActiveReader(filterModel);
      }
    };
    const filterSubmitted = async (filterModel: FilterModel) => {
      // NOTE: Order matters
      // The filterModel must be set before emailFilter because
      // the components will be re-rendered as soon as either value is set since they're receiving both as params
      // and if filterModel is incomplete, EngagementsByMonth will error due to the absence of date range filter
      paramsForComponentsReady.value = false;
      updateData(filterModel);
      await _setEmailFilterForComponentsText(filterModel);
      paramsForComponentsReady.value = true;
    };

    const dateRangeTxt = dateRangeText(filterModel.value.filters);

    const closePanel = () => {
      showFilter.value = false;
    };

    const rerunWithUpdatedFields = (val: boolean) => {
      if (val) {
        updateInitialState(fields.value);
        filterSubmitted(initialState.value);
      }
    };

    watch(fieldsAddedToStore, rerunWithUpdatedFields, { immediate: true });

    onMounted(() => {
      // No need to call functions if page was reloaded because they are handled by the fieldsAddedToStore watcher
      if (hasReloaded.value) { return; }

      setDefaultValues(fields.value);
      filterSubmitted(initialState.value);
    });


    return {
      dateRangeTxt,
      emailFilterForComponents,
      filterModel,
      filterModelWithWorkflowEmail,
      paramsForComponentsReady,
      showFilter,
      titleText,
      closePanel,
      filterSubmitted,
      basicSearchableActivityFields
    };
  }
};
</script>

<style lang="scss" scoped></style>
