import { AppUser } from '@/models/appUser';
import { cloneDeep } from 'lodash';
import axios from 'axios';
import { IntLookup, GuidLookup } from '@/models/lookup';
import { SearchCriteria } from './searchCriteria';
import { PagedList } from '@/models/pagedList';
import { UserLookup } from '@/models/userLookup';

export interface CreateUserRequest {
  user: AppUser;
  sendEmailToAdmin: boolean;
  sendOnboardingForm: boolean;
}
export default {
  get(id: string): Promise<AppUser> {
    return axios.get(`/api/user/${id}`).then(x => new AppUser(x.data));
  },

  async list(
    opts: SearchCriteria = {}
  ): Promise<PagedList<AppUser>> {
    const cIds = (opts.companyIds || []).join(',');
    const qsArgs = {
      params: { query: opts.query?.trim(), companyIds: cIds, page: opts.page, pageSize: opts.pageSize }
    };
    const response = await axios.get('/api/users', qsArgs);
    const pl: PagedList<any> = response.data;
    pl.items = pl.items.map(x => new AppUser(x));
    return pl;
  },

  create(request: CreateUserRequest): Promise<AppUser> {
    const data = <any>cloneDeep(request.user);
    data.sendEmailToAdmin = request.sendEmailToAdmin;
    data.sendOnboardingForm = request.sendOnboardingForm;
    data.companies = (data.companies || []).map((x: IntLookup) => x.id);
    data.communities = (data.communities || []).map((x: GuidLookup) => x.id);
    data.analystTags = (data.analystTags || []).map((x: GuidLookup) => x.id);
    const promise = axios.post('/api/user', data);
    return apiRequestPromiseHandler(promise);
  },

  update(user: AppUser): Promise<AppUser> {
    const req = <any>cloneDeep(user);
    req.companies = (req.companies || []).map((x: IntLookup) => x.id);
    req.communities = (req.communities || []).map((x: GuidLookup) => x.id);
    req.analystTags = (req.analystTags || []).map((x: GuidLookup) => x.id);
    const promise = axios.patch(`/api/user/${req.id}`, req);
    return apiRequestPromiseHandler(promise);
  },

  delete(id: string): Promise<any> {
    return axios.delete(`/api/user/${id}`);
  },

  triggerPasswordReset(email: string): Promise<any> {
    return apiRequestPromiseHandler(
      axios.post('/api/forgot-password', { email })
    );
  },

  resendRegistrationEmail(id: string): Promise<any> {
    return apiRequestPromiseHandler(
      axios.get(`/api/user/${id}/resend-registration-email`)
    );
  },

  /**
   * Calls user service to get UserLookup data
   */
  async getLookups(opts: SearchCriteria = {}): Promise<UserLookup[]> {
    const cIds = (opts.companyIds || []).join(',');
    const qsArgs = {
      params: { query: encodeURIComponent(opts?.query ?? ''), companyIds: cIds, page: opts.page }
    };

    const res = await axios.get<UserLookup[]>(`/api/users/lookups`, qsArgs);
    return res.data.map(x => new UserLookup(x.userId, x.name, x.email, x.role, x.isRegistered));
  }
};

function apiRequestPromiseHandler(requestPromise: Promise<any>): Promise<any> {
  return requestPromise.then(
    r => {
      const { success, message, data } = r.data;
      if (!success) {
        return Promise.reject(message || '');
      }
      return Promise.resolve(data);
    },
    err => {
      console.log('error', err);
      return Promise.reject(err);
    }
  );
}
