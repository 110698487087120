<template>
  <div class="grid w-full mt-2">
    <div class="col-12 lg:col-8">
      <span class="p-float-label p-fluid">
        <InputText id="email" v-model="user.email" type="email"
          :class="{ 'p-invalid': v$.email.$invalid && submitted }" />
        <label for="email">Email</label>
      </span>
      <small v-if="(v$.email.required.$invalid && submitted) || v$.email.$pending.$response" class="p-error">
        {{ v$.email.required.$message.replace('Value', 'Email') }}
      </small>
      <small v-if="(v$.email.email.$invalid && submitted) || v$.email.$pending.$response" class="p-error">
        {{ v$.email.email.$message.replace('Value', 'Email') }}
      </small>
    </div>
    <div class="col-12 lg:col-4">
      <span class="p-float-label p-fluid">
        <Dropdown v-model="user.role" :options="userRoles" option-value="id" option-label="id"
          placeholder="Select a Role" :disabled="loggedInUserIsClient" @change="userRoleChanged($event.value)" />
        <label for="role">Role</label>
      </span>
    </div>

    <div class="col-12 lg:col-6">
      <span class="p-float-label p-fluid">
        <InputText id="firstName" v-model="user.firstName" type="text"
          :class="{ 'p-invalid': v$.firstName.$invalid && submitted }" />
        <label for="firstName">First Name</label>
      </span>
      <small v-if="(v$.firstName.required.$invalid && submitted) || v$.firstName.$pending.$response" class="p-error">
        {{ v$.firstName.required.$message.replace('Value', 'First Name') }}</small>
      <small v-if="(v$.firstName.maxLength.$invalid && submitted) || v$.firstName.$pending.$response" class="p-error">
        {{ v$.firstName.maxLength.$message.replace('allowed is', 'allowed for First Name is') }}</small>
    </div>
    <div class="col-12 lg:col-6">
      <span class="p-float-label p-fluid">
        <InputText id="lastName" v-model="user.lastName" type="text"
          :class="{ 'p-invalid': v$.lastName.$invalid && submitted }" />
        <label for="lastName">Last Name</label>
      </span>
      <small v-if="(v$.lastName.required.$invalid && submitted) || v$.lastName.$pending.$response" class="p-error">
        {{ v$.lastName.required.$message.replace('Value', 'Last Name') }}</small>
      <small v-if="(v$.lastName.maxLength.$invalid && submitted) || v$.lastName.$pending.$response" class="p-error">
        {{ v$.lastName.maxLength.$message.replace('allowed is', 'allowed for Last Name is') }}</small>
    </div>
  </div>
</template>
<script lang="ts">
import { useStore } from 'vuex';

import { UserRole } from '@/models/userRole';
import { Claim } from '@/models/security';
import useUserSecurity from '@/use/userSecurity';
import { toRef } from 'vue';

export default {
  name: 'CpProfileUserIdentification',
  props: {
    modelValue: { type: Object, required: true },
    submitted: Boolean,
    v: { type: Object, required: true }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const atLeastInternal: Claim = { name: 'role', value: 'Internal' };

    // Vuex Store
    const store = useStore();
    const satisfiesClaim = store.getters['auth/satisfiesClaim'];
    const loggedInUserIsClient = !satisfiesClaim(atLeastInternal);
    // console.debug(`UserIdentification.setup() - local`, local);
    const { user, userRoles } = useUserSecurity(toRef(props, 'modelValue'));

    function userRoleChanged(role: UserRole) {
      if (role === 'Client') {
        user.value.reviewActivities = false;
        user.value.notificationClaimLevel = 'None';
      } else {
        user.value.companies = [];
      }
      emit('update:modelValue', user);
    }

    return {
      loggedInUserIsClient,
      user,
      userRoles,
      userRoleChanged,
      v$: props.v
    };
  }
};
</script>
