import { useStore } from 'vuex';
import { Ref, ref } from 'vue';
import { uniqBy } from 'lodash';

import { INDEX_LOOKUPS } from '@/models/activity-dissemination';
import { DEFAULT_DATE_RANGE_FILTER, FieldCode } from '@/models/filters';
import { FilterModel } from '@/models/search';
import { SearchDefaults } from '@/store/models/currentSearchState';
import { SEARCH_MODULE } from '@/store/modules/search.module';
import { initSearchFilter, SearchFieldRow } from '@/components/searchFields/metadata';
import { Lookup } from '@/models/lookup';

export default function useDefaultSearchFilters(userId: Ref<string>, selectedSearchFields: Ref<SearchFieldRow[]> = ref([]),
  selectedFilterModel: Ref<FilterModel> = ref({ filters: [], indices: [] }), selectedIndices: Ref<Array<Lookup<string>>> = ref([])) {
  const store = useStore();
  const currentState = ref<FilterModel>({ filters: [], indices: [] });
  const searchDefaults = ref<SearchDefaults>({ filters: [], indices: [] });
  const initialState = ref<FilterModel>({ filters: [], indices: [] });
  const defaultsLoaded = ref(false);

  const getData = async () => {
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async resolve => {
      if (!store.state.search.defaults) {
        await store.dispatch(`search/${SEARCH_MODULE.GET_SEARCH_DEFAULTS}`, { userId: userId.value });
        await store.dispatch(`search/${SEARCH_MODULE.GET_CURRENT_SEARCH}`, { userId: userId.value });
      }
      searchDefaults.value = store.state.search.defaults;
      currentState.value = store.state.search.criteria?.filterModel ?? store.state.search.defaults;
      const indices = currentState.value.indices?.length
        ? currentState.value.indices
        : searchDefaults.value.indices?.length
          ? searchDefaults.value.indices
          : INDEX_LOOKUPS;

      const filters = currentState.value.filters?.length
        ? currentState.value.filters
        : searchDefaults.value.filters;

      initialState.value = {
        filters: uniqBy([
          ...filters,
          DEFAULT_DATE_RANGE_FILTER,
        ], x => x.field),
        indices
      };

      selectedIndices.value = indices;
      defaultsLoaded.value = true;
      resolve(true);
    });
  };

  getData();

  const updateData = (fields: FieldCode[]) => {
    selectedSearchFields.value = initSearchFilter(fields, searchDefaults.value.filters);
    selectedFilterModel.value.filters = searchDefaults.value.filters;
    selectedFilterModel.value.indices = searchDefaults.value.indices;
    selectedIndices.value = searchDefaults.value.indices;
  };

  const updateInitialState = (fields: FieldCode[]) => {
      const indices = currentState.value.indices?.length
          ? currentState.value.indices
          : searchDefaults.value.indices?.length
              ? searchDefaults.value.indices
              : INDEX_LOOKUPS;

      const filters = currentState.value.filters?.length
          ? currentState.value.filters
          : searchDefaults.value.filters;

      initialState.value = {
          filters: uniqBy([
              ...filters.filter(f => fields.includes(f.field)),
              DEFAULT_DATE_RANGE_FILTER,
          ], x => x.field),
          indices
      };
  };

  return { searchDefaults, initialState, currentState, defaultsLoaded, setDefaultValues: updateData, updateInitialState };
}
