<template>
  <form @submit.prevent="login(!v$.$invalid)">
    <div class="grid">
      <div class="col-6 md:col-6 lg:col-6 brand-page-background">
        <img :src="require('../assets/LoginPageWithLogo.png')" style="width:97%;" alt="">
      </div>
    </div>
    <div class="flex flex-row flex-row-reverse justify-content-start v-screen">
      <div class="flex flex-column h-screen justify-content-center align-items-center col-12 md:col-6">
        <Card class="col-12 p-login-card">
          <template #title>
            <div class="flex flex-row justify-content-center mt-4 p-login-card-title">
              <h1>Log In</h1>
            </div>
          </template>
          <template #content>
            <div class="flex flex-column">
              <div class="flex flex-row align-items-center justify-content-center">
                <div class="flex px-8">
                  <p class="text-center">
                    Enter your email and password to continue.
                  </p>
                </div>
              </div>
              <div class="flex mx-8 mt-6">
                <span class="p-float-label p-input-icon-right w-full mt-3">
                  <InputText id="email" v-model="v$.cred.email.$model" class="p-inputtext w-full" type="email"
                    :class="{ 'p-invalid': v$.cred.email.$invalid && submitted }" />
                  <label for="email">Email</label>
                </span>
              </div>
              <div class="flex mx-8 mt-4">
                <span class="p-float-label p-input-icon-right w-full mt-3">
                  <i class="pi" :class="{ 'pi-eye': passwordFieldClass, 'pi-eye-slash': !passwordFieldClass }"
                    @click="viewPassword" />
                  <InputText id="password" v-model="v$.cred.password.$model" class="p-inputtext w-full"
                    :type="passwordFieldType" :class="{ 'p-invalid': v$.cred.password.$invalid && submitted }" />
                  <label for="password">Password</label>
                </span>
              </div>
              <div class="flex mx-8 align-items-left mt-2">
                <router-link to="/forgot-password"
                  class="p-button p-component p-button-link pl-0 cursor-pointer p-login-color">
                  Forgot Password
                </router-link>
              </div>
              <div class="flex align-items-center justify-content-center mt-4">
                <Button type="submit" label="Log In" class="p-button-raised p-button-rounded px-5 text-white">
                  <ProgressSpinner v-if="loading && !v$.$invalid" style="width: 20px; height: 20px" stroke-width="8" />
                </Button>
              </div>
              <HelpToggle title="Log in help" details="Please ensure your account is registered before logging in, by clicking on the link from the
                      registration e-mail that was sent to you." />
            </div>
          </template>
        </Card>
        <mail-to />
        <div class="mt-2">
          <a target="_blank" href="https://www.templetoninsights.com/terms-conditions/" class="pr-2 p-login-color">
            Terms And Conditions
          </a>
        </div>
        <div class="text-right align-items-center justify-content-center mt-2">
          <a target="_blank" href="https://www.templetoninsights.com/privacy-policy/" class="p-login-color">
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  </form>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { AUTH_ACTIONS, AUTH_MUTATIONS } from '@/store/modules/auth.module';
import { SEARCH_MODULE } from '@/store/modules/search.module';
import { email, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { set } from 'vue-gtag';
import HelpToggle from '@/components/login/helpToggle.vue';
import MailTo from '@/components/login/mailTo.vue';


export default defineComponent({
  components: { MailTo, HelpToggle },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      cred: {
        email: '',
        password: '',
      },
      submitted: false as boolean,
      loading: false,
      isOpenHelp: false,
      passwordFieldType: 'password'
    };
  },
  computed: {
    passwordFieldClass() {
      return this.passwordFieldType === 'password';
    }
  },
  validations() {
    return {
      cred: {
        email: {
          required,
          email,
        },
        password: {
          required,
        },
      },
    };
  },
  methods: {
    viewPassword() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    async login(isFormValid: boolean) {
      this.submitted = true;

      if (!isFormValid) {
        return;
      }

      this.loading = true;
      try {
        const success = await this.$store.dispatch(
          `auth/${AUTH_ACTIONS.LOG_IN}`,
          this.cred
        );
        const userId = this.$store.state.auth.user?.userId;
        if (success) {
          await this.$store.dispatch(`search/${SEARCH_MODULE.GET_SEARCH_DEFAULTS}`, {
            userId,
          });
        } else {
          this.$toast.removeAllGroups();
          this.$toast.add({
            severity: 'error',
            summary: '',
            detail: 'Invalid username or password',
            life: 3000,
            closable: false,
          });
        }

        set({ userId });
        const route = this.$store.state.auth.route;
        const path = route ? route.fullPath : '/';
        this.$store.commit(`auth/${AUTH_MUTATIONS.SET_ROUTE}`);
        return success && this.$router.push(path);
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.brand-page-background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  padding: 0;
  margin: 0;
}
</style>
