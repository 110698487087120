<template>
  <div class="flex justify-content-between">
    <h2 class="line-height-1">
      {{ title }}
      <!-- <span class="help-icon ml-2">?</span> -->
    </h2>
  </div>
  <div class="flex pt-3">
    <DataTable :value="data1"
      class="w-full">
      <Column field="ContentTitle"
        header="Content Title"
        :style="{ width: '35%' }">
        <template #body="slotProps">
          <div class="truncate-text-mostread">
            <a target="_blank"
              :title="slotProps.data.ContentTitle"
              :href="slotProps.data.DocumentUrl">{{
              slotProps.data.ContentTitle
            }}</a>
          </div>
        </template>
      </Column>
      <Column field="Type"
        header="Type"
        :style="{ width: '25%' }" />
      <Column field="Stage"
        header="Stage"
        :style="{ width: '15%' }">
        <template #body="slotProps">
          <img :src="slotProps?.data.buyersJourneyBadge?.icon" width="55">
        </template>
      </Column>
      <Column field="UniqueReaders"
        header="Unique Readers"
        :style="{ width: '15%' }" />
      <Column field="Engagements"
        header="Engagements"
        :style="{ width: '10%' }" />
      <template #empty>
        <div>
          <cp-no-datatable-records />
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import ReaderCoService from '@/services/readerCoService';
import DataMapper, { FieldMapping } from '@/models/dataMapper';
import { LinkFieldData } from '@/models/analytics/linkFieldData';
import { DEFAULT_PAGINATION, FilterModel, PageableFilterCriteria } from '@/models/search';
import { SEARCH_MODULE } from '@/store/modules/search.module';
import { AggregationType } from '@/models/analytics/aggregationType';
import { cloneDeep, isEqual } from 'lodash';
import { filterValueExists } from '@/utils/filters';
import { Filter } from '@/models/filters';
import { Vue, Options } from 'vue-class-component';
import NoDataTableRecordsComponent from '@/components/common/NoDataTableRecordsComponent.vue';

@Options({
  components: {
    'cp-no-datatable-records': NoDataTableRecordsComponent
  }
})
export default class DataTable extends Vue {
  @Prop({ default: () => <FilterModel>{ filters: [], indices: [] } })
  public filterModel: FilterModel;
  @Prop({ default: () => <Filter>{ field: 'ReaderOrgs' } }) public orgFilter: Filter;
  @Prop() aggType!: AggregationType;
  data1: Array<Record<string, any>> = [];
  linksCache: Record<string, string[]> = {};
  fieldMappings: FieldMapping[] = [];
  title: string = '';
  linkFields: LinkFieldData[] = [];
  rebuildLinksCache = false;
  loading = true;

  @Watch('filterModelWithOrg', { immediate: true })
  async filtersChanged(newVal: FilterModel, oldVal: FilterModel) {

    const noChanges = isEqual(newVal, oldVal);
    if (
      !newVal ||
      noChanges ||
      !filterValueExists(newVal.filters, 'Clients')
    ) {
      return;
    }

    this.rebuildLinksCache = true;
    await this._executeApiRequest(newVal);

    this.loading = false;
  }

  get filterModelWithOrg() {
    const filterModel = cloneDeep(this.filterModel);
    filterModel.filters.push(this.orgFilter);
    return filterModel;
  }

  get headers() {
    return this.fieldMappings.map((x: FieldMapping) => ({
      text: x.targetField,
      value: x.targetField,
      sortable: false,
    }));
  }

  get links() {
    if (Object.keys(this.linksCache).length === 0 || this.rebuildLinksCache) {
      const linkFields: LinkFieldData[] = this.linkFields;

      if (linkFields) {
        linkFields.forEach((x) => {
          this.linksCache[x.textField] = this.data1.map((y) => `${x.url}/${encodeURIComponent(y[x.urlParamField])}`);
        });
      }
    }

    return this.linksCache;
  }

  itemLink(item: Record<string, any>, key: string) {
    const links = this.links;
    return links[key] && links[key][this.data1.indexOf(item)];
  }

  showEngagements() {
    const pagination = Object.assign({}, DEFAULT_PAGINATION);

    const criteria = new PageableFilterCriteria({ filterModel: this.filterModel });

    this.$store.commit(`search/${SEARCH_MODULE.SET_CURRENT_SEARCH}`, {
      criteria,
      pagination,
    });
    this.$router.push('/analytics/search');
  }

  private async _executeApiRequest(filterModel: FilterModel) {
    let responseData: Array<Record<string, any>> = [];
    switch (this.aggType) {
      case 'mostReadContent':
        this.fieldMappings = [
          { targetField: 'ContentTitle', sourceField: 'title' },
          { targetField: 'Type', sourceField: 'contentType' },
          { targetField: 'Stage', sourceField: 'buyersJourneyStage' },
          { targetField: 'UniqueReaders', sourceField: 'totalUniqueReaders' },
          { targetField: 'Engagements', sourceField: 'totalEngagements' },
          { targetField: 'DocumentUrl', sourceField: 'documentUrl' },
        ];

        this.title = 'Most Read Content';
        responseData = await ReaderCoService.getMostReadContent(filterModel);
        break;
      default:
        break;
    }

    if (responseData?.length > 0) {
      // Create data object with fields that should appear in the header while preserving other fields
      this.data1 = responseData.map((x: Record<string, any>) => {
        const mapper = new DataMapper(x, this.fieldMappings, true);
        return { key: responseData.indexOf(x), ...mapper.mappedData };
      });
    }
    else{
      this.data1 = [];
    }
  }
}
</script>

<style lang="scss">
.reader-co-data-table {
  th {
    padding: 0 5px !important;
  }

  td {
    padding: 0 5px !important;
    &.ellipsis {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 120px;
    }
  }

  .all-engagements-link {
    color: white;
    text-decoration: underline;
  }
}

.truncate-text-mostread {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  max-width: 200px;
}
</style>
