<template>
  <div>
    <!-- <v-toolbar flat dense dark color='primary'>
      <slot name="icon">
        <v-tooltip v-if="showType" bottom>
          <template v-slot:activator="{ on }">
            <v-icon class="mr-2" v-on="on">{{icon}}</v-icon>
          </template>
<span>{{value.description || value.name}}</span>
</v-tooltip>
</slot>
<v-toolbar-title>
  {{title}}
</v-toolbar-title>
<v-spacer></v-spacer>
<v-icon v-if="shouldShowSchedule" class="ma-2" :title="scheduleTooltip">{{ scheduleIcon }}</v-icon>
<v-icon v-if="shouldShowCompleted" class="ma-2" color="red" :title="isCompletedTooltip">{{ isCompletedIcon }}
</v-icon>
</v-toolbar> -->
  </div>
</template>
<script lang='ts'>
import { Prop } from 'vue-property-decorator';
import { GetTypeIcon, Notification } from '@/models/notification';
import { Vue, Options } from 'vue-class-component';

@Options({
  name: 'cp-stepper-title-bar'
})
export default class NotificationStepperTitleBar extends Vue {
  @Prop() value: Notification;
  @Prop({ default: 'Create a Custom Alert' }) title: string;
  @Prop({ default: true }) showType: boolean;
  @Prop({ default: true }) showSchedule: boolean;
  @Prop({ default: true }) showComplete: boolean;

  get icon(): string {
    if (this.value.$type) {
      return GetTypeIcon(this.value.type) || '';
    }
    return '';
  }

  get scheduleIcon(): string {
    return this.value.scheduleActive ? 'alarm_on' : 'alarm_off';
  }

  get scheduleTooltip(): string {
    return this.value.scheduleActive ? 'Schedule Active' : 'Schedule Inactive';
  }

  get isCompletedIcon(): string {
    return this.value.isComplete ? 'cloud_done' : 'alarm_off';
  }

  get isCompletedTooltip(): string {
    if (this.value.scheduleActive) {
      return 'Notification Scheduled';
    }
    if (this.value.isComplete) {
      return 'Notification Ready To Be Scheduled';
    }
    return 'Notification Requires Additional Information To Be Scheduled';
  }

  get shouldShowCompleted(): boolean {
    return !this.value.scheduleActive && this.showComplete ? true : false;
  }

  get shouldShowSchedule(): boolean {
    return this.value.isComplete && this.showSchedule ? true : false;
  }
}
</script>

<style lang='scss'></style>
