import { ICON_WIDTH } from '@/models/styles';

const SEARCH_HEADERS = [
  { text: '', value: 'activityType', sortable: false, width: ICON_WIDTH },
      { text: 'Quality', value: 'quality', sortable: false },
      { text: 'Date', value: 'date' },
      { text: 'Community', value: 'onlines' },
      { text: 'Reader Company', value: 'company.keyword' },
      { text: 'Name', value: 'lastName' },
      { text: 'Title', value: 'jobTitle.keyword' },
      { text: 'Document', value: 'docTitle.keyword' },
      { text: 'Subsequent Count', value: 'shares', sortable: false }
];

export function getEngagementSearchHeaders() {
  return SEARCH_HEADERS;
}
