import { GenericActionItem } from '@/models/common/actionItem';
import { Frequency } from '.';

export type FrequencyActionItem = GenericActionItem<Frequency>

export const DEFAULT_FREQUENCY_ACTION: FrequencyActionItem = {
  type: 'Monthly',
  text: 'Both',
  description: 'Select past vs. current period change direction is both Positive and Negative',
  active: true
};

export const FREQUENCY_ACTIONS: FrequencyActionItem[] = [
  {
    type: 'Daily',
    text: 'Daily',
    icon: 'view_day',
    description: 'Send a notification daily'
  },
  {
    type: 'Weekly',
    text: 'Weekly',
    icon: 'view_week',
    description: 'Send a notification once a week'
  },
  {
    type: 'Monthly',
    text: 'Monthly',
    icon: 'calendar_view_month',
    description: 'Send a notification once a month',
    active: true
  }
];
