export type UserRole = 'Client' | 'Internal' | 'Admin' | 'SystemAdmin';
export enum UserRoleEnum {
  Client = 1,
  Internal,
  Admin,
  SystemAdmin
}

export interface UserRoleInfo {
  id: UserRole;
  createRoles: UserRole[] | [];
}

export const USER_ROLE_LOOKUPS: Readonly<Array<{ id: UserRole, createRoles: Array<UserRole> }>> = Object.freeze([
  { id: 'Client', createRoles: [] },
  { id: 'Internal', createRoles: ['Client'] },
  { id: 'Admin', createRoles: ['Client', 'Internal'] },
  { id: 'SystemAdmin', createRoles: ['Client', 'Internal', 'Admin', 'SystemAdmin'] }
]);

export class UserRoleOptions {
  static createRoles(userRole: UserRole): UserRoleInfo[] {
    const roleLookup = USER_ROLE_LOOKUPS.filter(r => r.id === userRole)[0];
    return USER_ROLE_LOOKUPS.filter(r => roleLookup.createRoles.some(x => r.id === x));
  }

  static availableRolesForSelf(userRole: UserRole): UserRoleInfo[] {
    return USER_ROLE_LOOKUPS.filter(r => UserRoleEnum[r.id] <= UserRoleEnum[userRole]);
  }

  static availableRolesForOthers(userRole: UserRole): UserRoleInfo[] {
    if (userRole === 'SystemAdmin') {
      return USER_ROLE_LOOKUPS.map(x => x);
    } else {
      return USER_ROLE_LOOKUPS.filter(r => UserRoleEnum[r.id] < UserRoleEnum[userRole]);
    }
  }
}
