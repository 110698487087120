import { ref, Ref } from 'vue';
import { useStore } from 'vuex';
import { FilterConfigModel, FilterModel } from '@/models/search';
import { SEARCH_MODULE } from '@/store/modules/search.module';
import { JwtTokenUser } from '@/store/models';
import { Validation } from '@vuelidate/core';

import { event } from 'vue-gtag';
import { Lookup } from '@/models/lookup';
import { INDEX_LOOKUPS } from '@/models/activity-dissemination';
import useActivityTypeSecurity from './activityTypeSecurity';

export default function useSearchFilterActions(selectedFilterModel: Ref<FilterModel>, v$: Ref<Validation>, selectedIndices: Ref<Array<Lookup<string>>>,
  filteredIndices: Ref<Array<Lookup<string>>>, authenticatedUser: Ref<JwtTokenUser>, updateData: (filters: FilterModel) => void, submitted: Ref<boolean>) {
  const loading = ref(false);
  const store = useStore();
  const { searchActivityTypes } = useActivityTypeSecurity(selectedIndices, filteredIndices);


  const searchFilters = async () => {
    const isValid = await _validateFilters();
    // console.debug(`useSearchFilterActions.searchFilters() - { isValid }`, isValid);
    if (!isValid) {
      return false;
    }
    _sendToGoogleTagManager();
    _saveSession();
    return true;
  };

  const resetFilter = async (config?: FilterConfigModel) => {
    // console.debug(`useSearchFilterActions.resetFilter()`);
    _sendToGoogleTagManager();

    await store.dispatch(`search/${SEARCH_MODULE.GET_SEARCH_DEFAULTS}`, {
      userId: authenticatedUser.value.userId,
    });

    const filterModel = store.state.search.defaults;
    selectedFilterModel.value.filters = [...filterModel.filters];
    selectedFilterModel.value.indices = filterModel.indices?.length ? [...filterModel.indices] : INDEX_LOOKUPS;
    selectedFilterModel.value.config = config;
    selectedFilterModel.value.savedSearchId = filterModel.savedSearchId;
    updateData(selectedFilterModel.value);
    // console.debug(`useSearchFilterActions.resetFilter().GET_SEARCH_DEFAULTS - { defaults, selectedFilterModel}`, filterModel, selectedFilterModel);
  };

  const updateFilter = async (model) => {
    _sendToGoogleTagManager();
    await store.dispatch(`search/${SEARCH_MODULE.GET_SEARCH_DEFAULTS}`, {
      userId: authenticatedUser.value.userId,
    });
    const filterModel = model;
    selectedFilterModel.value.filters = [...filterModel.filters];
    selectedFilterModel.value.indices = filterModel.indices?.length ? [...filterModel.indices] : INDEX_LOOKUPS;
    selectedFilterModel.value.savedSearchId = filterModel.savedSearchId;
    updateData(selectedFilterModel.value);
  };


  const _sendToGoogleTagManager = () => {
    event('search', {
      event_label: authenticatedUser.value.email,
      event_category: 'dashboard-configure',
    });
  };

  const _saveSession = () => {
    store.commit(`search/${SEARCH_MODULE.SET_CURRENT_SEARCH}`, {
      criteria: {
        filterModel: {
          savedSearchId: selectedFilterModel.value.savedSearchId,
          filters: [...selectedFilterModel.value.filters],
          indices: [...selectedFilterModel.value.indices]
        }
      }
    });
    // console.debug(`useSearchFilterActions._saveSession() - { selectedFilterModel }`, selectedFilterModel.value);
  };

  const _validateFilters = async () => {
    loading.value = true;
    submitted.value = true;
    const isValid = (await v$?.value.$validate()) ?? true;
    if (!isValid) {
      loading.value = false;
      // console.debug(`useSearchFilterAction._validateFilters() - { isValid, submitted }`, isValid, submitted.value);
    }
    return isValid;
  };


  return { searchFilters, searchActivityTypes, resetFilter, updateFilter };
}
