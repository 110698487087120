<template>
  <div class="flex align-items-center justify-content-between">
    <h2 class="flex align-items-center">
      {{ title }}
    </h2>
    <div class="pt-1">
      <cp-export-chart-options :chart="chartExport" @chart-updated="chartUpdated" />
    </div>
  </div>

  <div ref="chart" class="flex align-items-center pt-5" style="min-height: 300px">
    <ProgressSpinner />
  </div>
</template>

<script lang="ts">
import { ref, watch } from 'vue';
import {
  setDefaults,
  PieChartOptions,
  getPieChartConfig,
  getPieChartNoDataOptions,
  getColumnGradientBySource,
} from '@/utils/highchartUtils';
import { renderPieChart } from '@/plugins/highchart-extend';
import * as Highcharts from 'highcharts';
import ExportChartOptionsComponent from '@/components/common/ExportChartOptions.vue';
import { Chart } from 'highcharts';
import {
  mostActiveCosByTypeResult
} from '@/models/analytics/mostActiveCosByCompanyTypeResult';

export default {
  components: {
    'cp-export-chart-options': ExportChartOptionsComponent,
  },
  props: {
    data: { type: mostActiveCosByTypeResult }
  },
  setup(props) {
    const chart = ref(null);
    const chartExport = ref<Chart>();
    const tagsChartData = ref({});
    const title = ref('');

    const getTitle = () => {
      title.value = `Engagements by Org Type`;
    };

    getTitle();

    const renderChart = () => {
      setDefaults();

      const data = getSeriesData(props.data as mostActiveCosByTypeResult);

      const options: PieChartOptions = {
        field: 'ContentTheme',
        exportTitle: title.value,
        dataLabelFormat: '{point.name}: {point.y}',
        series: data,
        width: null
      };

      chartExport.value = (Highcharts as any).chart(
        chart.value,
        Object.keys(tagsChartData.value).length > 0
          ? getPieChartConfig(options)
          : getPieChartNoDataOptions(options),
        (chartInstance) => {
          if (Object.keys(tagsChartData.value).length <= 0) {
            renderPieChart(chartInstance, getPieChartNoDataOptions(options).lang.noDataImage);
          }
        }
      );
    };

    const getSeriesData = (result: mostActiveCosByTypeResult) => {

      tagsChartData.value = {
        ...result.data
          .reduce((acc, v) => ({ ...acc, ...{ [v.companyType]: v.activitiesCount } }), {}),
      };

      return [
        {
          type: 'pie',
          name: 'Engagements',
          colors: getColumnGradientBySource() as any,
          data: Object.keys(tagsChartData.value).map((k) => ({
            name: k,
            y: tagsChartData.value[k],
          })),
          innerSize: '50%',
          dataLabels: {
            enabled: true,
            format: '{point.name}: {point.percentage:.1f} %'
          },
          tooltip: {
            headerFormat: '',
            pointFormat:
              '<b>{series.name}</b>: {point.percentage:.1f}%',
          },
        },
      ];
    };

    watch(
      () => props.data,
      async () => {
        getTitle();
        renderChart();
      }
    );

    const chartUpdated = (value: Chart) => {
      chartExport.value = value;
      title.value = (chartExport as any).value.options.exporting.chartOptions.title.text;
      title.value = title.value.length > 32
        ? title.value.substring(0, 32) + '...  '
        : title.value;
    };

    return { title, chart, chartExport, chartUpdated };
  },
};
</script>
