/* eslint-disable no-useless-escape */
import { Filter, FieldCode } from '@/models/filters';
import { findFilter } from '../filters';

export function required(field: string | null = null) {
  return (v: string | string[]) => {
    if (!v || (Array.isArray(v) && !v.length)) {
      return `${field || 'This field'} Is Required`;
    }
    return true;
  };
}

export function conditionalFilter(filters: Filter[], filterName: FieldCode, validationMessage: string | null = null) {
  return () => {
    const filter = findFilter(filters, filterName);
    if (!validationMessage) {
      validationMessage = `${filter.field} is required`;
    }

    // if ((!v || (Array.isArray(v) && !v.length)) || filter?.isEmpty()) {
    if (filter?.isEmpty()) {
      return validationMessage;
    }
    return true;
  };
}

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
export function emailValid(v: string) {
  return EMAIL_REGEX.test(v) ? true : 'Invalid Email Address';
}

export const internalDomains: string[] = ['@vertmarkets.com'];
export function emailValidDomain(emails: string[]) {
  const domains = emails.map(x => x.split('@').pop());
  const EMAIL_DOMAIN_REGEX = domains.map(d => new RegExp(`^[A-Za-z0-9._%+-]+@${d}$`));

  return (v: string) =>
    EMAIL_DOMAIN_REGEX.some(r => r.test(v))
      ? true : `Email Doesn't Belong to Your Domain.`;
}

const GUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
export function GuidIdValid(field: string) {
  return (v: string) => GUID_REGEX.test(v) ? true : `${field} is not a valid GUID`;
}

export function EmailOrIdValid(v: string) {
  if (!GUID_REGEX.test(v) && !EMAIL_REGEX.test(v)) {
    return `Value is not a valid ID or Email`;
  }
  return true;
}


export function maxLength(field: string, maxLen: number) {
  return (v: string) => (v || '').length <= maxLen ? true : `${field} must not be longer than ${maxLen} characters.`;
}

export function minLength(field: string, minLen: number) {
  return (v: string) => (v || '').length >= minLen ? true : `${field} must be at least ${minLen} characters.`;
}

export function minValue(field: string, minVal: number) {

  return (v: number) => (v) <= minVal ? true : `${field} must be at least ${minVal}.`;
}

export function maxValue(field: string, maxVal: number) {
  return (v: number) => (v) >= maxVal ? true : `${field} must not be greater than ${maxVal}.`;
}

export function between(field: string, minVal: number, maxVal: number) {
  return (v: number) => !v || ((v) <= maxVal && (v) >= minVal) ? true : `${field} must be between ${minVal} and ${maxVal}.`;
}
