import { ref, Ref } from 'vue';
import DefaultFiltersService from '@/services/defaultFiltersService';
import { FilterConfigModel, FilterModel } from '@/models/search';
import savedSearchesService from '@/services/savedSearchesService';
import { savedSearchRow } from '@/models/saved-search/savedSearchModel';

export default function useDefaultFilterSecurity(userId: Ref<string>, searchId?: Ref<string>) {
  const data = ref<FilterModel>(<FilterModel>{ filters: [], indices: [], config: new FilterConfigModel({ excludeFields: ['DateRange', 'RelativeDateRange', 'ActivityTypes'] }) });

  const name = ref<string>();
  const description = ref<string>();

  const getData = async () => {
    let response: FilterModel;
    let query: savedSearchRow;

    if (searchId) {
      response = await DefaultFiltersService.getBySavedSearchId(userId.value, searchId.value);
      query = await savedSearchesService.getSavedSearch(userId.value, searchId.value);

      name.value = query?.name as string;
      description.value = query.description as string;
    }
    else
      response = await DefaultFiltersService.getDefault(userId.value);
    // console.debug(`useDefaultFilterSecurity.getData() - { response }`, response);
    data.value.filters = response.filters;
    data.value.indices = response.indices;
    data.value.savedSearchId = response.savedSearchId;
  };


  return { data, name, description, getData };
}
