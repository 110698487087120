import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-float-label" }
const _hoisted_2 = { for: "date" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createVNode(_component_Calendar, {
      id: "date",
      modelValue: $setup.local,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.local) = $event)),
      autocomplete: "off",
      "show-button-bar": true,
      "date-format": "mm/dd/yy",
      "number-of-months": $props.numberOfMonths,
      class: _normalizeClass({ 'p-invalid': $setup.v$.$invalid && $props.submitted }),
      "append-to": "self",
      onDateSelect: $setup.updateDate,
      onClearClick: $setup.clearDate,
      onBlur: $setup.handleManualDateChange
    }, null, 8, ["modelValue", "number-of-months", "class", "onDateSelect", "onClearClick", "onBlur"]),
    _createElementVNode("label", _hoisted_2, _toDisplayString($props.label), 1)
  ]))
}