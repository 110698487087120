<template>
  <form v-if="isMyProfile" ref="form" @submit.prevent="save">
    <div class="grid pb-0">
      <div class="col-6">
        <Message :closable="false">
          Please contact your representative for help with your profile.
        </Message>
      </div>
      <div v-if="loggedInUserAtLeastInternal" class="col-6 flex flex-row-reverse align-items-center">
        <Button type="submit" class="p-button-success p-button-raised p-button-rounded ml-3" label="Save" />

        <div v-if="saveSuccess" class="success-message flex p-0 m-0 pr-2">
          Saved.
          <span class="material-icons-outlined pl-1"> check_circle_outline </span>
        </div>
      </div>
    </div>

    <cp-profile-account-information v-model="user" :is-editable="loggedInUserAtLeastInternal" :submitted="submitted"
      :v="v$.user" />
    <Divider />
    <cp-profile-api-details v-if="user.enableApiAccess" v-model="user" />
    <Divider v-if="user.enableApiAccess" />
    <cp-profile-client-details v-if="!loggedInUserAtLeastInternal" v-model="user" />
    <cp-filter-options v-if="loggedInUserAtLeastInternal" v-model="user.activityFields" :submitted="submitted"
      :user-role="user.role" :v="v$.user.companies" />
  </form>
</template>
<script lang="ts">
import { PropType, ref, toRef } from 'vue';
import useVuelidate from '@vuelidate/core';

import { AppUser } from '@/models/appUser';

import ApiDetails from '@/components/api/ApiDetails.vue';
import ProfileAccountInformation from '@/components/profile/AccountInformation.vue';
import ProfileClientDetails from '@/components/profile/ClientDetails.vue';
import FilterOptions from '@/components/admin/FilterOptions.vue';

import useUserSecurity from '@/use/userSecurity';
import useUserActions from '@/use/userActions';

export default {
  name: 'CpMyProfile',
  components: {
    'cp-filter-options': FilterOptions,
    'cp-profile-account-information': ProfileAccountInformation,
    'cp-profile-api-details': ApiDetails,
    'cp-profile-client-details': ProfileClientDetails
  },
  props: {
    modelValue: { type: Object as PropType<AppUser>, required: true }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const saveSuccess = ref(false);
    const submitted = ref(false);
    const { user, userRoleAtLeastInternal, loggedInUserAtLeastInternal, isMyProfile, validations } = useUserSecurity(toRef(props, 'modelValue'));
    const v$ = useVuelidate(validations, { user });
    const { updateUser } = useUserActions(user, v$, saveSuccess, submitted, isMyProfile);

    async function save() {
      await updateUser();
      console.debug(`ManageUserProfile.save() - user`, user.value);
      emit('update:modelValue', user.value);
    }

    return { user, userRoleAtLeastInternal, loggedInUserAtLeastInternal, isMyProfile, save, submitted, saveSuccess, v$ };
  }
};

</script>