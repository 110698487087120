import jsPDF from 'jspdf';

export function createJsPdf(numberOfRouteParams = 0): jsPDF {
    const doc = new jsPDF('p', 'pt', 'a4');
    
    let rootPath = '..';
    for (let i= 0; i < numberOfRouteParams; i++) {
      rootPath += '/..';
    }
    
    doc.addFont(`${rootPath}/themes/vert-analytics/vert-analytics-light/fonts/Overpass-Regular.ttf`, 'Overpass', 'normal');
    doc.addFont(`${rootPath}/themes/vert-analytics/vert-analytics-light/fonts/Overpass-Medium.ttf`, 'Overpass', 'medium');
    doc.addFont(`${rootPath}/themes/vert-analytics/vert-analytics-light/fonts/Overpass-Bold.ttf`, 'Overpass', 'bold');
    doc.addFont(`${rootPath}/themes/vert-analytics/vert-analytics-light/fonts/Overpass-ExtraBold.ttf`, 'Overpass', 'extrabold');
    doc.addFont(`${rootPath}/themes/vert-analytics/vert-analytics-light/fonts/Overpass-Black.ttf`, 'Overpass', 'black');

    return doc;
}
