<template>
  <div class="flex justify-content-between">
    <h2 class="flex align-items-center">
      <Dropdown v-if="showOptions" v-model="category" :options="categories" option-label="name"
        :disabled="!categoryMenuEnabled" class="p-dropdown-noborder pl-1" @change="changeCategory($event.value)">
        <template #value="slotProps">
          <h2>{{ slotProps.value.name }}</h2>
        </template>
      </Dropdown>
    </h2>
    <div class="pt-2">
      <cp-export-chart-options :chart="chartExport" @chart-updated="chartUpdated" />
    </div>
  </div>

  <div ref="chart" v-cp-color-picker class="flex align-items-center pt-3" style="min-height: 300px">
    <ProgressSpinner />
  </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import ReaderCoService from '@/services/readerCoService';
import {
  setDefaults,
  getChartColor,
  getPieChartConfig,
  PieChartOptions, getPieChartNoDataOptions,
} from '@/utils/highchartUtils';
import { Chart } from 'highcharts';
import { Lookup } from '@/models/lookup';
import { NamedSeries } from '@/models/namedSeries';
import ExportChartOptionsComponent from '../common/ExportChartOptions.vue';
import { cloneDeep, isEqual } from 'lodash';
import { EngagementCategory } from '@/models/analytics/engagementCategory';
import { filterValueExists } from '@/utils/filters';
import { FilterModel } from '@/models/search';
import { FieldCode, Filter } from '@/models/filters';
import { Vue, Options } from 'vue-class-component';
import Highcharts from 'highcharts';
import { renderPieChart } from '@/plugins/highchart-extend';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
NoDataToDisplay(Highcharts);

@Options({
  components: {
    'cp-export-chart-options': ExportChartOptionsComponent,
  },
})
export default class EngagementsByCategory extends Vue {
  @Prop({ default: () => <FilterModel>{ filters: [], indices: [] } })
  public filterModel: FilterModel;
  @Prop({ default: () => <Filter>{ field: 'ReaderOrgs' } }) public orgFilter: Filter;
  data1: NamedSeries[] = [];
  declare $refs: { chart: HTMLDivElement };
  chartExport: Chart | null = null;
  loading = true;
  showTitle: string;
  categories: Array<Lookup<EngagementCategory>> = [
    { id: 'DocumentTypeName', name: 'Engagements By Document Type' },
    { id: 'BuyersJourneyStage', name: 'Engagements By Buyer\'s Journey' },
    { id: 'Onlines', name: 'Engagements By Community' },
  ];
  category: Lookup<EngagementCategory> = this.categories[0];
  showOptions: boolean = true;

  // mounted() {
  //   if (this.filterModel) {
  //     this.filtersChanged(this.filterModel);
  //   }
  // }

  @Watch('filterModelWithOrg', { immediate: true })
  async filtersChanged(
    newVal: FilterModel,
    oldVal: FilterModel = { filters: [], indices: [] }
  ) {
    const noChanges = isEqual(newVal, oldVal);
    if (!newVal || noChanges || !filterValueExists(newVal.filters, 'Clients')) {
      return;
    }
    this.loading = true;
    this.data1 = await ReaderCoService.getEngagementsByCategory(newVal, this.category.id);
    this.loading = false;

    this._renderChart();
  }

  get filterModelWithOrg() {
    const filterModel = cloneDeep(this.filterModel);
    filterModel.filters.push(this.orgFilter);
    return filterModel;
  }

  async changeCategory(category: Lookup<EngagementCategory>) {
    // console.log(category);

    this.category = category;
    this.loading = true;
    this.data1 = await ReaderCoService.getEngagementsByCategory(
      this.filterModelWithOrg,
      this.category.id
    );
    this.loading = false;
    this._renderChart();
  }

  get categoryMenuEnabled(): boolean {
    return filterValueExists(this.filterModel.filters, 'Clients');
  }

  get title() {
    this.showTitle = 'Engagements By ';
    return this.showTitle;
  }

  chartUpdated(value: Chart) {
    this.chartExport = value;
    const index = this.categories.findIndex(item => item.id === this.category.id);
    let chartName = (this.chartExport as any).options.exporting.chartOptions.title.text;
    chartName = chartName.length > 40
        ? chartName.substring(0, 40) + '...  '
        : chartName;
    this.categories[index].name = chartName;
    this.$forceUpdate();
  }

  titleTrim() {
    if (this.showTitle == undefined) {
      this.showTitle = 'Engagements By';
    }
    else {
      this.showTitle = this.showTitle.length > 40
        ? this.showTitle.substring(0, 40) + '...  '
        : this.showTitle;
    }
    return this.showTitle;
  }

  private _renderChart() {
    setDefaults();

    const series = this._getSeriesData();
    const options: PieChartOptions = <PieChartOptions>{
      field: <FieldCode>this.category.id,
      exportTitle: this.category.name,
      dataLabelFormat: '{point.name}: {point.y}',
      series,
    };

    this.chartExport = (Highcharts as any).chart(this.$refs.chart, this.data1.length > 0 ? {
      ...getPieChartConfig(options)
    } : getPieChartNoDataOptions(options),
      (chartInstance) => { // on complete
        if (this.data1.length < 1) {
          renderPieChart(chartInstance, getPieChartNoDataOptions(options).lang.noDataImage);
        }
      });
  }

  private _getSeriesData() {
    return [
      {
        type: 'pie',
        name: 'Engagements',
        data: this.data1.map((x, index) => ({
          name: x.name,
          y: x.value,
          color: getChartColor(<FieldCode>this.category.id, index, x.name),
        })),
        innerSize: '50%',
      },
    ];
  }
}
</script>

<style scoped lang="scss">
.v-sheet {
  z-index: 0;
}
</style>
