<template>
  <div class="grid w-full mt-2">
    <div class="col-5 p-3">
      <Message :closable="false">
        Tell Us About Your Company
      </Message>
    </div>

    <div class="col-12 description">
      The form below may be prepopulated with the current information we have on file for your company. 
      This contact information will appear on your external company profile page on our site(s). 
      Please review this information, and make any necessary changes.
    </div>

    <div class="col-12 lg:col12 p-3">
      <span class="p-float-label p-fluid">
        <ValidatedInputText :id="'name'" v-model="data1.name" :field-name="'name'" :display-label="'Company Name'"
          :preferred-field-name="'Company Name'" />
      </span>
    </div>
    <div class="col-12 lg:col-4 p-3">
      <span class="p-float-label p-fluid">
        <ValidatedInputText :id="'contactName'" v-model="data1.contactName" :field-name="'Contact Name'"
          :display-label="'Contact Name'" :preferred-field-name="'Contact Name'" />
      </span>
    </div>
    <div class="col-12 lg:col-4 p-3">
      <span class="p-float-label p-fluid">
        <ValidatedInputText :id="'contactTitle'" v-model="data1.contactTitle" :field-name="'contactTitle'"
          :display-label="'Contact Title'" :preferred-field-name="'Contact Title'" />
      </span>
    </div>
    <div class="col-12 lg:col-4 p-3">
      <span class="p-float-label p-fluid">
        <InputText id="phoneNumber" v-model="data1.phone" type="text" />
        <label for="phoneNumber">Phone Number</label>
      </span>
    </div>
    <div class="col-12 lg:col12 p-3">
      <span class="p-float-label p-fluid">
        <ValidatedInputText :id="'contactTitle'" :model-value="data1.address1" :field-name="'address'"
          :display-label="'Address'" :preferred-field-name="'Address'" />
      </span>
    </div>

    <div class="col-12 lg:col-4 p-3">
      <span class="p-float-label p-fluid">

        <ValidatedInputText :id="'city'" v-model="data1.city" :field-name="'city'" :display-label="'City'"
          :preferred-field-name="'City'" />
      </span>
    </div>
    <div class="col-12 lg:col-4 p-3">
      <span class="p-float-label p-fluid">
        <ValidatedInputText :id="'state'" v-model="data1.state" :field-name="'state'" :display-label="'State'"
          :preferred-field-name="'State'" />
      </span>
    </div>
    <div class="col-12 lg:col-4 p-3">
      <span class="p-float-label p-fluid">
        <InputText id="postalCode" v-model="data1.postalCode" type="text" />
        <label for="postalCode">Zip Code</label>
      </span>
    </div>
    <div class="col-12 lg:col-12 p-3">
      <span class="p-float-label p-fluid">
        <!-- <Textarea id="profile" v-model="data1.profile" type="text" /> -->
        <ckeditor id="profile" v-model="data1.profile" :editor="editor" :config="editorConfig" />
        <label for="profile" class="ckeditor-label">Company Profile</label>
        <small class="hint">A description of your company that would meet the requirements for an “About Us”. 
          You may write directly in the field, copy and paste, or provide a link to the information.</small>
      </span>
    </div>
    <div class="col-12 lg:col-6 p-3">
      <span class="p-float-label p-fluid">
        <InputText id="profilePageContact" v-model="data1.profilePageContact" type="text" />
        <label for="profilePageContact">Profile Page Contact</label>
        <small class="hint">A contact person to be listed on your company profile page.</small>
      </span>
    </div>
    <div class="col-12 lg:col-6 p-3">
      <span class="p-float-label p-fluid">
        <InputText id="companyWebsite" v-model="data1.companyWebsite" type="text" />
        <label for="companyWebsite">Company Website</label>
        <small class="hint">A URL to your website to be an active link on your profile page.</small>
      </span>
    </div>
    <div class="col-12 lg:col-12 p-3">
      <cp-multiple-file-upload 
        :file-url="data1.fileUrl" 
        hint="A high-resolution file for your company logo to appear on your profile page. 
        JPEG or PNG work best. You may also share a link that allows download access to the file(s)." accept="image/*" @update:file-url="updateFileURL" @files-selected="handleFilesSelected" />
    </div>
    <div v-if="uploadedLogos.length > 0" class="col-12 lg:col-12 p-3 pt-0">
      <div class="card">
        <Panel header="Uploaded logos">
          <div v-for="(file, index) in uploadedLogos" :key="index" class="flex align-items-center justify-content-center">
            <div class="col-2">
              <img :src="file.logoUrl" role="presentation" height="50" style="width:100%;object-fit: contain;" class="shadow-2">
            </div>
            <div class="col-9">
              <span class="font-semibold">{{ file.logoUrl }}</span>
            </div>
            <div class="col-1 flex align-items-center justify-content-center">
              <Button icon="pi pi-times" severity="danger" text rounded aria-label="Remove" @click="showRemoveConfirmation($event, file.id)" />
            </div>
          </div>
        </Panel>
      </div>
    </div>
  </div>

  <div style="float:right;margin-top:10px">
    <Button ref="goForward" label="Save and Continue" type="button" class="px-4 p-button-success p-button-raised"
      @click="nextStep" />
    <br><br>
  </div>
</template>

<script lang="ts">
import axios from 'axios';
import MultipleFileUpload from '@/components/common/MultipleFileUpload.vue';
import ValidatedInputText from '@/components/common/ValidatedInputText.vue';
import { onMounted, reactive, ref } from 'vue';
import useValidationErrors from '@/utils/validators/useValidationErrors';
import useFormValidation from '@/utils/validators/useFormValidation';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { onError, onSuccess } from '@/utils/toast/toastUtils';
import Editor from '@vert/ckeditor5-custom';
import CKEditor from '@ckeditor/ckeditor5-vue';

interface CompanyInfo {
  id: string; 
  companyId: number;
  name: string;
  address1: string; 
  city: string; 
  state: string; 
  postalCode: string; 
  phone: string;
  contactName: string; 
  contactTitle: string;
  profile: string;
  profilePageContact: string;
  companyWebsite: string;
  fileUrl: string;
}

interface LogoUrl { id: number; logoUrl: string; }

export default {
  components: {
    ValidatedInputText,
    ckeditor: CKEditor.component,
    'cp-multiple-file-upload': MultipleFileUpload
  },

  props: {
    formId: String,
  },
  emits: ['next-page', 'step-completed'],
  setup(props, { emit }) {

    const companyInfo = ref<CompanyInfo>({
      id: '',
      companyId: 0,
      name: '',
      address1: '',
      city: '',
      state: '',
      postalCode: '',
      phone: '',
      contactName: '',
      contactTitle: '',
      profile: '',
      profilePageContact: '',
      companyWebsite: '',
      fileUrl: ''
    });
    const data1 = reactive(companyInfo);
    let submitted = ref(false);
    let selectedLogos: File[] = [];
    const uploadedLogos = ref<LogoUrl[]>([]);
    const toast = useToast();
    const confirm = useConfirm();
    const editor = Editor;
    const editorConfig = {
      toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
      heading: {
        options: [
          { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
          { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
          { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
          { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
        ]
      }
    };

    onMounted(async () => {
      getCompanyInfo();
      getUploadedLogos();
    });

    const getCompanyInfo = async () => {
      const url = `/api/onboarding/${props.formId}/company-info`;
      companyInfo.value = data1.value;
      data1.value = (await axios.get<CompanyInfo>(url)).data as any;
    };

    const getUploadedLogos = async () => {
      const url = `/api/onboarding/${props.formId}/company-info/logos/list`;
      uploadedLogos.value = (await axios.get<LogoUrl[]>(url)).data;
    };

    const handleFilesSelected = (files: File[]) => {
      selectedLogos = files;
    };

    const updateFileURL = (newValue: string) => {
      data1.value.fileUrl = newValue;
    };

    const hasErrors = () => {
      const { errors } = useFormValidation();
      return useValidationErrors(data1.value, errors);
    };

    const save = async () => {
      try {
        const url = `/api/onboarding/${props.formId}/company-info`;
        const fd = new FormData();
        const data: any = data1.value || {};
        Object.keys(data).forEach(k => fd.append(k, data[k]));
        const opts = { headers: { 'Content-Type': 'multipart/form-data' } };
        await (await axios.patch<CompanyInfo>(url, fd, opts)).data;
      }
      catch (error) {
        console.log(error);
        throw (error);
      }
    };

    const saveLogos = async () => {
      try {
        const url = `/api/onboarding/${props.formId}/company-info/logos`;
        const fd = new FormData();
        selectedLogos.forEach(l => fd.append('logos', l));
        const opts = { headers: { 'Content-Type': 'multipart/form-data' } };
        const res = await axios.post<LogoUrl[]>(url, fd, opts);
        uploadedLogos.value = [...uploadedLogos.value, ...res.data];
      }
      catch (error) {
        console.log(error);
        throw (error);
      }
    };

    const removeLogo = async (id: number) => {
      try {
        const url = `/api/onboarding/${props.formId}/company-info/logos/${id}`;
        await axios.delete(url);
        onSuccess(toast, 'The logo has been deleted');
        getUploadedLogos();
      }
      catch (error) {
        onError(toast, 'There was an error deleting the logo');
        throw (error);
      }
    };

    const showRemoveConfirmation = async (event, id: number) => {
      confirm.close();
      confirm.require({
        target: event.currentTarget,
        message: 'Are you sure you want to delete this logo?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Continue',
        rejectLabel: 'Cancel',
        accept: () => {
          removeLogo(id);
        },
        reject: () => null
      });
    };

    const nextStep = () => {
      if (!hasErrors()) {
        save();
        saveLogos();
        emit('next-page', { pageIndex: 2 });
        emit('step-completed', 2);
      }
    };

    return { nextStep, data1, submitted, uploadedLogos, selectedLogos, editor, editorConfig,
      save, hasErrors, handleFilesSelected, updateFileURL, removeLogo, showRemoveConfirmation };
  }
};
</script>

<style lang="scss" scoped>
.intro {
  margin-bottom: 10px;
}
.p-button-icon-only {
  border-radius: 50%;
  background-color: transparent;
  color: #EF4444;
  border-color: transparent;
}
.p-button-icon-only:hover {
  background: rgba(239, 68, 68, 0.04);
}
.ckeditor-label {
  top: -0.5rem !important;
  background-color: #ffffff;
  padding: 0 4px;
  margin-left: -4px;
  margin-top: 0;
  font-size: 0.813rem !important;
  font-weight: 800;
  color: #1b3557;
  z-index: 1;
}
</style>
