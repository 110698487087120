import { UserRole } from '@/models/userRole';
import { NotificationTarget } from './notificationTarget';
export class DashboardTarget implements NotificationTarget {
  userId: string;
  name: string;
  email: string;
  isEmailSubscribed: boolean;
  readonly $type: string = 'DashboardTarget';
  role: UserRole;
  isRegistered: boolean;

  get displayText(): string {
    return `${this.name} <${this.email}>`;
  }

  public get isRegisteredClient(): boolean {
    return this.isRegistered && this.role === 'Client';
  }

  constructor(opts: Partial<DashboardTarget> = {}) {
    Object.assign(this, opts);
    this.$type = 'DashboardTarget';
  }
}
