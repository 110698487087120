<template>
  <div class="grid fluid">
    <div class="lg:flex col-12 pb-0 pt-4">
      <div class="col-12 md:col-12 lg:col-4 pb-2">
        <h3 class="pb-4">
          Greetings!
        </h3>
        <p class="description">
          The purpose of this form is to launch your Audience Access program with Life Science Connect. 
          After you click to continue, you’ll be asked to share information to set up your company profile page, including content. 
          If you have any questions, please contact your representative.
        </p>

        <div class="pb-6" />
        <div class="pb-4">
          <Button v-if="step !== 5" ref="goForward" label="Click here to Continue" type="button"
            class="px-4 p-button-success p-button-raised " @click="nextStep" />
        </div>
      </div>

      <div class="col-12 md:col-12 lg:col-8">
        <img alt="begin" :src="beginPng.img" class="begin">
      </div>
    </div>
  </div>
</template>

<script lang="ts">


export default {
  emits: ['next-page', 'step-completed'],
  setup(props, { emit }) {
    const beginPng = {
      img: require('../../assets/onboarding/images/onboarding-begin.png')
    };
    const nextStep = () => {

      emit('next-page', { pageIndex: 0 });
      emit('step-completed', 0);
    };

    return { nextStep, beginPng };
  }

};
</script>
<style scoped>
.begin {
  width: 50vw;
  float: right;
}

.begin-text {
  text-align: left;
  font: normal normal normal 16px/24px Overpass;
  letter-spacing: 0px;
  color: #7D8C9F;
  opacity: 1
}
</style>
