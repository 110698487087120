<template>
  <div class="flex justify-content-between">
    <h2>
      {{ titleTrim() }}
      <!-- <span class="help-icon ml-2">?</span> -->
    </h2>
    <div class="pt-2">
      <cp-export-chart-options :chart="chartExport" @chart-updated="chartUpdated" />
    </div>
  </div>
  <div ref="chart" cp-color-picker class="flex align-items-center pt-3">
    <ProgressSpinner />
  </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import ReaderCoService, { EngagementsByBuyersJourney } from '@/services/readerCoService';
import { Chart } from 'highcharts';
import {
  getSolidColorPerSliceByBuyerJourneyStage,
  getSplineChartConfig,
  setDefaults,
  LineChartOptions, getSplineChartNoDataOptions,
} from '@/utils/highchartUtils';
import { cloneDeep, isEqual } from 'lodash';
import { filterValueExists } from '@/utils/filters';
import { FilterModel } from '@/models/search';
import { Filter } from '@/models/filters';
import ExportChartOptionsComponent from '../common/ExportChartOptions.vue';
import { Vue, Options } from 'vue-class-component';
import { BUYERS_JOURNEY_MAP } from '@/models/buyersJourney';
import Highcharts from 'highcharts';
import { renderSplineChart } from '@/plugins/highchart-extend';

@Options({
  components: {
    'cp-export-chart-options': ExportChartOptionsComponent,
  },
})
export default class EngagementsByMonth extends Vue {
  @Prop({ default: () => <FilterModel>{ filters: [], indices: [] } })
  public filterModel: FilterModel;
  @Prop({ default: () => <Filter>{ field: 'ReaderOrgs' } }) public orgFilter: Filter;
  data1: EngagementsByBuyersJourney[] = [];
  declare $refs: { chart: HTMLDivElement };
  chartExport: Chart | null = null;
  loading = true;
  showTitle: string = '';

  @Watch('filterModelWithOrg', { immediate: true })
  async filterChanged(newVal: FilterModel, oldVal: FilterModel) {
    const noChanges = isEqual(newVal, oldVal);

    if (!newVal || noChanges || !filterValueExists(newVal.filters, 'Clients')) {
      return;
    }
    this.loading = true;
    this.data1 = await ReaderCoService.getEngagementsByBuyersJourney(newVal);

    this.loading = false;
    this._renderChart();
  }

  get filterModelWithOrg() {
    const filterModel = cloneDeep(this.filterModel);
    filterModel.filters.push(this.orgFilter);
    return filterModel;
  }

  get title() {
    this.showTitle = 'Engagements and Buyer\'s Journey';
    return this.showTitle;
  }


  getChartData(data) {
    return data.map(
      (item: any): EngagementsByBuyersJourney => ({
          name: BUYERS_JOURNEY_MAP[item.name],
          month: item.month,
          data: item.data,
          color: getSolidColorPerSliceByBuyerJourneyStage(BUYERS_JOURNEY_MAP[item.name]),
          marker: {
            symbol: 'circle',
            fillColor: '#FFFFFF',
            lineWidth: 1,
            radius: 3,
            lineColor: null,
          },
        })
    );
  }

  private _renderChart() {
    setDefaults();
    const xAxisLegend = this.data1.length > 0 ? this.data1[0].month : null;
    const series = this.getChartData(this.data1);
    const options: LineChartOptions = <LineChartOptions>{
      xAxisLegend,
      yAxisTitle: 'Engagements',
      exportTitle: this.title,
      series,
    };

    this.chartExport = (Highcharts as any).chart(
      this.$refs.chart,
      this.data1.length > 0 ? getSplineChartConfig(options) : getSplineChartNoDataOptions(options), (chartInstance) => { // on complete
        if (this.data1.length < 1) {
          renderSplineChart(chartInstance, getSplineChartNoDataOptions(options).lang.noDataImage);
        }
      });
  }

  chartUpdated(value: Chart) {
    this.chartExport = value;
    this.showTitle = (this.chartExport as any).options.exporting.chartOptions.title.text;
    this.$forceUpdate();
  }

  titleTrim() {
    if (this.showTitle == undefined) {
      this.showTitle = 'Engagements and Buyer\'s Journey';
    }
    else {
      this.showTitle = this.showTitle.length > 32
        ? this.showTitle.substring(0, 32) + '...  '
        : this.showTitle;
    }
    return this.showTitle;
  }
}
</script>
