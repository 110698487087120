import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueGtag from 'vue-gtag';
import {useRouteGuard} from './router/routeGuard';
import {
    useRequestInterceptor,
    useJwtInterceptor,
    useLoadingBarInterceptor,
    useErrorResponseInterceptor,
} from './utils/auth/axiosInterceptors';
import {enableHighchartsExporting, setupPrintScaling} from './utils/highchartUtils';
import LoggingApiService, {ErrorRequestData} from '@/services/loggingApiService';
import '@/utils/filters';
import {detect} from 'detect-browser';
import './styles/main.scss';

import PrimeVue from 'primevue/config';
import definePlugin from './plugins/plugins';

import 'primeicons/primeicons.css';
import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';

import VueSecureHTML from 'vue-html-secure';

const app = createApp(App);

app.config.errorHandler = async (err, vm, info) => {
    const browser: Record<string, any> = detect() || {name: navigator.userAgent, version: null, os: null};
    const userAgent = [browser.name, browser.version, browser.os].filter(x => x).join(' ');

    const reqData: ErrorRequestData = {
        eventInfo: info,
        stackTrace: (err as Error).stack,
        path: window.location.href,
        userAgent
    };

    await LoggingApiService.sendErrorToApi(reqData);
    throw (err);
};

useRequestInterceptor();
useJwtInterceptor(store.state.auth);
useErrorResponseInterceptor();
useLoadingBarInterceptor(store.commit);

enableHighchartsExporting();
setupPrintScaling();

useRouteGuard(router, store);

app.use(router);
app.use(store);
app.use(PrimeVue, {ripple: true});
app.use(VueGtag, {
    config: {
        id: 'G-9L6HYRH1E4',
        params: {
            send_page_view: true,
            userId: store.state.auth.user?.userId
        }
    }
}, router);
app.use(VueSecureHTML);
definePlugin(app);
app.mount('#app');
