<template>
  <form v-if="!isMyProfile" ref="form">
    <div class="flex px-4 pb-2">
      <div class="col-6" />
      <div class="col-6 flex flex-row-reverse align-items-center">
        <Button class="p-button-success p-button-raised p-button-rounded ml-3" label="Save" @click="save($event)" />
        <div v-if="saveSuccess" class="success-message flex p-0 m-0 pr-2">
          Saved.
          <span class="material-icons-outlined pl-1"> check_circle_outline </span>
        </div>
      </div>
    </div>
    <cp-profile-account-information v-model="user" :submitted="submitted" :v="v$.user" />
    <Divider />
    <cp-profile-client-settings v-if="!userRoleAtLeastInternal" v-model="user.companies" v-model:submitted="submitted"
      v-model:communities="user.communities" label-display-mode="columns" :v="v$.user.companies" />
    <Divider v-if="!userRoleAtLeastInternal" />
    <cp-filter-options v-if="loggedInUserAtLeastInternal" v-model="user.activityFields"
      v-model:restrictedAnalystTagValues="user.analystTags" v-model:userRole="user.role" v-model:v="v$.user"
      :submitted="submitted" />
    <Divider />
    <cp-profile-api-settings v-if="loggedInUserAdmin" v-model="user" />
    <Divider />
    <cp-profile-notification-settings v-model="user" />
    <Divider />
    <div class="grid w-full p-0 my-2">
      <div v-if="loggedInUserAdmin && userRoleAtLeastInternal" class="col-12 md:col-6 lg:col-2 flex align-items-center">
        <Checkbox v-model="user.reviewActivities" :binary="true" />
        <label class="p-secondary-color ml-2">Enable Review Activities</label>
      </div>
      <div class="col-12 md:col-6 lg:col-3 flex align-items-center">
        <Checkbox v-model="user.accessMarketingPerformance" :binary="true" />
        <label class="p-secondary-color ml-2">Enable Marketing Performance</label>
      </div>
    </div>
  </form>
</template>
<script lang="ts">
import { PropType, ref, toRef } from 'vue';
import useVuelidate from '@vuelidate/core';

import { AppUser } from '@/models/appUser';

import ApiSettings from '@/components/profile/ApiSettings.vue';
import FilterOptions from '@/components/admin/FilterOptions.vue';
import ProfileAccountInformation from '@/components/profile/AccountInformation.vue';
import ProfileClientSettings from '@/components/profile/ClientSettings.vue';
import ProfileNotificationSettings from '@/components/profile/NotificationSettings.vue';

import useUserSecurity from '@/use/userSecurity';
import useUserActions from '@/use/userActions';
import { useConfirm } from 'primevue/useconfirm';

export default {
  name: 'CpManageUserProfile',
  components: {
    'cp-filter-options': FilterOptions,
    'cp-profile-account-information': ProfileAccountInformation,
    'cp-profile-api-settings': ApiSettings,
    'cp-profile-client-settings': ProfileClientSettings,
    'cp-profile-notification-settings': ProfileNotificationSettings,
  },
  props: {
    modelValue: { type: Object as PropType<AppUser>, required: true },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const saveSuccess = ref(false);
    const submitted = ref(false);
    const emailInitial = ref(props.modelValue.email);
    const {
      user,
      userRoleAtLeastInternal,
      loggedInUserAtLeastInternal,
      loggedInUserAdmin,
      isMyProfile,
      validations,
    } = useUserSecurity(toRef(props, 'modelValue'));
    const v$ = useVuelidate(validations, { user });
    const { updateUser } = useUserActions(user, v$, saveSuccess, submitted, isMyProfile);
    const confirm = useConfirm();

    const save = async (event) => {
      if (emailInitial.value !== user.value.email) {
        confirm.require({
          target: event.currentTarget,
          message: 'Are you sure you want to change email address?',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'Continue',
          rejectLabel: 'Cancel',
          accept: async () => {
            await _updateUser();
          },
          reject: () => {
            user.value.email = emailInitial.value;
          },
        });
      }
      else {
        await _updateUser();
      }
    };

    const _updateUser = async () => {
      await updateUser();
      emailInitial.value = user.value.email;
      emit('update:modelValue', user.value);
    };

    return {
      user,
      userRoleAtLeastInternal,
      loggedInUserAtLeastInternal,
      loggedInUserAdmin,
      isMyProfile,
      save,
      submitted,
      saveSuccess,
      v$,
    };
  },
};
</script>
