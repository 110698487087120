import { EngagementSearchResultItem } from './engagementSearchResultItem';
import { BUYERS_JOURNEY_MAP } from '../buyersJourney';
import { fullAddress } from '@/utils/address/addressUtils';
import { Address } from '../user-service/address';

export class EngagementSearchDetails extends EngagementSearchResultItem {
  advertiser: string;
  declare orgId: string;
  declare orgName: string;
  newsletterName: string;
  address1: string;
  address2: string;
  phone: string;
  zip: string;
  city: string;
  state: string;
  country: string;
  docId?: string;
  portalUrl: string;
  industry: string;
  declare quality: string;
  consentToPrivacyDate: Date;
  linkedInUrl: string;

  constructor(data: Partial<EngagementSearchDetails> | null = null) {
    super(data);
    if (data) {
      Object.assign(this, data);
    }
  }

  get buyersJourneyName(): string {
    if (!this.buyersJourney) {
      return '';
    }
    return BUYERS_JOURNEY_MAP[this.buyersJourney];
  }


  get formattedAddr(): string {
    return fullAddress(<Address>{
      address1: this.address1,
      address2: this.address2,
      city: this.city,
      state: this.state,
      zip: this.zip,
      country: this.country
    });
  }

  get engagementSource(): string {
    return this.newsletterName || 'Organic (Web)';
  }
}
