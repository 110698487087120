import { Online } from '@/models/lookup';
import commonLookupService from '@/services/commonLookupService';
import { Ref, ref } from 'vue';

export default function useOnlineSecurity(selections: Ref<Online[]>) {
  const data = ref<Array<Online>>([]);
  const loading = ref(false);
  const error = ref<any>(null);

  const options = ref<Online[]>([]);

  const getOptions = async() => {
    const response = await commonLookupService.getOnlines();
    options.value = response;
    console.debug(`useOnlineSecurity.getOptions() - { options }`, options.value);
  };

  const getData = async (q: string) => {
    loading.value = true;
    data.value = [];
    error.value = null;
    // if (!q?.length) {
    //   loading.value = false;
    //   return;
    // }

    try {
      if (!q?.trim().length) {
        data.value = options.value.filter(o => selections.value.findIndex(s => s.name == o.name) === -1);
      }
      else {
        const notSelected = options.value.filter(o => selections.value.findIndex(s => s.name == o.name) === -1);
        data.value = notSelected.filter(n => n.name.toLowerCase().startsWith(q.toLowerCase()));
      }

      console.debug(`useOnlineSecurity.getData() - { filteredOptions }`, data.value);
    } catch (err) {
      error.value = err;
    }

    loading.value = false;
  };

  const removeData = async (value: Online) => {
    loading.value = true;
    error.value = null;

    try {
      const findIndex = selections.value.findIndex(o => o.id == value.id);
      selections.value.splice(findIndex, 1);
    } catch (err) {
      error.value = err;
    }
    loading.value = false;
  };

  return { data, loading, error, getData, removeData, getOptions };
}