import { Claim } from '@/models/security/claim';

export const NOTIFICATION_ROUTES = [
  {
    path: '/notifications/search',
    name: 'notifications-search',
    meta: { auth: new Claim('manageNotifications', 'Basic') },
    component: () => import(/* webpackChunkName: "notifications" */ '@/views/notifications/SearchNotifications.vue')
  },
  {
    path: '/notifications/dashboard/:id/:runId',
    name: 'notification-dashboard-details',
    component: () => import(/* webpackChunkName: "notifications" */ '@/views/notifications/NotificationRunDetails.vue'),
    meta: { auth: new Claim('viewNotifications') }
  },
  {
    path: '/notifications/dashboard',
    name: 'notifications-dash',
    component: () => import(/* webpackChunkName: "notifications" */ '@/views/notifications/NotificationsDashboard.vue'),
    meta: { auth: new Claim('viewNotifications') }
  },
  {
    path: '/notification/',
    name: 'notification-new',
    component: () => import(/* webpackChunkName: "notifications" */ '@/views/notifications/NotificationStepper.vue'),
    meta: { auth: new Claim('manageNotifications', 'Basic') }
  },
  {
    path: '/notification/:id/:step?',
    name: 'notification-form',
    component: () => import(/* webpackChunkName: "notifications" */ '@/views/notifications/NotificationStepper.vue'),
    meta: { auth: new Claim('manageNotifications', 'Basic') }
  }
];
