<template>
  <div class="bg-white">
    <Dialog v-model:visible="showDialog" :closable="false"
      :style="disabled ? 'border-color:#FF0000' : 'border-color:#ffb061'" class="w-9">
      <template #header>
        <div class="flex col-12 pb-0 mt-2">
          <div class="flex col-11 pb-0">
            <h2 :class="{ 'p-error': disabled }">
              {{ headerText }}
            </h2>
          </div>
          <div class="flex flex-row-reverse col-1 pb-0">
            <i class="pi pi-times reverse" aria-haspopup="true" aria-controls="overlay_menu" @click="dialogClose" />
          </div>
        </div>
      </template>
      <div v-if="disabled" class="flex col-12">
        <h4 class="p-error">
          {{ showDisabledMessage(disabledReasonType) }}
        </h4>
      </div>
      <div v-if="scheduleDesc" class="col-12 pb-3">
        <Message :closable="false">
          <div v-html-safe="scheduleDesc" />
          <div v-html-safe="safeLinkInstr" />
        </Message>
      </div>
      <div class="col-12 mt-4">
        <DataTable :value="friendlyText">
          <Column v-for="col of friendlyTextHeaders" :key="col.value" :field="col.value" :header="col.text" sortable />
        </DataTable>
      </div>
      <div v-if="editable" class="flex col-12">
        <div class="col-8" />
        <div v-if="!disabled" class="col-4 flex flex-row-reverse">
          <router-link :to="editUrl" class="no-underline">
            <Button type="button" class="p-button-outlined p-button-rounded">
              <img alt="logo" :src="require('../../../assets/icons/edit.svg')" class="text-xl pr-2">

              Edit Configuration
            </Button>
          </router-link>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { createFilter, FriendlyFilterRow, HIDDEN_FIELDS, toLabel } from '@/models/filters';
import { FRIENDLY_FILTER_HEADERS } from '@/models/notification';
import {
  DisabledReasonType,
  getDisabledReasonSummary,
} from '@/models/notification/disabledReasons';
import {computed, PropType, ref} from 'vue';
import useAuthenticatedUser from '@/use/authenticatedUser';
import { FriendlyTextModel } from '@/models/common/friendlyTextModel';
import { safeLinkInstruction } from '@/models/common/alertMessage';
import useSearchLookups from '@/use/searchLookups';
import useSearchFilterSecurity from '@/use/searchFilterSecurity';
import { FilterModel } from '@/models/search';
import useActivityFields from '@/use/activityFields';

export default {
  name: 'CpFriendlyFilterText',
  props: {
    modelValue: { type: Object as PropType<FriendlyTextModel>, required: true },
    scheduleDesc: { type: String, default: '' },
    editable: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    disabledReasonType: {
      type: Number as PropType<DisabledReasonType>,
      default: DisabledReasonType.None,
    },
  },
  emits: ['modal-closed'],
  setup(props, { emit }) {
    const { notificationClaimLevel } = useAuthenticatedUser();
    const friendlyTextHeaders = [...FRIENDLY_FILTER_HEADERS];
    const { userActivitySearchFields } = useAuthenticatedUser();
    const { onlineBgMap, orgQuestionsMap } = useSearchLookups();
    const { basicSearchableActivityFields } = useActivityFields();
    const fields = computed(() => [
      ...userActivitySearchFields.value, 
      ...basicSearchableActivityFields.value.map(x => x.field)
    ]);
    const { updateData, displayableFilters } = useSearchFilterSecurity(ref([]), ref([]), fields, orgQuestionsMap, onlineBgMap);
    const friendlyText = computed(() => {
      updateData(<FilterModel>{ filters: props.modelValue.filters, indices: [] });
      const contextSpecificHiddenFields = props.modelValue.contextSpecificHiddenFields || [];
      const nonEmptyFilters = displayableFilters.value
        .filter(f => !f.isEmpty() && ![...HIDDEN_FIELDS, ...contextSpecificHiddenFields].includes(f.field))
        .map(f => {
          const filterObj = createFilter(f.$type, f);
          const label = toLabel(f.field);
          return filterObj.getFriendlyText(label);
        });

      const activityTypesString = props.modelValue.activityTypes.join(', ');

      const activityTypes = <FriendlyFilterRow>{ filterName: 'Activity Types', operation: 'Contains', value: activityTypesString };
      nonEmptyFilters.unshift(activityTypes);
      return nonEmptyFilters;
    });
    const showDialog = computed(() => props.modelValue.show);
    const headerText = computed(() => props.modelValue.idType === 'ActivityReport'
      ? 'Activity Report Parameters'
      : 'Notification Parameters');
    const editUrl = computed(() => {
      const id = Array.isArray(props.modelValue.id) ? props.modelValue.id.join('/') : props.modelValue.id;

      return props.modelValue.idType === 'ActivityReport'
        ? `/dissemination-contact/${id}`
        : `/notification/${id}/1`;
    });

    const dialogClose = () => {
      emit('modal-closed', false);
    };

    const showDisabledMessage = (DisabledReasonType: DisabledReasonType) => getDisabledReasonSummary(DisabledReasonType, notificationClaimLevel.value);

    const safeLinkInstr = safeLinkInstruction;
    
    return {
      friendlyText,
      friendlyTextHeaders,
      editUrl,
      headerText,
      showDialog,
      dialogClose,
      showDisabledMessage,
      safeLinkInstr,
    };
  },
};

</script>
<style lang="scss"></style>
