import { Filter } from '@/models/filters/filter';
import { SearchFieldProp } from './searchFieldProp';
import Vue from 'vue';
import { SearchFieldDisplayDependency } from '@/components/searchFields/metadata/searchFieldDisplayDependency';

export interface SearchFieldMetaData {
  type?: Vue.Component;
  model: Filter | Filter[];
  props?: SearchFieldProp;
  displayDependency?: SearchFieldDisplayDependency
  hide?: boolean;
}

export function searchFieldModel(searchField: SearchFieldMetaData) {
  return searchField.props?.isCompound ? (searchField.model as Filter[])[0] : searchField.model as Filter;
}

export function searchFieldModelValue(searchField: SearchFieldMetaData, key: keyof Filter) {
  return searchFieldModel(searchField)[key];
}

export function callSearchFieldModelFunction(searchField: SearchFieldMetaData, funcName: string, ...params) {
  const model = searchFieldModel(searchField);
  if (params) {
    return model[funcName](params);
  } else {
    return model[funcName]();
  }
}
