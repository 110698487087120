<template>
  <Card :class="activityCount == 0 ? ['p-card no-results-card'] : 'p-count-card flex align-items-center justify-content-center'">
    <template #content>
      <ProgressSpinner v-if="loading" class="flex align-items-center justify-content-center" />
      <cp-animated-integer v-if="activityCount !== 0 && loading == false" :value="activityCount" class="company-count pt-4" />
      <cp-no-data-component v-if="activityCount === 0 && loading == false" :chart-type="'widget'" class="company-count" />
      <div v-if="!loading" :class="activityCount !== 0 ? 'text-center pt-0' : 'sub-no-data'">
        {{ text }}
      </div>
    </template>
  </Card>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import ContentOverviewDashboardService from '@/services/contentOverviewDashboardService';
import { Chart } from 'highcharts';
import { getPieGradientPerSlicePercentTotal } from '@/utils/highchartUtils';
import { isEqual } from 'lodash';
import { filterValueExists } from '@/utils/filters';
import { FilterModel } from '@/models/search';
import { Vue, Options } from 'vue-class-component';
import NoChartDataComponent from '@/components/common/NoChartDataComponent.vue';
import AnimatedIntegerComponent from '../common/animatedInteger.component';

@Options({
  components: {
    'cp-no-data-component': NoChartDataComponent,
    'cp-animated-integer': AnimatedIntegerComponent,
  },
})
export default class DocsWithEngagements extends Vue {
  @Prop({ default: () => <FilterModel>{ filters: [], indices: [] } })
  public filterModel: FilterModel;
  @Prop({ required: true, default: 0 }) docCount: number;
  @Prop({ required: true }) text: string;
  percentWithEngagements: number = 0;
  declare $refs: { chart: HTMLDivElement };
  chartExport: Chart;
  activityCount = 0;
  loading = true;

  @Watch('filterModel', { immediate: true })
  async filtersChanged(newVal: FilterModel, oldVal: FilterModel) {
    if (
      !newVal ||
      isEqual(oldVal, newVal) ||
      !filterValueExists(newVal.filters, 'Clients')
    ) {
      return;
    }

    await this.refreshData(this.docCount);
  }

  @Watch('docCount')
  async refreshData(val: number) {
    if (!val) {
      return;
    }
    this.activityCount = await ContentOverviewDashboardService.getActivitiesWithDocsCount(
      this.filterModel
    );
    const percent = (this.activityCount / val) * 100;
    this.percentWithEngagements = Number.parseFloat(percent.toPrecision(2));
    this.loading = false;
  }

  private get chartData() {
    const percentWithoutEngagements = 100 - this.percentWithEngagements;

    return [
      { name: 'with engagements', y: this.percentWithEngagements },
      { name: 'without engagements', y: percentWithoutEngagements },
    ];
  }

  private renderChart() {
    if (!this.$refs.chart) {
      return;
    }

    this.chartExport = new Chart(this.$refs.chart, {
      credits: { enabled: false },
      chart: { type: 'pie', height: '170', backgroundColor: 'transparent', margin: 2 },
      exporting: { enabled: false },
      colors: getPieGradientPerSlicePercentTotal() as any,
      title: {
        text: `${this.percentWithEngagements?.toString()}<span class='text-4xl'>%`,
        align: 'center',
        floating: true,
        verticalAlign: 'middle',
        style: {
          fontSize: '40px',
          textAlign: 'center',
          font: 'normal normal 900 45px/45px Overpass !important',
          fontWeight: '900',
          letterSpacing: '-1px',
          color: '#54606e',
          opacity: 1,
          position: 'relative',
          fill: '#54606e !important',
        },
        y: 30,
      },
      plotOptions: {
        pie: {
          showInLegend: false,
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: { enabled: false },
          startAngle: -90,
          endAngle: 90,
        },
      },
      series: [
        { type: 'pie', name: '% of documents', data: this.chartData, innerSize: '73%' },
      ],
    });
  }

  seriesHasData() {
    var totalEngagements = this.chartData?.filter(
      (item) => item.name === 'with engagements'
    )[0].y;
    return totalEngagements !== 0;
  }
}
</script>

<style scoped lang="scss">
.subheading-container {
  text-align: center;
  margin-top: -90px;
  margin-left: 0px;
  font: normal normal normal 16px/30px Overpass;
  letter-spacing: 0px;
  color: #54606e;
  opacity: 1;
  float: initial;
}

.noresults {
  margin-top: 25px;
}

.sub-no-data {
  opacity: 1;
  font-family: Overpass, sans-serif;
  font-size: 0.8rem;
  font-weight: normal;
  text-align: center;
}

.align-title {
  margin-top: -75px;
}
</style>
