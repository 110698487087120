<template>
  <div v-if="layout == 'lg-card'" class="flex col-12 pl-0">
    <div v-for="item in valueArray" :key="item.type" class="col-4 pr-4">
      <Card class="p-alerts-card" :class="activeTab == item.type ? item.class + ' active' : item.class">
        <template #content>
          <div class="p-card-title mb-0">
            {{ item.text }}
          </div>
          <Divider align="center" class="mt-1" />
          <div>
            {{ item.description }}
          </div>
          <div v-if="!(activeTab == item.type)" class="mt-4 pb-0 h-2rem">
            <Button label="Select" class="p-button-rounded p-button-outlined px-4" @click="activeTab = item.type" />
          </div>
          <div v-if="activeTab == item.type" class="mt-4 pb-0 h-2rem">
            <span class="material-icons text-white"> done </span>
            <div class="font-bold text-sm text-white" style="margin-top: -5px">
              Selected
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
  <div v-else class="grid col-12">
    <div v-for="item in valueArray" :key="item.type" class="col-5 pr-4">
      <Card class="p-alerts-user-card p-secondary-color pt-0 pl-3" :class="activeTab == item.type ? 'active' : ''"
        @click="activeTab = item.type">
        <template #content>
          <div class="flex pb-0 pt-0">
            <div class="flex align-items-center">
              <span class="material-icons text-4xl"> {{ item.icon }} </span>
            </div>
            <div class="pl-3">
              <div class="text-sm font-bold">
                {{ item.text }}
              </div>
              <div class="text-sm p-secondary-color">
                {{ item.description }}
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>
<script lang="ts">
import { GenericActionItem } from '@/models/common/actionItem';
import { isEqual } from 'lodash';
import { Prop, Watch } from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component';

@Options({
  name: 'cp-action-item-list',
})
export default class ActionItemList extends Vue {
  @Prop({ required: true }) modelValue!: any;
  @Prop({ required: true }) valueArray: Array<GenericActionItem<any>>;
  @Prop({ default: 'lg-card' }) layout: string;

  activeTab: string = '';

  mounted() {
    this.activeTab =
      this.modelValue || (this.layout == 'lg-card' ? '' : this._defaultValue);
  }

  @Watch('activeTab')
  onActionItemChanged(newVal: any, oldVal: any): void {
    if (isEqual(newVal, oldVal)) {
      return;
    }

    this.$emit('update:modelValue', newVal);
  }

  private get _defaultValue() {
    return this.valueArray.filter((x) => x.active)[0]?.type;
  }
}
</script>
