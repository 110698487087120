<template>
  <form ref="addForm" :disabled="modelValue.isReadOnly">
    <div class="flex col-12 pt-0">
      <h3>
        Search for users and grant permission to view this alert on Templeton's
        Notification Dashboard:
      </h3>
    </div>
    <div class="flex p-fluid col-12 px-0 pt-5">
      <span class="p-float-label w-full">
        <AutoComplete v-model="selectedEmail" :suggestions="users" field="displayText" class="w-auto"
          placeholder="Enter your Name/Email" @complete="search($event)" @item-select="addUser($event.value)" />
        <label for="client">Name/Email</label>
      </span>
    </div>
    <div class="col-12 pt-4 pb-0">
      <h3>Settings:</h3>
      <div class="text-sm p-secondary-color">
        Those listed below can access the alert details on the notification dashboard by
        default. Use the controls below to manage e-mail notifications, or remove access.
      </div>
    </div>
    <div class="grid col-12 px-0 pt-4">
      <div v-for="t in dashboardTargets" :key="t.email" class="col-6 pl-0">
        <Card class="p-alerts-user-card p-secondary-color pt-0 pl-3 mr-4">
          <template #content>
            <div class="flex pb-0 pt-0">
              <div class="flex align-items-center">
                <Avatar class="va-avatar-initials font-medium" shape="circle" :class="getAvatarColor(t)">
                  <span class="pt-1"
                    :class="{ 'text-white': t.isRegisteredClient, 'p-avatar-text': !t.isRegisteredClient }">{{
                    initials(t) }}</span>
                </Avatar>
              </div>
              <div class="pl-3">
                <div class="capitalize text-sm font-bold p-secondary-color">
                  {{ t.name }}
                </div>
                <div class="text-sm p-secondary-color">
                  {{ t.email }}
                </div>
              </div>
              <div class="col flex flex-row-reverse pr-2">
                <i v-if="t.email !== modelValue.createdBy && !modelValue.isReadOnly" class="pi pi-trash pl-3"
                  aria-haspopup="true" aria-controls="overlay_menu" title="Remove User From Notification Recipients"
                  @click.prevent="removeUser(t)" />
                <InputSwitch v-model="t.isEmailSubscribed" title="Is Subscribed" dense>
                  Subscribed:
                </InputSwitch>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </form>
</template>

<script lang="ts">
import { NotificationWorkflowMixin } from '@/mixins/notificationWorkflow.mixin';
import { DashboardTarget } from '@/models/notification/alert-method';
import { Claim, CompanyClaim } from '@/models/security';
import notificationService from '@/services/notificationService';
import UserService from '@/services/userService';
import { Watch, Mixins } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import NotificationStepperTitleBar from './common/NotificationStepTitleBar.vue';
import { SearchCriteria } from '@/services/searchCriteria';
import { TermsFilter } from '@/models/filters';
import { Options } from 'vue-class-component';
import { Debounce } from '@/utils/decorators/debounce.decorator';
import { WorkflowStepEnum } from '@/models/notification';
import { SetTargetRequest } from '@/models/notification/alert-method/setTargetRequest';
import { getAvatarColorClass, getInitials } from '@/utils/userUtils';
import { findFilter } from '@/utils/filters';
import { DEFAULT_TIMEOUT_TIME } from '@/models/common/constants';
import { UserLookup } from '@/models/userLookup';
@Options({
  name: 'cp-notification-alert-method-step',
  computed: {
    ...mapGetters('auth', [
      'satisfiesClaim',
      'userId',
      'userName',
      'userEmail',
      'userCompanies',
    ]),
  },
  components: {
    'cp-stepper-title-bar': NotificationStepperTitleBar,
  },
})
export default class NotificationAlertMethodStep extends Mixins(
  NotificationWorkflowMixin
) {
  // from vuex
  satisfiesClaim: (claim: Claim) => boolean;
  userId!: string;
  userName!: string;
  userEmail!: string;
  userCompanies!: CompanyClaim[];

  selectedCompanies = new TermsFilter({ field: 'Clients', value: [] });
  users: DashboardTarget[] = [];
  dashboardTargets: DashboardTarget[] = [];
  criteria: SearchCriteria = { page: 1 };
  selectedEmail = null;

  mounted() {
    this.selectedCompanies = findFilter<TermsFilter>(this.modelValue.filters, 'Clients');
    this._setTargets();
  }

  /**
   * Calls the Liberty Bell API and saves the alert targets to the loaded Notification
   *
   * @returns Notification Id
   */
  async save(): Promise<string> {
    try {
      const id = this.$route.params['id'].toString();
      const req: SetTargetRequest = {
        user:  this.userEmail,
        targets: this.dashboardTargets,
        lastStepCompleted: this.modelValue.getLastAvailableStep(WorkflowStepEnum.SetAlert),
      };
      await notificationService.setTargets(id, req);
      this.$emit('update:modelValue', this.modelValue);
      return Promise.resolve(this.modelValue.id);
    } catch (err) {
      // Send error message back to parent
      this.showError([this.ERROR_MESSAGES.save, this.ERROR_MESSAGES.sysAdmin]);
      return Promise.reject(err);
    }
  }

  get atleastAdvancedUser(): boolean {
    return this.satisfiesClaim({
      name: 'manageNotifications',
      value: 'Advanced',
    });
  }

  /**
   * Removes the User at the specified @param index from the Target array
   */
  removeUser(target: DashboardTarget) {
    this.dashboardTargets = this.dashboardTargets.filter(
      (d) => d.userId !== target.userId
    );
  }

  /**
   * Adds the User to the Target array
   */
  addUser(target: DashboardTarget) {
    if (!this.dashboardTargets.find((x) => x.userId === target.userId)) {
      target.isEmailSubscribed = true;
      this.dashboardTargets.push(target);
    }

    this.selectedEmail = null;
  }

  /**
   * Initiate a user lookup search query for users that will gets notifications on their dashboard
   */
  @Debounce(DEFAULT_TIMEOUT_TIME)
  async search(query) {
    let users: UserLookup[] = [];
    this.criteria.companyIds =
      this.selectedCompanies.value.map((x) => x.id) ??
      this.userCompanies.map((x) => x.id);

    this.criteria.query = query.query;

    if (this.criteria.query) {
      users = (await UserService.getLookups(this.criteria));
    }
    const notSelected = users.filter((e1) => this.dashboardTargets.findIndex((e2) => e2.email == e1.email) === -1);

    this.users = notSelected.map((x) => {
      const dt = new DashboardTarget({
        userId: x.userId,
        name: x.displayText,
        email: x.email,
        isEmailSubscribed: true,
        role: x.role,
        isRegistered: x.isRegistered
      });
      return dt;
    });
  }

  @Watch('$route')
  routeChanged() {
    this._setTargets();
  }

  initials(target: DashboardTarget): string {
    const nameParts = target.name.split(' ');

    return getInitials(nameParts);
  }

  getAvatarColor(user: DashboardTarget): string {
    return getAvatarColorClass(user.role, user.isRegistered);
  }
  // function getAvatarColor(): string {
  //   return this.userName ? getAvatarColorClass('Admin') : "color-yellow";
  // }

  private _setDefaultUserSelection() {
    if (!this.isEditWorkflow && this.dashboardTargets.length === 0) {
      this.dashboardTargets.push(
        new DashboardTarget({
          userId: this.userId,
          name: this.userName,
          email: this.userEmail,
          isEmailSubscribed: true,
          role: 'Admin',
          isRegistered: true,
        })
      ); // Pre-fill logged in user by default
    }
  }

  private _setTargets() {
    this.$emit('is-loaded', false);
    this.dashboardTargets = (this.modelValue.targets || []).map(
      (v) => new DashboardTarget(v)
    );
    this._setDefaultUserSelection();
    this.users = this.dashboardTargets;
    this.$emit('is-loaded', true);
  }


}
</script>

<style lang="scss"></style>
