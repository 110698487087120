import { FieldCode, Filter } from '@/models/filters';
import { ActivityReportDashboardDetails, ActivityReportDashboardRow } from '../activity-dissemination/dashboard';
import { ActivityReportType } from '@/models/activity-dissemination';

export type IdType = 'Notification' | 'ActivityReport';


export class FriendlyTextModel {
  filters: Filter[];
  activityTypes: string[];
  id: string | string[];
  idType: IdType;
  show: boolean;
  contextSpecificHiddenFields?: FieldCode[];
}

export function toFriendlyTextModel(run: ActivityReportDashboardRow | ActivityReportDashboardDetails): FriendlyTextModel {
  return {
    filters: run.filterModel.filters,
    activityTypes: run.reportTypeEnum === ActivityReportType.Individual
      ? ['Customer Requests']
      : run.activityTypes.length
        ? run.activityTypes
        : run.filterModel.indices.map(x => x.name),
    id: [run.userId, run.activityReportConfigId],
    idType: 'ActivityReport',
    show: false,
    // NOTE: ActivityTypes are removed because indices are manipulated in the actual filter view
    contextSpecificHiddenFields: ['ActivityTypes', 'EligibilityDate', 'DateRange', 'RelativeDateRange']
  };
}
