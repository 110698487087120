<template>
  <div class="flex justify-content-between">
    <h2 class="flex align-items-center">
      Recent Engagements
      <!-- <span class="help-icon ml-2">?</span> -->
    </h2>
  </div>

  <DataTable :id="data1.length > 0 ? ['recentEngagements'] : 'no-results'"
    class="pt-3"
    :value="data1"
    :striped-rows="data1.length > 0">
    <Column field="date"
      header="Date"
      :style="{ width: '22%' }"
      class="pl-3"
      style="border-top-left-radius: 24px; border-bottom-left-radius: 24px">
      <template #body="slotProps">
        <img v-tooltip="slotProps.data.activityTypeBadge.title"
          :src="slotProps.data.activityTypeBadge.icon"
          width="17"
          class="vertical-align-middle mr-2">
        {{ " " + formatDate(slotProps.data.date) }}
      </template>
    </Column>
    <Column field="title"
      header="Title"
      class="truncate-text"
      :style="{ width: '35%' }">
      <template #body="slotProps">
        <a :href="slotProps.data.sitesUrl" :title="slotProps.data.title"
          target="_blank" v-html="slotProps.data.title" />
      </template>
    </Column>
    <Column field="type"
      header="Type"
      style="
         {
          width: '23%';
        }
      ">
      <template #body="slotProps">
        {{ slotProps.data.type }}
      </template>
    </Column>
    <Column field="buyersJourney"
      header="Buyer's Journey"
      :style="{ width: '18%' }"
      style="border-top-right-radius: 24px; border-bottom-right-radius: 24px">
      <template #body="slotProps">
        <img :src="slotProps?.data.buyersJourneyBadge?.icon" width="55">
      </template>
    </Column>
    <template #empty>
      <div>
        <cp-no-datatable-records />
      </div>
    </template>
  </DataTable>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import dayjs from 'dayjs';
import ReaderDashboardService from '@/services/readerDashboardService';
import { ReaderFilterModel } from '@/models/search';
import { ReaderRecentEngagement } from '@/models/user-service/readerRecentEngagement';
import { isEqual } from 'lodash';
import { Vue, Options } from 'vue-class-component';
import NoDataTableRecordsComponent from '@/components/common/NoDataTableRecordsComponent.vue';

@Options({
  components: {
    'cp-no-datatable-records': NoDataTableRecordsComponent,
  },
})
export default class RecentEngagements extends Vue {
  @Prop({ default: 5 }) size!: number;
  @Prop( {default: () => false}) noReaderFound: boolean;
  @Prop({ default: () => <ReaderFilterModel>{ filters: [], indices: [] } })
  public filterModel!: ReaderFilterModel;
  data1: ReaderRecentEngagement[] = [];

  async mounted() {
    await this.computedValueChanged(this.filterModel, <ReaderFilterModel>{
      filters: [],
      indices: [],
    });
  }

  @Watch('filterModel')
  async computedValueChanged(newVal: ReaderFilterModel, oldVal: ReaderFilterModel) {
    const noChanges = isEqual(newVal, oldVal);
    if (!newVal || noChanges) {
      return;
    }

    this.data1 = newVal.noReaderFound ? [] : await ReaderDashboardService.getRecentActivities(newVal, this.size);
  }

  formatDate(date: Date): string {
    return dayjs(date).format('MMM DD \'YY');
  }
}
</script>

<style lang="scss" scoped>
.no-results .p-datatable-thead > tr > th .p-datatable-tbody > tr > td {
  border-bottom: 1px #e4e4e4 !important;
  background: white;
  width: 100%;
}
</style>
