import { GenericActionItem } from './actionItem';
import { DayOfMonth } from './dayOfMonth';

export type DayOfMonthActionItem = GenericActionItem<DayOfMonth>

export const DEFAULT_DAY_OF_MONTH_ACTION: DayOfMonthActionItem = {
  type: 'First',
  text: 'First',
  description: 'The first of the month',
  active: true
};

export const DAY_OF_MONTH_ACTIONS: DayOfMonthActionItem[] = [
  {
    type: 'First',
    text: 'First',
    description: 'The first day of the month',
    icon: 'today',
    active: true
  },
  {
    type: 'Mid',
    text: 'Mid',
    icon: 'event',
    description: 'The 15th of the month'
  }
];
