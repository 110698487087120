import { EngagementsByBuyersJourney } from '@/services/readerCoService';
import { uniq, sortBy } from 'lodash';

export function fill(result: EngagementsByBuyersJourney[]) {
  const distinctMonths = uniq(result.flatMap(d => d.month));
  const filledResult = result.map(journey => {
    if (!distinctMonths.every(m => journey.month.includes(m))) {

      // console.debug(`BuyerJourneyUtils.fill() - Missing Months`, journey, sortedMonths, distinctMonths);
      // Find all missing month indices in journey
      const missingMonths = distinctMonths.filter(x => !journey.month.includes(x));
      missingMonths.forEach(m => {
        journey.month.push(m);
        journey.data.push(0);
      });

      // 1 combine the arrays
      let list: any[] = [];
      for(let j= 0; j < journey.month.length; j++) {
        list.push({'month': journey.month[j], 'data': journey.data[j]});
      }

      // 2 sort
      list = sortBy(list, d => {
        const formattedDate = '1-' + d.month.replace(` '`, '-');
        return new Date(formattedDate);
      });

      //3) separate them back out:
      for (let k = 0; k < list.length; k++) {
        journey.month[k] = list[k].month;
        journey.data[k] = list[k].data;
      }
    }

    return journey;
  });
  return filledResult;
}