<template>
  <div class="flex p-fluid">
    <span class="p-float-label" :style="defaultLabel.length < 21 ? 'min-width: 150px' : 'min-width: 230px'">
      <Dropdown :model-value="local.op" :options="textOpts" option-label="name" option-value="id"
        class="p-border-round-left" @change="v => updateField('op', v)" />
      <label for="options">{{ defaultLabel }}</label>
    </span>
    <div class="w-full">
      <span v-if="!multiValue" class="p-float-label">
        <InputText id="textfield" class="p-inputtext w-full p-border-round-right" :model-value="local.value"
          @input="updateField('value', $event.target)" />
      </span>
      <span v-if="multiValue" class="p-float-label">
        <Textarea id="textarea" rows="1" class="p-inputtext w-full p-border-round-right" :model-value="local.value"
          @input="updateField('value', $event.target)" />
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { Prop } from 'vue-property-decorator';
import { Operation, TextFilter, toLabel, TEXT_OPERATIONS, operationLabel } from '@/models/filters';
import { Vue, Options } from 'vue-class-component';
import { Lookup } from '@/models/lookup';

const DEFAULT = new TextFilter({ op: 'Equals', field: undefined });

@Options({
  name: 'cp-text-search-field',
  inheritAttrs: true,
})
export default class TextSearchField extends Vue {
  @Prop() modelValue: TextFilter;
  @Prop() label: string;
  @Prop({ default: false }) multiValue: boolean;
  @Prop({ default: true }) dense: boolean;
  @Prop({ default: () => [] }) rules: Array<(val: string) => any>;
  textOpts: Lookup<Operation>[];
  
  created() {
    this.textOpts = (this.modelValue.restrictedToOps?.length
        ? TEXT_OPERATIONS.filter(x => this.modelValue.restrictedToOps.includes(x)) 
        : TEXT_OPERATIONS).map(x=><Lookup<Operation>>{id: x, name: operationLabel[x]});
    
    this.$emit('update:modelValue', new TextFilter(this.local));
  }

  get local() {
    return new TextFilter({ ...DEFAULT, ...this.modelValue });
  }

  get defaultLabel(): string {
    return this.label || toLabel(this.modelValue.field);
  }

  public updateField(name: keyof TextFilter, value: any) {
    const newVal = new TextFilter({
      ...this.local,
      [name]: value.value
    });

    this.$emit('update:modelValue', newVal);
  }

  getOptionsWidth(): string {
    return this.defaultLabel.length < 22 ? 'min-width: 150px' : (this.defaultLabel.length > 26 ? 'min-width: 230px' : 'min-width: 210px');
  }
}
</script>

<style lang="scss" scoped>

</style>
