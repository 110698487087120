<template>
  <div class="jspdf c-text-base" style="width:550px">
    <div class="flex justify-content-between align-items-center">
      <div>
        <img :src="require('../assets/Templeton_logo.png')" alt="Templeton Logo" class="h-3rem">
      </div>
      <div class="p-primary-color c-text-lg font-extrabold">
        {{ title }}
      </div>
    </div>
    <div class="flex align-items-center font-bold mt-3 pl-1 c-text-base font-italic">
      Date: {{ dayjs(String(activity?.date)).format('MMMM DD, YYYY') }}
    </div>
    <div class="card mt-3">
      <div class="p-panel p-component">
        <div class="p-panel-header">
          <span class="c-text-base font-bold p-primary-color">Reader Details</span>
        </div>
        <div class="flex c-text-base my-3 px-3 pb-0">
          <div class="right-border col-5 p-0 pl-2 ">
            <div v-if="activity?.firstName && activity?.lastName"
              class="flex align-items-center c-text-xl font-bold capitalize mb-3">
              <img class="mr-2" src="../assets/account_circle.png" width="20">
              {{ activity?.fullName }}
            </div>
            <div class="flex align-items-center font-normal capitalize font-italic">
              {{ activity?.jobTitle }}
            </div>
            <div class="flex align-items-center font-bold capitalize">
              {{ activity?.company }}
            </div>
          </div>
          <div class="col-7 pl-5 p-0">
            <div class="flex align-items-center">
              <img class="mr-2" src="../assets/email.png" width="10">
              <a :href="`mailto:${activity?.email}`" class="no-underline relative">
                <span class="c-underline" />
                {{ activity?.email }}
              </a>
            </div>
            <div class="flex align-items-center mt-2 capitalize">
              <img class="mr-2" src="../assets/place.png" width="10">
              {{ activity?.formattedAddr }}
            </div>
            <div class="flex align-items-center mt-2">
              <img class="mr-2" src="../assets/phone.png" width="10">
              {{ activity?.phone }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-4">
      <div class="p-panel p-component">
        <div class="p-panel-header">
          <span class="c-text-base font-bold p-primary-color">Engagement Details</span>
        </div>
        <div class="flex c-text-base my-3 px-3 pb-0">
          <div class="col-3 p-0 pl-2 ">
            <div class="flex align-items-center font-bold">
              ACTIVITY TYPE
            </div>
            <div class="flex align-items-center mt-2 font-bold">
              <img v-tooltip="activity?.activityTypeBadge?.title" :src="activity?.activityTypeBadge?.icon" width="14"
                class="mr-2 mt-1">
              {{ activity?.activityTypeBadge?.title }}
            </div>
          </div>
          <div class="col-9 p-0 pl-2 ">
            <div class="flex align-items-center font-bold">
              CONTENT TITLE
            </div>
            <div class="align-items-center mt-2">
              <a :href="activity?.portalUrl" target="_blank" class="no-underline font-bold">{{
                activity?.docTitle
              }}</a><br>
              <a :href="activity?.portalUrl" target="_blank" class="c-text-xs no-underline relative">
                <span class="c-underline" />
                {{ activity?.portalUrl }}</a>
            </div>
          </div>
        </div>
        <div class="top-border mx-2" />
        <div class="flex c-text-base my-3 px-3 pb-0 justify-content-between">
          <div class="p-0 pl-2 ">
            <div class="flex align-items-center font-bold">
              REQUEST RECEIVED FROM
            </div>
            <div class="flex align-items-center mt-2">
              <a :href="onlineUrl" target="_blank" class="font-bold no-underline relative">
                <span class="c-underline" />
                {{ onlineUrl }}
              </a>
            </div>
          </div>
          <div class="p-0 pl-2 ">
            <div class="flex align-items-center font-bold">
              BUYER'S JOURNEY
            </div>
            <div class="flex align-items-center mt-2">
              <img :src="activity?.buyersJourneyBadge?.icon" height="15">
            </div>
          </div>
          <div class="p-0 pl-2 ">
            <div class="flex align-items-center font-bold">
              QUALITY SCORE
            </div>
            <div class="flex align-items-center mt-2">
              <img :src="activity?.qualityBadge?.icon" height="15">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="activity?.specificsModel || activity?.specifics" class="card mt-4" style="page-break-after: always;">
      <div class="p-panel p-component">
        <div class="p-panel-header">
          <span class="c-text-base font-bold p-primary-color">Specifics</span>
        </div>
        <div class="flex c-text-base px-3 pb-2">
          <specifics-questions v-model="activity.specificsModel" :specifics-freetext="activity?.specifics"
            :show-title="false" />
        </div>
      </div>
    </div>
    <div class="mt-4 pl-1">
      <b>Need help?</b> Email <a :href="`mailto:${supportEmail}`" class="font-bold no-underline relative">
        <span class="c-underline" />
        {{ supportEmail }}</a>, visit
      <a :href="kbLink" target="_blank" class="font-bold no-underline relative">
        <span class="c-underline" />
        {{ kbLink }}</a>
      or contact your representative.
    </div>
  </div>
</template>

<script lang="ts">
import { ref, PropType } from 'vue';
import { ActivityType } from '@/models/activity-review/activityType';
import searchService from '@/services/searchService';
import { EngagementSearchDetails } from '@/models/search/engagementSearchDetails';
import dayjs from 'dayjs';
import SpecificsQuestions from '../components/analytics/SpecificsQuestions.vue';
import { computed } from '@vue/reactivity';

export default {
  components: {
    'specifics-questions': SpecificsQuestions,
  },
  props: {
    activityType: { type: Object as PropType<ActivityType> },
    id: { type: String, default: '' },
  },
  setup(props) {
    const title = ref('');
    const activity = ref<EngagementSearchDetails>();

    const supportEmail = process.env.VUE_APP_SUPPORT_EMAIL;
    const kbLink = process.env.VUE_APP_KB_URL;

    const getActivityDetails = async () => {
      activity.value = await searchService.getDetails(props.activityType, props.id);
    };

    getActivityDetails();

    const onlineUrl = computed(() => activity.value?.portalUrl ? (new URL(activity.value?.portalUrl)).origin : '');

    return { title, activity, dayjs, supportEmail, kbLink, onlineUrl };
  }
};
</script>

<style>
.jspdf {
  font-family: 'Overpass' !important;
  color: #586A83;
}

.font-extrabold {
  font-weight: 800;
}

.c-text-base {
  font-size: 9px !important;
}

.c-text-sm {
  font-size: 8px !important;
}

.c-text-xs {
  font-size: 7px !important;
}

.c-text-lg {
  font-size: 10px !important;
}

.c-text-xl {
  font-size: 12px !important;
}

.p-panel {
  border-radius: 10px;
  box-shadow: none !important;
  border: 1px dashed #d3d8e6;
}

.p-panel-header {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  padding: 0.5rem 1rem !important;
}

.right-border {
  border-right: 1px solid #d3d8e6;
}

.top-border {
  border-top: 1px solid #d3d8e6;
}

.c-underline {
  position: absolute;
  bottom: -.5px;
  left: 0;
  width: 100%;
  height: .5px;
  background-color: #586A83;
}
</style>
