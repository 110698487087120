export enum EmailType { 
    From = 'from',
    To = 'to', 
    Cc = 'cc', 
    Bcc = 'bcc' 
}
export interface EmailAddress { 
    type: EmailType, 
    name?: string, 
    email: string 
}
export interface EmailMessage { 
    addresses: EmailAddress[], 
    subject: string,    
    body: string,
    attachReportExcel: boolean,
    attachReportPdf: boolean,
    activityPdf: Blob | null,
}