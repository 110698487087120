import { NotificationDashboardDetails, NotificationDashboardSearchRequest, NotificationDashboardRow } from '@/models/notification/dashboard';
import { PagedList } from '@/models/pagedList';
import axios from 'axios';

class NotificationDashboardService {
  private baseUrl: string = '/api/notification-dashboard';

  async get(req: NotificationDashboardSearchRequest): Promise<PagedList<NotificationDashboardRow>> {
    const r = await axios.post<PagedList<NotificationDashboardRow>>(`${this.baseUrl}`, req);
    r.data.items = r.data.items.map(n => new NotificationDashboardRow(n));
    return r.data;
  }

  async markRead(ids: string[], isRead: boolean = true) {
    await axios.post(`${this.baseUrl}/mark-read/${isRead}`, ids);
  }

  async getUnreadCount(): Promise<number> {
    const r = await axios.get<number>(`${this.baseUrl}/unread-count`, { responseType: 'text' as 'json' });
    return r.data;
  }

  async getById(runId: string): Promise<NotificationDashboardDetails> {
    const r = await axios.get<NotificationDashboardDetails>(`${this.baseUrl}/${runId}`);
    return r.data;
  }
}

export default new NotificationDashboardService();
