import { BoolFilter, TermsControlType, TermsFilter, TextFilter } from '@/models/filters';
import FilterLookupsSvc from '@/services/filterLookupsService';
import { SearchFieldMetaData } from '.';
import lookupsSvc from '@/services/commonLookupService';

export const DEMOGRAPHIC_FIELD_METADATA: Array<SearchFieldMetaData | SearchFieldMetaData[]> = [
  {
    model: new TextFilter({ field: 'Email' }),
    props: { multiValue: true }
  },
  [
    {
      model: new TextFilter({ field: 'FirstName' })
    },
    {
      model: new TextFilter({ field: 'LastName' })
    }
  ],

  {
    model: [new TextFilter({ field: 'Title' })],
    props: { multiValue: true, isCompound: true }
  },
  {
    model: new TermsFilter({ field: 'Personas' }),
    props: { loadOptionsOnce: false, optionsGetter: FilterLookupsSvc.getPersonas }
  },
  [
    {
      model: new TextFilter({ field: 'City' })
    },
    {
      model: new TermsFilter({ field: 'State' }),
      props: { controlType: TermsControlType.Chips, optionsGetter: lookupsSvc.getStates }
    },

  ],
  [
    {
      model: new TermsFilter({ field: 'Country' }),
      props: { controlType: TermsControlType.Chips, showOps: true, optionsGetter: lookupsSvc.getCountries }
    },
    {
      model: new TermsFilter({ field: 'Region' }),
      props: { controlType: TermsControlType.Chips, optionsGetter: lookupsSvc.getRegions }
    }
  ],
  {
    model: new BoolFilter({ field: 'ClientConsentStatus' }),
    props: { label: 'Privacy Policy Consent Status' }
  }
];
