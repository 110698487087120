import axios from 'axios';
import { BaseReaderData } from '@/models/analytics/baseReaderData';
import { NamedSeries } from '@/models/namedSeries';
import { Quality } from '@/models/activity-dissemination';
import { Filter } from '@/models/filters';
import { Lookup } from '@/models/lookup';
import { EngagementCategory } from '@/models/analytics/engagementCategory';
import { FilterModel } from '@/models/search';
import { fill } from '@/utils/buyersJourneyUtils';
import { filterModelParams, toIndices } from '@/utils/filters/filterUtils';
import { Badge } from '@/models/analytics/badge';

export interface ReaderCoRequest {
  filters: Filter[];
  termField?: string;
  termVal?: string;
  category?: string;
}

export interface EngagementsOverTimeData { key: string; value: number; }

export interface EngagementsByBuyersJourney {
  name: string;
  color: string | undefined;
  month: string[];
  data: number[];
  marker: any;
}

export interface MostActiveReaderData extends BaseReaderData {
  name: string;
  jobTitle: string;
  company: string;
  totalDocuments: number;
  totalEngagements: number;
  documentTitle: string;
}

export class MostReadContentData {
  title: string;
  contentType: string;
  buyersJourneyStage: string;
  totalUniqueReaders: number;
  totalEngagements: number;
  readonly buyersJourneyBadge?: Badge;

  constructor(data: Partial<MostReadContentData> | null = null) {
    if (data) {
      Object.assign(this, data);
    }

    switch (this.buyersJourneyStage.toLowerCase()) {
      case 'early':
        this.buyersJourneyBadge = { icon: require('../assets/icons/early.png'), title: 'Early' };
        break;
      case 'middle':
        this.buyersJourneyBadge = { icon: require('../assets/icons/middle.png'), title: 'Middle' };
        break;
      case 'late':
        this.buyersJourneyBadge = { icon: require('../assets/icons/late.png'), title: 'Late' };
        break;
    }
  }
}

export interface ReaderCosRequest {
  companyIds: number[];
  term: string;
  quality: Quality[];
}

export interface ReaderCoData {
  company: string;
  orgId?: string;
  orgName?: string;
}

export interface ReaderOrgData {
  name: string;
  id: string;
}

const BASE_URL = '/api/reader-co-dashboard';

class ReaderCoDashboardService {
  async getTotalEngagementsCount(filterModel: FilterModel): Promise<number> {
    return (await axios.post(`${BASE_URL}/total-engagements-count`, {
      ...filterModelParams(filterModel),
      ...toIndices(filterModel)
    })).data.total;
  }

  async getMostActiveOrg(filterModel: FilterModel): Promise<Lookup<string>> {
    return (await (axios.post(`${BASE_URL}/most-active-org`, {
      ...filterModelParams(filterModel),
      ...toIndices(filterModel)
    }))).data;
  }

  async getTotalUniqueUserEngagements(filterModel: FilterModel): Promise<number> {
    return (await axios.post(`${BASE_URL}/total-unique-user-engagements-count`, {
      ...filterModelParams(filterModel),
      ...toIndices(filterModel)
    })).data.total;
  }

  async getEngagementsByCategory(filterModel: FilterModel, category: EngagementCategory): Promise<NamedSeries[]> {
    return (await axios.post(`${BASE_URL}/engagements-by-category`, {
      ...filterModelParams(filterModel),
      ...toIndices(filterModel),
      category
    })).data;
  }
  async getEngagementsOverTime(filterModel: FilterModel): Promise<EngagementsOverTimeData[]> {
    return (await axios.post(`${BASE_URL}/engagements-over-time`, {
      ...filterModelParams(filterModel),
      ...toIndices(filterModel)
    })).data;
  }
  async getEngagementsByBuyersJourney(filterModel: FilterModel): Promise<EngagementsByBuyersJourney[]> {
    const results = (await axios.post(`${BASE_URL}/engagements-by-buyersjourney`, {
      ...filterModelParams(filterModel),
      ...toIndices(filterModel)
    })).data;
    const filledResults = fill(results);
    return filledResults;
  }

  async getMostActiveReaders(filterModel: FilterModel): Promise<MostActiveReaderData[]> {
    return (await axios.post(`${BASE_URL}/most-active-readers`, {
      ...filterModelParams(filterModel),
      ...toIndices(filterModel)
    })).data;
  }

  async getMostReadContent(filterModel: FilterModel): Promise<MostReadContentData[]> {
    return (await axios.post(`${BASE_URL}/most-read-content`, {
      ...filterModelParams(filterModel),
      ...toIndices(filterModel)
    })).data.map(l => new MostReadContentData(l));
  }
}

export default new ReaderCoDashboardService();
