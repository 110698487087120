import { Claim } from '../security';
import { NavItem } from './navItem';

const MANAGE_NOTIFICATIONS = new Claim('manageNotifications', 'Basic');
const VIEW_NOTIFICATIONS = new Claim('viewNotifications');

export const NAV_ITEMS_ALERT: NavItem[] = [
    {
        name: 'Manage Alerts',
        link: '/notifications/search',
        icon: '<i class="ci ci-manage-alerts"></i>',
        claim: MANAGE_NOTIFICATIONS,
    },
    {
        name: 'Notification Dashboard',
        link: '/notifications/dashboard',
        icon: '<i class="ci ci-notification-dashboard"></i>',
        claim: VIEW_NOTIFICATIONS,
    },
];