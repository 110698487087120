<template>
  <div>
    <cp-readonly-form v-if="formData.isSubmitted || formData.Expired" :data1="data" />
    <div v-if="!formData.isSubmitted || !formData.Expired" class="side-space px-6">
      <Steps :model="activeItems" :readonly="false" class="pb-0">
        <template #item="{ item }">
          <div class="flex col-12 w-max">
            <a :step="item.step" :class="{
              'p-complete': (data['lastStepCompleted'] = step && step > item.step) || lastStepCompleted,
              'p-active': step === item.step,
              'pointer-events-none': step < item.step,
              'cursor-pointer': (data['lastStepCompleted'] = step && step > item.step) || lastStepCompleted
            }" :title="item.text"
              class="p-menuitem-link flex align-items-center justify-content-center no-underline opacity-100"
              @click="stepClick(item)">
              <Avatar shape="circle" class="p-steps-number mr-2">
                <span class="material-icons"> edit </span>
              </Avatar>
              <span class="p-steps-title"><b>{{ item.text }}</b>
                <div class="p-steps-subtitle pt-0">
                  {{ stepStatus(data['lastStepCompleted'] = (step && step > item.step) || lastStepCompleted) }}
                </div>
              </span>
            </a>
          </div>
        </template>
      </Steps>
      <Divider class="mt-0 mb-5" />

      <router-view @prev-page="prevPage($event)" @complete="complete">
        <div v-for="item in activeItems" :key="item.step + '-content'">
          <component :is="item.componentDef" v-if="item.step === step" class="onboarding-stepper-content"
            :form-id="onboardingFormId" :step="item.step" @next-page="nextPage($event)" @skip-step="skipStep($event)"
            @step-completed="stepCompleted($event)" @complete="complete" @is-loaded="onComponentLoaded" />
        </div>
      </router-view>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  ONBOARDING_STEPPER_ITEMS,
  OnboardingStepperItems,
  BasicOnboardingFormData, OnboardingWorkflowStep
} from '@/models/onboarding';
import { maxBy } from 'lodash';
import { StepDataItem } from '@/models/steppers/stepDataItem';
import onboardingFormService from '@/services/onboardingFormService';
import ReadOnlyForm from '@/components/onboarding/ReadOnlyForm.vue';
import useAuthenticatedUser from '@/use/authenticatedUser';

export default {
  components: {
    'cp-readonly-form': ReadOnlyForm
  },
  setup() {

    const router = useRouter();
    const route = useRoute();
    let onBoardingForm = new BasicOnboardingFormData();
    const onboardingFormId = ref();
    let isComponentLoaded = ref(false);
    let stepperData: OnboardingStepperItems[] = reactive(ONBOARDING_STEPPER_ITEMS);
    let data = ref({});
    let formData = ref({});
    const lastStepCompleted = ref(false);
    let activeStepper: OnboardingStepperItems = <any>[];
    let step = ref(0);
    const { isAuthenticatedAtLeastInternal } = useAuthenticatedUser();

    onMounted(async () => {
      data.value = await onboardingFormService.getOnboardingForm(onboardingFormId.value);
      formData.value = await onboardingFormService.getOnboardingFormStatus(onboardingFormId.value);
    });

    const onComponentLoaded = (isLoaded: boolean): void => {
      isComponentLoaded.value = isLoaded;
    };

    watch(async () => route.params, async () => {
      onboardingFormId.value = route.params.id;
      step.value = parseInt(route.params['step'].toString() || '0');
      activeStepper = ONBOARDING_STEPPER_ITEMS[0];
      if(step.value === 1 && !isAuthenticatedAtLeastInternal.value) {
        step.value -= 1;
        router.push(`/onboarding/${onboardingFormId.value}/${step.value}`);
      }
    }, { immediate: true });

    const isActive = (item) => route.path.endsWith(item.step);

    const stepClick = (h: StepDataItem<BasicOnboardingFormData>): void => {
      if (h.step > step.value) {
        return;
      }

      router.push(`/onboarding/${onboardingFormId.value}/${h.step}`);
    };

    const nextPage = (event) => {
      step.value = event.pageIndex + 1;

      if(step.value === 1 && !isAuthenticatedAtLeastInternal.value) step.value += 1;

      const maxStep = maxBy(activeItems.value, 'step')?.step || 0;
      const navUrl =
        step.value > maxStep
          ? '/admin/onboarding-list'
          : `/onboarding/${onboardingFormId.value}/${step.value}`;
      router.push(navUrl);
    };

    const activeItems = computed(() => !isAuthenticatedAtLeastInternal.value ? activeStepper.items?.filter(item => item.step !== 1) : activeStepper.items);

    const skipStep = (event) => {
      step.value = event.pageIndex;
      const navUrl = `/onboarding/${onboardingFormId.value}/${step.value}`;
      router.push(navUrl);
      data.value['lastStepCompleted'] = event.pageIndex - 1;
    };


    const stepCompleted = (step) => {
      data.value['lastStepCompleted'] = step;
    };

    const prevPage = () => {
      step.value = step.value - 1;
      if(step.value === 1 && !isAuthenticatedAtLeastInternal.value) step.value -= 1;
    };

    const complete = () => {
      data.value['lastStepCompleted'] = OnboardingWorkflowStep.Confirm;
      lastStepCompleted.value = true;
    };

    const stepStatus = (status) => status ? 'COMPLETE' : 'PENDING';

    return {
      nextPage, prevPage, complete, route, onboardingFormId, data, formData, isComponentLoaded, step, isActive, skipStep,
      activeStepper, onBoardingForm, stepperData, stepStatus, stepClick, activeItems, onComponentLoaded, stepCompleted, lastStepCompleted,
    };
  }
};
</script>

<style scoped lang="scss">
::v-deep(b) {
  display: block;
}


::v-deep(.p-card-body) {
  padding: 2rem;
}
</style>
