import { Filter } from '@/models/filters/filter';
import { FieldCode } from '@/models/filters/fieldCode';
import { RelativeDateRange } from '@/models/filters/relativeDateRange';
import { FriendlyFilterRow } from '@/models/filters/filterFactory';
import { startCase } from 'lodash';

export class RelativeDateRangeFilter implements Filter {
    field: FieldCode;
    value = RelativeDateRange.Custom;
    readonly $type = 'RelativeDateRangeFilter';

    constructor(opts: Partial<RelativeDateRangeFilter> = {}) {
        Object.assign(this, opts);
    }

    public isEmpty() {
        return !this.value || this.value === RelativeDateRange.Custom;
    }

    public clearValue() {
        this.value = RelativeDateRange.Custom;
    }

    getFriendlyText(labelOverride?: string): FriendlyFilterRow {
        const label = `${labelOverride || startCase(this.field)}`;
        let dateText = '';
        let operation = '';

        // NOTE: The filter is never used when the value is 'custom'
        if (this?.value !== RelativeDateRange.Custom) {
            operation = 'is in';
            switch (this.value) {
                case RelativeDateRange.Last30Days:
                    dateText = 'the last 30 days';
                    break;
                case RelativeDateRange.Last60Days:
                    dateText = 'the last 60 days';
                    break;
                case RelativeDateRange.Last90Days:
                    dateText = 'the last 90 days';
                    break;
                case RelativeDateRange.Last1Year:
                    dateText = 'the last year';
                    break;
                case RelativeDateRange.Last2Years:
                    dateText = 'the last 2 years';
                    break;
                case RelativeDateRange.Last1CalendarYear:
                    dateText = 'the last calendar year';
                    break;
                case RelativeDateRange.Last2CalendarYears:
                    dateText = 'the last 2 calendar years';
            }
        }

        return <FriendlyFilterRow>{ filterName: label, operation, value: dateText };
    }
}
