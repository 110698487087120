import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import { CurrentSearchState, SearchDefaults } from '../models/currentSearchState';
import { PageableFilterCriteria } from '@/models/search/pageableFilterCriteria';
import defaultFiltersService from '@/services/defaultFiltersService';
import { FilterModel } from '@/models/search';
import defaultColumnService from '@/services/defaultColumnService';
import { FieldCode, createFilter } from '@/models/filters';

const FILTERS_KEY = 'cp-filters';

export const SEARCH_MODULE = Object.freeze({
  SET_CURRENT_SEARCH: 'setCurrentSearch',
  GET_CURRENT_SEARCH: 'getCurrentSearch',
  CLEAR_CURRENT_SEARCH: 'clearCurrentSearch',
  GET_CURRENT_PAGEINFO: 'getCurrentPageInfo',
  SET_SEARCH_DEFAULTS: 'setSearchDefaults',
  SET_EXPORT_DEFAULTS: 'SetExportDefaults',
  GET_SEARCH_DEFAULTS: 'getSearchDefaults',
  GET_EXPORT_DEFAULTS: 'getExportDefaults',
  SET_SEARCH_FIELDS: 'setSearchFields',
  GET_SEARCH_FIELDS: 'getSearchFields',

});

function initFromLocalStorage(): FilterModel | undefined {
  const filters = localStorage.getItem(FILTERS_KEY) as string;

  if (filters != 'undefined' && filters) {
    const filtersJSON = JSON.parse(filters) as FilterModel;

    return {
      savedSearchId: filtersJSON.savedSearchId,
      filters: filtersJSON.filters.map(f => createFilter(f.$type, f)),
      indices: filtersJSON.indices
    };
  }

  return undefined;
}

const GETTERS: GetterTree<CurrentSearchState, any> = {

  [SEARCH_MODULE.GET_CURRENT_SEARCH](state): any {
    return state.criteria;
  },
  [SEARCH_MODULE.GET_CURRENT_PAGEINFO](state): any {
    return state.pagination;
  },
  [SEARCH_MODULE.GET_SEARCH_DEFAULTS](state): any {
    return state.defaults;
  },
  [SEARCH_MODULE.GET_EXPORT_DEFAULTS](state): any {
    return state.exportColumns;
  },
  searchFields: state => state.searchFields
};

const MUTATIONS: MutationTree<CurrentSearchState> = {
  [SEARCH_MODULE.SET_CURRENT_SEARCH](state, data: { criteria?: PageableFilterCriteria, pagination: any }) {
    state.criteria = data.criteria || null;
    state.pagination = data.pagination;
    localStorage.setItem(FILTERS_KEY, JSON.stringify(data.criteria?.filterModel));
    console.debug(`SEARCH_MODULE.SET_CURRENT_SEARCH() - { state.criteria, FILTERS_KEY }`, data.criteria, FILTERS_KEY);
  },
  [SEARCH_MODULE.SET_SEARCH_DEFAULTS](state, data: SearchDefaults) {
    state.defaults = data;
    localStorage.setItem(FILTERS_KEY, JSON.stringify(data));
  },
  [SEARCH_MODULE.SET_EXPORT_DEFAULTS](state, data: FieldCode[]) {
    state.exportColumns = data;
  },
  [SEARCH_MODULE.SET_SEARCH_FIELDS](state, data: { fields: FieldCode[] }) {
    state.searchFields = data.fields || [];
  }
};

const ACTIONS: ActionTree<CurrentSearchState, any> = {
  [SEARCH_MODULE.GET_SEARCH_DEFAULTS]({ commit }, data: { userId: string }) {
    return defaultFiltersService.getDefault(data.userId).then((filterModel: FilterModel) => {
      const savedSearchId = filterModel.savedSearchId;
      const filters = filterModel.filters;
      const indices = filterModel.indices;
      commit(SEARCH_MODULE.SET_SEARCH_DEFAULTS, { savedSearchId, filters, indices });
    });
  },
  [SEARCH_MODULE.GET_EXPORT_DEFAULTS]({ commit }, data: { userId: string }) {
    return defaultColumnService.get(data.userId).then((exportColumns: FieldCode[]) => {
      commit(SEARCH_MODULE.SET_EXPORT_DEFAULTS, exportColumns);
    });
  },
  [SEARCH_MODULE.CLEAR_CURRENT_SEARCH]({ commit }) {
    localStorage.removeItem(FILTERS_KEY);
    commit(SEARCH_MODULE.SET_CURRENT_SEARCH, { criteria: null, pagination: null });
  }
};

export const searchModule: Module<CurrentSearchState, any> = {
  namespaced: true,
  state: {
    criteria: null,
    pagination: null,
    defaults: initFromLocalStorage(),
    searchFields: undefined,
    exportColumns: undefined
  },
  getters: GETTERS,
  mutations: MUTATIONS,
  actions: ACTIONS
};
