
<template>
  <div v-if="data1['generalInfo']">
    <div class="mt-2 ml-3">
      <h2> Onboarding Form </h2>
    </div>
    <div v-if="data1['generalInfo']['isSubmitted'] && !data1['generalInfo']['isExpired']" class="col-5 pb-2 ml-2">
      <Message :closable="false" icon="none">
        <div class="col-2 flex ml-n8">
          <div class="material-icons col-1 "
            :style="{ color: data1['generalInfo']['isSubmitted'] ? '#57BFC3' : 'grey', fontSize: '1.8rem' }">
            check_circle
          </div>
          <div class="col-1 ml-3 mt-2">
            Submitted
          </div>
        </div>
      </Message>
    </div>

    <div v-if="data1['generalInfo']['isExpired']" class="col-5 pb-2 ml-2">
      <Message :closable="false" icon="none">
        <div class="col-2 flex ml-n8">
          <img alt="Clock" :src="require('../../assets/onboarding/icons/timer-alert-outline.svg')">
          <div class="col-1 mt-2">
            Expired
          </div>
        </div>
      </Message>
    </div>

    <div v-if="!data1['generalInfo']['isSubmitted'] || data1['generalInfo']['isExpired']" style="
        background-color: #d0e7ff54;
        border: 0;
        box-shadow: none;
        border-radius: 14px;" class="mt-2 max-w-full m-3">
      <div class="lg:flex col-12">
        <div v-if="data1['generalInfo']" class="col-6">
          <h4>{{ data1["generalInfo"]["name"] }} </h4>
        </div>


        <div v-if="isAuthenticatedAtLeastInternal" class="col-6 flex flex-row-reverse">
          <Button label="New" class="p-button-success p-button-raised" @click="resendOnBoardingForm">
            Resend Onboarding Form
            <span class="material-icons pl-2"> email</span>
          </Button>
        </div>
      </div>
    </div>
    <div v-if="isAuthenticatedAtLeastInternal" class="col-12">
      <Card v-if="data1['generalInfo']" class="p-reader-card ml-2  max-w-full onboarding-card">
        <template #subtitle>
          <div class="flex" style="color: #1b3557">
            <div class="col-6">
              <h3>Basic Info</h3>
            </div>
            <div v-if="!data1['generalInfo']['isSubmitted'] && !data1['generalInfo']['isExpired']"
              class="flex flex-row-reverse col-6">
              <router-link :to="'/onboarding/' + data1.id + '/1'" class="text-decoration-none pl-1">
                <div class="col-1 edit-text">
                  Edit
                </div>
                <div class="material-icons font-bold cursor-pointer col-1 flex ml-4 edit-top">
                  edit
                </div>
              </router-link>
            </div>
          </div>
        </template>
        <template #content>
          <div class="flex">
            <div class="col-4">
              <div class="text-sm font-bold ">
                <label class="p-0">Name</label>
              </div>
              <div class="p-0">
                {{ data1["generalInfo"].name }}
              </div>
            </div>
            <div class="col-4">
              <div class="text-sm font-bold ">
                <label class="p-0">Email Address</label>
              </div>
              <div class="p-0">
                {{ data1["generalInfo"].email }}
              </div>
            </div>
            <div class="col-4">
              <div class="text-sm font-bold ">
                <label class="p-0">Phone Number</label>
              </div>
              <div class="p-0">
                {{ data1["generalInfo"].phone }}
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="col-4">
              <div class="text-sm font-bold ">
                <label class="p-0">Communities</label>
              </div>

              <div class="p-0">
                {{ formatOnlines(data1["generalInfo"].onlines) }}
              </div>
            </div>
            <div class="col-4" />

            <div class="col-4">
              <div class="text-sm font-bold">
                <label class="p-0">Account Manager</label>
              </div>
              <div class="p-0">
                {{ data1["generalInfo"].accountManager }}
              </div>
            </div>
          </div>
          <div class="flex lg:gap-3">
            <div class="col-8">
              <div class="text-sm">
                <label class="p-primary-color"> Are you a new client or is this the renewal of your program with
                  us? </label>
              </div>
            </div>

            <div class="col-4">
              <div class="p-0">
                <label class="p-primary-color ">{{ data1["generalInfo"].isNewclient ? 'Yes' : 'No' }}</label>
              </div>
            </div>
          </div>
          <div class="flex lg:gap-3">
            <div class="col-8">
              <div class="text-sm">
                <label class="p-primary-color"> Have you purchased access to multiple audiences (publications)? </label>
              </div>
            </div>

            <div class="col-4">
              <div class="p-0">
                <label class="p-primary-color ">{{
                  data1["generalInfo"].purchaseMultipleOnlines ? 'Yes' : 'No'
                }}</label>
              </div>
            </div>
          </div>
          <div class="flex lg:gap-3">
            <div class="col-8">
              <div class="text-sm">
                <label class="p-primary-color"> If you have multiple audiences, would you like to promote the same
                  content to each audience? </label>
              </div>
            </div>

            <div class="col-4">
              <div class="p-0">
                <label class="p-primary-color">{{ data1["generalInfo"].sameContentPerSite ? 'Yes' : 'No' }}</label>
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>
    <br>
    <div class="col-12">
      <Card v-if="data1['companyInfo']" class="p-reader-card ml-2 max-w-full onboarding-card">
        <template #subtitle>
          <div class="flex" style="color: #1b3557">
            <div class="col-6">
              <h3>Company Info</h3>
            </div>
            <div v-if="!data1['generalInfo']['isSubmitted'] && !data1['generalInfo']['isExpired']"
              class="flex flex-row-reverse col-6">
              <router-link :to="'/onboarding/' + data1.id + '/2'" class="text-decoration-none pl-1">
                <div class="col-1 edit-text">
                  Edit
                </div>
                <div class="material-icons font-bold cursor-pointer col-1 flex ml-4 edit-top">
                  edit
                </div>
              </router-link>
            </div>
          </div>
        </template>
        <template #content>
          <div class="flex">
            <div class="col-12">
              <div class="text-sm font-bold ">
                <label class="p-0">Company Name</label>
              </div>
              <div class="p-0">
                {{ data1["companyInfo"].name }}
              </div>
            </div>
          </div>

          <div class="flex">
            <div class="col-4">
              <div class="text-sm font-bold ">
                <label class="p-0">Contact Name</label>
              </div>
              <div class="p-0">
                {{ data1["companyInfo"].contactName }}
              </div>
            </div>
            <div class="col-4">
              <div class="text-sm font-bold ">
                <label class="p-0">Contact Title</label>
              </div>
              <div class="p-0">
                {{ data1["companyInfo"].contactTitle }}
              </div>
            </div>
            <div class="col-4">
              <div class="text-sm font-bold ">
                <label class="p-0">Phone Number</label>
              </div>
              <div class="p-0">
                {{ data1["companyInfo"].phone }}
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="col-12">
              <div class="text-sm font-bold ">
                <label class="p-0">Street Address</label>
              </div>
              <div class="p-0">
                {{ data1["companyInfo"].address1 }}
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="col-4">
              <div class="text-sm font-bold ">
                <label class="p-0">City</label>
              </div>
              <div class="p-0">
                {{ data1["companyInfo"].city }}
              </div>
            </div>
            <div class="col-4">
              <div class="text-sm font-bold ">
                <label class="p-0">State</label>
              </div>
              <div class="p-0">
                {{ data1["companyInfo"].state }}
              </div>
            </div>
            <div class="col-4">
              <div class="text-sm font-bold ">
                <label class="p-0">Zip Code</label>
              </div>
              <div class="p-0">
                {{ data1["companyInfo"].postalCode }}
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="col-4">
              <div class="text-sm font-bold ">
                <label class="p-0">Logo</label>
              </div>
              <div v-if="data1['logoUrls']">
                <div v-for="(file, index) in data1['logoUrls']" :key="index" class="p-0">
                  <img alt="logo" :src="file.logoUrl" height="100" style="width:100%;object-fit: contain;">
                </div>
              </div>
              <div v-else class="p-0">
                <img alt="logo" :src="noLogSvg.img" height="100" width="100">
              </div>
            </div>
            <div class="col-6">
              <div class="text-sm font-bold ">
                <label class="p-0">Company Profile</label>
              </div>
              <div class="p-0" :title="data1['companyInfo'].profile">
                <span v-html="data1['companyInfo'].profile" />
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>
    <br>

    <div class="col-12">
      <Card v-if="data1['contacts']" class="p-reader-card ml-2 max-w-full onboarding-card" style="--bg-color:#FBFBFB">
        <template #subtitle>
          <div class="flex" style="color: #1b3557">
            <div class="col-6">
              <h3>Configure for Activity Reports</h3>
            </div>
            <div v-if="!data1['generalInfo']['isSubmitted'] && !data1['generalInfo']['isExpired']"
              class="flex flex-row-reverse col-6">
              <router-link :to="'/onboarding/' + data1.id + '/3'" class="text-decoration-none pl-1">
                <div class="col-1 edit-text">
                  Edit
                </div>
                <div class="material-icons font-bold cursor-pointer col-1 flex ml-4 edit-top">
                  edit
                </div>
              </router-link>
            </div>
          </div>
        </template>
        <template #content>
          <div class="flex">
            <div class="col-12  pb-10">
              <DataTable id="preview-contacts" :value="data1['contacts']" data-key="id"
                style="background:transparent!important">
                <Column header-style="display:none" body-style="background:#FBFBFB">
                  <template #body="slotProps">
                    <div class="p-2">
                      <Avatar class="font-medium " shape="circle" style="background:#1B3557">
                        <span style="color: #ffffff" class="pt-1"> {{ getUserInitials(`${slotProps.data.firstName }
                        ${ slotProps.data.lastName }`)
                        }}</span>
                      </Avatar>
                    </div>
                  </template>
                </Column>
                <Column header-style="display:none" body-style="background: #FBFBFB">
                  <template #body="slotProps">
                    {{ slotProps.data.firstName + ' ' + slotProps.data.lastName }}
                  </template>
                </Column>
                <Column header-style="display:none" body-style="background: #FBFBFB">
                  <template #body="slotProps">
                    {{ slotProps.data.email }}
                  </template>
                </Column>
              </DataTable>
            </div>
          </div>
        </template>
      </Card>
    </div>
    <br>

    <div v-if="data1['content']">
      <div v-for="c in data1['content']" :key="c.title" class="col-12">
        <Card v-if="c" class="p-reader-card ml-2 max-w-full onboarding-card">
          <template #subtitle>
            <div class="flex" style="color: #1b3557">
              <div class="col-6">
                <h3>Content</h3>
              </div>
              <div v-if="!data1['generalInfo']['isSubmitted'] && !data1['generalInfo']['isExpired']"
                class="flex flex-row-reverse col-6">
                <router-link :to="'/onboarding/' + data1.id + '/4'" class="text-decoration-none pl-1">
                  <div class="col-1 edit-text">
                    Edit
                  </div>
                  <div class="material-icons font-bold cursor-pointer col-1 flex ml-4 edit-top">
                    edit
                  </div>
                </router-link>
              </div>
            </div>
          </template>
          <template #content>
            <div class="col-12">
              <div class="text-sm font-bold ">
                <label class="p-0">Title</label>
              </div>
              <div class="p-0">
                {{ c.title }}
              </div>
            </div>
            <div class="col-12">
              <div class="text-sm font-bold ">
                <label class="p-0">Url</label>
              </div>
              <div class="p-0">
                {{ c.url }}
              </div>
            </div>
            <div class="col-12">
              <div class="text-sm font-bold ">
                <label class="p-0">Keywords</label>
              </div>
              <div class="p-0">
                {{ c.keyword }}
              </div>
            </div>
            <div class="flex">
              <div class="col-7 pb-0">
                <div class="text-sm font-bold ">
                  <label class="p-0">Files</label>
                </div>
              </div>
              <div class="col-3 pb-0">
                <div class="text-sm font-bold ">
                  <label class="p-0">Preview</label>
                </div>
              </div>
            </div>
            <div v-for="(file, index) in c.uploadedFileUrls" :key="index" class="flex">
              <div class="col-7">
                <div class="p-0 truncate-text" :title="file.fileUrl">
                  {{ file.fileUrl }}
                </div>
              </div>
              <div class="col-3">
                <div class="p-0">
                  <img v-if="file.isImage" alt="file" :src="file.fileUrl" height="100" style="width:100%;object-fit: contain;">
                  <img v-else :src="noImageSvg.img" alt="file" height="100" style="width:100%;object-fit: contain;">
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script lang="ts">


import OnboardingFormService from '@/services/onboardingFormService';
import { useToast } from 'primevue/usetoast';
import { getInitials } from '@/utils/userUtils';
import useAuthenticatedUser from '@/use/authenticatedUser';

export default {
  props: {
    data1: Object,
  },
  setup(props) {
    const { isAuthenticatedAtLeastInternal } = useAuthenticatedUser();
    const toast = useToast();
    const resendOnBoardingForm = async () => {
      await OnboardingFormService.resendOnBoardingForm(props.data1.id, toast);
    };

    const noImageSvg = {
      img: require('../../assets/onboarding/images/broken-image-with-text.svg')
    };

    const getUserInitials = (name) => {
      if (name) {
        const nameParts = name.split(' ');
        return getInitials(nameParts);
      } else return '';
    };

    const formatOnlines = (onlines: string[]) => (onlines || []).join(', ');

    return { resendOnBoardingForm, getUserInitials, formatOnlines, isAuthenticatedAtLeastInternal, noImageSvg };
  }

};
</script>
<style scoped>
.truncate-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  max-width: 520px;
}
</style>

<style scoped>
.onboarding-card {
  color: #1B3557;
  --bg-color: #FBFBFB;
  border: 1px solid #D3D8E6;
  background: #FBFBFB 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 2px #EDEDED;
  opacity: 1;

}

.edit-top {
  margin-top: -2.2em;
}

.edit-text {
  font-size: 1.3em;
}
</style>