
export const DEFAULT_PAGINATION = {
  page: 1,
  itemsPerPage: 50,
  sortBy: ['date'],
  sortDesc: [true],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false
};
