<template>
  <div class="grid mr-3">
    <div class="col-12 pb-0">
      <div class="flex my-3">
        <h1 class="line-height-1">
          {{ pageTitle }}
        </h1>
        <!-- <span class="help-icon ml-2">?</span> -->
      </div>
    </div>
    <div class="col-12">
      <div class="profile-name p-primary-color">
        <h2>{{ fullName }}</h2>
      </div>
      <div v-if="saveSuccess" class="success-message flex p-0 m-0 pr-2">
        Saved.
        <span class="material-icons-outlined pl-1"> check_circle_outline </span>
      </div>
      <TabView v-if="isDataLoaded" ref="tabview1" v-model:activeIndex="activeTab" lazy
        @tab-click="navigateTab(activeTab)">
        <TabPanel :header="profileTabTitle">
          <cp-manage-user-profile v-if="!isMyProfile && activeTab == 0" v-model="user" />
          <cp-my-profile v-if="isMyProfile && activeTab == 0" v-model="user" />
        </TabPanel>
        <TabPanel header="Saved Searches">
          <cp-manage-saved-searches :model-value="user" :is-my-profile="isMyProfile" />
        </TabPanel>
        <TabPanel header="Default Export Columns">
          <cp-default-columns :edited-user="user" />
        </TabPanel>
        <TabPanel v-if="isAuthenticatedAtLeastInternal" header="Setup Activity Reports">
          <cp-manage-activity-reports v-model="user" />
        </TabPanel>
      </TabView>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from 'vuex';

import { AppUser } from '@/models/appUser';
import { ProfileMenu } from '@/models/ProfileMenu';

import { MSG_MUTATIONS } from '@/store/modules/msg.module';
import { AUTH_ACTIONS } from '@/store/modules/auth.module';

import UserService from '@/services/userService';

import ManageUserProfile from '@/components/profile/ManageUserProfile.vue';
import DefaultColumns from '@/components/admin/DefaultColumns.vue';
import MyProfile from '@/components/profile/MyProfile.vue';
import ManageActivityReports from '@/components/profile/ManageUserActivityReports.vue';
import ManageSavedSearches from '@/components/profile/ManageSavedSearches.vue';
import { computed, ref, watch } from 'vue';
import useAuthenticatedUser from '@/use/authenticatedUser';
import { useRoute, useRouter } from 'vue-router';
import { TOAST_LIFETIME } from '@/models/common/constants';
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { USER_MODULE } from '@/store/modules/user.module';

export default {
  name: 'CpEditUser',
  components: {
    'cp-default-columns': DefaultColumns,
    'cp-manage-user-profile': ManageUserProfile,
    'cp-my-profile': MyProfile,
    'cp-manage-activity-reports': ManageActivityReports,
    'cp-manage-saved-searches': ManageSavedSearches
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const user = ref(new AppUser());
    const activeTab = ref(0);
    const saveSuccess = ref(false);
    const isDataLoaded = ref(false);

    const { authenticatedUser, isAuthenticatedAtLeastInternal } = useAuthenticatedUser();
    const rules = computed(() => {
      const validationModel = {
        user: {
          firstName: {
            required
          },
          lastName: {
            required
          },
          email: { required }
        }
      };

      return validationModel;
    });
    const v$ = useVuelidate(rules, { user });

    const isMyProfile = computed(() => user.value.id === authenticatedUser.value?.userId);
    const fullName = computed(() => user.value.email ? `${user.value.firstName} ${user.value.lastName}` : '');

    const pageTitle = computed(() => isMyProfile.value ? 'Profile Settings' : 'Manage User Profile');
    const profileTabTitle = computed(() => isMyProfile.value ? 'My Profile' : 'Profile');



    const routeChange = async () => {
      if (route.params['id']) {
        user.value = await UserService.get(route.params['id'].toString());
        // await getUserDefaults();
        isDataLoaded.value = true;

        store.commit(`user/${USER_MODULE.SET_CURRENT_USER}`,
          {
            email: user.value.email,
            userId: user.value.id,
            firstName: user.value.firstName,
            lastName: user.value.lastName,
            searchFields: user.value.activityFields,
            // defaults: userDefaults.value,
            clients: user.value.companies,
            isClientUser: user.value.role === 'Client'
          });
        // console.debug(`EditUser.routeChange() - { id, user }`, route.params['id'], user.value);
      }
      if (route.query['active']) {
        const active = Number(route.query['active'].toString());
        activeTab.value = active;
      }
    };
    const navigateTab = (tab) => {
      if (tab == ProfileMenu.Filters) {
        router.push('?active=' + ProfileMenu.Filters);
      } else if (tab == ProfileMenu.Columns) {
        router.push('?active=' + ProfileMenu.Columns);
      } else if (tab == ProfileMenu.ActivityReports) {
        router.push('?active=' + ProfileMenu.ActivityReports);
      } else {
        router.push('?active=' + ProfileMenu.Profile);
      }
    };
    const save = async () => {
      const isValid = (await v$?.value.$validate()) ?? true;
      if (!isValid) {
        return;
      }
      try {
        await _updateUser();
        saveSuccess.value = true;
        setTimeout(() => { saveSuccess.value = false; }, TOAST_LIFETIME);
      } catch (error) {
        store.commit(`msg/${MSG_MUTATIONS.MSG}`, { text: error, type: 'error' });
        // NOTE: Error thrown to be detected by Vue.config.errorHandler
        throw error;
      }
    };
    const _updateUser = async () => {
      user.value = await UserService.update(user.value);

      if (isMyProfile.value) {
        await store.dispatch(`auth/${AUTH_ACTIONS.RESET_TOKEN}`);
      }
      store.commit(`msg/${MSG_MUTATIONS.MSG}`, { text: 'User was successfully saved 👍' });
    };

    watch(route, () => routeChange, { immediate: true });

    routeChange();

    return {
      activeTab
      , isAuthenticatedAtLeastInternal
      , isDataLoaded
      , isMyProfile
      , pageTitle
      , profileTabTitle
      , saveSuccess
      , user
      , fullName
      , navigateTab
      , save
    };
  }
};
</script>

<style lang="scss">
.mobile-toolbar {
  .v-toolbar__content {
    height: 100% !important;
  }
}

.profile-name {
  // margin-top: $margin;
  // margin-left: 20px;
  margin-top: 20px;
  margin-left: 20px;
  position: absolute;
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .profile-name {
    margin-top: 15px;
  }
}
</style>
