import { computed, Ref, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import { email, maxLength, required, requiredIf } from '@vuelidate/validators';

import { AppUser } from '@/models/appUser';
import { BASIC_NOTIFICATION_CLAIM_LEVELS, EMPLOYEE_CLAIM_LEVELS } from '@/models/security';
import { UserRoleEnum, UserRoleOptions } from '@/models/userRole';
import { JwtTokenUser } from '@/store/models';

export default function useUserSecurity(selectedUser: Ref<AppUser>) {
  const loading = ref(false);
  const error = ref<any>(null);
  const user = ref(selectedUser.value);

  const route = useRoute();
  const store = useStore();


  /**
   * Computed Properties
  */
  const isNew = computed(() => route.params['id'] === undefined);
  const loggedInUser = computed(() => <JwtTokenUser>store.state.auth.user);
  const isMyProfile = computed(() => store.state.auth.user.userId === selectedUser.value.id);
  const notificationClaimLevelLookups = computed(() => {
    if (user.value.role === 'Client') {
      return BASIC_NOTIFICATION_CLAIM_LEVELS;
    }
    if (loggedInUser.value.hasMinRole('Internal')) {
      return EMPLOYEE_CLAIM_LEVELS;
    }
    return [];
  });
  const userRoleAtLeastInternal = computed(() => {
    if (user.value.roleEnum >= UserRoleEnum.Internal) {
      user.value.companies = [];  // Remove company claims
      return true;
    }
    return false;
  });
  const loggedInUserAdmin = computed(() => (<JwtTokenUser>loggedInUser.value).hasMinRole('Admin'));
  const loggedInUserAtLeastInternal = computed(() => (<JwtTokenUser>loggedInUser.value).hasMinRole('Internal'));
  const userRoles = computed(() => {
    if (isNew.value) {
      return UserRoleOptions.createRoles(loggedInUser.value.role);
    }
    return user.value.id === loggedInUser.value.userId
      ? UserRoleOptions.availableRolesForSelf(user.value.role)
      : UserRoleOptions.availableRolesForOthers(loggedInUser.value.role);
  });
  const validations = computed(() => {
    const includeCompanies = user.value.role === 'Client';
    const isClientUserProfile = user.value.role === 'Client';
    const includeAnalystTags = (user.value.activityFields ?? [])?.includes('AnalystTags') && isClientUserProfile;


    return {
      user: {
        firstName: { required, maxLength: maxLength(50) },
        lastName: { required, maxLength: maxLength(50) },
        email: { required, email },
        companies: { required: requiredIf(() => includeCompanies), maxLength: maxLength(20) },
        analystTags: { required: requiredIf(() => includeAnalystTags) }
      }
    };
  });

  const userActivitySearchFields = computed(() => store.state.auth?.user.activityFields ?? []);

  return {
    user, loading, error, notificationClaimLevelLookups, validations, userRoleAtLeastInternal, loggedInUserAtLeastInternal,
    loggedInUserAdmin, userRoles, isNew, isMyProfile, loggedInUser, userActivitySearchFields
  };
}