<template>
  <div class="flex p-fluid w-full">
    <div class="col-12 p-0">
      <span class="p-float-label">
        <AutoComplete v-if="isSingleSelectMode" v-model="singleSelectOption" :suggestions="filteredOptions" field="name"
          :multiple="false" force-selection :class="{ 'p-invalid': v$.singleSelectOption.$invalid && submitted }"
          @complete="onCompanySearchChange($event.query)" />
        <AutoComplete v-else :id="modelValue.field" v-model="selectedOptions" :suggestions="filteredOptions" field="name"
          :multiple="true" :complete-on-focus="true" :input-class="{ 'p-invalid' : invalidSelectedOptions.length > 0 }" 
          :class="{ 'p-invalid': (v$.selectedOptions.$invalid && submitted) || invalidSelectedOptions.length > 0 }"
          @complete="onCompanySearchChange($event.query)" @item-select="getFocus(modelValue.field)" />
        <label for="client">Clients</label>
      </span>
      <small v-if="(!isSingleSelectMode && v$.selectedOptions.required.$invalid && submitted) || v$.$pending"
        class="p-error">
        {{ v$.selectedOptions.required.$message.replace("The value", "Clients") }}
      </small>
      <small v-if="(isSingleSelectMode && v$.singleSelectOption.required.$invalid && submitted) || v$.$pending"
        class="p-error">
        {{ v$.singleSelectOption.required.$message.replace("The value", "Clients") }}
      </small>
      <small v-if="invalidSelectedOptions.length > 0" class="p-error">
        User does not have claim{{ invalidSelectedOptions.length > 1 ? 's' : '' }} for <strong>
          {{ invalidSelectedOptions.map(c => c.name).join(", ") }}
        </strong>
      </small>
    </div>
  </div>
</template>

<script lang="ts">
import { TermsFilter, FieldCode } from '@/models/filters';
import { CompanyClaim } from '@/models/security';
import CmsCompanyService from '@/services/cmsCompanyService';
import { PropType, ref, computed, watch } from 'vue';
import useAuthenticatedUser from '@/use/authenticatedUser';
import { requiredIf } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { debounce, isEqual } from 'lodash';
import useCurrentUser from '@/use/currentUser';
import { DEFAULT_TIMEOUT_TIME } from '@/models/common/constants';

const DEFAULT: TermsFilter = new TermsFilter({ field: 'Clients', op: 'Contains' });

export default {
  name: 'CpCompaniesSearchField',
  props: {
    modelValue: { type: Object as PropType<TermsFilter>, default: new TermsFilter() },
    submitted: Boolean,
    requiredFields: { type: Array as PropType<FieldCode[]>, default: () => [] },
    isUserProfile: { type: Boolean, default: false },
  },
  emits: ['update:modelValue'],
  setup(props, { emit, expose }) {
    const filteredOptions = ref<Array<CompanyClaim>>([]);
    const selectedOptions = ref<Array<CompanyClaim>>(<Array<CompanyClaim>>props.modelValue.value);
    const singleSelectOption = ref<CompanyClaim>(<CompanyClaim>props.modelValue?.value[0]);
    const requiredFields = ref<Array<FieldCode>>(props.requiredFields);

    const { isAuthenticatedAtLeastInternal, userCompanies } = useAuthenticatedUser();
    const options = ref<Array<CompanyClaim>>([]);
    const { currentUser } = useCurrentUser();
    const isUserProfile = ref(props.isUserProfile);

    const isSingleSelectMode = ref(false); // we dont' need any more, just in case we will need in future
    
    const invalidSelectedOptions = computed(() => 
      (isUserProfile.value && isAuthenticatedAtLeastInternal && currentUser.value.isClientUser)
        ? selectedOptions.value.filter(c => !currentUser.value.clients?.some(claim => claim.id === c.id)) 
        : []
    );
    
    const validations = computed(() => {
      const isClientUser = !isAuthenticatedAtLeastInternal;
      const isRequired = requiredFields.value.includes('Clients');

      return {
        selectedOptions: {
          required: requiredIf(() => (isRequired || isClientUser) && !isSingleSelectMode.value),
        },
        singleSelectOption: {
          required: requiredIf(() => isSingleSelectMode.value && isRequired)
        }
      };
    });
    const singleSelectOptionAsArray = computed(() =>
      (!singleSelectOption.value)
        ? []
        : [singleSelectOption.value].filter(x => x)
    );

    const v$ = useVuelidate(validations, { selectedOptions, singleSelectOption });
    let query: string;

    const onCompanySearchChange = debounce(async (q: string) => {
      if (q.length > 0) {
        query = q;
      }

      if (!query?.length) {
        return;
      }
      
      if (isAuthenticatedAtLeastInternal.value) {
        if (isUserProfile.value && currentUser.value.isClientUser) {
          options.value = currentUser.value.clients?.filter((client) =>
            client.name.toLowerCase().includes(query.toLowerCase()) ||
            client.id.toString().startsWith(query)
          ) || [];
        } else {
          options.value = (await CmsCompanyService.findByNameOrIdStartingWith(query)) || [];
        }
      } else {
        options.value = userCompanies.value?.filter((client) =>
          client.name.toLowerCase().includes(query.toLowerCase()) ||
          client.id.toString().startsWith(query)
        ) || [];
      }

      const invalidOptions = isSingleSelectMode.value ? singleSelectOptionAsArray.value : selectedOptions.value;
      filteredOptions.value = options.value.filter((e1) => invalidOptions.findIndex((e2) => e2.name == e1.name) === -1);
    }, DEFAULT_TIMEOUT_TIME);

    const updateData = (newVal: Array<CompanyClaim>) => {
      const companyFilter = new TermsFilter({ ...DEFAULT, value: newVal });
      emit('update:modelValue', companyFilter);
    };
    watch(selectedOptions, (newVal, oldVal) => {
      if (isEqual(newVal, oldVal)) { return; }
      if (!isSingleSelectMode.value) {
        updateData(newVal);
      }
    });
    watch(singleSelectOption, (newVal, oldVal) => {
      if (isEqual(newVal, oldVal)) { return; }
      if (typeof newVal === 'string') { return; }
      if (isSingleSelectMode.value) {
        updateData(singleSelectOptionAsArray.value);
      }
    });

    const getFocus = (id: string) => {
      document.getElementById(id)?.getElementsByTagName('input')[0].focus();
    };

    expose({ v$ });

    return {
      filteredOptions,
      selectedOptions,
      singleSelectOption,
      onCompanySearchChange,
      isSingleSelectMode,
      v$,
      getFocus,
      invalidSelectedOptions
    };
  },
};
</script>
