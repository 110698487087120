import { SearchFieldComponentName } from '@/components/searchFields/metadata';
import { FieldCode } from '@/models/filters';

export class FilterConfigModel {
    excludeFields?: FieldCode[] = [];
    includeComponents?: SearchFieldComponentName[] = [];

    constructor(opts: Partial<FilterConfigModel> = {}) {
        Object.assign(this, opts);
    }
}
