<template>
  <div v-if="!isWidget" ref="chart" class="flex align-items-center pt-3" style="min-height: 400px" />
  <div v-if="isWidget" class="no-results-container pt-4">
    <div class="image-no-results">
      <img alt="No data" :src="require('../../assets/no-data-group.svg')">
    </div>
    <div class="no-results">
      No results found
    </div>
  </div>
</template>

<script lang="ts">
import { Prop } from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component';
import { Chart } from 'highcharts';
import emitter from '@/services/emitter';

@Options({})
export default class NoChartDataComponent extends Vue {
  @Prop() chartType: string;
  @Prop() isWidget: boolean = false;
  chartNoData: Chart | null = null;
  @Prop() segment: string;

  mounted() {
    switch (this.chartType) {
      case 'widget':
        return this.renderWidgetNodata();
      default:
        return;
    }
  }
  renderWidgetNodata() {
    this.isWidget = true;
    emitter.emit('nodata', true);
  }
}
</script>

<style scoped>
.image-no-results {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.no-results-container {
  margin-top: -30px;
  text-align: center;
}

.no-results {
  display: inline-block;
  margin-left: 5px;
  align-items: center;
  font-size: 1rem;
  color: #76869A
}
</style>
