<template>
  <div v-for="(_, i) in searchField.model" :key="i" :class="getClass(i)">
    <div class="col-11">
      <component :is="searchField.type" v-bind="searchField.props" v-model="searchFieldRef.model[i]"
        :submitted="submitted" :org-selected="orgSelected" :reset="reset" :field-name="searchField.name" :is-user-profile="isUserProfile" />
    </div>
    <div class="col p-fluid">
      <i v-if="canAddElements(searchField.model, i)" class="pi pi-plus-circle px-2 compound-filter-action"
        @click="addElement(searchField.model)" />
      <i v-if="i > 0" class="pi pi-minus-circle px-2 compound-filter-action" 
        @click="removeElement(searchField.model, i)" />
    </div>
  </div>
</template>

<script lang="ts">
import { SearchFieldMetaData } from '@/components/searchFields/metadata';
import { PropType, computed } from 'vue';
import { Filter } from '@/models/filters';
import { clone } from 'lodash';

export default {
  name: 'CompoundSearchFieldElements',
  props: {
    searchField: {
      type: Object as PropType<SearchFieldMetaData>,
      required: true
    },
    submitted: {
      type: Boolean
    },
    orgSelected: {
        type: Boolean
    }, 
    reset: {
     type: Boolean
    },
    isUserProfile: {
     type: Boolean
    }
  },
  setup(props) {
    const searchFieldRef = computed(() => props.searchField as SearchFieldMetaData);
    const maxElements = 7;
    
    const canAddElements = (filters: Filter | Filter[], index: number) => {
      const totalElements = (filters as Filter[]).length;
      return totalElements < maxElements && index === totalElements - 1;
    };
    
    const addElement = (filters: Filter | Filter[]) => {
      const clonedFilter = clone(filters[0]);
      clonedFilter.clearValue();
      (filters as Filter[]).push(clonedFilter);
    };
    
    const getClass = (index: number) => {
      const baseClass = 'grid flex align-items-center';
      return index === (props.searchField.model as Filter[]).length - 1 ? baseClass : `${baseClass} pb-3`;
    };
    
    const removeElement = (filters: Filter | Filter[], index: number) => (filters as Filter[]).splice(index, 1);
      
    return {
      searchFieldRef, 
      getClass,
      canAddElements,
      addElement,
      removeElement
    };
  }
};
</script>

<style scoped lang="scss">
.pi.compound-filter-action {
  cursor: pointer;
  font-size: 1.5rem;
}

</style>
