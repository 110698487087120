import { Filter } from '@/models/filters';
import { Lookup } from '@/models/lookup';
import { MSG_MUTATIONS } from '@/store/modules/msg.module';
import { onSuccess, onError } from '@/utils/toast/toastUtils';
import { Validation } from '@vuelidate/core';
import { useToast } from 'primevue/usetoast';
import { Ref, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { SavedSearchRequest, savedSearchRow } from '@/models/saved-search/savedSearchModel';
import savedSearchesService from '@/services/savedSearchesService';
import { useRoute, useRouter } from 'vue-router';

export default function useDefaultFilterActions(userId: Ref<string>, name: Ref<string | undefined>, description: Ref<string | undefined>, v$: Ref<Validation>,
  selectedIndices: Ref<Array<Lookup<string>>>, submitted: Ref<boolean>, getSearchFieldFilters: () => Filter[]) {
  const loading = ref(false);
  const store = useStore();
  const toast = useToast();
  const route = useRoute();
  const router = useRouter();
  const searchId = computed(() => route.params['id']?.toString());

  const save = async () => {
    const isValid = await _validateFilters();
    if (!isValid) {
      return false;
    }
    await _saveDefaultFilters(userId.value);
    return true;
  };

  const _saveDefaultFilters = async (userId: string) => {
    try {
      const newFilter = getSearchFieldFilters();
      const result = await savedSearchesService.save(<SavedSearchRequest>{
        id: searchId.value ?? undefined,
        userId: userId,
        name: name.value,
        description: description.value,
        filters: newFilter,
        indices: selectedIndices.value
      });
      onSuccess(toast, 'Search Saved');
      navigateToDefaultFiltersEditMode(result.id);
    } catch (err) {
      store.commit(`msg/${MSG_MUTATIONS.MSG}`, { text: err, type: 'error' });
      onError(toast, 'Error saving Search');
    }
    loading.value = false;
  };

  const navigateToDefaultFiltersEditMode = (id:string) => {
    router.push(`/admin/savedsearch/${userId.value}/${id}`);
  };

  const _validateFilters = async () => {
    loading.value = true;
    submitted.value = true;
    const isValid = (await v$?.value.$validate()) ?? true;
    if (!isValid) {
      loading.value = false;
      // console.debug(`useSearchFilterAction._validateFilters() - { isValid, submitted }`, isValid, submitted.value);
    }
    return isValid;
  };

  return { save };
}