import { ref, onUnmounted, onMounted } from 'vue';

export function usePageReload() {
  const hasReloaded = ref(false);

  // Function to check and update the reload status
  const checkReloadStatus = () => {
    const reloadFlag = sessionStorage.getItem('hasReloaded');
    if (reloadFlag) {
      hasReloaded.value = true;
    } else {
      sessionStorage.setItem('hasReloaded', 'true');
      hasReloaded.value = false;
    }
  };

  // Function to reset the reload status
  const resetReloadStatus = () => {
    sessionStorage.removeItem('hasReloaded');
    hasReloaded.value = false;
  };

  onMounted(() => {
    checkReloadStatus();
  });

  // Clean up the event listener when the component is unmounted
  onUnmounted(() => {
    resetReloadStatus();
  });

  return {
    hasReloaded
  };
}
