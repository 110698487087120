<template>
  <div class="mb-5">
    <h1 v-if="activity" class="mx-3">
      {{ activityTypeName }} from {{ activity.portal }}
    </h1>

    <div v-if="activity" class="mx-5 mb-3">
      <div class="mt-2 flex align-items-center">
        <router-link to="/dissemination-contact/dashboard"
          class="flex align-items-center back-to-activity-report-icon-link">
          <i class="pi pi-chevron-left" />
        </router-link>
        <router-link to="/dissemination-contact/dashboard" class="mx-1">
          BACK TO ACTIVITY REPORTS
        </router-link>
        <Button class="p-button-raised p-button-text p-button-plain p-button-sm text-base mx-3" icon-pos="right"
          @click="showParameters(true, data)">
          <span class="p-button-label underline">Report Parameters</span>
          <i class="material-icons text-xl pl-2">settings</i>
        </Button>
      </div>

      <div class="mt-5 flex justify-content-between">
        <div class="flex align-items-end">
          <h2>{{ activityTypeName }} Details</h2>
        </div>
        <div class="flex align-items-center">
          <div class="round-icon-container flex align-items-center justify-content-center mx-2 cursor-pointer"
            @click="downloadPDF">
            <img alt="pdf" :src="require('../../assets/icons/pdf-blue.svg')" style="width: 25px;">
          </div>
          <div class="round-icon-container flex justify-content-center mx-2 cursor-pointer" @click="downloadExcel">
            <img alt="download excel" :src="require('../../assets/icons/ms-excel-blue.svg')" style="width: 25px;">
          </div>
          <div class="round-icon-container flex justify-content-center mx-2 cursor-pointer"
            @click="showDialogShareReport = true">
            <img alt="share report" :src="require('../../assets/icons/share-blue.svg')" style="width: 25px;">
          </div>
        </div>
      </div>
    </div>
    <individual-activity-details v-if="activity" :activity="activity" />
  </div>
  <friendly-filter-text v-model="runInfo" :editable="isAuthenticatedAtLeastInternal" @modal-closed="modalClosed" />
  <individual-activity-share v-if="showDialogShareReport && activity" :show="showDialogShareReport" :activity="activity"
    :activity-pdf="pdfDataUrl" @closed="showDialogShareReport = false" />
</template>

<script lang="ts">
import { useRoute } from 'vue-router';
import searchService from '@/services/searchService';
import dayjs from 'dayjs';
import { createApp, onMounted, ref } from 'vue';
import { IndividualActivitySearchDetails } from '@/models/search';
import jsPDF from 'jspdf';
import { createJsPdf } from '@/utils/jsPdfUtils';
import IndividualActivityReportForPdf from '@/views/individual-activity/IndividualActivityReportForPdf.vue';
import IndividualActivityDetails from '@/views/individual-activity/IndividualActivityDetails.vue';
import { ACTIVITY_TYPE_LOOKUPS } from '@/models/analytics/activityTypes';
import { useStore } from 'vuex';
import DefaultColumnsService from '@/services/defaultColumnService';
import { JwtTokenUser } from '@/store/models';
import useActivityReportDetailsActions from '@/use/activityReportDetailsActions';
import FriendlyFilterText from '@/components/notifications/run-details/FriendlyFilterText.vue';
import useAuthenticatedUser from '@/use/authenticatedUser';
import IndividualActivityShare from '@/views/individual-activity/IndividualActivityShare.vue';
import { FieldCode } from '@/models/filters';

export default {
  name: 'IndividualActivityReport',
  components: {
    IndividualActivityDetails,
    IndividualActivityShare,
    FriendlyFilterText,
  },
  setup() {
    const route = useRoute();
    const activityReportConfigId = route.params['activityReportConfigId'] as string;
    const activityReportId = route.params['activityReportId'] as string;
    const activityId = route.params['activityId'] as string;
    const store = useStore();
    const user = store.state.auth.user as JwtTokenUser;
    const { isAuthenticatedAtLeastInternal } = useAuthenticatedUser();
    const activity = ref<IndividualActivitySearchDetails>();
    const activityType = 'CustomerRequest';
    const activityTypeName = ACTIVITY_TYPE_LOOKUPS.find(x => x.id === activityType)?.name;
    const exportColumns = ref<FieldCode[]>([]);
    const pdfDataUrl = ref<string>('');

    const {
      getActivityReportDetails,
      runInfo,
      data,
      showParameters,
      modalClosed,
      sasUrl
    } = useActivityReportDetailsActions();

    const exportPDF = async () => {
      // NOTE: The value for the numberOfRouteParams argument passed to createJsPdf
      // is based on the number of parameters used for the current route (contactId, runId, activityId).
      // This impacts the path to the root folder containing fonts used by the library
      const doc = createJsPdf(3);
      return htmlToPdf(doc);
    };

    const htmlToPdf = (doc: jsPDF) => new Promise<string>((resolve) => {
      const props = { activity: activity.value };
      const app = createApp(IndividualActivityReportForPdf, props);
      const el: HTMLDivElement = document.createElement('div');
      app.mount(el).$nextTick();

      setTimeout(() => {
        doc.html(el, {
          callback: function (doc) {
            const pdfDataUrl = doc.output('datauristring');
            resolve(pdfDataUrl);
          },
          y: 0,
          html2canvas: { scale: 1 },
          autoPaging: 'text',
          margin: [20, 8, 20, 20],
        });
      }, 1000);
    });


    const downloadFile = (url: string, filename: string): void => {
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      // NOTE: document.body.appendChild and the code in setTimeout are needed for the file download option to appear in Firefox
      // https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      }, 100);
    };

    const downloadPDF = () => {
      downloadFile(pdfDataUrl.value, 'Activity Report.pdf');
    };

    const downloadExcel = () => {
      downloadFile(sasUrl.value, 'Activity Report.xlsx');
    };

    const showDialogShareReport = ref<boolean>(false);

    getActivityReportDetails(activityReportConfigId, activityReportId);

    onMounted(async () => {
      activity.value = await searchService.getIndividualActivityDetails(activityType, activityId.toString());
      exportColumns.value = await DefaultColumnsService.get(user.userId);
      pdfDataUrl.value = await exportPDF();
    });

    return {
      activity,
      activityTypeName,
      downloadPDF,
      downloadExcel,
      runInfo,
      data,
      showParameters,
      modalClosed,
      isAuthenticatedAtLeastInternal,
      showDialogShareReport,
      pdfDataUrl,
    };
  },
  methods: { dayjs }
};
</script>

<style lang="scss" scoped>
.back-to-activity-report-icon-link {
  text-decoration: none;
}

.round-icon-container {
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.35);
  border-radius: 22px;
  opacity: 1;
  width: 42px;
  height: 42px;

  &:hover {
    background: #F2F2F2 0 0 no-repeat padding-box;
  }
}
</style>
