import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { UserTokenState } from '@/store/models/userTokenState';


export const USERTOKEN_MODULE = Object.freeze({
    SET_USER_TOKEN: 'setUserToken',
    GET_USER_TOKEN: 'getUserToken',
    GET_USER_REDIRECT: 'getUserRedirect',
    SET_USER_REDIRECT: 'setUserRedirect',
});

const GETTERS: GetterTree<UserTokenState, any> = {
    [USERTOKEN_MODULE.GET_USER_TOKEN](state): any {
        return state.userToken;
    },
    [USERTOKEN_MODULE.GET_USER_REDIRECT](state): any {
        return state.redirect;
    }
};

const MUTATIONS: MutationTree<UserTokenState> = {
    [USERTOKEN_MODULE.SET_USER_TOKEN](state, data: { token: string, redirect: string }) {
        state.userToken = data.token;
        state.redirect = data.redirect;
    },
    [USERTOKEN_MODULE.SET_USER_REDIRECT](state, data: { redirect: string }) {
        localStorage.setItem('USER_TOKEN_REDIRECT', data.redirect);
        state.redirect = data.redirect;
    }
};

const ACTIONS: ActionTree<UserTokenState, any> = {
    [USERTOKEN_MODULE.GET_USER_REDIRECT]({ state }) {
        const temp = localStorage.getItem('USER_TOKEN_REDIRECT');
        state.redirect = temp ?? undefined;
    }
};

export const userTokenModule: Module<UserTokenState, any> = {
    namespaced: true,
    state: {
        userToken: undefined,
        redirect: undefined
    },
    getters: GETTERS,
    mutations: MUTATIONS,
    actions: ACTIONS
};
