import { sum } from 'lodash';

export interface ScheduleDayInfo { id: number; code: string; }

export const SEND_DAYS: { [name in string]: ScheduleDayInfo } = Object.freeze({
    Monday: { id: 1, code: 'M' },
    Tuesday: { id: 2, code: 'T', },
    Wednesday: { id: 3, code: 'W', },
    Thursday: { id: 4, code: 'TH', },
    Friday: { id: 5, code: 'F' }
});

// NOTE: All days should be selected by default
export const SEND_DAYS_LIST = Object.keys(SEND_DAYS).map(day => ({ name: day, selected: true }));
export const SUM_OF_SEND_DAYS = sum(Object.keys(SEND_DAYS).map(day => SEND_DAYS[day].id));

export function sendDaysString(sendDays: string[]) {
    const sendDaysAbbr = sendDays.map(x => SEND_DAYS[x].code);
    return sendDaysAbbr.join(', ');
}
