<template>
  <form ref="addForm" :disabled="modelValue.isReadOnly">
    <cp-activate v-model="activate" text="Would you like to activate the alert now?" />
    <div class="flex col-12 pt-0 text-sm p-secondary-color">
      You are now confirmed to receive this notification. Please contact your Account
      Manager for further questions.
    </div>
    <div class="col-12 pb-0">
      <Message :closable="false">
        <span v-html-safe="safeLinkInstr" />
      </Message>
    </div>
  </form>
</template>
<script lang="ts">
import { NotificationWorkflowMixin } from '@/mixins/notificationWorkflow.mixin';
import notificationScheduleService from '@/services/notificationScheduleService';
import notificationService from '@/services/notificationService';
import { mixins } from 'vue-class-component';
import NotificationStepperTitleBar from './common/NotificationStepTitleBar.vue';
import ActivateAlertField from './fields/ActivateAlertField.vue';
import { Options } from 'vue-class-component';
import { safeLinkInstruction } from '@/models/common/alertMessage';

@Options({
  name: 'cp-notification-final-step',
  components: {
    'cp-activate': ActivateAlertField,
    'cp-stepper-title-bar': NotificationStepperTitleBar,
  },
})
export default class NotificationFinalStep extends mixins(NotificationWorkflowMixin) {
  activate: boolean = true;
  safeLinkInstr: string = safeLinkInstruction;

  mounted() {
    this.$emit('is-loaded', true);
  }

  /**
   * Calls the Liberty Bell API and removes the Notification from draft status and activates the Notification if 'activate' was selected
   *
   * @returns Notification Id
   */
  async save(): Promise<string> {
    try {
      await notificationService.markAsCompleted(this.modelValue.id);
      if (this.activate) {
        await notificationScheduleService.activate(this.modelValue.id);
      }
      this.$emit('update:modelValue', this.modelValue);
      return Promise.resolve(this.modelValue.id);
    } catch (err) {
      // Send error message back to parent
      this.showError([this.ERROR_MESSAGES.save, this.ERROR_MESSAGES.sysAdmin]);
      return Promise.reject(err);
    }
  }
}
</script>
<style lang="scss">

</style>
