<template>
  <div class="flex align-items-center justify-content-between">
    <h2 class="flex align-items-center">
      <Dropdown v-if="showOptions" v-model="category" :options="categories" option-label="name"
        :disabled="!categoryMenuEnabled" :placeholder="selectedCategory" class="p-dropdown-noborder pl-1"
        @change="changeCategory()">
        <template #value="slotProps">
          <h2>{{ slotProps.value.name }}</h2>
        </template>
      </Dropdown>
    </h2>
    <div class="pt-1">
      <cp-export-chart-options :chart="chartExport" @chart-updated="chartUpdated" />
    </div>
  </div>

  <div ref="chart" v-cp-color-picker class="flex align-items-center pt-5" style="min-height: 300px">
    <ProgressSpinner />
  </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import { Chart } from 'highcharts';
import {
  setDefaults,
  getChartColor,
  getPieChartConfig,
  PieChartOptions, getPieChartNoDataOptions,
} from '@/utils/highchartUtils';
import { isEqual } from 'lodash';
import { Lookup } from '@/models/lookup';
import { NamedSeries } from '@/models/namedSeries';
import readerDashboardService from '@/services/readerDashboardService';
import { ReaderFilterModel } from '@/models/search';
import ExportChartOptionsComponent from '../common/ExportChartOptions.vue';
import { Vue, Options } from 'vue-class-component';
import { filterValueExists } from '@/utils/filters';
import { EngagementCategory } from '@/models/analytics/engagementCategory';
import Highcharts from 'highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import setHighChartPlotSize, { renderPieChart } from '@/plugins/highchart-extend';
import { FieldCode } from '@/models/filters';
NoDataToDisplay(Highcharts);

@Options({
  name: 'cp-engagements-by-category',
  components: {
    'cp-export-chart-options': ExportChartOptionsComponent,
  },
})
export default class EngagementsByCategory extends Vue {
  @Prop({ default: 5 }) size!: string;
  @Prop({ default: () => <ReaderFilterModel>{ filters: [], indices: [] } })
  public filterModel!: ReaderFilterModel;

  data1: NamedSeries[] = [];
  // total = 0;
  declare $refs: { chart: HTMLDivElement };
  chartExport: Chart | null = null;
  selectedCategory = null;
  showTitle: string;
  showOptions: boolean = true;
  categories: Array<Lookup<EngagementCategory>> = [
    { id: 'DocumentTypeName', name: 'Engagements By Document Type' },
    { id: 'BuyersJourneyStage', name: 'Engagements By Buyer\'s Journey' },
    { id: 'Onlines', name: 'Engagements By Community' },
  ];
  category: Lookup<EngagementCategory> = this.categories[0];

  async changeCategory() {
    await this._setDataAndTotal(this.filterModel);
  }

  async mounted() {
    await this.computedValueChanged(this.filterModel, <ReaderFilterModel>{ filters: [], indices: [] });
  }

  @Watch('filterModel')
  async computedValueChanged(newVal: ReaderFilterModel, oldVal: ReaderFilterModel) {
    const noChanges = isEqual(newVal, oldVal);
    if (!newVal || noChanges) {
      return;
    }

    await this._setDataAndTotal(newVal);
  }

  get categoryMenuEnabled(): boolean {
    return filterValueExists(this.filterModel.filters, 'Clients');
  }

  get title() {
    this.showTitle = 'Engagements By ';
    return this.showTitle;
  }

  titleTrim() {
    if (this.showTitle == undefined) {
      this.showTitle = 'Engagements By';
    }
    else {
      this.showTitle = this.showTitle.length > 40
        ? this.showTitle.substring(0, 40) + '...  '
        : this.showTitle;
    }
    return this.showTitle;
  }
  
  chartUpdated(value: Chart) {
    this.chartExport = value;
    const index = this.categories.findIndex(item => item.id === this.category.id);
    let chartName = (this.chartExport as any).options.exporting.chartOptions.title.text;
    chartName = chartName.length > 40
        ? chartName.substring(0, 40) + '...  '
        : chartName;
    this.categories[index].name = chartName;
    this.$forceUpdate();
  }

  private async _setDataAndTotal(filterModel: ReaderFilterModel) {
    
    this.data1 = filterModel.noReaderFound ? [] : await readerDashboardService.getEngagementsByCategory(
      filterModel,
      this.category.id
    );
    this._renderChart();
  }

  setLegend() {
    return this.category.name == 'buyers-journey'
      ? {
        align: 'center',
        verticalAlign: 'bottom',
        floating: true,
        padding: 0,
        margin: 0,
        symbolRadius: 5,
        itemMarginBottom: 7,
        x: 0,
        y: -90,
      }
      : {
        align: 'center',
        verticalAlign: 'bottom',
        floating: true,
        padding: 0,
        itemMarginBottom: 7,
        margin: 0,
        symbolRadius: 5,
        x: 0,
        y: -50,
      };
  }

  private _renderChart() {
    setDefaults();
    setHighChartPlotSize();
    const series = this._getSeriesData();
    const options: PieChartOptions = <PieChartOptions>{
      exportTitle: this.category.name,
      field: <FieldCode>this.category.id,
      series
    };

    this.chartExport = (Highcharts as any).chart(this.$refs.chart, this.data1.length > 0 ? 
      getPieChartConfig(options) : getPieChartNoDataOptions(options),
      (chartInstance) => { // on complete
        if (this.data1.length < 1) {
          renderPieChart(chartInstance, getPieChartNoDataOptions(options).lang.noDataImage);
        }
      }
    );
  }

  private _getSeriesData() {
    return [
      {
        type: 'pie',
        name: 'Engagements',
        data: this.data1.map((x, index) => ({
          name: x.name,
          y: x.value,
          color: getChartColor(<FieldCode>this.category.id, index, x.name)
        })),
        innerSize: '50%',
      },
    ];
  }
}
</script>