import {SortOrder} from '@/models/sortOrder';
import { savedSearchRow } from '@/models/saved-search/savedSearchModel';

export interface SearchCriteria {
  companyIds?: number[];
  query?: string;
  page?: number;
  pageSize?: number;
  sortBy?: keyof savedSearchRow;
  sortDir?: SortOrder
}

export const DEFAULTS = {
  PAGE_SIZE: 10,
  PAGE_SIZE_OPTIONS: [10, 25, 50, 100]
};
