import { ActivityReportDashboardDetails } from '@/models/activity-dissemination/dashboard';
import ActivityReportDetailsService from '@/services/activityReportDetailsService';
import { computed, ref } from 'vue';
import { WorkSheet, read, utils } from 'xlsx';
import { DetailsAudit } from '@/models/notification/audit/detailsAudit';
import DataMapper from '@/models/dataMapper';
import { sanitizeUrlParam } from '@/utils/urlUtils';
import { FriendlyTextModel, toFriendlyTextModel } from '@/models/common/friendlyTextModel';
import { isArray } from 'lodash';

export default function useActivityReportDetailsActions() {
  const data = ref<ActivityReportDashboardDetails>(new ActivityReportDashboardDetails({}));
  const activities = ref<DetailsAudit[]>([]);
  const runInfo = ref<FriendlyTextModel>({ filters: [], activityTypes: [], id: '', idType: 'ActivityReport', show: false });

  const isLoading = ref(false);
  const show = ref(false);
  const sasUrl = computed(() => data.value.filePath);

  const fieldMappings = [
    { targetField: 'date', sourceField: 'Activity Date' },
    { targetField: 'orgName', sourceField: 'Reader Org' },
    { targetField: 'readerCompany', sourceField: 'Reader Company' },
    { targetField: 'firstName', sourceField: 'First Name' },
    { targetField: 'lastName', sourceField: 'Last Name' },
    { targetField: 'title', sourceField: 'Title' },
    { targetField: 'documentTitle', sourceField: 'Content Title' },
    { targetField: 'documentLink', sourceField: 'Content Title URL' },
    { targetField: 'email', sourceField: 'Email' },
    { targetField: 'referenceNumber', sourceField: 'Reference Number' },
    { targetField: 'activityType', sourceField: 'Activity Type' }
  ];

  const readFromExcel = async (filePath: string) => {
    isLoading.value = true;

    try {
      const file = await ActivityReportDetailsService.downloadFile(filePath);
      const fileArray = await file.arrayBuffer();
      const excelData = await _parseExcelData(fileArray);
      activities.value = _mapExcelData(excelData);
      activities.value.map(i => _enrichModel(i));
    } finally {
      isLoading.value = false;
    }
  };

  const _parseExcelData = async (fileArray: ArrayBuffer) => {
    const wb = read(fileArray);
    const sheet = _addHyperlinks(wb.Sheets[wb.SheetNames[0]]);
    return utils.sheet_to_json(sheet, { raw: true }) as Array<Record<string, any>>;
  };

  const _mapExcelData = (excelData: Array<Record<string, any>>) => excelData.map(x => {
    const mappedData = _mapUrlFields(x);
    const mapper = new DataMapper(mappedData, fieldMappings, false);
    return { ...<DetailsAudit>mapper.mappedData };
  });

  const _addHyperlinks = (sheet: WorkSheet): WorkSheet => Object.fromEntries(
    Object.entries(sheet).map(([cell, value]) => {
      if (value.v && value.w) {
        value.v = value.w;
      }
      if (value.l && value.l.Target) {
        return [cell, { ...value, v: [value.v, value.l.Target] }];
      } else {
        return [cell, value];
      }
    })
  );

  const _mapUrlFields = (x: Record<string, any>): Record<string, any> => {
    Object.keys(x).map(key => {
      if (isArray(x[key])) {
        x[key + ' URL'] = x[key][1];
        x[key] = x[key][0];
      }
    });
    return x;
  };

  const _enrichModel = (audit: DetailsAudit): DetailsAudit => {
    audit.fullName = audit.firstName ? `${audit.firstName} ${audit.lastName}` : audit.email;
    audit.readerLink = `/analytics/reader-dashboard/${sanitizeUrlParam(audit.email)}`;
    if (audit.orgId) {
      audit.orgLink = `/analytics/reader-co-dashboard/${sanitizeUrlParam(audit.readerCompany)}/${audit.orgId}/${sanitizeUrlParam(audit.orgName)}`;
    }
    return audit;
  };

  const getActivityReportDetails = async (id: string, runId: string) => {
    isLoading.value = true;

    try {
      data.value = await ActivityReportDetailsService.get(id, runId);
    } finally {
      isLoading.value = false;
    }
  };

  const showParameters = (value: boolean, run: ActivityReportDashboardDetails) => {
    const friendlyText = toFriendlyTextModel(run);
    showParametersFriendlyText(value, friendlyText);
  };

  const showParametersFriendlyText = (value: boolean, friendlyText: FriendlyTextModel) => {
    runInfo.value = friendlyText;
    runInfo.value.show = value;
  };

  const modalClosed = (value: boolean) => {
    showParametersFriendlyText(value, runInfo.value);
  };

  return { data, sasUrl, show, isLoading, getActivityReportDetails, readFromExcel, activities, showParameters, modalClosed, runInfo };
}
