import { FilterModel } from '@/models/search';
import axios from 'axios';
import { NamedSeries } from '@/models/namedSeries';
import { Cacheable } from '@/utils/decorators/cacheable.decorator';
import { filterModelParams, toActivityTypes } from '@/utils/filters';
import { FieldCode } from '@/models/filters';

export interface ContentDataRequest {
  companyIds: number[];
  from?: string;
  to?: string;
}

export interface DocsByBuyersJourneyAndAge {
  stage: string;
  count: number;
  docAge: string;
  color: any;
}

function cmsContentDataRequestUrl(opts: ContentDataRequest, specificRoute: string): string {
  const reqParams = opts as { [key: string]: any };
  const companyIds = (reqParams.companyIds || []).join(',');
  const optionalParamsRoute = [reqParams.stage, reqParams.from, reqParams.to]
    .filter(x => x)
    .join('/');

  let url = `/api/cms-proxy?path=templeton/document-data/${companyIds}/${specificRoute}`;

  if (optionalParamsRoute.length > 0) {
    url = `${url}/${optionalParamsRoute}`;
  }
  return url;
}

class ContentOverviewDashboardService {


  async getActivitiesWithDocsCount(filterModel: FilterModel): Promise<number> {
    const url = `/api/content-overview-dashboard/activities-with-docs-count`;
    const at = toActivityTypes(filterModel);
    return (await axios.post(url, { ...filterModelParams(filterModel), activityTypes: at, excludeInactiveDocs: true })).data;
  }

  @Cacheable({ keyProvider: (filterModel: FilterModel) => JSON.stringify(filterModel) })
  async getMostPopularDocsWithUniqueReaders(filterModel: FilterModel, size: number): Promise<NamedSeries[]> {
    const url = `/api/content-overview-dashboard/top-documents-unique-readers`;
    const metrics = (await axios.post<NamedSeries[]>(url, { filters: filterModel.filters, indices: filterModel.indices.map(x => x.id), size })).data;
    return metrics.filter(m => m.customFields).map(m => {
      m.customFields = m.customFields || {};
      m.customFields['buyersJourney'] = m.customFields['buyersJourney'] || 'Unclassified';
      m.customFields['contentType'] = m.customFields['contentType'] || 'Unclassified';
      m.customFields['documentUrl'] = m.customFields['documentUrl'];
      m.customFields['uniqueReaders'] = m.customFields['uniqueReaders'];
      return m;
    }).sort((a, b) => a.customFields['uniqueReaders'] < b.customFields['uniqueReaders'] ? 1 : -1);
  }
  async getDocCount(req: ContentDataRequest): Promise<number> {
    const url = cmsContentDataRequestUrl(req, 'doc-count');
    return (await axios.get(url)).data;
  }

  async getDocCountBySegment(req: ContentDataRequest, segment: FieldCode): Promise<Record<string, number>> {
    const url = cmsContentDataRequestUrl(req, `doc-count-by-${segment}`);
    return (await axios.get(url)).data;
  }

  async getDocCountByBuyersJourneyAndAge(opts: ContentDataRequest): Promise<DocsByBuyersJourneyAndAge[]> {
    const url = cmsContentDataRequestUrl(opts, 'doc-count-by-buyers-journey-and-age');
    return (await axios.get(url)).data;
  }
}

export default new ContentOverviewDashboardService();



