import { Operation } from '../filters';

export class FieldComparison {
  field: string;
  op: Operation;
  value?: any;

  constructor(field: string, op: Operation, value?: any) {
    this.field = field;
    this.op = op;
    this.value = value;
  }
}
