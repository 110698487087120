import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22bc543c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex p-fluid pb-2" }
const _hoisted_2 = { for: "options" }
const _hoisted_3 = { class: "w-full" }
const _hoisted_4 = { class: "p-float-label w-full" }
const _hoisted_5 = {
  key: 3,
  for: "options"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_AutoComplete = _resolveComponent("AutoComplete")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showOps)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "p-float-label",
          style: _normalizeStyle(_ctx.defaultLabel.length < 22 ? 'min-width: 150px' : 'min-width: 230px')
        }, [
          _createVNode(_component_Dropdown, {
            id: "options",
            "model-value": _ctx.local.op,
            options: _ctx.operationItems,
            "option-label": "name",
            "option-value": "id",
            class: "p-border-round-left",
            onChange: _cache[0] || (_cache[0] = (v) => _ctx.updateOp(v.value))
          }, null, 8, ["model-value", "options"]),
          _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.defaultLabel), 1)
        ], 4))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", _hoisted_4, [
        (_ctx.controlType === _ctx.TermsControlType.Chips)
          ? (_openBlock(), _createBlock(_component_AutoComplete, {
              key: 0,
              id: _ctx.modelValue.field,
              ref: "autocomplete",
              multiple: true,
              "model-value": _ctx.local.value,
              suggestions: _ctx.filteredOptions,
              field: "name",
              dropdown: _ctx.loadOptionsOnce,
              class: _normalizeClass({
            'p-border-noround': _ctx.showOps && _ctx.loadOptionsOnce,
            'p-border-round-right': _ctx.showOps && !_ctx.loadOptionsOnce,
          }),
              "complete-on-focus": true,
              onComplete: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSearch($event.query))),
              onItemSelect: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateVal($event.value, _ctx.modelValue.field))),
              onItemUnselect: _cache[3] || (_cache[3] = ($event: any) => (_ctx.deleteVal($event.value)))
            }, null, 8, ["id", "model-value", "suggestions", "dropdown", "class"]))
          : _createCommentVNode("", true),
        (_ctx.controlType === _ctx.TermsControlType.Select)
          ? (_openBlock(), _createBlock(_component_Dropdown, {
              key: 1,
              "model-value": _ctx.local.value[0],
              suggestions: _ctx.options,
              field: "name",
              dropdown: true,
              class: "p-border-round-left",
              onComplete: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onSearch($event.query)))
            }, null, 8, ["model-value", "suggestions"]))
          : _createCommentVNode("", true),
        (_ctx.controlType === _ctx.TermsControlType.Autocomplete)
          ? (_openBlock(), _createBlock(_component_AutoComplete, {
              key: 2,
              multiple: true,
              "model-value": _ctx.local.value,
              suggestions: _ctx.filteredOptions,
              field: "name",
              dropdown: _ctx.loadOptionsOnce,
              class: _normalizeClass({
            'p-border-noround': _ctx.showOps && _ctx.loadOptionsOnce,
            'p-border-round-right': _ctx.showOps && !_ctx.loadOptionsOnce,
          }),
              "complete-on-focus": true,
              onComplete: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onSearch($event.query))),
              onItemSelect: _cache[6] || (_cache[6] = ($event: any) => (_ctx.updateVal($event.value, _ctx.modelValue.field))),
              onItemUnselect: _cache[7] || (_cache[7] = ($event: any) => (_ctx.deleteVal($event.value)))
            }, null, 8, ["model-value", "suggestions", "dropdown", "class"]))
          : _createCommentVNode("", true),
        (!_ctx.showOps)
          ? (_openBlock(), _createElementBlock("label", _hoisted_5, _toDisplayString(_ctx.defaultLabel), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}