<template>
  <form @submit.prevent="save">
    <div class="col-12 pb-0">
      <div class="flex">
        <h1 class="line-height-1">
          {{ pageTitle }}
        </h1>
      </div>
    </div>
    <div class="col-12 flex">
      <div class="col-6 md:col-8 p-primary-color user_info">
        <h2>{{ usersName }}</h2>
        {{ email }}
      </div>
      <div class="col-6 md:col-4 flex flex-row-reverse">
        <Button type="submit" class="p-button-success p-button-raised p-button-rounded ml-3" label="Save" />
        <Button type="button" class="p-button-outlined p-button-rounded" label="Return to Saved Search"
          @click="navigateToProfile()" />
      </div>
    </div>
    <div class="grid mb-3">
      <Message :closable="false">
        <b>Saved Searches</b> allow you to setup a combination of filters for various fields and save it, so that they
        can be used for all dashboards. Your current filter selections are displayed below. Edit your selections and
        click
        save to see them persist throughout Templeton<sup>&reg;</sup>.
      </Message>
    </div>
    <div class="grid pb-4">
      <div class="col-12">
        <Card class="p-card-default-filters">
          <template #title>
            <div class="px-3 pt-1">
              Search details
            </div>
          </template>
          <template #content>
            <div class="grid px-3 pb-0">
              <div class="col-12 p-fluid pb-3">
                <span class="p-float-label">
                  <InputText id="name" v-model="savedSearchName" class="p-inputtext w-full"
                    :class="{ 'p-invalid': v$.name.$invalid && submitted }" maxlength="60" />
                  <label for="name">Search Name</label>
                </span>
                <small v-if="(v$.name.required.$invalid && submitted) || v$.name.$pending.$response" class="p-error">
                  {{ v$.name.required.$message.replace('The value', 'Search Name') }}
                </small>
              </div>
              <div class="col-12 p-fluid pb-3">
                <span class="p-float-label">
                  <InputText id="desc" v-model="savedSearchDescription" class="p-inputtext w-full" />
                  <label for="desc">Search Description</label>
                </span>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
    <div ref="general" class="grid pb-4">
      <div class="col-12">
        <Card class="p-card-default-filters">
          <template #title>
            <div class="px-3 pt-1">
              General
            </div>
          </template>
          <template #content>
            <div class="grid px-3 pb-0">
              <div class="col-12 p-fluid pb-3">
                <span class="p-float-label">
                  <AutoComplete id="activityType" v-model="selectedIndices" :multiple="true"
                    :suggestions="filteredIndices" field="name" :dropdown="true" :complete-on-focus="true"
                    @complete="searchActivityTypes($event)" @focus="searchActivityTypes($event)" />
                  <label for="activityType">Activity Type</label>
                </span>
              </div>
              <div v-for="row in generalFields" :key="row.items[0].props?.label" class="col-12 p-fluid pb-3">
                <div v-for="f in displayableItems(row.items)" :key="f.props?.label">
                  <cp-compound-search-field-elements v-if="f.props?.isCompound" :search-field="f" :submitted="submitted"
                    :is-user-profile="!isMyProfile" />
                  <component :is="f.type" v-if="!f.props?.isCompound" v-bind="f.props" v-model="f.model"
                    :submitted="submitted" :is-user-profile="!isMyProfile" />
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>

    <div v-if="demographicFields != null" ref="demographic" class="grid pb-4">
      <div class="col-12">
        <Card class="p-card-default-filters">
          <template #title>
            <div class="px-3 pt-1">
              Demographic
            </div>
          </template>
          <template #content>
            <div class="grid px-3 pb-0">
              <div v-for="(row, i) in demographicFields" :key="row.items[0].props?.label"
                :class="getClass('main', row.items.length, i, row.items.some(x => x.props?.isCompound))">
                <div v-for="(f, j) in displayableItems(row.items)" :key="f.props?.label"
                  :class="getClass('child', row.items.length, j)">
                  <cp-compound-search-field-elements v-if="f.props?.isCompound" :search-field="f"
                    :submitted="submitted" />
                  <component :is="f.type" v-if="!f.props?.isCompound" v-bind="f.props" v-model="f.model"
                    :submitted="submitted" />
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
    <div v-if="allFirmographicFields != null" ref="firmographic" class="grid pb-4">
      <div class="col-12">
        <Card class="p-card-default-filters">
          <template #title>
            <div class="px-3 pt-1">
              Firmographic
            </div>
          </template>
          <template #content>
            <cp-reader-org-search-field v-if="isReaderLookUpExists" ref="childRef" @filter-changed="orgFilterChanged" />
            <div class="grid px-3 pb-0">
              <div v-for="(row, i) in allFirmographicFields" :key="row.items[0].props?.label"
                :class="getClass('main', row.items.length, i, row.items.some(x => x.props?.isCompound))">
                <div v-for="(f, j) in displayableItems(row.items)" :key="f.props?.label"
                  :class="getClass('child', row.items.length, j)">
                  <cp-compound-search-field-elements :is="f.type" v-if="f.props?.isCompound" v-bind="f.props"
                    :search-field="f" :submitted="submitted" />
                  <component :is="f.type" v-if="!f.props?.isCompound" v-bind="f.props" v-model="f.model"
                    :submitted="submitted" />
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>

    <div v-if="contextualFields != null" ref="contextual" class="grid pb-4">
      <div class="col-12">
        <Card class="p-card-default-filters">
          <template #title>
            <div class="px-3 pt-1">
              Contextual
            </div>
          </template>
          <template #content>
            <div class="grid px-3 pb-0">
              <div v-for="(row, i) in contextualFields" :key="row.items[0].props?.label" class=""
                :class="getClass('main', row.items.length, i, row.items.some(x => x.props?.isCompound))">
                <div v-for="(f, j) in displayableItems(row.items)" :key="f.props?.label"
                  :class="getClass('child', row.items.length, j)">
                  <cp-compound-search-field-elements v-if="f.props?.isCompound" :search-field="f"
                    :submitted="submitted" />
                  <component :is="f.type" v-if="!f.props?.isCompound" v-bind="f.props" v-model="f.model"
                    :submitted="submitted" />
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </form>
</template>

<script lang="ts">
import { computed, PropType, ref, watch } from 'vue';
import useVuelidate from '@vuelidate/core';
import { FILTER_COMPONENTS } from '@/components/searchFields';
import { AppUser } from '@/models/appUser';
import { Lookup } from '@/models/lookup';
import useAuthenticatedUser from '@/use/authenticatedUser';
import useDefaultFilterSecurity from '@/use/defaultFilterSecurity';
import useSearchFilterActions from '@/use/searchFilterActions';
import useSearchFilterSecurity from '@/use/searchFilterSecurity';
import { FilterProp, findFilter } from '@/utils/filters';
import useDefaultFilterActions from '@/use/defaultFilterActions';
import useSearchLookups from '@/use/searchLookups';
import { useRoute } from 'vue-router';
import { TermsFilter } from '@/models/filters';
import { FilterModel } from '@/models/search';
import useActivityFields from '@/use/activityFields';
import router from '@/router';
import { ProfileMenu } from '@/models/ProfileMenu';
import useCurrentUser from '@/use/currentUser';

export default {
  name: 'CpDefaultFilters',
  components: {
    ...FILTER_COMPONENTS
  },
  props: {
    modelValue: { type: Object as PropType<AppUser>, required: true },
    isMyProfile: { type: Boolean }
  },
  setup: function (props) {
    const selectedIndices = ref<Array<Lookup<string>>>([]);
    const filteredIndices = ref<Array<Lookup<string>>>([]);
    const submitted = ref(false);
    const filterProps = ref<FilterProp[]>([]);
    const childRef = ref();
    const route = useRoute();
    const { authenticatedUser } = useAuthenticatedUser();

    const routeUserId = computed(() => route.params['userId']?.toString());

    const { currentUser } = useCurrentUser();
    const { basicSearchableActivityFields } = useActivityFields();
    const fields = computed(() => [
      ...currentUser.value?.searchFields || [],
      ...basicSearchableActivityFields.value.map(x => x.field)
    ]);
    const isReaderLookUpExists = computed(() => fields.value.includes('ReaderOrgsLookup'));

    const isNew = computed(() => !route.params['id']);
    const pageTitle = computed(() => isNew.value
      ? 'New Search'
      : 'Edit Search');

    const usersName = computed(() => `${currentUser.value.firstName ?? ''} ${currentUser.value.lastName ?? ''}`);
    const email = computed(() => `${currentUser.value.email ?? ''}`);

    const searchId = computed(() => route.params['id']?.toString());

    const {
      data: defaultFilterModel,
      getData: getDefaultFilters,
      name,
      description
    } = useDefaultFilterSecurity(routeUserId, searchId);

    const { onlineBgMap, orgQuestionsMap } = useSearchLookups();
    const {
      generalFields,
      demographicFields,
      contextualFields,
      allFirmographicFields,
      validations,
      filterModel,
      getSearchFieldRowClass,
      getSearchFieldFilters,
      updateData,
      clearData,
      displayableItems
    } = useSearchFilterSecurity(selectedIndices, filterProps, fields, orgQuestionsMap, onlineBgMap, searchId);
    const v$ = useVuelidate(validations, { filterModel, name });

    const { searchActivityTypes } = useSearchFilterActions(filterModel, v$, selectedIndices, filteredIndices, authenticatedUser, updateData, submitted);
    const { save } = useDefaultFilterActions(routeUserId, name, description, v$, selectedIndices, submitted, getSearchFieldFilters);

    const clearFilterValues = () => {
      clearData();
    };

    clearFilterValues();

    const onUserChanged = () => {
      getDefaultFilters().then(() =>
        updateData(defaultFilterModel.value)
      );
    };
    watch(props, () => {
      onUserChanged();
    }, { deep: true, immediate: true });

    const orgFilterChanged = (model: Array<Lookup<any>>) => {
      let readerOrgFilter: TermsFilter = findFilter<TermsFilter>(filterModel.value.filters, 'ReaderOrgs');
      if (!readerOrgFilter) {
        readerOrgFilter = new TermsFilter({ field: 'ReaderOrgs', value: model });
        filterModel.value.filters.push(readerOrgFilter);
      }
      readerOrgFilter.value = model;
      onFilterModelChanged(filterModel.value);
    };

    const onFilterModelChanged = (filters: FilterModel) => {
      updateData(filters);
    };

    const navigateToProfile = () => {
      router.push(`/admin/user/${route.params['userId']}?active=${ProfileMenu.Filters}`);
    };

    return {
      generalFields,
      demographicFields,
      contextualFields,
      allFirmographicFields,
      filterModel,
      submitted,
      getClass: getSearchFieldRowClass,
      searchActivityTypes,
      selectedIndices,
      filteredIndices,
      clearFilterValues,
      save,
      displayableItems,
      childRef,
      isReaderLookUpExists,
      orgFilterChanged,
      pageTitle,
      navigateToProfile,
      getSearchFieldFilters,
      savedSearchName: name,
      savedSearchDescription: description,
      v$,
      usersName,
      email
    };
  }
};
</script>

<style scoped lang="scss">
.sticky-header {
  top: 60px;
}

</style>
