export class MostActiveCosEngagements {

    activitiesCount: string;
    usersCount: string;
    orgId: string
    uniqueReaders: string;
    orgName: string;
    companyType: string[];

    constructor(data: Partial<MostActiveCosEngagements> | null = null) {
        if (data) {
            Object.assign(this, data);
        }
    }

    get getOrgProfileUrl() {
        return '/analytics/reader-co-dashboard/' + this.orgName + '/' + this.orgId + '/' + this.orgName;
    }

    get formatCompanyTypes() {
        return this.companyType?.join(', ');
    }
}
