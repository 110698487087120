import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f90df8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layout-main" }
const _hoisted_2 = { class: "flex flex-row align-items-center" }
const _hoisted_3 = { class: "flex py-3 align-items-center justify-content-center" }
const _hoisted_4 = {
  key: 0,
  class: "material-icons pr-3"
}
const _hoisted_5 = {
  key: 1,
  class: "material-icons pr-3"
}
const _hoisted_6 = {
  key: 2,
  class: "material-icons pr-3"
}
const _hoisted_7 = { class: "flex py-4 flex-grow-1 align-items-center justify-content-center h-full" }
const _hoisted_8 = { class: "p-confirm-popup-content" }
const _hoisted_9 = { class: "ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_cp_nav = _resolveComponent("cp-nav")!
  const _component_cp_top = _resolveComponent("cp-top")!
  const _component_Toast = _resolveComponent("Toast")!
  const _component_ConfirmPopup = _resolveComponent("ConfirmPopup")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.navVisible && _ctx.inProgressCount !== 0)
      ? (_openBlock(), _createBlock(_component_ProgressBar, {
          key: 0,
          mode: "indeterminate",
          style: {"height":"0.3em"}
        }))
      : _createCommentVNode("", true),
    (_ctx.navVisible)
      ? (_openBlock(), _createBlock(_component_cp_nav, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.navVisible)
      ? (_openBlock(), _createBlock(_component_cp_top, { key: 2 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Toast, { position: "top-center" }, {
        message: _withCtx((slotProps) => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (slotProps.message.severity === 'error')
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, " error_outline "))
                : (slotProps.message.severity === 'warn')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, " report "))
                  : (_openBlock(), _createElementBlock("span", _hoisted_6, " done_all "))
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("h4", null, _toDisplayString(slotProps.message.summary), 1),
              _createElementVNode("p", null, _toDisplayString(slotProps.message.detail), 1)
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_ConfirmPopup, null, {
        message: _withCtx((slotProps) => [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("span", {
              class: _normalizeClass(["mt-1 p-confirm-icon", slotProps.message.icon])
            }, null, 2),
            _createElementVNode("p", _hoisted_9, _toDisplayString(slotProps.message.message), 1)
          ])
        ]),
        _: 1
      }),
      (_openBlock(), _createBlock(_component_router_view, {
        key: _ctx.$route.path,
        class: "side-space"
      }))
    ])
  ], 64))
}