<template>
  <div class="flex flex-column align-items-center justify-content-center mb-4">
    <hr class="align-self-center mt-4 div-hr">
    <div class="flex flex-row align-items-center justify-content-center mt-2">
      <div class="cursor-pointer" @click="isOpenHelp = !isOpenHelp">
        <u>
          {{ title }}
        </u>
        &nbsp;
        <i class="pi " :class="{ 'pi-chevron-up': isOpenHelp, 'pi-chevron-down': !isOpenHelp }"
          style="font-size: .8em;" />
      </div>
    </div>
    <div class="flex flex-row align-items-center justify-content-center mx-7 mt-4 mb-1">
      <div v-show="isOpenHelp">
        {{ details }}
        <br><br>
        For questions, please contact a Life Science Connect representative by calling (814) 897-7700,
        or you can email us at <a href="mailto:support@templetoninsights.com">support@templetoninsights.com</a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HelpToggle',
  props: {
    title : { type: String, required : true },
    details : { type: String, required: false},
  },
  data() {
    return {
      isOpenHelp: false
    };
  },
};
</script>

<style scoped>
.div-hr {
  border: 1px solid #DBDFE4;
  width: 6rem;
}
</style>