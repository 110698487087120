import axios from 'axios';
import { PagedList } from '@/models/pagedList';
import { EngagementSearchResultItem } from '@/models/search/engagementSearchResultItem';
import { EngagementSearchDetails } from '@/models/search/engagementSearchDetails';
import { Cacheable } from '@/utils/decorators/cacheable.decorator';
import { PageableFilterCriteria } from '@/models/search/pageableFilterCriteria';
import { EngagementSearchRequest } from '@/models/search/engagementSearchRequest';
import { ActivityType } from '@/models/activity-review/activityType';
import { IndividualActivitySearchDetails } from '@/models/search';

const URL_BASE = '/api/analytics/search';

class SearchService {

  @Cacheable({
    keyProvider: (type: ActivityType, id: string) => id
  })
  async getDetails(type: ActivityType, id: string): Promise<EngagementSearchDetails> {
    const resp = await axios.get(`/api/analytics/search-details/${type}/${id}`);
    return resp.data && new EngagementSearchDetails(resp.data);
  }

  async getIndividualActivityDetails(type: ActivityType, id: string): Promise<IndividualActivitySearchDetails> {
    const resp = await axios.get(`/api/analytics/search-details/individual-activity/${type}/${id}`);
    return resp.data && new IndividualActivitySearchDetails(resp.data);
  }

  /*
  * A qualified id is in the format of EN123 for Engagement, CU123 for Customer Request, WE345abc for Webinar and EV345abc for Event
  * */
  async getDetailsByQualifiedIds(ids: string[]): Promise<EngagementSearchDetails[]> {
    const opts = {
      params: { ids: ids.join(',') }
    };
    const results = await axios.get(`/api/analytics/search-details`, opts);
    return results.data.map((d: any) => new EngagementSearchDetails(d));
  }

  async search(req: PageableFilterCriteria): Promise<PagedList<EngagementSearchResultItem>> {
    const searchRequest: EngagementSearchRequest = {
      filters: req.filterModel.filters,
      page: { page: req.page, size: req.pageSize },
      sort: req.sortInfo,
      indices: req.filterModel.indices.map(x => x.id)
    };

    const response = await axios.post(URL_BASE, searchRequest);
    const pl: PagedList<EngagementSearchResultItem> = response.data;
    pl.items = pl.items.map(l => new EngagementSearchResultItem(l));
    return pl;
  }
}

export default new SearchService();
