<template>
  <div class="flex p-fluid w-full">
    <div class="col-12 p-0">
      <span class="p-float-label">
        <AutoComplete id="online" :model-value="local.value" :suggestions="filteredCommunities" field="name"
          :dropdown="true" :multiple="true" :complete-on-focus="true" @complete="searchCommunities($event)"
          @item-select="updateField($event.value, 'online')" @item-unselect="deleteField($event.value)" />
        <label for="online">Communities</label>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { Prop } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import { Lookup } from '@/models/lookup';
import { TermsFilter } from '@/models/filters';
import { Claim } from '@/models/security';
import commonLookupService from '@/services/commonLookupService';
import { Vue, Options } from 'vue-class-component';
import { Debounce } from '@/utils/decorators/debounce.decorator';

const DEFAULT: TermsFilter = new TermsFilter({ field: 'Onlines', op: 'Contains' });

@Options({
  computed: {
    ...mapGetters('auth', ['satisfiesClaim', 'userOnlines']),
  },
  name: 'cp-onlines-search-field',
})
export default class OnlinesSearchField extends Vue {
  @Prop() modelValue: TermsFilter;
  @Prop({ default: false }) submitted: boolean;
  @Prop({ default: () => [] }) rules: Array<(val: string) => any>;
  options: Array<Lookup<string>> = [];
  userOnlines!: Array<Lookup<string>>; // mapped from vuex getter
  satisfiesClaim: (claim: Claim) => boolean;
  q: string = '';
  onlinesQuery: string | null = null;
  query: string;

  filteredCommunities: Array<Lookup<string>> = [];

  async mounted() {
    this.$emit('update:modelValue', cloneDeep(this.local));
    const atLeastInternal: Claim = { name: 'role', value: 'Internal' };

    this.options = this.satisfiesClaim(atLeastInternal)
      ? await commonLookupService.getOnlines()
      : this.userOnlines;

    // console.log(this.local);
  }

  get local(): TermsFilter {
    return new TermsFilter({ ...DEFAULT, ...this.modelValue });
  }

  public updateField(value: any, id: string) {
    const termValue: Array<Lookup<string>> = [
      ...this.local.value,
      { id: value.id, name: value.name },
    ];
    const newVal = new TermsFilter({ ...this.local, value: termValue });
    this.$emit('update:modelValue', newVal);

    document.getElementById(id)?.getElementsByTagName('input')[0].focus();
  }

  public deleteField(value: any) {
    const findIndex = this.local.value.findIndex((a) => a.id === value.id);
    this.local.value.splice(findIndex, 1);

    const newVal = new TermsFilter({ ...this.local });
    this.$emit('update:modelValue', newVal);
  }

  @Debounce(250)
  searchCommunities(event) {
    if (event.query.length > 0) {
      this.query = event.query;
    }

    const notSelected = this.options.filter((e1) => this.local.value.findIndex((e2) => e2.name == e1.name) === -1);

    if (!this.query?.trim().length) {
      this.filteredCommunities = notSelected;
    } else {
      this.filteredCommunities = notSelected.filter((type) => type.name.toLowerCase().includes(this.query.trim().toLowerCase()));
    }

    if (this.filteredCommunities.length == 0) {
      this.query = '';
    }
  }

}
</script>
