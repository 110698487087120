export * from './companiesSearchField.vue';
export * from './termsSearchField.vue';
export * from './textSearchField.vue';
export * from './companiesSearchField.vue';
export * from './boolSearchField.vue';
export * from './onlinesSearchField.vue';
export * from './dateRangeSearchField.vue';
export * from  './readerOrgSearchField.vue';
export * from './compoundSearchFieldElements.vue';

import TermsSearchField from './termsSearchField.vue';
import TextSearchField from './textSearchField.vue';
import CompaniesSearchField from './companiesSearchField.vue';
import BoolSearchField from './boolSearchField.vue';
import OnlinesSearchField from './onlinesSearchField.vue';
import DateRangeSearchField from './dateRangeSearchField.vue';
import AnalystTagsSearchField from './analystTagsSearchField.vue';
import ReaderOrgSearchField from './readerOrgSearchField.vue';
import CompoundSearchFieldElements from './compoundSearchFieldElements.vue';

import { SearchFieldComponentName } from './metadata';
import  Vue from 'vue';

export const FILTER_COMPONENTS: Record<SearchFieldComponentName, Vue.Component> = {
  'cp-terms-search-field': TermsSearchField,
  'cp-text-search-field': TextSearchField,
  'cp-companies-search-field': CompaniesSearchField,
  'cp-onlines-search-field': OnlinesSearchField,
  'cp-bool-search-field': BoolSearchField,
  'cp-date-range-search-field': DateRangeSearchField,
  'cp-analyst-tags-search-field': AnalystTagsSearchField,
  'cp-reader-org-search-field' : ReaderOrgSearchField, 
  'cp-compound-search-field-elements': CompoundSearchFieldElements
};
