import Exporting from 'highcharts/modules/exporting';
import * as Highcharts from 'highcharts';
import { Chart } from 'highcharts';
import { range, sortBy } from 'lodash';
import dayjs from 'dayjs';
import { FieldCode } from '@/models/filters/fieldCode';
import { COLORS, COLORS_BY_NAME, GRADIENT_COLORS_BY_NAME } from './colorUtils';

export { COLORS };

export type ExportFormat = 'SVG' | 'PNG' | 'PDF' | 'JPG';

export const DEFAULT_EXPORT_OPTIONS = {
  exporting: {
    enabled: false,
  },
};

export function setDefaults() {
  return Highcharts.setOptions({
    chart: {
      style: {
        fontFamily: 'Overpass, sans-serif',
      },
    },
    xAxis: {
      labels: {
        style: {
          fontSize: '12px',
          color: '#1B3557',
          fontWeight: '500',
        },
      },
    },
    yAxis: {
      labels: {
        style: {
          fontSize: '12px',
          color: '#1B3557',
          fontWeight: '500',
        },
      },
    },
    legend: {
      itemStyle: {
        fontSize: '12px',
        color: '#1B3557',
        fontWeight: '500',
      },
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true,
          color: '#1B3659',
          style: {
            textShadow: false,
            fontSize: '12px',
            fontWeight: '500',
          },
        },
      },
    },
  });
}

export function getGradientUnknown() {
  return {
    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
    stops: GRADIENT_COLORS_BY_NAME['Gray'],
  };
}

export function getGradientOther() {
  return {
    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
    stops: GRADIENT_COLORS_BY_NAME['Brown'],
  };
}

export function getBarGradientPerColumn() {
  return [{
    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
    stops: GRADIENT_COLORS_BY_NAME['LightBlue'],
  }, {
    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
    stops: GRADIENT_COLORS_BY_NAME['LSCBlue'],
  },
  {
    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
    stops: GRADIENT_COLORS_BY_NAME['DarkBlue'],
  },
  getGradientUnknown()
  ];
}

export function getSolidColorPerSliceByBuyerJourneyStage(stage: string) {
  switch (stage.toLowerCase()) {
    case 'early': {
      return COLORS_BY_NAME['LightBlue'];
    }
    case 'middle': {
      return COLORS_BY_NAME['LSCBlue'];
    }
    case 'late': {
      return COLORS_BY_NAME['DarkBlue'];
    }
    case 'unclassified': {
      return COLORS_BY_NAME['Gray'];
    }
  }
}

export function getPieGradientPerSliceBuyerJourneyStage(stage: string) {
  switch (stage.toLowerCase()) {
    case 'early': {
      return {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: GRADIENT_COLORS_BY_NAME['LightBlue'],
      };
    }
    case 'middle': {
      return {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: GRADIENT_COLORS_BY_NAME['LSCBlue'],
      };
    }
    case 'late': {
      return {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: GRADIENT_COLORS_BY_NAME['DarkBlue'],
      };
    }
    case 'unclassified': {
      return getGradientUnknown();
    }
  }
}

export function getBarChartGradientPerNetNewActivitySource(source: string) {
  switch (source.toLowerCase()) {
    case 'non-newsletter': {
      return {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: GRADIENT_COLORS_BY_NAME['TSL_Teal'],
      };
    }
    case 'webinar': {
      return {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: GRADIENT_COLORS_BY_NAME['DarkBlue'],
      };
    }
    case 'event': {
      return {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: GRADIENT_COLORS_BY_NAME['LSCBlue'],
      };
    }
    case 'regular newsletter': {
      return {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: GRADIENT_COLORS_BY_NAME['LightGreen'],
      };
    }
    case 'custom newsletter': {
      return {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: GRADIENT_COLORS_BY_NAME['LightBlue'],
      };
    }
    case 'lead nurture campaign': {
      return {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: GRADIENT_COLORS_BY_NAME['LightPurple']
      };
    }
    case 'unclassified': {
      return getGradientUnknown();
    }
  }
}

export function getPieGradientPerSlicePercentTotal() {
  return [
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['TSL_Teal'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['LightGreen']
    }
  ];
}

export function getPieGradientPerSlice() {
  return [
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['LSCBlue'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['DarkBlue'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['LightBlue'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['TSL_Teal'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Orange'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Pink'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Yellow'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Red'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['LightPurple']
    }
  ];
}

export function getPieGradientForContentThemes() {
  return [
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Orange'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['TSL_Teal'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['LightBlue'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['LSCBlue'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['DarkBlue'],
    },
    {
      linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Gray']
    }
  ];
}

export function getPieGradientForContentSource() {
  return [
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['LSCBlue'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['LightBlue'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['DarkBlue'],
    },
    {
      linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Gray']
    }
  ];
}

export function getColumnGradientBySource() {
  return [
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['LSCBlue'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['LightGreen'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['LightPurple'],
    },
    {
      linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['DarkBlue'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Orange'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Pink'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Yellow'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Red']
    }
  ];
}

export function getPieGradientPerSliceCommunity() {
  return [
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['TSL_Teal'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['LightBlue'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Orange'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Red'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['LSCBlue'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Yellow'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Pink'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['LightPurple'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['DarkBlue']
    }
  ];
}

export function getColumnGradientPerBlock() {
  return [
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['TSL_Teal4'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['LightPurple4'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['DarkBlue4'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Orange4'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Blue4'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Pink4'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Yellow4'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Brown4'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Gray4']
    }
  ];
}

export function getColumnGradientPerBlockSubsequent() {
  return [
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['LSCBlue2'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['LightBlue2'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['DarkBlue2'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['TSL_Teal2'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['LightPurple2'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Orange2'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Red2'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Brown2'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Yellow2'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Pink2'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Gray2']
    }
  ];
}

export function getColumnGradientPerBlockNewsletter() {
  return [
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['TSL_Teal'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['LightPurple'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['DarkBlue'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Orange'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Blue'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Pink'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Yellow'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Brown'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Gray']
    }
  ];
}
export function getColumnGradientPerBlockCustomNewsletter() {
  return [
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['TSL_Teal2'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['LightPurple2'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['DarkBlue2'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Orange2'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Blue2'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Pink2'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Yellow2'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Brown2'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Gray2']
    }
  ];
}
export function getColumnGradientPerBlockNonNewsletter() {
  return [
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['TSL_Teal3'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['LightPurple3'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['DarkBlue3'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Orange3'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Blue3'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Pink3'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Yellow3'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Brown3'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Gray3']
    }
  ];
}

export function getColumnGradientPerBlockWebinar() {
  return [
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['TSL_Teal5'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['LightPurple5'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['DarkBlue5'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Orange5'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Blue5'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Pink5'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Yellow5'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Brown5'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Gray5']
    }
  ];
}

export function getColumnGradientPerBlockEvent() {
  return [
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['TSL_Teal6'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['LightPurple6'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['DarkBlue6'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Orange6'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Blue6'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Pink6'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Yellow6'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Brown6'],
    },
    {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: GRADIENT_COLORS_BY_NAME['Gray6']
    }
  ];
}

export function getColumnGradientMostPopularByJourney() {
  return [
    {
      linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
      stops: [
        [0, COLORS_BY_NAME['TSL_Teal_Gradient']],
        [1, COLORS_BY_NAME['TSL_Teal2']],
      ],
    },
    {
      linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
      stops: [
        [0, COLORS_BY_NAME['LSCBlue_Gradient']],
        [1, COLORS_BY_NAME['LSCBlue']],
      ],
    },
    {
      linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
      stops: [
        [0, '#285693'],
        [1, COLORS_BY_NAME['DarkBlue']],
      ],
    },
  ];
}

export function getColumnGradientMostPopularByOrigin(contentType: string) {
  switch (contentType.toLowerCase()) {
    case 'unclassified': {
      return {
        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
        stops: GRADIENT_COLORS_BY_NAME['Gray'],
      };
    }
    case 'vendor-supplied':
    case 'your content': {
      return {
        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
        stops: GRADIENT_COLORS_BY_NAME['TSL_Teal'],
      };
    }
    case 'custom content': {
      return {
        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
        stops: GRADIENT_COLORS_BY_NAME['Orange'],
      };
    }
    case 'editorial': {
      return {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: GRADIENT_COLORS_BY_NAME['LightBlue'],
      };
    }
  }
}

export function getChartColor(
  field: FieldCode,
  index: number,
  stage: string
): string | undefined {
  switch (stage.toLowerCase()) {
    case 'unclassified':
      return getGradientUnknown() as any;
    case 'other':
      return getGradientOther() as any;
  }
  switch (field) {
    case 'ContentType':
      return getColumnGradientMostPopularByOrigin(stage) as any;
    case 'Region':
      return getPieGradientPerSlice()[index] as any;
    case 'BuyersJourneyStage':
      return getPieGradientPerSliceBuyerJourneyStage(stage) as any;
    case 'DocumentTypes':
      return getPieGradientPerSlice()[index] as any;
    case 'Onlines':
      return getPieGradientPerSliceCommunity()[index] as any;
  }
  if (index > COLORS.length - 1) {
    return undefined;
  }
  return COLORS[index];
}

export function getStackedLabelsConfig(
  enabled: boolean = false,
  fontSize: string = '12px'
): any {
  return {
    enabled: enabled || false,
    allowOverlap: true,
    crop: false,
    overflow: 'allow',
    style: <any>{
      color: '#333333',
      fill: '#333333',
      fontSize,
      fontWeight: 'normal',
      top: 0,
    },
    formatter() {
      const val = this.total || this.y;
      if (val > 0) {
        return val;
      }
    },
  };
}

export function enableHighchartsExporting() {
  Exporting(Highcharts);
}

let printScalingEnabled = false;
// https://github.com/wkhtmltopdf/wkhtmltopdf/issues/2016#issuecomment-346858945
// this is a utility function that helps highcharts when in print mode. It will
// prevent the horizontal scrollbar from appearing.
export function setupPrintScaling() {
  if (printScalingEnabled) {
    return;
  }
  printScalingEnabled = true;
  window.matchMedia('print').addListener(() => {
    try {
      reflowCharts();
    } catch (e) {
      console.warn(e);
    }
  });
}

export function exportChart(chart: Chart, format: ExportFormat) {
  const mimeType = getMimeType(format);
  // console.log(`HighChartUtils.exportChart(). MimeType:${mimeType}`, chart);
  chart.exportChart({ type: mimeType }, {});
}

function reflowCharts() {
  Highcharts.charts.filter((c) => c).forEach((c) => c?.reflow(undefined));
}

function getMimeType(format: ExportFormat): Highcharts.ExportingMimeTypeValue {
  switch (format) {
    case 'PNG':
      return 'image/png';
    case 'JPG':
      return 'image/png';
    case 'SVG':
      return 'image/svg+xml';
    default:
      return 'application/pdf';
  }
}

export function getExportOptions(title: string): Highcharts.ExportingOptions {
  return <Highcharts.ExportingOptions>{
    enabled: false,
    chartOptions: {
      title: {
        text: title,
        margin: 50,
        style: {
          fontFamily: 'Overpass, sans-serif',
          color: '#1b3557',
          fontWeight: 'bold',
          fontSize: '20px',
        }
      },
    },
    filename: title.replace(' ', '-').toLocaleLowerCase(),
  };
}

export function getStackedBarChartXAxis(startDate: Date | undefined) {
  return range(0, 12).map((i) =>
    dayjs(startDate).add(i, 'month').format('MMM')
  );
}

export function getOrderedDataByYear(byYear, xAxisLegend: string[]) {
  const orderedDataByYear: Record<string, any> = {};
  Object.keys(byYear).forEach((year) => {
    orderedDataByYear[year] = sortBy(byYear[year], (d) => xAxisLegend.indexOf(dayjs().month(parseInt(d.key.split('-')[1], 0) - 1).format('MMM'))
    );
  });
  return orderedDataByYear;
}

export interface StackedBarOptions {
  xAxisLegend: string[];
  yAxisTitle: string;
  exportTitle: string;
  series: any[];
  height?: number | null;
}
export function getStackedColumnChartConfig({
  xAxisLegend,
  exportTitle,
  series,
}: StackedBarOptions) {
  return {
    credits: { enabled: false },
    plotOptions: {
      column: {
        stacking: 'normal',
        borderWidth: 1,
        borderRadius: 2,
        minPointLength: 2,
      },
    },
    chart: { type: 'column' },
    title: { text: undefined },
    lang: {
      noDataImage: require('../assets/no-data-group.svg')
    },
    xAxis: {
      categories: xAxisLegend, lineWidth: 0,
      labels: {
        rotation: 0,
        style: {
          textOverflow: 'ellipsis',
        },
      },
    },
    yAxis: {
      title: { text: undefined },
      stackLabels: { ...getStackedLabelsConfig(true) },
      startOnTick: false,
      endOnTick: false,
      tickPositions: [],
      min: 0
    },
    exporting: getExportOptions(exportTitle),
    series,
  };
}

export function getStackedBarChartConfig({
  xAxisLegend,
  exportTitle,
  series,
  height = null,
}: StackedBarOptions) {
  return {
    credits: { enabled: false },
    plotOptions: {
      series: {
        stacking: 'normal',
        pointWidth: 8,
        borderRadiusTopLeft: '45%',
        borderRadiusTopRight: '45%',
      },
    },
    yAxis: {
      title: '',
      stackLabels: {
        enabled: true,
        format: '{total}',
      }
    },
    chart: { type: 'bar', height: height },
    title: { text: undefined },
    lang: {
      noDataImage: require('../assets/no-data-group.svg')
    },
    xAxis: {
      categories: xAxisLegend, lineWidth: 0,
      labels: {
        rotation: 0,
        style: {
          textOverflow: 'ellipsis',
        },
      },
    },
    exporting: getExportOptions(exportTitle),
    series,
    legend: {
      reversed: true
    },
  };
}

export interface PieChartOptions {
  field: FieldCode;
  exportTitle: string;
  dataLabelFormat: string;
  series: any[];
  width: number | null;
}
export function getPieChartConfig({
  field,
  exportTitle,
  dataLabelFormat = '{point.name}: {point.percentage:.1f} %',
  series,
  width = null,
}: PieChartOptions) {
  return {
    credits: { enabled: false },
    chart: { type: 'pie', width: width },
    title: { text: '' },
    lang: {
      noDataImage: require('../assets/no-data-group.svg')
    },
    plotOptions: {
      pie: {
        showInLegend: true,
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: dataLabelFormat,
        },
        startAngle: -90,
        endAngle: 90,
        size: '80%'
      },
    },
    legend: {
      align: 'center',
      verticalAlign: 'bottom',
      floating: true,
      padding: 0,
      margin: 0,
      itemDistance: 10,
      itemMarginBottom: 1,
      symbolRadius: 5,
      x: 0,
      ...(setLegend(field, series[0].data.length) as object),
    },
    exporting: { ...getExportOptions(exportTitle), sourceWidth: 900 },
    series,
  };
}

export interface BarChartOptions {
  CHART_HEIGHT_PX?: number;
  xAxisLegend: string[];
  xAxisTitle?: string;
  yAxisTitle: string;
  exportTitle: string;
  series: any[];
  stacking?: string | undefined;
}
export function getBarChartConfig(options: BarChartOptions) {
  const {
      CHART_HEIGHT_PX,
      xAxisLegend,
      exportTitle,
      series,
  } = options;

  const stacking = Object.prototype.hasOwnProperty.call(options,'stacking') ? options.stacking : 'normal';

  return {
    chart: { type: 'bar', height: CHART_HEIGHT_PX },
    exporting: {
      ...getExportOptions(exportTitle),
      sourceHeight: CHART_HEIGHT_PX,
      sourceWidth: 1500,
      allowHTML: true,
    },
    legend: {
      enabled: true,
    },
    credits: { enabled: false },
    series: series,
    plotOptions: {
      series: {
        stacking: stacking,
        borderRadiusTopLeft: '45%',
        borderRadiusTopRight: '45%',
        pointWidth: 8,
      },
    },
    title: { text: '' },
    lang: {
      noDataImage: require('../assets/no-data-group.svg')
    },
    xAxis: {
      // categories: this.metrics.map(d => d.name),
      categories: xAxisLegend,
      title: { text: undefined },
      labels: {
        style: {
          fontSize: '12px',
          wordBreak: 'break-all',
          textOverflow: 'ellipsis',
          color: '#000000',
          font: 'normal normal 600 12px/16px Overpass',
        },
      },
    },
    yAxis: {
      stackLabels: getStackedLabelsConfig(true, '12px'),
      title: { text: undefined },
      maxPadding: 0.07
    },
    tooltip: {
      backgroundColor: '#F4FCFD',
      useHTML: false
    },
  };
}


export function getPieChartNoDataOptions(
  {
    exportTitle,
  }: PieChartOptions) {
  return {
    credits: { enabled: false },
    chart: {
      height: 300,
      events: {
        redraw: function () {
          //eslint-disable-next-line  @typescript-eslint/no-this-alias
          const chart: any = this;
          if (chart.noData) {
            chart.noData.attr({
              x: chart.plotLeft + chart.plotWidth / 2 - 108,
              y: chart.plotTop + chart.plotHeight / 2 - 30,
            });
          }
        }
      },
    },
    title: false,
    lang: {
      noDataImage: require('../assets/pie-chart-text-icon-only.svg')
    },
    exporting: { ...getExportOptions(exportTitle) },
    series: [
      {
        type: 'pie',
        data: [{ y: 1, color: '#ECF0F4', name: 'No data' }]
      }
    ],
    tooltip: {
      enabled: false
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false
          },
          inactive: {
            opacity: 1,
          }
        }
      },
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        },
        startAngle: -90,
        endAngle: 90,
        size: '100%',
        innerSize: '50%',
        showInLegend: true
      }
    },

    legend: {
      y: 10,
      itemStyle: {
        font: 'normal normal normal 12px/16px Overpass',
        color: '#76869A'
      },
      itemHoverStyle: {
        color: '#76869A'
      },
    }
  };
}

export function getStackedColumnChartNoDataOptions(
  {
    exportTitle,
  }: StackedBarOptions) {

  return {
    chart: {
      type: 'column',
      height: 384,
      events: {
        redraw: function () {
          //eslint-disable-next-line  @typescript-eslint/no-this-alias
          const chart: any = this;
          if (chart.noData) {
            chart.noData.attr({
              x: chart.plotLeft + chart.plotWidth / 2 - 100,
              y: chart.plotTop + chart.plotHeight / 2 - 80,
            });
          }
        }
      }
    },
    exporting: { ...getExportOptions(exportTitle) },
    credits: { enabled: false },
    colors: ['#ECF0F4'],
    lang: {
      noDataImage: require('../assets/chart-text-icon-only.svg')
    },
    title: {
      text: ''
    },
    xAxis: {
      categories: [''],
      title: {
        text: null
      },
      labels: {
        enabled: false,//default is true
      }
    },
    yAxis: {
      title: {
        text: ''
      }
    },
    legend: {
      y: -50,
      itemStyle: {
        color: '#76869A',
        font: 'normal normal normal 12px/16px Overpass',
        fontWeight: 'lighter',
        opacity: '100%'
      }
    },

    plotOptions: {
      pie: {
        shadow: false,
      },
      series: {
        states: {
          hover: {
            enabled: false
          }
        }
      }
    },
    series: [{
      name: 'No Data',
      color: '#ECF0F4',
      data: [
        [],
      ],

      showInLegend: true,
      dataLabels: {
        enabled: false
      }
    }]
  };
}

export function getBarChartNoDataOptions(
  {
    exportTitle,
  }: BarChartOptions) {
  return {
    credits: false,
    chart: {
      type: 'bar',
      events: {
        redraw: function () {
          //eslint-disable-next-line  @typescript-eslint/no-this-alias
          const chart: any = this;
          if (chart.noData) {
            chart.noData.attr({
              x: chart.plotLeft + chart.plotWidth / 2 - 110,
              y: chart.plotTop + chart.plotHeight / 2 - 80,
            });
          }
          if (chart.engagementLabel) {
            chart.engagementLabel.attr({
              x: chart.plotLeft + chart.plotWidth / 2 - 35,
              y: chart.plotTop + chart.plotHeight / 2 + 195,
            });
          }
        }
      }
    },
    exporting: { ...getExportOptions(exportTitle) },
    title: false,
    lang: {
      noDataImage: require('../assets/nodata-horiz-line-chart-text-icon-only.svg')
    },
    yAxis: {

      gridLineWidth: 1,
      title: {
        text: ''
      },
      labels: {
        style: {
          color: '#76869A'
        }
      },
      min: 0,
      max: 1000
    },
    xAxis: {
      labels: {
        x: -15,
        y: -139,
        style: {
          color: '#76869A'
        }
      },
      type: 'category'
    },
    /*   legend: {
           symbolPadding: 0,
           symbolWidth: 0,
           symbolHeight: 0,
           squareSymbol: false
       },*/
    legend: {
      itemMarginTop: 20,
      x: 72,
      color: '#76869A',
      itemStyle: {
        font: 'normal normal normal 12px/16px Overpass',
        color: '#76869A'
      },
    },
    series: [{
      name: 'No data',
      color: '#ECF0F4',
      data: [['No documents to display', {}]]
    }]
  };
}

export function getSplineChartNoDataOptions(
  {
    exportTitle,
  }: LineChartOptions) {
  return {
    exporting: { ...getExportOptions(exportTitle) },
    credits: false,
    title: false,
    lang: {
      noDataImage: require('../assets/no-data-group.svg')
    },
    colorAxis: [{
      maxColor: '#ECF0F4',
      minColor: '#ECF0F4',
      labels: {
        format: '{value}%'
      },
      reversed: true
    }],
    xAxis: {
      min: 0,
      max: 11,
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      style: {
        color: '#ECF0F4'
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: '',
      },
      style: {
        color: '#ECF0F4'
      }
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: false
        },
        states: {
          hover: {
            enabled: false
          }
        }
      },
      line: {
        marker: {
          enabled: false
        }
      }
    },
    chart: {
      type: 'spline'
    },
    tooltip: {
      enabled: false
    },
    series: [{
      name: 'No data',
      data: [null, null, null, null, null, 17, null, null, null, null, null],
      color: '#ECF0F4',
      marker: {
        enabled: false
      },
      states: {
        hover: {
          lineWidth: 0
        }
      }
    }]
  };



}
export interface LineChartOptions {
  xAxisLegend: string[];
  yAxisTitle: string;
  exportTitle: string;
  series: any[];
}

export function getSplineChartConfig({ exportTitle, xAxisLegend, yAxisTitle, series }: LineChartOptions) {
  return {
    credits: { enabled: false },
    chart: { type: 'spline' },
    title: { text: undefined },
    lang: {
      noDataImage: require('../assets/nodata-horiz-line-chart-with-text-and-logo.svg')
    },
    xAxis: { categories: xAxisLegend },
    yAxis: {
      title: { text: yAxisTitle },
      allowDecimals: false,
    },
    exporting: { ...getExportOptions(exportTitle) },
    series
  };
}

function setLegend(field: FieldCode, seriesSize: number) {
  if (field == 'ContentTheme') {
    return { y: 0 };
  }

  switch (seriesSize) {
    case 1:
    case 2:
      return { y: -90 };
    case 3:
    case 4:
      return { y: -40 };
    case 5:
    case 6:
      return { y: -20 };
    case 7:
    case 8:
      return { y: -20 };
    case 9:
    case 10:
      return { y: -10 };
    default:
      return { y: -30 };
  }


}

export function seriesHasData(series) {
  let total = 0;
  series?.forEach((outerItem) => {
    outerItem.data.forEach((inner) => {
      total += inner.y;
    });
  });
  return total !== 0;

}

export interface SeriesData {
  name: string;
  data: (number | null)[];
  color?: any;
}
