import { FriendlyFilterRow } from './filterFactory';
import { Filter } from './filter';
import { FieldCode } from './fieldCode';
import { startCase } from 'lodash';

export class BoolFilter implements Filter {
  field: FieldCode;
  allowNA: boolean;
  value: boolean | null;
  readonly $type = 'BoolFilter';

  constructor(opts: Partial<BoolFilter> = {}) {
    Object.assign(this, opts);
    this.field = this.field;
  }

  public isEmpty() {
    return this.value === null || this.value === undefined;
  }

  getFriendlyText(labelOverride: string): FriendlyFilterRow {
    const label = `${labelOverride || startCase(this.field)}`;
    return <FriendlyFilterRow>{ filterName: label, operation: 'is', value: this.value ? 'True' : 'False' };
  }

  public clearValue() {
    this.value = null;
  }
}
