import { IntLookup } from '../lookup';

export enum ActivityReportType {
  Individual = 1,
  Consolidated = 2,
  Webhook = 3
}

export type ActivityReportTypeString = 'Individual' | 'Consolidated' | 'Webhook';

export const ACTIVITY_REPORT_TYPE_LOOKUPS: ActivityReportTypeString[] = [
  'Individual',
  'Consolidated',
  'Webhook'
];
