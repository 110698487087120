<template>
  <div v-if="anyData" class="col-12">
    <div v-if="showTitle">
      <div class="p-column-value">
        Specifics
      </div>
      <!-- <hr class="flex align-self-center mt-2 mb-3 hr-color"> -->
    </div>
    <div v-if="modelValue">
      <list-questions v-model="array1" title="Questions Asked by Reader" />
      <hr v-if="renderH1" class="align-self-center mt-4 hr-color">
      <list-questions v-model="array2" title="Polls" />
      <hr v-if="renderH2" class="align-self-center mt-4 hr-color">
      <list-questions v-model="array3" title="Surveys" />
      <hr v-if="renderH3" class="align-self-center mt-4 hr-color">
      <list-questions v-model="array4" title="Registration Questions" />
    </div>
    <div v-else-if="specificsFreetext" class="p-column-value font-normal">
      {{ specificsFreetext }}
    </div>
  </div>
</template>

<script lang="ts">
import { computed, PropType } from 'vue';
import ListQuestions from '@/components/analytics/ListQuestions.vue';
import { Specifics } from '@/models/analytics/Specifics';
import { MultiAnswerQuestion, SingleAnswerQuestion, SingleAnswerQuestionWithAuthor } from '@/models/analytics/';

export default {
  name: 'SpecificsQuestions',
  components: { ListQuestions },
  props: {
    modelValue: { type: Object as PropType<Specifics>, required: false },
    specificsFreetext: { type: String, required: false, default: '' },
    showTitle: { type: Boolean, required: false, default: true }
  },
  setup(props) {
    const array1 = computed(() => props.modelValue?.questions?.map(x => new SingleAnswerQuestionWithAuthor(x)));
    const array2 = computed(() => props.modelValue?.polls?.map(x => new MultiAnswerQuestion(x)));
    const array3 = computed(() => props.modelValue?.surveys?.map(x => new MultiAnswerQuestion(x)));
    const array4 = computed(() => props.modelValue?.registrationQuestions?.map(x => new SingleAnswerQuestion(x)));

    const anyQ1 = computed(() => !!array1.value?.length);
    const anyQ2 = computed(() => !!array2.value?.length);
    const anyQ3 = computed(() => !!array3.value?.length);
    const anyQ4 = computed(() => !!array4.value?.length);

    const renderH1 = computed(() => anyQ2.value && anyQ1.value);
    const renderH2 = computed(() => anyQ3.value && (anyQ1.value || anyQ2.value));
    const renderH3 = computed(() => anyQ4.value && (anyQ1.value || anyQ2.value || anyQ3.value));
    const anyData = computed(() => anyQ1.value || anyQ2.value || anyQ3.value || anyQ4.value || props.specificsFreetext);
    return {
      array1,
      array2,
      array3,
      array4,
      renderH1,
      renderH2,
      renderH3,
      anyData
    };
  }
};
</script>

<style scoped>
.hr-color {
  color: #54606E;
}
</style>