<template>
  <div v-if="items">
    <div v-for="(group, index) in items" :key="index" class="flex col-12">
      <Panel :toggleable="true" class="w-full" :collapsed="isCollapsed" :class="{ collapsed: group.isCollapsed }"
        @toggle="group.isCollapsed = !group.isCollapsed">
        <template #header>
          <span class="p-panel-title">{{ group.label }}</span>
        </template>
        <div class="grid mt-2 pb-3 pl-5">
          <div v-for="(field, index1) in group.children" :key="index1" class="col-4 flex align-items-center">
            <InputSwitch v-model="(itemsModel.find(x => x.key == field.key) as ModelValue).value" class="mr-1"
              :disabled="field.isReadOnly" @input="changeState($event, field.key)" />
            <span class="pt-1" :class="(itemsModel.find(x => x.key == field.key) as ModelValue).value ?
              'active-label' : 'inactive-label'">{{ field.label }}</span>
          </div>
        </div>
        <div class="flex flex-row-reverse align-items-center">
          <InputSwitch v-model="(groupsModel.find(x => x.key == group.key) as ModelValue).value" class="mr-2 ml-2"
            @input="activateAll($event, group.key)" /><span>Activate All</span>
        </div>
      </Panel>
    </div>
  </div>
</template>
<script lang="ts">
import { ActivityField, ExportColumnNode, TreeNode } from '@/models/search';
import { groupBy } from 'lodash';
import { Prop, Watch } from 'vue-property-decorator';
import { ActivityFieldCategory, ActivityFieldCategoryEnum, categoryLabel } from '../searchFields/metadata';
import { Vue, Options } from 'vue-class-component';
import { getExportColumnNode } from './activityFieldAdminUtils';
import { FieldCode } from '@/models/filters';
import ActivityFieldsService from '@/services/activityFieldsService';
import DefaultColumnsService from '@/services/defaultColumnService';

export interface ModelValue {
  group: string;
  key: string;
  value: boolean;
}

@Options({
  name: 'cp-export-column-manager',
})
export default class ExportColumnManager extends Vue {
  @Prop({ required: true }) userId: string;
  @Prop({ default: () => false }) isReadOnly: boolean;
  @Prop({ default: () => [] }) rules: Array<(val: string) => any>;
  @Prop({ default: () => false }) isCollapsed: boolean;

  categories: ActivityFieldCategory[];
  items: TreeNode[] | null = null;
  openIds: string[] = [];
  exportFields: ActivityField[];
  selectedValues: FieldCode[] = [];
  defaultColumns: FieldCode[] = [];

  selectedKeys: any = {};
  expandedKeys: any = {};
  fieldChecked: boolean = false;
  itemsModel: Array<ModelValue> = [];
  groupsModel: Array<ModelValue> = [];

  notifyParent(e: any) {
    this.$emit('setSelections', e);
  }

  async mounted() {
    this.exportFields = await ActivityFieldsService.getByUserId(this.userId);
    this.defaultColumns = await DefaultColumnsService.get(this.userId); // For Notification default export columns
      if(!this.defaultColumns?.length) {
        this.defaultColumns = this.exportFields.map((x) => x.field);
      }
    await this.onUserExportColumnsChanged();
  }

  async onUserExportColumnsChanged() {
    this.items = this._getTreeNodes();

    if (!this.defaultColumns?.length) {
      this.notifyParent(this.defaultColumns); // Need this to make sure basic fields are set initially

      this.selectedValues = this.defaultColumns;
    } else {
      this.selectedValues = this.defaultColumns;
    }

    this.itemsModel = (this.items as any).flatMap((y) =>
      y.children.map((x) => <ModelValue>{
        group: y.key,
        key: x.key,
        value: this.selectedValues?.includes(x.key) as boolean,
      })
    );

    this.groupsModel = (this.items as any).map((x) => <ModelValue>{
      key: x.key,
      value: this.changeActiveAll(x.key),
    });

    this.selectedValues = this.itemsModel
      .filter((x) => x.value == true)
      .map((y) => y.key) as FieldCode[];
  }

  private _getTreeNodes(): TreeNode[] {
    const groups = groupBy(this.exportFields, (x) => x.category);

    return Object.keys(groups)
      .map<TreeNode>((cat) => this._getCategoryNode(ActivityFieldCategoryEnum[cat], groups[cat]))
      .sort((a, b) => ((a.sortOrder || 99) > (b.sortOrder || 99) ? 1 : -1));
  }

  private _getCategoryNode(cat: ActivityFieldCategoryEnum, group: ActivityField[]): TreeNode {
    return {
      key: ActivityFieldCategoryEnum[cat],
      label: categoryLabel[cat] || ActivityFieldCategoryEnum[cat],
      children: group
        .map<ExportColumnNode>((c) => {
          const isReadOnly = c.isMandatoryForExport ? true : this.isReadOnly;
          return getExportColumnNode(c, cat, isReadOnly);
        })
        .sort((a, b) => (a.label > b.label ? 1 : -1)),
      sortOrder: cat ?? 99,
      isReadOnly: this.isReadOnly,
      isCollapsed: this.isCollapsed,
    };
  }

  changeState(state, key) {
    (this.itemsModel.find((x) => x.key == key) as ModelValue).value = state;

    this.selectedValues = this.itemsModel
      .filter((x) => x.value == true)
      .map((y) => y.key) as FieldCode[];

    this.notifyParent(this.selectedValues);

    var group = (this.itemsModel.find((x) => x.key == key) as ModelValue).group;

    (this.groupsModel.find((x) => x.key == group) as any).value = this.changeActiveAll(
      group
    );
  }

  activateAll(state, key) {
    // console.debug(key);
    var category = (this.items as any).filter((x) => x.key == key);
    // console.debug(category);

    if (category.length > 0) {
      category[0].children.forEach((x) => {
        if (!x.isReadOnly)
          (this.itemsModel.find((y) => y.key == x.key) as ModelValue).value = state;
      });
    }

    this.selectedValues = this.itemsModel
      .filter((x) => x.value == true)
      .map((y) => y.key) as FieldCode[];

    this.notifyParent(this.selectedValues);
  }

  changeActiveAll(key: string): boolean {
    if (
      this.itemsModel.filter((x) => x.group == key).length ==
      this.itemsModel.filter((x) => x.value == true && x.group == key).length
    ) {
      return true;
    } else {
      return false;
    }
  }
}
</script>
<style lang="scss">
.filter-names-tree {
  .v-treeview-node__prepend {
    min-width: 0 !important;
  }


}
</style>
./activityFieldAdminUtils