<template>
  <form ref="addForm" :disabled="modelValue.isReadOnly">
    <div class="flex col-12 pt-0">
      <h3>See results for EITHER Reader OR Reader Organization:</h3>
    </div>
    <div class="flex p-fluid col-12 px-0 pt-2">
      <cp-action-item-list v-model="modelValue.aggField" :value-array="aggregateTypes" layout="sm-card" />
    </div>
    <div v-if="isSeriesOfActivities" class="p-fluid col-12 px-0 ">
      <h3>Notify when:</h3>
      <small v-if="customValidate() == false" class="p-error pt-4">Please correct the below highlighted errors.</small>
      <div class="flex col-12 pl-0 pt-4">
        <div class="col-6 pl-0">
          <div v-if="!isReaderOrg" class="p-secondary-color pb-3">
            A reader from your target Reader Org(s)
          </div>
          <div v-if="isReaderOrg" class="flex p-secondary-color align-items-center">
            <span class=" pr-3">At least</span>
            <span>
              <InputNumber id="vertical" v-model="modelValue.uniqueUserCount" mode="decimal" show-buttons
                button-layout="vertical" class="w-3rem" increment-button-icon="pi pi-angle-up"
                decrement-button-icon="pi pi-angle-down" :min="1" :max="99"
                :class="{ 'p-invalid': modelValue.uniqueUserCount == null }" />
            </span><span class=" pl-3">or more readers from your target Reader Org(s)</span>
          </div>
          <div class="flex pt-2 p-secondary-color align-items-center">
            <span class=" pr-3">engaged with your content at least</span>
            <span>
              <InputNumber id="vertical" v-model="modelValue.minimumCount" mode="decimal" show-buttons
                button-layout="vertical" class="w-3rem" increment-button-icon="pi pi-angle-up"
                decrement-button-icon="pi pi-angle-down" :min="1" :max="99"
                :class="{ 'p-invalid': modelValue.minimumCount == null }" />
            </span><span class=" pl-3">time(s),</span>
          </div>
          <div class="flex pt-2 p-secondary-color align-items-center">
            <span class=" pr-3">for the last</span>
            <span>
              <InputNumber id="vertical" v-model="modelValue.activityMonths" mode="decimal" show-buttons
                button-layout="vertical" class="w-3rem" increment-button-icon="pi pi-angle-up"
                decrement-button-icon="pi pi-angle-down" :min="1" :max="12"
                :class="{ 'p-invalid': modelValue.activityMonths == null }" />
            </span><span class=" pl-3">month(s).</span>
          </div>
        </div>
        <div class="col-6">
          <Card class="p-alerts-user-card p-secondary-color pt-0 pl-3">
            <template #content>
              <div class="px-2 pb-3 pt-1">
                <div class="flex align-items-center">
                  <span class="material-icons-outlined text-4xl card-header"> info </span>
                  <span class="pl-3 font-bold card-header">Your Selection:</span>
                </div>
                <div class="pt-3">
                  <div v-if="!isReaderOrg" class="text-base p-secondary-color">
                    This notification is triggered when a target Reader has engaged with
                    your content {{ modelValue.minimumCount }} or more times over the last
                    {{ modelValue.activityMonths }} month(s).
                  </div>
                  <div v-if="isReaderOrg" class="text-base p-secondary-color">
                    This notification is triggered when at least
                    {{ modelValue.uniqueUserCount }} or more readers from your target
                    Reader Org have engaged with your content
                    {{ modelValue.minimumCount }} or more times over the last
                    {{ modelValue.activityMonths }} month(s).
                  </div>
                </div>
              </div>
            </template>
          </Card>
        </div>
      </div>
    </div>

    <div v-if="isChangeOverTime" class="p-fluid col-12 px-0 ">
      <h3>Notify when:</h3>
      <small v-if="customValidate() == false" class="p-error pt-4">Please correct the below highlighted errors.</small>

      <div class="flex col-12 pl-0 pt-4">
        <div class="col-6 pl-0">
          <div v-if="!isReaderOrg" class="p-secondary-color pb-3">
            A reader at one or more your target Reader Org(s)
          </div>
          <div v-if="isReaderOrg" class="flex p-secondary-color align-items-center">
            <span class="pr-3">At least</span>
            <span>
              <InputNumber id="vertical" v-model="modelValue.uniqueUserCount" mode="decimal" show-buttons
                button-layout="vertical" class="w-3rem" increment-button-icon="pi pi-angle-up"
                decrement-button-icon="pi pi-angle-down" :min="1" :max="99"
                :class="{ 'p-invalid': modelValue.uniqueUserCount == null }" />
            </span><span class=" pl-3">or more readers from your target Reader Org(s)
            </span>
          </div>
          <div class="flex p-secondary-color align-items-center">
            <span class="pr-3">engaged with your content in the prior</span>
            <span>
              <InputNumber id="vertical" v-model="modelValue.baselineActivityMonths" mode="decimal" show-buttons
                button-layout="vertical" class="w-3rem" increment-button-icon="pi pi-angle-up"
                decrement-button-icon="pi pi-angle-down" :min="1" :max="12"
                :class="{ 'p-invalid': modelValue.baselineActivityMonths == null }" />
            </span><span class=" px-3">month(s)</span>
          </div>
          <div class="flex p-secondary-color align-items-center">
            <span class="pr-3">at least</span>
            <span>
              <InputNumber id="vertical" v-model="modelValue.minimumBaselineActivities" mode="decimal" show-buttons
                button-layout="vertical" class="w-3rem" increment-button-icon="pi pi-angle-up"
                decrement-button-icon="pi pi-angle-down" :min="1" :max="99"
                :class="{ 'p-invalid': modelValue.minimumBaselineActivities == null }" />
            </span><span class=" px-3">time(s) on average, and has been</span>
            <span>
              <InputNumber id="vertical" v-model="modelValue.minimumThresholdPercent" mode="decimal" show-buttons
                button-layout="vertical" class="w-4rem" increment-button-icon="pi pi-angle-up"
                decrement-button-icon="pi pi-angle-down" :min="1" :max="100" suffix=" %"
                :class="{ 'p-invalid': modelValue.minimumThresholdPercent == null }" />
            </span>
          </div>
          <div class="flex p-secondary-color align-items-center ">
            <span>
              <Dropdown id="ddDirection" v-model="thresholdDirection" :options="thresholdDirections" option-label="text"
                option-value="type" style="border: 1px solid #cad0d8" @change="changeThresholdDirection($event.value)">
                <template #value="slotProps">
                  <span class="dropdown-text"> {{ slotProps.value.text }}</span>
                </template>
              </Dropdown>
            </span><span class=" px-3">active in the last</span><span>
              <InputNumber id="vertical" v-model="modelValue.activityMonths" mode="decimal" show-buttons
                button-layout="vertical" class="w-3rem" increment-button-icon="pi pi-angle-up"
                decrement-button-icon="pi pi-angle-down" :min="1" :max="12"
                :class="{ 'p-invalid': modelValue.activityMonths == null }" />
            </span>
            <span class=" pl-3">month(s).</span>
          </div>
        </div>
        <div class="col-6">
          <Card class="p-alerts-user-card p-secondary-color pt-0 pl-3">
            <template #content>
              <div class="px-2 pb-3 pt-1">
                <div class="flex align-items-center">
                  <span class="material-icons-outlined text-4xl card-header"> info </span>
                  <span class="pl-3 font-bold card-header">Your Selection:</span>
                </div>
                <div class="pt-3">
                  <div v-if="!isReaderOrg" class="text-base p-secondary-color">
                    This notification is triggered when a target Reader has engaged with
                    your content in the prior {{ modelValue.baselineActivityMonths }} months at least
                    {{ modelValue.minimumBaselineActivities }} time(s) on average, and has been
                    {{ modelValue.minimumThresholdPercent }}%
                    {{ thresholdDirections.find((x) => x.type == modelValue.thresholdDirection).text }}
                    active in the last {{ modelValue.activityMonths }} month(s).
                  </div>
                  <div v-if="isReaderOrg" class="text-base p-secondary-color">
                    This notification is triggered when at least
                    {{ modelValue.uniqueUserCount }} or more readers from your target
                    Reader Org engaged with your content in the prior {{ modelValue.baselineActivityMonths }} months at
                    least
                    {{ modelValue.minimumBaselineActivities }} time(s) on average, and has been
                    {{ modelValue.minimumThresholdPercent }}%
                    {{ thresholdDirections.find((x) => x.type == modelValue.thresholdDirection).text }}
                    active in the last {{ modelValue.activityMonths }} month(s).
                  </div>
                </div>
              </div>
            </template>
          </Card>
        </div>
      </div>
    </div>
  </form>
</template>

<script lang="ts">
import { NotificationWorkflowMixin } from '@/mixins/notificationWorkflow.mixin';
import {
  ChangeInActivitiesNotificationBehaviorChangeRequest,
  InsightType,
  InsightTypeActionItem,
  INSIGHT_TYPE_ACTIONS,
  NotificationBehaviorChangeRequest,
  SeriesOfActivitiesNotificationBehaviorChangeRequest,
  ThresholdDirectionActionItem,
  THRESHOLD_DIRECTION_ACTIONS,
} from '@/models/notification/behavior-change';
import notificationService from '@/services/notificationService';
import { between, required } from '@/utils/validators/validators';
import { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import NotificationStepperTitleBar from './common/NotificationStepTitleBar.vue';
import ActionItemList from '@/components/common/ActionItemList.vue';
import { Options } from 'vue-class-component';

@Options({
  name: 'cp-notification-behavior-change-step',
  components: {
    'cp-stepper-title-bar': NotificationStepperTitleBar,
    'cp-action-item-list': ActionItemList,
  },
})
export default class NotificationBehaviorChangeStep extends mixins(
  NotificationWorkflowMixin
) {
  aggregateTypes: InsightTypeActionItem[] = [...INSIGHT_TYPE_ACTIONS];
  thresholdDirections: ThresholdDirectionActionItem[] = [...THRESHOLD_DIRECTION_ACTIONS];

  rules = {
    activityMonths: [between('Activity Months', 1, 12), required('Activity Months')],
    minimumCount: [
      between('Minimum # of activities', 1, 99),
      required('Minimum # of activities'),
    ],
    uniqueUserCount: [between('Unique User Count', 1, 99)],
    currentMonths: [between('Current Months', 1, 6)],
    baselineActivityMonths: [
      between('Baseline Months to compare with', 1, 12),
      required('Baseline Months to compare with'),
    ],
    minimumThresholdPercent: [
      between('Minimum threshold % for activities', 1, 200),
      required('Minimum threshold % for activities'),
    ],
    minimumBaselineActivities: [
      between('Minimum Baseline # of activities', 1, 99),
      required('Minimum Baseline # of activities'),
    ],
  };

  /**
   * VueJS lifecycle event - mounted
   * This method handles:
   * 1. Setting default values on the bound data model
   */
  mounted(): void {
    this.$emit('is-loaded', false);
    this._configDefaults();
    this.$emit('is-loaded', true);
  }

  @Watch('$route')
  async routeChange() {
    this.$emit('is-loaded', false);
    this._configDefaults();
    this.$emit('is-loaded', true);
  }

  /**
   * Calls the Liberty Bell API and saves the behavior change options to the loaded Notification
   *
   * @returns Notification Id
   */
  async save(): Promise<string> {
    try {
      if (this.customValidate() == false) {
        return Promise.reject();
      }

      // If n.aggField != 'Reader' don't send Unique User Count
      const id = this.$route.params['id'].toString();
      const request: NotificationBehaviorChangeRequest = this.isChangeOverTime
        ? ChangeInActivitiesNotificationBehaviorChangeRequest.fromNotification(
          this.modelValue
        )
        : SeriesOfActivitiesNotificationBehaviorChangeRequest.fromNotification(
          this.modelValue
        );
      // console.log(this.modelValue);
      await notificationService.setBehaviorChange(id, request);
      this.$emit('update:modelValue', this.modelValue);
      return Promise.resolve(this.modelValue.id);
    } catch (err) {
      // Send error message back to parent
      this.showError([this.ERROR_MESSAGES.save, this.ERROR_MESSAGES.sysAdmin]);
      return Promise.reject(err);
    }
  }

  customValidate(): boolean {
    if (this.isSeriesOfActivities) {
      if (
        this.isReaderOrg &&
        (this.modelValue.minimumCount == null ||
          this.modelValue.activityMonths == null ||
          this.modelValue.uniqueUserCount == null)
      ) {
        return false;
      } else if (
        !this.isReaderOrg &&
        (this.modelValue.minimumCount == null || this.modelValue.activityMonths == null)
      ) {
        return false;
      }
    } else {
      if (
        this.isReaderOrg &&
        (this.modelValue.minimumBaselineActivities == null ||
          this.modelValue.minimumThresholdPercent == null ||
          this.modelValue.activityMonths == null ||
          this.modelValue.baselineActivityMonths == null ||
          this.modelValue.uniqueUserCount == null)
      ) {
        return false;
      } else if (
        !this.isReaderOrg &&
        (this.modelValue.minimumBaselineActivities == null ||
          this.modelValue.minimumThresholdPercent == null ||
          this.modelValue.activityMonths == null)
      ) {
        return false;
      }
    }

    return true;
  }

  get isChangeOverTime(): boolean {
    return this.modelValue.type === 'ChangeInActivitiesNotification';
  }

  get isSeriesOfActivities(): boolean {
    return this.modelValue.type === 'SeriesOfActivitiesNotification';
  }

  get isReaderOrg(): boolean {
    return this.modelValue.aggField === InsightType.ReaderOrg;
  }

  private _configDefaults() {
    if (this.isSeriesOfActivities) {
      this.modelValue.minimumCount = this.modelValue.minimumCount || 1;
    }
    if (this.isChangeOverTime) {
      this.modelValue.baselineActivityMonths =
        this.modelValue.baselineActivityMonths || 3;
      this.modelValue.minimumThresholdPercent =
        this.modelValue.minimumThresholdPercent || 50;
      this.modelValue.minimumBaselineActivities =
        this.modelValue.minimumBaselineActivities || 1;
    }
    this.modelValue.activityMonths = this.modelValue.activityMonths || 1;
    this.modelValue.uniqueUserCount = this.modelValue.uniqueUserCount || 1;
  }

  get title(): string {
    return !this.isSeriesOfActivities ? 'Behavior Change' : 'Behavior Threshold';
  }

  get thresholdDirection() {
    return this.thresholdDirections.find(
      (x) => x.type == this.modelValue.thresholdDirection
    );
  }

  changeThresholdDirection(value) {
    this.modelValue.thresholdDirection = value;
  }
}
</script>

<style lang="scss" scoped>
.dropdown-text {
  font-size: 1rem !important;
  color: #5472da !important;
  font-weight: bold !important;
}

.card-header {
  color: #5472da;
}
</style>
