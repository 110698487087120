import { ErrorMessage } from '@/models/common/errorMessage';
import { Emit, Prop } from 'vue-property-decorator';
import { NavigationItem, Notification, NotificationWorkflowStep } from '@/models/notification';
import { Vue, Options } from 'vue-class-component';
import eventBus from '../plugins/eventBus';

@Options({})
export class NotificationWorkflowMixin extends Vue implements NotificationWorkflowStep {
  @Prop() formId!: string;
  @Prop() modelValue!: Notification;
  @Prop() bus!: any;
  @Prop() step!: number;

  declare $refs: { addForm: HTMLFormElement };
  ERROR_MESSAGES = {
    save: 'An unknown error occurred while processing your request.',
    retry: 'Please try again later.',
    sysAdmin: 'Contact your system administrator if this error is persistent.'
  };

  /**
   * VueJS lifecycle event - created
   * This method handles:
   * 1. Subscribing event bus listeners via @param bus
   */
  created(): void {
    eventBus.all.clear();

    eventBus.on(`save-${this.step}`, this.save);
    eventBus.on(`save-continue-${this.step}`, this.saveAndContinue);
    eventBus.on(`go-back-${this.step}`, this.saveAndGoBack);
  }

  /**
   * VueJS lifecycle event - beforeDestroy
   * This method handles:
   * 1. Unsubscribing event bus listeners via @param bus
   */
  beforeDestroy(): void {
    eventBus.off(`save-${this.step}`, this.save);
    eventBus.off(`save-continue-${this.step}`, this.saveAndContinue);
    eventBus.off(`go-back-${this.step}`, this.saveAndGoBack);
  }


  /**
   * Calls the Liberty Bell API
   *
   * Sends the resulting Id to the subscribers
   * @returns @interface NavigationItem with Notification Id and a forward direction
   */
  @Emit('step-move')
  saveAndContinue(): Promise<NavigationItem> {
    return new Promise((resolve, reject) => {
      this.save().then(x => {
        this.$emit('update:modelValue', this.modelValue);
        return resolve({ id: x, dir: 'f' });
      }).catch(e => reject(e));
    });
    // // save the alert via API
    // const res = await this.save();
    // return Promise.resolve({id: res, dir: 'f'});
  }

  /**
   * Calls the Liberty Bell API
   *
   * Sends the resulting Id to the subscribers
   * @returns @interface NavigationItem with Notification Id and a backward direction
   */
  @Emit('step-move')
  saveAndGoBack(): Promise<NavigationItem> {
    return new Promise((resolve, reject) => {
      this.save().then(x => {
        this.$emit('update:modelValue', this.modelValue);
        return resolve({ id: x, dir: 'b' });
      }).catch(e => reject(e));
    });
  }

  /**
   * Calls the Liberty Bell API and saves the form fields to the loaded Notification
   *
   * @returns Notification Id
   */
  save(): Promise<string> { return Promise.resolve(''); }

  showError(msg: string[], d: string[] = [], t: string = 'Unknown Request Error') {
    const errMsg: ErrorMessage = { title: t, details: d, message: msg, show: true };
    this.$emit('error', errMsg);
  }

  get isEditWorkflow(): boolean {
    return this.modelValue.isComplete;
  }
}
