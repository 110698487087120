import { GetWorkflowType, NotificationType, Notification, WorkflowType, WorkflowStepEnum } from '.';
import NotificationIntroStep from '@/components/notifications/NotificationIntroStep.vue';
import NotificationDefineFiltersStep from '@/components/notifications/NotificationDefineFiltersStep.vue';
import NotificationBehaviorChangeStep from '@/components/notifications/NotificationBehaviorChangeStep.vue';
import NotificationAlertMethodStep from '@/components/notifications/NotificationAlertMethodStep.vue';
import NotificationFrequencyFormatStep from '@/components/notifications/NotificationFrequencyFormatStep.vue';
import NotificationFinalStep from '@/components/notifications/NotificationFinalStep.vue';
import { cloneDeep } from 'lodash';
import { StepDataItem } from '@/models/steppers/stepDataItem';


export interface NotificationStepperItems {
  type?: NotificationType;
  items: StepDataItem<Notification>[];
}

export const INTRO_STEP_ONLY_ITEMS: NotificationStepperItems = {
  items: [
    { step: 1, text: 'Begin', stepCompleted: n => WorkflowStepEnum[n.lastStepCompleted] >= WorkflowStepEnum.Begin, componentDef: NotificationIntroStep, isActive: () => true }
  ]
};

const SPECIFIC_ACTIVITY_STEPPER_METADATA: NotificationStepperItems = {
  type: 'SpecificActivityNotification',
  items: [
    { step: 1, text: 'Begin', stepCompleted: n => 
      WorkflowStepEnum[n.lastStepCompleted] >= WorkflowStepEnum.Begin, componentDef: NotificationIntroStep, isActive: () => true },
    { step: 2, text: 'Define Filters', stepCompleted: n => 
      WorkflowStepEnum[n.lastStepCompleted] >= WorkflowStepEnum.DefineFilters, componentDef: NotificationDefineFiltersStep, isActive: n => !n.isNew },
    { step: 3, text: 'Set Alert', stepCompleted: n => 
      WorkflowStepEnum[n.lastStepCompleted] >= WorkflowStepEnum.SetAlert, componentDef: NotificationAlertMethodStep, isActive: n => n.hasMinimumFilters },
    { step: 4, text: 'Frequency/Format', stepCompleted: n => 
      WorkflowStepEnum[n.lastStepCompleted] >= WorkflowStepEnum.Frequency, componentDef: NotificationFrequencyFormatStep, isActive: n => n.hasTargets },
    { step: 5, text: 'Activate', stepCompleted: n => 
      WorkflowStepEnum[n.lastStepCompleted] >= WorkflowStepEnum.Activate, componentDef: NotificationFinalStep, isActive: n => n.hasFrequency }
  ]
};

const SERIES_OF_ACTIVITIES_STEPPER_METADATA: NotificationStepperItems = {
  type: 'SeriesOfActivitiesNotification',
  items: [
    { step: 1, text: 'Begin', stepCompleted: n => 
      WorkflowStepEnum[n.lastStepCompleted] >= WorkflowStepEnum.Begin, componentDef: NotificationIntroStep, isActive: () => true },
    { step: 2, text: 'Define Filters', stepCompleted: n => 
      WorkflowStepEnum[n.lastStepCompleted] >= WorkflowStepEnum.DefineFilters, componentDef: NotificationDefineFiltersStep, isActive: n => !n.isNew },
    {
      step: 3, text: 'Behavior Threshold', stepCompleted: n => 
      WorkflowStepEnum[n.lastStepCompleted] >= WorkflowStepEnum.BehaviorChange,
      componentDef: NotificationBehaviorChangeStep, isActive: n => n.hasMinimumFilters
    },
    { step: 4, text: 'Set Alert', stepCompleted: n => 
      WorkflowStepEnum[n.lastStepCompleted] >= WorkflowStepEnum.SetAlert, componentDef: NotificationAlertMethodStep, isActive: n => n.isBehaviorConfigured },
    { step: 5, text: 'Frequency/Format', stepCompleted: n => 
      WorkflowStepEnum[n.lastStepCompleted] >= WorkflowStepEnum.Frequency, componentDef: NotificationFrequencyFormatStep, isActive: n => n.hasTargets },
    { step: 6, text: 'Activate', stepCompleted: n => 
      WorkflowStepEnum[n.lastStepCompleted] >= WorkflowStepEnum.Activate, componentDef: NotificationFinalStep, isActive: n => n.hasFrequency }
  ]
};

const CHANGE_IN_ACTIVITIES_STEPPER_METADATA: NotificationStepperItems = {
  type: 'ChangeInActivitiesNotification',
  items: [
    { step: 1, text: 'Begin', stepCompleted: n => 
      WorkflowStepEnum[n.lastStepCompleted] >= WorkflowStepEnum.Begin, componentDef: NotificationIntroStep, isActive: () => true },
    { step: 2, text: 'Define Filters', stepCompleted: n => 
      WorkflowStepEnum[n.lastStepCompleted] >= WorkflowStepEnum.DefineFilters, componentDef: NotificationDefineFiltersStep, isActive: n => !n.isNew },
    { step: 3, text: 'Behavior Change', stepCompleted: n => 
      WorkflowStepEnum[n.lastStepCompleted] >= WorkflowStepEnum.BehaviorChange, componentDef: NotificationBehaviorChangeStep, isActive: n => n.hasMinimumFilters },
    { step: 4, text: 'Set Alert', stepCompleted: n => 
      WorkflowStepEnum[n.lastStepCompleted] >= WorkflowStepEnum.SetAlert, componentDef: NotificationAlertMethodStep, isActive: n => n.isBehaviorConfigured },
    { step: 5, text: 'Frequency/Format', stepCompleted: n => 
      WorkflowStepEnum[n.lastStepCompleted] >= WorkflowStepEnum.Frequency, componentDef: NotificationFrequencyFormatStep, isActive: n => n.hasTargets },
    { step: 6, text: 'Activate', stepCompleted: n => 
      WorkflowStepEnum[n.lastStepCompleted] >= WorkflowStepEnum.Activate, componentDef: NotificationFinalStep, isActive: n => n.hasFrequency }
  ]
};

export const NOTIFICATION_STEPPER_ITEMS: NotificationStepperItems[] = [
  SPECIFIC_ACTIVITY_STEPPER_METADATA,
  SERIES_OF_ACTIVITIES_STEPPER_METADATA,
  CHANGE_IN_ACTIVITIES_STEPPER_METADATA
];

export function configureItemsByWorkflow(items: StepDataItem<Notification>[], n: Notification): StepDataItem<Notification>[] {
  const workflowType = GetWorkflowType(n ? n.isComplete : false);
  let result = cloneDeep(items);

  if (workflowType === WorkflowType.Edit) {
    result = result.filter(i => i.text !== 'Activate'); // Remove Finish step in Edit Workflow
    result[0].text = 'Name/Description';
  }

  return result;
}
