<template>
  <div v-if="user && !isEditMode"
    class="flex align-items-center p-0 my-2">
    <div class="col-12 lg:col-2 p-secondary-color flex align-items-center px-0">
      Account Information
      <Button v-if="isEditable"
        class="p-button-sm p-button-link ml-1 pl-0"
        @click="toggleMode">
        <i class="material-icons">edit</i>
      </Button>
    </div>
    <div class="col-12 md:col-6 lg:col-4 p-secondary-color px-0">
      {{ user.email }}
      <div v-if="v$.email.$invalid && submitted">
        <small v-if="(v$.email.required.$invalid && submitted) || v$.email.$pending.$response"
          class="p-error">{{ v$.email.required.$message.replace("Value", "Email") }}</small>
        <small v-if="(v$.email.email.$invalid && submitted) || v$.email.$pending.$response"
          class="p-error">{{ v$.email.email.$message.replace("Value", "Email") }}</small>
      </div>
      <div v-if="v$.firstName.$invalid && submitted">
        <small v-if="
                 (v$.firstName.required.$invalid && submitted) ||
                   v$.firstName.$pending.$response
               "
          class="p-error">{{ v$.firstName.required.$message.replace("Value", "First Name") }}</small>
        <small v-if="
                 (v$.firstName.maxLength.$invalid && submitted) ||
                   v$.firstName.$pending.$response
               "
          class="p-error">{{
          v$.firstName.maxLength.$message.replace(
            "allowed is",
            "allowed for First Name is"
          )
        }}</small>
      </div>
      <div v-if="v$.lastName.$invalid && submitted">
        <small v-if="
                 (v$.lastName.required.$invalid && submitted) || v$.lastName.$pending.$response
               "
          class="p-error">{{ v$.lastName.required.$message.replace("Value", "Last Name") }}</small>
        <small v-if="
                 (v$.lastName.maxLength.$invalid && submitted) ||
                   v$.lastName.$pending.$response
               "
          class="p-error">{{
          v$.lastName.maxLength.$message.replace(
            "allowed is",
            "allowed for Last Name is"
          )
        }}</small>
      </div>
    </div>
    <div class="col-12 md:col-6 lg:col-1 p-secondary-color">
      <span v-if="!isClient()">{{ user.role }}</span>      
    </div>
    <div class="col-12 lg:col-2 flex lg:flex-row-reverse">
      <div v-if="sendEmailSuccess"
        class="success-message flex p-0 m-0 pr-2 bg-white">
        {{ successMessage }}
        <span class="material-icons-outlined pl-1"> check_circle_outline </span>
      </div>
    </div>
    <div class="col-12 lg:col-3 flex lg:flex-row-reverse">
      <Button v-if="user.isRegistered"
        type="button"
        class="p-button-outlined p-button-rounded"
        @click="resetPasswordApiCall">
        <span className="p-button-label p-c">Reset Password</span>
        <i class="material-icons ml-3 p-secondary-color">lock_reset</i>
      </Button>
      <Button v-if="!user.isRegistered"
        type="button"
        class="p-button-outlined p-button-rounded"
        label="Resend Registration"
        @click="resendRegistrationEmailApiCall" />
    </div>
  </div>
  <div v-if="user && isEditMode"
    class="grid p-0 my-2">
    <div class="col-12 md:col-5 lg:col-5 p-secondary-color align-items-center">
      <span class="mr-2">Account Information</span> |
      <Button class="p-button-sm p-button-link py-0"
        @click="toggleMode">
        <i class="material-icons">assignment_return</i><span class="ml-1 font-bold">Go Back</span>
      </Button>
    </div>
    <div class="col-12 md:col-3 lg:col-3 flex flex-row-reverse">
      <div v-if="sendEmailSuccess"
        class="success-message flex p-0 m-0 ml-auto pr-2 bg-white">
        {{ successMessage }}
        <span class="material-icons-outlined pl-1"> check_circle_outline </span>
      </div>
    </div>
    <div class="col-12 md:col-4 lg:col-4 flex md:flex-row-reverse">
      <Button v-if="user.isRegistered"
        type="button"
        class="p-button-outlined p-button-rounded ml-3"
        @click="resetPasswordApiCall">
        <span className="p-button-label p-c">Reset Password</span>
        <i class="material-icons ml-3 p-secondary-color">lock_reset</i>
      </Button>
      <Button v-if="!user.isRegistered"
        type="button"
        class="p-button-outlined p-button-rounded ml-3"
        label="Resend Registration"
        @click="resendRegistrationEmailApiCall" />
    </div>
    <cp-profile-user-identification v-if="isEditMode"
      v-model="user"
      :submitted="submitted"
      :v="v$" />
  </div>
</template>
<script lang="ts">
import { AppUser } from '@/models/appUser';

import ProfileUserIdentification from '@/components/profile/UserIdentification.vue';
import { PropType, ref, toRef } from 'vue';
import useUserSecurity from '@/use/userSecurity';
import useUserActions from '@/use/userActions';

export default {
  name: 'CpProfileAccountInformation',
  components: {
    'cp-profile-user-identification': ProfileUserIdentification,
  },
  props: {
    modelValue: { type: Object as PropType<AppUser>, required: true },
    isEditable: { type: Boolean, default: true },
    submitted: Boolean,
    v: { type: Object, required: true },
  },
  setup(props) {
    const isEditMode = ref(false);
    const sendEmailSuccess = ref(false);
    const successMessage = ref('');
    const submitted = toRef(props, 'submitted');

    const { user, isMyProfile } = useUserSecurity(toRef(props, 'modelValue'));
    const {
      passwordResetMessage,
      resetPassword,
      resendRegistrationEmail,
    } = useUserActions(user, toRef(props, 'v'), sendEmailSuccess, submitted, isMyProfile);
    async function resetPasswordApiCall() {
      await resetPassword();
      successMessage.value = passwordResetMessage.value;
    }

    async function resendRegistrationEmailApiCall() {
      await resendRegistrationEmail();
      successMessage.value = `The registration email has been resent to ${user.value.email}`;
    }

    function toggleMode() {
      isEditMode.value = !isEditMode.value;
    }  
    
    function isClient(){
      return user.value.role === 'Client';
    }

    // console.debug(`AccountInformation.setup() - props`, props);

    return {
      isEditMode,
      sendEmailSuccess,
      successMessage,
      user,
      isMyProfile,
      resetPasswordApiCall,
      resendRegistrationEmailApiCall,
      toggleMode,
      v$: props.v,
      isClient
    };
  },
};
</script>
