<template>
  <div>
    <form ref="addForm" :disabled="modelValue.isReadOnly" @submit.prevent="save">
      <div v-if="!isEditMode" class="grid">
        <div class="col-12 pb-0">
          <div class="flex">
            <h1 class="line-height-1">
              Create Alert
            </h1>
            <!-- <span class="help-icon ml-2">?</span> -->
          </div>
        </div>
      </div>
      <div v-if="!isEditMode" class="flex col-12 pt-4">
        <Message :closable="false">
          Fill out the form below and select your alert type to get started.
        </Message>
      </div>
      <div v-if="!isEditMode" class="flex col-12 pt-0">
        <h3>What kind of notification would you like to configure?</h3>
      </div>

      <cp-action-item-list v-if="!isEditMode" v-model="data1.type" :value-array="formFields" />

      <div class="flex col-12 px-0 pt-4">
        <div class="col-4">
          <span class="p-float-label">
            <InputText v-model="data1.name" type="text" class="w-full"
              :class="{ 'p-invalid': validate && (submitted || isEditMode) }" maxlength="60"
              placeholder="Enter your alert title" />
            <label>Alert Title</label>
          </span>
          <small v-if="validate && (submitted || isEditMode)" class="p-error">Alert Title is required</small>
        </div>
        <div class="col-8">
          <span class="p-float-label">
            <Textarea v-model="data1.description" rows="1" type="text" class="w-full" maxlength="500"
              placeholder="Enter your description" />
            <label>Description (500 characters maximum)</label>
          </span>
        </div>
      </div>
      <div v-if="isEditMode" class="flex col-12 py-3">
        <h3>This is a notification for:</h3>
      </div>
      <div v-if="isEditMode" class="col-12 pr-4">
        <Card class="p-alerts-card pt-0 pb-3 text-left" :class="notificationType.class">
          <template #content>
            <div class="p-card-title mb-0">
              {{ notificationType.text }}
            </div>
            <Divider align="left" class="pl-0 mt-1 w-4" />
            <div>
              {{ notificationType.description }}
            </div>
          </template>
        </Card>
      </div>
      <div v-if="!isEditMode" class="col-12 pr-4 flex align-items-center justify-content-center">
        <Button type="button" class="px-4 p-alerts-button text-white" :class="buttonClass" :disabled="!data1.type"
          @click="saveAndContinue">
          <span class="ml-3">Continue</span>
        </Button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { NotificationWorkflowMixin } from '@/mixins/notificationWorkflow.mixin';
import {
  DEFAULT_NOTIFICATION_TYPE_ACTION,
  NavigationItem,
  Notification,
  NotificationCreateRequest,
  NotificationTypeActionItem,
  NOTIFICATION_TYPE_ACTIONS,
  WorkflowStepEnum,
} from '@/models/notification';
import { Claim } from '@/models/security/claim';
import notificationService from '@/services/notificationService';
import { required } from '@/utils/validators/validators';
import { Mixins, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import NotificationStepperTitleBar from './common/NotificationStepTitleBar.vue';
import ActionItemList from '@/components/common/ActionItemList.vue';
import { Options } from 'vue-class-component';
import { DisabledReason } from '@/models/notification/disabledReasons';

@Options({
  name: 'cp-notification-intro-step',
  computed: {
    ...mapGetters('auth', ['satisfiesClaim']),
  },
  components: {
    'cp-stepper-title-bar': NotificationStepperTitleBar,
    'cp-action-item-list': ActionItemList,
  },
})
export default class NotificationIntroStep extends Mixins(NotificationWorkflowMixin) {
  // from vuex
  satisfiesClaim: (claim: Claim) => boolean;
  isEditMode: boolean = false;
  submitted: boolean = false;

  data1: NotificationCreateRequest = {
    name: '',
    description: '',
    type: 'SpecificActivityNotification',
    isReadOnly: false,
    id: '',
    disabled: false,
    disabledReason: new DisabledReason(),
    lastStepCompleted: WorkflowStepEnum.Begin
  };
  rules = {
    name: [required('Name')],
  };
  formFields: NotificationTypeActionItem[] = [...NOTIFICATION_TYPE_ACTIONS];

  /**
   * VueJS lifecycle event - mounted
   * This method handles:
   * 1. Binding the Component @param notification to the @interface NotificationCreateRequest which is using for model binding
   * 2. Configuring this component for the Notification Create Workflow or the Notification Edit Workflow
   */
  mounted(): void {
    this.$emit('is-loaded', false);
    this._configDefaults();
    this.$emit('is-loaded', true);
  }

  /**
   * Calls the Liberty Bell API and either
   * 1. creates a notification
   * 2. updates a notification
   *
   * Sends the resulting Id to the subscribers via @event step-move(id, 'f')
   * @returns @interface NavigationItem with Notification Id and a forward direction
   */
  //@Emit("step-move")
  async saveAndContinue(): Promise<NavigationItem> {
    try {
      this.submitted = true;

      const res = this.isEditMode ? await this.save() : await this.create();
      this.$router.push('/notification/' + res + '/2');
      return Promise.resolve({ id: res, dir: 'f' });
    } catch (err) {
      return Promise.reject(err);
    }
  }

  /**
   * Calls the Liberty Bell API
   * 1. creates a notification
   *
   * @returns Notification Id
   */
  async create(): Promise<string> {
    if (this.validate) {
      return Promise.reject();
    }

    try {
      // save the alert via API
      const res = await notificationService.create(this.data1);
      return Promise.resolve(res.id);
    } catch (err) {
      // Send error message back to parent
      this.showError([this.ERROR_MESSAGES.save, this.ERROR_MESSAGES.sysAdmin]);
      return Promise.reject(err);
    }
  }

  /**
   * Calls the Liberty Bell API
   * 1. updates a notification
   *
   * @returns Notification Id
   */
  async save(): Promise<string> {
    // if (this.$refs.addForm.disabled) {
    //   return await Promise.resolve(this.modelValue.id);
    // }
    // if (!this.$refs.addForm.validate()) {
    //   return Promise.reject();
    // }
    try {
      // save the alert via API
      await notificationService.setName(this.modelValue.id, {
        name: this.data1.name,
        description: this.data1.description,
        lastStepCompleted: this.modelValue.getLastAvailableStep(WorkflowStepEnum.Begin)
      });
      return Promise.resolve(this.data1.id);
    } catch (err) {
      // Send error message back to parent
      this.showError([this.ERROR_MESSAGES.save, this.ERROR_MESSAGES.sysAdmin]);
      return Promise.reject(err);
    }
  }

  @Watch('$route')
  routeChange() {
    this.$emit('is-loaded', false);
    this._configDefaults();
    this.$emit('is-loaded', true);
  }

  /**
   * Update the data model when the notification is changed
   */
  // @Watch("modelValue")
  // onNotificationChanged(val: Notification, oldVal: Partial<Notification>) {
  //   if (isEqual(val, oldVal)) {
  //     return;
  //   }

  //   this._updateData(val);
  // }

  async onEnableClicked() {
    const res = await notificationService.enable(this.modelValue.id);
    this.$emit('input', res);
  }
  /**
   * Does the logged in user have at minimum a manageNotifications claim of 'Advanced'
   */
  get atleastAdvancedUser(): boolean {
    return this.satisfiesClaim({
      name: 'manageNotifications',
      value: 'Advanced',
    });
  }

  /**
   * Load the form field for rendering the notification type based on the Notification Type manifest
   */
  get notificationType(): NotificationTypeActionItem {
    const f = this.formFields.find((x) => x.type === this.data1.type);
    return f || DEFAULT_NOTIFICATION_TYPE_ACTION;
  }

  get title(): string {
    return this.modelValue.isReadOnly
      ? 'Review Your Alert'
      : this.isEditMode
        ? 'Edit a Custom Alert'
        : 'Create a Custom Alert';
  }

  get nameLabel(): string {
    return !this.isEditMode ? 'Give Your Alert A Name' : 'Alert Name';
  }

  get notificationTypeHeader(): string {
    return this.isEditMode
      ? 'This is a Notification for:'
      : 'Select the type of notification you want to set up/configure?';
  }

  /**
   * Configures values that determine how the page will render and loads the page defaults
   */
  private _configDefaults() {
    const id = this.$route.params['id'];
    if (id !== 'new') {
      this._updateData(this.modelValue);
      this.isEditMode = true;
    } else {
      this.isEditMode = false;
    }
  }

  /**
   * Updates the model that handles page data from a LibertyBell Notification
   */
  private _updateData(n: Notification): void {
    this.data1.name = n.name;
    this.data1.description = n.description;
    this.data1.type = n.type;
    this.data1.isReadOnly = n.isReadOnly;
    this.data1.id = n.id;
    this.data1.disabled = n.disabled;
    this.data1.disabledReason = n.disabledReason;
    this.data1.lastStepCompleted = WorkflowStepEnum[n.lastStepCompleted] ?? WorkflowStepEnum.Begin;
  }

  updateActivity(value) {
    this.data1.type = value;
  }

  get validate() {
    return this.data1.name.trim().length == 0;
  }

  get buttonClass() {
    if (this.data1.type == 'SpecificActivityNotification') {
      return 'individual';
    } else if (this.data1.type == 'SeriesOfActivitiesNotification') {
      return 'multiple';
    } else if (this.data1.type == 'ChangeInActivitiesNotification') {
      return 'changein';
    }

    return '';
  }
}
</script>

<style lang="scss"></style>
