<template>
  <div v-if="!submitted">
    <div class="grid fluid">
      <div class="flex col-5 pb-5">
        <Message :closable="false">
          Almost there!
        </Message>
      </div>
    </div>
    <div class="flex col-12">
      <h2>Now, let's finish up.</h2>
    </div>
    <div class="col-12 description pb-5">
      If you have completed sharing your information and content to get started, 
      simply hit the "Finish and Submit" button to send to your representative. 
      You can always share more content or make adjustments throughout your program.
    </div>
    <cp-readonly-form :data1="data" />
    <div class="flex col-12 justify-content-end">
      <Button label="Finish and Submit" type="button" class="px-4 p-button-success p-button-raised"
        @click="submitForm" />
    </div>
  </div>

  <div v-if="submitted">
    <div class="lg:flex col-12 pb-0 pt-4">
      <div class="col-12 md:col-12 lg:col-4 pb-2">
        <h2>Perfect, Thank you!</h2>
        <br>

        <p class="description">
          We will begin working on your program. Your representative
          will reach out to you soon. In the meantime if you have questions feel free to reach out.
          We look foward to working with you.

          <br><br>
          All set.
        </p>
        <div class="pb-6" />
      </div>

      <div class="col-12 md:col-12 lg:col-8">
        <img alt="finished" :src="finishedPng.img" class="finished">
      </div>
    </div>
  </div>
</template>
<script lang="ts">import axios from 'axios';
import { onMounted, ref, toRefs } from 'vue';
import { OnboardingWorkflowStep } from '@/models/onboarding';
import ReadOnlyForm from '@/components/onboarding/ReadOnlyForm.vue';
import onboardingFormService from '@/services/onboardingFormService';
import { isImageFileType } from '@/utils/fileUtils';
import { ContentItem, FileUrl } from '@/models/onboarding/onboardingFormContent';

export default {
  components: {
    'cp-readonly-form': ReadOnlyForm
  },
  props: {
    formId: {
      type: String,
      default: ''
    },
  },
  emits: ['step-completed', 'complete'],
  setup(props, { emit }) {
    const submitted = ref(false);
    const finishedPng = {
      img: require('../../assets/onboarding/images/onboarding-finished.png')
    };
    let { formId } = toRefs(props);
    let data = ref({});
    const submitForm = async () => {
      try {
        await axios.patch(`/api/onboarding/${formId.value}`);
        submitted.value = true;
        //emit('next-page', { pageIndex: 5 });
        emit('step-completed', OnboardingWorkflowStep.Confirm);
        emit('complete');
      }
      catch (err) {
        console.log(err);
        throw (err);
      }
    };

    onMounted(async () => {
      data.value = await onboardingFormService.getOnboardingForm(formId.value);
      if (data.value['content']) {
        data.value['content'].forEach((content: ContentItem) => {
          content.uploadedFileUrls = content.uploadedFileUrls.map((file: FileUrl) => {
            file.isImage = isImageFileType(file.fileType, file.fileUrl?.split(/\//).pop());
            return file;
          });
        });
      }
    });

    return { data, submitForm, submitted, finishedPng };
  }
};
</script>

<style scoped>
.finished {
  width: 50vw;
  float: right;
}

.finished-text {
  text-align: left;
  font: normal normal normal 16px/24px Overpass;
  letter-spacing: 0px;
  color: #7D8C9F;
  opacity: 1
}
</style>
