
export enum AuditType {
  IndividualActivitiesAudit = 1,
  SeriesOfActivitiesOrgAudit = 2,
  SeriesOfActivitiesUserAudit = 3,
  ChangeInActivitiesOrgAudit = 4,
  ChangeInActivitiesUserAudit = 5
}

export type AuditTypeDiscriminatorName =
  'IndividualActivitiesAudit' |
  'SeriesOfActivitiesOrgAudit' |
  'SeriesOfActivitiesUserAudit' |
  'ChangeInActivitiesOrgAudit' |
  'ChangeInActivitiesUserAudit';

export const AUDIT_TYPE_ICON_MANIFEST: Record<AuditTypeDiscriminatorName, string> = {
  ChangeInActivitiesOrgAudit: 'mdi-chart-gantt',
  ChangeInActivitiesUserAudit: 'mdi-chart-gantt',
  SeriesOfActivitiesOrgAudit: 'mdi-chart-bar',
  SeriesOfActivitiesUserAudit: 'mdi-chart-bar',
  IndividualActivitiesAudit: 'mdi-chart-scatter-plot'
};
