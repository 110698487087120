<template>
  <form class="grid mr-3" @submit.prevent="save">
    <div class="col-12 pb-0">
      <div class="flex">
        <h1 class="line-height-1">
          {{ pageTitle }}
        </h1>
      </div>
    </div>
    <div class="col-12 flex">
      <div class="col-6 md:col-8 p-primary-color user_info">
        <h2>{{ usersName }}</h2>
        {{ config.email }}
      </div>

      <div class="col-6 md:col-4 flex flex-row-reverse">
        <Button :disabled="loading" type="submit" class="p-button-success p-button-raised p-button-rounded ml-3"
          label="Save" />
        <Button :disabled="loading" type="button" class="p-button-outlined p-button-rounded bg-white"
          label="Return to Profile" @click="navigateToProfile()" />
      </div>
    </div>
    <div class="col-12 mb-4">
      <Message :closable="false">
        <b>Activity Reports</b> allow you to configure weekly reports using
        the defined filters and settings.
      </Message>
    </div>
    <div class="col-12 mb-2 lg:mb-0 lg:align-items-stretch">
      <cp-activity-report-config-settings v-model="config" v-model:sendDayData="sendDayData" :submitted="submitted"
        :v="v$.config" @is-individual="reportType" />
    </div>
    <cp-activity-report-config-filters ref="filters" v-model="config.filterModel" :include-indices="!isIndividual"
      :submitted="submitted" :v="v$.config.filters" :is-new="isNew" />
  </form>
</template>

<script lang="ts">
import {
  ActivityReportConfig, ActivityReportType, SEND_DAYS,
} from '@/models/activity-dissemination';
import ActivityReportConfigSettings from '@/components/dissemination-contact/ActivityReportConfigSettings.vue';
import ActivityReportConfigFilters from '@/components/dissemination-contact/ActivityReportConfigFilters.vue';
import { computed, onMounted, Ref, ref } from 'vue';
import useActivityReportConfigSecurity from '@/use/activityReportConfigSecurity';
import useVuelidate from '@vuelidate/core';
import useActivityReportConfigActions from '@/use/activityReportConfigActions';
import { useRoute, useRouter } from 'vue-router';
import { sum } from 'lodash';
import { ProfileMenu } from '@/models/ProfileMenu';

export default {
  name: 'CpActivityReportConfigEdit',
  components: {
    'cp-activity-report-config-settings': ActivityReportConfigSettings,
    'cp-activity-report-config-filters': ActivityReportConfigFilters,
  },
  setup() {
    const selectedConfig: Ref<ActivityReportConfig> = ref(new ActivityReportConfig());
    const filters = ref<any>(null);
    const submitted = ref(false);
    const router = useRouter();
    const route = useRoute();
    const { config, sendDayData, validations } = useActivityReportConfigSecurity(selectedConfig);
    const v$ = useVuelidate(validations, { config });
    const { loading, getData, saveData, getCompanyId, isNew } = useActivityReportConfigActions(config, sendDayData, v$, submitted);
    const isIndividual = ref(false);

    const save = () => {
      updateExternalData();
      saveData();
    };

    const updateExternalData = () => {
      config.value.filterModel.filters = filters.value.filterModel.filters;
      config.value.filterModel.indices = filters.value.filterModel.indices;

      config.value.sendDays = sendDayData.value
        .filter(c => c.selected)
        .map(c => c.name);

      config.value.companyId = getCompanyId(config.value.filterModel);
    };

    const navigateToProfile = () => {
      router.push(`/admin/user/${route.params['userId']}?active=${ProfileMenu.ActivityReports}`);
    };

    const pageTitle = computed(() => isNew.value
      ? 'New Activity Report Configuration'
      : 'Edit Activity Report Configuration');
    const usersName = computed(() => `${config.value.firstName ?? ''} ${config.value.lastName ?? ''}`);
    onMounted(async () => {
      await getData();
      isIndividual.value = config.value.reportTypeEnum === ActivityReportType.Individual;
    });

    const reportType = (value: boolean) => {
      isIndividual.value = value;
    };

    return { config, sendDayData, pageTitle, filters, submitted, loading, usersName, v$, navigateToProfile, save, isNew, reportType, isIndividual };
  }
};
</script>

<style lang="scss" scoped>
.day-checkbox.v-input--checkbox {
  padding-left: 10px;
  margin-top: 0;
}

.day-checkbox.v-input--checkbox:first {
  padding-left: 0;
}

</style>
