
export interface CacheOpts {
    keyProvider: (...args: any[]) => string;
}

const DEFAULT_OPTS: CacheOpts = {
    keyProvider: () => ''
};

const cache: any = {};
export function Cacheable(options: CacheOpts = DEFAULT_OPTS) {
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
        const method = descriptor.value;
        descriptor.value = (...args: any[]) => {
            const cacheKey = `${propertyKey}_${options.keyProvider(...args)}`.replace(/_$/, '');
            cache[cacheKey] = cache[cacheKey] || method.apply(target, args);
            return cache[cacheKey];
        };
        return descriptor;
    };
}
