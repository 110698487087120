export type ActivityFieldCategory = 'General' |
  'Demographics' |
  'FirmographicGeneral' |
  'Contextual' |
  'FirmographicMedicalDevices' |
  'FirmographicLifeSciences' |
  'Conferences';

export enum ActivityFieldCategoryEnum {
  General = 1,
  Demographics,
  Contextual,
  FirmographicGeneral,
  FirmographicLifeSciences,
  FirmographicMedicalDevices,
  Conferences
}

export const categoryLabel: Partial<Record<ActivityFieldCategoryEnum, string>> = {
  [ActivityFieldCategoryEnum.General]: 'General',
  [ActivityFieldCategoryEnum.Demographics]: 'Demographics',
  [ActivityFieldCategoryEnum.FirmographicGeneral]: 'Firmographic (General)',
  [ActivityFieldCategoryEnum.FirmographicLifeSciences]: 'Firmographic (Life Sciences)',
  [ActivityFieldCategoryEnum.FirmographicMedicalDevices]: 'Firmographic (Medical Device)',
  [ActivityFieldCategoryEnum.Contextual]: 'Contextual',
  [ActivityFieldCategoryEnum.Conferences]: 'Webinar/Event'
};

export const categorySortOrder: Partial<Record<ActivityFieldCategoryEnum, number>> = {
    [ActivityFieldCategoryEnum.General]: 1,
    [ActivityFieldCategoryEnum.FirmographicGeneral]: 2,
    [ActivityFieldCategoryEnum.FirmographicLifeSciences]: 3,
    [ActivityFieldCategoryEnum.FirmographicMedicalDevices]: 4,
    [ActivityFieldCategoryEnum.Contextual]: 5,
    [ActivityFieldCategoryEnum.Demographics]: 6,
    [ActivityFieldCategoryEnum.Conferences]: 7
};
