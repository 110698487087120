import { emailValid } from './validators/validators';

export function sanitizeUrlParam(param: string): string {
  if(!param) { return ''; }
  if(emailValid(param)) { param = param.replaceAll('.', '___'); }
  return encodeURIComponent(param);
}

export function revertSanitizedUrlParam(param: string) {
    if (!param) { return ''; }
    return decodeURIComponent(param.replaceAll('___', '.'));
}

export function fullUrl(val: string): string {
    if (!val) {
        return '';
    }

    const httpRegex = new RegExp('http');
    return httpRegex.test(val) ? val : `https://${val}`;
}
