<template>
  <div class="grid">
    <div class="col-12 pb-0 grid">
      <div class="col-9">
        <Message :closable="false">
          <b>Activity Reports</b> allow you to configure weekly reports that are sent to {{ modelValue.email }} using
          the defined filters and settings.
        </Message>
      </div>
      <div class="col-3 flex flex-row-reverse align-items-center">
        <Button label="New User" class="p-button-success p-button-raised" @click="newConfig">
          New Activity Report
          <span class="material-icons pl-2"> person_add_alt_1 </span>
        </Button>
      </div>
    </div>
    <cp-friendly-filter-text v-model="rowParameters" :editable="true" @modal-closed="modalClosed" />
    <div id="activity-report-configs-table" class="col-12 pt-4">
      <DataTable :value="pagedResults.items" data-key="id" :paginator="true" :rows="searchCriteria.pageSize"
        :rows-per-page-options="pageSizeOptions" :lazy="true" :total-records="pagedResults.total"
        paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
        current-page-report-template="Showing {first} to {last} of {totalRecords}" paginator-position="both"
        @page="onPage($event)" @sort="onSort($event)">
        <Column field="runOn" header="Last Sent Date" sortable class="w-5rem">
          <template #body="slotProps">
            {{ format_date(slotProps.data.lastSentOn) }}
          </template>
        </Column>
        <Column field="reportType" header="Report Type" class="w-3rem">
          <template #body="slotProps">
            {{ slotProps.data.iconTooltip }}
          </template>
        </Column>
        <Column header="Client(s)" class="w-10rem">
          <template #body="slotProps">
            <chip v-for="x in slotProps.data.companies.splice(0, 3)" :key="x.id" class="mb-1 ml-1 py-1">
              <div class="flex-1 text-xs p-secondary-color">
                {{ x.name }}
              </div>
            </chip>
            <chip v-if="slotProps.data.companies.length > 3" class="ml-1 py-1">
              <div class="text-xs p-secondary-color">
                {{ "+" + (slotProps.data.companies.length - 3) }} Others
              </div>
            </chip>
            <a title="Show All Parameters" class="ml-1 capitalize text-base p-secondary-color cursor-pointer"
              @click="showParameters(true, slotProps.data)"><span class="material-icons pl-1"> launch </span></a>
          </template>
        </Column>
        <Column header="Communities" class="w-7rem">
          <template #body="slotProps">
            <chip v-for="x in slotProps.data.onlines.splice(0, 3)" :key="x.id" class="mb-1 ml-1 py-1">
              <div class="flex-1 text-xs p-secondary-color">
                {{ x.name }}
              </div>
            </chip>
            <chip v-if="slotProps.data.onlines.length > 3" class="ml-1 py-1">
              <div class="text-xs p-secondary-color">
                {{ "+" + (slotProps.data.onlines.length - 3) }} Others
              </div>
            </chip>
          </template>
        </Column>
        <Column header="Schedule" class="w-3rem">
          <template #body="slotProps">
            <i v-tooltip.bottom="slotProps.data.scheduleTooltip" :class="slotProps.data.scheduleClass">schedule</i>
            <span>{{ sendDaysString(slotProps.data.sendDays) }}</span>
          </template>
        </Column>
        <Column header="Configure" class="w-2rem">
          <template #body="slotProps">
            <router-link :to="slotProps.data.editUrl" class="flex flex-wrap ml-4">
              <img alt="logo" :src="require('../../assets/icons/edit.svg')" style="width: 1.2rem" class="text-xl">
            </router-link>
          </template>
        </Column>
        <Column class="w-1rem">
          <template #body="slotProps">
            <Button class="p-button-text" @click="removeConfigShow($event, slotProps.data)">
              <img :src="require('../../assets/icons/trash.svg')" aria-haspopup="true" aria-controls="overlay_menu"
                width="16" alt="Delete Activity Report Config">
            </Button>
          </template>
        </Column>
        <template #empty>
          <div v-if="!loading" class="flex align-items-center justify-content-center py-5">
            <cp-no-datatable-records />
          </div>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script lang="ts">
import ActivityReportConfigService from '@/services/activityReportConfigService';
import {
  ActivityReportConfig,
  toFriendlyTextModel,
  ActivityReportConfigRow,
  sendDaysString
} from '@/models/activity-dissemination';
import { DEFAULTS, SearchCriteria } from '@/services/searchCriteria';

import commonLookupService from '@/services/commonLookupService';
import { Online } from '@/models/lookup';
import Tooltip from 'primevue/tooltip';
import { onSuccess, onError } from '@/utils/toast/toastUtils';
import { PagedList } from '@/models/pagedList';

import NoDataTableRecordsComponent from '@/components/common/NoDataTableRecordsComponent.vue';
import FriendlyFilterText from '@/components/notifications/run-details/FriendlyFilterText.vue';
import { onMounted, PropType, ref, toRef } from 'vue';
import { AppUser } from '@/models/appUser';
import useUserSecurity from '@/use/userSecurity';
import { useRouter } from 'vue-router';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { FriendlyTextModel } from '@/models/common/friendlyTextModel';
import dayjs from 'dayjs';
import { SortOrder } from '@/models/sortOrder';

export default {
  name: 'CpManageUserActivityReports',
  components: {
    // 'cp-companies-search-field': CompanySearchField,
    // 'cp-alert-dialog': AlertDialog,
    'cp-no-datatable-records': NoDataTableRecordsComponent,
    'cp-friendly-filter-text': FriendlyFilterText
  },
  directives: {
    tooltip: Tooltip
  },
  props: {
    modelValue: { type: Object as PropType<AppUser>, required: true }
  },
  setup(props) {
    const submitted = ref(false);

    const confirm = useConfirm();
    const router = useRouter();
    const toast = useToast();

    const { user } = useUserSecurity(toRef(props, 'modelValue'));
    const pagedResults = ref<PagedList<ActivityReportConfigRow>>({ items: [], total: 0, totalPages: 0 });
    const searchCriteria = ref<SearchCriteria>({ page: 1, pageSize: DEFAULTS.PAGE_SIZE });
    const rowParameters = ref<FriendlyTextModel>({ filters: [], activityTypes: [], id: '', idType: 'ActivityReport', show: false });
    const onlines = ref<Online[]>([]);
    const loading = ref(false);

    const newConfig = () => {
      router.push(`/dissemination-contact/${user.value.id}`);
    };

    const onPage = (event) => {
      searchCriteria.value.page = event.page + 1;
      searchCriteria.value.pageSize = event.rows;
      search();
    };
    const removeConfigShow = (event: any, configRow: ActivityReportConfigRow) => {
      confirm.close();
      confirm.require({
        target: event.currentTarget,
        message: 'Are you sure you want to delete this activity report?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Continue',
        rejectLabel: 'Cancel',
        accept: () => {
          _removeConfig(configRow);
        },
        reject: () => {
          //callback to execute when user rejects the action
        }
      });
    };
    const search = async () => {
      submitted.value = true;
      loading.value = true;
      pagedResults.value = await ActivityReportConfigService.getAllForUser(props.modelValue.id as string, searchCriteria.value);
      loading.value = false;
    };

    const onSort = (event) => {
      if (event.sortField) {
        searchCriteria.value.sortBy = event.sortField;
      }
      if (event.sortField) {
        searchCriteria.value.sortDir =
          event.sortOrder == 1 ? SortOrder.Descending : SortOrder.Ascending;
      }
      search();
    };

    const _removeConfig = async (row: ActivityReportConfigRow) => {
      try {
        await ActivityReportConfigService.delete(<string>row.id);
        onSuccess(toast, `Deleted report ${row.companies ?? row.onlines}`);
        await search();
      } catch (error: any) {
        onError(toast, `There was a error on deleting the report ${row.companies ?? row.onlines}.`);
      }
    };
    const showParameters = (value: boolean, config: ActivityReportConfig) => {
      const friendlyText = toFriendlyTextModel(config);
      showParametersFriendlyText(value, friendlyText);
    };

    const showParametersFriendlyText = (value: boolean, friendlyText: FriendlyTextModel) => {
      rowParameters.value = friendlyText;
      rowParameters.value.show = value;
    };

    const modalClosed = (value: boolean) => {
      showParametersFriendlyText(value, rowParameters.value);
    };

    const format_date = (value: string) => {
      if (value) {
        return dayjs(String(value)).format('MMM D, YYYY');
      }
    };

    onMounted(async () => {
      onlines.value = await commonLookupService.getOnlines();
      search();
    });

    return {
      loading,
      pagedResults,
      pageSizeOptions: DEFAULTS.PAGE_SIZE_OPTIONS,
      rowParameters,
      searchCriteria,
      format_date,
      newConfig,
      onPage,
      onSort,
      removeConfigShow,
      showParameters,
      modalClosed,
      sendDaysString
    };
  }
};
</script>

<style lang="scss" scoped>
.email {
  padding-left: 1px;
}

.v-icon {
  padding-right: 5px !important;
}
</style>
