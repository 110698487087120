import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64c93947"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-11" }
const _hoisted_2 = { class: "col p-fluid" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.searchField.model, (_, i) => {
    return (_openBlock(), _createElementBlock("div", {
      key: i,
      class: _normalizeClass($setup.getClass(i))
    }, [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(), _createBlock(_resolveDynamicComponent($props.searchField.type), _mergeProps($props.searchField.props, {
          modelValue: $setup.searchFieldRef.model[i],
          "onUpdate:modelValue": ($event: any) => (($setup.searchFieldRef.model[i]) = $event),
          submitted: $props.submitted,
          "org-selected": $props.orgSelected,
          reset: $props.reset,
          "field-name": $props.searchField.name,
          "is-user-profile": $props.isUserProfile
        }), null, 16, ["modelValue", "onUpdate:modelValue", "submitted", "org-selected", "reset", "field-name", "is-user-profile"]))
      ]),
      _createElementVNode("div", _hoisted_2, [
        ($setup.canAddElements($props.searchField.model, i))
          ? (_openBlock(), _createElementBlock("i", {
              key: 0,
              class: "pi pi-plus-circle px-2 compound-filter-action",
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.addElement($props.searchField.model)))
            }))
          : _createCommentVNode("", true),
        (i > 0)
          ? (_openBlock(), _createElementBlock("i", {
              key: 1,
              class: "pi pi-minus-circle px-2 compound-filter-action",
              onClick: ($event: any) => ($setup.removeElement($props.searchField.model, i))
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true)
      ])
    ], 2))
  }), 128))
}