import { debounce } from 'lodash';
import { createDecorator } from 'vue-class-component';

export function Debounce(milliseconds: number) {

  return createDecorator((opts, methodName) => {

    if (!opts.methods) {
      throw new Error('This decorator must be used on a vue component method.');
    }

    const originalFn = opts.methods[methodName];
    opts.methods[methodName] = debounce(originalFn, milliseconds);
  });
}
