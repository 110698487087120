<template>
  <div class="grid">
    <div class="col-12 pb-0">
      <div class="flex">
        <h1 class="line-height-1">
          Performance Overview
        </h1>
      </div>
      <span class="p-secondary-color text-xs">{{ dateRange }}</span>
    </div>
    <div class="col-12 lg:col-4 lg:pr-4">
      <cp-engagements-stat-card :filter-model="filterModel" stat="engagements" />
    </div>
    <div class="col-12 lg:col-4 lg:px-2">
      <cp-engagements-stat-card :filter-model="filterModel" stat="users" />
    </div>
    <div class="col-12 lg:col-4 lg:pl-4">
      <cp-engagements-stat-card :filter-model="filterModel" stat="docs" />
    </div>

    <div class="col-12 lg:pl-0">
      <cp-engagements-by-month :filter-model="filterModel" />
    </div>

    <div class="col-12 lg:col-6">
      <cp-engagements-by-field :filter-model="filterModel" field="DocumentTypeName"
        title="Engagements By Document Type" />
    </div>
    <div class="col-12 lg:col-6">
      <cp-engagements-by-field :filter-model="filterModel" field="BuyersJourneyStage"
        title="Engagements By Buyer's Journey" />
    </div>
    <div class="col-12 lg:pl-0">
      <cp-content-by-buyers-journey :filter-model="filterModel" series-field="buyersJourney"
        title="Content By Buyer's Journey" />
    </div>
    <div class="col-12 lg:pl-0">
      <cp-engagements-by-source :filter-model="filterModel" />
    </div>

    <div class="col-12 lg:col-6">
      <cp-engagements-by-field :filter-model="filterModel" field="Onlines" title="Engagements By Community" />
    </div>
    <div class="col-12 lg:col-6">
      <cp-engagements-by-field :filter-model="filterModel" field="Region" title="Engagements By Region" />
    </div>


    <div class="col-12 lg:pl-0">
      <cp-most-popular-docs :filter-model="filterModel" series-field="contentType" title="Content Origin" />
    </div>
    <Suspense>
      <cp-dashboard-filter v-model="filterModel" :show="showFilter" :include-indices="true"
        @filter-submitted="filterSubmitted" @close-panel="closePanel" />
    </Suspense>

    <div class="filter-menu-wrapper">
      <a class="filter-menu" @click.stop="showFilter = !showFilter">
        <img :src="require('../../assets/icons/dashboard-filters.svg')" alt="Filter Framework" width="52">
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { mixins } from 'vue-class-component';
import EngagementsByMonth from '@/components/client-dashboard/EngagementsByMonth.vue';
// import DashboardFilter from "@/components/client-dashboard/DashboardFilter.vue";
import EngagmentsByField from '@/components/client-dashboard/EngagementsByField.vue';
import EngagmentsBySource from '@/components/client-dashboard/EngagementsBySource.vue';
import MostPopularDocs from '@/components/client-dashboard/MostPopularDocs.vue';
import EngagementsStatCard from '@/components/client-dashboard/EngagementsStatCard.vue';
import dayjs from 'dayjs';
import { filterValueExists, dateRangeText } from '@/utils/filters';
import { Filter } from '@/models/filters';
import { FilterableDashboard } from '@/models/analytics/filterableDashboard.interface';
import { FilterModel } from '@/models/search';
import { cloneDeep } from 'lodash';
import { DefaultSearchMixin } from '@/mixins/defaultSearch.mixin';
import { Options } from 'vue-class-component';
import NoChartDataComponent from '@/components/common/NoChartDataComponent.vue';
import emitter from '@/services/emitter';
import ContentByBuyersJourney from '@/components/client-dashboard/ContentByBuyersJourney.vue';
import { defineAsyncComponent } from 'vue';
import { Watch } from 'vue-property-decorator';

const DashboardFilter = defineAsyncComponent(() => import('@/components/client-dashboard/DashboardFilter.vue'));

@Options({
  components: {
    'cp-dashboard-filter': DashboardFilter,
    'cp-engagements-by-month': EngagementsByMonth,
    'cp-engagements-by-source': EngagmentsBySource,
    'cp-engagements-by-field': EngagmentsByField,
    'cp-most-popular-docs': MostPopularDocs,
    'cp-no-data': NoChartDataComponent,
    'cp-content-by-buyers-journey': ContentByBuyersJourney,
    'cp-engagements-stat-card': EngagementsStatCard,
  },
})
export default class ClientDashboard
  extends mixins(DefaultSearchMixin)
  implements FilterableDashboard {
  filterModel: FilterModel = { filters: [], indices: [] };
  declare initialState: FilterModel;
  hasNoData: boolean = false;
  filters: Filter[] = [];

  showFilter = false;
  currentDate = dayjs().format('MM/DD/YYYY');
  activeStates = {
    row1: false,
    row2: false,
    row3: false,
    row4: false,
    row5: false,
    row6: false,
  };

  async mounted() {
    emitter.on('nodata', ($event) => {
      this.hasNoData = ($event as boolean) || false;
    });
    // await this.defaultsLoaded;
    // this.includeIndices = this.fields.every(f=>f.field != 'ActivityTypes');

    // this.filterModel = cloneDeep(this.initialState);
    // if (!filterValueExists(this.filterModel.filters, 'Clients')) {
    //   this.showFilter = true;
    // }
    // // console.debug(`ClientDashboard.mounted() - { filterModel, searchDefaults, initialState, currentState }`, this.filterModel, this.searchDefaults, this.initialState, this.currentState);
  }

  @Watch('defaultsLoaded', { immediate: true })
  defaultsLoadedDone(val: boolean) {
    if (val) {
      this.filterModel = cloneDeep(this.initialState);
      if (!filterValueExists(this.filterModel.filters, 'Clients')) {
        this.showFilter = true;
      }
    }
  }

  filterSubmitted(filterModel: FilterModel): void {
    this.filterModel = cloneDeep(filterModel);
    // console.debug(`ClientDashboard.filterSubmitted() - { filterModel }`, this.filterModel);
  }

  get dateRange() {
    return dateRangeText(this.filterModel.filters);
  }

  closePanel() {
    this.showFilter = false;
  }
}
</script>

<style lang="scss" scoped>
.v-btn.btn-slider {
  z-index: 100;
  top: 140px !important;
  right: 0;
  height: 60px !important;
}

.inline {
  display: inline;
}

.p-card.no-results-card {
  color: #c1c8d1;
  background: white !important;
  box-shadow: 0px 4px #cfdaea;
  max-width: 400px;
  border-width: thin;
  border-style: solid;
}
</style>
