import axios from 'axios';
import { FilterModel } from '@/models/search';
import { MostActiveCosEngagements } from '@/models/analytics/mostActiveCosEngagements';
import { MostActiveCosStats } from '@/models/analytics/mostActiveCosStats';
import { mostActiveCosByTypeResult } from '@/models/analytics/mostActiveCosByCompanyTypeResult';
import { filterModelParams, toActivityTypes } from '@/utils/filters/filterUtils';

const BASE_URL = '/api/most-active-cos';

export default {
    async getStats(filterModel: FilterModel): Promise<MostActiveCosStats> {
        const at = toActivityTypes(filterModel);
        const stats = (await axios.post(`${BASE_URL}/summary`, { ...filterModelParams(filterModel), ...{ activityTypes: at } })).data;
        const statsObj = new MostActiveCosStats(stats);        
        return statsObj;        
    },

    async getReaderOrgEngagements(filterModel: FilterModel): Promise<MostActiveCosEngagements[]> {
        const at = toActivityTypes(filterModel);
        const readerOrgs = (await axios.post(`${BASE_URL}`, { ...filterModelParams(filterModel), ...{ activityTypes: at } })).data;
        return readerOrgs.map(x => new MostActiveCosEngagements(x));
    },

    async getMostActiveCosByOrgType(filterModel: FilterModel): Promise<mostActiveCosByTypeResult> {
        const at = toActivityTypes(filterModel);
        const mostActiveCosByType = (await axios.post(`${BASE_URL}/orgtypechartdata`, { ...filterModelParams(filterModel), ...{ activityTypes: at } }));
        return mostActiveCosByType?.data;
    }
};