import { RelativeDateRangeFilter, DateRangeFilter, RelativeDateRange, TermsFilter, DEFAULT_ACTIVITY_TYPE_FILTER } from '@/models/filters';
import { SearchFieldMetaData } from '.';
import OnlinesSearchField from '../onlinesSearchField.vue';
import CompaniesSearchField from '../companiesSearchField.vue';
import RelativeDateRangeSearchField from '../relativeDateRangeSearchField.vue';
import DateRangeSearchField from '../dateRangeSearchField.vue';

export const GENERAL_FIELD_METADATA: Array<SearchFieldMetaData | SearchFieldMetaData[]> = [
    {
        model: DEFAULT_ACTIVITY_TYPE_FILTER
    },
    { type: RelativeDateRangeSearchField, model: new RelativeDateRangeFilter({ field: 'RelativeDateRange' }) },
    {
        type: DateRangeSearchField, model: new DateRangeFilter({ field: 'DateRange' }),
        displayDependency: {
            field: 'RelativeDateRange',
            values: [RelativeDateRange.Custom]
        }
    },
    { type: OnlinesSearchField, model: new TermsFilter({ field: 'Onlines' }) },
    { type: CompaniesSearchField, model: new TermsFilter({ field: 'Clients' }), props: { requiredFields: ['Clients'] } }
];
