import { computed, Ref, ref } from 'vue';
import { FieldCode } from '@/models/filters';
import { GuidLookup } from '@/models/lookup';
import FilterLookupsSvc from '@/services/filterLookupsService';


export default function useCmsSecurity(filterName: Ref<FieldCode>, loadOptionsOnce: Ref<boolean>, optionsLoaded: Ref<boolean>, selectedTermValues: Ref<GuidLookup[]>) {
  const data = ref<GuidLookup[]>([]);
  const loading = ref(false);
  const error = ref<any>(null);
  let optionsGetter: (...args: any) => Promise<GuidLookup[]>;

  const cmsFields: FieldCode[] = ['AnalystTags'];
  const isCmsField = computed(() => {
    const isValid = cmsFields.includes(filterName.value);
    if (isValid) {
      optionsGetter = q => FilterLookupsSvc.getCmsTags(filterName.value, q);
    }
    return isValid;
  });


  const getData = async (q: string) => {
    loading.value = true;
    data.value = [];
    error.value = null;

    if (!isCmsField.value) {
      loading.value = false;
      return;
    }

    try {
      let options: GuidLookup[] = [];
      if (loadOptionsOnce.value && !optionsLoaded.value) {
        options = await optionsGetter();
        data.value = options;
        optionsLoaded.value = true;
        return;
      }

      if (q?.length > 0 && optionsGetter) {
        options = await optionsGetter(q);
      }

      // Exclude selected results from result set
      data.value = options.filter(o =>
        !selectedTermValues.value.flatMap(l => l.id).includes(o.id)
      );

    } catch (err) {
      error.value = err;
    }

    loading.value = false;
  };

  return { data, loading, error, getData };
}