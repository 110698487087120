import { BoolFilter } from './boolFilter';
import { DateRangeFilter } from './dateRangeFilter';
import { Filter } from './filter';
import { FilterType } from './filterType';
import { MediaFilter } from './mediaFilter';
import { TermsFilter } from './termsFilter';
import { TextFilter } from './textFilter';
import { NumericFilter } from './numericFilter';
import { RelativeDateRangeFilter } from './relativeDateRangeFilter';
import { ExistsFilter } from './existsFilter';

type FilterConstructor = new (args: any) => Filter;

const FILTER_STORE: Record<string, FilterConstructor> = {
  BoolFilter,
  DateRangeFilter,
  TermsFilter,
  TextFilter,
  MediaFilter,
  NumericFilter,
  RelativeDateRangeFilter,
  ExistsFilter  
};

export function createFilter(type: FilterType, opts: any): Filter {
  return new FILTER_STORE[type](opts) as Filter;
}

export interface FriendlyFilterRow {
  filterName: string;
  operation: string;
  value: string;
}
