<template>
  <Sidebar :visible="show" position="full" :show-close-icon="false">
    <ScrollPanel id="customScroll" style="width: 100%; height: 100%">
      <form class="mb-8" @submit.prevent="emitFilterChange">
        <div class="flex px-0 mb-3 py-3 sticky-header align-items-center">
          <div class="col-6">
            <h2 class="text-2xl text-white pl-5">
              Dashboard Filters
            </h2>
          </div>
          <div class="col-6 flex flex-row-reverse align-items-center">
            <span class="ml-6 cursor-pointer flex align-items-center" @click="closePanel">
              <span class="material-icons text-white text-5xl mr-4"> close </span></span>
            <Button type="submit" class="p-button-success p-button-rounded p-button-raised ml-5 px-4"
              label="See results" />
            <Button type="button" class="p-button-outlined p-button-rounded p-button-raised bg-white"
              @click="resetFilters()">
              <span class="p-button-label p-primary-color">Reset</span>
            </Button>
          </div>
        </div>
        <div class="grid pb-4 mt-4">
          <div class="col-10 col-offset-1">
            <Card class="card-active px-3 pt-2 pb-0">
              <template #title>
                <div class="px-2">
                  Select a Saved Search
                </div>
              </template>
              <template #content>
                <div class="grid px-2 pb-2">
                  <div class="col-6 p-fluid pb-3">
                    <span class="p-float-label">
                      <Dropdown id="savedSearch" v-model="selectedSavedSearch" :options="savedSearches"
                        option-label="name" option-value="id" placeholder="Select a Saved Search"
                        @change="onSavedSearchChange" />
                      <label for="savedSearch">Saved Search</label>
                    </span>
                  </div>
                  <div class="col-6 p-fluid pb-3 pt-3">
                    <a target="_blank" class="saved-search-link cursor-pointer" @click="navigateToSavedSearches()">
                      Manage Saved Searches
                    </a>
                  </div>
                </div>
              </template>
            </Card>
          </div>
        </div>
        <div ref="general" class="grid pb-4">
          <div class="col-10 col-offset-1">
            <Card class="card-active px-3 pt-2 pb-0">
              <template #title>
                <div class="px-2">
                  General
                </div>
              </template>
              <template #content>
                <div class="grid px-2 pb-2">
                  <div v-if="includeIndices" class="col-12 p-fluid pb-3">
                    <span class="p-float-label">
                      <AutoComplete id="activityType" v-model="selectedIndices" :multiple="true"
                        :suggestions="filteredIndices" field="name" :dropdown="true" :complete-on-focus="true"
                        @complete="searchActivityTypes($event)" @focus="searchActivityTypes($event)" />
                      <label for="activityType">Activity Type</label>
                    </span>
                  </div>
                  <div v-for="row in generalFields" :key="row.items[0].props?.label" class="col-12 p-fluid pb-3">
                    <div v-for="f in displayableItems(row.items)" :key="f.props?.label">
                      <cp-compound-search-field-elements v-if="f.props?.isCompound" :search-field="f"
                        :submitted="submitted" />
                      <component :is="f.type" v-if="!f.props?.isCompound" v-bind="f.props" v-model="f.model"
                        :submitted="submitted" />
                    </div>
                  </div>
                </div>
              </template>
            </Card>
          </div>
        </div>
        <div ref="demographic" class="grid pb-4">
          <div class="col-10 col-offset-1">
            <Card class="card-active px-3 pt-2 pb-0">
              <template #title>
                <div class="px-2">
                  Demographic
                </div>
              </template>
              <template #content>
                <div class="grid px-2 pb-2">
                  <div v-for="(row, i) in demographicFields" :key="row.items[0].props?.label"
                    :class="getClass('main', row.items.length, i, row.items.some(x => x.props?.isCompound))">
                    <div v-for="(f, j) in displayableItems(row.items)" :key="f.props?.label"
                      :class="getClass('child', row.items.length, j)">
                      <cp-compound-search-field-elements v-if="f.props?.isCompound" :search-field="f"
                        :submitted="submitted" />
                      <component :is="f.type" v-if="!f.props?.isCompound" v-bind="f.props" v-model="f.model"
                        :submitted="submitted" />
                    </div>
                  </div>
                </div>
              </template>
            </Card>
          </div>
        </div>

        <div ref="firmographic" class="grid pb-4">
          <div class="col-10 col-offset-1">
            <Card class="card-active px-3 pt-2 pb-0">
              <template #title>
                <div class="px-2">
                  Firmographic
                </div>
              </template>
              <template #content>
                <cp-reader-org-search-field v-if="isReaderLookUpExists" ref="childRef"
                  @filter-changed="orgFilterChanged" @reset-filter="resetFilters" />
                <div class="grid px-2 pb-2">
                  <div v-for="(row, i) in allFirmographicFields" :key="row.items[0].props?.label"
                    :class="getClass('main', row.items.length, i, row.items.some(x => x.props?.isCompound))">
                    <div v-for="(f, j) in displayableItems(row.items)" :key="f.props?.label"
                      :class="getClass('child', row.items.length, j)">
                      <keep-alive>
                        <div>
                          <cp-compound-search-field-elements :is="f.type" v-if="f.props?.isCompound" v-bind="f.props"
                            :search-field="f" :submitted="submitted" :org-selected="orgSelected" :reset="reset" />
                          <component :is="f.type" v-if="!f.props?.isCompound" v-bind="f.props" v-model="f.model"
                            :submitted="submitted" :org-selected="orgSelected" :reset="reset"
                            :field-name="f.props?.label" />
                        </div>
                      </keep-alive>
                    </div>
                  </div>
                </div>
              </template>
            </Card>
          </div>
        </div>

        <div ref="contextual" class="grid pb-4">
          <div class="col-10 col-offset-1">
            <Card class="card-active px-3 pt-2 pb-0">
              <template #title>
                <div class="px-2">
                  Contextual
                </div>
              </template>
              <template #content>
                <div class="grid px-2 pb-2">
                  <div v-for="(row, i) in contextualFields" :key="row.items[0].props?.label"
                    :class="getClass('main', row.items.length, i, row.items.some(x => x.props?.isCompound))">
                    <div v-for="(f, j) in displayableItems(row.items)" :key="f.props?.label"
                      :class="getClass('child', row.items.length, j)">
                      <cp-compound-search-field-elements v-if="f.props?.isCompound" :search-field="f"
                        :submitted="submitted" />
                      <component :is="f.type" v-if="!f.props?.isCompound" v-bind="f.props" v-model="f.model"
                        :submitted="submitted" />
                    </div>
                  </div>
                </div>
              </template>
            </Card>
          </div>
        </div>
      </form>
    </ScrollPanel>
  </Sidebar>
</template>

<script lang="ts">
import { ref, toRef, PropType, watch, onBeforeUpdate, computed } from 'vue';
import useVuelidate from '@vuelidate/core';
import { FILTER_COMPONENTS } from '@/components/searchFields';
import { FilterModel } from '@/models/search';
import { Lookup } from '@/models/lookup';
import {
  FilterProp,
  findFilter
} from '@/utils/filters';
import useSearchFilterSecurity from '@/use/searchFilterSecurity';
import useAuthenticatedUser from '@/use/authenticatedUser';
import useSearchFilterActions from '@/use/searchFilterActions';
import useSearchLookups from '@/use/searchLookups';
import { TermsFilter } from '@/models/filters';
import useActivityFields from '@/use/activityFields';
import { PagedList } from '@/models/pagedList';
import { savedSearchRow } from '@/models/saved-search/savedSearchModel';
import savedSearchesService from '@/services/savedSearchesService';
import { SearchCriteria } from '@/services/searchCriteria';
import DefaultFiltersService from '@/services/defaultFiltersService';
import { SortOrder } from '@/models/sortOrder';
import router from '@/router';

export default {
  name: 'CpDashboardFilter',
  components: {
    ...FILTER_COMPONENTS
  },
  props: {
    modelValue: {
      type: Object as PropType<FilterModel>,
      default: () => <FilterModel>{ filters: [], indices: [], config: {} }
    },
    includeIndices: { type: Boolean, default: false },
    props: { type: Array as PropType<FilterProp[]>, default: () => [] },
    show: { type: Boolean, default: false }
  },
  emits: ['filter-submitted', 'input', 'close-panel'],
  setup(props, { emit }) {
    const selectedIndices = ref<Array<Lookup<string>>>([]);
    const filteredIndices = ref<Array<Lookup<string>>>([]);
    const submitted = ref(false);
    const reset = ref(false);
    const { authenticatedUser, userActivitySearchFields } = useAuthenticatedUser();
    const { onlineBgMap, orgQuestionsMap } = useSearchLookups();
    const childRef = ref();
    const components = ref([]);

    const orgSelected = ref(false);

    const { basicSearchableActivityFields } = useActivityFields();
    const basicFields = computed(() => basicSearchableActivityFields.value.map(x => x.field));
    const fields = computed(() => [...userActivitySearchFields.value, ...basicFields.value].filter(x => x !== 'ActivityTypes'));
    const isReaderLookUpExists = computed(() => fields.value.includes('ReaderOrgsLookup'));

    const pagedResults = ref<PagedList<savedSearchRow>>({ items: [], total: 0, totalPages: 0 });
    const searchCriteria = ref<SearchCriteria>({
      page: 1,
      pageSize: 100,
      sortBy: 'name',
      sortDir: SortOrder.Ascending,
    });
    const savedSearches = ref<Array<Lookup<string>>>([]);
    const selectedSavedSearch = ref<string | undefined>(props.modelValue.savedSearchId);

    const loadSavedSearchDropdown = async () => {
      pagedResults.value = await savedSearchesService.getAllForUser(authenticatedUser.value?.userId, searchCriteria);

      savedSearches.value = pagedResults.value?.items?.map(x => <Lookup<string>>{
        id: x.id,
        name: x.name
      });

      selectedSavedSearch.value = props.modelValue.savedSearchId;
    };

    loadSavedSearchDropdown();

    const {
      generalFields,
      demographicFields,
      contextualFields,
      allFirmographicFields,
      validations,
      getSearchFieldRowClass,
      updateData,
      filterModel,
      displayableItems,
      displayableFilters
    } = useSearchFilterSecurity(selectedIndices, toRef(props, 'props'), fields, orgQuestionsMap, onlineBgMap, selectedSavedSearch);

    const v$ = useVuelidate(validations, { filterModel, name: '' });
    const {
      searchFilters,
      searchActivityTypes,
      resetFilter
    } = useSearchFilterActions(filterModel, v$, selectedIndices, filteredIndices, authenticatedUser, updateData, submitted);

    onBeforeUpdate(() => {
      components.value = [];
    });

    const onSavedSearchChange = async (selectedSearch) => {
      const model = ref(filterModel.value);
      model.value = await DefaultFiltersService.getBySavedSearchId(authenticatedUser.value?.userId, selectedSearch.value);
      updateData(model.value);
    };

    const onFilterModelChanged = (filters: FilterModel) => {
      updateData(filters);
    };

    const onShowChanged = (show: boolean) => {
      const html = document.getElementsByTagName('html')[0];
      if (show) {
        html.classList.add('overflow-hidden');
      } else {
        html.classList.remove('overflow-hidden');
      }
    };

    const emitFilterChange = async () => {
      const response = await searchFilters();
      if (!response) {
        return;
      }

      const submittedFilterModel = { ...filterModel.value, filters: displayableFilters.value };

      emit('filter-submitted', submittedFilterModel);
      emit('input', false);
      emit('close-panel', false);
    };

    const resetFilters = () => {
      resetFilter(props.modelValue?.config);
      reset.value = true;
      childRef.value?.clearOrgs();
      emit('input', false);
    };

    const navigateToSavedSearches = () => {
      closePanel();
      router.push(`/admin/user/${authenticatedUser.value.userId}?active=1`);
    };

    const orgFilterChanged = (model: Array<Lookup<any>>) => {
      reset.value = false;
      let readerOrgFilter: TermsFilter = findFilter<TermsFilter>(filterModel.value.filters, 'ReaderOrgs');

      if (!readerOrgFilter) {

        readerOrgFilter = new TermsFilter({ field: 'ReaderOrgs', value: model });
        filterModel.value.filters.push(readerOrgFilter);
      }
      readerOrgFilter.value = model;
      orgSelected.value = true;
      onFilterModelChanged(filterModel.value);
    };

    const closePanel = async () => {
      emit('close-panel', false);
    };

    watch(props, (newProps) => {
      onFilterModelChanged(newProps.modelValue);
      onShowChanged(newProps.show);
    }, { deep: true, immediate: true });

    return {
      generalFields,
      demographicFields,
      contextualFields,
      allFirmographicFields,
      components,
      reset,
      orgFilterChanged,
      filterModel,
      onFilterModelChanged,
      getClass: getSearchFieldRowClass,
      searchActivityTypes,
      selectedIndices,
      filteredIndices,
      emitFilterChange,
      resetFilters,
      submitted,
      closePanel,
      v$,
      childRef,
      orgSelected,
      displayableItems,
      isReaderLookUpExists,
      savedSearches,
      selectedSavedSearch,
      onSavedSearchChange,
      navigateToSavedSearches
    };
  }
};
</script>

<style scoped lang="scss">
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #1b3a63;
}

.p-card.p-component::after {
  display: block;
  content: "";
  height: 4px;
  width: 81%;
  margin: 0 auto;
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 14px;
  background: #223b5c;
  position: absolute;
  margin-top: -2px;
}
</style>@/services/savedSearchesService@/models/saved-search/savedSearchesModel@/models/saved-search/savedSearchModel