import { BasicOnboardingFormData, OnboardingWorkflowStep } from '@/models/onboarding/basicOnboardingFormData';
import { StepDataItem } from '../steppers/stepDataItem';
import OnboardingIntroStep from '@/components/onboarding/OnboardingIntroStep.vue';
import GeneralInfoStep from '@/components/onboarding/GeneralInfoStep.vue';
import CompanyContentStep from '@/components/onboarding/CompanyContentStep.vue';
import CompanyInfoStep from '@/components/onboarding/CompanyInfoStep.vue';
import CompanyContactsStep from '@/components/onboarding/CompanyContactsStep.vue';
import OnboardingConfirmStep from '@/components/onboarding/OnboardingConfirmStep.vue';


export interface OnboardingStepperItems {
    items?: StepDataItem<BasicOnboardingFormData>[];
}
export const INTRO_STEP_ONLY_ITEMS: OnboardingStepperItems = {

    items: [
        { step: 1, text: 'Begin', stepCompleted: form => form.isSubmitted, componentDef: OnboardingIntroStep, isActive: () => true }
    ]
};



export const ONBOARDING_STEPPER_METADATA: OnboardingStepperItems = {
    items: [
        {
            step: 0,
            text: 'Begin',
            name: 'intro',
            stepCompleted: form => form.lastStepCompleted > OnboardingWorkflowStep.Begin,
            componentDef: OnboardingIntroStep,
            isActive: form => form.userId !== undefined
        },
        {
            step: 1,
            text: 'Basic Info',
            name: 'generalInfo',
            stepCompleted: form => form.lastStepCompleted > OnboardingWorkflowStep.General,
            componentDef: GeneralInfoStep,
            isActive: form => form.userId !== undefined
        },
        {
            step: 2,
            text: 'Company Info',
            name: 'companyInfo',
            stepCompleted: form => form.lastStepCompleted > OnboardingWorkflowStep.Company,
            componentDef: CompanyInfoStep,
            isActive: form => form.userId !== undefined
        },
        {
            step: 3,
            text: 'Contacts',
            name: 'contacts',
            stepCompleted: form => form.lastStepCompleted > OnboardingWorkflowStep.Contacts,
            componentDef: CompanyContactsStep,
            isActive: form => form.userId !== undefined
        },
        {
            step: 4,
            text: 'Content',
            name: 'content',
            stepCompleted: form => form.lastStepCompleted > OnboardingWorkflowStep.Content,
            componentDef: CompanyContentStep,
            isActive: form => form.userId !== undefined
        },
        {
            step: 5,
            text: 'Confirm',
            name: 'confirm',
            stepCompleted: form => form.isSubmitted,
            componentDef: OnboardingConfirmStep,
            isActive: form => form.userId !== undefined
        }
    ]
};
export const ONBOARDING_STEPPER_ITEMS: OnboardingStepperItems[] = [
    ONBOARDING_STEPPER_METADATA
];


