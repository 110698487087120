<template>
  <div v-if="labelDisplayMode === 'columns'" class="grid p-0 my-2">
    <div class="col-12 lg:col-2 p-secondary-color">
      Clients
    </div>
    <div class="col-12 lg:col-10">
      <div class="flex p-fluid">
        <div class="col-12 p-0">
          <AutoComplete id="client" :model-value="selectedCompanies" :suggestions="filteredClients" field="name"
            :multiple="true" :class="{ 'p-invalid': v$.$invalid && submitted }" @complete="search($event.query)"
            @item-select="updateValue($event.value)" @item-unselect="removeValue($event.value)" />
          <small v-if="(v$.required.$invalid && submitted) || v$.$pending.$response" class="p-error">
            {{ v$.required.$message.replace('The value', 'Clients') }}</small>
          <small v-if="(v$.maxLength.$invalid && submitted) || v$.$pending.$response" class="p-error">
            {{ v$.maxLength.$message.replace('allowed is', 'allowed for Clients is') }}</small>
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-2 p-secondary-color">
      Communities
    </div>
    <div class="col-12 lg:col-10">
      <div class="flex p-fluid">
        <div class="col-12 p-0">
          <AutoComplete id="online" :model-value="selectedCommunities" :suggestions="filteredCommunities" field="name"
            :dropdown="true" :multiple="true" :complete-on-focus="true" :disabled="selectedCompanies?.length == 0"
            @complete="searchCommunities($event.query)" @item-select="updateCommunityField($event.value, 'online')"
            @item-unselect="deleteCommunityField($event.value)" />
        </div>
      </div>
    </div>
  </div>
  <div v-if="labelDisplayMode === 'floating'" class="grid p-0 my-2">
    <div class="col-12">
      <span class="p-float-label p-fluid pr-3">
        <AutoComplete id="client" :model-value="selectedCompanies" :suggestions="filteredClients" field="name"
          :multiple="true" :class="{ 'p-invalid': v$.$invalid && submitted }" @complete="search($event.query)"
          @item-select="updateValue($event.value)" @item-unselect="removeValue($event.value)" />
        <label for="client">Clients</label>
      </span>
      <small v-if="(v$.required.$invalid && submitted) || v$.$pending.$response" class="p-error">
        {{ v$.required.$message.replace('Value', 'Clients') }}</small>
      <small v-if="(v$.maxLength.$invalid && submitted) || v$.$pending.$response" class="p-error">
        {{ v$.maxLength.$message.replace('allowed is', 'allowed for Clients is') }}</small>
    </div>
    <div class="col-12 mt-2">
      <span class="p-float-label p-fluid pr-3">
        <AutoComplete id="online" :model-value="selectedCommunities" :suggestions="filteredCommunities" field="name"
          :dropdown="true" :multiple="true" :complete-on-focus="true" :disabled="selectedCompanies?.length == 0"
          @complete="searchCommunities($event.query)" @item-select="updateCommunityField($event.value, 'online')"
          @item-unselect="deleteCommunityField($event.value)" />
        <label for="online">Communities</label>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, PropType, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { CompanyClaim, Claim } from '@/models/security';
import useCompanySecurity from '@/use/companySecurity';
import { Online } from '@/models/activity-dissemination';
import _ from 'lodash';

type DisplayMode = 'floating' | 'columns';

export default {
  name: 'CpProfileClientSettings',
  props: {
    modelValue: { type: Array as PropType<Array<CompanyClaim>>, required: true },
    labelDisplayMode: { type: String as PropType<DisplayMode>, default: 'columns' },
    submitted: Boolean,
    v: { type: Object, required: true },
    communities: { type: Array as PropType<Array<Online>>, default: () => [] }
  },
  emits: ['update:modelValue', 'update:communities'],
  setup(props, { emit }) {
    const atLeastInternal: Claim = { name: 'role', value: 'Internal' };

    const selectedCompanies = ref<CompanyClaim[]>([...props.modelValue]);
    const selectedCommunities = ref<Online[]>([...props.communities]);

    const filteredClients = ref<CompanyClaim[]>([]);
    const filteredCommunities = ref<Online[]>([]);
    const v$ = ref(props.v);
    const query = ref('');

    // Vuex Store
    const store = useStore();
    const satisfiesClaim = store.getters['auth/satisfiesClaim'];
    const userCompanies = store.state.auth.user.companies;

    const { data, getCompanyData, updateCompanyData, removeCompanyData } = useCompanySecurity(satisfiesClaim(atLeastInternal), userCompanies, selectedCompanies);

    async function search(q: string) {
      await getCompanyData(q);
      filteredClients.value = [...data.value];
    }

    function updateValue(value: CompanyClaim) {
      updateCompanyData(value);
      emit('update:modelValue', selectedCompanies);
      const updatedOnlines = [...selectedCommunities.value || [], ...value.onlines];
      selectedCommunities.value = _.uniqBy(updatedOnlines, 'id');

      emit('update:communities', selectedCommunities.value);
    }

    function removeValue(value: any) {
      removeCompanyData(value);
      emit('update:modelValue', selectedCompanies);
      const updatedOnlines = (selectedCompanies.value)?.flatMap((x) => x.onlines);
      selectedCommunities.value = _.uniqBy(updatedOnlines.filter(x=> selectedCommunities.value.map(x=>x.id).includes(x.id)), 'id');

      emit('update:communities', selectedCommunities.value);
    }

    watch(props, () => {
      v$.value = props.v;
    });

    function searchCommunities(event) {
      if (event.query?.length > 0) {
        query.value = event.query;
      }

      const eligibleCommunities = _.uniqBy(selectedCompanies.value.flatMap(x => x.onlines), 'id');

      const notSelected = eligibleCommunities.filter((e1) => selectedCommunities.value.findIndex((e2) => e2.name == e1.name) === -1);

      if (!query.value?.trim().length) {
        filteredCommunities.value = notSelected;
      } else {
        filteredCommunities.value = notSelected.filter((type) => type.name.toLowerCase().startsWith(query.value.trim().toLowerCase()));
      }

      if (filteredCommunities.value.length == 0) {
        query.value = '';
      }
    }

    function updateCommunityField(value: any, id: string) {
      selectedCommunities.value = [...selectedCommunities.value, { id: value.id, name: value.name },];

      emit('update:communities', selectedCommunities.value);

      document.getElementById(id)?.getElementsByTagName('input')[0].focus();
    }

    function deleteCommunityField(value: any) {
      const findIndex = selectedCommunities.value.findIndex((a) => a.id === value.id);
      selectedCommunities.value.splice(findIndex, 1);

      emit('update:communities', selectedCommunities.value);
    }

    const isClientInvalid = computed(() => (v$.value?.$invalid ?? false) && props.submitted);

    const result = {
      selectedCompanies,
      modelValue: props.modelValue,
      labelDisplayMode: props.labelDisplayMode,
      filteredClients,
      isClientInvalid,
      search,
      updateValue,
      removeValue,
      v$: props.v,
      submitted: props.submitted,
      selectedCommunities,
      filteredCommunities,
      searchCommunities,
      updateCommunityField,
      deleteCommunityField
    };

    return { ...result };
  }
};
</script>
