export const PUBLIC_PAGES = [
    '/login',
    '/set-password',
    '/forgot-password',
    '/register',
    '/onboarding'
];

export const PUBLIC_API_ROUTES = [
    '/api/auth',
    '/api/confirm-registration',
    '/api/forgot-password',
    '/api/set-password/from-token',
    '/api/reset-auth-token',
];

export function isPublicPage(path: string): boolean {
    return PUBLIC_PAGES.some(p => path.startsWith(p));
}

export function isPublicRoute(path: string): boolean {
    return PUBLIC_API_ROUTES.some(p => p.startsWith(path));
}
