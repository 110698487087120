export default function useValidationErrors(data, errors) {
    //const hasValidationErrors = (data) => {
    let hasErrors = true;
    for (const prop in data) {
        if (errors[prop]) {
            hasErrors = true;
            break;
        }
        hasErrors = false;
    }
    return hasErrors;
    //};

}
