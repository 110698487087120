import { FieldCode } from '../../models/filters/fieldCode';
import JwtDecode from 'jwt-decode';
import dayjs from 'dayjs';
import { UserRole, UserRoleEnum } from '@/models/userRole';
import { Claim } from '@/models/security/claim';
import { CompanyClaim } from '@/models/security/companyClaim';
import { ClaimAccessLevel, ClaimAccessLevelEnum } from '@/models/security/claimLevel';
import { GuidLookup } from '@/models/lookup';
import { Online } from '@/models/activity-dissemination';

export class JwtTokenUser {
  readonly email: string = '';
  readonly name: string = '';
  readonly companies: CompanyClaim[] = [];
  readonly communities: Online[] = [];
  readonly claims: any = {};
  readonly role: UserRole = 'Internal';
  readonly notificationClaimLevel: ClaimAccessLevel;
  readonly activityFields: FieldCode[];
  readonly expirationDate: Date = new Date(1, 0, 1);
  readonly userId: string = '';
  readonly analystTags: GuidLookup[] = [];
  readonly intercomHmac: string | undefined = '';
  readonly isMPSFeatureEnabled: boolean = false;

  constructor(token: string, hmac?: string) {
    try {
      const data = JwtDecode<any>(token);
      this.email = data.sub;
      this.name = `${data.given_name} ${data.family_name}`;
      this.userId = data['mm:userId'];
      this.intercomHmac = hmac;
      if (typeof this.intercomHmac === 'string') {
        localStorage.setItem('INTERCOM_HMAC', this.intercomHmac);
      }
      Object.keys(data)
        .filter((k) => k.startsWith('mm:'))
        .filter((k) => !['mm:companies', 'mm:userId'].some((val) => val === k))
        .forEach((k) => (this.claims[k.replace('mm:', '')] = data[k]));

      if (data['mm:companies']) {
        const companyData = JSON.parse(data['mm:companies']) || {
          companies: [],
        };
        this.companies = companyData.companies;
      }

      if (data['mm:communities']) {
        const companyData = JSON.parse(data['mm:communities']) || {
          companies: [],
        };
        this.communities = companyData.communities;
      }

      this.role = data['mm:role'];
      this.notificationClaimLevel = data['mm:manageNotifications'];
      this.activityFields = data['mm:activityFields'] || [];
      this.expirationDate = dayjs.unix(data.exp).toDate();
      if (data['mm:analystTags']) {
        this.analystTags = JSON.parse(data['mm:analystTags']).sort((a: GuidLookup, b: GuidLookup) => (a.name > b.name ? 1 : -1)) || [];
      }
      this.isMPSFeatureEnabled = JSON.parse(data['mm:accessMarketingPerformance'] || false);
    } catch (err) {
      console.log('failed to parse jwt token', err);
    }
  }
  public get isExpired(): boolean {
    return this.expirationDate <= new Date();
  }

  public get roleEnum(): UserRoleEnum {
    return UserRoleEnum[this.role];
  }

  public get notificationClaimLevelEnum(): ClaimAccessLevelEnum {
    return ClaimAccessLevelEnum[this.notificationClaimLevel];
  }

  public hasMinRole(role: UserRole): boolean {
    return this.roleEnum >= UserRoleEnum[role];
  }

  public hasMinNotificationClaimLevel(claimLevel: ClaimAccessLevel): boolean {
    return this.notificationClaimLevelEnum >= ClaimAccessLevelEnum[claimLevel];
  }

  public satisfiesClaim(claim: Claim): boolean {
    if (claim.name === 'role') {
      return this.hasMinRole(claim.value as UserRole);
    }
    if (claim.name === 'manageNotifications') {
      return this.hasMinNotificationClaimLevel(claim.value as ClaimAccessLevel);
    }
    return claim.value === this.claims[claim.name];
  }

  public get isAdminLevel(): boolean {
    return this.hasMinRole('Admin');
  }

  public get isInternal(): boolean {
    return this.role === 'Internal';
  }

  public get isClient(): boolean {
    return this.role === 'Client';
  }

  public get isSysAdmin(): boolean {
    return this.role === 'SystemAdmin';
  }

  public hasMinAdvancedNotificationClaim(): boolean {
    return this.hasMinNotificationClaimLevel('Advanced');
  }
}
