import axios, { AxiosError, AxiosResponse } from 'axios';
import { isPublicPage, isPublicRoute } from '../publicRoutes';
import { AuthState } from '@/store/models';
import { MSG_MUTATIONS } from '@/store/modules/msg.module';
import { Commit } from 'vuex';
import router from '@/router';

//import { Dictionary } from 'vue-router/types/router';
type Dictionary<T> = { [key: string]: T }

export function useRequestInterceptor() {
    axios.interceptors.request.use(async config => {
        config.baseURL = process.env.VUE_APP_TEMPLETON_API_URL;
        return config;
    },
        error => Promise.reject(error)
    );
}
export function useJwtInterceptor(authState: AuthState) {
    axios.interceptors.request.use(config => {
        if (!config.url) {
            return config;
        }
        const isLocal = config.url.match(/^\/api/i);

        if (!isLocal || isPublicPage(config.url)) {
            return config;
        }

        // This means that this is a secure endpoint so we need to include the jwt token.   
        if (config.headers) {
            const token = authState && authState.rawToken;
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    });
}

export function useErrorResponseInterceptor() {
    axios.interceptors.response.use(undefined, (err: AxiosError) => {
        const response = <AxiosResponse>err.response;
        if (isPublicRoute(err.config.url || '')) {
            return Promise.reject(err);
        }

        if (!response) {
            return Promise.reject(err);
        }
        // unauthorized or forbidden
        if ([401, 403].includes(response.status)) {
            // alert('You are not authorized to perform this action. Please log out and try again.');
            // commit(`auth/${AUTH_MUTATIONS.CLEAR_STATE}`);
            // window.location.replace('/');
            const params: Dictionary<string> = {
                message: response.data,
                statusCode: response.status.toString(),
                statusText: response.statusText
            };

            router.push({ name: 'error', params });
        }

        if (response.status !== 200) {
            return Promise.reject(err);
        }
    });
}

export function useLoadingBarInterceptor(commit: Commit) {
    axios.interceptors.request.use(config => {
        if (!config.url) {
            return config;
        }
        commit(`msg/${MSG_MUTATIONS.PROGRESS}`, 'up');
        return config;
    });

    axios.interceptors.response.use(rsp => {
        commit(`msg/${MSG_MUTATIONS.PROGRESS}`, 'down');
        return rsp;
    }, err => {
        commit(`msg/${MSG_MUTATIONS.PROGRESS}`, 'down');
        return Promise.reject(err);
    });
}
