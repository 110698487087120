<template>
  <div class="flex p-fluid pb-2">
    <div class="col-12 p-0">
      <span class="p-float-label">
        <AutoComplete id="tag"
          :model-value="local.value"
          :suggestions="filteredTags"
          field="name"
          :multiple="true"
          complete-on-focus="true"
          @complete="onAnalystTagSearchChange($event.query)"
          @item-select="updateField($event.value)"
          @item-unselect="deleteField($event.value)" />
        <label for="tag">{{ label }}</label>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import { GuidLookup } from '@/models/lookup';
import { TermsFilter, toLabel } from '@/models/filters';
import { Claim } from '@/models/security';
import { Debounce } from '@/utils/decorators/debounce.decorator';
import FilterLookupsSvc from '@/services/filterLookupsService';
import { Vue, Options } from 'vue-class-component';
import { Lookup } from '@/models/lookup';
import { DEFAULT_TIMEOUT_TIME } from '@/models/common/constants';

const DEFAULT: TermsFilter = new TermsFilter({
  field: 'AnalystTags',
  op: 'Contains',
});

@Options({
  computed: {
    ...mapGetters('auth', ['satisfiesClaim', 'userAnalystTags']),
  },
  name: 'cp-analyst-tags-search-field',
})
export default class AnalystTagsSearchField extends Vue {
  options: GuidLookup[] = [];
  @Prop() modelValue: TermsFilter;
  @Prop({ default: true }) dense: boolean;
  @Prop({ default: () => [] }) rules: Array<(val: string) => any>;
  userAnalystTags!: GuidLookup[]; // mapped from vuex getter
  satisfiesClaim: (claim: Claim) => boolean;

  query: string | null = null;

  filteredTags: Array<Lookup<string>> = [];

  /*
  This method will ensure that chips are populated when
  data is set from outside (like initial values or later loaded values).
  it will set the options to the value to make sure everything is rendered in the control.
  */
  @Watch('value', { deep: true })
  setDefaultOptions(val: TermsFilter) {
    const atLeastInternal: Claim = { name: 'role', value: 'Internal' };
    this.options = this.satisfiesClaim(atLeastInternal)
      ? [...val.value]
      : this.userAnalystTags.map((x) => ({ id: x.id, name: x.name }));
  }

  created() {
    this.$emit('update:modelValue', cloneDeep(this.local));
  }

  get local(): TermsFilter {
    return new TermsFilter({ ...DEFAULT, ...this.modelValue });
  }
  get label(): string {
    return toLabel(this.local.field);
  }

  @Watch('query')
  @Debounce(DEFAULT_TIMEOUT_TIME)
  async onAnalystTagSearchChange(q: string) {
    if (!q?.length) {
      return;
    }
    const atLeastInternal: Claim = { name: 'role', value: 'Internal' };

    this.options = this.satisfiesClaim(atLeastInternal)
      ? (await FilterLookupsSvc.getCmsTags(this.local.field, q)) || []
      : this.userAnalystTags.filter((x) => x.name.indexOf(q) < 0);

    this.options = this.options.map((x) => ({ id: x.id, name: x.name }));

    const notSelected = this.options.filter((e1) => this.local.value.findIndex((e2) => e2.name == e1.name) === -1);

    if (!q.trim().length) {
      this.filteredTags = notSelected;
    } else {
      this.filteredTags = notSelected.filter((type) => type.name.toLowerCase().includes(q.trim().toLowerCase()));
    }
  }

  public updateField(value: any) {
    // console.log(value);
    const termValue: Array<Lookup<string>> = [
      ...this.local.value,
      { id: value.id, name: value.name },
    ];

    const newVal = new TermsFilter({ ...this.local, value: termValue });
    this.$emit('update:modelValue', newVal);
    //  console.log(newVal);
  }

  public deleteField(value: any) {
    const findIndex = this.local.value.findIndex((a) => a.id === value.id);
    this.local.value.splice(findIndex, 1);

    const newVal = new TermsFilter({ ...this.local });
    this.$emit('update:modelValue', newVal);
  }
}
</script>
