<template>
  <InputText id="name" type="text" :model-value="modelValue" :class="{ 'p-invalid': errors.name && submitted }"
    @input="updateValue($event.target.value)" />
  <label for="name">{{ displayLabel }}</label>
  <small v-if="errors[fieldName]" class="p-error">{{ errors[fieldName] }}</small>
</template>

<script>
import { toRefs } from 'vue';
import useFormValidation from '@/utils/validators/useFormValidation';
export default {
  props: {
    fieldName: String,
    modelValue: String,
    displayLabel: String,
    preferredFieldName: String,
    id: String

  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const propsRef = toRefs(props);
    const fieldName = propsRef.fieldName;
    const preferredFieldName = propsRef.preferredFieldName;
    const { validateNameField, errors } = useFormValidation();

    const updateValue = (value) => {
      emit('update:modelValue', value);
      validateInput(value);
    };

    const validateInput = (value) => {
      validateNameField(fieldName.value, value, preferredFieldName.value);
    };

    return { errors, validateInput, validateNameField, updateValue };
  },
};
</script>
