import { ActionItem } from '@/models/common/actionItem';
import { Lookup } from '@/models/lookup';
import { DATE_WIDTH, ICON_WIDTH } from '@/models/styles';

const SEARCH_HEADERS = [
  { text: '#', align: 'start', value: 'type', width: '85px' },
  { text: 'Alert Name', value: 'name' },
  { text: 'Client Name (CID)', value: 'Clients', sortable: false },
  { text: 'Frequency', value: 'frequency', width: '75px' },
  { text: 'Active / Inactive', value: 'scheduleActive', width: '50px' },
  { text: 'Updated On', value: 'updatedOn', width: DATE_WIDTH },
  { text: 'Created by', value: 'createdBy' },
  { text: 'Last Run', value: 'lastRunOn', sortable: false, width: DATE_WIDTH },
  { text: '', align: 'end', value: 'id', width: ICON_WIDTH },
];

export const GRID_ACTION_ITEMS: ActionItem[] = [
  {
    icon: 'add',
    text: 'New Alert',
    description: 'Create new alert',
    to: '/notification/new',
    exact: false
  }
];

export const ACTIVE_STATUSES: Array<Lookup<boolean | null>> = [
  { id: null, name: 'Both' },
  { id: true, name: 'Active' },
  { id: false, name: 'Inactive' }
];

export function getNotificationHeaders(atleastAdvancedUser: boolean) {
  return atleastAdvancedUser ? SEARCH_HEADERS : SEARCH_HEADERS.filter(x => x.value !== 'createdBy');
}
