function hexToRgb(hex, alpha) {
  hex = hex.replace('#', '');
  const r = parseInt(
    hex.length == 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2),
    16
  );
  const g = parseInt(
    hex.length == 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4),
    16
  );
  const b = parseInt(
    hex.length == 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6),
    16
  );
  if (alpha) {
    return 'rgb(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
}

export type ColorNames = 'Blue' |
  'Blue2' |
  'Blue3' |
  'Blue4' |
  'Blue5' |
  'Blue6' |
  'Blue_Gradient' |
  'Blue2_Gradient' |
  'Blue3_Gradient' |
  'Blue4_Gradient' |
  'Blue5_Gradient' |
  'Blue6_Gradient' |  
  'Brown' |
  'Brown2' |
  'Brown3' |
  'Brown4' |
  'Brown5' |
  'Brown6' |  
  'Brown_Gradient' |
  'Brown2_Gradient' |
  'Brown3_Gradient' |
  'Brown4_Gradient' |
  'Brown5_Gradient' |
  'Brown6_Gradient' |  
  'DarkBlue2' |
  'DarkBlue3' |
  'DarkBlue' |
  'DarkBlue4' |
  'DarkBlue5' |
  'DarkBlue6' |  
  'DarkBlue2_Gradient' |
  'DarkBlue3_Gradient' |
  'DarkBlue_Gradient' |
  'DarkBlue4_Gradient' |
  'DarkBlue5_Gradient' |
  'DarkBlue6_Gradient' |  
  'Gray' |
  'Gray2' |
  'Gray3' |
  'Gray4' |
  'Gray5' |
  'Gray6' |  
  'Gray_Gradient' |
  'Gray2_Gradient' |
  'Gray3_Gradient' |
  'Gray4_Gradient' |
  'Gray5_Gradient' |
  'Gray6_Gradient' |  
  'LightBlue3' |
  'LightBlue3_Gradient' |
  'LightBlue2' |
  'LightBlue2_Gradient' |
  'LightBlue' |
  'LightBlue_Gradient' |
  'LightGreen' |
  'LightGreen_Gradient' |
  'Orange' |
  'Orange2' |
  'Orange3' |
  'Orange4' |
  'Orange5' |
  'Orange6' |  
  'Orange_Gradient' |
  'Orange2_Gradient' |
  'Orange3_Gradient' | 
  'Orange4_Gradient' |
  'Orange5_Gradient' |
  'Orange6_Gradient' |  
  'LightPurple' |
  'LightPurple2' |
  'LightPurple3' |
  'LightPurple4' |
  'LightPurple5' |
  'LightPurple6' |  
  'LightPurple_Gradient' |
  'LightPurple2_Gradient' |
  'LightPurple3_Gradient' |
  'LightPurple4_Gradient' |
  'LightPurple5_Gradient' |
  'LightPurple6_Gradient' |  
  'LSCBlue3' |
  'LSCBlue2' |
  'LSCBlue' |
  'LSCBlue3_Gradient' |
  'LSCBlue2_Gradient' |
  'LSCBlue_Gradient' |
  'Pink' |
  'Pink2' |
  'Pink3' |
  'Pink4' |
  'Pink5' |
  'Pink6' |  
  'Pink_Gradient' |
  'Pink2_Gradient' |
  'Pink3_Gradient' |
  'Pink4_Gradient' |
  'Pink5_Gradient' |
  'Pink6_Gradient' |  
  'Purple' |
  'Purple_Gradient' |
  'Red' |
  'Red2' |
  'Red3' |
  'Red_Gradient' |
  'Red2_Gradient' |
  'Red3_Gradient' |
  'TSL_Teal' |
  'TSL_Teal2' |
  'TSL_Teal3' |
  'TSL_Teal4' |
  'TSL_Teal5' |
  'TSL_Teal6' |  
  'TSL_Teal_Gradient' |
  'TSL_Teal2_Gradient' |
  'TSL_Teal3_Gradient' |
  'TSL_Teal4_Gradient' |
  'TSL_Teal5_Gradient' |
  'TSL_Teal6_Gradient' |  
  'Yellow' |
  'Yellow2' |
  'Yellow3' |
  'Yellow4' |
  'Yellow5' |
  'Yellow6' |  
  'Yellow_Gradient' |
  'Yellow2_Gradient' |
  'Yellow3_Gradient' |
  'Yellow4_Gradient' |
  'Yellow5_Gradient' |
  'Yellow6_Gradient';

  export const COLORS_BY_NAME: Record<ColorNames, string> = {
    Blue: '#1E9ED8',
    Blue_Gradient: '#ADE7FF',
    Blue2: '#73E1F6',
    Blue2_Gradient: '#ADE7FF',
    Blue3: '#B8F0FA',
    Blue3_Gradient: '#D4F3FF',
    Blue4: '#084EA2',
    Blue4_Gradient: '#28A0D5',
    Blue5: '#1F4573',
    Blue5_Gradient: '#1678C6',
    Blue6: '#0F2034',
    Blue6_Gradient: '#2169A2',  
    Brown: '#cd946f',
    Brown_Gradient: '#eebc9b',
    Brown2: '#f2b086',
    Brown2_Gradient: '#f4d5c0',
    Brown3: '#efd4c1',
    Brown3_Gradient: '#fdf6f1',
    Brown4: '#C67247',
    Brown4_Gradient: '#E3A079',
    Brown5: '#AF5530',
    Brown5_Gradient: '#D48056',
    Brown6: '#7D3112',
    Brown6_Gradient: '#C87957',  
    DarkBlue: '#162c4c',
    DarkBlue_Gradient: '#295795',
    DarkBlue2: '#355f98',
    DarkBlue2_Gradient: '#87a9d9',
    DarkBlue3: '#7fa0eb',
    DarkBlue3_Gradient: '#bad1f4',
    DarkBlue4: '#0E2252',
    DarkBlue4_Gradient: '#062F76',
    DarkBlue5: '#001254',
    DarkBlue5_Gradient: '#1B365A',
    DarkBlue6: '#000000',
    DarkBlue6_Gradient: '#1B365A',  
    Gray: '#76869a',
    Gray_Gradient: '#bfc8d2',
    Gray2: '#aeb7c3',
    Gray2_Gradient: '#e9ecef',
    Gray3: '#edf0f3',
    Gray3_Gradient: '#fafafa',
    Gray4: '#526277',
    Gray4_Gradient: '#A4B0BC',
    Gray5: '#324055',
    Gray5_Gradient: '#7B8A9B',
    Gray6: '#111928',
    Gray6_Gradient: '#58677A',  
    LightBlue3: hexToRgb('#73e1f6', 0.5),
    LightBlue3_Gradient: hexToRgb('#ade7ff', 0.5),
    LightBlue2: '#73e1f6',
    LightBlue2_Gradient: '#ade7ff',
    LightBlue: '#1f9fd8',
    LightBlue_Gradient: '#ade7ff',
    LightGreen: '#a1e9da',
    LightGreen_Gradient: '#c7ffeb',
    Orange: '#ffac59',
    Orange_Gradient: '#ffdbb8',
    Orange2: '#ffc489',
    Orange2_Gradient: '#ffe1c4',
    Orange3: '#ffdeac',
    Orange3_Gradient: '#ffedd8',
    Orange4: '#F98931',
    Orange4_Gradient: '#FBBD89',
    Orange5: '#FF691B',
    Orange5_Gradient: '#FFAC6E',
    Orange6: '#DE5108',
    Orange6_Gradient: '#EF8E46',  
    LightPurple: '#9572f5',
    LightPurple_Gradient: '#d9cbfe',
    LightPurple2: '#c5a9e5',
    LightPurple2_Gradient: '#d5cbef',
    LightPurple3: '#ebe4fd',
    LightPurple3_Gradient: '#f7f4fe',
    LightPurple4: '#6B418C',
    LightPurple4_Gradient: '#9765C9',
    LightPurple5: '#532678',
    LightPurple5_Gradient: '#7D3EBE',
    LightPurple6: '#2F004C',
    LightPurple6_Gradient: '#4E1E7D',  
    LSCBlue3: hexToRgb('#9ba7ce', 0.5),
    LSCBlue3_Gradient: hexToRgb('#d9dded', 0.5),
    LSCBlue2: '#9ba7ce',
    LSCBlue2_Gradient: '#d9dded',
    LSCBlue: '#304899',
    LSCBlue_Gradient: '#b1bbdb',
    Pink: '#d870a1',
    Pink_Gradient: '#fba6ce',
    Pink2: '#f0aacb',
    Pink2_Gradient: '#fdd9ea',
    Pink3: '#f9d0e4',
    Pink3_Gradient: '#fdf0f6',
    Pink4: '#CB4B81',
    Pink4_Gradient: '#F98CBE',  
    Pink5: '#AC2C5B',
    Pink5_Gradient: '#E682B0',
    Pink6: '#9B173E',
    Pink6_Gradient: '#D74179',  
    Purple: '#8747c8',
    Purple_Gradient: '#d4c9ef',
    Red: '#f4515d',
    Red_Gradient: '#f5aeae',
    Red2: '#f08890',
    Red2_Gradient: '#f8bdb9',
    Red3: '#f5b0b5',
    Red3_Gradient: '#fbdcda',
    TSL_Teal: '#53bec2',
    TSL_Teal_Gradient: '#a8dadc',
    TSL_Teal2: '#95d7d9',
    TSL_Teal2_Gradient: '#c7e7e8',
    TSL_Teal3: '#a1e9da',
    TSL_Teal3_Gradient: '#c7ffeb', 
    TSL_Teal4: '#3BA5A9',
    TSL_Teal4_Gradient: '#7CC2C5',
    TSL_Teal5: '#008D82',
    TSL_Teal5_Gradient: '#3EBFAE',
    TSL_Teal6: '#016C65',
    TSL_Teal6_Gradient: '#249E91',  
    Yellow: '#fad24a',
    Yellow_Gradient: '#f5e08b',
    Yellow2: '#f8e080',
    Yellow2_Gradient: '#f8e9ad',
    Yellow3: '#fcf2c9',
    Yellow3_Gradient: '#f8fcdd',
    Yellow4: '#EDBA2B',
    Yellow4_Gradient: '#EDCA56',
    Yellow5: '#E39A0E',
    Yellow5_Gradient: '#EDBA2C',
    Yellow6: '#DD8500',
    Yellow6_Gradient: '#E5A216'  
  };

  export const COLORS = [
    COLORS_BY_NAME['LSCBlue'],
    COLORS_BY_NAME['LightBlue'],
    COLORS_BY_NAME['DarkBlue'],
    COLORS_BY_NAME['TSL_Teal'],
    COLORS_BY_NAME['Purple'],
    COLORS_BY_NAME['Orange'],
    COLORS_BY_NAME['Red'],
    COLORS_BY_NAME['Brown'],
    COLORS_BY_NAME['Yellow'],
    COLORS_BY_NAME['Pink'],
    COLORS_BY_NAME['LightGreen'],
    COLORS_BY_NAME['LightPurple'],
    COLORS_BY_NAME['Gray'],
    '#310e31',
    '#ffde17',
    '#8b5e3c',
    '#f15a29',
    '#2bb673',
    '#006635',
    '#183319',
    '#00aeef',
    '#2e3192',
    '#ebf2e8',
    '#ee2a7b',
    '#e06369',
    '#f69799',
    '#f07178', // pink
    '#b2ccd6', // pale-blue
  ];

  export const GRADIENT_COLORS_BY_NAME: Partial<
    Record<ColorNames, (string | number)[][]>
  > = {
    Blue: [
      [0, COLORS_BY_NAME['Blue']],
      [1, COLORS_BY_NAME['Blue_Gradient']]  
    ],
    Blue2: [
      [0, COLORS_BY_NAME['Blue2']],
      [1, COLORS_BY_NAME['Blue2_Gradient']]
    ],
    Blue3: [
      [0, COLORS_BY_NAME['Blue3']],
      [1, COLORS_BY_NAME['Blue3_Gradient']]
    ],
    Blue4: [
      [0, COLORS_BY_NAME['Blue4']],
      [1, COLORS_BY_NAME['Blue4_Gradient']]
    ],
    Blue5: [
      [0, COLORS_BY_NAME['Blue5']],
      [1, COLORS_BY_NAME['Blue5_Gradient']]
    ],
    Blue6: [
      [0, COLORS_BY_NAME['Blue6']],
      [1, COLORS_BY_NAME['Blue6_Gradient']]
    ],
    Brown: [
      [0, COLORS_BY_NAME['Brown']],
      [1, COLORS_BY_NAME['Brown_Gradient']],
    ],
    Brown2: [
      [0, COLORS_BY_NAME['Brown2']],
      [1, COLORS_BY_NAME['Brown2_Gradient']],
    ],
    Brown3: [
      [0, COLORS_BY_NAME['Brown3']],
      [1, COLORS_BY_NAME['Brown3_Gradient']],
    ],
    Brown4: [
      [0, COLORS_BY_NAME['Brown4']],
      [1, COLORS_BY_NAME['Brown4_Gradient']],   
    ],
    Brown5: [
      [0, COLORS_BY_NAME['Brown5']],
      [1, COLORS_BY_NAME['Brown5_Gradient']],
    ],
    Brown6: [
      [0, COLORS_BY_NAME['Brown6']],
      [1, COLORS_BY_NAME['Brown6_Gradient']],
    ],  
    DarkBlue: [
      [0, COLORS_BY_NAME['DarkBlue']],
      [1, COLORS_BY_NAME['DarkBlue_Gradient']],
    ],
    DarkBlue2: [
      [0, COLORS_BY_NAME['DarkBlue2']],
      [1, COLORS_BY_NAME['DarkBlue2_Gradient']],
    ],
    DarkBlue3: [
      [0, COLORS_BY_NAME['DarkBlue3']],
      [1, COLORS_BY_NAME['DarkBlue3_Gradient']],
    ],
    DarkBlue4: [
      [0, COLORS_BY_NAME['DarkBlue4']],
      [1, COLORS_BY_NAME['DarkBlue4_Gradient']],
    ],
    DarkBlue5: [
      [0, COLORS_BY_NAME['DarkBlue5']],
      [1, COLORS_BY_NAME['DarkBlue5_Gradient']],
    ],
    DarkBlue6: [
       [0, COLORS_BY_NAME['DarkBlue6']],
       [1, COLORS_BY_NAME['DarkBlue6_Gradient']],
    ],  
    Gray: [
      [0, COLORS_BY_NAME['Gray']],
      [1, COLORS_BY_NAME['Gray_Gradient']],
    ],
    Gray2: [
      [0, COLORS_BY_NAME['Gray2']],
      [1, COLORS_BY_NAME['Gray2_Gradient']],
    ],
    Gray3: [
      [0, COLORS_BY_NAME['Gray3']],
      [1, COLORS_BY_NAME['Gray3_Gradient']],
    ],
    Gray4: [
      [0, COLORS_BY_NAME['Gray4']],
      [1, COLORS_BY_NAME['Gray4_Gradient']],
    ],
    Gray5: [
      [0, COLORS_BY_NAME['Gray5']],
      [1, COLORS_BY_NAME['Gray5_Gradient']],
    ],
    Gray6: [
      [0, COLORS_BY_NAME['Gray6']],
      [1, COLORS_BY_NAME['Gray6_Gradient']],
    ],  
    LightBlue2: [
      [0, COLORS_BY_NAME['LightBlue2']],
      [1, COLORS_BY_NAME['LightBlue2_Gradient']],
    ],
    LightBlue: [
      [0, COLORS_BY_NAME['LightBlue']],
      [1, COLORS_BY_NAME['LightBlue_Gradient']],
    ],
    LightGreen: [
      [0, COLORS_BY_NAME['LightGreen']],
      [1, COLORS_BY_NAME['LightGreen_Gradient']],
    ],
    Orange: [
      [0, COLORS_BY_NAME['Orange']],
      [1, COLORS_BY_NAME['Orange_Gradient']],
    ],
    Orange2: [
      [0, COLORS_BY_NAME['Orange2']],
      [1, COLORS_BY_NAME['Orange2_Gradient']],
    ],
    Orange3: [
      [0, COLORS_BY_NAME['Orange3']],
      [1, COLORS_BY_NAME['Orange3_Gradient']],
    ],
    Orange4: [
      [0, COLORS_BY_NAME['Orange4']],
      [1, COLORS_BY_NAME['Orange4_Gradient']],
    ],
    Orange5: [
      [0, COLORS_BY_NAME['Orange5']],
      [1, COLORS_BY_NAME['Orange5_Gradient']],
    ],
    Orange6: [
      [0, COLORS_BY_NAME['Orange6']],
      [1, COLORS_BY_NAME['Orange6_Gradient']],
    ],  
    LightPurple: [
      [0, COLORS_BY_NAME['LightPurple']],
      [1, COLORS_BY_NAME['LightPurple_Gradient']],
    ],
    LightPurple2: [
      [0, COLORS_BY_NAME['LightPurple2']],
      [1, COLORS_BY_NAME['LightPurple2_Gradient']],
    ],
    LightPurple3: [
      [0, COLORS_BY_NAME['LightPurple3']],
      [1, COLORS_BY_NAME['LightPurple3_Gradient']],
    ],
    LightPurple4: [
      [0, COLORS_BY_NAME['LightPurple4']],
      [1, COLORS_BY_NAME['LightPurple4_Gradient']],
    ],
    LightPurple5: [
      [0, COLORS_BY_NAME['LightPurple5']],
      [1, COLORS_BY_NAME['LightPurple5_Gradient']],
    ],
    LightPurple6: [
      [0, COLORS_BY_NAME['LightPurple6']],
      [1, COLORS_BY_NAME['LightPurple6_Gradient']],
    ],  
    LSCBlue3: [
      [0, COLORS_BY_NAME['LSCBlue3']],
      [1, COLORS_BY_NAME['LSCBlue3_Gradient']],
    ],
    LSCBlue2: [
      [0, COLORS_BY_NAME['LSCBlue2']],
      [1, COLORS_BY_NAME['LSCBlue2_Gradient']],
    ],
    LSCBlue: [
      [0, COLORS_BY_NAME['LSCBlue']],
      [1, COLORS_BY_NAME['LSCBlue_Gradient']],
    ],
    Pink: [
      [0, COLORS_BY_NAME['Pink']],
      [1, COLORS_BY_NAME['Pink_Gradient']],
    ],
    Pink2: [
      [0, COLORS_BY_NAME['Pink2']],
      [1, COLORS_BY_NAME['Pink2_Gradient']],
    ],
    Pink3: [
      [0, COLORS_BY_NAME['Pink3']],
      [1, COLORS_BY_NAME['Pink3_Gradient']],
    ],
    Pink4: [
      [0, COLORS_BY_NAME['Pink4']],
      [1, COLORS_BY_NAME['Pink4_Gradient']],
    ],
    Pink5: [
      [0, COLORS_BY_NAME['Pink5']],
      [1, COLORS_BY_NAME['Pink5_Gradient']],
    ],
    Pink6: [
      [0, COLORS_BY_NAME['Pink6']],
      [1, COLORS_BY_NAME['Pink6_Gradient']],
    ],  
    Purple: [
      [0, COLORS_BY_NAME['Purple']],
      [1, COLORS_BY_NAME['Purple_Gradient']],
    ],
    Red: [
      [0, COLORS_BY_NAME['Red']],
      [1, COLORS_BY_NAME['Red_Gradient']],
    ],
    Red2: [
      [0, COLORS_BY_NAME['Red2']],
      [1, COLORS_BY_NAME['Red2_Gradient']],
    ],
    Red3: [
      [0, COLORS_BY_NAME['Red3']],
      [1, COLORS_BY_NAME['Red3_Gradient']],
    ],
    TSL_Teal: [
      [0, COLORS_BY_NAME['TSL_Teal']],
      [1, COLORS_BY_NAME['TSL_Teal_Gradient']],
    ],
    TSL_Teal2: [
      [0, COLORS_BY_NAME['TSL_Teal2']],
      [1, COLORS_BY_NAME['TSL_Teal2_Gradient']],
    ],
    TSL_Teal3: [
      [0, COLORS_BY_NAME['TSL_Teal3']],
      [1, COLORS_BY_NAME['TSL_Teal3_Gradient']],
    ],
    TSL_Teal4: [
      [0, COLORS_BY_NAME['TSL_Teal4']],
      [1, COLORS_BY_NAME['TSL_Teal4_Gradient']],
    ],
    TSL_Teal5: [
      [0, COLORS_BY_NAME['TSL_Teal5']],
      [1, COLORS_BY_NAME['TSL_Teal5_Gradient']],
    ],
    TSL_Teal6: [
      [0, COLORS_BY_NAME['TSL_Teal6']],
      [1, COLORS_BY_NAME['TSL_Teal6_Gradient']],
    ],  
    Yellow: [
      [0, COLORS_BY_NAME['Yellow']],
      [1, COLORS_BY_NAME['Yellow_Gradient']],
    ],
    Yellow2: [
      [0, COLORS_BY_NAME['Yellow2']],
      [1, COLORS_BY_NAME['Yellow2_Gradient']],
    ],
    Yellow3: [
      [0, COLORS_BY_NAME['Yellow3']],
      [1, COLORS_BY_NAME['Yellow3_Gradient']]
    ],
    Yellow4: [
      [0, COLORS_BY_NAME['Yellow4']],
      [1, COLORS_BY_NAME['Yellow4_Gradient']]
    ],
    Yellow5: [
      [0, COLORS_BY_NAME['Yellow5']],
      [1, COLORS_BY_NAME['Yellow5_Gradient']]
    ],
    Yellow6: [
      [0, COLORS_BY_NAME['Yellow6']],
      [1, COLORS_BY_NAME['Yellow6_Gradient']]
    ]  
  };
