import { GetterTree, MutationTree, Module } from 'vuex';
import { ActivityFieldsState } from '../models/activityFieldsState';
import { ActivityField } from '@/models/search';
import activityFieldsService from '@/services/activityFieldsService';

export const ACTIVITY_FIELDS_MODULE = Object.freeze({
  SET_FIELDS: 'setFields',
  GET_FIELDS: 'getFields'
});

const GETTERS: GetterTree<ActivityFieldsState, any> = {
  fields: state => state.fields || []
};

const MUTATIONS: MutationTree<ActivityFieldsState> = {
  [ACTIVITY_FIELDS_MODULE.SET_FIELDS](state, fields: ActivityField[]) {
    state.fields = fields;
  }
};

export const activityFieldsModule: Module<ActivityFieldsState, any> = {
  namespaced: true,
  state: {
    fields: []
  },
  getters: GETTERS,
  mutations: MUTATIONS,
  actions: {
    [ACTIVITY_FIELDS_MODULE.GET_FIELDS]({ commit }) {
      return activityFieldsService.get().then(fields => {
        commit(ACTIVITY_FIELDS_MODULE.SET_FIELDS, fields);
      });
    }
  }
};
