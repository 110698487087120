<template>
  <form @submit.prevent="reset(!v$.$invalid)">
    <background-logo />
    <div class="flex flex-column align-items-center justify-content-center h-screen">
      <Card v-show="!isSuccess" class="col-12 p-login-card">
        <template #title>
          <div class="flex flex-row justify-content-center mt-8 p-login-card-title">
            <h1>Reset Password</h1>
          </div>
        </template>
        <template #content>
          <div class="flex flex-column">
            <div class="flex flex-row align-items-center justify-content-center">
              <div class="flex px-8">
                <p class="text-center">
                  Enter your e-mail below, and we'll email you a link to reset your password.
                </p>
              </div>
            </div>
            <div class="flex mx-8 mt-7">
              <span class="p-float-label p-fluid w-full">
                <InputText id="email" v-model="v$.emailModel.email.$model" type="text" class="main-card-email"
                  :class="{ 'p-invalid': v$.emailModel.email.$invalid && submitted }" />
                <label for="email">Email</label>
              </span>
            </div>
            <div class="flex align-items-start justify-content-between mx-8 mt-5">
              <Button type="submit" label="Submit"
                class="p-button-raised p-button-rounded text-white px-5 py-2">
                <ProgressSpinner v-if="loading && !v$.$invalid" style="width: 20px; height: 20px" stroke-width="8" />
              </Button>
              <router-link to="/login" class="p-button p-component p-button-link pl-0s">
                Back to log in
              </router-link>
            </div>
            <HelpToggle title="Password Help" details="In order to reset your password, your account must be registered.
              To register your account, click on the link from the registration e-mail that was sent to you." />
          </div>
        </template>
      </Card>
      <Card v-show="isSuccess" class="col-12 p-login-card">
        <template #content>
          <div class="flex flex-row align-items-center justify-content-center mt-8 cursor-pointer">
            <span class="material-icons" style="font-size: 4em;"> mail_outline </span>
          </div>
          <div class="flex align-items-center justify-content-center mt-2">
            <h1>Success!</h1>
          </div>
          <div class="flex align-items-center justify-content-center mt-6 mb-6">
            <p>Watch your inbox for a password reset e-mail.</p>
          </div>
        </template>
      </Card>
      <mail-to />
    </div>
  </form>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import axios from 'axios';
import { MSG_MUTATIONS } from '@/store/modules/msg.module';

import { email, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import BackgroundLogo from '@/components/login/backgroundLogo.vue';
import HelpToggle from '@/components/login/helpToggle.vue';
import MailTo from '@/components/login/mailTo.vue';

export default defineComponent({
  components: {MailTo, HelpToggle, BackgroundLogo },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      loading: false,
      submitted: false,
      errorMessage: '',
      emailModel: { email: '' },
      isOpenHelp: false,
      isSuccess: false,
    };
  },
  validations() {
    return {
      emailModel: {
        email: { required, email },
      },
    };
  },
  methods: {
    async reset(isFormValid: boolean) {
      this.submitted = true;

      if (!isFormValid) {
        return;
      }

      this.loading = true;

      const payload = { email: this.emailModel.email };
      const apiResult = await axios.post('/api/forgot-password', payload);
      const { success, message } = apiResult.data;

      if (!success) {
        this.errorMessage = message || '';
        this.loading = false;
        this.$toast.removeAllGroups();
        this.$toast.add({
          severity: 'error',
          summary: '',
          detail: this.errorMessage,
          closable: true,
        });

        return;
      }
      this.isSuccess = true;

      this.$store.commit(`msg/${MSG_MUTATIONS.MSG}`, {
        text: `A reset password link has been sent to ${payload.email}`,
      });

      this.$toast.add({
        severity: 'success',
        summary: '',
        detail: `A reset password link has been sent to ${payload.email}`,
        life: 3000,
        closable: false,
      });

      this.loading = false;
    },
  },
});
</script>

<style lang="scss" scoped>

</style>
