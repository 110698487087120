<template>
  <cp-readonly-form :data1="data1" />
</template>


<script lang="ts">

import { onMounted, reactive, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { getInitials } from '@/utils/userUtils';
import { useToast } from 'primevue/usetoast';
import OnboardingFormService from '@/services/onboardingFormService';
import ReadOnlyForm from '@/components/onboarding/ReadOnlyForm.vue';
import { isImageFileType } from '@/utils/fileUtils';

export default {

  components: {
    'cp-readonly-form': ReadOnlyForm
  },
  emits: ['next-page'],
  setup(props, { emit }) {

    const previewInfo = ref([]);
    const data1 = reactive(previewInfo);
    const onboardingFormId = ref('') as any;
    const toast = useToast();
    const noLogSvg = {
      img: require('../../assets/onboarding/images/broken-image-with-text.svg')
    };

    const route = useRoute();
    watch(() => route.params, () => {
      onboardingFormId.value = route.params.id;
    }, { immediate: true });

    onMounted(async () => {
      data1.value = (await OnboardingFormService.getOnboardingForm(onboardingFormId.value)) as any;
      checkFileType();
    });

    const checkFileType = () => {
      if(data1.value['content']) {
        data1.value['content'].map(c => c.uploadedFileUrls.map(file => {
          file.isImage = isImageFileType(file.fileType, file.fileUrl?.split(/\//).pop());
        }));
      }
    };

    const resendOnBoardingForm = async () => {
      await OnboardingFormService.resendOnBoardingForm(onboardingFormId.value, toast);
    };

    const loggedInUserInitials = (name) => {
      if (name) {
        const nameParts = name.split(' ');
        return getInitials(nameParts);
      } else return '';
    };

    const formatOnlines = (onlines: string[]) => (onlines || []).join(', ');

    const edit = () => {

      emit('next-page', { pageIndex: 0 });

    };

    return { edit, data1, formatOnlines, noLogSvg, loggedInUserInitials, resendOnBoardingForm, toast };
  }

};
</script>