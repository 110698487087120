<template>
  <div class="grid fluid">
    <div class="col-12 pb-5">
      <Message :closable="false">
        Please use this to share content that you would like to appear on your company profile page.
        If you have content in an external repository, such as a file-sharing service,
        you can submit any relevant URLs and information to access instead of uploading multiple documents.
        <br><br>
        Please note: We cannot retrieve videos from YouTube or any URLs that do not allow an option to download the MP4
        file,
        so please share MP4 files or links with the option to download for any video or audio files.
        Descriptions can be helpful for multimedia content.
      </Message>
    </div>
    <div class="col-12 p-3 flex flex-column gap-2">
      <div>Would you like us to review your website to find applicable content?</div>
      <div class="flex flex-wrap gap-3">
        <div class="flex align-items-center">
          <RadioButton v-model="contentInfo.websiteReview" input-id="websiteReviewYes" name="websiteReview" value="Yes" />
          <label for="websiteReviewYes" class="ml-2">Yes</label>
        </div>
        <div class="flex align-items-center">
          <RadioButton v-model="contentInfo.websiteReview" input-id="websiteReviewNo" name="websiteReview" value="No" />
          <label for="websiteReviewNo" class="ml-2">No</label>
        </div>
      </div>
    </div>
    <div class="col-12 p-3">
      <span class="p-float-label p-fluid">
        <Textarea id="comment" v-model="contentInfo.comment" type="text" />
        <label for="comment">Comment</label>
      </span>
    </div>
    <div class="flex col-12 pb-4 align-items-center">
      <div class="col-4">
        <Button type="submit" label="Add New Content" class="p-button-success p-button-raised" @click="addNew">
          Add New Content
          <span class="material-icons pl-2"> add </span>
        </Button>
      </div>
    </div>

    <div class="flex col-12">
      <div class="col-12">
        <h4>
          My Content
        </h4>
      </div>
    </div>
    <div v-if="data1 && data1['length'] === 0" class="flex col-12 text-base p-secondary-color">
      <div class="col-12 hint">
        <small>You haven't uploaded any content yet. To upload content please click "Add New Content" or,
          click Save and Continue if you are finished adding your content.</small>
      </div>
    </div>
    <div v-if="data1 && data1['length'] > 0" class="flex col-12">
      <DataTable :value="data1" class="w-full" :striped-rows="data1.length > 0"
        responsive-layout="scroll" data-key="id" :lazy="true">
        <Column header="Title" field="title" style="width: 80%" />
        <Column>
          <template #body="slotProps">
            <Button class="p-button-text pi pi-user-edit" aria-haspopup="true" aria-controls="overlay_menu"
              @click="edit(slotProps.data.id)" />
            <Button class="p-button-text pi pi-trash" aria-haspopup="true" aria-controls="overlay_menu"
              @click="showRemoveConfirmation($event, slotProps.data)" />
          </template>
        </Column>
      </DataTable>
    </div>
  </div>

  <div>
    <Dialog id="onboardingheader" v-model:visible="showContent" :closable="false"
      style="border: 1px solid #FFB061;min-height:100%;zoom:88%;" :modal="true">
      <template #header>
        <div class="flex col-12">
          <div class="flex col-11">
            <h4 style="color:#ffffff">
              Upload New Content
            </h4>
          </div>
          <div class="flex col-1 absolute right-0 align-items-center justify-content-center" style="padding-right:0rem;">
            <i style="color:white" class="pi pi-times reverse" aria-haspopup="true" aria-controls="overlay_menu"
              @click="handleClose" />
          </div>
        </div>
      </template>
      <div class="pb-4" />
      <div class="card">
        <div class="col-12 pb-3">
          <Message :closable="false">
            Please use this to share content that you would like to appear on your company profile page.
            If you have content in an external repository, such as a file-sharing service,
            you can submit any relevant URLs and information to access instead of uploading multiple documents.
            <br><br>
            Please note: We cannot retrieve videos from YouTube or any URLs that do not allow an option to download the
            MP4 file,
            so please share MP4 files or links with the option to download for any video or audio files.
            Descriptions can be helpful for multimedia content.
          </Message>
        </div>
        <div class="flex col-12">
          <div class="col-12">
            <span class="p-float-label p-fluid">
              <InputText id="title" v-model="v$.data.title.$model" type="text"
                :class="{ 'p-invalid': v$.data.title.$invalid && submitted }" />
              <label for="title" :class="{ 'p-error': v$.data.title.$invalid && submitted }">Title</label>
              <small id="title-help" class="hint">Enter title of document (Note: Publisher editorial discretion applies,
                title may be edited.).</small> <br>
              <small v-if="(v$.data.title.$invalid && submitted) || v$.data.title.$pending.$response" class="p-error">
                {{ v$.data.title.required.$message.replace('Value', 'Title') }}
              </small>
            </span>
          </div>
        </div>
        <div class="flex col-12">
          <div class="col-12">
            <span class="p-float-label p-fluid">
              <InputText id="weburl" v-model="newContent.data.url" type="text" />
              <label for="weburl">Website URL</label>
              <small id="url-help" class="hint">Enter the hyperlink for the piece of content on your website.</small>
            </span>
          </div>
        </div>
        <div class="flex col-12">
          <div class="col-12">
            <span class="p-float-label p-fluid">
              <InputText id="keywords" v-model="newContent.data.keyword" type="text" />
              <label for="keywords">Keywords</label>
              <small id="keywords-help" class="hint">Enter 2-3 applicable keywords to assist with search engine
                relevancy and content
                categorization.</small>
            </span>
          </div>
        </div>
        <div class="col-12 lg:col-12 p-3">
          <cp-multiple-file-upload
            hint="Note: Only provide images that you retain the copyright license to share with 3rd parties. 
              Free or creative commons stock photography is not accepted. You may also share a link that allows download access to the file(s)."
            accept="" :file-url="newContent.data.fileUrl" @update:file-url="updateFileURL"
            @files-selected="handleFilesSelected" />
        </div>
        <div v-if="newContent.data.uploadedFileUrls && newContent.data.uploadedFileUrls.length > 0"
          class="col-12 lg:col-12 p-3 pt-0">
          <div class="card">
            <Panel header="Uploaded files">
              <div v-for="(file, index) in newContent.data.uploadedFileUrls" :key="index"
                class="flex align-items-center justify-content-center">
                <div class="col-2">
                  <img v-if="file.isImage" :src="file.fileUrl" role="presentation" height="50"
                    style="width:100%;object-fit: contain;" class="shadow-2">
                  <img v-else :src="noImageSvg.img" role="presentation" height="50"
                    style="width:100%;object-fit: contain;" class="shadow-2">
                </div>
                <div class="col-9">
                  <span class="font-semibold">{{ file.fileUrl }}</span>
                </div>
                <div class="col-1 flex align-items-center justify-content-center">
                  <Button icon="pi pi-times" severity="danger" text rounded aria-label="Remove"
                    @click="showRemoveFileConfirmation($event, file.id, newContent.data.id)" />
                </div>
              </div>
            </Panel>
          </div>
        </div>
      </div>
      <!-- <template #footer> -->
      <div class="col-12 flex flex-row-reverse">
        <Button type="submit" class="p-button-success p-button-raised p-button-rounded ml-3" 
          :label="saveButtonLabel" :loading="saveButtonLoading" @click="saveContent(!v$.$invalid)" />
        <Button type="button" class="p-button-rounded p-button-outlined clear-button ml-3" 
          label="Cancel" @click="showDialog(false)" />
        <ProgressBar v-if="(fileUploadProgress > 0)" :value="fileUploadProgress" class="w-12 h-2rem" />
      </div>
      <!-- </template> -->
    </Dialog>
  </div>
  <br>
  <div style="float:right;" class="pb-5">
    <Button v-if="data1 && data1.length > 0" ref="goForward" label="Save and Continue" type="button"
      class="p-button-success p-button-raised" @click="nextStep" />
  </div>
</template>

<script lang="ts">
import axios from 'axios';
import MultipleFileUpload from '@/components/common/MultipleFileUpload.vue';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { ref, toRefs, onMounted, reactive } from 'vue';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import onboardingFormService from '@/services/onboardingFormService';
import { onError, onSuccess } from '@/utils/toast/toastUtils';
import { isImageFileType } from '@/utils/fileUtils';
import { computed } from 'vue';
import { ContentInfo, ContentItem, FileUrl } from '@/models/onboarding/onboardingFormContent';
import { v4 as uuidv4 } from 'uuid';

export default {

  components: {
    'cp-multiple-file-upload': MultipleFileUpload
  },
  props: {
    formId: {
      type: String,
      default: ''
    },
  },
  emits: ['next-page', 'step-completed'],
  setup(props, { emit }) {
    const contentInfo = ref<ContentInfo>({
      id: 0,
      companyId: 0,
      websiteReview: '',
      comment: ''
    });
    const data1 = ref<ContentItem[]>([]);
    const showContent = ref(false);
    const { formId } = toRefs(props);
    const toast = useToast();
    const confirm = useConfirm();
    const submitted = ref(false);
    let selectedFiles = ref<File[]>([]);
    let fileUploadProgress = ref(0);
    const noImageSvg = {
      img: require('../../assets/onboarding/images/broken-image-with-text.svg')
    };
    const processingBackend = ref(false);
    const saveButtonLabel = computed(() => {
      if (fileUploadProgress.value > 0 && fileUploadProgress.value < 100) {
        return 'Saving...';
      }
      if (processingBackend.value) {
        return 'Processing...';
      }
      return 'Save';
    });
    const saveButtonLoading = computed(() => (fileUploadProgress.value > 0 && fileUploadProgress.value < 100) || processingBackend.value);
    const CHUNK_SIZE = 1024 * 1024 * 2; // 2 MB
    const isUploadCancelled = ref(false);
    
    onMounted(async () => {
      getContentInfo();
      getContents();
    });

    const getNewContent = () => (<ContentItem>{
      id: 0,
      title: '',
      url: '',
      keyword: '',
      fileUrl: '',
      uploadedFileUrls: [] as FileUrl[],
    });

    const newContent = reactive({
      data: getNewContent(),
    });

    const rules = {
      data: {
        title: { required }
      }
    };
    const v$ = useVuelidate(rules, newContent);

    const fetchApi = async (url: string) => {
      try {
        return (await axios.get(url)).data;
      } catch (err) {
        console.log(err, `An error occurred while fetching data from ${url}`);
        throw err;
      }
    };

    const getContentInfo = async () => {
      const url = `/api/onboarding/${formId.value}/content-info`;
      contentInfo.value = await fetchApi(url);
    };

    const getContents = async () => {
      const url = `/api/onboarding/${formId.value}/content/list`;
      data1.value = await fetchApi(url);
    };

    const removeFile = async (id: number, contentId: number) => {
      try {
        const url = `/api/onboarding/${props.formId}/content/${contentId}/file/${id}`;
        await axios.delete(url);
        newContent.data.uploadedFileUrls = newContent.data.uploadedFileUrls.filter((item) => item.id !== id);
        onSuccess(toast, 'The file has been deleted');
      }
      catch (error) {
        onError(toast, 'There was an error deleting the file');
        throw (error);
      }
    };

    const showRemoveFileConfirmation = async (event, id: number, contentId: number) => {
      confirm.close();
      confirm.require({
        target: event.currentTarget,
        message: 'Are you sure you want to delete this file?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Continue',
        rejectLabel: 'Cancel',
        accept: () => {
          removeFile(id, contentId);
        },
        reject: () => null
      });
    };

    const showRemoveConfirmation = async (event, item: ContentItem) => {
      confirm.close();
      confirm.require({
        target: event.currentTarget,
        message: 'Are you sure you want to delete this content?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Continue',
        rejectLabel: 'Cancel',
        accept: () => {
          remove(item);
        },
        reject: () => null
      });
    };

    const remove = (item: ContentItem) => {
      try {
        onboardingFormService.deleteContent(formId, item.id);
        const index = data1.value.findIndex(x => x === item);
        data1.value.splice(index, 1);
        onSuccess(toast, `Deleted content '${item.title}'`);
      } catch (error) {
        onError(toast, `There was an error deleting the content '${item.title}'`);
      }
    };

    const handleFilesSelected = (files: File[]) => {
      selectedFiles.value = files;
    };

    const updateFileURL = (newValue: string) => {
      newContent.data.fileUrl = newValue;
    };

    const saveContentInfo = async () => {
      try {
        const url = `/api/onboarding/${formId.value}/content-info`;
        await axios.patch(url, contentInfo.value);
        onSuccess(toast, 'Saved content info');
      } catch (error) {
        onError(toast, 'There was an error saving the content info');
      }
    };

    const saveContent = async (isFormValid: boolean) => {
      submitted.value = true;
      if (!isFormValid) {
        return;
      }

      const url = `/api/onboarding/${formId.value}/content`;
      const isNew = true;
      const fd = new FormData();
      Object.keys(newContent.data).forEach(k => fd.append(k, (<any>newContent.data)[k]));
      const opts = { headers: { 'Content-Type': 'multipart/form-data' } };
      const fn = isNew ? axios.post : axios.patch;
      const item: ContentItem = (await fn(url, fd, opts)).data;
      
      item.uploadedFileUrls = (await saveFiles(item.id));

      const sidebarIndex = data1.value.findIndex(c => c.id === item.id);

      if (sidebarIndex > -1) {
        data1.value.splice(sidebarIndex, 1, <ContentItem>{ id: item.id, title: item.title });
      } else {
        data1.value.push(item);
      }

      onSuccess(toast, `Content Saved`);
      newContent.data = getNewContent();
      fileUploadProgress.value = 0;
      showDialog(false);
    };

    const saveFiles = async (contentId: number) => {
      try {
        const url = `/api/onboarding/${formId.value}/content/${contentId}/file/chunk`;
        const uploadedFiles: FileUrl[] = [];
        const totalChunksOverall = selectedFiles.value.reduce((sum, file) => sum + Math.ceil(file.size / CHUNK_SIZE), 0);
        let chunksUploaded = 0;

        for (const file of selectedFiles.value) {
          let start = 0;
          let chunkIndex = 0;
          const totalChunksForFile = Math.ceil(file.size / CHUNK_SIZE);
          const fileName = `${uuidv4()}_${encodeURIComponent(file.name)}`;

          while (start < file.size) {
            if (isUploadCancelled.value) {
              break;
            }
            const end = Math.min(start + CHUNK_SIZE, file.size);
            const chunk = file.slice(start, end);
            const fd = new FormData();
            fd.append('file', chunk);
            fd.append('fileName', fileName);
            fd.append('chunkIndex', chunkIndex.toString());
            fd.append('totalChunks', totalChunksForFile.toString());
            
            const opts = {
              headers: { 'Content-Type': 'multipart/form-data' },
              onUploadProgress: (progressEvent: ProgressEvent) => {
                const chunkPercentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                fileUploadProgress.value = Math.round(((chunksUploaded + (chunkPercentCompleted / 100)) * 100) / totalChunksOverall);
              }
            };

            if(chunksUploaded === totalChunksForFile - 1) {
              processingBackend.value = true; 
            }
            const response = (await axios.post(url, fd, opts)).data;
            if (response.isFileAssembled) {
              uploadedFiles.push(response.fullFileUrl);
              processingBackend.value = false;
            }

            start += CHUNK_SIZE;
            chunkIndex++;
            chunksUploaded++;
          }
        }

        return uploadedFiles;
      } catch (err) {
        console.log(err, `An error occurred while uploading files`);
        throw err;
      }
    };

    const edit = async (id: number) => {
      const url = `/api/onboarding/${formId}/content/${id}`;
      newContent.data = (await axios.get<ContentItem>(url)).data;
      v$.value.data.title.$model = newContent.data.title;
      isUploadCancelled.value = false;
      checkFileType();
      showDialog(true);
    };

    const checkFileType = () => {
      newContent.data.uploadedFileUrls.map((file: FileUrl) => {
        file.isImage = isImageFileType(file.fileType, file.fileUrl?.split(/\//).pop());
      });
    };

    const addNew = () => {
      newContent.data = getNewContent();
      isUploadCancelled.value = false;
      showDialog(true);
    };

    const showDialog = (value: boolean) => {
      if (!value) {
        cancelUpload();
      }
      showContent.value = value;
    };

    const handleClose = () => {
      showContent.value = false;
      cancelUpload();
    };

    const cancelUpload = () => {
      isUploadCancelled.value = true;
      fileUploadProgress.value = 0;
      processingBackend.value = false;
      selectedFiles.value = [];
    };

    const nextStep = () => {
      saveContentInfo();
      emit('next-page', { pageIndex: 4 });
      emit('step-completed', 4);
    };
    return {
      v$, submitted, data1,
      addNew, handleClose, showRemoveConfirmation, showRemoveFileConfirmation, saveButtonLoading,
      showDialog, edit, saveContent, contentInfo, noImageSvg, selectedFiles, fileUploadProgress,
      showContent, newContent, nextStep, handleFilesSelected, updateFileURL, saveButtonLabel
    };
  }
};
</script>


<style lang="scss" scoped>
.step-container {
  width: 100%;
}

.search-button {
  box-shadow: none !important;
}

.fill-height {
  height: calc(100vh - 205px);
}

.add-content-container {
  min-height: 400px;
}

.active {
  background-color: #ccc;
  border-top: 1px solid EEE;
  border-bottom: 1px solid EEE;
}

h1,
h3 {
  margin-bottom: 10px;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}</style>