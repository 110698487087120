<template>
  <form ref="form" @submit.prevent="save">
    <div class="col-12 flex">
      <div class="flex">
        <h1 class="line-height-1">
          New User
        </h1>
        <!-- <span class="help-icon ml-2">?</span> -->
      </div>
    </div>
    <div class="col-12 flex">
      <div class="flex col-6">
        <span class="p-secondary-color text-sm">Fill out this form to add/change a user</span>
      </div>
      <div class="col-6 flex flex-row-reverse">
        <Button type="submit" class="p-button-success p-button-raised p-button-rounded ml-3" label="Save" />
        <Button type="button" class="p-button-outlined p-button-rounded" label="Reset" @click="resetForm" />
      </div>
    </div>
    <cp-profile-user-identification v-model="user" :submitted="submitted" :v="v$.user" />
    <cp-profile-client-settings v-if="!userRoleAtLeastInternal" v-model="user.companies"
      v-model:communities="user.communities" v-model:submitted="submitted" label-display-mode="floating"
      :v="v$.user.companies" />
    <div class="grid w-full p-0 my-2">
      <div class="col-12 lg:col-6">
        <span class="p-float-label p-fluid">
          <Dropdown v-model="user.notificationClaimLevel" :options="notificationClaimLevelLookups"
            placeholder="Select Notification Access Level" />
          <label for="manageNotifications">Manage Notifications</label>
        </span>
      </div>
      <div class="col-12 md:col-6 lg:col-3 flex align-items-center">
        <Checkbox v-model="user.viewNotifications" :binary="true" />
        <label class="p-secondary-color ml-2">Enable Notification Dashboard</label>
      </div>
      <div class="col-12 md:col-6 lg:col-3 flex align-items-center">
        <Checkbox v-model="user.enableApiAccess" :binary="true" />
        <label class="p-secondary-color ml-2">Enable API</label>
      </div>
    </div>
    <Divider />
    <div class="grid w-full p-0 my-2">
      <div class="col-12 lg:col-6 flex py-0 align-items-center ">
        <Checkbox v-model="sendEmailToAdmin" :binary="true" />
        <label class="p-secondary-color ml-2">Send me a copy of the registration email</label>
      </div>
      <div v-if="authenticatedUser.isAdminLevel && userRoleAtLeastInternal"
        class="col-12 md:col-6 lg:col-3 flex align-items-center">
        <Checkbox v-model="user.reviewActivities" :binary="true" />
        <label class="p-secondary-color ml-2">Enable Review Activities</label>
      </div>
      <div class="col-12 md:col-6 lg:col-3 flex align-items-center">
        <Checkbox v-model="user.accessMarketingPerformance" :binary="true" />
        <label class="p-secondary-color ml-2">Enable Marketing Performance</label>
      </div>
    </div>
    <Divider />
    <cp-filter-options v-model="user.activityFields" v-model:restrictedAnalystTagValues="user.analystTags"
      :submitted="submitted" :user-role="user.role" :v="v$.user" />
  </form>
</template>
<script lang="ts">
import { ref } from 'vue';
import useVuelidate from '@vuelidate/core';

import { AppUser } from '@/models/appUser';
import useUserSecurity from '@/use/userSecurity';
import useUserActions from '@/use/userActions';

import FilterOptions from '@/components/admin/FilterOptions.vue';
import ProfileUserIdentification from '@/components/profile/UserIdentification.vue';
import ProfileClientSettings from '@/components/profile/ClientSettings.vue';
import useAuthenticatedUser from '@/use/authenticatedUser';

export default {
  name: 'CpNewUser',
  components: {
    'cp-filter-options': FilterOptions,
    'cp-profile-client-settings': ProfileClientSettings,
    'cp-profile-user-identification': ProfileUserIdentification,
  },
  setup() {
    const sendEmailToAdmin = ref(true);
    const submitted = ref(false);
    const sendOnboardingForm = ref(false);
    const saveSuccess = ref(false);
    const user = ref(new AppUser({ firstName: '', email: '', lastName: '', notificationClaimLevel: 'None' }));

    const { notificationClaimLevelLookups, userRoleAtLeastInternal, isMyProfile, validations } = useUserSecurity(user);
    const v$ = useVuelidate(validations, { user });
    const { createUser } = useUserActions(user, v$, saveSuccess, submitted, isMyProfile, sendEmailToAdmin, sendOnboardingForm);

    const { authenticatedUser } = useAuthenticatedUser();

    // console.debug(`NewUser.setup() - useVuelidate`, v$);

    async function save() {
      await createUser();
    }


    function resetForm() {
      // console.debug(`NewUser.resetForm() - before reset`, user.value);
      user.value.firstName = '';
      user.value.lastName = '';
      user.value.email = '';
      user.value.role = 'Client';
      user.value.activityFields = [];
      user.value.companies = [];
      user.value.communities = [];
      user.value.analystTags = [];
      user.value.notificationClaimLevel = 'None';
      user.value.reviewActivities = false;
      user.value.viewNotifications = false;
      user.value.enableApiAccess = false;
      // console.debug(`NewUser.resetForm() - after reset`, user.value);
      sendEmailToAdmin.value = true;
      submitted.value = false;
      setTimeout(() => {
        v$.value.$reset();
      }, 1000);
    }

    return {
      user,
      sendEmailToAdmin,
      notificationClaimLevelLookups,
      userRoleAtLeastInternal,
      submitted,
      saveSuccess,
      save,
      resetForm,
      v$,
      authenticatedUser
    };
  }

};
</script>
