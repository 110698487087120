import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex align-items-center justify-content-between" }
const _hoisted_2 = { class: "flex align-items-center" }
const _hoisted_3 = { class: "pt-1" }
const _hoisted_4 = {
  ref: "chart",
  class: "flex align-items-center pt-5",
  style: {"min-height":"300px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cp_export_chart_options = _resolveComponent("cp-export-chart-options")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString($setup.title), 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_cp_export_chart_options, {
          chart: $setup.chartExport,
          onChartUpdated: $setup.chartUpdated
        }, null, 8, ["chart", "onChartUpdated"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_ProgressSpinner)
    ], 512)
  ], 64))
}