import { FieldCode } from '@/models/filters';
import axios from 'axios';

const baseUrl: string = '/api/default-columns';
class DefaultColumnService {
  async get(userId: string): Promise<FieldCode[]> {
    if (!userId) {
      return [];
    }

    const response = await axios.get<FieldCode[]>(`${baseUrl}/${userId}`);
    return response.data;
  }

  async save(userId: string, defaultColumns: FieldCode[]): Promise<FieldCode[]> {
    const response = await axios.put<FieldCode[]>(`${baseUrl}`, { columns: defaultColumns, userId });
    return response.data;
  }
}

export default new DefaultColumnService();
