<template>
  <div class="brand-page-background">
    <img src="../../assets/Templeton_logo.png" class="brand-page-logo" alt="Templeton logo">
  </div>
</template>

<script>
export default {
  name: 'BackgroundLogo'
};
</script>

<style scoped>
.brand-page-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: transparent linear-gradient(206deg, #E5F9FAF2 0%, #D0E7FF78 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  z-index: -1;
  padding: 0;
  margin: 0;
}

.brand-page-logo {
  margin-top: 36px;
  margin-left: 42px;
  width: 175px;
  height: 70px;
}
</style>