import axios from 'axios';
import { FilterModel } from '@/models/search';
import { MostActiveReaderEngagements } from '@/models/analytics/mostActiveReaderEngagements';
import { filterModelParams, toActivityTypes } from '@/utils/filters/filterUtils';

const BASE_URL = '/api/most-active-readers';

export default {

    async getMostActiveReaderEngagements(filterModel: FilterModel): Promise<MostActiveReaderEngagements[]> {
        const at = toActivityTypes(filterModel);
        const mostActiveReaderEng = (await axios.post(`${BASE_URL}`, { ...filterModelParams(filterModel), ...{ activityTypes: at } })).data;
        return mostActiveReaderEng.map(x => new MostActiveReaderEngagements(x));
    }
};