import { Filter, FieldCode, FriendlyFilterRow } from '@/models/filters';
import { startCase } from 'lodash';

export class ExistsFilter implements Filter {
  field: FieldCode;
  value?: boolean | null;
  readonly $type = 'ExistsFilter';

  constructor(opts: Partial<ExistsFilter> = {}) {
    Object.assign(this, opts);
  }

  isEmpty() {
    return this.value === null || this.value === undefined;
  }

  getFriendlyText(labelOverride: string): FriendlyFilterRow {
    const label = `${labelOverride || startCase(this.field)}`;
    return <FriendlyFilterRow>{ filterName: label, operation: '', value: this.value ? 'Exists' : `Doesn't Exist` };
  }

  clearValue() {
    this.value = null;
  }
}
