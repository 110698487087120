<template>
  <div class="grid">
    <div class="col-12">
      <h1 class="line-height-1">
        Manage Users
      </h1>
    </div>
    <div class="mt-4 p-2 search-group grid">
      <div class="col-6">
        <h4>Search For Users</h4>
      </div>
      <div class="col-6 flex flex-row-reverse">
        <Button v-if="isAuthenticatedAdmin" label="New User" class="p-button-success p-button-raised" @click="createNewUser">
          New User
          <span class="material-icons pl-2"> person_add_alt_1 </span>
        </Button>
      </div>
      <div class="col-12 lg:col-3 mb-2">
        <span class="p-float-label">
          <InputText id="email" v-model="criteria.query" type="text" style="width: 100%" />
          <label for="email">Enter Email/Name</label>
        </span>
      </div>
      <div class="flex col-12 lg:col-9">
        <cp-companies-search-field v-if="isMounted" v-model="selectedCompanies" :submitted="submitted" />
        <Button class="ml-3 va-button-icon-only" @click="search">
          <span class="material-icons text-3xl text-white"> search
          </span>
        </Button>
      </div>
    </div>
    <div id="users-table" class="col-12 pt-4">
      <DataTable :value="data1.items" data-key="id" :paginator="true" :rows="criteria.pageSize"
        :rows-per-page-options="pageSizeOptions" :lazy="true" :total-records="data1.total"
        paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
        current-page-report-template="Showing {first} to {last} of {totalRecords}" paginator-position="both"
        paginatorstart="true" paginatorend="true" @page="onPage($event)">
        <Column>
          <template #body="slotProps">
            <Avatar class="va-avatar-initials font-medium" shape="circle" :class="getAvatarColor(slotProps.data)">
              <span class="pt-1"
                :class="{ 'text-white': slotProps.data.isRegisteredClient, 'p-avatar-text': !slotProps.data.isRegisteredClient }">{{ slotProps.data.initials }}</span>
            </Avatar>
            <!-- <pre>{{slotProps.data.roleColor}}</pre> -->
          </template>
        </Column>
        <Column>
          <template #body="slotProps">
            <router-link :to="'/admin/user/' + slotProps.data.id">
              <span>
                {{ slotProps.data.firstName + " " + slotProps.data.lastName }}
              </span>
            </router-link>
          </template>
        </Column>
        <Column field="email" />
        <Column field="role" />
        <Column>
          <template #body="slotProps">
            <Button v-if="slotProps.data.isRegistered && isAuthenticatedAtLeastInternal"
              class="p-button-sm p-button-rounded p-button-outlined w-10rem justify-content-center"
              @click="resetPasswordShow($event, slotProps.data.email)">
              Reset Password
            </Button>
            <Button v-if="!slotProps.data.isRegistered && isAuthenticatedAtLeastInternal"
              class="p-button-sm p-button-rounded p-button-outlined w-10rem justify-content-center"
              @click="resendRegistrationEmailShow($event, slotProps.data)">
              Resend Registration
            </Button>
          </template>
        </Column>
        <Column>
          <template #body="slotProps">
            <Button class="p-button-text" @click="removeUserShow($event, slotProps.data)">
              <img :src="require('../../assets/icons/trash.svg')" aria-haspopup="true" aria-controls="overlay_menu"
                width="16" alt="Delete User">
            </Button>
          </template>
        </Column>
        <template #empty>
          <div v-if="!inProgress" class="flex align-items-center justify-content-center py-5">
            <cp-no-datatable-records />
          </div>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script lang="ts">
import { AppUser } from '@/models/appUser';
import UserService from '@/services/userService';
import { DEFAULTS, SearchCriteria } from '@/services/searchCriteria';
import { PagedList } from '../../models/pagedList';
import CompanySearchField from '@/components/searchFields/companiesSearchField.vue';
import { TermsFilter } from '@/models/filters';
import { findFilter } from '@/utils/filters';
import userService from '@/services/userService';
import { getAvatarColorClass } from '@/utils/userUtils';
import NoDataTableRecordsComponent from '@/components/common/NoDataTableRecordsComponent.vue';
import { onError, onSuccess, onWarning } from '@/utils/toast/toastUtils';
import { ref } from 'vue';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { useRouter } from 'vue-router';
import useAuthenticatedUser from '@/use/authenticatedUser';
import useDefaultSearchFilters from '@/use/defaultSearchFilters';
import useSearchFilterSecurity from '@/use/searchFilterSecurity';
export default {
  name: 'CpUserList',
  components: {
    'cp-companies-search-field': CompanySearchField,
    'cp-no-datatable-records': NoDataTableRecordsComponent
  },
  setup() {
    const criteria = ref<SearchCriteria>({ page: 1, pageSize: DEFAULTS.PAGE_SIZE });
    const pageSizeOptions = DEFAULTS.PAGE_SIZE_OPTIONS;
    const data1 = ref<PagedList<AppUser>>({
      items: [],
      total: 0,
      totalPages: 0,
    });
    const selectedCompanies = ref(new TermsFilter({ field: 'Clients', value: [] }));
    const submitted = ref(false);
    const isMounted = ref(false);
    const inProgress = ref(false);

    const { isAuthenticatedAdmin, isAuthenticatedAtLeastInternal, userId, userActivitySearchFields } = useAuthenticatedUser();

    const confirm = useConfirm();
    const toast = useToast();
    const router = useRouter();

    const { searchFields, filterModel }= useSearchFilterSecurity(ref([]), ref([]), userActivitySearchFields, ref([]), ref({}));
    const { initialState } = useDefaultSearchFilters(userId, searchFields, filterModel, ref([]));

    const search = async () => {
      submitted.value = true;
      criteria.value.companyIds = selectedCompanies.value.value.map(x => x.id);
      inProgress.value = true;
      data1.value = await UserService.list(criteria.value);
      inProgress.value = false;
    };

    const removeUser = async (userId: string, email: string) => {
      try {
        var result = await userService.delete(userId);
        var msgPrefix = result?.data ? result.data : 'Deleted user ';
        var msg = `${msgPrefix}-${email}`;
        if (result.data) {
        onWarning(toast, msg, true);
      }
      else {
        onSuccess(toast, msg);
      }
        search();
      } catch (error: any) {
        var emsg = error + email;
        onError(toast, emsg);
      }
    };

    const removeUserShow = (event, user: AppUser) => {
      // console.debug(`UserList.removeUserShow()`, event, user);
      confirm.close();
      confirm.require({
        target: event.currentTarget,
        message: 'Are you sure you want to delete this user ?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Continue',
        rejectLabel: 'Cancel',
        accept: () => {
          removeUser(<string>user.id, user.email);
        },
        reject: () => {
          //callback to execute when user rejects the action
        }
      });
    };

    const resendRegistrationEmailApiCall = async (userId: string, email: string) => {
      try {
        await UserService.resendRegistrationEmail(<string>userId);
        const msg = `The registration email has been resent to ${email}`;
        onSuccess(toast, msg);
      } catch (error: any) {
        // this.showEmailModal = false;
        onError(toast, 'There was a error on resending the email.');
      }
    };

    const resendRegistrationEmailShow = (event: any, user: AppUser) => {
      // console.debug(`UserList.resendRegistrationEmailShow()`, event, user);
      confirm.close();
      confirm.require({
        target: event.currentTarget,
        message: 'Are you sure you want to resend the registration email?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Continue',
        rejectLabel: 'Cancel',
        accept: () => {
          resendRegistrationEmailApiCall(<string>user.id, user.email);
        },
        reject: () => {
          //callback to execute when user rejects the action
        }
      });
    };

    const resetPasswordApiCall = async (email: string) => {
      try {
        await UserService.triggerPasswordReset(email);
        const msg = `A reset password link has been sent to ${email}`;
        onSuccess(toast, msg);
      } catch (error) {
        onError(toast, 'There was an error resetting password.');
        // NOTE: Error thrown to be detected by Vue.config.errorHandler
        throw error;
      }
    };

    const resetPasswordShow = (event, email: string) => {
      // console.debug(`UserList.resetPasswordShow()`, event, email);
      confirm.close();
      confirm.require({
        target: event.currentTarget,
        message: `Are you sure you want to reset this user's password?`,
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Continue',
        rejectLabel: 'Cancel',
        accept: () => {
          resetPasswordApiCall(email);
        },
        reject: () => {
          //callback to execute when user rejects the action
        }
      });
    };

    const onPage = (event) => {
      criteria.value.page = event.page + 1;
      criteria.value.pageSize = event.rows;
      search();
    };

    const getAvatarColor = (user: AppUser) => getAvatarColorClass(user.role, user.isRegistered);

    const createNewUser = () => {
      router.push('/admin/user/');
    };

    selectedCompanies.value = findFilter<TermsFilter>(initialState.value.filters, 'Clients') ??
      new TermsFilter({ field: 'Clients', value: [] });
    search();
    isMounted.value = true;

    return {
      criteria, data1, isAuthenticatedAdmin, isAuthenticatedAtLeastInternal, inProgress, pageSizeOptions, selectedCompanies, submitted, isMounted,
      createNewUser, getAvatarColor, onPage, removeUserShow, resendRegistrationEmailShow, resetPasswordShow, search
    };
  }
};
</script>

<style lang="scss" scoped>
.email {
  margin-top: -13px;
}

.pi-trash:hover {
  color: red;
}

// Primevue Avatar
.p-avatar {
  &.two-char-avatar {
    font-size: 0.875rem;
    width: 36px;
    height: 36px;
  }
}

a:hover {
  text-decoration: none;
}
</style>
