<template>
  <div class="bg-white">
    <Dialog id="chartColorDialog"
      v-model:visible="dialogModel"
      :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
      :style="{ width: '40vw' }"
      :closable="false"
      :modal="true">
      <template #header>
        <div class="flex col-12 pb-0">
          <div class="col-11 pb-0">
            <h3 class="p-secondary-color">
              Configure chart colors
            </h3>
          </div>
          <div class="col-1 absolute right-0 pb-0 p-secondary-color">
            <i class="pi pi-times reverse p-secondary-color"
              aria-haspopup="true"
              aria-controls="overlay_menu"
              @click="dialogClose" />
          </div>
        </div>
      </template>
      <div class="col-11 pb-2 pt-0">
        <Message :closable="false">
          Click on a color thumbnail to modify
        </Message>
      </div>
      <div v-for="(s, index) in series"
        :key="index"
        class="lg:flex col-12 bg-white p-0">
        <div class="p-secondary-color col-12 md:col-12 lg:col-7">
          {{ s.name }}
        </div>
        <div v-if="s.color.stops != undefined"
          class="col-12 md:col-12 lg:col-5 pt-0">
          <cp-color-picker :model-value="s.color.stops[0][1]"
            @input="getUpdatedColor(s.name, $event)" />
        </div>
        <div v-if="s.color.stops == undefined"
          class="col-12 md:col-12 lg:col-5 pt-0">
          <cp-color-picker :model-value="s.color"
            @input="getUpdatedColor(s.name, $event)" />
        </div>
      </div>
      <div class="flex col-12 pb-0">
        <div class="col-9 pb-0">
          <Button type="button"
            class="p-button-outlined p-button-rounded"
            label="Cancel"
            style="float:right"
            @click="dialogClose()" />
        </div>
        <div class="col-3 pb-0">
          <Button type="button"
            class="p-button-outlined p-button-rounded"
            label="Apply"
            @click="applyColors()" />
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script lang="ts">
import {  Prop } from 'vue-property-decorator';
import ColorPickerComponent from '@/components/common/ColorPicker.vue';
import { Chart } from 'highcharts';
import { Vue, Options } from 'vue-class-component';

@Options({
  components: {
    'cp-color-picker': ColorPickerComponent
  }
})

export default class VChangeChartColorComponent extends Vue {
  @Prop() series: any;
  @Prop() dialogModel: boolean;
  @Prop() chart: Chart;
  chartModel: { [key: string]: string } = {};
  chartSeries: any = [];

  getUpdatedColor(fieldName: string, colorValue: string) {
    this.chartModel[fieldName] = '#' + colorValue;
  }

  dialogClose() {
    this.$emit('modalClosed', false);
  }

  applyColors() {
    // console.log(this.chartModel);
    Object.entries(this.chartModel).forEach(([name, color]) => {
      const s = this.series.find((x: any) => x.name === name) || {};
      s.color = color;
    });
    this.getSeries();
    if (this.chart !== null) {
      this.chart.update({ series: this.chartSeries });
      if (this.chart.options.chart?.type === 'pie') {
        this.chart.series[0].setData([...this.chartSeries]);
      } else {
        this.chart.update({ series: this.chartSeries });
      }
    }
    this.dialogClose();
  }

  getSeries() {
    this.chartSeries = [];
    this.series.map((item: any) => {
      this.chartSeries.push({ y: item.y, name: item.name, color: item.color });
    }
    );
  }

}
</script>

<style scoped>
</style>
