import { IntLookup } from '@/models/lookup';
import { zip } from 'lodash';

export enum Quality {
  Level1 = 1,
  Level2 = 2,
  Level3 = 3
}

export const DISSEMINATION_QUALITY_LOOKUPS: IntLookup[] = [
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 4, name: '3' }
];

export const QUALITY_LOOKUPS: IntLookup[] = [
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' }
];

export function disseminationToEngagementQuality(quality: number) {
  const dQualities = DISSEMINATION_QUALITY_LOOKUPS.map(x => x.id);
  const eQualities = QUALITY_LOOKUPS.map(x => x.id);

  return zip(dQualities, eQualities)
    .filter(x => x[0] && (x[0] & quality) === x[0])
    .map(x => x[1]);
}
