<template>
  <div class="flex col-12 justify-content-between">
    <h2 id="engagements-over-time">
      <Dropdown v-model="category" :options="categories" option-label="name" class="p-dropdown-noborder"
        @change="changeCategory()">
        <template #value="slotProps">
          <h2>{{ slotProps.value.name }}</h2>
        </template>
      </Dropdown>
      {{ showTitle }}
    </h2>
    <div class="pt-2">
      <cp-export-chart-options :chart="chartExport" @chart-updated="chartUpdated" />
    </div>
  </div>

  <div ref="chart" cp-color-picker class="flex align-items-center pt-3" style="min-height: 300px">
    <ProgressSpinner />
  </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import { Chart } from 'highcharts';
import { groupBy, flatten, Dictionary } from 'lodash';
import ClientDashboardService, {
  ActivityCountValue,
} from '@/services/clientDashboardService';
import {
  getStackedColumnChartConfig,
  getStackedBarChartXAxis,
  getColumnGradientPerBlockSubsequent,
  getColumnGradientPerBlock,
  getOrderedDataByYear,
  StackedBarOptions,
  setDefaults, getStackedColumnChartNoDataOptions,
} from '@/utils/highchartUtils';
import { IntervalBucket } from '@/models/intervalBucket';
import ExportChartOptionsComponent from '../common/ExportChartOptions.vue';
import {dateFromRelativeDateRange, DateRangeFilter, RelativeDateRange, RelativeDateRangeFilter} from '@/models/filters';
import { filterValueExists, findFilter } from '@/utils/filters';
import { FilterModel } from '@/models/search';
import { Vue, Options } from 'vue-class-component';
import { Lookup } from '@/models/lookup';
import * as Highcharts from 'highcharts';
import { renderColumnChart } from '@/plugins/highchart-extend';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
NoDataToDisplay(Highcharts);

@Options({
  components: {
    'cp-export-chart-options': ExportChartOptionsComponent,
  },
})
export default class EngagmentsByMonth extends Vue {
  @Prop({ default: () => <FilterModel>{ filters: [], indices: [] } })
  public filterModel: FilterModel;
  declare $refs: { chart: HTMLDivElement };
  chartExport: Chart | null = null;
  metrics: Array<IntervalBucket<ActivityCountValue>> = [];
  loading = true;
  series: any;
  showTitle: string = '';

  categories: Array<Lookup<any>> = [
    { id: 'all', name: 'All' },
    { id: 'unique', name: 'Unique' },
  ];
  category: Lookup<any> = this.categories[0];

  @Watch('filterModel', { immediate: true })
  async onFilterChange(filterModel: FilterModel) {
    if (
      !filterModel.filters?.length ||
      !filterValueExists(filterModel.filters, 'Clients')
    ) {
      return;
    }
    this.loading = true;
    this.metrics = (this.category.id == 'unique' ? true : false)
      ? await ClientDashboardService.getUniqueByMonth(this.filterModel)
      : await ClientDashboardService.getByMonth(this.filterModel);
    this.loading = false;
    if (
      this.metrics.filter((x) => x.value.primary == 0 && x.value.secondary == 0).length ==
      this.metrics.length
    ) {
      this.metrics = [];
    }
    this._renderChart();
  }

  async changeCategory() {
    this.loading = true;
    this.metrics = (this.category.id == 'unique' ? true : false)
      ? await ClientDashboardService.getUniqueByMonth(this.filterModel)
      : await ClientDashboardService.getByMonth(this.filterModel);
    if (
      this.metrics.filter((x) => x.value.primary != 0 && x.value.secondary != 0).length ==
      0
    ) {
      this.metrics = [];
    }

    this.loading = false;
    this._renderChart();
  }

  chartUpdated(value: Chart) {
    this.chartExport = value;
    this.showTitle = (this.chartExport as any).options.exporting.chartOptions.title.text;
    this.$forceUpdate();
  }

  get title() {
    this.showTitle = 'Engagements Over Time';
    return this.showTitle;
  }

  private _renderChart() {
    setDefaults();
    const byYear: Dictionary<IntervalBucket<ActivityCountValue>[]> = groupBy<
      IntervalBucket<ActivityCountValue>
    >(this.metrics, (m) => m.key.substring(0, 4));
    const dtFilter = findFilter<DateRangeFilter>(this.filterModel.filters, 'DateRange');
    const relativeDateRangeFilter = findFilter<RelativeDateRangeFilter>(this.filterModel.filters, 'RelativeDateRange');

    // Re-arrange the months to match the order of the filter's date range e.g. Jun, Jul, Aug, Sep, Oct, Nov, Dec, Jan, Feb, Mar, Apr, May
    const from = relativeDateRangeFilter?.value && relativeDateRangeFilter.value != RelativeDateRange.Custom
      ? dateFromRelativeDateRange(relativeDateRangeFilter.value)
      : dtFilter.value.from;
    const xAxisLegend: string[] = getStackedBarChartXAxis(from);

    // Re-arrange data to match the order of the filter's date range
    const orderedDataByYear: Record<string, any> = getOrderedDataByYear(
      byYear,
      xAxisLegend
    );
    this.series = this._getSeriesData(orderedDataByYear);

    const options: StackedBarOptions = {
      xAxisLegend,
      yAxisTitle: 'Number of Engagements',
      exportTitle: this.title,
      series: this.series,
    };

    console.debug(`EngagementsByMonth._renderChart() - { options }`, options);
    this.chartExport = (Highcharts as any).chart(
      this.$refs.chart, this.metrics.length > 1 ?
      getStackedColumnChartConfig(options) : getStackedColumnChartNoDataOptions(options), (chartInstance) => {
        if (this.metrics.length < 1) {
          renderColumnChart(chartInstance, getStackedColumnChartNoDataOptions(options).lang.noDataImage);
        }

      }
    );
  }

  private _getSeriesData(orderedDataByYear: Record<string, any>) {
    return flatten(
      Object.keys(orderedDataByYear).map<any>((year, i) => [
        {
          name: `${year} Subsequent Engagements`,
          stack: year,
          color: getColumnGradientPerBlockSubsequent()[i],
          data: orderedDataByYear[year].map((m: any) => ({
            y: m.value.secondary === 0 ? null : m.value.secondary,
            name: m.key,
          })),
        },
        {
          name: `${year} Initial Engagements`,
          color: getColumnGradientPerBlock()[i],
          stack: year,
          data: orderedDataByYear[year].map((m: any) => ({
            y: m.value.primary === 0 ? null : m.value.primary,
            name: m.key,
          })),
        },
      ])
    );
  }
}
</script>

<style scoped lang="scss">

</style>
