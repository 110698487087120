import axios from 'axios';
import { Cacheable } from '@/utils/decorators/cacheable.decorator';
import { Lookup, Online } from '@/models/lookup';
import { Country } from '@/models/country';
import { uniq } from 'lodash';

class CommonLookupService {

  @Cacheable()
  getCountries(): Promise<Country[]> {
    return axios
      .get('/api/common-lookup/countries')
      .then(x => x.data.map((c: any) => ({ id: c.code, name: c.name, region: c.region })));
  }

  @Cacheable()
  getStates(): Promise<Array<Lookup<string>>> {
    return axios
      .get('/api/common-lookup/states')
      .then(x => x.data.map((c: any) => ({ id: c.code, name: c.name })));
  }

  @Cacheable()
  getNewsletters(): Promise<Array<Lookup<string>>> {
    return axios
      .get('/api/common-lookup/newsletters')
      .then(x => x.data.map((c: any) => ({ id: c.publication, name: c.newsletterName })));
  }
  @Cacheable()
  getOnlines(): Promise<Online[]> {
    return axios
      .get('/api/common-lookup/onlines')
      .then(x => x.data.map((o: any) => ({ id: o.code, name: o.name, businessGroup: o.businessGroup?.code })));
  }

  @Cacheable()
  async getRegions(): Promise<Array<Lookup<string>>> {
    const countries = await this.getCountries();
    return uniq(countries.map(x => x.region)).filter(x => x).map(x => ({ id: <string>x, name: x }));
  }

  @Cacheable()
  async getBusinessGroups(): Promise<Array<Lookup<string>>> {
    return await axios
      .get('/api/common-lookup/businessgroups')
      .then(x => x.data.map((o: any) => ({ id: o.code, name: o.name })));
  }
}

export default new CommonLookupService();
