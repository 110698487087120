<template>
  <div class="grid pt-3 pb-0 vertical-align-middle">
    <label for="no"
      class="text-sm font-bold mx-2">{{ defaultLabel }}</label>
    <div class="mr-5">
      <RadioButton id="none"
        name="city"
        value="NONE"
        :model-value="local.value"
        class="mr-2"
        @click="updateField('value', 'NONE')" />
      <label for="none"
        class="text-sm font-bold">{{ noSelectionLabel }}</label>
    </div>
    <div class="mr-5">
      <RadioButton id="yes"
        name="city"
        value="true"
        :model-value="local.value"
        class="mr-2"
        @click="updateField('value', 'true')" />
      <label for="yes"
        class="text-sm font-bold">Yes</label>
    </div>
    <div class="">
      <RadioButton id="no"
        name="city"
        value="false"
        :model-value="local.value"
        class="mr-2"
        @click="updateField('value', 'false')" />
      <label for="no"
        class="text-sm font-bold">No</label>
    </div>
  </div>
</template>

<script lang="ts">
import { Prop } from 'vue-property-decorator';
import { BoolFilter, toLabel } from '@/models/filters';
import { Vue, Options } from 'vue-class-component';

const NONE = 'NONE';
const DEFAULT = new BoolFilter({ value: null });

@Options({
  name: 'cp-bool-search-field',
})
export default class BoolSearchField extends Vue {
  @Prop() modelValue: BoolFilter;
  @Prop() label: string;
  @Prop({ default: 'Both' }) noSelectionLabel: string;
  @Prop({ default: true }) dense: boolean;

  created() {
    const b = new BoolFilter({ ...this.local });
    b.value = <any>b.value === NONE ? null : <any>b.value === 'true';
    this.$emit('update:modelValue', b);
  }

  get local(): BoolFilter {
    const f = new BoolFilter({ ...DEFAULT, ...this.modelValue });
    f.value = <any>(
      (this.modelValue.value === undefined || this.modelValue.value === null
        ? NONE
        : this.modelValue.value.toString())
    );
    return f;
  }

  get defaultLabel(): string {
    return this.label || toLabel(this.modelValue.field);
  }

  public updateField(name: keyof BoolFilter, value: any) {
    // console.log(value);
    value = value === NONE ? null : value === 'true';
    const newVal = new BoolFilter({ ...this.local, [name]: value });
    this.$emit('update:modelValue', newVal);
  }
}
</script>

<style lang="scss" scoped>
.bool-container {
  margin-top: 0;
  padding-top: 0;
}
</style>
