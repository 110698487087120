import { sanitizeUrlParam } from '@/utils/urlUtils';
import { fullAddress } from '@/utils/address/addressUtils';
import { Address } from './address';

export class ReaderInfo {
  id: string;
  firstName: string;
  lastName: string;
  company: string;
  orgName: string;
  email: string;
  linkedIn?: string;
  jobTitle: string;
  address: Address;
  clientConsent: boolean;
  clientConsentUpdatedDate: Date;
  readerOrgId: string;

  constructor(fields: Partial<ReaderInfo>) {
    Object.assign(this, fields);
  }

  get readerCompanyUrl() {
    const encodedCompanyName = sanitizeUrlParam(this.company);
    const route = `/analytics/reader-co-dashboard/${encodedCompanyName}`;
    return this.readerOrgId ? `${route}/${this.readerOrgId}/${this.company}` : route;
  }

  get formattedAddr(): string {
    return fullAddress(this.address);
  }
}
