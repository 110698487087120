<template>
  <Card :class="value == 0 ? ['p-card no-results-card'] : 'p-count-card'">
    <template #content>
      <ProgressSpinner v-if="loading" class="flex align-items-center justify-content-center" />
      <cp-animated-integer v-show="value !== 0 && loading == false" :value="value" class="company-count pt-4" />
      <cp-no-data-component v-if="value === 0 && loading == false" :chart-type="'widget'" />
      <div :class="value !== 0 ? 'text-center pt-0' : 'sub-no-data'">
        {{ text }}
      </div>
    </template>
  </Card>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import ClientDashboardService from '@/services/clientDashboardService';
import AnimatedIntegerComponent from '@/components/common/animatedInteger.component';
import { StatType } from '@/models/analytics/statType';
import { filterValueExists } from '@/utils/filters';
import { FilterModel } from '@/models/search/filterModel.interface';
import { Vue, Options } from 'vue-class-component';
import NoChartDataComponent from '@/components/common/NoChartDataComponent.vue';

@Options({
  components: {
    'cp-animated-integer': AnimatedIntegerComponent,
    'cp-no-data-component': NoChartDataComponent,
  },
})
export default class EngagementsStatCard extends Vue {
  @Prop({ default: () => <FilterModel>{ filters: [], indices: [] } })
  public filterModel: FilterModel;
  @Prop({ default: 'engagements' }) public stat!: StatType;

  text = '';
  icon = '';
  iconBackground = 'white';
  value = 0;
  loading = true;

  @Watch('filterModel', { immediate: true })
  async onFilterChange(filterModel: FilterModel) {
    if (
      !filterModel.filters?.length ||
      !filterValueExists(filterModel.filters, 'Clients')
    ) {
      return;
    }

    this.loading = true;
    const stats = await ClientDashboardService.getStats(this.filterModel);

    switch (this.stat) {
      case 'engagements':
        this.text = 'Engagements with your content';
        this.icon = 'recent_actors';
        this.iconBackground = 'error';
        this.value = stats.totalLeads || 0;
        break;
      case 'users':
        this.text = 'Unique users engaging with your content';
        this.icon = 'person';
        this.iconBackground = 'info';
        this.value = stats.uniquePeople || 0;
        break;
      default:
        this.text = 'Pieces of unique content with engagement';
        this.icon = 'description';
        this.iconBackground = 'warning';
        this.value = stats.uniqueDocs || 0;
        break;
    }

    this.loading = false;
  }
}
</script>

<style scoped lang="scss">
.subheading-container {
  text-align: left;
}

.stat-number {
  font-size: 75px;
  font-weight: bold;
}

.sub-no-data {
  opacity: 1;
  color: #76869A;
  font-family: Overpass, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  text-align: center;
}
</style>
