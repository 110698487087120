export class MostActiveCosStats {
    orgsCount: number;
    usersCount: number;
    initialActivitiesCount: number;
    subsequentActivitiesCount: number;
    activitiesCount: number;

    constructor(data: Partial<MostActiveCosStats> | null = null) {
        if (data) {
            Object.assign(this, data);
            this.activitiesCount = this.initialActivitiesCount + this.subsequentActivitiesCount;
        }
    }
}
