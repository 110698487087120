<template>
  <div class="flex justify-content-between">
    <h2>
      {{ titleTrim() }}
    </h2>
    <div class="pt-2">
      <cp-export-chart-options :chart="chartExport" @chart-updated="chartUpdated" />
    </div>
  </div>
  <div ref="chart" cp-color-picker class="flex align-items-center pt-3" style="min-height: 300px">
    <ProgressSpinner />
  </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import { Chart } from 'highcharts';
import ContentOverViewDashboardService, {
  ContentDataRequest,
  DocsByBuyersJourneyAndAge,
} from '@/services/contentOverviewDashboardService';
import {
  getPieGradientPerSliceBuyerJourneyStage,
  getStackedColumnChartConfig, getStackedColumnChartNoDataOptions,
  StackedBarOptions,
} from '@/utils/highchartUtils';
import ExportChartOptionsComponent from '../common/ExportChartOptions.vue';
import { isEqual, uniq } from 'lodash';
import { DateRangeFilter, TermsFilter } from '@/models/filters';
import { filterValueExists, findFilter } from '@/utils/filters';
import dayjs from 'dayjs';
import { Options, Vue } from 'vue-class-component';
import { FilterModel } from '@/models/search';
import { NamedSeries } from '@/models/namedSeries';
import { renderColumnChart } from '@/plugins/highchart-extend';
import * as Highcharts from 'highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
NoDataToDisplay(Highcharts);

@Options({
  components: {
    'cp-export-chart-options': ExportChartOptionsComponent,
  },
})
export default class BuyersJourneyByDocumentAge extends Vue {
  @Prop({ default: () => <FilterModel>{ filters: [], indices: [] } })
  public filterModel: FilterModel;
  @Prop({ default: 'Untitled' }) label: string;
  @Prop({ required: true }) segment: 'type' | 'buyers-journey';
  @Prop({ default: 25 }) public size: number;
  declare $refs: { chart: HTMLDivElement };
  chartExport: Chart | null = null;
  metrics: NamedSeries[] = [];
  loading = true;
  dataSource: DocsByBuyersJourneyAndAge[];
  declare initialState: FilterModel;
  categories: string[] = [];
  series: any = [];
  showTitle: string = '';
  nameUpdate: boolean = false;

  @Watch('filterModel', { immediate: true })
  async filtersChanged(filterModel: FilterModel, oldVal: FilterModel) {
    const noChanges = isEqual(filterModel, oldVal);
    if (
      !filterModel.filters?.length ||
      !filterValueExists(filterModel.filters, 'Clients') ||
      noChanges
    ) {
      return;
    }
    this.loading = true;

    const dateVal = findFilter<DateRangeFilter>(this.filterModel.filters, 'DateRange')
      ?.value;
    const req: ContentDataRequest = {
      companyIds: findFilter<TermsFilter>(this.filterModel.filters, 'Clients')?.value.map(
        (x) => x.id
      ),
      from: dayjs(dateVal.from).format('YYYY-MM-DD'),
      to: dayjs(dateVal.to).format('YYYY-MM-DD'),
    };

    this.dataSource = await ContentOverViewDashboardService.getDocCountByBuyersJourneyAndAge(
      req
    );
    this.categories = uniq(this.dataSource.map(x => x.docAge));
    this.loading = false;
    this.renderChart();
  }

  getSeriesData(dataSource: DocsByBuyersJourneyAndAge[]) {
    const sortedDataSource = [...dataSource].sort((a, b) => this.sortByStage(a, b));
    const stages = { 'Early': new Array(this.categories.length).fill(0),
                    'Middle': new Array(this.categories.length).fill(0),
                    'Late': new Array(this.categories.length).fill(0) };
    sortedDataSource.forEach((current) => this.addDataToStage(current, stages));
    return Object.entries(stages).map(this.createSeriesEntry);
  }

  sortByStage(a: DocsByBuyersJourneyAndAge, b: DocsByBuyersJourneyAndAge) {
    const order = { 'Early': 1, 'Middle': 2, 'Late': 3 };
    return order[a.stage] - order[b.stage];
  }

  addDataToStage(current: DocsByBuyersJourneyAndAge, stages: { [key: string]: number[] }) {
    const { count, stage, docAge } = current;
    const ageIndex = this.categories.indexOf(docAge);
    if (ageIndex !== -1) {
      stages[stage][ageIndex] += count;
    }
  }

  createSeriesEntry([name, data]: [string, number[]]) {
    return {
      name,      color: getPieGradientPerSliceBuyerJourneyStage(name),
      data
    };
  }

  private renderChart() {
    const xAxisLegend = this.categories;
    const yAxisTitle = 'Number of documents';
    this.series = this.getSeriesData(this.dataSource);
    const options: StackedBarOptions = <StackedBarOptions>{ xAxisLegend, yAxisTitle, exportTitle: this.label, series: this.series };
    this.chartExport = (Highcharts as any).chart(this.$refs.chart, (this.series.length > 0) ? {
      ...getStackedColumnChartConfig(options),
      // Enables yAxis lines
      yAxis: {
        min: 0,
        title: {
          text: yAxisTitle,
        },
        stackLabels: {
          enabled: false,
          style: {
            fontWeight: 'bold',
            textOutline: 'none',
          },
        },
      },
      // Enables tool custom tooltip text
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
      }
    } : getStackedColumnChartNoDataOptions(options), (chartInstance) => {

      if (this.series.length < 1) {
        renderColumnChart(chartInstance, getStackedColumnChartNoDataOptions(options).lang.noDataImage);
      }
    });
  }

  chartUpdated(value: Chart) {
    this.chartExport = value;
    this.nameUpdate = true;
    this.showTitle = (this.chartExport as any).options.exporting.chartOptions.title.text;
    this.$forceUpdate();
  }

  titleTrim() {
    if (!this.showTitle) {
      this.showTitle = this.label;
    }
    else if (this.nameUpdate) {
      this.showTitle = this.showTitle.length > 42
        ? this.showTitle.substring(0, 42) + '...  '
        : this.showTitle;
      this.nameUpdate = false;
    }
    return this.showTitle;
  }

}
</script>
<style scoped lang="scss">
.chart-label {
  text-align: left;
  font: normal normal bold 24px/30px Overpass;
  letter-spacing: 0px;
  color: #1b3557;
  opacity: 1;
}

.highcharts-container {
  height: auto;
}
</style>
