import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3409a55e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-content-between" }
const _hoisted_2 = { class: "pt-2" }
const _hoisted_3 = {
  ref: "chart",
  "cp-color-picker": "",
  class: "flex align-items-center pt-3",
  style: {"min-height":"300px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cp_export_chart_options = _resolveComponent("cp-export-chart-options")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.titleTrim()), 1),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_cp_export_chart_options, {
          chart: _ctx.chartExport,
          onChartUpdated: _ctx.chartUpdated
        }, null, 8, ["chart", "onChartUpdated"])
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_ProgressSpinner)
    ], 512)
  ], 64))
}