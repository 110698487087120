import { getColor } from '@/models/common/activityFieldAccessLevel';
import { ExportColumnNode, ActivityField, ActivityFieldNode } from '@/models/search';
import { RESTRICTED_FIELDS } from '@/models/filters';
import { ActivityFieldCategoryEnum } from '../searchFields/metadata';

export function getActivityFieldNode(c: ActivityField, cat: ActivityFieldCategoryEnum): ActivityFieldNode {
  return {
    label: c.label,
    key: c.field,
    category: cat,
    accessLevel: c.accessLevel,
    color: getColor(c.accessLevel),
    isActive: c.isActive,
    isRestricted: RESTRICTED_FIELDS.includes(c.field)
  };
}

export function getExportColumnNode(c: ActivityField, cat: ActivityFieldCategoryEnum, isReadOnly: boolean): ExportColumnNode {
  return {
    label: c.label,
    key: c.field,
    category: cat,
    accessLevel: c.accessLevel,
    color: getColor(c.accessLevel),
    isReadOnly
  };
}
