import { IntLookup } from '../lookup';

export enum InclusionCondition {
  All = 1,
  Any
}

export const INCLUSION_CONDITION_LIST_ITEMS: IntLookup[] = [
  {id: 1, name: 'All'},
  {id: 2, name: 'Any'}
];
