import axios, { AxiosRequestConfig } from 'axios';
import { PageableFilterCriteria } from '@/models/search/pageableFilterCriteria';
import { ExportFromSearchRequest } from '@/models/search';
import { FieldCode } from '@/models/filters';

const EXCEL_MIME = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

const baseUrl: string = '/api/analytics/';

class ExportService {

  async exportSelected(ids: string[], exportColumns: FieldCode[], aggregate = true): Promise<Blob> {
    const data = { itemIds: ids, aggregateSubsequents: aggregate, columns: exportColumns };
    const opts = { responseType: 'blob' } as AxiosRequestConfig;
    const response = await axios.post(`${baseUrl}export-selection`, data, opts);
    return new Blob([response.data], { type: EXCEL_MIME });
  }

  async exportFromSearch(criteria: PageableFilterCriteria, exportColumns: FieldCode[], aggregate = true): Promise<Blob> {
    const data: ExportFromSearchRequest = {
      filters: criteria.filterModel.filters,
      indices: criteria.filterModel.indices.map(x => x.id),
      aggregateSubsequents: aggregate,
      columns: exportColumns
    };
    const opts = { responseType: 'blob' } as AxiosRequestConfig;
    const response = await axios.post(`${baseUrl}export`, data, opts);

    return new Blob([response.data], { type: EXCEL_MIME });
  }

}

export default new ExportService();
