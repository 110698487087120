<template>
  <div class="mx-5">
    <div class="grid mt-5">
      <div class="col-fixed">
        <Card class="p-reader-card px-2" :style="readerBgColor">
          <template #subtitle>
            <div class="flex">
              <div class="col-6">
                READER PROFILE
              </div>
              <div v-if="activity.consentToPrivacyDate" class="flex flex-row-reverse col-6">
                PRIVACY CONSENT: {{ clientConsentDate }}
              </div>
            </div>
          </template>
          <template #content>
            <div class="flex py-0">
              <div class="flex align-items-center">
                <span class="material-icons text-7xl">account_circle</span>
              </div>
              <div class="pl-3">
                <div class="p-card-title capitalize underline">
                  <router-link :to="`/analytics/reader-dashboard/${activity.emailUrl}`" class="text-white opacity-100">
                    {{ (activity.firstName || "") + " " + (activity.lastName || "") }}
                  </router-link>
                </div>
                <div class="capitalize">
                  {{ activity.jobTitle + ", " }}
                  <router-link :to="activity.readerCompanyUrl" class="text-white opacity-100 font-medium">
                    {{ activity.company }}
                  </router-link>
                </div>
              </div>
              <div class="col flex flex-row-reverse ml-2">
                <a v-if="fullLinkedInUrl" :href="fullLinkedInUrl" target="_blank"
                  class="p-primary-color opacity-100 font-medium">
                  <i class="pi pi-linkedin" style="color: white" />
                </a>
              </div>
            </div>
            <div class="flex mt-4">
              <div>
                <div class="underline">
                  <a :href="'mailto:' + activity.email" class="text-white opacity-100 font-medium">
                    {{ activity.email }}
                  </a>
                </div>
              </div>
              <div class="col flex flex-row-reverse">
                <span class="material-icons"> email </span>
              </div>
            </div>
            <div class="flex m-0 pt-2">
              <div class="capitalize">
                {{ activity.formattedAddr }}
              </div>
              <div class="col flex flex-row-reverse">
                <span class="material-icons"> place </span>
              </div>
            </div>
            <div class="flex m-0 pt-2">
              <div>
                <div>{{ activity.phone }}</div>
              </div>
              <div class="col flex flex-row-reverse">
                <span class="material-icons"> phone </span>
              </div>
            </div>
          </template>
        </Card>
      </div>
      <div class="col flex">
        <Card class="p-white-text-with-bg-color-card px-2 col-12" :style="notesBgColor">
          <template #content>
            <div class="flex justify-content-between">
              <div class="flex">
                <img :src="activityImagePath" :alt="activityTypeName" width="33" height="24">
                <span class="pl-3 p-card-title flex align-items-center">{{ `${activityTypeName} Notes` }}</span>
              </div>
              <div class="flex align-items-center">
                <img :src="onlineLogoPath" :alt="activity.portal" class="online-logo">
              </div>
            </div>
            <div v-if="!activity.specifics && !activity.specificsModel">
              {{ noSpecificsMessageByActivityType[activity.activityType] }}
            </div>
            <div v-if="activity.specifics || activity.specificsModel">
              <div class="mt-1 activity-date">
                {{ dayjs(activity.date).format('MM/DD/YYYY') }}
              </div>
              <div class="my-2 specifics-text flex align-items-center">
                <p>{{ specificsText }}</p>
              </div>
              <div v-if="showExpand" class="expand-note flex align-items-center"
                @click="displayDialogSpecifics = !displayDialogSpecifics">
                <i class="material-icons-outlined">add_box</i>
                <span class="underline pl-2">Expand All Details</span>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>

    <div v-if="activity.docId" class="mt-4 p-0">
      <Card class="content-consumed-card px-2 standard-font-color-link">
        <template #content>
          <div class="flex align-items-center pb-2">
            <i class="ci-open-document" />
            <div class="pl-2 pt-1">
              <span class="font-bold">Content Consumed:&nbsp;</span>
              <a :href="activity.portalUrl" :title="activity.docTitle" target="_blank">{{ activity.docTitle }}</a>
            </div>
          </div>
        </template>
      </Card>
    </div>

    <div v-if="activity.otherActivitiesForReaderCount || activity.otherReadersCount" class="mt-4 p-0">
      <Card class="insights-card px-2 standard-font-color-link">
        <template #content>
          <div class="flex align-items-center">
            <i class="ci-account-star" />
            <div class="pl-2 pt-1">
              <span class="font-bold">Related insights</span>
            </div>
          </div>
          <div class="pl-5 mt-2 font-bold pb-2">
            <ul>
              <li v-if="activity.otherActivitiesForReaderCount" class="pt-2">
                <router-link :to="`/analytics/reader-dashboard/${activity.emailUrl}`">
                  {{ activity.firstName }}
                </router-link>
                has {{ activity.otherActivitiesForReaderCount }} other activities recorded with your content in the
                last 6 months.
              </li>
              <li v-if="activity.otherReadersCount" class="pt-2">
                {{ activity.otherReadersCount }} other reader(s) from
                <router-link :to="activity.readerCompanyUrl">
                  {{ activity.company }}
                </router-link>
                also consumed your content in the past 6 months.
              </li>
            </ul>
          </div>
        </template>
      </Card>
    </div>

    <h4 class="mt-4">
      General Info:
    </h4>
    <div class="grid mt-4">
      <div class="col general-info-item-container flex align-items-center standard-font-color-link">
        <span>Reader Company:</span>
        <span class="pl-3">
          <router-link :to="activity.readerCompanyUrl">{{ activity.company }}</router-link>
        </span>
      </div>
      <div class="col general-info-item-container ml-3 flex align-items-center">
        <span>Document Type:</span>
        <span class="pl-3">{{ activity.docType || "N/A" }}</span>
      </div>
    </div>
    <div class="grid mt-2">
      <div class="col flex align-items-center">
        <span>Activity Type:</span>
        <span class="pl-3">{{ activityTypeName }}</span>
      </div>
      <div class="col ml-3 flex align-items-center">
        <span>Client:</span>
        <span class="pl-3">{{ activity.advertiser }}</span>
      </div>
    </div>
    <div class="grid mt-2">
      <div class="col general-info-item-container flex align-items-center">
        <span>Newsletter:</span>
        <span class="pl-3">{{ activity.newsletterName || "N/A" }}</span>
      </div>
      <div class="col general-info-item-container ml-3 flex align-items-center">
        <span>Quality</span>
        <span class="pl-3 flex align-items-center">
          <img :src="activity.qualityBadge?.icon" :alt="activity.quality" width="30">
        </span>
        <span class="pl-3 flex align-items-center">Buyer's Journey</span>
        <span v-if="activity.buyersJourneyName" class="pl-3 flex align-items-center">
          <img :src="activity.buyersJourneyBadge?.icon" :alt="activity.buyersJourneyName" width="50">
        </span>
        <span v-if="!activity.buyersJourneyName" class="pl-2">N/A</span>
      </div>
    </div>
  </div>

  <Dialog id="dialogSpecificsDetails" v-model:visible="displayDialogSpecifics"
    :breakpoints="{ '960px': '100vw', '640px': '100vw' }" :style="{ width: '50vw' }" :modal="true" header="Specifics"
    :closable="false" class="dark-blue-gradient-dialog">
    <template #header>
      <div class="absolute top-0 right-0 flex align-items-center justify-content-center w-3rem h-3rem">
        <i class="pi pi-times reverse p-primary-text-color" aria-haspopup="true" aria-controls="overlay_menu"
          @click="displayDialogSpecifics = false" />
      </div>
      <div class="col-12 pt-6">
        <div class="flex justify-content-between pr-3">
          <div class="flex align-items-center">
            <img :src="activityImagePath" :alt="activityTypeName" width="33" height="24">
            <h3 class="cursor-pointer p-primary-text-color pl-2 flex">
              {{ `${activityTypeName} Notes` }}
            </h3>
          </div>
          <div class="flex align-items-center">
            <img :src="onlineLogoPath" :alt="activity.portal" class="online-logo">
          </div>
        </div>
      </div>
    </template>
    <div class="col-12">
      <div>{{ dayjs(activity.date).format('MM/DD/YYYY') }}</div>
      <div class="specifics-container mt-5">
        <specifics-questions v-model="activityRef.specificsModel" :show-title="false"
          :specifics-freetext="activity?.specifics" />
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import dayjs from 'dayjs';
import { fullUrl } from '@/utils/urlUtils';
import { READER_CARD_COLORS_MAP } from '@/models/analytics/readerCardColors';
import { computed, ref } from 'vue';
import { IndividualActivitySearchDetails } from '@/models/search';
import { ACTIVITY_TYPE_IMAGE_PATH_LOOKUPS, ACTIVITY_TYPE_LOOKUPS } from '@/models/analytics/activityTypes';
import SpecificsQuestions from '@/components/analytics/SpecificsQuestions.vue';
import { Quality } from '@/models/activity-dissemination';

export default {
  name: 'IndividualActivityDetails',
  components: { SpecificsQuestions },
  props: {
    activity: { type: IndividualActivitySearchDetails, required: true }
  },
  setup(props) {
    const activity = props.activity as IndividualActivitySearchDetails;
    const activityRef = ref<IndividualActivitySearchDetails>(props.activity);
    const activityType = activity.activityType;
    const activityTypeName = ACTIVITY_TYPE_LOOKUPS.find(x => x.id === activityType)?.name;
    const activityImagePath = ACTIVITY_TYPE_IMAGE_PATH_LOOKUPS.find(x => x.id === activityType)?.name;
    const readerBgColor = READER_CARD_COLORS_MAP[Quality[activity.quality] || 3];
    const notesBgColor = '--bg-color:transparent linear-gradient(90deg, #162C4C 0%, #295795 100%) 0% 0% no-repeat padding-box;';
    const onlineLogoPath = `https://leadtrail.blob.core.windows.net/images/onlines/${activity.portal
      .replace(/\s/g, '_').replace('&', 'and').toLowerCase()}.png`;
    const noSpecificsMessageByActivityType = {
      ['CustomerRequest']: 'This customer did not enter any notes when they filled out the form'
    };

    const clientConsentDate = props.activity.consentToPrivacyDate
      ? dayjs(props.activity.consentToPrivacyDate).format('YYYY-MM-DD')
      : 'NO CONSENT';

    const fullLinkedInUrl = fullUrl(activity?.linkedInUrl || '');
    const trimmedNote = activity?.specifics.replace('\n', '').trim();
    const noteExceedsMaxLength = (trimmedNote?.length || 0) > 390;
    const showExpand = noteExceedsMaxLength || activity.specificsModel;

    const specificsText = computed(() => {
      if (!trimmedNote && activity.specificsModel) {
        return 'Expand to see answers to questions, polls and/or surveys.';
      }

      if (noteExceedsMaxLength) {
        const contentBeforeLastSpace = trimmedNote?.substring(0, 389)
          .replace(/^(.*\s)([^ ]+)$/, '$1');
        return `${contentBeforeLastSpace}...`;
      } else {
        return trimmedNote;
      }
    });

    const displayDialogSpecifics = ref<boolean>(false);

    return {
      activityRef,
      fullLinkedInUrl,
      clientConsentDate,
      readerBgColor,
      notesBgColor,
      activityTypeName,
      activityImagePath,
      onlineLogoPath,
      noSpecificsMessageByActivityType,
      showExpand,
      specificsText,
      displayDialogSpecifics
    };
  },
  methods: { dayjs }
};
</script>

<style lang="scss" scoped>
img.online-logo {
  width: 100%;
}

.p-reader-card {
  max-width: 500px;
  min-width: 400px;
}

.activity-date {
  font-size: 14px;
}

.specifics-text {
  min-height: 7rem;
}

.expand-note {
  cursor: pointer;
  font-size: 20px;

  i {
    color: white;
  }
}

.content-consumed-card {
  background: #FFFBEA 0 0 no-repeat padding-box;
  border: 1px solid #F4D24A;
}

.insights-card {
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px #D3DDFF;
  border: 1px solid #9BABDD;
  opacity: 1;
}

.general-info-item-container {
  background: #F4F5F7 0 0 no-repeat padding-box;
  border-radius: 24px;
  color: #1B3557;
}

.general-info-item-data {
  background: #D1D7DD85 0 0 no-repeat padding-box;
  border-radius: 13px;
  opacity: 1;
}

.p-card-subtitle {
  color: #ffff;
  font-size: 0.6rem;
}

.standard-font-color-link {
  a {
    color: #1b3557;
    text-decoration: underline;
  }
}
</style>
