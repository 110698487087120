import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { ref: "form" }
const _hoisted_2 = { class: "grid fluid" }
const _hoisted_3 = { class: "col-12 pb-2" }
const _hoisted_4 = { class: "flex justify-content-between" }
const _hoisted_5 = { class: "flex align-items-center line-height-1" }
const _hoisted_6 = { class: "p-secondary-color text-xs" }
const _hoisted_7 = { class: "col-12 lg:col-5" }
const _hoisted_8 = { class: "col-12 lg:col-7" }
const _hoisted_9 = { class: "col-12 lg:col-5" }
const _hoisted_10 = { class: "col-12 lg:col-7 pt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cp_reader_info = _resolveComponent("cp-reader-info")!
  const _component_cp_recent_engagements = _resolveComponent("cp-recent-engagements")!
  const _component_cp_engagements_by_month = _resolveComponent("cp-engagements-by-month")!
  const _component_cp_engagements_by_category = _resolveComponent("cp-engagements-by-category")!

  return (_openBlock(), _createElementBlock("form", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h1", _hoisted_5, _toDisplayString($setup.titleText), 1)
        ]),
        _createElementVNode("span", _hoisted_6, _toDisplayString($setup.dateRangeTxt), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_cp_reader_info, {
          email: $setup.emailFilterForComponents.value
        }, null, 8, ["email"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        ($setup.paramsForComponentsReady)
          ? (_openBlock(), _createBlock(_component_cp_recent_engagements, {
              key: 0,
              "filter-model": $setup.filterModelWithWorkflowEmail,
              size: "5"
            }, null, 8, ["filter-model"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_9, [
        ($setup.paramsForComponentsReady)
          ? (_openBlock(), _createBlock(_component_cp_engagements_by_month, {
              key: 0,
              "filter-model": $setup.filterModelWithWorkflowEmail
            }, null, 8, ["filter-model"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_10, [
        ($setup.paramsForComponentsReady)
          ? (_openBlock(), _createBlock(_component_cp_engagements_by_category, {
              key: 0,
              "filter-model": $setup.filterModelWithWorkflowEmail
            }, null, 8, ["filter-model"]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 512))
}