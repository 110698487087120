<template>
  <div class="grid">
    <div class="col-12 pb-0">
      <div class="flex">
        <h1 class="line-height-1">
          Review Activities
        </h1>
        <!-- <span class="help-icon ml-2">?</span> -->
      </div>
      <span class="p-secondary-color text-xs">{{ 'RESULTS FROM ' + formatDate(requestOptions.from) }} - {{
        formatDate(requestOptions.to)
      }}</span>
    </div>
    <div v-if="apiStatus" class="col-12 md:col-12 lg:col-8 success-message flex p-0 m-0 pr-2 flex">
      <label>{{ message }}</label>
    </div>
    <div class="filter-menu-wrapper">
      <a class="filter-menu" @click.stop="showFilter = !showFilter">
        <img :src="require('../../assets/icons/dashboard-filters.svg')" alt="Filter Framework" width="52">
      </a>
    </div>
    <div v-if="selected.length > 0" class="col-12 p-0 flex pt-2">
      <div class="col-10">
        <span class="p-buttonset text-white">
          <Button v-if="canApprove" class="p-button-success" label="APPROVE" style="margin-right: 0.1rem;"
            @click="approveActivities">
            APPROVE
            <span class="material-icons ml-3"> thumb_up </span>
          </Button>
          <Button v-if="canReject" class="p-button-success" label="REJECT" style="margin-right: 0.1rem;"
            @click="rejectActivities">
            REJECT
            <span class="material-icons ml-3"> thumb_down </span>
          </Button>
        </span>
      </div>
      <div class="col-2">
        <Button type="button" label="Toggle" aria-haspopup="true" aria-controls="overlay_menu" class="p-button-rounded"
          @click="exportData()">
          <img alt="logo" :src="require('../../assets/icons/microsoft-excel.svg')" style="width: 1.2rem"
            class="text-xl">
          <span class="mx-2 text-white">Download</span>
          <span class="material-icons text-sm"> arrow_drop_down </span>
        </Button>
      </div>
    </div>
    <div class="col-12 pt-4">
      <DataTable v-model:selection="selected" :value="data1.items" data-key="id" :paginator="true"
        :rows-per-page-options="pageSizes" :rows="pagination.itemsPerPage" :lazy="true" :total-records="data1.total"
        paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
        current-page-report-template="Showing {first} to {last} of {totalRecords}" :scrollable="true"
        scroll-direction="both" @page="onPage($event)" @sort="onSort($event)">
        <Column selection-mode="multiple" class="w-2rem" />
        <column header="Drop Date" field="DropDate" sortable class="w-13rem">
          <template #body="slotProps">
            {{ formatDateTime(slotProps.data.formattedDropDate) }}
          </template>
        </column>
        <column header="Status" field="statusText" sortable class="w-12rem" />
        <span v-if="visibleHeadersInclude('RejectReason')">
          <column header="Reject Reason" field="rejectReason" class="w-12rem">
            <template #body="slotProps">
              {{ slotProps.data.rejectReason }}
            </template>
          </column>
        </span>
        <span v-if="visibleHeadersInclude('ApprovedDate')">
          <column header="Approved Date" field="formattedApprovedDate" class="w-13rem">
            <template #body="slotProps">
              {{ formatDateTime(slotProps.data.formattedApprovedDate) }}
            </template>
          </column>
        </span>
        <column header="First Name" field="firstName" class="w-10rem" />
        <column header="Last Name" field="lastName" class="w-10rem" />
        <column header="Client" field="company" sortable class="w-19rem" />
        <column header="Email" field="email" sortable class="w-19rem" />
        <column header="Job Title" field="jobTitle" class="w-27rem opacity-100" />
        <column header="Specifies" field="specifics" sortable class="w-27rem opacity-100" />
        <column header="Country" field="country" class="w-10rem opacity-100" />
        <column header="City" field="city" class="w-10rem opacity-100" />
        <column header="State" field="state" class="w-10rem opacity-100" />
        <column header="Activity Source" field="ActivitySource" sortable class="w-10rem opacity-100" />
        <column header="Online" field="online" sortable class="w-10rem opacity-100" />
        <column header="Content Title" field="documentTitle" sortable class="w-30rem opacity-100" />
        <column header="Document Company" field="documentCompanyName" sortable class="w-30rem" />
        <template #empty>
          <div class="flex col-12 py-5" style="padding-left:20rem;">
            <ProgressSpinner v-if="inProgress" />
            <cp-no-datatable-records v-if="!inProgress" />
          </div>
        </template>
      </DataTable>
      <Button v-if="canReject" class="p-button-success mt-2" label="EXCLUDE EMAIL & REJECT"
        @click="excludeEmailAndReject">
        EXCLUDE EMAIL & REJECT
        <span class="material-icons ml-3">mail thumb_down</span>
      </Button>
    </div>
    <cp-activity-fields-filter :show="showFilter" @filter-changed="retrieveByFilter" @close-panel="closePanel" />
  </div>
</template>

<script lang="ts">
import { Watch } from 'vue-property-decorator';
import { MSG_MUTATIONS } from '@/store/modules/msg.module';
import { Activity, ActivityStatus } from '@/models/activity-review';
import { ActivityReviewRequestParams } from '@/services/activityReviewRequestParams';
import ActivityReviewService from '@/services/activityReviewService';
import { PagedList } from '@/models/pagedList';
import { SortOrder } from '@/models/sortOrder';
import { FieldComparison, InclusionCondition, DEFAULT_PAGINATION } from '@/models/search';
import ActivityFieldsFilter from '@/components/activity-review/ActivityFieldsFilter.vue';
import dayjs from 'dayjs';
import { ActivityReviewSaveParams } from '@/services/activityReviewSaveParams';
import { isEqual, uniq } from 'lodash';
import { ActivityReviewSaveResponse } from '@/services/activityReviewSaveResponse';
import { Vue, Options } from 'vue-class-component';
import NoDataTableRecordsComponent from '@/components/common/NoDataTableRecordsComponent.vue';
import { onSuccess } from '@/utils/toast/toastUtils';
import { DATETIME_FORMAT, DATE_FORMAT } from '@/models/common/dateFormats';

@Options({
  components: {
    'cp-activity-fields-filter': ActivityFieldsFilter,
    'cp-no-datatable-records': NoDataTableRecordsComponent
  }
})
export default class ActivitiesToReviewList extends Vue {
  pageSizes = [10, 50, 100, 200, 300];
  showActivityFieldsFilter = false;
  defaultFromDate = dayjs().subtract(7, 'day').toDate();
  message: string;
  apiStatus: boolean = false;
  requestOptions: ActivityReviewRequestParams = {
    action: 'search',
    status: 'Pending',
    from: this.defaultFromDate,
    to: new Date(),
    activitySource: undefined,
    onlines: [],
    companyIds: [],
    secondaryOnly: false,
    page: 1,
    size: this.pageSizes[0],
    sortCol: 'DropDate',
    sortOrder: SortOrder.Descending,
    comparedFields: [],
    inclusionCondition: InclusionCondition.All,
    activityTypes: [],
    quality: []
  };
  showFilter = false;
  data1: PagedList<Activity> = { items: [], total: 0, totalPages: 0 };


  pagination: any = Object.assign({}, {
    ...DEFAULT_PAGINATION,
    sortDesc: [true],
    itemsPerPage: 10
  });

  dataFooterOptions = {
    itemsPerPageOptions: this.pageSizes
  };

  headers = [
    { text: 'Drop Date', value: 'DropDate', class: 'long-header' },
    { text: 'Status', value: 'Status' },
    { text: 'Reject Reason', value: 'RejectReason' },
    { text: 'Approved Date', value: 'ApprovedDate' },
    { text: 'First Name', value: 'FirstName', sortable: false },
    { text: 'Last Name', value: 'LastName', sortable: false },
    { text: 'Client', value: 'Company', class: 'long-header' },
    { text: 'Email', value: 'Email', class: 'long-header' },
    { text: 'Job Title', value: 'JobTitle', sortable: false },
    { text: 'Specifics', value: 'Specifics' },
    { text: 'Country', value: 'Country', class: 'medium-header', sortable: false },
    { text: 'City', value: 'City', class: 'medium-header', sortable: false },
    { text: 'State', value: 'State', sortable: false },
    { text: 'Activity Source', value: 'ActivitySource', sortable: false },
    { text: 'Online', value: 'Online', class: 'medium-header' },
    { text: 'Content Title', value: 'DocumentTitle' },
    { text: 'Document Company', value: 'DocumentCompanyName', class: 'long-header' }
  ];
  visibleHeaders = this.headers;
  selected: Activity[] = [];
  inProgress = false;

  mounted() {
    this.search();
  }

  async exportData() {
    this.requestOptions.action = 'export';
    let blob: Blob;
    if (!this.selected.length) {
      blob = await ActivityReviewService.export(this.requestOptions);
    } else {
      this.requestOptions.engagementIds = this.selected
        .filter(x => x.activityType === 'Engagement')
        .map(x => x.id);

      this.requestOptions.customerRequestIds = this.selected
        .filter(x => x.activityType === 'CustomerRequest')
        .map(x => x.id);

      blob = await ActivityReviewService.export(this.requestOptions);
    }
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'Activity Review Export.xlsx';
    // NOTE: document.body.appendChild and the code in setTimeout are needed for the file download option to appear in Firefox
    // https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    }, 100);
  }

  get headersByStatus() {
    switch (this.requestOptions.status) {
      case 'RejectedManually':
      case 'RejectedByBadWordsFilter':
      case 'RejectedByOtherFilters':
        return this.headers.filter(x => x.value !== 'ApprovedDate');
      case 'Approved':
        return this.headers.filter(x => x.value !== 'RejectReason');
      case 'Pending':
        return this.headers.filter(x => !['ApprovedDate', 'RejectReason'].includes(x.value));
      default:
        return this.headers;
    }
  }

  visibleHeadersInclude(val: string) {
    return this.visibleHeaders.some(x => x.value === val);
  }

  get canApprove() {
    const allowedStatuses: ActivityStatus[] = ['Pending', 'Rejected', 'RejectedAfterApproval'];
    return this.selected.length > 0 && allowedStatuses.includes(this.selected[0].status);
  }

  get canReject() {
    const allowedStatuses: ActivityStatus[] = ['Pending', 'Approved', 'EditAfterApproval'];
    return this.selected.length > 0 && allowedStatuses.includes(this.selected[0].status);
  }

  get secondaryOnly() {
    return this.requestOptions.secondaryOnly;
  }

  async approveActivities() {
    // console.debug(`ActivitiesToReviewList.approveActivities() - { requestOptions, data1 }`, this.requestOptions, this.data1);
    await this.updateActivities('Approved', 'Activities Approved (may include Subsequent) 👍');
    this.selected = [];
    await this.scrollToTop();
  }

  async rejectActivities() {
    // console.debug(`ActivitiesToReviewList.rejectActivities() - { requestOptions, data1 }`, this.requestOptions, this.data1);
    await this.updateActivities('Rejected', 'Activities Rejected (may include Subsequent) 👎');
    this.selected = [];
    await this.scrollToTop();
  }

  async scrollToTop() {
    // console.debug(`ActivitiesToReviewList.scrollToTop() - { requestOptions, pagination, data1 }`, this.requestOptions, this.pagination, this.data1);
    await this.retrieveData(this.pagination);
  }

  async excludeEmailAndReject() {
    await this.updateActivities('Rejected', undefined, true);
    this.selected = [];
    await this.scrollToTop();
  }

  async updateActivities(status: ActivityStatus, message?: string, excludeEmail = false) {
    const params: ActivityReviewSaveParams = {
      engagementIds: this.selected.filter(x => x.activityType === 'Engagement').map(x => x.friendlyId),
      customerRequestIds: this.selected.filter(x => x.activityType === 'CustomerRequest').map(x => x.friendlyId),
      status,
      startDate: this.requestOptions.from
    };

    if (excludeEmail) {
      params['excludedEmails'] = uniq(this.selected.map(x => x.email));
    }

    const response: ActivityReviewSaveResponse = await ActivityReviewService.save(params);

    const text = excludeEmail
      ? `${response.emailsExcluded} Email(s) Excluded & ${response.activitiesUpdated} Activities Rejected 👎`
      : `${response.activitiesUpdated} ${message}`;

    onSuccess(this.$toast, text);

    this.$store.commit(`msg/${MSG_MUTATIONS.MSG}`, { text });
  }

  @Watch('pagination')
  async retrieveData(data: any, oldOpts: Partial<any> = {}) {

    if (isEqual(data, oldOpts)) {
      return;
    }

    // console.debug(`ActivitiesToReviewList.retrieveData() - { itemsPerPage, requestOptions }`, data.ItemsPerPage, this.requestOptions);

    if (data.itemsPerPage !== this.requestOptions.size) {
      this.requestOptions.size = data.itemsPerPage;
      this.requestOptions.page = 1;
    }
    this.inProgress = true;
    this.data1 = await ActivityReviewService.search(this.requestOptions);
    this.inProgress = false;
    this.visibleHeaders = this.headersByStatus;

  }

  async search() {
    this.inProgress = true;
    this.data1 = await ActivityReviewService.search(this.requestOptions);
    this.inProgress = false;
    this.visibleHeaders = this.headersByStatus;
  }

  onPage(event) {
    this.requestOptions.page = event.page + 1;
    this.requestOptions.size = event.rows;
    this.pagination.itemsPerPage = event.rows;
    // console.debug(`ActivitiesToReviewList.onPage() - { event, requestOptions }`, event, this.requestOptions);
    this.search();
  }

  onSort(event) {
    if (event.sortField) {
      this.requestOptions.sortCol = (event.sortField === 'date') ? 'DropDate' : this.toPascalCase(event.sortField);
    }
    if (event.sortField) {
      this.requestOptions.sortOrder = event.sortOrder == 1 ? SortOrder.Descending : SortOrder.Ascending;
    }
    // console.debug(`ActivitiesToReviewList.onSort() - { event, requestOptions }`, event, this.requestOptions);
    this.search();
  }

  hideFilter() {
    this.showActivityFieldsFilter = true;
  }
  comparedFieldDataWithValues(comparedFields: Record<string, FieldComparison>) {
    return Object.keys(comparedFields)
      .map(key => (
        comparedFields[key].value &&
        comparedFields[key]
      ))
      .filter(data => data);
  }

  retrieveByFilter(data: Record<string, any>) {
    if (!this.showFilter) {
      return;
    }
    this.requestOptions.inclusionCondition = InclusionCondition.All;
    this.requestOptions.onlines = data.onlines;
    this.requestOptions.companyIds = data.companyIds;
    this.requestOptions.activitySource = data.activitySource;
    this.requestOptions.from = data.fromDate;
    this.requestOptions.to = data.toDate;
    this.requestOptions.comparedFields = this.comparedFieldDataWithValues(data.comparedFields);
    this.requestOptions.status = data.status;
    this.requestOptions.secondaryOnly = data.secondaryOnly;
    this.requestOptions.activityTypes = data.activityTypes;
    this.requestOptions.quality = data.qualities;
    this.retrieveData(this.pagination);
  }

  formatDate(date: Date): string {
    return dayjs(date).format(DATE_FORMAT);
  }

  formatDateTime(date: Date): string {
    return dayjs(date).format(DATETIME_FORMAT);
  }

  toPascalCase(s: string): string {
    return s.replace(/\w+/g,
      function (w) { return w[0].toUpperCase() + w.slice(1).toLowerCase(); });
  }

  closePanel() {
    this.showFilter = false;
  }
}
</script>

<style lang="scss">
.activities-to-review-container {
  .fixed-header {
    max-height: calc(100vh - 260px);
    display: flex;
    flex-direction: column;
    height: 100%;

    .v-data-table__wrapper {
      overflow-y: auto;

      table {
        table-layout: fixed;
      }

      th {
        position: sticky;
        top: 0;
        z-index: 2;
        padding: 0 5px !important;
        width: 120px !important;
        background-color: white;
        font-weight: bold;
        color: #4558a1 !important;

        &:first-child {
          width: 50px !important;
        }

        &.medium-header {
          width: 150px !important;
        }

        &.long-header {
          width: 300px !important;
        }
      }

      td {
        padding: 0 5px !important;

        &.ellipsis {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>
