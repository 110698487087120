import commonLookupService from '@/services/commonLookupService';
import {
  callSearchFieldModelFunction,
  ActivityFieldCategory,
  SearchFieldMetaData, searchFieldModel,
  searchFieldModelValue,
  SearchFieldRow
} from '@/components/searchFields/metadata';
import { Watch } from 'vue-property-decorator';
import { Vue } from 'vue-class-component';
import { Lookup } from '@/models/lookup';
import { Question } from '@/models/user-service';
import filterLookupsService from '@/services/filterLookupsService';
import { TermsFilter, Filter } from '@/models/filters';
import { isEqual, uniq } from 'lodash';


export class FiltersMixin extends Vue {
  searchFields: SearchFieldRow[] = [];
  excludeOrgSearchFields: string[] = [];
  orgQuestionsMap: Record<number, Question> = {};
  dataReady: Promise<boolean>;
  onlineBgMap: Record<string, string> = {};
  private firstWatch = true;

  created() {
    this.dataReady = new Promise(resolve => {
      Promise.all([
        filterLookupsService.getOrgQuestionsMap(),
        commonLookupService.getOnlines()
      ]).then(([orgMap, onlines]) => {
        this.orgQuestionsMap = orgMap;
        this.onlineBgMap = onlines.reduce((prev, curr) => {
          prev[curr.id] = curr.businessGroup;
          return prev;
        }, <Record<string, string>>{});
        resolve(true);
      });

    });
  }

  get generalFields() { return this._getByCategory('General'); }
  get demographicFields() { return this._getByCategory('Demographics'); }
  get contextualFields() { return this._getByCategory('Contextual'); }
  get firmographicFields() { return this._getByCategory('FirmographicGeneral'); }
  get customFirmographicFields() { return this._getByCategory('FirmographicLifeSciences', 'FirmographicMedicalDevices'); }

  get allFirmographicFields() {
    const fields = this.firmographicFields.concat(
      this.customFirmographicFields.filter(x =>
        x.items.some(i => !this.excludeOrgSearchFields.includes(searchFieldModelValue(i, 'field')))
      )
    );
    fields.flatMap(f => f.items).filter(i => i.props?.questionId).forEach(i => {
      const q = this.orgQuestionsMap[i.props?.questionId || 0];
      (i.props || {}).label = q?.title;
      if (i.type?.name !== 'cp-terms-search-field') { return; }
      this._setOrgFieldOptions(q, i);
    });
    return fields;
  }

  get onlines(): string[] {
    const onlinesSearchField = this.searchFields
      .flatMap(x => x.items)
      .find(r => searchFieldModelValue(r, 'field') === 'onlines')?.model as TermsFilter;
    return onlinesSearchField ? onlinesSearchField.value.map(x => x.id) : [];
  }

  @Watch('onlines')
  async onlinesChanged(prev: any, cur: any) {
    if (this.firstWatch || !isEqual(prev, cur)) {
      this.firstWatch = false;
      await this.dataReady;
      const bgCodes = uniq(this.onlines.map(o => this.onlineBgMap[o]));
      this.excludeOrgSearchFields = this.customFirmographicFields
        .flatMap(f => f.items)
        .filter(i => i.props?.questionId &&
          !bgCodes.includes(this.orgQuestionsMap[i.props.questionId].businessGroup.toUpperCase())
        ).map(i => searchFieldModelValue(i, 'field'));
    }
  }

  getSearchFieldFilters(): (Filter | Filter[])[] {
    return this.searchFields
      .flatMap(x => x.items)
      .filter(x => !this.excludeOrgSearchFields.includes(searchFieldModelValue(x, 'field')))
      .filter(x => {
        try {
          return !callSearchFieldModelFunction(x, 'isEmpty');
        } catch (error) {
          console.log('error', x);
        }

      })
      .flatMap((x) => x.props?.isCompound ? x.model as Filter[] : [x.model as Filter]);
  }

  private _getByCategory(...categories: ActivityFieldCategory[]): SearchFieldRow[] {
    return this.searchFields.filter(f => (f.category && categories.includes(f.category)));
  }
  private _setOrgFieldOptions(q: Question, i: SearchFieldMetaData): void {
    let opts = q?.options || [];
    const bgCodes = uniq(this.onlines.map(o => this.onlineBgMap[o]));
    if (bgCodes?.length) {
      opts = opts.filter(o =>
        o.businessGroups?.length ? o.businessGroups.some(x => bgCodes.includes(x)) : true);
    }
    const lookups: Array<Lookup<number>> = opts.map(x => ({ id: x.id, name: x.text }));
    (i.props || {}).optionsGetter = () => Promise.resolve(lookups);
    const tf = <TermsFilter>searchFieldModel(i);
    // Filter out selected options which are not applicable.
    tf.value = tf.value.filter(x => lookups.some(y => y.id === x.id));
  }
}
