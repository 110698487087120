import { IntLookup, Lookup } from '@/models/lookup';
import { ClaimAccessLevel } from '../security';

export enum DisabledReasonType {
  None = 1,
  NonPaidClient = 2,
  InsufficientUserPermissions = 3,
  DeletedUser = 4
}

const AdminSummary = 'Configuration is disabled for internal/client users.';
const AdvancedSummary =
  'Edit configuration is not available. Contact an administrator for assistance.';
const BasicSummary = 'Edit configuration is not available.';

export const DISABLED_REASON_SUMMARY_BASIC_MAP: Record<
  DisabledReasonType,
  string
> = Object.freeze({
  [DisabledReasonType.None]: '',
  [DisabledReasonType.NonPaidClient]: `${BasicSummary} Please confirm if your campaign is still active to continue.`,
  [DisabledReasonType.InsufficientUserPermissions]: `${BasicSummary} Please check with your representative about your permissions to edit this alert.`,
  [DisabledReasonType.DeletedUser]: `${BasicSummary} An account associated with this alert has been deactivated. Please contact your representative to edit this alert.`,
  [DisabledReasonType.NonPaidClient | DisabledReasonType.InsufficientUserPermissions]:
    `${BasicSummary} Please confirm if your campaign is still active. Please check with your representative about your permissions to edit this alert.`,
  [DisabledReasonType.NonPaidClient | DisabledReasonType.DeletedUser]:
    `${BasicSummary} Please confirm if your campaign is still active, an account associated with this alert has been deactivated. Please contact your representative to edit this alert.`,
  [DisabledReasonType.NonPaidClient | DisabledReasonType.InsufficientUserPermissions & DisabledReasonType.DeletedUser]:
    `${BasicSummary} Please confirm if your campaign is still active, an account associated with this alert has been deactivated. Please contact your representative to edit this alert.`,
  [DisabledReasonType.InsufficientUserPermissions | DisabledReasonType.DeletedUser]:
    `${BasicSummary} An account associated with this alert has been deactivated. Please contact your representative to edit this alert.`
});
export const DISABLED_REASON__SUMMARY_ADVANCED_MAP: Record<
  DisabledReasonType,
  string
> = Object.freeze({
  [DisabledReasonType.None]: '',
  [DisabledReasonType.NonPaidClient]: `${AdvancedSummary} Reason: CMS company is not paid.`,
  [DisabledReasonType.InsufficientUserPermissions]: `${AdvancedSummary} Reason: Insufficient user permissions.`,
  [DisabledReasonType.DeletedUser]: `${AdvancedSummary} Reason: Deleted user.`,
  [DisabledReasonType.NonPaidClient | DisabledReasonType.InsufficientUserPermissions]: `${AdvancedSummary} Reasons: CMS company is not paid and Insufficient user permissions.`,
  [DisabledReasonType.NonPaidClient | DisabledReasonType.DeletedUser]: `${AdvancedSummary} Reasons: CMS company is not paid and Deleted user.`,
  [DisabledReasonType.NonPaidClient | DisabledReasonType.InsufficientUserPermissions & DisabledReasonType.DeletedUser]:
    `${AdvancedSummary} Reasons: CMS company is not paid, Insufficient user permissions, and Deleted user.`,
  [DisabledReasonType.InsufficientUserPermissions | DisabledReasonType.DeletedUser]: `${AdvancedSummary} Reasons: Insufficient user permissions and Deleted user.`
});
export const DISABLED_REASON_SUMMARY_ADMIN_MAP: Record<
  DisabledReasonType,
  string
> = Object.freeze({
  [DisabledReasonType.None]: '',
  [DisabledReasonType.NonPaidClient]: `${AdminSummary}`,
  [DisabledReasonType.InsufficientUserPermissions]: `${AdminSummary}`,
  [DisabledReasonType.DeletedUser]: `${AdminSummary}`,
  [DisabledReasonType.NonPaidClient | DisabledReasonType.InsufficientUserPermissions]: `${AdminSummary}`,
  [DisabledReasonType.NonPaidClient | DisabledReasonType.DeletedUser]: `${AdminSummary}`,
  [DisabledReasonType.NonPaidClient | DisabledReasonType.InsufficientUserPermissions & DisabledReasonType.DeletedUser]: `${AdminSummary}`,
  [DisabledReasonType.InsufficientUserPermissions | DisabledReasonType.DeletedUser]: `${AdminSummary}`
});

export function getDisabledReasonSummary(
  disabledReasonType: DisabledReasonType,
  notificationClaimLevel: ClaimAccessLevel | unknown
): string {
  // debugger;
  console.debug(`DisabledReasons.getDisabledReasonSummary() - { BASIC_DISABLED_REASONS }`, DISABLED_REASON_SUMMARY_BASIC_MAP);
  switch (notificationClaimLevel) {
    case 'Advanced':
      return DISABLED_REASON__SUMMARY_ADVANCED_MAP[disabledReasonType];
    case 'Admin':
      return DISABLED_REASON_SUMMARY_ADMIN_MAP[disabledReasonType];
    case 'Basic':
    default:
      return DISABLED_REASON_SUMMARY_BASIC_MAP[disabledReasonType];
  }
}

export function getDisabledReasonDetails(reasons: DisabledReason): string[] {
  if (!reasons) return [];

  const details: string[] = [];
  reasons?.deletedUsers.map((x) => {
    const keyValueMap = {
      '{RECIPIENT_EMAIL}': x.email,
    };
    const message = replaceTokens(
      keyValueMap,
      DISABLED_REASON_DETAIL_MAP[DisabledReasonDetailType.DeletedUser]
    );
    details.push(message);
    // console.debug(`disabledReasons.getDisabledReasonDetails() - { deletedUsers, keyValueMap, message }`, reasons.deletedUsers, keyValueMap, message);
  });
  reasons?.invalidClients.map((x) => {
    const keyValueMap = {
      '{RECIPIENT_EMAIL}': x.email,
      '{CLIENT_NAMES}': x.externalData?.map(c => c.name).join(', '),
      '{USER_PROFILE_LINK}': `/admin/user/${x.userId}`,
    };
    const message = replaceTokens(
      keyValueMap,
      DISABLED_REASON_DETAIL_MAP[
      DisabledReasonDetailType.InsufficientClientPermissions
      ]
    );
    details.push(message);
    // console.debug(`disabledReasons.getDisabledReasonDetails() - { invalidClients, keyValueMap, message }`, reasons.invalidClients, keyValueMap, message);
  });
  reasons?.invalidExportColumns?.map((x) => {
    const keyValueMap = {
      '{RECIPIENT_EMAIL}': x.email,
      '{EXPORT_COLUMN_NAMES}': x.externalData?.map(c => c.name).join(', '),
      '{USER_PROFILE_LINK}': `/admin/user/${x.userId}`,
    };
    const message = replaceTokens(
      keyValueMap,
      DISABLED_REASON_DETAIL_MAP[
      DisabledReasonDetailType.InsufficientExportColumnPermissions
      ]
    );
    details.push(message);
    // console.debug(`disabledReasons.getDisabledReasonDetails() - { invalidExportColumns, keyValueMap, message }`, reasons.invalidExportColumns, keyValueMap, message);
  });
  reasons?.nonPaidClients?.map((x) => {
    const keyValueMap = {
      '{CLIENT_NAME}': x.name,
      '{CMS_COMPANY_PROFILE_LINK}': `${process.env.VUE_APP_CMS_URL}/CompanySearch?searchTerm=${encodeURIComponent(x.name)}`,
    };
    const message = replaceTokens(
      keyValueMap,
      DISABLED_REASON_DETAIL_MAP[
      DisabledReasonDetailType.NonPaidClient
      ]
    );
    details.push(message);
    // console.debug(`disabledReasons.getDisabledReasonDetails() - { nonPaidClients, keyValueMap, message }`, reasons.nonPaidClients, keyValueMap, message);
  });
  return details;
}

export enum DisabledReasonDetailType {
  None = 0,
  NonPaidClient = 1,
  InsufficientClientPermissions = 2,
  InsufficientExportColumnPermissions = 4,
  DeletedUser = 8,
}

export const DISABLED_REASON_DETAIL_MAP: Record<
  DisabledReasonDetailType,
  string
> = Object.freeze({
  [DisabledReasonDetailType.None]: '',
  [DisabledReasonDetailType.NonPaidClient]:
    `Client "{CLIENT_NAME}" no longer has access to Templeton. To fix this, adjust the company filters in the notification,
    or you can edit the company profile in <a href="{CMS_COMPANY_PROFILE_LINK}" target="_blank">CMS</a>.`,
  [DisabledReasonDetailType.InsufficientClientPermissions]:
    `User "{RECIPIENT_EMAIL}" no longer has access to {CLIENT_NAMES}. To fix this, edit the <a href="{USER_PROFILE_LINK}" target="_blank">user profile</a>, or delete this recipient.`,
  [DisabledReasonDetailType.InsufficientExportColumnPermissions]:
    `User "{RECIPIENT_EMAIL}" no longer has access to premium filters: {EXPORT_COLUMN_NAMES}.
    To fix this, edit the <a href="{USER_PROFILE_LINK}" target="_blank">user profile</a>, or delete this recipient.`,
  [DisabledReasonDetailType.DeletedUser]: `User "{RECIPIENT_EMAIL}" was deleted. Activate this alert for all users below.`,
});

export class DisabledReason {
  isInvalid: boolean;
  deletedUsers: Array<UserValidation>;
  invalidClients: Array<UserValidation>;
  invalidExportColumns: Array<UserValidation>;
  nonPaidClients: Array<IntLookup>;

  constructor(opts: Partial<DisabledReason> = {}) {
    Object.assign(this, opts);
  }
}

class UserValidation {
  userId: string;
  email: string;
  externalData: Array<Lookup<string>>;
}

const replaceTokens = (dynamicValues, value) => Object.keys(dynamicValues).reduce((prev, current) => prev.replace(new RegExp(current, 'g'), dynamicValues[current]), value);
