import axios from 'axios';
import { ActivityReviewRequestParams } from './activityReviewRequestParams';
import { Activity } from '@/models/activity-review/activity';
import { PagedList } from '@/models/pagedList';
import { ActivityReviewSaveParams } from './activityReviewSaveParams';
import { ActivityReviewSaveResponse } from './activityReviewSaveResponse';
const EXCEL_MIME = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export default {
  async search(opts: ActivityReviewRequestParams): Promise<PagedList<Activity>> {
    const r = await axios.post('/api/review-activities-search', opts);
    r.data.items = r.data.items.map((x: any) => new Activity(x));
    return r.data;
  },
  async export(opts: ActivityReviewRequestParams): Promise<Blob> {
    const response = await axios.post('/api/export-activities-review', opts, { responseType: 'blob' });
    return new Blob([response.data], { type: EXCEL_MIME });
  },
  async save(opts: ActivityReviewSaveParams): Promise<ActivityReviewSaveResponse> {
    const { engagementIds, customerRequestIds, status, excludedEmails, startDate } = opts;
    const data = { engagementIds, customerRequestIds, status, excludedEmails, startDate };
    const r = await axios.post('/api/review-activities', data);
    return r.data;
  }
};
