import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex col-12 pt-4 align-items-center pl-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputSwitch = _resolveComponent("InputSwitch")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", null, _toDisplayString(_ctx.text), 1),
    _createVNode(_component_InputSwitch, {
      modelValue: _ctx.local,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.local) = $event)),
      class: "ml-4"
    }, null, 8, ["modelValue"])
  ]))
}