<template>
  <div class="mt-3">
    <p>Have questions? <a href="mailto:support@templetoninsights.com" style="color:#1b3557;">Contact us</a>.</p>
  </div>
</template>

<script>
export default {
  name: 'MailTo'
};
</script>

<style scoped>

</style>