import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex align-items-center justify-content-between" }
const _hoisted_2 = { class: "flex align-items-center" }
const _hoisted_3 = { class: "pt-1" }
const _hoisted_4 = {
  ref: "chart",
  class: "flex align-items-center pt-5",
  style: {"min-height":"300px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_cp_export_chart_options = _resolveComponent("cp-export-chart-options")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _directive_cp_color_picker = _resolveDirective("cp-color-picker")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, [
        (_ctx.showOptions)
          ? (_openBlock(), _createBlock(_component_Dropdown, {
              key: 0,
              modelValue: _ctx.category,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.category) = $event)),
              options: _ctx.categories,
              "option-label": "name",
              disabled: !_ctx.categoryMenuEnabled,
              placeholder: _ctx.selectedCategory,
              class: "p-dropdown-noborder pl-1",
              onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeCategory()))
            }, {
              value: _withCtx((slotProps) => [
                _createElementVNode("h2", null, _toDisplayString(slotProps.value.name), 1)
              ]),
              _: 1
            }, 8, ["modelValue", "options", "disabled", "placeholder"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_cp_export_chart_options, {
          chart: _ctx.chartExport,
          onChartUpdated: _ctx.chartUpdated
        }, null, 8, ["chart", "onChartUpdated"])
      ])
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
      _createVNode(_component_ProgressSpinner)
    ])), [
      [_directive_cp_color_picker]
    ])
  ], 64))
}