<template>
  <div v-if="modelValue" class="grid p-0 my-2">
    <div class="col-12 lg:col-2 p-secondary-color">
      Clients
    </div>
    <div class="col-12 lg:col-10 px-0 mt-3">
      <DataView :value="modelValue.companies" :data-key="modelValue.id">
        <template #list="slotProps">
          <div :class="getItemClass(slotProps.index)" class="flex align-items-center">
            <span class="font-weight-bold col-9">{{ slotProps.data.name }}</span>
            <Badge v-if="modelValue.enableApiAccess" class="p-badge-pointer col-3" :style="badgeStyle">
              {{ slotProps.data.id }}
            </Badge>
          </div>
        </template>
      </DataView>
    </div>
  </div>
  <Divider />
  <div v-if="modelValue" class="grid p-0 my-2">
    <cp-communities :communities="modelValue.communities" :show-id="modelValue.enableApiAccess" />
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { AppUser } from '@/models/appUser';

import Communities from '@/components/profile/Communities.vue';

export default {
  components: {
    'cp-communities': Communities
  },
  props: {
    modelValue: { type: Object as PropType<AppUser>, required: true }
  },
  setup() {
    const badgeStyle = '--bg-color:#76869A';

    function getItemClass(index) {
      return index % 2 === 0 ? 'col-4 my-1 mr-6 flex' : 'col-4 my-1 flex';
    }

    return {
      badgeStyle,
      getItemClass
    };
  }
};
</script>
