<template>
  <div class="grid">
    <div class="lg:flex col-12 pb-0">
      <div class="col-12 md:col-12 lg:col-4">
        <h1 class="line-height-1">
          Onboarding Dashboard
        </h1>
      </div>
      <div v-if="apiStatus" class="col-12 md:col-12 lg:col-8 success-message flex p-0 m-0 pr-2 flex">
        <label>{{ message }}</label>
        <span class="material-icons-outlined pl-1"> check_circle_outline </span>
      </div>
    </div>

    <div style="
        width: 97%;
        background-color: #d0e7ff54;
        border: 0;
        box-shadow: none;
        border-radius: 14px;
      " class="mt-4">
      <div class="lg:flex col-12">
        <div class="col-6">
          <h4>Search </h4>
        </div>
        <div class="col-6 flex flex-row-reverse">
          <Button label="New" class="p-button-success p-button-raised" @click="openOnboardingForm">
            New Onboarding Form
            <span class="material-icons pl-2"> email</span>
          </Button>
        </div>
      </div>
      <div class="flex col-12">
        <div class="col-11">
          <span class="p-float-label">
            <InputText id="email" v-model="filters['global'].value" type="text" style="width: 100%" />
            <label for="email">Enter Company/Name</label>
          </span>
        </div>
        <div class="col-1">
          <Button class="ml-3" @click="search(filters['global'].value)">
            <span class="material-icons text-xl text-white">
              search </span>
          </Button>
        </div>
      </div>
    </div>
    <main>
      <cp-new-onboarding-form :show="show" />
    </main>

    <div id="onboarding-table" class="flex col-12 pt-4">
      <DataTable ref="dt" v-model:filters="filters" :value="data1.items" data-key="id" :paginator="true" :rows="20"
        :total-records="data1.total"
        paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink  CurrentPageReport RowsPerPageDropdown"
        paginator-position="bottom" paginatorstart="true" :lazy="true" paginatorend="true"
        current-page-report-template="Showing {first} to {last} of {totalRecords}" :rows-per-page-options="[10, 20, 50]"
        :global-filter-fields="['createdOn', 'fillerName', 'companyName']" @filter="onFilter($event)"
        @page="onPage($event)">
        <template #empty>
          No customers found.
        </template>
        <Column header="Date Sent" field="createdOn">
          <template #body="{ data }">
            {{ formatDate(data.createdOn) }}
          </template>
        </Column>
        <Column header="Status" field="isSubmitted">
          <template #body="{ data }">
            <div v-if="data.isSubmitted">
              <span v-tooltip="'Submitted'" class="material-icons"
                :style="{ color: data.isSubmitted ? '#57BFC3' : 'grey' }">
                check_circle
              </span>
            </div>
            <div v-if="data.isExpired && !data.isSubmitted">
              <span v-tooltip="'Expired'">
                <img alt="Clock" :src="require('../../assets/onboarding/icons/timer-alert-outline.svg')">
              </span>
            </div>
            <div v-if="!data.isExpired && !data.isSubmitted" class="material-icons">
              <span v-tooltip="'Not Submitted'">
                remove_circle
              </span>
            </div>
          </template>
        </Column>
        <Column header="Company" field="companyName">
          <template #body="{ data }">
            <div>
              <router-link :to="'/admin/onboarding/' + data.id">
                {{ data.companyName }}
              </router-link>
            </div>
          </template>
        </Column>

        <Column header="Name" field="fillerName">
          <template #body="{ data }">
            {{ data.fillerName }}
          </template>
        </Column>
        <Column header="Preview" field="Preview">
          <template #body="{ data }">
            <div>
              <img alt="edit" :src="require('../../assets/onboarding/icons/preview-eye.svg')" style="cursor:pointer"
                @click="navigateToPreview(data.id)">
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>


<script lang="ts">
import { ref, onMounted } from 'vue';
import axios from 'axios';
import dayjs from 'dayjs';
import { OnBoardingSearchRequest } from '@/models/onboarding/onBoardingSearchRequest';
import NewOnboardingForm from '@/components/onboarding/NewOnboardingForm.vue';
import { useRouter } from 'vue-router';

export default {
  components: {
    'cp-new-onboarding-form': NewOnboardingForm
  },
  setup() {
    onMounted(() => {
      lazyParams.value = {
        first: 0,
        rows: dt.value?.rows,
        sortField: null,
        sortOrder: null,
        filters: filters.value
      };
      retrieve();
    });
    const dt = ref();
    let data1 = ref([]);
    const router = useRouter();
    const show = ref(false);

    const openOnboardingForm = () => {
      show.value = !show.value;
      let html = document.getElementsByTagName('html')[0];

      html.classList.add('overflow-hidden');

    };
    const onFilter = () => {
      lazyParams.value.filters = filters.value as any;
      retrieve();
    };
    const formatDate = (value) => dayjs(value).format('YYYY-MM-DD');
    const lazyParams = ref({}) as any;
    const resend = () => null;
    const retrieve = async () => {

      const searchRequest: OnBoardingSearchRequest = {
        searchString: lazyParams.value.filters.global.value,
        page: lazyParams.value.filters.page.value,
        pageSize: lazyParams.value.rows
      };

      data1.value = (await axios.post('/api/manage-onboarding/list', searchRequest)).data as any;
    };
    const onPage = (event) => {
      lazyParams.value.filters.page.value = event.page + 1;
      lazyParams.value.rows = event.rows;
      retrieve();
    };
    const search = (event) => {
      lazyParams.value.filters.fillerName.value = event;
      retrieve();
    };

    const navigateToPreview = (id) => {
      const navUrl = `/admin/onboarding/${id}`;
      router.push(navUrl);
    };

    const mySVG = () => require('../../assets/onboarding/icons/timer-alert-outline.svg');

    const filters = ref({
      'page': { value: 1 },
      'global': { value: '', matchMode: 'contains' },
      'fillerName': { value: '', matchMode: 'contains' },
      'companyName': { value: '', matchMode: 'contains' },
      'createdOn': { value: '', matchMode: 'contains' },
      'IsSubmitted': { value: '', matchMode: 'contains' },
    });

    return { dt, data1, filters, mySVG, formatDate, resend, onPage, retrieve, onFilter, lazyParams, search, show, openOnboardingForm, navigateToPreview };
  }

};
</script>
<style lang="scss">
.ui-paginator {
  font-family: verdana;
  font-weight: bold;
}

.p-datatable {
  width: 97% !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #ECEEF0 !important
}

.p-paginator .p-paginator-pages .p-paginator-page {
  color: #78879b !important;
  min-width: 1.5rem !important;
  height: 1.5rem !important;
  border-radius: 8px !important;

}

.ui-paginator span {
  text-align: left !important;
}
</style>
