import { GenericActionItem } from './actionItem';
import { DayOfWeek } from './dayOfWeek';

export type DayOfWeekActionItem = GenericActionItem<DayOfWeek>

export const DEFAULT_DAY_OF_WEEK_ACTION: DayOfWeekActionItem = {
  type: 'Monday',
  text: 'Monday',
  description: 'Monday',
  active: true
};

export const DAY_OF_WEEK_ACTIONS: DayOfWeekActionItem[] = [
  {
    type: 'Monday',
    text: 'Monday',
    description: 'Monday',
    active: true
  },
  {
    type: 'Tuesday',
    text: 'Tuesday',
    description: 'Tuesday'
  },
  {
    type: 'Wednesday',
    text: 'Wednesday',
    description: 'Wednesday'
  },
  {
    type: 'Thursday',
    text: 'Thursday',
    description: 'Thursday'
  },
  {
    type: 'Friday',
    text: 'Friday',
    description: 'Friday'
  },
  {
    type: 'Saturday',
    text: 'Saturday',
    description: 'Saturday'
  },
  {
    type: 'Sunday',
    text: 'Sunday',
    description: 'Sunday'
  }
];
