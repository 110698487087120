<template>
  <div v-if="loading" class="flex ml-2 mr-2 p-4 pb-0">
    <div class="col-4 p-0">
      <Skeleton width="100%" height="200px" />
    </div>
    <div class="col-8 text-sm">
      <div class="flex col-12">
        <div class="col-7">
          <Skeleton width="30%" />
          <Skeleton width="75%" class="mt-2" />
        </div>

        <div class="col-5">
          <Skeleton width="30%" />
          <Skeleton width="75%" class="mt-2" />
        </div>
      </div>
      <div class="flex col-12">
        <div class="col-7">
          <Skeleton width="30%" />
          <Skeleton width="75%" class="mt-2" />
        </div>
        <div class="col-5">
          <Skeleton width="30%" />
          <Skeleton width="75%" class="mt-2" />
        </div>
      </div>
      <div class="flex col-12">
        <div class="col-7">
          <Skeleton width="30%" />
          <Skeleton width="75%" class="mt-2" />
        </div>
        <div class="col-5">
          <Skeleton width="30%" />
          <Skeleton width="75%" class="mt-2" />
        </div>
      </div>
    </div>
  </div>
  <!-- <Card class="m-2">
    <template #content> -->
  <div v-if="!loading" class="flex ml-2 mr-2 p-4 justify-content-around">
    <div class="col-lg-5 col-md-3 p-0">
      <Card class="p-reader-card px-1" :style="getCardBgColor">
        <template #subtitle>
          <div class="flex">
            <div class="col-6">
              READER PROFILE
            </div>
            <div v-if="activity.consentToPrivacyDate" class="flex flex-row-reverse col-6">
              PRIVACY CONSENT: {{ clientConsentDate }}
            </div>
          </div>
        </template><template #content>
          <div class="flex py-0">
            <div class="flex align-items-center">
              <span class="material-icons text-7xl"> account_circle </span>
            </div>
            <div class="pl-3">
              <div class="p-card-title capitalize">
                {{ (activity.firstName || "") + " " + (activity.lastName || "") }}
              </div>
              <div class="capitalize">
                {{ activity.jobTitle + ", " }}
                <router-link :to="activity.readerCompanyUrl" class="text-white opacity-100 font-medium">
                  {{ activity.company }}
                </router-link>
              </div>
            </div>
            <div class="col flex flex-row-reverse ml-2">
              <a v-if="fullLinkedInUrl" :href="fullLinkedInUrl" target="_blank"
                class="p-primary-color opacity-100 font-medium">
                <i class="pi pi-linkedin" style="color: white" />
              </a>
            </div>
          </div>
          <div class="flex mt-4">
            <div>
              <div class="underline">
                <a :href="'mailto:' + activity.email" class="text-white opacity-100 font-medium">{{ activity.email
                }}</a>
              </div>
            </div>
            <div class="col flex flex-row-reverse">
              <span class="material-icons"> email </span>
            </div>
          </div>
          <div class="flex m-0 pt-2">
            <div class="capitalize">
              {{ activity.formattedAddr }}
            </div>
            <div class="col flex flex-row-reverse">
              <span class="material-icons"> place </span>
            </div>
          </div>
          <div class="flex m-0 pt-2">
            <div>
              <div>{{ activity.phone }}</div>
            </div>
            <div class="col flex flex-row-reverse">
              <span class="material-icons"> phone </span>
            </div>
          </div>
        </template>
      </Card>
    </div>
    <div class="col-7">
      <div class="flex col-12">
        <div class="col-7">
          <div class="p-column-value">
            Client
          </div>
          <div class="p-column-value font-normal">
            {{ activity.advertiser }}
          </div>
        </div>

        <div class="col-5">
          <div class="p-column-value">
            Newsletter
          </div>
          <div class="p-column-value font-normal">
            {{ activity.newsletterName }}
          </div>
        </div>
      </div>
      <div class="flex col-12">
        <div class="col-7">
          <div class="p-column-value">
            Document
          </div>
          <div>
            <a :href="activity.portalUrl" target="_blank" class="p-button-link p-column-value no-underline font-normal">
              {{ activity.docTitle }}</a>
          </div>
        </div>
        <div class="col-5">
          <div class="p-column-value">
            Buyer's Journey
          </div>
          <div class="p-column-value font-normal">
            <img :src="activity?.buyersJourneyBadge?.icon" width="55">
          </div>
        </div>
      </div>
      <div class="flex col-12">
        <div class="col-7">
          <div class="p-column-value">
            Document Type
          </div>
          <div class="p-column-value font-normal">
            {{ activity.docType }}
          </div>
        </div>
        <div class="col-5">
          <div class="p-column-value">
            Portal
          </div>
          <div class="p-column-value font-normal">
            {{ activity.portal }}
          </div>
        </div>
      </div>
      <div v-if="activity.specificsModel || activity.specifics" class="flex col-12">
        <specifics-questions v-model="activity.specificsModel" :specifics-freetext="activity.specifics" />
      </div>
    </div>
  </div>
  <!-- </template>
  </Card> -->
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import searchService from '@/services/searchService';
import { EngagementSearchDetails } from '@/models/search/engagementSearchDetails';
import { ActivityType } from '@/models/activity-review/activityType';
import { Options, Vue } from 'vue-class-component';
import { READER_CARD_COLORS_MAP } from '@/models/analytics/readerCardColors';
import dayjs from 'dayjs';
import SpecificsQuestions from './SpecificsQuestions.vue';
import { Specifics } from '@/models/analytics/Specifics';
import { fullUrl } from '@/utils/urlUtils';
import { Quality } from '@/models/activity-dissemination';

@Options({
  components: {
    'specifics-questions': SpecificsQuestions,
  },
})
export default class ActivityListItem extends Vue {
  @Prop({ default: '' }) activityId!: number;
  @Prop({ default: '' }) activityType!: ActivityType;
  @Prop({ default: 1 }) quality!: number;

  activity: Partial<EngagementSearchDetails> = {};
  loading: boolean = true;
  specifics: Specifics | undefined = new Specifics();

  @Watch('activityId', { immediate: true })
  async idChanged(id: string) {
    if (!id) {
      return;
    }

    this.loading = true;
    this.activity = await searchService.getDetails(this.activityType, id);
    this.loading = false;
  }

  get getCardBgColor(): string {
    return READER_CARD_COLORS_MAP[this.quality || 3];
  }

  get clientConsentDate(): string {
    if (this.activity?.consentToPrivacyDate) {
      return dayjs(this.activity.consentToPrivacyDate).format('YYYY-MM-DD');
    }
    return 'NO CONSENT';
  }

  get fullLinkedInUrl(): string {
    return fullUrl(this.activity.linkedInUrl || '');
  }
}
</script>
