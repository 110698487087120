import { ActivityStatus, leadStatusTranslation } from '@/models/activity-review/activityStatus';
import { ActivityType } from '@/models/activity-review/activityType';
import dayjs from 'dayjs';

export class Activity {
  id: string;
  activityType: ActivityType;
  documentTitle: string;
  online: string;
  firstName: string;
  lastName: string;
  email: string;
  city: string;
  state: string;
  country: string;
  approvedDate?: Date;
  leadType: string;
  status: ActivityStatus;
  dropDate: Date;
  documentCompanyName: string;
  rejectReason: string;
  jobTitle: string;
  activitySource: string;
  specifics: string;
  trackableUrlId: number;
  documentCompanyId: number;
  friendlyId: number;

  constructor(fields: Partial<Activity> = {}) {
    if (fields) {
      Object.assign(this, fields);
    }
  }

  public get formattedDropDate(): string {
    return this.formattedDate(this.dropDate);
  }

  public get formattedApprovedDate(): any {
    return this.approvedDate && this.formattedDate(this.approvedDate);
  }

  public get statusText(): any {
    return leadStatusTranslation(this.status);
  }


  private formattedDate(val: Date) {
    return dayjs(val).format('YYYY-MM-DD hh:mm:ss A');
  }
}
