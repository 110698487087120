import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex p-fluid pb-2" }
const _hoisted_2 = { class: "col-12 p-0" }
const _hoisted_3 = { class: "p-float-label" }
const _hoisted_4 = { for: "tag" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _createVNode(_component_AutoComplete, {
          id: "tag",
          "model-value": _ctx.local.value,
          suggestions: _ctx.filteredTags,
          field: "name",
          multiple: true,
          "complete-on-focus": "true",
          onComplete: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onAnalystTagSearchChange($event.query))),
          onItemSelect: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateField($event.value))),
          onItemUnselect: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deleteField($event.value)))
        }, null, 8, ["model-value", "suggestions"]),
        _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.label), 1)
      ])
    ])
  ]))
}