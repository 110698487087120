<template>
  <form @submit.prevent="save">
    <div class="flex px-4 pb-2">
      <div class="col-6" />
      <div class="col-6 flex flex-row-reverse align-items-center">
        <Button type="submit" class="p-button-success p-button-raised p-button-rounded ml-3" label="Save" />

        <div v-if="saveSuccess" class="success-message flex p-0 m-0 pr-2 flex">
          Saved.
          <span class="material-icons-outlined pl-1"> check_circle_outline </span>
        </div>
      </div>
    </div>
    <cp-export-column-manager :user-id="editedUser.id" @set-selections="setSelections" />
  </form>
</template>
<script lang="ts">
import { mapGetters } from 'vuex';
import { Prop, Watch } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';

import { AppUser } from '@/models/appUser';

import DefaultColumnsService from '@/services/defaultColumnService';
import { MSG_MUTATIONS } from '@/store/modules/msg.module';
import { SEARCH_MODULE } from '@/store/modules/search.module';

import ExportColumnManager from './ExportColumnManager.vue';
import { Vue, Options } from 'vue-class-component';
import { FieldCode } from '@/models/filters';

@Options({
  computed: {
    ...mapGetters('auth', ['userId']),
  },
  components: {
    'cp-export-column-manager': ExportColumnManager,
  },
})
export default class DefaultColumns extends Vue {
  userId!: string;
  @Prop({ required: true }) editedUser: AppUser;
  declare $refs: { form: HTMLFormElement };
  exportColumns: FieldCode[] = [];
  saveSuccess: boolean = false;

  public get routeUserId(): string {
    return this.$route.params['id'].toString();
  }

  get userName() {
    return this.editedUser.email
      ? `${this.editedUser.firstName} ${this.editedUser.lastName}`
      : 'USER';
  }

  formIsValid(): boolean {
    return true;
  }

  async setSelections(exportColumns: FieldCode[]) {
    this.exportColumns = cloneDeep(exportColumns);
  }

  async save() {
    try {
      // console.debug(this.exportColumns);
      const exportColumns = await DefaultColumnsService.save(
        this.routeUserId,
        this.exportColumns
      );
      if (this.routeUserId === this.userId) {
        this.$store.commit(`search/${SEARCH_MODULE.SET_EXPORT_DEFAULTS}`, exportColumns);
      }
      this.exportColumns = exportColumns;

      this.$store.commit(`msg/${MSG_MUTATIONS.MSG}`, { text: 'Default Columns Saved' });

      this.saveSuccess = true;
      setTimeout(() => {
        this.saveSuccess = false;
      }, 3000);
    } catch (error) {
      this.$store.commit(`msg/${MSG_MUTATIONS.MSG}`, { text: error, type: 'error' });

      this.$toast.add({
        severity: 'error',
        summary: '',
        detail: 'Error saving default filters.',
        life: 3000,
        closable: false
      });
    }
  }
}
</script>
