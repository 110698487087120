<template>
  <Card :class="value == 0 ? ['p-card no-results-card'] : 'p-count-card'">
    <template #content>
      <ProgressSpinner v-if="loading"
        class="flex align-items-center justify-content-center" />
      <cp-animated-integer v-show="value !== 0 && loading == false"
        :value="value"
        class="company-count pt-4" />
      <cp-no-data-component v-if="value === 0 && loading == false"
        :chart-type="'widget'" />
      <div :class="value !== 0 ? 'text-center pt-0' : 'sub-no-data'">
        {{ subheading }}
      </div>
    </template>
  </Card>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import ReaderCoService from '@/services/readerCoService';
import AnimatedIntegerComponent from '@/components/common/animatedInteger.component';
import { cloneDeep, isEqual } from 'lodash';
import { StatType } from '@/models/analytics/statType';
import { filterValueExists } from '@/utils/filters';
import { FilterModel } from '@/models/search';
import { Filter } from '@/models/filters';
import { Vue, Options } from 'vue-class-component';
import NoChartDataComponent from '@/components/common/NoChartDataComponent.vue';

@Options({
  components: {
    'cp-animated-integer': AnimatedIntegerComponent,
    'cp-no-data-component': NoChartDataComponent,
  },
})
export default class EngagementsStatCard extends Vue {
  @Prop({ default: () => <FilterModel>{ filters: [], indices: [] } })
  public filterModel: FilterModel;
  @Prop({ default: () => <Filter>{ field: 'ReaderOrgs' } }) public orgFilter: Filter;
  @Prop({ required: true }) stat!: StatType;
  subheading: string = '';
  icon: string = '';
  iconBg: string = 'white';
  value: number = 0;
  loading = true;

  @Watch('filterModelWithOrg', { immediate: true, deep: true })
  async filtersChanged(newVal: FilterModel, oldVal: FilterModel) {
    const noChanges = isEqual(newVal, oldVal);
    if (
      !newVal ||
      noChanges ||
      !filterValueExists(newVal.filters, 'Clients') 
    
    ) {
      return;
    }

    this.loading = true;
    await this._executeApiRequest(newVal);
    // console.log("stat", this.value)
    this.loading = false;
  }

  get filterModelWithOrg() {
    const filterModel = cloneDeep(this.filterModel);
    filterModel.filters.push(this.orgFilter);
    return filterModel;
  }

  private async _executeApiRequest(filterModel: FilterModel) {
    switch (this.stat) {
      case 'engagements':
        this.subheading = 'Engagements with your content';
        this.icon = 'recent_actors';
        this.iconBg = 'error';
        this.value = (await ReaderCoService.getTotalEngagementsCount(filterModel)) || 0;
        break;
      case 'users':
        this.subheading = 'Unique individuals with your content';
        this.icon = 'person';
        this.iconBg = 'info';
        this.value =
          (await ReaderCoService.getTotalUniqueUserEngagements(filterModel)) || 0;
        break;
      default:
        // this.subheading = 'Pieces of unique content with engagement';
        this.icon = 'description';
        this.iconBg = 'warning';
        // this.value = stats.uniqueDocs || 0;
        break;
    }
  }
}
</script>

<style scoped lang="scss">
.sub-no-data {
  opacity: 1;
  font-family: Overpass, sans-serif;
  font-size: 0.8rem;
  font-weight: normal;
  text-align: center;
}</style>
