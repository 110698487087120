import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12 flex flex-row-reverse align-items-center" }
const _hoisted_3 = { class: "p-secondary material-icons text-xs pl-1" }
const _hoisted_4 = {
  id: "saved-queries-table",
  class: "col-14 pt-4"
}
const _hoisted_5 = ["title", "onClick"]
const _hoisted_6 = { style: {"display":"flex","justify-content":"center"} }
const _hoisted_7 = {
  key: 0,
  class: "flex align-items-center justify-content-center py-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Message = _resolveComponent("Message")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_RadioButton = _resolveComponent("RadioButton")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      ($setup.currentUser.email != $setup.authenticatedUser.email)
        ? (_openBlock(), _createBlock(_component_Message, {
            key: 0,
            closable: false,
            style: {"background-color":"#E5F9FA6B"}
          }, {
            default: _withCtx(() => [
              _createTextVNode(" NOTE: You are currently in "),
              _createElementVNode("b", null, _toDisplayString($setup.usersName), 1),
              _createTextVNode(" Account. Be mindful when making any changes, especially when deleting filters, to avoid unintended impacts. ")
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, [
          _createVNode(_component_Button, {
            label: "New User",
            class: "p-button-success p-button-raised",
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.goToUserFilters('')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" New Search ")
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_DataTable, {
        value: $setup.pagedResults.items,
        "data-key": "id",
        paginator: true,
        rows: $setup.searchCriteria.pageSize,
        "rows-per-page-options": $setup.pageSizeOptions,
        lazy: true,
        "total-records": $setup.pagedResults.total,
        "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown",
        "current-page-report-template": "Showing {first} to {last} of {totalRecords}",
        "paginator-position": "both",
        onPage: _cache[2] || (_cache[2] = ($event: any) => ($setup.onPage($event))),
        onSort: _cache[3] || (_cache[3] = ($event: any) => ($setup.onSort($event)))
      }, {
        empty: _withCtx(() => [
          (!$setup.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, " No saved search data exists. "))
            : _createCommentVNode("", true)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            field: "name",
            header: "Search Name",
            sortable: "",
            class: "w-6rem"
          }, {
            body: _withCtx((slotProps) => [
              _createElementVNode("a", {
                class: "cursor-pointer",
                title: slotProps.data.name,
                onClick: ($event: any) => ($setup.goToUserFilters(slotProps.data.id))
              }, _toDisplayString(slotProps.data.name), 9, _hoisted_5)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            field: "updatedOn",
            header: "Date Modified",
            sortable: "",
            class: "w-5rem"
          }, {
            body: _withCtx((slotProps) => [
              _createTextVNode(_toDisplayString($setup.format_date(slotProps.data.updatedOn)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            field: "updatedBy",
            header: "Modified By",
            sortable: "",
            class: "w-5rem"
          }, {
            body: _withCtx((slotProps) => [
              _createTextVNode(_toDisplayString(slotProps.data.updatedBy), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            field: "createdOn",
            header: "Date Created",
            sortable: "",
            class: "w-5rem"
          }, {
            body: _withCtx((slotProps) => [
              _createTextVNode(_toDisplayString($setup.format_date(slotProps.data.createdOn)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            field: "createdBy",
            header: "Created By",
            sortable: "",
            class: "w-5rem"
          }, {
            body: _withCtx((slotProps) => [
              _createTextVNode(_toDisplayString(slotProps.data.createdBy), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            header: "Make Default",
            class: "w-5rem"
          }, {
            body: _withCtx((slotProps) => [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_RadioButton, {
                  id: slotProps.data.id,
                  modelValue: $setup.selectedQuery,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.selectedQuery) = $event)),
                  value: slotProps.data.id,
                  class: "p-button-rounded p-button-text",
                  onClick: ($event: any) => ($setup.queryPrompt($event, slotProps.data, slotProps.data.isDefault))
                }, null, 8, ["id", "modelValue", "value", "onClick"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            header: "Delete",
            class: "w-2rem"
          }, {
            body: _withCtx((slotProps) => [
              (!slotProps.data.isDefault)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    icon: "pi pi-trash",
                    class: "p-button-rounded p-button-text",
                    onClick: ($event: any) => ($setup.deletePrompt($event, slotProps.data, slotProps.data.isDefault))
                  }, null, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value", "rows", "rows-per-page-options", "total-records"])
    ])
  ], 64))
}