export type ActivityStatus = 'EditAfterApproval' | 'Rejected' | 'Approved' | 'RejectedAfterApproval' | 'Pending';

export function leadStatusTranslation(activityStatus: ActivityStatus) {
  const activityStatusMap = new Map([
    ['EditAfterApproval', 'Edit After Approval'],
    ['Rejected', 'Rejected'],
    ['Approved', 'Approved'],
    ['RejectedAfterApproval', 'Rejected After Approval'],
    ['Pending', 'Pending']
  ]);

  return activityStatusMap.get(activityStatus);
}
