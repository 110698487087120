import { FriendlyFilterRow } from './filterFactory';
import dayjs from 'dayjs';
import { Filter } from './filter';
import { FieldCode } from './fieldCode';
import { startCase } from 'lodash';

export interface DateRangeFilterValue { from?: Date; to?: Date; }

export class DateRangeFilter implements Filter {
  field: FieldCode;
  value: DateRangeFilterValue = {};
  endDateInclusive: boolean = false;
  readonly $type = 'DateRangeFilter';

  constructor(opts: Partial<DateRangeFilter> = {}) {
    Object.assign(this, opts);
    this.endDateInclusive = true;

    const dtFrom = this.value.from;
    const dtTo = this.value.to;

    const oneYearAgo = dayjs().subtract(1, 'year').toDate();

    //date range restriction 
    if (!dtFrom && !dtTo) {
      this.value.from = oneYearAgo;
      this.value.to = new Date();
    }
    else if (!dtFrom && dtTo) {
      const oneYearBeforeToDt = dayjs(dtTo).subtract(1, 'year').toDate();
      this.value.from = oneYearBeforeToDt;
    }
    else if (dtFrom && !dtTo) {
      const oneYearAfterFromDt = dayjs(dtFrom).add(1, 'year').toDate();
      if (oneYearAfterFromDt > new Date())
        this.value.to = new Date();
      else
        this.value.to = oneYearAfterFromDt;
    } else {
      this.value.to = dtTo ? new Date(dtTo.toString()) : new Date();
      this.value.from = dtFrom ? new Date(dtFrom.toString()) : oneYearAgo;
    }
  }

  public isEmpty() {
    return !this.value?.from && !this.value?.to;
  }

  public clearValue() {
    this.value.from = DEFAULT_FROM_DATE;
    this.value.to = DEFAULT_TO_DATE;
  }

  getFriendlyText(labelOverride: string): FriendlyFilterRow {
    const label = `${labelOverride || startCase(this.field)}`;
    let dateText = '';
    let operation = '';
    if (this?.value.from && this?.value.to) {
      operation = 'is between';
      // console.debug(`DateRangeFilter.getFriendlyText() - { from, to }`, this.value.from, this.value.to);
      dateText = `${this.value.from.toLocaleDateString()} and ${this.value.to.toLocaleDateString()}`;
    } else if (this?.value.from) {
      operation = 'is on or after';
      dateText = `${this.value.from.toLocaleDateString()}`;
    } else if (this?.value.to) {
      operation = `is ${this.endDateInclusive ? 'on or' : ''} before`;
      dateText = `${this.value.to.toLocaleDateString()}`;
    }
    // return <`${label} ${dateText}`>;
    return <FriendlyFilterRow>{ filterName: label, operation, value: dateText };
  }
}

export const DEFAULT_FROM_DATE = dayjs().subtract(12, 'month').toDate();
export const DEFAULT_TO_DATE = new Date();

export const DEFAULT_DATE_RANGE_FILTER: DateRangeFilter = new DateRangeFilter({
  field: 'DateRange',
  value: {
    from: DEFAULT_FROM_DATE,
    to: DEFAULT_TO_DATE
  }
});
