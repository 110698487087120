import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { CurrentUserState } from '../models/currentUserState';
const CURRENT_USER_KEY = 'cp-current-user';

export const USER_MODULE = Object.freeze({
  SET_CURRENT_USER: 'setCurrentUser',
  GET_CURRENT_USER: 'getCurrentUser',
  CLEAR_CURRENT_USER: 'clearCurrentUser'
});

function initFromLocalStorage(): CurrentUserState | undefined {
  const currentUserStr = localStorage.getItem(CURRENT_USER_KEY) as string;

  if (currentUserStr != 'undefined' && currentUserStr) {
    const currentUser = JSON.parse(currentUserStr) as CurrentUserState;
    return currentUser;
  }

  return undefined;
}

const GETTERS: GetterTree<CurrentUserState, any> = {
  userId: state => state.userId,
  firstName: state => state.firstName ?? '',
  lastName: state => state.lastName ?? '',
  email: state => state.email?? '',
  searchFields: state => state.searchFields??[],
  clients: state => state.clients??[],
  isClientUser: state => state.isClientUser ?? false
};

const MUTATIONS: MutationTree<CurrentUserState> = {
  [USER_MODULE.SET_CURRENT_USER](state, {userId, firstName, lastName, email, searchFields, defaults, clients, isClientUser}) {
    state.userId = userId;
    state.email = email;
    state.firstName = firstName;
    state.lastName = lastName;
    state.searchFields = searchFields;
    state.defaults = defaults;
    state.clients = clients;
    state.isClientUser = isClientUser;
    localStorage.setItem(CURRENT_USER_KEY, JSON.stringify(state));
    // console.debug(`currentUserModule.SET_CURRENT_USER() - { state, userId, firstName, lastName, email }`, state, userId, firstName, lastName, email);
  }
};

const ACTIONS: ActionTree<CurrentUserState, any> = {
  [USER_MODULE.GET_CURRENT_USER]({ commit }) {
    const data = initFromLocalStorage() ?? {userId: '', email: '', firstName: '', lastName: '', searchFields: []};
    commit(USER_MODULE.SET_CURRENT_USER, data);
    // console.debug(`USER_MODULE.GET_CURRENT_USER() - { user }`, data);
  },
  [USER_MODULE.CLEAR_CURRENT_USER]({ commit }) {
    localStorage.removeItem(CURRENT_USER_KEY);
    commit(USER_MODULE.SET_CURRENT_USER, { email: null, firstName: null, lastName: null, userId: null, searchFields: [] });
  }
};

export const userModule: Module<CurrentUserState, any> = {
  namespaced: true,
  state: initFromLocalStorage(),
  getters: GETTERS,
  mutations: MUTATIONS,
  actions: ACTIONS
};