import { ACTIVITY_TYPE_LOOKUPS } from './../models/analytics/activityTypes';
import { INDEX_LOOKUPS } from '@/models/analytics/activityTypes';
import { Lookup } from '@/models/lookup';
import { ref, Ref } from 'vue';
import { DEFAULT_TIMEOUT_TIME } from '@/models/common/constants';

export default function useActivityTypeSecurity(selectedIndices: Ref<Array<Lookup<any>>>, filteredIndices: Ref<Array<Lookup<any>>>, basicOnly: Ref<boolean> = ref(false)) {
  const indices: Array<Lookup<any>> = basicOnly.value ? ACTIVITY_TYPE_LOOKUPS : INDEX_LOOKUPS;
  const options = ref(indices);

  const searchActivityTypes = (event) => {
    setTimeout(() => {
      if (!event.query?.trim().length) {
        filteredIndices.value = options.value.filter((e1) => selectedIndices.value.findIndex((e2) => e2.name == e1.name) === -1);
      } else {
        const notSelected = options.value.filter((e1) => selectedIndices.value.findIndex((e2) => e2.name == e1.name) === -1);

        filteredIndices.value = notSelected.filter((type) => type.name.toLowerCase().startsWith(event.query.toLowerCase()));
      }
    }, DEFAULT_TIMEOUT_TIME);
  };

  selectedIndices.value = options.value;
  return { options, searchActivityTypes };
}