import {IntLookup} from '@/models/lookup';

export enum ProfileStatus {
  Standard = 1,
  Basic = 2,
  Incomplete = 3
}

export const PROFILE_STATUS_LOOKUPS: IntLookup[] = [
  {id: 1, name: 'Standard'},
  {id: 2, name: 'Basic'},
  {id: 3, name: 'Incomplete'}
];
