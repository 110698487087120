<template>
  <div v-if="modelValue" class="flex align-items-center p-0 my-2">
    <div class="col-12 lg:col-2 p-secondary-color px-0">
      Notification Settings
    </div>
    <div class="col-12 md:col-6 lg:col-7">
      <span class="p-float-label p-fluid">
        <Dropdown v-model="modelValue.notificationClaimLevel" :options="notificationClaimLevelLookups" placeholder="Select Notification Access Level" />
        <label for="manageNotifications">Manage Notifications</label>
      </span>
    </div>
    <div class="col-12 md:col-6 lg:col-3 flex md:flex-row-reverse lg:flex-row-reverse align-items-center">
      <label class="ml-2 p-secondary-color">Enable Notification Dashboard</label>
      <Checkbox v-model="modelValue.viewNotifications" class="ml-4" :binary="true" />
    </div>
  </div>
</template>
<script lang="ts">
import { Prop } from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component';

import { AppUser } from '@/models/appUser';
import { BASIC_NOTIFICATION_CLAIM_LEVELS, ClaimAccessLevel, EMPLOYEE_CLAIM_LEVELS } from '@/models/security';
import { JwtTokenUser } from '@/store/models';

@Options({
  //   components: {
  //     'cp-terms-field-manager': TermsFieldManager
  //   },
  name: 'cp-profile-notification-settings'
})
export default class ProfileNotificationSettings extends Vue {
  @Prop({ required: true }) modelValue: AppUser;

  loggedInUser: JwtTokenUser;

  get notificationClaimLevelLookups(): ClaimAccessLevel[] {
    if (this.modelValue.role === 'Client') {
      return BASIC_NOTIFICATION_CLAIM_LEVELS;
    }
    if (this.loggedInUser.hasMinRole('Admin')) {
      return EMPLOYEE_CLAIM_LEVELS;
    }
    return [];
  }

  async created() {
    this.loggedInUser = <JwtTokenUser>this.$store.state.auth.user;
  }
}
</script>