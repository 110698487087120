import dayjs from 'dayjs';

export enum RelativeDateRange {
  Custom = 'custom',  
  Last30Days  = '-30d',
  Last60Days = '-60d',
  Last90Days = '-90d',
  Last1Year = '-1y',
  Last2Years = '-2y',
  Last1CalendarYear = '/y-1y',
  Last2CalendarYears = '/y-2y'  
}

export function dateFromRelativeDateRange(value: RelativeDateRange) {
  switch (value) {
      case RelativeDateRange.Last30Days:
        return dayjs().subtract(30, 'days').toDate();
      case RelativeDateRange.Last60Days:
        return dayjs().subtract(60, 'days').toDate();
      case RelativeDateRange.Last90Days:
        return dayjs().subtract(90, 'days').toDate();
      case RelativeDateRange.Last1Year:
        return dayjs().subtract(1, 'year').toDate();
      case RelativeDateRange.Last1CalendarYear:
        return dayjs().startOf('year').subtract(1, 'year').toDate();
      case RelativeDateRange.Last2Years:
        return dayjs().subtract(2, 'years').toDate();  
      case RelativeDateRange.Last2CalendarYears:
        return dayjs().startOf('year').subtract(2, 'years').toDate();  
        
  }  
}
