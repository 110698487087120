<template>
  <div class="flex justify-content-between">
    <h2 class="flex align-items-center">
      {{ titleTrim }}
    </h2>
    <div class="pt-2">
      <cp-export-chart-options :chart="chartExport" @chart-updated="chartUpdated" />
    </div>
  </div>
  <div ref="chart" v-cp-color-picker class="flex align-items-center pt-3" />
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import Highcharts, { Chart } from 'highcharts';
import {
  getSolidColorPerSliceByBuyerJourneyStage,
  getSplineChartConfig, getSplineChartNoDataOptions,
  LineChartOptions,
  setDefaults
} from '@/utils/highchartUtils';
import { isEqual } from 'lodash';
import readerDashboardService from '@/services/readerDashboardService';
import { ReaderFilterModel } from '@/models/search';
import ExportChartOptionsComponent from '../common/ExportChartOptions.vue';
import { TextFilter } from '@/models/filters';
import { Vue, Options } from 'vue-class-component';
import { BUYERS_JOURNEY_MAP } from '@/models/buyersJourney';
import { EngagementsByBuyersJourney } from '@/services/readerCoService';
import NoChartDataComponent from '@/components/common/NoChartDataComponent.vue';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { renderSplineChart } from '@/plugins/highchart-extend';
NoDataToDisplay(Highcharts);


@Options({
  name: 'cp-engagements-by-month',
  components: {
    'cp-export-chart-options': ExportChartOptionsComponent,
    'cp-no-data-component': NoChartDataComponent
  },
})
export default class EngagementsByMonthShare extends Vue {
  @Prop({ default: 5 }) size!: string;
  @Prop( {default: () => false}) noReaderFound: boolean;
  @Prop({ default: () => <ReaderFilterModel>{ filters: [], indices: [] } })
  public filterModel!: ReaderFilterModel;
  @Prop() emailFilter!: TextFilter;

  data1: EngagementsByBuyersJourney[] = [];
  // total = 0;
  declare $refs: { chart: HTMLDivElement };
  chartExport: Chart | null = null;
  series: any;
  showTitle: string = '';
  nameUpdate: boolean = false;

  async mounted() {
    await this.computedValueChanged(this.filterModel, <ReaderFilterModel>{ filters: [], indices: [] });
  }

  @Watch('filterModel')
  async computedValueChanged(newVal: ReaderFilterModel, oldVal: ReaderFilterModel) {
    const noChanges = isEqual(newVal, oldVal);
    if (!newVal || noChanges) {
      return;
    }

    this.data1 = newVal.noReaderFound ? [] : await readerDashboardService.getActivitiesByBuyersJourney(newVal);
    this._renderChart();
  }

  get title() {
    this.showTitle = 'Engagements and Buyer\'s Journey';
    return this.showTitle;
  }

  get titleTrim() {
    if (this.showTitle == undefined) {
      this.showTitle = 'Engagements and Buyer\'s Journey';
    }
    else if (this.nameUpdate) {
      this.nameUpdate = false;
      this.showTitle = this.showTitle.length > 32
        ? this.showTitle.substring(0, 32) + '...  '
        : this.showTitle;
    }
    return this.showTitle;
  }

  chartUpdated(value: Chart) {
    this.chartExport = value;
    this.nameUpdate = true;
    this.showTitle = (this.chartExport as any).options.exporting.chartOptions.title.text;
    this.$forceUpdate();
  }

  private _renderChart() {
    setDefaults();
    const xAxisLegend = this.data1[0]?.month;
    const yAxisTitle = 'Engagements';
    this.series = this.getChartData(this.data1);
    const options: LineChartOptions = <LineChartOptions>{
      exportTitle: this.title,
      xAxisLegend,
      yAxisTitle,
      series: this.series
    };

    this.chartExport = (Highcharts as any).chart(
      this.$refs.chart,
      this.data1.length > 0 ? getSplineChartConfig(options) : 
        getSplineChartNoDataOptions(options), (chartInstance) => { // on complete
        if (this.data1.length < 1) {
          renderSplineChart(chartInstance, getSplineChartNoDataOptions(options).lang.noDataImage);
        }
      });
  }

  getChartData(data: EngagementsByBuyersJourney[]) {
    return data.map(
      item => ({
          name: BUYERS_JOURNEY_MAP[item.name],
          month: item.month,
          data: item.data,
          color: getSolidColorPerSliceByBuyerJourneyStage(BUYERS_JOURNEY_MAP[item.name]),
          marker: {
            symbol: 'circle',
            fillColor: '#FFFFFF',
            lineWidth: 1,
            radius: 3,
            lineColor: null,
          },
        })
    );
  }
}
</script>