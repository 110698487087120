import axios from 'axios';
import {ReaderOrgLookup} from '@/models/readerOrgLookUp';


export interface OrgLookupsWithUnmatchedNames {
    lookups: ReaderOrgLookup[];
    unmatchedNames: string[];
}

export interface ReaderOrgRequestParams {
    Names: string[]
}
const BASE_URL = '/api/program-performance/roi';
export default {

    async getReaderOrgByNames(names: string[]): Promise<OrgLookupsWithUnmatchedNames> {

        const opts:ReaderOrgRequestParams = { Names: names } ;
        const response = await axios.post(`${BASE_URL}/reader-orgs`, opts);
        return response.data ;
    }

};
