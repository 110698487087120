<template>
  <div class="flex p-fluid">
    <div class="col-6 p-0">
      <cp-date-input v-model="dateRange.from" label="From Date" :submitted="submitted" :v="v$.dateRange.from" />
    </div>
    <div class="col-6 p-0 pl-4">
      <cp-date-input v-model="dateRange.to" label="To Date" :submitted="submitted" :v="v$.dateRange.to" />
    </div>
  </div>
  <small v-if="(v$?.dateRange?.from?.minValue != null && (v$.dateRange.from.minValue.$invalid || v$.dateRange.to.minValue.$invalid) && submitted) || v$.$pending"
    class="p-error"><br>From Date should not be greater than To Date or Current date</small>
</template>

<script lang="ts">
import { computed, PropType, ref, watch } from 'vue';
import useVuelidate from '@vuelidate/core';
import { DateRangeFilter, DateRangeFilterValue } from '@/models/filters';
import DateInputComponent from '@/components/common/DateInput.vue';

export default {
  name: 'CpDateRangeSearchField',
  components: {
    'cp-date-input': DateInputComponent,
  },
  inheritAttrs: true,
  props: {
    modelValue: { type: Object as PropType<DateRangeFilter>, default: new DateRangeFilter() },
    label: { type: String, default: '' },
    submitted: Boolean,
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const dateRange = ref<DateRangeFilterValue>({ ...props.modelValue.value });

    const rules = computed(() => {
      const validationModel = {
        dateRange: {
          from: {
            minValue(val) {
              const result = new Date(val) <= new Date();
              return result;
            },
            maxValue(val, { to }) {
              if (!to) return true;
              const result = new Date(to) >= new Date(val);
              return result;
            },
          },
          to: {
            minValue(val, { from }) {
              if (!val) return true;
              const result = new Date(val) >= new Date(from);
              return result;
            },
          },
        },
      };

      return validationModel;
    });
    const v$ = useVuelidate(rules, { dateRange });

    const onDateChanged = () => {
      const newVal = new DateRangeFilter({
        ...props.modelValue,
        value: { ...dateRange.value },
      });
      emit('update:modelValue', newVal);
    };

    watch(
      dateRange,
      () => {
        onDateChanged();
      },
      { deep: true, immediate: true }
    );

    return { dateRange, v$ };
  },
};
</script>

<style lang="scss" scoped>
.range-seperator {
  font-size: 25px;
  line-height: 70px;
}
</style>
