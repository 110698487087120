<template>
  <ProgressBar v-if="navVisible && inProgressCount !== 0" mode="indeterminate" style="height: 0.3em" />

  <cp-nav v-if="navVisible" />
  <cp-top v-if="navVisible" />

  <div class="layout-main">
    <Toast position="top-center">
      <template #message="slotProps">
        <div class="flex flex-row align-items-center">
          <div class="flex py-3 align-items-center justify-content-center">
            <span v-if="slotProps.message.severity === 'error'" class="material-icons pr-3">
              error_outline
            </span>
            <span v-else-if="slotProps.message.severity === 'warn'" class="material-icons pr-3"> report </span>
            <span v-else class="material-icons pr-3"> done_all </span>
          </div>
          <div class="flex py-4 flex-grow-1 align-items-center justify-content-center h-full">
            <h4>{{ slotProps.message.summary }}</h4>
            <p>{{ slotProps.message.detail }}</p>
          </div>
        </div>
      </template>
    </Toast>
    <ConfirmPopup>
      <template #message="slotProps">
        <div class="p-confirm-popup-content">
          <span class="mt-1 p-confirm-icon" :class="slotProps.message.icon" />
          <p class="ml-2">
            {{ slotProps.message.message }}
          </p>
        </div>
      </template>
    </ConfirmPopup>

    <router-view :key="$route.path" class="side-space" />
  </div>
</template>

<script lang="ts">
import { Watch } from 'vue-property-decorator';
import SideBar from '@/components/common/SideBar.vue';
import TopBar from '@/components/common/TopBar.vue';
import { PUBLIC_PAGES } from '@/utils/publicRoutes';
import { AUTH_ACTIONS } from '@/store/modules/auth.module';
import { AppMessage } from '@/store/models/AppMessage';
import { Vue, Options } from 'vue-class-component';

@Options({
  name: 'App',
  components: {
    'cp-nav': SideBar,
    'cp-top': TopBar,
  },
})
export default class App extends Vue {
  navVisible = false;
  showSnackbar = true;

  get message(): AppMessage {
    return this.$store.state.msg.message;
  }

  get inProgressCount(): number {
    return this.$store.state.msg.inProgressCount;
  }

  @Watch('$route', { immediate: true })
  routeChange() {
    this.navVisible = !PUBLIC_PAGES.some((p) => this.$route.path.startsWith(p));
  }

  @Watch('navVisible', { immediate: true })
  hideSidebarPadding() {
    window.document.body.style.paddingLeft = '0px';
  }

  mounted() {
    this.$store.dispatch(`auth/${AUTH_ACTIONS.MONITOR_TOKEN}`);
  }
}
</script>

<style lang="scss" scoped>
.fixed-progress {
  position: fixed;
  top: 65px;
  z-index: 1000;
}

@media print {

  .main-viewport,
  .main-container {
    padding: 0 !important;
  }
}
</style>
<style>
.p-toast {
  width: min(50rem, 100vw) !important;
}
.p-toast .p-toast-message.p-toast-message-error {
   background: #EB6670;
   box-shadow: 0px 3px 6px #A2AAB596;
   color: white;
   opacity: 0.95;
 }
.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
  color: white;
}
</style>
