import { sanitizeUrlParam } from '@/utils/urlUtils';

export class MostActiveReaderEngagements {

    userIdOrEmail: string;
    orgId: string;
    orgName: string;
    company: string;
    totalEngagements: number;
    totalDocuments: number;
    documentTitle: string;
    jobTitle: string;
    documentUrl: string;
    firstName: string;
    lastName: string;

    constructor(data: Partial<MostActiveReaderEngagements> | null = null) {
        if (data) {
            Object.assign(this, data);
        }
    }

    get getReaderURL() {
        return '/analytics/reader-dashboard/' + sanitizeUrlParam(this.userIdOrEmail);
    }

    get fullName() {
        return `${this.firstName || ''} ${this.lastName || ''}`.trim() || this.userIdOrEmail;
    }
}