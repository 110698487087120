

export enum OnboardingWorkflowStep {
    Begin,
    General,
    Company,
    Contacts,
    Content,
    Confirm
}

export class BasicOnboardingFormData {
  userId: string;
  name: string;
  email: string;
  companyId: number;
  senderEmail: string;
  sentFromName: string;
  isSubmitted: boolean;
  lastStepCompleted: OnboardingWorkflowStep

  constructor(data: Partial<BasicOnboardingFormData> = {}) {
    Object.assign(this, data);
  }
}
