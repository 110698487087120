export class MostActiveCosByCompanyTypeResult {
    companyType: string;
    activitiesCount: number;

    constructor(data: Partial<MostActiveCosByCompanyTypeResult> | null = null) {
        if (data) {
            Object.assign(this, data);
        }
    }
}

export class mostActiveCosByTypeResult {
    data: MostActiveCosByCompanyTypeResult[];
    contentThemesMap: Record<string, string>;

    constructor(data: Partial<mostActiveCosByTypeResult> | null = null) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
