export interface FieldMapping {
  targetField: string;
  sourceField: string;
}

export default class DataMapper {
  private _dataObj: Record<string, any> = {};

  constructor(sourceObj: Record<string, any>, fieldMappings: FieldMapping[], preserveUnmappedFields: boolean = false) {
    fieldMappings.forEach(m => {
      this._dataObj[m.targetField] = sourceObj[m.sourceField];
    });

    if (preserveUnmappedFields) {
      const unmappedFields = Object.keys(sourceObj).filter(key => (
        !fieldMappings.some(m => m.sourceField === key)
      ));

      unmappedFields.forEach(x => {
        this._dataObj[x] = sourceObj[x];
      });
    }
  }

  get mappedData(): Record<string, any> {
    return this._dataObj;
  }
}
