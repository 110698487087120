import { FieldCode, Filter, FriendlyFilterRow, NumericDisplayType } from '@/models/filters';
import { Operation, WithOperation, operationLabel } from '@/models/filters/operation';
import { startCase } from 'lodash';

export class NumericFilter implements Filter, WithOperation {
  field: FieldCode;
  op: Operation;
  value: number | null;
  displayType: NumericDisplayType

  readonly $type = 'NumericFilter';

  constructor(opts: Partial<NumericFilter> = {}) {
    Object.assign(this, opts);
    this.op = opts.op || 'Equals';
    this.displayType = opts.displayType || NumericDisplayType.Integer;
  }

  getFriendlyText(labelOverride: string): FriendlyFilterRow {
    const label = `${labelOverride || startCase(this.field)}`;
    const opStr = operationLabel[this.op];
    return <FriendlyFilterRow>{ filterName: label, operation: opStr, value: `${this.value}` };
  }

  isEmpty(): boolean {
    return this.value === null || this.value === undefined;
  }

  clearValue() {
    this.value = null;
  }
}
