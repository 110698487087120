<template>
  <div v-if="statusCode">
    <Message :closable="false"
      severity="info">
      <h2 v-if="statusCode === '404'">
        {{ pageNotFound }}
      </h2>
      <h2 v-else-if="statusCode === '403'">
        {{ restrictedAccess }}
      </h2>
      <h2 v-else>
        {{ otherError }}
      </h2>
      {{ message }}
    </Message>
  </div>
</template>
<script lang="ts">
import { Vue, Options } from 'vue-class-component';

@Options({ components: {} })
export default class Error extends Vue {
  pageNotFound = '404 Not Found';
  otherError = 'An unexpected error occurred';
  restrictedAccess = 'You do not have permission to access the requested resource!';

  get message() {
    return this.$route.params['message'];
  }
  get statusCode() {
    return this.$route.params['statusCode'];
  }
  get statusText() {
    return this.$route.params['statusText'];
  }
}
</script>
<style lang="scss" scoped></style>
