import { ReaderRecentEngagement } from '@/models/user-service/readerRecentEngagement';
import axios from 'axios';
import { IntervalBucket } from '@/models/intervalBucket';
import { NamedSeries } from '@/models/namedSeries';
import { FilterModel } from '@/models/search';
import { EngagementsByBuyersJourney } from './readerCoService';
import { fill } from '@/utils/buyersJourneyUtils';
import { filterModelParams, toIndices } from '@/utils/filters/filterUtils';

const BASE_URL = '/api/reader-dashboard';

class ReaderDashboardService {
  async getMostActiveReader(filterModel: FilterModel): Promise<string> {
    return (await axios.post(`${BASE_URL}/most-active-reader`, {
      ...filterModelParams(filterModel),
      ...toIndices(filterModel)
    })).data;
  }

  async getRecentActivities(filterModel: FilterModel, size: number = 5): Promise<ReaderRecentEngagement[]> {
    const activies = (await axios.post(`${BASE_URL}/recent-activities`, {
      ...filterModelParams(filterModel),
      ...toIndices(filterModel),
      size
    })).data;

    return activies.map(x => new ReaderRecentEngagement(x));
  }

  async getRecentCustomerRequests(filterModel: FilterModel, size: number = 5): Promise<ReaderRecentEngagement[]> {
    return (await axios.post(`${BASE_URL}/recent-customer-requests`, {
      ...filterModelParams(filterModel),
      ...toIndices(filterModel),
      size
    })).data;
  }

  async getActivitiesByMonth(filterModel: FilterModel): Promise<Array<IntervalBucket<Record<string, number>>>> {
    return (await axios.post(`${BASE_URL}/share-by-month`, {
      ...filterModelParams(filterModel),
      ...toIndices(filterModel)
    })).data;
  }

  async getActivitiesByBuyersJourney(filterModel: FilterModel): Promise<EngagementsByBuyersJourney[]> {
    const result = (await axios.post<EngagementsByBuyersJourney[]>(`${BASE_URL}/share-by-buyersjourney`, {
      ...filterModelParams(filterModel),
      ...toIndices(filterModel)
    })).data;
    const filledResult = fill(result);
    // console.debug(`ReaderDashboardService.getActivitiesByBuyersJourney() - data and filled data`, result, filledResult);
    return filledResult;
  }

  async getEngagementsByCategory(filterModel: FilterModel, category: string): Promise<NamedSeries[]> {
    return (await axios.post(`${BASE_URL}/by-category`, {
      ...filterModelParams(filterModel),
      ...toIndices(filterModel), category
    })).data;
  }
}

export default new ReaderDashboardService();
