import { DayOfMonth } from './../common/dayOfMonth';
import { DayOfWeek } from '@/models/common/dayOfWeek';
import { Frequency } from './frequency';
import { Lookup } from '../lookup';
import { Filter } from '@/models/filters';
import { InsightType, ThresholdDirection } from './behavior-change/';
import { NotificationTarget } from './alert-method';
import { NotificationType } from './notificationType';
import { WorkflowStep, WorkflowStepEnum } from './workflowStep';
import { DisabledReason, DisabledReasonType } from './disabledReasons';
import { FieldCode } from '../filters';

export class Notification {
  $type: NotificationType;
  id: string;
  name: string;
  filters: Filter[];
  indices: Array<Lookup<string>>;
  aggField: InsightType;
  activityMonths?: number;
  minimumThresholdPercent?: number;
  uniqueUserCount?: number;
  baselineActivityMonths?: number;
  minimumBaselineActivities?: number;
  minimumCount?: number;
  thresholdDirection: ThresholdDirection;
  targets: NotificationTarget[];
  frequency: Frequency;
  dayOfWeek?: DayOfWeek = 'Monday';
  dayOfMonth?: DayOfMonth = 'First';
  isComplete: boolean;
  scheduleActive: boolean;
  description: string;
  createdBy: string;
  isReadOnly: boolean;
  exportColumns?: FieldCode[];
  disabled: boolean;
  disabledReason: DisabledReason;
  disabledReasonType: DisabledReasonType;
  lastStepCompleted: WorkflowStep;

  constructor(opts: Partial<Notification> = {}) {
    Object.assign(this, opts);
  }

  get type() {
    return this.$type;
  }

  get isNew() {
    // const previousStep = WorkflowStep.Begin;
    // return this.lastStepCompleted > previousStep;
    return this.id === 'new';
  }

  get hasMinimumFilters() {
    if (this.isNew) {
      return false;
    }

    // todo: not just length... when we have specific minium filter requirements
    // we will have those here.
    if (this.filters?.length < 1) {
      return false;
    }

    const previousStep = WorkflowStepEnum.Begin;
    return WorkflowStepEnum[this.lastStepCompleted] > previousStep;
  }

  get hasTargets() {
    if (!this.hasMinimumFilters) {
      return false;
    }

    // todo: not just length...when we have specific minimum target requirements
    const isBehaviorRendered = ['ChangeInActivitiesNotification', 'SeriesOfActivitiesNotification'].includes(this.type);
    if (isBehaviorRendered && !this.isBehaviorConfigured) {
      return false;
    }

    // we will have those here.
    if (this.targets?.length < 1) {
      return false;
    }
    const previousStep = isBehaviorRendered ? WorkflowStepEnum.BehaviorChange : WorkflowStepEnum.DefineFilters;
    return WorkflowStepEnum[this.lastStepCompleted] > previousStep;
  }

  get hasFrequency() {
    if (!this.hasTargets) {
      return false;
    }

    if ((this.exportColumns?.length ?? 0) < 1) {
      return false;
    }
    const previousStep = WorkflowStepEnum.SetAlert;
    return WorkflowStepEnum[this.lastStepCompleted] > previousStep;
    // return this.hasTargets && (this.exportColumns?.length ?? 0) >= 4;  /* replace true with logic */
  }

  get isBehaviorConfigured(): boolean {
    if (!this.hasMinimumFilters) {
      return false;
    }

    if (!this.activityMonths) {
      return false;
    }

    const previousStep = WorkflowStepEnum.DefineFilters;
    return WorkflowStepEnum[this.lastStepCompleted] > previousStep;
    // return this.hasMinimumFilters && this.activityMonths;  /* replace true with logic */
  }

  getLastAvailableStep(currentStep: WorkflowStepEnum): WorkflowStepEnum {
    return WorkflowStepEnum[this.lastStepCompleted] < currentStep ? currentStep : WorkflowStepEnum[this.lastStepCompleted];
  }
}
