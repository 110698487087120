<template>
  <div class="flex col-12 pt-4 align-items-center pl-0">
    <h3>{{ text }}</h3>
    <InputSwitch v-model="local" class="ml-4" />
  </div>
</template>

<script lang="ts">
import { isEqual } from 'lodash';
import { Prop, Watch } from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component';

@Options({ name: 'cp-activate' })
export default class ActivateAlertField extends Vue {
  @Prop({ required: true, default: false }) modelValue!: boolean;
  @Prop() text: string;
  local: boolean = false;

  mounted() {
    this.local = this.modelValue;
  }

  @Watch('local')
  onActivateChanged(newVal: boolean, oldVal: boolean): void {
    if (isEqual(newVal, oldVal)) {
      return;
    }

    this.$emit('update:modelValue', newVal);
  }
}
</script>
<style lang="scss">

</style>
